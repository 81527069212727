export interface DirectoryViewModel {
  id: number,
  avatar: string,
  countAuthored: number,
  countInvolved: number,
  displayName: string,
  email: string,
  firstName: string,
  lastName: string,
  slug: string,
  followId: number
}

export class DirectoryViewModel {
  constructor(data?: Partial<DirectoryViewModel>) {
    Object.assign(this, data);
  }

  public get isFollowing() {
    return this.followId > 0;
  }
}
