import { Action } from '@ngrx/store';

import { FollowInputApiModel } from '../../models/api-models/input';
import { FollowOutputApiModel } from '@modules/shared/models/api-models/output';

export enum ActionTypes {
    FOLLOW = '[Shared] Follow',
    FOLLOW_SUCCESS = '[Shared] Follow Success',
    FOLLOW_FAILURE = '[Shared] Follow Failure',

    UNFOLLOW = '[Shared] Unfollow',
    UNFOLLOW_SUCCESS = '[Shared] Unfollow Success',
    UNFOLLOW_FAILURE = '[Shared] Unfollow Failure'
}

export class Follow implements Action {
    readonly type = ActionTypes.FOLLOW;

    constructor(public payload: FollowInputApiModel) { }
}

export class FollowSuccess implements Action {
    readonly type = ActionTypes.FOLLOW_SUCCESS;

    constructor(public response: FollowOutputApiModel) { }
}

export class FollowFailure implements Action {
    readonly type = ActionTypes.FOLLOW_FAILURE;

    constructor(public error: any) { }
}

export class Unfollow implements Action {
    readonly type = ActionTypes.UNFOLLOW;

    constructor(public id: number, public resourceType: string, public resourceId: number) { }
}

export class UnfollowSuccess implements Action {
    readonly type = ActionTypes.UNFOLLOW_SUCCESS;

    constructor(public response: FollowOutputApiModel) { }
}

export class UnfollowFailure implements Action {
    readonly type = ActionTypes.UNFOLLOW_FAILURE;

    constructor(public error: any) { }
}

export type Actions = Follow | FollowSuccess | FollowFailure |
                      Unfollow | UnfollowSuccess | UnfollowFailure;
