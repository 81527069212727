import { Component, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';

@Component({
  selector: 'user-pub-informations',
  templateUrl: './informations.component.html',
  styleUrls: ['./informations.component.scss']
})
export class UserProfileInformationsComponent extends ComponentBase {

  // Private variables
  private selectSelectedProfile$: Observable<UserProfileViewModel>;
  private selectSelectedProfileSubscription: Subscription;

  // Public varibales
  public profile: UserProfileViewModel;
  public bageDefaultIcon: string = '/assets/front-end-minify/img/badge-default.svg';

  constructor(
    private store$: Store<CoreState>
  ) {
    super();
    this.selectSelectedProfile$ = this.store$.select(userProfileSelectors.selectSelectedProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectSelectedProfileSubscription = this.selectSelectedProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.profile = userProfile;
      }
    });
  }

  private unsubscribe() {
    this.selectSelectedProfileSubscription.unsubscribe();
  }
  // Public functions
  public hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.3)';
    }
  }
  getModifiedNames(number){
    var name;
    if(this.profile && this.profile?.storyFrequentTags &&  this.profile?.storyFrequentTags.length){
      if(number == 2){
        name = this.profile?.storyFrequentTags.find(item => item.id == 2).header_title.toLowerCase() == 'roles' ? 'Teams' : this.profile?.storyFrequentTags.find(item => item.id == 2).header_title;
         
      }
      if(number == 8){
        name = this.profile?.storyFrequentTags.find(item => item.id == 8).header_title.toLowerCase() == 'impact' ? 'Skills' : this.profile?.storyFrequentTags.find(item => item.id == 8).header_title;
         
      }
      if(number == 13){
        name = this.profile?.storyFrequentTags.find(item => item.id == 13).header_title.toLowerCase() == 'feeling' ? 'Vibes' : this.profile?.storyFrequentTags.find(item => item.id == 13).header_title;
         
      }
      if(number == 14){
        name = this.profile?.storyFrequentTags.find(item => item.id == 14).header_title.toLowerCase() == 'values' ? 'Values' : this.profile?.storyFrequentTags.find(item => item.id == 14).header_title;
         
      }
      if(number == 11){
        name = this.profile?.storyFrequentTags.find(item => item.id == 11).header_title.toLowerCase() == 'challenges' ? 'Challenges' : this.profile?.storyFrequentTags.find(item => item.id == 11).header_title;
         
      }
      return name;
    }

  }
}

