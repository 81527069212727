import { Action } from '@ngrx/store';

import { LoadAppSettingOutputApiModel } from '@modules/shared/models/api-models/output';

export enum ActionTypes {
    LOAD_APP_SETTING = '[Shared] Load App Setting',
    LOAD_APP_SETTING_SUCCESS = '[Shared] Load App Setting Success',
    LOAD_APP_SETTING_FAILURE = '[Shared] Load App Setting Failure'
}


export class LoadAppSetting implements Action {
    readonly type = ActionTypes.LOAD_APP_SETTING;

    constructor() { }
}

export class LoadAppSettingSuccess implements Action {
    readonly type = ActionTypes.LOAD_APP_SETTING_SUCCESS;

    constructor(public response: LoadAppSettingOutputApiModel) { }
}

export class LoadAppSettingFailure implements Action {
    readonly type = ActionTypes.LOAD_APP_SETTING_FAILURE;

    constructor(public error: any) { }
}

export type Actions
    = LoadAppSetting
    | LoadAppSettingSuccess
    | LoadAppSettingFailure;
