import { Component, HostListener, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from '@modules/shared/models/common/seo.model';

import { isPlatformBrowser } from '@angular/common';
import { Page } from '@framework/base';
import { environment } from '@app/env/environment';


@Component({
  selector: 'app-embed-video-url',
  templateUrl: './embed-video-url.component.html',
  styleUrls: ['./embed-video-url.component.css']
})
export class EmbedVideoComponent extends Page {
  // Private variables
  public idOrSlug: number | string;
 public isBrowser:boolean;
  quillData:string;
  quillModule = {
    toolbar:[{}],
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object,
    private router:Router,
    private seoService:SeoService

  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    // this.checkIsAuthenticated();

    this.captureParams();

  }

  // Life cycle hook
  protected onInit(): void {
    // this.startImport();
    this.subscribe();
    if(!this.isBrowser){
      this.addMetaTags();
    }
   
  }
  protected onDestroy(): void {
    this.unsubscribe();
    // this.destoryScript();
    this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }

  /**
   * this method is to update meta tag according to story detail
   */
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
      config.title = `Embed`;
      config.description = 'EmbedGS';
      config.alt=  'Embed';
      config.image ='https://image.mux.com/'+this.idOrSlug+'/thumbnail.jpg'
   
    config.url = 'https://'+environment.customUrl+this.router.url;
  
    config.castLarge = true;
    this.seoService.updateMetaTags(config);
  }


  // public startImport(){
  //   $("#hippo-video-delivery-scripts").empty();
  //   let injectScript = document.createElement('script');
  //   injectScript.type = 'text/javascript';
  //   injectScript.async = true;
  //   injectScript.src = 'https://hippo-embed-scripts.s3.amazonaws.com/video-delivery-embed.js'
  //   document.getElementById('hippo-video-delivery-scripts').appendChild(injectScript);
  // }


  // destoryScript(){
  //   $("#hippo-video-delivery-scripts").empty();
  // }

  private subscribe() {



  }

  private unsubscribe() {

  }

  private captureParams() {
   this.idOrSlug = this.activatedRoute.snapshot.paramMap.get('idOrSlug');
  }





  // Public function
 
  getUrl(media){
    if(media && media.thumbnail_url){
      return media.thumbnail_url
    }
    else{
     return 'https://image.mux.com/'+media.source+'/thumbnail.jpg'
    }
  }

}



