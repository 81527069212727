import { TagOutputApiModel } from './tag-output.api-model';

export class TagPlayer {
  id: number;
  name: string;
  emaile: string;
  avatar: string;
  constructor(init?: Partial<TagPlayer>) {
    Object.assign(this, init);
    this.init();
  }

  private init() {
    
  }
}

export interface StepOutputApiModel {
  id: number,
  sort: number,
  title: string,
  text: string,
  type: string,
  color: string,
  tags: Array<TagOutputApiModel>,
}

export class StepOutputApiModel {
  constructor(init?: Partial<StepOutputApiModel>) {
    Object.assign(this, init);
    this.init();
  }

  private init() {
  }
}