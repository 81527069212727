<div class="modal-header">
  <div style="display: flex; flex-direction: column"><h5 class="modal-title">Get a Link to Share</h5>
    Pick a single prompt or campaign with multiple prompts. </div>
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">
          <svg role="img">
              <use xlink:href="#icon-close"></use>
          </svg></span></button>
</div>
<div class="modal-body request-story" [formGroup]="requestForm">
  <ng-container *ngIf="initialState.public">
    <div class="headings_of_invite_content">
      <div class="row">
        <div class="col-4" style="white-space: nowrap;">
        <h6>Story From</h6>
        </div>
        <div class="col-4">
          <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" (change)="userstatus($event)" formControlName="userStatus" value="user"
                    class="custom-control-input">
                <span class="custom-control-label">Link for a User</span>
            </label>
        </div>
        </div>
        <div class="col-4">
          <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio" (change)="userstatus($event)" formControlName="userStatus" value="Guestuser"
                    class="custom-control-input">
                <span class="custom-control-label">Link for a Guest</span>
            </label>
        </div>
        </div>
       </div>
    </div>

   </ng-container>
  <ng-container *ngIf="requestForm.controls.userStatus.value == 'user'">
    <!--<h6 class="font-weight-bold mt-3 mr-3">Invite author</h6>-->
    <div (keyup.enter)="handleComKeyEnter()" class="story-flow-view__box view-box-people outline-none"
        [ngClass]="{'current': !viewmodel.isStoryCreating}">
  
        <form
           
            [formGroup]="form" class="view-box-people__search setting_form" (keyup.enter)="exceptHandleComKeyEnter($event)">
            <div class="view-box-media__tab-item ml-1">
                <div class="search-input">
                    <input id="searchUnsplash"formControlName="keyword" type="text" placeholder="Search"
                        class="form-control" autofocus>
                </div>
                <div class="view-box-people__search-button button-label" [ngClass]="{'searching': viewmodel.searching}">
                    <div [hidden]="!viewmodel.searching" class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <button [hidden]="viewmodel.searching" type="submit"><i class=""></i></button>
                </div>
            </div>
  
        </form>
        <div class="view-box-people__result spacer">
          <div class="no___members" *ngIf="members && members?.length == 0">
            NO USER FOUND. <span *ngIf="initialState.public">Invite them to submit as a guest.</span>
          </div>
            <scrollbar #scrollbar *ngIf="members && members?.length > 0" (onYReachEnd)="onYReachEnd()"
                (onPositionChanged)="onPositionChanged()">
                <div class="l-people-result">
  
                    <label class="l-people-result__item"
                        *ngFor="let player of members; index as i; trackBy: memberTrackByFnc"
                        title="{{player.title ? player.title : ''}}">
  
                        <span class="l_people_request">
                            <gs-avatar [src]="player.avatar" isBackground="true" classCss="avatar"></gs-avatar>
                        </span>
  
                        <ng-container *ngIf="player.storyInviteEnabled !== false">
                            <span class="l-people-result__content">
                                <span class="l-people-result__name">{{player.displayName}}&nbsp;-</span>
                                <span class="l-people-result__mail">&nbsp;{{player.email}}</span>
                            </span>
                            <span class="l-people-result__checkbox" *ngIf="!isCopyCode">
                                <input type="checkbox" [(ngModel)]="player.checked" [ngModelOptions]="{standalone: true}" (change)="selectItem(player)">
                                <span>
                                </span>
                            </span>
                            <span class="l-people-result__checkbox" *ngIf="isCopyCode">
                                <span data-toggle="tooltip" #copyToolTip class="copy-text-{{i}}" data-placement="right"
                                    title="Click to copy"><span></span>
                                    <span (click)="copyMessage(player,copyToolTip)"><i
                                            class="fas fa-link"></i></span>
                                    <span>
                                    </span>
                                </span>
                            </span>
                        </ng-container>
  
                        <ng-container *ngIf="player.storyInviteEnabled === false && !player.checked">
                            <span class="l-people-result__content">
                                <span class="l-people-result__name text-dark">
                                    <span data-toggle="tooltip" data-placement="right" title="Unavailable to add"
                                        class="pr-2"><span>{{player.displayName}} &nbsp;</span>
                                        <svg role="img" class="icon16">
                                            <use xlink:href="#icon-info"></use>
                                        </svg>
                                    </span>
                                </span>
                                <span class="l-people-result__mail">-&nbsp;{{player.email}}</span>
                            </span>
                        </ng-container>
                        <span class="copied-success" *ngIf="player?.linkCopied">Link copied!</span>
                    </label>
                    <div class="l-story-box-media__loading" [hidden]="(!loadMore || viewmodel.searching)" *ngIf="!isCopyCode"
                    >
                        Loading more ....
                     
                    </div>
                </div>
            </scrollbar>
            <div class="container mt-5 text-center"
                *ngIf="(viewmodel.isPlayerCreating || (viewmodel.isPlayerLoading && !(members && members?.length > 0)))">
                <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="showRequestedUser && requestedMembers.length > 1 &&  !isCopyCode">
      <h6>Add popup message from:</h6>
      <div class="job__select mb-2" >
        <ng-select
        class="job-select"
        [placeholder]="''"
        [items]="requestedMembers"
        [multiple]="false"
        [closeOnSelect]="true"
        [selectableGroup]="true"
        maxSelectedItems ="1"
        bindValue="id"
        bindLabel="title"
        formControlName="userselcted"
        >
        <ng-template ng-option-tmp let-item="item" let-item$="item$"  let-index="index">
          <div class="mobile-check custom-control custom-checkbox">
            <input id="item-{{index}}" class="custom-control-input"  type="checkbox"  [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected"/> 
            <label class="custom-control-label">{{item.title}}</label>
          </div>
        </ng-template>
      </ng-select>
      </div>
    </ng-container>
    <div class="row">  
        <div class="col-12" *ngIf="!isCopyCode">
          <h6>Personal Message</h6>
          <!-- <textarea class="input__style" formControlName="message" name="" id="" cols="30" rows="4"></textarea> -->
          <gs-area-material
          label=""
          name="Message" placeholder="What happened? Why does it matter?" rows="2"
          cssClass="spacing-lg" formControlName="internalmessage" [formControl]="requestForm.controls.internalmessage">
      </gs-area-material>
         </div>
    </div>

    <div class="generate__box">

      <ng-container *ngIf="!hideSendanCopylinkButton">
        <div>
        <button [style.background]="primary_color ? primary_color : ''" [disabled]="!selectedData.length || isLoading ||  requestForm.invalid || diableonsearching" type="button" (click)="GenearateInternalLink()" class="btn btn-pink min-width">
        Generate Link
        </button>
        </div>
      </ng-container>
      <ng-container>
        <div>
          <button [style.background]="primary_color ? primary_color : ''" type="button" (click)="sendIntenalEmail()" class="btn btn-pink min-width" *ngIf="hideSendanCopylinkButton">
            Send to Selected
        </button>
        </div>
    
      </ng-container>
    
    
    </div>
  </ng-container>

  <ng-container *ngIf="requestForm.controls.userStatus.value == 'Guestuser'">
     <h6 class=" mr-3">Author Details</h6>
    <div class="pt-3">
      <div class="row">
        <div class="col-6">
          <!-- <h6>Firstname</h6> -->
          <gs-input-material label="{{!requestForm.controls.firstname.disabled ? 'First Name (Optional)' : ''}}" name="{{!requestForm.controls.firstname.disabled ? 'First Name (Optional)' : ''}}" placeholder="{{!requestForm.controls.firstname.disabled ? 'First Name (Optional)' : ''}}" formControlName="firstname" [formControl]="requestForm.controls.firstname" type="text"></gs-input-material>
        </div>
        <div class="col-6">
          <!-- <h6>Lastname</h6> -->
          <gs-input-material label="{{!requestForm.controls.lastname.disabled ? 'Last Name (Optional)' : ''}}" name="{{!requestForm.controls.lastname.disabled ? 'Last Name (Optional)' : ''}}" placeholder="{{!requestForm.controls.lastname.disabled ? 'Last Name (Optional)' : ''}}" formControlName="lastname" [formControl]="requestForm.controls.lastname" type="text"></gs-input-material>
        </div>
      </div>

      <div class="row">
          <div class="col-12">
            <!-- <h6>Email (Optional)</h6> -->
            <gs-input-material label="{{!requestForm.controls.email.disabled ? 'Email (Optional)' : ''}}" name="{{!requestForm.controls.email.disabled ? 'Email (Optional)' : ''}}" placeholder="!requestForm.controls.email.disabled ? 'Email (Optional)' : ''" formControlName="email" [formControl]="requestForm.controls.email" type="text"></gs-input-material>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <gs-input-material label="{{!requestForm.controls.note_email.disabled ? 'Notes (Optional)' : ''}}" name="{{!requestForm.controls.email.disabled ? 'Notes (Optional)' : ''}}" placeholder="!requestForm.controls.note_email.disabled ? 'Notes (Optional)' : ''" formControlName="note_email" [formControl]="requestForm.controls.note_email" type="text"></gs-input-material>
      </div>
    </div>
    </div>


   <div class="">
      <!-- <div class="row mb-3 ml-1" >
        <div class="mt-1"><h6>Personalize Invite Link</h6></div>
        <div class="slide_toggle ml-2 mb-2">
            <mat-slide-toggle  class="toglle_color" color="accent" (change)="chnageExternaladdPersonalize($event)" formControlName="addPersonalize">
            </mat-slide-toggle>
        </div>
      </div> -->
      <ng-container *ngIf="showRequestedUser && requestedMembers.length > 1">
        <h6>Add popup message from:</h6>
        <div class="job__select mb-2" >
          <ng-select
          class="job-select"
          [placeholder]="''"
          [items]="requestedMembers"
          [multiple]="false"
          [closeOnSelect]="true"
          [selectableGroup]="true"
          maxSelectedItems ="1"
          bindValue="id"
          bindLabel="title"
          formControlName="externalUserSelcted"
          >
          <ng-template ng-option-tmp let-item="item" let-item$="item$"  let-index="index">
            <div class="mobile-check custom-control custom-checkbox">
              <input id="item-{{index}}" class="custom-control-input"  type="checkbox"  [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected"/> 
              <label class="custom-control-label">{{item.title}}</label>
            </div>
          </ng-template>
        </ng-select>
        </div>
      </ng-container>
  
        <div class="row">
          <div class="col-12">
            <h6>Personal Message</h6>
            <gs-area-material
            label=""
            name="Message" placeholder="What happened? Why does it matter?" rows="2"
            cssClass="spacing-lg" formControlName="message" [formControl]="requestForm.controls.message">
        </gs-area-material>
          </div>

         </div>
    </div>

    <div class="generate__box">

      <ng-container *ngIf="!hideSendanCopylinkButton">
        <div>
          <button [style.background]="primary_color ? primary_color : ''" type="button" [disabled]="!requestForm.valid" (click)="GenearateExternalLink()" class="btn btn-pink min-width">
        Generate Link
        </button>
        </div>
      </ng-container>
      <ng-container *ngIf="isSingleInternalRequested">
        <div>
          <button type="button"  (click)="copyExternalUrl()" class="invite_guest btn  min-width">
            {{buttonText}} &nbsp; <span><i class="fas fa-link" style="color: #1579f6"></i></span>
        </button>
        </div>
      </ng-container>
      <ng-container *ngIf="isSingleInternalRequested">
        <div class="ml-3">
          <button type="button" (click)="shareadthis()"  class="invite_guest btn  min-width">
            Share &nbsp; <span><i class="fa-regular fa-share" style="color: #1579f6"></i></span>
          </button>
        </div>
      </ng-container>
      <div class="remark-box__adthis" [@showhideadthis]="isDispalyadthis ? 'show' : 'hide'">
        <div class="close__popup_Design">
          <button class="btn btn-trans ml-3" (click)="closeshare()">
            <svg role="img" class="text-pink">
              <use xlink:href="#icon-collapse"></use>
            </svg>
          </button>
        </div>
        <div #shareholic class="shareaholic-canvas" data-app="share_buttons" data-app-id="33117316"></div>
      </div>
      <!-- <ng-container>
        <div class="copy-link_container" *ngIf="isSingleInternalRequested">
          <div (click)="copyExternalUrl()">
            {{buttonText}}  <span ><i class="fa-solid fa-link"></i></span>
          </div>
        </div>
        <div class="or__container" *ngIf="isSingleInternalRequested">
          or
        </div>
        <div *ngIf="hideSendanCopylinkButton">
          <button type="button" (click)="sendExternalMainEmail()" class="btn btn-pink min-width" >
        Send
        </button>
        </div>
    
      </ng-container> -->
    
    
    </div>
  </ng-container>


</div>
