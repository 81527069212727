import { StoryFilterDataModel } from '@modules/company-admin/models';
import { Action } from '@ngrx/store';
import { GetStoryInputApiModel } from '../models/api-models/input';

export enum ActionTypes {

    LOAD = '[Policy] Load Policy',
    LOAD_SUCCESS = '[Policy] Load Success',
    LOAD_FAIL = '[Policy] Load Fail',

    LOAD_CONSENT = '[Consent] Load Consent',
    LOAD_CONSENT_SUCCESS = '[Consent] Load Consent Success',
    LOAD_CONSENT_FAIL = '[Consent] Load Consent Fail',

    ADD_POLICY = '[Add Policy] Add Policy',
    ADD_POLICY_SUCCESS = '[Add Policy] Add Policy Success',
    ADD_POLICY_FAIL = '[Add Policy] Add Policy Fail',


    UPDATE_POLICY = '[Update Policy] Update Policy',
    UPDATE_POLICY_SUCCESS = '[Update Policy] Update Policy Success',
    UPDATE_POLICY_FAIL = '[Update Policy] Update Policy Fail',

    UPDATE_CONSENT = '[Update Consent] Update Consent',
    UPDATE_CONSENT_SUCCESS = '[Update Consent] Update Consent Success',
    UPDATE_CONSENT_FAIL = '[Update Consent] Update Consent Fail',

    UPDATE_CONSENT_STORY = '[Update Consent Story] Update Consent Story',
    UPDATE_CONSENT_STORY_SUCCESS = '[Update Consent Story] Update Consent Story Success',
    UPDATE_CONSENT_STORY_FAIL = '[Update Consent Story] Update Consent Story Fail',

    DELETE_POLICY = '[Delete Policy] Delete Policy',
    DELETE_POLICY_SUCCESS = '[Delete Policy] Delete Policy Success',
    DELETE_POLICY_FAIL = '[Delete Policy] Delete Policy Fail',

    CHECK_POLICY_SLUG = '[Policy slug] Policy slug',
    CHECK_POLICY_SLUG_SUCCESS = '[Policy slug] Policy slug Success',
    CHECK_POLICY_SLUG_FAIL = '[Policy slug] Policy slug Fail',
     
}

export class Load implements Action{
    readonly type = ActionTypes.LOAD;
    constructor(public companyId: number ) { }
}
export class LoadSuccess implements Action{
    readonly type = ActionTypes.LOAD_SUCCESS;
    constructor(public response: any ) { }
}

export class LoadFail implements Action{
    readonly type = ActionTypes.LOAD_FAIL;
}

export class LoadConsent implements Action{
    readonly type = ActionTypes.LOAD_CONSENT;
    constructor(public companyId: number ) { }
}
export class LoadConsentSuccess implements Action{
    readonly type = ActionTypes.LOAD_CONSENT_SUCCESS;
    constructor(public response: any ) { }
}

export class LoadConsentFail implements Action{
    readonly type = ActionTypes.LOAD_CONSENT_FAIL;
}

export class checkPolicySlug implements Action{
    readonly type = ActionTypes.CHECK_POLICY_SLUG;
    constructor(public companyId: number,public policy_Slug ) { }
}
export class checkPolicySlugSuccess implements Action{
    readonly type = ActionTypes.CHECK_POLICY_SLUG_SUCCESS;
}

export class checkPolicySlugFail implements Action{
    readonly type = ActionTypes.CHECK_POLICY_SLUG_FAIL;
}

export class AddPolicy implements Action{
    readonly type = ActionTypes.ADD_POLICY;
    constructor(public companyId: number, public payload:any ) { } ;
}

export class AddPolicySuccess implements Action{
    readonly type = ActionTypes.ADD_POLICY_SUCCESS;
}

export class AddPolicyFail implements Action{
    readonly type = ActionTypes.ADD_POLICY_FAIL;
}

export class UpdatePolicy implements Action{
    readonly type = ActionTypes.UPDATE_POLICY;

    constructor(public companyId: number, public payload:any ) { }
}

export class UpdatePolicySuccess implements Action{
    readonly type = ActionTypes.UPDATE_POLICY_SUCCESS;
}

export class UpdatePolicyFail implements Action{
    readonly type = ActionTypes.UPDATE_POLICY_FAIL;
}

export class UpdateConsent implements Action{
    readonly type = ActionTypes.UPDATE_CONSENT;

    constructor(public companyId: number, public payload:any ) { }
}

export class UpdateConsentSuccess implements Action{
    readonly type = ActionTypes.UPDATE_CONSENT_SUCCESS;
}

export class UpdateConsentFail implements Action{
    readonly type = ActionTypes.UPDATE_CONSENT_FAIL
}

export class DeletePolicy implements Action{
    readonly type = ActionTypes.DELETE_POLICY;

    constructor(public companyId: number, public policy_id:any ) { }
}

export class DeletePolicySuccess implements Action{
    readonly type = ActionTypes.DELETE_POLICY_SUCCESS;
}

export class DeletePolicyFail implements Action{
    readonly type = ActionTypes.DELETE_POLICY_FAIL;
}

export class UpdateConsentStory implements Action{
    readonly type = ActionTypes.UPDATE_CONSENT_STORY;

    constructor(public companyId: number, public payload:any, public story_id:any ) { }
}

export class UpdateConsentStorySuccess implements Action{
    readonly type = ActionTypes.UPDATE_CONSENT_STORY_SUCCESS;
}

export class UpdateConsentStoryFail implements Action{
    readonly type = ActionTypes.UPDATE_CONSENT_STORY_FAIL
}


export type Actions = 
Load | LoadSuccess | LoadFail | AddPolicy | AddPolicySuccess | AddPolicyFail
| UpdatePolicy 
| UpdatePolicySuccess 
| UpdatePolicyFail  
| DeletePolicy 
| DeletePolicySuccess 
| DeletePolicyFail
| checkPolicySlug
| checkPolicySlugFail
| checkPolicySlugSuccess
| UpdateConsent
| UpdateConsentSuccess
| UpdateConsentFail
| LoadConsent
| LoadConsentSuccess
| LoadConsentFail
| UpdateConsentStory
| UpdateConsentStorySuccess
| UpdateConsentStoryFail