import { UserNotificationsOutputApiModel } from '@modules/user/models/output';

export interface UserNotificationsEntity {
  companyStoryPublishEmailEnabled: boolean;
  commentEmailEnabled: boolean;
  commentLikeEmailEnabled: boolean;
  likeEmailEnabled: boolean;
  viewEmailEnabled: boolean;
  storyContributionEmailEnabled: boolean;
}

export class UserNotificationsEntity {
  constructor(data?: UserNotificationsOutputApiModel) {
    if (data) {
      this.convertFormApi(data);
    }
  }

  private convertFormApi(data: UserNotificationsOutputApiModel) {
    this.companyStoryPublishEmailEnabled = data.company_story_publish_email_enabled;
    this.commentEmailEnabled = data.my_story_comment_email_enabled;
    this.commentLikeEmailEnabled = data.my_story_comment_like_email_enabled;
    this.likeEmailEnabled = data.my_story_like_email_enabled;
    this.viewEmailEnabled = data.my_story_view_email_enabled;
    this.storyContributionEmailEnabled = data.my_story_contribution_email_enabled;
  }
}
