import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripHtml'
})

export class StripHtmlPipe implements PipeTransform {

    constructor() { }

    transform(value: string): any {
        let htmlText;
        if (value) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = value;
            htmlText = tmp.textContent || tmp.innerText || "";

            // value = value.replace(/<.*?>/gi, '');
            // value = value.replace(/<[^>]*>?/gm, '');
        }
        return htmlText ? htmlText : value;
    }
}
