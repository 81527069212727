import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[background-image]'
})
export class BackgroundImageDirective implements OnChanges {
    private el: HTMLElement;

    @Input('background-image') backgroundImage: string;

    constructor(el: ElementRef, private sanitizer: DomSanitizer) {
        this.el = el.nativeElement;
    }

    ngOnChanges() {
        this.el.style.backgroundImage = 'url(' + this.backgroundImage + ')';
    }
}