<div class="modal-body">
  <div class="setting-photo-crop">
    <image-cropper [config]="initialState.options"
      [imageUrl]="initialState.imageUrl"></image-cropper>
  </div>
  <div class="mt-5 text-center">Upload new photo</div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" (click)="close()" class="btn btn-outline btn-white min-width">Cancel</button>
  <button type="button" (click)="exportData()" class="btn btn-pink min-width">Save</button>
</div>