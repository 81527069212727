import { Component, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';

import { ComponentBase } from '@framework/base/component/component.base';

@Component({
  selector: 'co-pb-cp-story-item',
  templateUrl: './story-item.component.html',
  styleUrls: ['./story-item.component.scss']
})
export class StoryItemComponent extends ComponentBase {
  @Input() story: any;
  @Input() href: string;
  @Input() stepsData: any;
  @Input() userLables:any = ''
  @Input() GuestLables:any = ''

  @Output() componentRendered: EventEmitter<any> = new EventEmitter<any>();
  // Private variables
  private monthNames = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
  };
  // Public variables
  public maxContributorItem: number = 4;
  dynamicHoverColor: any;

  constructor() {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }

  protected onDestroy(): void {
    this.unsubscribe();
  }

  protected onChanges(changes: SimpleChanges): void {
    this.mapStoryTagsColor();
  }

  protected onDoCheck(): void {

  }

  protected onAfterContentInit(): void {

  }

  protected onAfterContentChecked(): void {

  }

  protected onAfterViewInit(): void {
    this.componentRendered.emit();
  }

  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }

  // Public functions
  public getMonth(date: Date) {
    if (!date) {
      return '';
    }
    date = new Date(date);
    return this.monthNames[date.getMonth()];
  }

  public getDay(date: Date) {
    if (!date) {
      return '';
    }
    date = new Date(date);
    return date.getDate();
  }

  mapStoryTagsColor(): void {
    if (this.stepsData && this.stepsData.length > 0 && this.story.highlightTags) {
      this.story.highlightTags = this.story.highlightTags.map(tag => {
        const stepMatched = this.stepsData.find(step => step.sort == tag.sort);
        tag.color = stepMatched ? stepMatched.color : tag.color;
        return tag;
      })
    }
  }
  getUrl(media){
    if(media && media.thumbnail_url){
      return media.thumbnail_url
    }
    else{
     return 'https://image.mux.com/'+media.source+'/thumbnail.jpg'
    }
  }

  
  queryParamsDeatil(story){
    if(story && story.campaign_id) {
      return {campaignId:story.campaign_id , public:1}
    }
    else{
      return ''
    }
 
 }
 changeHoverColor() {
  var dynamicHoverColors = localStorage.getItem('hoverColor'); 
  this.dynamicHoverColor = dynamicHoverColors ?  dynamicHoverColors : '#1bb6bb'// New hover color
}

resetHoverColor() {
  this.dynamicHoverColor = ''; // Reset to the initial color
}
}
