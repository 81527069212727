import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';

@Injectable({
    providedIn: 'root'
})
export class RssFeedHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/');
    }

    public getRSSToken() {
        return this.post(`rss/tokens/`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
