export class StepIcon {
    static readonly default = '#icon-impact';
    static readonly impression = '#icon-impressive';
    static readonly impressive = '#icon-impressive';
    static readonly teams = '#icon-roles';
    static readonly people = '#icon-involved';
    static readonly vibes = '#icon-feeling';
    static readonly skills = '#icon-impact';
    static readonly challenges = '#icon-atstake';
    static readonly atstake = '#icon-atstake';
    static readonly values = '#icon-values';
    static readonly media = '#icon-gallery';

    public static getStepIcon(key: string) {
        key = key.toLocaleLowerCase().trim();
        if (this[key]) {
            return this[key];
        }
        return this.default;
    }
};