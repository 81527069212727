import { Action } from '@ngrx/store';

import { SearchCompanyOutputApiModel, InfoCompanyOutputApiModel } from '../../models/api-models/output';
import { CreateCompanyInputApiModel, UpdateCompanyInputApiModel } from '@modules/company/models/api-models/input';

export enum ActionTypes {
    SEARCH = "[Company] Search",
    SEARCH_SUCCESS = "[Company] Search Success",
    SEARCH_FAILURE = "[Company] Search Failure",

    SEARCH_BY_NAME = "[Company] Search By Name",
    SEARCH_BY_NAME_SUCCESS = "[Company] Search By Name Success",
    SEARCH_BY_NAME_FAILURE = "[Company] Search By Name Failure",

    ADD_MEMBER = "[Company] Add Member",
    ADD_MEMBER_SUCCESS = "[Company] Add Member Success",
    ADD_MEMBER_FAILURE = "[Company] Add Member Failure",

    REMOVE_MEMBER = "[Company] Remove Member",
    REMOVE_MEMBER_SUCCESS = "[Company] Remove Member Success",
    REMOVE_MEMBER_FAILURE = "[Company] Remove Member Failure",

    CREATE = "[Company] Create",
    CREATE_SUCCESS = "[Company] Create Success",
    CREATE_FAILURE = "[Company] Create Failure",

    UPDATE = "[Company] Update",
    UPDATE_SUCCESS = "[Company] Update Success",
    UPDATE_FAILURE = "[Company] Update Failure",

    LOAD_INFO = '[Company] Load Info',
    LOAD_INFO_SUCCESS = '[Company] Load Info Success',
    LOAD_INFO_FAILURE = '[Company] Load Info Failure',

    LOAD_PUBLIC_INFO = '[Company] Load Info Public',
    LOAD_PUBLIC_INFO_SUCCESS = '[Company] Load Info Public Success',
    LOAD_PUBLIC_INFO_FAILURE = '[Company] Load Info Public Failure',

    LOAD_SHORT_INFO = '[Company] Load Short Info',
    LOAD_SHORT_INFO_SUCCESS = '[Company] Load Short Info Success',
    LOAD_SHORT_INFO_FAILURE = '[Company] Load Short Info Failure',

    SET_CREATE_COMPANY_DOMAIN = "[Company] Set Create Company Domain",

    CHANGE_SLUG = '[Company Profile] Change Slug',
    CHANGE_SLUG_SUCCESS = '[Company Profile] Change Slug Success',
    CHANGE_SLUG_FAILURE = '[Company Profile] Change Slug Failure',

    GET_STORY_CHEKOUT = '[Company] Get Story Checkout',
    GET_STORY_CHEKOUT_SUCCESS = '[Company] Get Story Checkout Success',
    GET_STORY_CHEKOUT_FAILURE = '[Company] Get Story Checkout Failure',

    UPDATE_STORY_CHEKOUT = '[Company] Update Story Checkout',
    UPDATE_STORY_CHEKOUT_SUCCESS = '[Company] Update Story Checkout Success',
    UPDATE_STORY_CHEKOUT_FAILURE = '[Company] Update Story Checkout Failure',

}



export class Search implements Action {
    readonly type = ActionTypes.SEARCH;

    constructor(public domain: string) { }
}

export class SearchSuccess implements Action {
    readonly type = ActionTypes.SEARCH_SUCCESS;

    constructor(public response: SearchCompanyOutputApiModel) { }
}

export class SearchFailure implements Action {
    readonly type = ActionTypes.SEARCH_FAILURE;

    constructor(public error: any) { }
}

export class SearchByName implements Action {
    readonly type = ActionTypes.SEARCH_BY_NAME;

    constructor(public name: string) { }
}

export class SearchByNameSuccess implements Action {
    readonly type = ActionTypes.SEARCH_BY_NAME_SUCCESS;

    constructor(public response: SearchCompanyOutputApiModel) { }
}

export class SearchByNameFailure implements Action {
    readonly type = ActionTypes.SEARCH_BY_NAME_SUCCESS;

    constructor(public error: any) { }
}

export class AddMember implements Action {
    readonly type = ActionTypes.ADD_MEMBER;

    constructor(public companyId: number, public userId: number) { }
}

export class AddMemberSuccess implements Action {
    readonly type = ActionTypes.ADD_MEMBER_SUCCESS;

    constructor() { }
}

export class AddMemberFailure implements Action {
    readonly type = ActionTypes.ADD_MEMBER_FAILURE;

    constructor(public error: any) { }
}

export class RemoveMember implements Action {
    readonly type = ActionTypes.REMOVE_MEMBER;

    constructor() { }
}

export class RemoveMemberSuccess implements Action {
    readonly type = ActionTypes.REMOVE_MEMBER_SUCCESS;

    constructor() { }
}

export class RemoveMemberFailure implements Action {
    readonly type = ActionTypes.REMOVE_MEMBER_FAILURE;

    constructor(public error: any) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(public payload: CreateCompanyInputApiModel) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(public companyId: any) { }
}

export class CreateFailure implements Action {
    readonly type = ActionTypes.CREATE_FAILURE;

    constructor(public error: any) { }
}

export class SetCreateCompanyDomain implements Action {
    readonly type = ActionTypes.SET_CREATE_COMPANY_DOMAIN;

    constructor(public companyDomain: string) { }
}

export class LoadInfo implements Action {
    readonly type = ActionTypes.LOAD_INFO;

    constructor(public companyId: number) { }
}

export class LoadInfoSuccess implements Action {
    readonly type = ActionTypes.LOAD_INFO_SUCCESS;

    constructor(public response: InfoCompanyOutputApiModel) { }
}

export class LoadInfoFailure implements Action {
    readonly type = ActionTypes.LOAD_INFO_FAILURE;

    constructor(public error: any) { }
}

export class LoadPublicInfo implements Action {
    readonly type = ActionTypes.LOAD_PUBLIC_INFO;

    constructor(public companyIdOrSlug: number | string) { }
}

export class LoadPublicInfoSuccess implements Action {
    readonly type = ActionTypes.LOAD_PUBLIC_INFO_SUCCESS;

    constructor(public response: InfoCompanyOutputApiModel) { }
}

export class LoadPublicInfoFailure implements Action {
    readonly type = ActionTypes.LOAD_PUBLIC_INFO_FAILURE;

    constructor(public error: any) { }
}

export class LoadShortInfo implements Action {
    readonly type = ActionTypes.LOAD_SHORT_INFO;

    constructor(public companyIdOrSlug: number | string) { }
}

export class LoadShortInfoSuccess implements Action {
    readonly type = ActionTypes.LOAD_SHORT_INFO_SUCCESS;

    constructor(public response: InfoCompanyOutputApiModel) { }
}

export class LoadShortInfoFailure implements Action {
    readonly type = ActionTypes.LOAD_SHORT_INFO_FAILURE;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public payload: Partial<UpdateCompanyInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;

    constructor() { }
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAILURE;

    constructor(public error: any) { }
}

export class ChangeSlug implements Action {
    readonly type = ActionTypes.CHANGE_SLUG;

    constructor(public payload: Partial<any>) { }
}

export class ChangeSlugSuccess implements Action {
    readonly type = ActionTypes.CHANGE_SLUG_SUCCESS;
}

export class ChangeSlugFailure implements Action {
    readonly type = ActionTypes.CHANGE_SLUG_FAILURE;

    constructor(public error: any) { }
}


export class UpdateStoryCheckOut implements Action {
    readonly type = ActionTypes.UPDATE_STORY_CHEKOUT;

    constructor(public companyId:any, public payload:any) { }
}

export class UpdateStoryCheckOutSuccess implements Action {
    readonly type = ActionTypes.UPDATE_STORY_CHEKOUT_SUCCESS;
}

export class UpdateStoryCheckOutFailure implements Action {
    readonly type = ActionTypes.UPDATE_STORY_CHEKOUT_FAILURE;

    constructor(public error: any) { }
}


export class GetStoryCheckout implements Action {
    readonly type = ActionTypes.GET_STORY_CHEKOUT;

    constructor(public companyId:any) { }
}

export class GetStoryCheckoutSuccess implements Action {
    readonly type = ActionTypes.GET_STORY_CHEKOUT_SUCCESS;

    constructor(public response: any) { }
}

export class GetStoryCheckoutFailure implements Action {
    readonly type = ActionTypes.GET_STORY_CHEKOUT_FAILURE;

    constructor(public error: any) { }
}






export type Actions =
    Search | SearchSuccess | SearchFailure |
    SearchByName | SearchByNameSuccess | SearchByNameFailure |
    AddMember | AddMemberSuccess | AddMemberFailure |
    Create | CreateSuccess | CreateFailure |
    SetCreateCompanyDomain |
    LoadInfo | LoadInfoSuccess | LoadInfoFailure |
    LoadPublicInfo | LoadPublicInfoSuccess | LoadPublicInfoFailure |
    Update | UpdateSuccess | UpdateFailure |
    LoadShortInfo | LoadShortInfoSuccess | LoadShortInfoFailure |
    ChangeSlug | ChangeSlugSuccess | ChangeSlugFailure
    | UpdateStoryCheckOut | UpdateStoryCheckOutSuccess | UpdateStoryCheckOutFailure
    | GetStoryCheckout | GetStoryCheckoutSuccess | GetStoryCheckoutFailure
    
