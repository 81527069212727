import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { UserEmailEntity } from '@modules/user/store/entities';
import { UserEmailState } from '@modules/user/store/states';

export const userEmailAdapter: EntityAdapter<UserEmailEntity> = createEntityAdapter<UserEmailEntity>();

export const initialState: UserEmailState = userEmailAdapter.getInitialState({
  isLoading: false,
  isLoaded: false
});
