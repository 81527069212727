<div class="modal-header pb-0">
  <h4 class="modal-title">Change Display Title</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>

<div class="modal-body" [formGroup]="copyForm">
<div class="row">
  <div class="col-12">
    <gs-input-material label="Display Title" name="Display Title" placeholder="Display Title"
    [formControl]="copyForm.controls.displayTitle"  formControlName="displayTitle" type="text">
  </gs-input-material>
  </div>
</div>
</div>
<div class="modal-footer">
  <button (click)="close()" type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width">Cancel</button>
  <button [disabled]="copyForm.invalid" (click)="saveData()" type="button" class="btn btn-pink min-width">
    Save
  </button>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->