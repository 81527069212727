
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
@Injectable()
export class ScriptHttpservice extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/scripts');
    }

    load(companyid){
        return this.get(`/companies/${companyid}/setting/scripts/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    add(companyid,payload){
        return this.post(`/companies/${companyid}/setting/scripts/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    
    edit(companyid,scriptid,payload){
        return this.patch(`/companies/${companyid}/setting/scripts/${scriptid}/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    remove(companyid,scriptid){
        return this.delete(`/companies/${companyid}/setting/scripts/${scriptid}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}