import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ImageGallerySettingEntity } from '../entities';
import { ImageGallerySettingState } from '../states';

export const ImageGallerySettingAdapter: EntityAdapter<ImageGallerySettingEntity> = createEntityAdapter<ImageGallerySettingEntity>();

export const initialState: ImageGallerySettingState = ImageGallerySettingAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});