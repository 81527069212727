import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { PolicyActons } from '../actions';
import { PolicyHttpService } from '../services';
// import { AddUserHttpService, } from '../services';


@Injectable()
export class PolicyEffect {

    addPolicyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.AddPolicy>(PolicyActons.ActionTypes.ADD_POLICY),
        switchMap((action: PolicyActons.AddPolicy) => {
          return this.PolicyHttpService.addPolicy(action.companyId, action.payload).pipe(
            map((respone) => {
              return new PolicyActons.AddPolicySuccess();
            }),
            catchError((error: any) => {
              return of(new PolicyActons.AddPolicyFail());
            }));
        })
      ));

      checkPolicySlug$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.AddPolicy>(PolicyActons.ActionTypes.ADD_POLICY),
        switchMap((action: PolicyActons.AddPolicy) => {
          return this.PolicyHttpService.checkPolicySlug(action.companyId, action.payload).pipe(
            map((respone) => {
              return new PolicyActons.AddPolicySuccess();
            }),
            catchError((error: any) => {
              return of(new PolicyActons.AddPolicyFail());
            }));
        })
      ))
    
      
      updatePolicyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.UpdatePolicy>(PolicyActons.ActionTypes.UPDATE_POLICY),
        switchMap((action: PolicyActons.UpdatePolicy) => {
          return this.PolicyHttpService.updatePolicy(action.companyId, action.payload).pipe(
            map((respone) => {
              return new PolicyActons.UpdatePolicySuccess();
            }),
            catchError((error: any) => {
              return of(new PolicyActons.UpdatePolicyFail());
            }));
        })
      ));

      updateConsentEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.UpdateConsent>(PolicyActons.ActionTypes.UPDATE_CONSENT),
        switchMap((action: PolicyActons.UpdateConsent) => {
          return this.PolicyHttpService.updateConcent(action.companyId, action.payload).pipe(
            map((respone) => {
              return new PolicyActons.UpdateConsentSuccess();
            }),
            catchError((error: any) => {
              return of(new PolicyActons.UpdateConsentFail());
            }));
        })
      ));
    
    
      
      loadPolicy$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.Load>(PolicyActons.ActionTypes.LOAD),
        switchMap((action: PolicyActons.Load) => {
          return this.PolicyHttpService.getPolicy(action.companyId).pipe(
            map((respone) => {
              return new PolicyActons.LoadSuccess(respone);
            }),
            catchError((error: any) => {
              return of(new PolicyActons.LoadFail());
            }));
        })
      ));

      loadConsent$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.LoadConsent>(PolicyActons.ActionTypes.LOAD_CONSENT),
        switchMap((action: PolicyActons.LoadConsent) => {
          return this.PolicyHttpService.getConsent(action.companyId).pipe(
            map((respone) => {
              return new PolicyActons.LoadConsentSuccess(respone);
            }),
            catchError((error: any) => {
              return of(new PolicyActons.LoadConsentFail());
            }));
        })
      ));

      updateConsentStory$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.UpdateConsentStory>(PolicyActons.ActionTypes.UPDATE_CONSENT_STORY),
        switchMap((action: PolicyActons.UpdateConsentStory) => {
          return this.PolicyHttpService.updateConsentToStory(action.companyId,action.payload,action.story_id).pipe(
            map((respone) => {
              return new PolicyActons.UpdateConsentStorySuccess();
            }),
            catchError((error: any) => {
              return of(new PolicyActons.UpdateConsentStoryFail());
            }));
        })
      ));

        deletePolicy$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<PolicyActons.DeletePolicy>(PolicyActons.ActionTypes.DELETE_POLICY),
        switchMap((action: PolicyActons.DeletePolicy) => {
          return this.PolicyHttpService.deletePolicy(action.companyId,action.policy_id).pipe(
            map((respone) => {
              return new PolicyActons.DeletePolicySuccess();
            }),
            catchError((error: any) => {
              return of(new PolicyActons.DeletePolicyFail());
            }));
        })
      ));

      constructor(
        private actions$: Actions,
        private PolicyHttpService: PolicyHttpService) { }    
}