import { storyStepReducer } from './story-step.reducer';
import { storyCtaTemplateReducer } from './story-cta-template.reducer';
import { storyReducer } from './story.reducer';
import { storyBuilderReducer } from './story-builder.reducer';
import { storySettingReducer } from './story-setting.reducer';
import { contributionReducer } from './contribution.reducer';

export const storyReducers = {
  story: storyReducer,
  storyStep: storyStepReducer,
  storyCtaTemplate: storyCtaTemplateReducer,
  storyBuilder: storyBuilderReducer,
  storySetting: storySettingReducer,
  contribution: contributionReducer
}

