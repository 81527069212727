import { Action } from '@ngrx/store';

import { TestimonialOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetTestimonialInputApiModel } from '../models/api-models/input';


export enum ActionTypes {
    LOAD = '[Company Admin Testimonial] Load',
    LOAD_SUCCESS = '[Company Admin Testimonial] Load Success',
    LOAD_FAIL = '[Company Admin Testimonial] Load Fail',

    MAKE_ACTIVE = '[Company Admin Testimonial] Make Active',
    MAKE_ACTIVE_SUCCESS = '[Company Admin Testimonial] Make Active Success',
    MAKE_ACTIVE_FAIL = '[Company Admin Testimonial] Make Active Fail',

    MAKE_INACTIVE = '[Company Admin Testimonial] Make Inactive',
    MAKE_INACTIVE_SUCCESS = '[Company Admin Testimonial] Make Inactive Success',
    MAKE_INACTIVE_FAIL = '[Company Admin Testimonial] Make Inactive Fail',

    TURN_PUBLIC_ON = '[Company Admin Testimonial] Make Public',
    TURN_PUBLIC_ON_SUCCESS = '[Company Admin Testimonial] Make Public Success',
    TURN_PUBLIC_ON_FAIL = '[Company Admin Testimonial] Make Public Fail',

    TURN_PUBLIC_OFF = '[Company Admin Testimonial] Make Internal',
    TURN_PUBLIC_OFF_SUCCESS = '[Company Admin Testimonial] Make Internal Success',
    TURN_PUBLIC_OFF_FAIL = '[Company Admin Testimonial] Make Internal Fail',

    APPROVE = '[Company Admin Testimonial] Approve',
    APPROVE_SUCCESS = '[Company Admin Testimonial] Approve Success',
    APPROVE_FAIL = '[Company Admin Testimonial] Approve Fail',

    REJECT = '[Company Admin Testimonial] Make Reject',
    REJECT_SUCCESS = '[Company Admin Testimonial] Reject Success',
    REJECT_FAIL = '[Company Admin Testimonial] Reject Fail',

    TURN_INTERNAL_ON = '[Company Admin Testimonial] Make Visible',
    TURN_INTERNAL_ON_SUCCESS = '[Company Admin Testimonial] Make Visible Success',
    TURN_INTERNAL_ON_FAIL = '[Company Admin Testimonial] Make Visible Fail',

    TURN_INTERNAL_OFF = '[Company Admin Testimonial] Make Invisible',
    TURN_INTERNAL_OFF_SUCCESS = '[Company Admin Testimonial] Make Invisible Success',
    TURN_INTERNAL_OFF_FAIL = '[Company Admin Testimonial] Make Invisible Fail',

    EDIT = '[Company Admin Testimonial] Edit',
    EDIT_SUCCESS = '[Company Admin Testimonial] Edit Success',
    EDIT_FAIL = '[Company Admin Testimonial] Edit Fail',

    LINK_TO_CAMPAIGN = '[Company Admin Testimonial] Link to Campaign',
    LINK_TO_CAMPAIGN_SUCCESS = '[Company Admin Testimonial] Link to Campaign Success',
    LINK_TO_CAMPAIGN_FAIL = '[Company Admin Testimonial] Link to Campaign Fail',

    EXPORT_GUEST_STORIES = '[Stories] Export Guest Stories',
    EXPORT_GUEST_STORIES_SUCCESS = '[Stories] Export Guest Stories Success',
    EXPORT_GUEST_STORIES_FAILURE = '[Stories] Export Guest Stories Failure'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public queryData: GetTestimonialInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<TestimonialOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class MakeActive implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class MakeActiveSuccess implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE_SUCCESS;
}

export class MakeActiveFail implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE_FAIL;

    constructor(public error: any) { }
}

export class MakeInactive implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class MakeInactiveSuccess implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE_SUCCESS;
}

export class MakeInactiveFail implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE_FAIL;

    constructor(public error: any) { }
}

export class TurnPublicOn implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON;

    constructor(public companyId: number, public adminId: number, public ids: number[], public payload: any) { }
}

export class TurnPublicOnSuccess implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON_SUCCESS;
}

export class TurnPublicOnFail implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON_FAIL;

    constructor(public error: any) { }
}

export class TurnPublicOff implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class TurnPublicOffSuccess implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF_SUCCESS;
}

export class TurnPublicOffFail implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF_FAIL;

    constructor(public error: any) { }
}

export class Approve implements Action {
    readonly type = ActionTypes.APPROVE;

    constructor(public companyId: number, public adminId: number, public ids: number[],public sendemail: boolean,public sendTo: number,public payload: any) { }
}

export class ApproveSuccess implements Action {
    readonly type = ActionTypes.APPROVE_SUCCESS;
}

export class ApproveFail implements Action {
    readonly type = ActionTypes.APPROVE_FAIL;

    constructor(public error: any) { }
}

export class Reject implements Action {
    readonly type = ActionTypes.REJECT;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class RejectSuccess implements Action {
    readonly type = ActionTypes.REJECT_SUCCESS;
}

export class RejectFail implements Action {
    readonly type = ActionTypes.REJECT_FAIL;

    constructor(public error: any) { }
}

export class TurnInternalOn implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_ON;

    constructor(public companyId: number, public ids: number[]) { }
}

export class TurnInternalOnSuccess implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_ON_SUCCESS;
}

export class TurnInternalOnFail implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_ON_FAIL;

    constructor(public error: any) { }
}

export class TurnInternalOff implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF;

    constructor(public companyId: number, public ids: number[]) { }
}

export class TurnInternalOffSuccess implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF_SUCCESS;
}

export class TurnInternalOffFail implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF_FAIL;

    constructor(public error: any) { }
}

export class Edit implements Action {
    readonly type = ActionTypes.EDIT;

    constructor(public companyId: number, public ids: number[]) { }
}

export class EditSuccess implements Action {
    readonly type = ActionTypes.EDIT_SUCCESS;
}

export class EditFail implements Action {
    readonly type = ActionTypes.EDIT_FAIL;

    constructor(public error: any) { }
}

export class LinkToCampaign implements Action {
    readonly type = ActionTypes.LINK_TO_CAMPAIGN;

    constructor(public companyId: number, public campaignId: number, public id: number) { }
}

export class LinkToCampaignSuccess implements Action {
    readonly type = ActionTypes.LINK_TO_CAMPAIGN_SUCCESS;
}

export class LinkToCampaignFail implements Action {
    readonly type = ActionTypes.LINK_TO_CAMPAIGN_FAIL;

    constructor(public error: any) { }
}

export class ExportGuestStories implements Action {
    readonly type = ActionTypes.EXPORT_GUEST_STORIES;
  
    constructor(public companyId: number, public queryData: GetTestimonialInputApiModel) {}
  }
  
  export class ExportGuestStoriesSuccess implements Action {
    readonly type = ActionTypes.EXPORT_GUEST_STORIES_SUCCESS;
  
    constructor() {}
  }
  
  export class ExportGuestStoriesFailure implements Action {
    readonly type = ActionTypes.EXPORT_GUEST_STORIES_FAILURE;
  
    constructor(public error: any) {}
  }

export type Actions =
    Load | LoadSuccess | LoadFail |
    MakeActive | MakeActiveSuccess | MakeActiveFail |
    MakeInactive | MakeInactiveSuccess | MakeInactiveFail |
    TurnPublicOn | TurnPublicOnSuccess | TurnPublicOnFail |
    TurnPublicOff | TurnPublicOffSuccess | TurnPublicOffFail |
    Approve | ApproveSuccess | ApproveFail |
    Reject | RejectSuccess | RejectFail |
    TurnInternalOn | TurnInternalOnSuccess | TurnInternalOnFail |
    TurnInternalOff | TurnInternalOffSuccess | TurnInternalOffFail |
    Edit | EditSuccess | EditFail |
    LinkToCampaign | LinkToCampaignSuccess | LinkToCampaignFail;