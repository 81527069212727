import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@framework/services';

@Injectable()
export class AuthPageSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/auth-page/options/`);
    }

    public update(companyId: number, payload: any) {
        return this.patch(`/${companyId}/setting/auth-page/options/`, payload);
    }
}
