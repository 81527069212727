import { StorySettingState, initialState } from '../states/story-setting.state';
import { storySettingActions } from '../actions';
import { QuestionnaireModel, ThankYouPageSettingModel } from '../models';


export function storySettingReducer(state = initialState, action: storySettingActions.Actions): StorySettingState {
    switch (action.type) {

        case storySettingActions.ActionTypes.LOAD_TESTIMONIAL_SETTING_SUCCESS: {
            let questionnaireSettings = [];
            if (action.response.questionnaires) {
                questionnaireSettings = action.response.questionnaires.map(quest => new QuestionnaireModel(quest));
            }
            const thankYouPageSettings = new ThankYouPageSettingModel(action.response.thank_you_page);
            return {
                ...state,
                questionnaireSettings,
                thankYouPageSettings
            };
        }

        case storySettingActions.ActionTypes.LOAD_STORY_SETTING_SUCCESS: {
            return {
                ...state,
                storyPublicEnabled: action.data.story_public_enabled,
            };
        }

        default: {
            return state;
        }
    }
}
