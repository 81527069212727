import { Action } from '@ngrx/store';
import { ResponsePaginationApiModel } from '@framework/models';
import { NudgesSettingOutputApiModel } from '../models/api-models/output';
import { NudgesSettingInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Admin Nudges Setting] Load',
    LOAD_SUCCESS = '[Company Admin Nudges Setting] Load Success',
    LOAD_FAIL = '[Company Admin Nudges Setting] Load Fail',

    ADD = '[Company Admin Nudges Setting] add',
    ADD_SUCCESS = '[Company Admin Nudges Setting] add Success',
    ADD_FAIL = '[Company Admin Nudges Setting] add Fail',

    DELETE = '[Company Admin Nudges Setting] delete',
    DELETE_SUCCESS = '[Company Admin Nudges Setting] delete Success',
    DELETE_FAIL = '[Company Admin Nudges Setting] delete Fail',

    UPDATE = '[Company Admin Nudges Setting] update',
    UPDATE_SUCCESS = '[Company Admin Nudges Setting] update Success',
    UPDATE_FAIL = '[Company Admin Nudges Setting] update Fail',

    UPDATE_COLUMN_ORDER = '[Company Admin Nudges Setting] update column',
    UPDATE_COLUMN_ORDER_SUCCESS = '[Company Admin Nudges Setting] update column Success',
    UPDATE_COLUMN_ORDER_FAIL = '[Company Admin Nudges Setting] update column Fail',

    LOAD_NUDGE_SETTING = '[Company Admin Nudges Setting] load nudge setting',
    LOAD_NUDGE_SETTING_SUCCESS = '[Company Admin Nudges Setting] load nudge setting success',
    LOAD_NUDGE_SETTING_FAIL = '[Company Admin Nudges Setting] load nudge setting fail',


    UPDATE_NUDGE_SETTING = '[Company Admin Nudges Setting] update nudge setting',
    UPDATE_NUDGE_SETTING_SUCCESS = '[Company Admin Nudges Setting] update nudge setting success',
    UPDATE_NUDGE_SETTING_FAIL = '[Company Admin Nudges Setting] update nudge setting fail'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<NudgesSettingOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}



export class LoadNudgeSettings implements Action {
    readonly type = ActionTypes.LOAD_NUDGE_SETTING;

    constructor(public companyId: number) { }
}

export class LoadNudgeSettingsSuccess implements Action {
    readonly type = ActionTypes.LOAD_NUDGE_SETTING_SUCCESS;

    constructor(public response: any) { }
}

export class LoadNudgeSettingsFail implements Action {
    readonly type = ActionTypes.LOAD_NUDGE_SETTING_FAIL;

    constructor(public  error: any) { }
}


export class UpdateNudgeSettings implements Action {
    readonly type = ActionTypes.UPDATE_NUDGE_SETTING;

    constructor(public companyId: number, public payload: any) { }
}

export class UpdateNudgeSettingsSuccess implements Action {
    readonly type = ActionTypes.UPDATE_NUDGE_SETTING_SUCCESS;

    constructor(public response: any) { }
}

export class UpdateNudgeSettingsFail implements Action {
    readonly type = ActionTypes.UPDATE_NUDGE_SETTING_FAIL;

    constructor(public  error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public audienceId: number, public payload: Partial<NudgesSettingInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}


export class UpdateColumn implements Action {
    readonly type = ActionTypes.UPDATE_COLUMN_ORDER;

    constructor(public companyId: number, public updateList) { }
}

export class UpdateColumnSuccess implements Action {
    readonly type = ActionTypes.UPDATE_COLUMN_ORDER_SUCCESS;
}

export class UpdateColumnFailure implements Action {
    readonly type = ActionTypes.UPDATE_COLUMN_ORDER_FAIL;

    constructor(public error: any) { }
}
export class Delete implements Action {
    readonly type = ActionTypes.DELETE;

    constructor(public companyId: number, public audienceId: number) { }
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
}

export class DeleteFailure implements Action {
    readonly type = ActionTypes.DELETE_FAIL;

    constructor(public error: any) { }
}

export class Add implements Action {
    readonly type = ActionTypes.ADD;

    constructor(public companyId: number, public payload: Partial<NudgesSettingInputApiModel>) { }
}

export class AddSuccess implements Action {
    readonly type = ActionTypes.ADD_SUCCESS;
}

export class AddFailure implements Action {
    readonly type = ActionTypes.ADD_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    Update |
    UpdateSuccess |
    UpdateFailure |
    Delete |
    DeleteSuccess |
    DeleteFailure |
    Add |
    AddSuccess |
    AddFailure |
    UpdateColumn |
    UpdateColumnSuccess |
    UpdateColumnFailure
    ;