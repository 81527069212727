import { CompanySettingState, initialCompanySettingState } from '../states';
import { companySettingActions } from '../actions';
import { WorkAttributeStoreModel } from '../models';


export function companySettingReducer(state = initialCompanySettingState, action: companySettingActions.Actions): CompanySettingState {
    switch (action.type) {

        case companySettingActions.ActionTypes.LOAD_WORK_ATTRIBUTES_SUCCESS: {
            const workAttibutes = action.response && action.response.map(m => {
                return new WorkAttributeStoreModel(m);
            })
            return {
                ...state,
                workAttibutes: workAttibutes || []
            }
        }

        default: {
            return state;
        }
    }
}