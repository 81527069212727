import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'skeleton-story-list',
  templateUrl: './skeleton-story-list.component.html',
  styleUrls: ['./skeleton-story-list.component.scss']
})
export class SkeletonStoryListComponent implements OnInit {

  @Input() countItem: number = 8;
  @Input() cssClassItem: string = '';
  listItem: number[] = [];

  constructor() { }

  ngOnInit() {
    this.renderItem();
  }

  private renderItem() {
    for (let i = 0; i < this.countItem; i++) {
      this.listItem.push(i);
    }
  }

}
