import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[autoFocusNexInput]',
})
export class AutoFocusNextInputDirective implements OnInit {
    @Input() maxLength: number = 1;
    @Input() value: string;
    @Input() nextFocusElement: any;

    constructor(private el: ElementRef) {

    }

    public ngOnInit() {
        this.el.nativeElement.onkeyup = (event: KeyboardEvent) => {
            const keyCode = event.keyCode || event.which;
            if (this.value && this.value.toString().length >= this.maxLength && this.nextFocusElement && keyCode !== 16 && keyCode !== 9) {
                this.nextFocusElement.focus();
                this.nextFocusElement.select();
            }
        };
    }
}