import { CtaTemplateSelectValueViewModel } from ".";

export interface SelectReplacementForCtaInputViewModel {
    selectList: CtaTemplateSelectValueViewModel[],
    stepId: number,
    companyId: number,
    inbound: boolean
}

export class SelectReplacementForCtaInputViewModel {
    constructor() {
        this.selectList = [];
    }
}