import { PaginationModel } from '@framework/models/pagination-models';

export interface LoadNotificationInputApiModel {
    userId: number,
    selectedValues: number[],
    pagination: PaginationModel
}

export class LoadNotificationInputApiModel {
    constructor(public pagination: PaginationModel) {
        this.pagination = new PaginationModel(12);
    }

    private buildQuery() {
        let query = '';
        if (this.selectedValues && this.selectedValues.length > 0) {
            query += `selected=${this.selectedValues.join(',')}&`;
        }
        query += this.pagination.query;

        return query;
    }

    public  get queryBuilder() {
        return this.buildQuery();
    }
}