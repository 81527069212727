export interface UserOutputApiModel {
    id: number;
    name: string;
    slug: string;
    email: string;
    author_count: number;
    contributor_count: number;
    joined: Date;
    registered: string;
    access: boolean;
    admin: boolean;
    status: string;
    user_role: string;
    draft_count: any;
    display_title?:any;
    profile_title?:any
}

export class UserOutputApiModel {
    constructor(data?: Partial<UserOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}


