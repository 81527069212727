import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions,createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { ScriptActions } from '../actions';
// import { AddUserHttpService, } from '../services';
import { ScriptHttpservice } from '../services/script.https-service';

@Injectable()
export class ScriptEffect {


  
  loadLinkScripts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ScriptActions.Load>(ScriptActions.ActionTypes.LOAD),
    switchMap((action: ScriptActions.Load) => {
      return this.ScriptHttpservice.load(action.companyId).pipe(
        map((respone) => {
          return new ScriptActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new ScriptActions.LoadFail(error));
        }));
    })
  ));


  
  addSCripts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ScriptActions.Add>(ScriptActions.ActionTypes.ADD),
    switchMap((action: ScriptActions.Add) => {
      return this.ScriptHttpservice.add(action.companyId,action.payload).pipe(
        map((respone) => {
          return new ScriptActions.AddSuccess();
        }),
        catchError((error: any) => {
          return of(new ScriptActions.AddFail());
        }));
    })
  ));



  
  UpdateScripts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ScriptActions.Edit>(ScriptActions.ActionTypes.EDIT),
    switchMap((action: ScriptActions.Edit) => {
      return this.ScriptHttpservice.edit(action.companyId,action.scriptid,action.payload).pipe(
        map((respone) => {
          return new ScriptActions.EditSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new ScriptActions.EditFail(error));
        }));
    })
  ));



  
  deleteScripts$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ScriptActions.Delete>(ScriptActions.ActionTypes.DELETE),
    switchMap((action: ScriptActions.Delete) => {
      return this.ScriptHttpservice.remove(action.companyId,action.scriptid).pipe(
        map((respone) => {
          return new ScriptActions.DeleteSuccess();
        }),
        catchError((error: any) => {
          return of(new ScriptActions.DeleteFail());
        }));
    })
  ));

    constructor(
        private actions$: Actions,
        private ScriptHttpservice: ScriptHttpservice) { }

}