export interface UserNotificationsOutputApiModel {
  company_story_publish_email_enabled: boolean;
  my_story_comment_email_enabled: boolean;
  my_story_comment_like_email_enabled: boolean;
  my_story_like_email_enabled: boolean;
  my_story_view_email_enabled: boolean;
  my_story_contribution_email_enabled: boolean;
}

export class UserNotificationsOutputApiModel {
  constructor(init?: UserNotificationsOutputApiModel) {
    Object.assign(this, init);
  }
}
