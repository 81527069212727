import { CognitoUserPool, ICognitoStorage } from 'amazon-cognito-identity-js';
import { GsCognitoUser } from './gs-cognito-user';
import { CognitoHelper } from './cognito.helper';

export class GsCognitoUserPool extends CognitoUserPool {

    /**
     * Constructs a new CognitoUserPool object
     * @param {object} data Creation options.
     * @param {string} data.UserPoolId Cognito user pool id.
     * @param {string} data.ClientId User pool application client id.
     * @param {object} data.Storage Optional storage object.
     * @param {boolean} data.AdvancedSecurityDataCollectionFlag Optional:
     *        boolean flag indicating if the data collection is enabled
     *        to support cognito advanced security features. By default, this
     *        flag is set to true.
     */
    constructor(data) {
        super(data);
    }

    /**
     * method for getting the current user of the application from the local storage
     *
     * @returns {CognitoUser} the user retrieved from storage
     */
    getCurrentUser() {
        const lastUserKey = `CognitoIdentityServiceProvider.${this['clientId']}.LastAuthUser`;

        const lastAuthUser = this['storage'].getItem(lastUserKey);
        if (lastAuthUser) {
            const cognitoUser = {
                Username: lastAuthUser,
                Pool: this,
                Storage: this['storage'],
            };

            return new GsCognitoUser(cognitoUser);
        }

        return null;
    }

    /**
   * @typedef {object} SignUpResult
   * @property {CognitoUser} user New user.
   * @property {bool} userConfirmed If the user is already confirmed.
   */
    /**
     * method for signing up a user
     * @param {string} username User's username.
     * @param {string} password Plain-text initial password entered by user.
     * @param {(AttributeArg[])=} userAttributes New user attributes.
     * @param {(AttributeArg[])=} validationData Application metadata.
     * @param {nodeCallback<SignUpResult>} callback Called on error or with the new user.
     * @returns {void}
     */
    signUp(username, password, userAttributes, validationData, callback) {
        const jsonReq: any = {
            ClientId: this['clientId'],
            Username: username,
            Password: password,
            UserAttributes: userAttributes,
            ValidationData: validationData,
        };
        if (this['getUserContextData'](username)) {
            jsonReq.UserContextData = this['getUserContextData'](username);
        }
        this['client'].request('SignUp', jsonReq, (err, data) => {
            if (err) {
                return callback(err, null);
            }

            const cognitoUser = {
                Username: username,
                Pool: this,
                Storage: this['storage'],
            };

            const returnData = {
                user: new GsCognitoUser(cognitoUser),
                userConfirmed: data.UserConfirmed,
                userSub: data.UserSub,
                codeDeliveryDetails: data.CodeDeliveryDetails,
            };

            return callback(null, returnData);
        });
    }

    /**
     * method for change storage
     * @param storage 
     */
    changeStorage(storage: ICognitoStorage) {
        this['storage'] = storage;
    }
}