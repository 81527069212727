export class StoryEditEntity {
    public id: number = 0;
    public title: string = '';
    public slug: string = '';
    public content: string = '';
    public thumbnail: string = '';
    public cta_text: string = '';
    public cta_button: string = '';
    public company_id: number = null;
    public completed: boolean = false;
    public public: boolean = false;
    public time_created: any = '';
    public created_by: number = null;
    public modified_at: any = '';
    public modified_by: number = null;
    public published_at: any = '';
    public published_by: number = null;
    public steps: any = null;
    public archived: boolean = false;

    constructor(init?: Partial<StoryEditEntity>) {
        Object.assign(this, init);
    }
}