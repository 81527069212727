import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gs-select-material',
  templateUrl: './gs-select-material.component.html',
  styleUrls: ['./gs-select-material.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsSelectMaterialComponent),
      multi: true
    }
  ]
})
export class GsSelectMaterialComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @Input() label: string;
  @Input() labelSmall: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() type: string;
  @Input() cssClass: string;
  @Input() hideErrorMessage: boolean = false;
  @Input() data: any[] = [];
  @Input() valueField: string;
  @Input() displayField: string;

  @Output() blur: EventEmitter<string> = new EventEmitter<string>();

  @Output() change: EventEmitter<any> = new EventEmitter<any>()


  private inputValue: string;

  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges() {

  }

  private triggerChange($event) {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  // Public methods

  public set value(val: string) {
    if (val !== undefined) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public writePlaceholder(val: string): void {
    this.placeholder = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange() {
    this.triggerChange(this.inputValue);
    this.change.emit(this.inputValue);
  }
}
