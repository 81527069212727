import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetUserInputApiModel, SendEmailInputApiModel } from '../models/api-models/input';
import { UserOutputApiModel } from '../models/api-models/output';
import { CreateUserInputApiModel, CreateUserCSVInputApiModel } from '@modules/company-admin/store/models/api-models/input';
import { Observable, of } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class UserHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient, private fileSaverService: FileSaverService) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetUserInputApiModel) {
        let requestQuery = queryData.query;
        if (queryData.showRequests) {
            requestQuery = requestQuery.replace('a=0', 'g=1');
        }
        return this.get(`/${companyId}/setting/users/?${requestQuery}`).pipe(
            map((response: ResponsePaginationApiModel<UserOutputApiModel>) => {
                return response;
            })
        );
    }

    public create(companyId: number, payload: CreateUserInputApiModel) {
        return this.post(`/${companyId}/setting/users/add/`, payload).pipe(

            map((response: any) => {
                return response;
            })
        );
    }
    public createCSV(companyId: number, payload: CreateUserCSVInputApiModel) {
        return this.post(`/${companyId}/setting/user/upload`, payload).pipe(

            map((response: any) => {
                return response;
            })
        );
    }

    public sendEmail(companyId: number, data: SendEmailInputApiModel) {
        return this.post(`/${companyId}/setting/users/send-mail/`, data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public enableAccess(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/users/access/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public disableAccess(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/users/access/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }



    public makeAdmin(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/users/admin/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    public changeUserRoles(companyId: number, ids: number[],roleId) {
        return this.post(`/${companyId}/setting/users/admin-roles/?ids=${ids.join(',')}&rid=${roleId}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeRegular(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/users/admin/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public exportUsers(companyId: number, userInputApiModel: GetUserInputApiModel): Observable<void> {
        const queryString = `${userInputApiModel.query}&format=csv`;
    
        return this.get(`/${companyId}/setting/users/?${queryString}`, { 
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentType = response.headers.get('content-type');
                const blob = new Blob([response.body], { type: contentType || 'text/csv' });
                this.fileSaverService.save(blob, 'users.csv', 'text/csv');
                return undefined;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.text) {
                    const blob = new Blob([error.error.text], { type: 'text/csv' });
                    this.fileSaverService.save(blob, 'users.csv', 'text/csv');
                    return of(undefined);
                }
                throw error;
            })
        );
    }

    public exportGuestUsers(companyId: number, userInputApiModel: GetUserInputApiModel): Observable<void> {
        const queryString = `${userInputApiModel.query}&format=csv`;
    
        return this.get(`/${companyId}/setting/users/?g=1&${queryString}`, { 
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentType = response.headers.get('content-type');
                const blob = new Blob([response.body], { type: contentType || 'text/csv' });
                this.fileSaverService.save(blob, 'guest_users.csv', 'text/csv');
                return undefined;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.text) {
                    const blob = new Blob([error.error.text], { type: 'text/csv' });
                    this.fileSaverService.save(blob, 'guest_users.csv', 'text/csv');
                    return of(undefined);
                }
                throw error;
            })
        );
    }
}
