import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseHttpService } from '@framework/services';
import { Injectable } from '@angular/core';

@Injectable()
export class GetUniquelinkServiceForspotlightandCampaign extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/stories/');
    }

    public getuniqueLinkSpotlight(payload,fromSpotlight){

        if(fromSpotlight){
            return this.post(`unique/spotlight-token/
            `,payload).pipe(map((response: any) => {
                return response;
            }));
        }

        else{
            return this.post(`unique/campaign-token/
            `,payload).pipe(map((response: any) => {
                return response;
            })); 
        }

    }
}