import { Actions,createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { storyStepActions } from '../actions';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { TagService, CompanyHttpService } from '@modules/story/services';
import { StepOutputApiModel } from '@app/store/http/models/output';
import { CreateTagOutputApiModel } from '@modules/story/models/api-models/output';


@Injectable()
export class StoryStepEffect {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.Load>(storyStepActions.ActionTypes.LOAD),
    switchMap((action: storyStepActions.Load) => {
      return this.companyHttpService.getSteps(action.companyId, action.inbound, action.tagSelected, action.activeOnly).pipe(
        map((data: Array<StepOutputApiModel>) => {
          return new storyStepActions.LoadSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.LoadFailure(error));
        }))
    })
  ));

  
  loadQuestionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.LoadQuestions>(storyStepActions.ActionTypes.LOAD_QUESTIONS),
    switchMap((action: storyStepActions.LoadQuestions) => {
      return this.companyHttpService.getQuestionsList(action.companyId).pipe(
        map((data: any) => {
          return new storyStepActions.LoadQuestionsSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.LoadFailure(error));
        }))
    })
  ));
  
  addQuestionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.CreateQuestion>(storyStepActions.ActionTypes.CREATE_QUESTION),
    switchMap((action: storyStepActions.CreateQuestion) => {
      return this.companyHttpService.addQuestionsList(action.companyId, action.payload).pipe(
        map((data: any) => {
          return new storyStepActions.CreateQuestionSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.CreateQuestionFailure(error));
        }))
    })
  ));
  
  updateQuestionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.UpdateQuestion>(storyStepActions.ActionTypes.UPDATE_QUESTION),
    switchMap((action: storyStepActions.UpdateQuestion) => {
      return this.companyHttpService.editQuestionsList(action.companyId, action.questionId, action.payload).pipe(
        map((data: any) => {
          return new storyStepActions.UpdateQuestionSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.UpdateQuestionFailure(error));
        }))
    })
  ));
  
  deleteQuestionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.DeleteQuestion>(storyStepActions.ActionTypes.DELETE_QUESTION),
    switchMap((action: storyStepActions.DeleteQuestion) => {
      return this.companyHttpService.deleteQuestionsList(action.companyId, action.questionId).pipe(
        map((data: any) => {
          return new storyStepActions.DeleteQuestionSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.DeleteQuestionFailure(error));
        }))
    })
  ));


  
  loadNotesEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.LoadNote>(storyStepActions.ActionTypes.LOAD_NOTE),
    switchMap((action: storyStepActions.LoadNote) => {
      return this.companyHttpService.getNotes(action.companyId,action.storyId).pipe(
        map((data: any) => {
          return new storyStepActions.LoadNoteSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.LoadNoteFailure(error));
        }))
    })
  ));
  
  addNoteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.CreateNote>(storyStepActions.ActionTypes.CREATE_NOTE),
    switchMap((action: storyStepActions.CreateNote) => {
      return this.companyHttpService.addNoteList(action.companyId, action.payload).pipe(
        map((data: Array<any>) => {
          return new storyStepActions.CreateNoteSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.CreateNoteFailure(error));
        }))
    })
  ));
  
  updateNoteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.UpdatNote>(storyStepActions.ActionTypes.UPDATE_NOTE),
    switchMap((action: storyStepActions.UpdatNote) => {
      return this.companyHttpService.editNotesList(action.companyId, action.noteId, action.payload).pipe(
        map((data: any) => {
          return new storyStepActions.UpdatNoteSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.UpdatNoteFailure(error));
        }))
    })
  ));
  
  deleteNoteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.DeletNote>(storyStepActions.ActionTypes.DELETE_NOTE),
    switchMap((action: storyStepActions.DeletNote) => {
      return this.companyHttpService.deleteNoteList(action.companyId, action.noteId).pipe(
        map((data: any) => {
          return new storyStepActions.DeletNoteSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.DeletNoteFailure(error));
        }))
    })
  ));

  
  loadPlayerStepEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.LoadPlayerStep>(storyStepActions.ActionTypes.LOAD_PLAYER_STEP),
    switchMap((action: storyStepActions.LoadPlayerStep) => {
      return this.companyHttpService.getSteps(action.companyId, action.inbound, action.tagSelected, action.activeOnly).pipe(
        map((data: Array<StepOutputApiModel>) => {
          return new storyStepActions.LoadPlayerStepSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.LoadPlayerStepFailure(error));
        }))
    })
  ));

  
  createTagEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.CreateTag>(storyStepActions.ActionTypes.CREATE_TAG),
    switchMap((action: storyStepActions.CreateTag) => {
      return this.tagService.createTag(action.payload).pipe(
        map((data: CreateTagOutputApiModel) => {
          return new storyStepActions.CreateTagSuccess(action.stepId, data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.CreateTagFailure(error));
        }))
    })
  ));

  
  loadContributionStepEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.LoadContributionStep>(storyStepActions.ActionTypes.LOAD_CONTRIBUTION_STEP),
    switchMap((action: storyStepActions.LoadContributionStep) => {
      return this.companyHttpService.getContributionStep(action.companyId).pipe(
        map((data: StepOutputApiModel[]) => {
          return new storyStepActions.LoadContributionStepSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyStepActions.LoadContributionStepFailure(error));
        })
      );
    })
  ));

  
  updateStepTagEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.UpdateStepTag>(storyStepActions.ActionTypes.UPDATE_STEP_TAG_SETTING),
    mergeMap((action: storyStepActions.UpdateStepTag) => {
      return this.companyHttpService.updateStepTagSetting(action.companyId, action.id, action.isInbound, action.isStep, action.payload).pipe(
        map(() => {
          return new storyStepActions.UpdateStepTagSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.UpdateStepTagFailure(error));
        }))
    })
  ));

  
  createStepTagEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.CreateTagSetting>(storyStepActions.ActionTypes.CREATE_STEP_TAG_SETTING),
    switchMap((action: storyStepActions.CreateTagSetting) => {
      return this.companyHttpService.createStepTagSetting(action.companyId, action.stepId, action.isInbound, action.isStep, action.payload).pipe(
        map(() => {
          return new storyStepActions.CreateTagSettingSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.CreateTagSettingFailure(error));
        }))
    })
  ));

  
  deleteStepTagEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyStepActions.DeleteTagSetting>(storyStepActions.ActionTypes.DELETE_STEP_TAG_SETTING),
    switchMap((action: storyStepActions.DeleteTagSetting) => {
      return this.companyHttpService.deleteStepTagSetting(action.companyId, action.id, action.isInbound, action.isStep).pipe(
        map(() => {
          return new storyStepActions.DeleteTagSettingSuccess();
        }),
        catchError((error: any) => {
          return of(new storyStepActions.DeleteTagSettingFailure(error));
        }))
    })
  ));

  constructor(
    private actions$: Actions,
    private tagService: TagService,
    private companyHttpService: CompanyHttpService
  ) { }

}