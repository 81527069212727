import { UserEntity } from "../entities";

export interface UserViewModel {
    id: number | string;
    firstname: string;
    lastname: string;
    title: string;
    email: string;
    avatar: string;
    displayName: string;
    checked: boolean;
    storyInviteEnabled:boolean;
}

export class UserViewModel {
    constructor(userEntity: UserEntity) {
        if (userEntity)
            Object.assign(this, userEntity);
    }

    get fullname() {
        return `${this.firstname} ${this.lastname}`;
    }
}