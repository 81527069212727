import { Component, SimpleChanges, Inject, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

import { ComponentModalBase } from '@framework/base/component';
import { SocialSharingService } from '@framework/services';
import { Observable, Subscription } from "rxjs";
import { userProfileSelectors } from "@modules/user/store/selectors";
import { Store } from "@ngrx/store";
import { CoreState } from "@app/store/core/states";
import { UserProfileViewModel } from "@modules/user/store/models";
import { environment } from '@app/env/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'co-embed-modal-container',
  templateUrl: './embed-modal-container.component.html',
  styleUrls: ['./embed-modal-container.component.scss']
})
export class EmbedModalContainerComponent extends ComponentModalBase<string> {
  @ViewChild("addthis") addthis: ElementRef;

  // Private variables
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentUserProfileSubscription: Subscription;

  // Public varibales
  public currentUserProfile: UserProfileViewModel;

  // Private variables
  private shareUrl: string;
  // Public variables
  public owlOptions: any = {
    dots: false,
    nav: true,
    margin: 16,
    autoWidth: true,
  };
  public shareForm: UntypedFormGroup;

  constructor(private router: Router, private store$: Store<CoreState>, private socialSharingService: SocialSharingService, private formBuilder: UntypedFormBuilder) {
    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.initForm();
  }

  // Life cycle hook
  protected onInit(): void {
    if (!_.isEmpty(this.initialState)) {
      this.shareUrl = this.initialState;
    } else {
      this.shareUrl = environment.baseUrl + this.router.url;
    }

    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
    // if(typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.pipe(
      take(1)
    ).subscribe(userProfile => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.shareUrl += '?sb=' + this.currentUserProfile.id;
      }
      // this.addthis.nativeElement.setAttribute("data-url", this.shareUrl);
      if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
      this.shareForm.controls.link.setValue(this.shareUrl);
    });
  }

  private unsubscribe() {
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }

  private initForm() {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl]
    });
  }

  // Public functions
  public close() {
    this.onClose.emit();
  }
}
