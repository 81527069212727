import { Component, SimpleChanges } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@app/env/environment';

import { Page } from '@framework/base';
import { CoreState } from '@app/store/core/states';
import { UserProfileViewModel, UserBadgeViewModel, UserStoryStatisticsViewModel, UserStoryFrequentTagsViewModel } from '../../store/models';
import { userProfileSelectors } from '../../store/selectors';
import { userProfileActions } from '../../store/actions';
import { MetaTagConfig } from '@modules/shared/models/common/seo.model';
import { SeoService } from '@modules/shared/services/seo.service';

@Component({
  selector: 'app-public-profile',
  templateUrl: './public-profile.page.html',
  styleUrls: ['./public-profile.page.scss']
})
export class PublicProfilePage extends Page {

  // Private variables
  private selectSelectedProfile$: Observable<UserProfileViewModel>;
  private baseUrl: string = environment.baseUrl;

  private selectSelectedProfileSubscription: Subscription;
  private loadUserProfileFailureSubscription: Subscription;
  // Public variables
  public profile: UserProfileViewModel;
  public userBadge: UserBadgeViewModel;
  public storyStatistics: UserStoryStatisticsViewModel;
  public storyFrequentTags: UserStoryFrequentTagsViewModel[];

  constructor(
    private store$: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actions$: Actions,
    private seoService: SeoService
  ) {
    super();
    this.captureParams();

    this.selectSelectedProfile$ = this.store$.select(userProfileSelectors.selectSelectedProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }

  protected onDestroy(): void {
    this.unsubscribe();
    this.seoService.clearMetaTags();
  }

  protected onChanges(changes: SimpleChanges): void {

  }

  protected onDoCheck(): void {

  }

  protected onAfterContentInit(): void {

  }

  protected onAfterContentChecked(): void {

  }

  protected onAfterViewInit(): void {

  }

  protected onAfterViewChecked(): void {

  }

  /**
   * this method is to update meta tag according to profile detail
   */
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
    config.title = `${this.profile.displayName} - ${this.profile.title} - ${this.profile.company.name}`;
    config.url = `${environment.baseUrl}${this.router.url}`;
    config.image = this.profile.avatar;
    config.description = this.profile.workPurpose;
    config.keywords = this.getSeoKeywords();
    this.seoService.updateMetaTags(config);
  }

  // Private functions
  private subscribe() {
    this.selectSelectedProfileSubscription = this.selectSelectedProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.profile = userProfile;
        this.addMetaTags();
      }
    });

    this.loadUserProfileFailureSubscription = this.actions$.pipe(
      ofType<userProfileActions.LoadPublicProfileFailure>(userProfileActions.ActionTypes.LOAD_PUBLIC_PROFILE_FAILURE)
    ).subscribe(() => {
      this.router.navigate(['/common/404']);
    });
  }

  private unsubscribe() {
    this.selectSelectedProfileSubscription.unsubscribe();
    this.loadUserProfileFailureSubscription.unsubscribe();
  }

  private captureParams() {
    const userSlug = this.activatedRoute.snapshot.params.slug;
    this.store$.dispatch(new userProfileActions.LoadPublicProfile(userSlug));
  }

  private getSeoKeywords(): string {
    let s = this.profile.displayName;
    if(this.profile.company.name) s += ', ' + this.profile.company.name;
    if(this.profile.storyFrequentTags) {
      for( let item of this.profile.storyFrequentTags ) {
        for( let tag of item.data ) {
          s += ', ' + tag;
        }
      }
    }
    return s;
  }
  // Public functions

}
