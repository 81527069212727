import { Component, SimpleChanges, Inject, ViewChild, ElementRef } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentModalBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { companySelectors } from '@modules/company/store/selectors';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { storySelector } from '@modules/story/store/selectors';
import { StoryViewModel } from '@modules/story/models/view-models';

@Component({
  selector: 'app-pb-embed-iframe-container',
  templateUrl: './embed-iframe-container.component.html',
  styleUrls: ['./embed-iframe-container.component.css']
})
export class EmbedIframeContainerComponent extends ComponentModalBase<null> {
  @ViewChild('embedInput')
  embedInput: ElementRef;

  // Private variables
  private selectCompany$: Observable<CompanyViewModel>;
  private selectStoryDetail$: Observable<StoryViewModel>;

  private selectCompanySubscription: Subscription;
  // Public variables
  public embedCode: string = '';
  public currentCompany: CompanyViewModel;
  public storyDetail: StoryViewModel;
  public isCopied: boolean = false;

  constructor(private store$: Store<CoreState>) {
    super();

    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.
      pipe(
        withLatestFrom(
          this.selectStoryDetail$
        )
      )
      .subscribe(([company, storyDetail]) => {
        if (company && storyDetail) {
          this.currentCompany = company;
          this.storyDetail = storyDetail;
          this.buildEmbed();
        }
      });
  }

  private unsubscribe() {
    this.selectCompanySubscription.unsubscribe();
  }

  private buildEmbed() {
    this.embedCode = `<iframe style="display:block;margin: auto;width: 95%;max-width: 1200px;height: 800px;border: 5px solid #eee;" src="${window.location.origin}/c/${this.currentCompany.slug}/${this.storyDetail.slug}?m=0"></iframe>`;
  }
  // Public functions
  public copy() {
    this.embedInput.nativeElement.select();
    window.document.execCommand("copy");
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }
}
