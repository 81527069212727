import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import * as helpers from './utilities';
import { StorageProvider } from './utilities/storage/providers';
import * as services from './services';
import * as interceptors from './interceptors';
import { SrollbarDirective } from './directives';
import * as components from './components';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AuthorizedGuard } from './guards';
import { AppVersionInterceptor } from './interceptors/app-version.interceptor';
import { AdminAccessGuard } from './guards/adminAccess.guard';

@NgModule({
	declarations: [
		SrollbarDirective,

		components.ScrollbarComponent,
		components.LoadingSpinnerComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		PerfectScrollbarModule,
		ToastrModule.forRoot({
			maxOpened: 1,
			autoDismiss: true
		}),
		NgxUiLoaderModule.forRoot({ fgsType: SPINNER.threeBounce }),
		ModalModule.forRoot()
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		NgxUiLoaderModule,

		SrollbarDirective,

		components.ScrollbarComponent,
		components.LoadingSpinnerComponent
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: interceptors.HttpErrorInterceptor,
			multi: true,
			deps: [services.ErrorHandlerService, services.ToastService]
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AppVersionInterceptor,
			multi: true
		},

		CookieService,

		services.ToastService,
		services.ModalService,
		services.UploaderService,
		services.ImageEditorService,
		services.AuthService,
		services.LoadingPopupService,
		services.SocialSharingService,
		services.BackDropService,
		services.ErrorHandlerService,

		StorageProvider,
		helpers.FileHelper,
		helpers.FormHelper,
		helpers.MomentHelper,
		helpers.GuidHelper,
		helpers.JqueryHelper,
		helpers.NumberHelper,
		helpers.TokenHelper,
		helpers.MimeTypesHelper,
		helpers.UtilityHelper,
		helpers.StringHelper,
		helpers.EncodeHelper,

		AuthorizedGuard,
		AdminAccessGuard
	]
})
export class FrameworkModule {

}
