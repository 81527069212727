import { Action } from '@ngrx/store';

import { CreateTagOutputApiModel } from '@modules/story/models/api-models/output';
import { CreateTagInputApiModel } from '@modules/story/models/api-models/input';
import { StepOutputApiModel } from '@app/store/http/models/output';
import { UpdateStepTagSettingInputApiModel } from '@modules/company-admin/store/models/api-models/input';

export enum ActionTypes {

  LOAD = "[Story-Step] Load",
  LOAD_SUCCESS = "[Story-Step] Load Success",
  LOAD_FAILURE = "[Story-Step] Load Failure",

  LOAD_QUESTIONS = "[Story-Step] Load Questions",
  LOAD_QUESTIONS_SUCCESS = "[Story-Step] Load Questions Success",
  LOAD_QUESTIONS_FAILURE = "[Story-Step] LLoad Questions Failure",

  LOAD_NOTE = "[Story-Step] Load Note",
  LOAD_NOTE_SUCCESS = "[Story-Step] Load Note Success",
  LOAD_NOTE_FAILURE = "[Story-Step] Load Note Failure",

  LOAD_PLAYER_STEP = "[Story-Step] Load Player Step",
  LOAD_PLAYER_STEP_SUCCESS = "[Story-Step] Load Player Step Success",
  LOAD_PLAYER_STEP_FAILURE = "[Story-Step] Load Player Step Failure",

  CREATE_TAG = "[Story-Step] Create Tag",
  CREATE_TAG_SUCCESS = "[Story-Step] Create Tag Success",
  CREATE_TAG_FAILURE = "[Story-Step] Create Tag Failure",

  CREATE_QUESTION = "[Story-Step-Questions] Create Question",
  CREATE_QUESTION_SUCCESS = "[Story-Step-Questions] Create Question Success",
  CREATE_QUESTION_FAILURE = "[Story-Step-Questions] Create Question Failure",

  CREATE_NOTE = "[Story-Step-Questions] Create note",
  CREATE_NOTE_SUCCESS = "[Story-Step-Questions] Create note Success",
  CREATE_NOTE_FAILURE = "[Story-Step-Questions] Create note Failure",


  SELECT_STEP_SETTING = "[Story-Step setting] Select step",

  LOAD_CONTRIBUTION_STEP = "[Story-Step] Load Contribution Step",
  LOAD_CONTRIBUTION_STEP_SUCCESS = "[Story-Step] Load Contribution Step Success",
  LOAD_CONTRIBUTION_STEP_FAILURE = "[Story-Step] Load Contribution Step Failure",

  UPDATE_STEP_TAG_SETTING = '[Company Admin Story-Step Setting] update step or tag',
  UPDATE_STEP_TAG_SETTING_SUCCESS = '[Company Admin Story-Step Setting] update step or tag Success',
  UPDATE_STEP_TAG_SETTING_FAIL = '[Company Admin Story-Step Setting] update step or tag Fail',

  UPDATE_QUESTION = '[Story-Step-Questions] update  note',
  UPDATE_QUESTION_SUCCESS = '[Story-Step-Questions] update  note Success',
  UPDATE_QUESTION_FAIL = '[Story-Step-Questions] update  note Fail',

  UPDATE_NOTE = '[Story-Step-Questions] update Questions',
  UPDATE_NOTE_SUCCESS = '[Story-Step-Questions] update Questions Success',
  UPDATE_NOTE_FAIL = '[Story-Step-Questions] update Questions Fail',


  CREATE_STEP_TAG_SETTING = '[Company Admin Story-Step Setting] create step or tag',
  CREATE_STEP_TAG_SETTING_SUCCESS = '[Company Admin Story-Step Setting] create step or tag Success',
  CREATE_STEP_TAG_SETTING_FAIL = '[Company Admin Story-Step Setting] create step or tag Fail',

  DELETE_STEP_TAG_SETTING = '[Company Admin Story-Step Setting] delete step or tag',
  DELETE_STEP_TAG_SETTING_SUCCESS = '[Company Admin Story-Step Setting] delete step or tag Success',
  DELETE_STEP_TAG_SETTING_FAIL = '[Company Admin Story-Step Setting] delete step or tag Fail',

  DELETE_QUESTION = '[Story-Step-Questions] delete Question',
  DELETE_QUESTION_SUCCESS = '[Story-Step-Questions] delete Question Success',
  DELETE_QUESTION_FAIL = '[Story-Step-Questions] delete Question fail',

  DELETE_NOTE = '[Story-Step-Questions] delete note',
  DELETE_NOTE_SUCCESS = '[Story-Step-Questions] delete note Success',
  DELETE_NOTE_FAIL = '[Story-Step-Questions] delete note fail',

  RESET_STATE = '[Story-Step] Reset State'
}

export class SelectStepSetting implements Action {
  readonly type = ActionTypes.SELECT_STEP_SETTING;
  constructor(public stepId: number) { }
}

export class Load implements Action {
  readonly type = ActionTypes.LOAD;
  constructor(public companyId: number, public inbound?: boolean, public tagSelected?: string, public activeOnly?: boolean) { };
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public data: Array<StepOutputApiModel>) {
  }
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public error: any) { }
}

export class LoadQuestions implements Action {
  readonly type = ActionTypes.LOAD_QUESTIONS;
  constructor(public companyId: number) { };
}

export class LoadQuestionsSuccess implements Action {
  readonly type = ActionTypes.LOAD_QUESTIONS_SUCCESS;
  constructor(public data: any) {
  }
}

export class LoadQuestionsFailure implements Action {
  readonly type = ActionTypes.LOAD_QUESTIONS_FAILURE;
  constructor(public error: any) { }
}

export class LoadNote implements Action {
  readonly type = ActionTypes.LOAD_NOTE;
  constructor(public companyId: number,public storyId) { };
}

export class LoadNoteSuccess implements Action {
  readonly type = ActionTypes.LOAD_NOTE_SUCCESS;
  constructor(public data: any) {
  }
}

export class LoadNoteFailure implements Action {
  readonly type = ActionTypes.LOAD_NOTE_FAILURE;
  constructor(public error: any) { }
}

export class LoadPlayerStep implements Action {
  readonly type = ActionTypes.LOAD_PLAYER_STEP;
  constructor(public companyId: number, public inbound?: boolean, public tagSelected?: string, public activeOnly?: boolean) { };
}

export class LoadPlayerStepSuccess implements Action {
  readonly type = ActionTypes.LOAD_PLAYER_STEP_SUCCESS;
  constructor(public data: Array<StepOutputApiModel>) {
  }
}

export class LoadPlayerStepFailure implements Action {
  readonly type = ActionTypes.LOAD_PLAYER_STEP_FAILURE;
  constructor(public error: any) { }
}

export class CreateTag implements Action {
  readonly type = ActionTypes.CREATE_TAG;
  constructor(public stepId: number, public payload: CreateTagInputApiModel) { }
}

export class CreateTagSuccess implements Action {
  readonly type = ActionTypes.CREATE_TAG_SUCCESS;
  constructor(public stepId: number, public data: CreateTagOutputApiModel) { }
}

export class CreateTagFailure implements Action {
  readonly type = ActionTypes.CREATE_TAG_FAILURE;
  constructor(public error: any) { }
}

export class CreateQuestion implements Action {
  readonly type = ActionTypes.CREATE_QUESTION;
  constructor(public companyId: number, public payload: any) { }
}

export class CreateQuestionSuccess implements Action {
  readonly type = ActionTypes.CREATE_QUESTION_SUCCESS;
  constructor(public response: any) { }
}

export class CreateQuestionFailure implements Action {
  readonly type = ActionTypes.CREATE_QUESTION_FAILURE;
  constructor(public error: any) { }
}

export class CreateNote implements Action {
  readonly type = ActionTypes.CREATE_NOTE;
  constructor(public companyId: number, public payload: any) { }
}

export class CreateNoteSuccess implements Action {
  readonly type = ActionTypes.CREATE_NOTE_SUCCESS;
}

export class CreateNoteFailure implements Action {
  readonly type = ActionTypes.CREATE_NOTE_FAILURE;
  constructor(public error: any) { }
}

export class LoadContributionStep implements Action {
  readonly type = ActionTypes.LOAD_CONTRIBUTION_STEP;
  constructor(public companyId: number) { }
}

export class LoadContributionStepSuccess implements Action {
  readonly type = ActionTypes.LOAD_CONTRIBUTION_STEP_SUCCESS;
  constructor(public reponse: StepOutputApiModel[]) { }
}

export class LoadContributionStepFailure implements Action {
  readonly type = ActionTypes.LOAD_CONTRIBUTION_STEP_FAILURE;
  constructor(public error: any) { }
}

export class ResetState implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export class UpdateStepTag implements Action {
  readonly type = ActionTypes.UPDATE_STEP_TAG_SETTING;

  constructor(public companyId: number, public id: number, public isInbound: boolean, public isStep: boolean, public payload: Partial<UpdateStepTagSettingInputApiModel>) { }
}

export class UpdateStepTagSuccess implements Action {
  readonly type = ActionTypes.UPDATE_STEP_TAG_SETTING_SUCCESS;
}

export class UpdateStepTagFailure implements Action {
  readonly type = ActionTypes.UPDATE_STEP_TAG_SETTING_FAIL;

  constructor(public error: any) { }
}

export class UpdateQuestion implements Action {
  readonly type = ActionTypes.UPDATE_QUESTION;
  constructor(public companyId: number,public questionId: number , public payload: any) { }
}

export class UpdateQuestionSuccess implements Action {
  readonly type = ActionTypes.UPDATE_QUESTION_SUCCESS;
}

export class UpdateQuestionFailure implements Action {
  readonly type = ActionTypes.UPDATE_QUESTION_FAIL;
  constructor(public error: any) { }
}


export class UpdatNote implements Action {
  readonly type = ActionTypes.UPDATE_NOTE;
  constructor(public companyId: number,public noteId: number , public payload: any) { }
}

export class UpdatNoteSuccess implements Action {
  readonly type = ActionTypes.UPDATE_NOTE_SUCCESS;
}

export class UpdatNoteFailure implements Action {
  readonly type = ActionTypes.UPDATE_NOTE_FAIL;
  constructor(public error: any) { }
}

export class CreateTagSetting implements Action {
  readonly type = ActionTypes.CREATE_STEP_TAG_SETTING;
  constructor(public companyId: number, public stepId: number, public isInbound: boolean, public isStep: boolean, public payload) { }
}

export class CreateTagSettingSuccess implements Action {
  readonly type = ActionTypes.CREATE_STEP_TAG_SETTING_SUCCESS;
}

export class CreateTagSettingFailure implements Action {
  readonly type = ActionTypes.CREATE_STEP_TAG_SETTING_FAIL;
  constructor(public error: any) { }
}

export class DeleteTagSetting implements Action {
  readonly type = ActionTypes.DELETE_STEP_TAG_SETTING;
  constructor(public companyId: number, public id: number, public isInbound: boolean, public isStep: boolean) { }
}

export class DeleteTagSettingSuccess implements Action {
  readonly type = ActionTypes.DELETE_STEP_TAG_SETTING_SUCCESS;
}

export class DeleteTagSettingFailure implements Action {
  readonly type = ActionTypes.DELETE_STEP_TAG_SETTING_FAIL;
  constructor(public error: any) { }
}

export class DeleteQuestion implements Action {
  readonly type = ActionTypes.DELETE_QUESTION;
  constructor(public companyId: number, public questionId: any) { }
}

export class DeleteQuestionSuccess implements Action {
  readonly type = ActionTypes.DELETE_QUESTION_SUCCESS;
}

export class DeleteQuestionFailure implements Action {
  readonly type = ActionTypes.DELETE_QUESTION_FAIL;
  constructor (public error: any) {}
}

export class DeletNote implements Action {
  readonly type = ActionTypes.DELETE_NOTE;
  constructor(public companyId: number, public noteId: any) { }
}

export class DeletNoteSuccess implements Action {
  readonly type = ActionTypes.DELETE_NOTE_SUCCESS;
}

export class DeletNoteFailure implements Action {
  readonly type = ActionTypes.DELETE_NOTE_FAIL;
  constructor (public error: any) {}
}

export type Actions =
  | Load | LoadSuccess | LoadFailure | SelectStepSetting
  | CreateTag | CreateTagSuccess | CreateTagFailure
  | LoadContributionStep | LoadContributionStepSuccess | LoadContributionStepFailure | ResetState |
  UpdateStepTag |
  UpdateStepTagSuccess |
  UpdateStepTagFailure |
  CreateTagSetting |
  CreateTagSettingSuccess |
  CreateTagSettingFailure |
  DeleteTagSetting |
  DeleteTagSettingSuccess |
  DeleteTagSettingFailure|
  LoadPlayerStep | LoadPlayerStepSuccess | LoadPlayerStepFailure | 
  LoadQuestions | LoadQuestionsSuccess | LoadQuestionsFailure
  | CreateQuestion | CreateQuestionSuccess | CreateQuestionFailure
  | UpdateQuestion | UpdateQuestionSuccess | UpdateQuestionFailure
  | DeleteQuestion | DeleteQuestionSuccess | DeleteQuestionFailure
  | CreateNote | CreateNoteSuccess | CreateNoteFailure
  | UpdatNote | UpdatNoteSuccess | UpdatNoteFailure
  | LoadNote | LoadNoteSuccess | LoadNoteSuccess
  | DeletNote | DeletNoteSuccess | DeletNoteFailure;