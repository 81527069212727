import { notificationReducer } from "./notification.reducer";
import { appSettingReducer } from './app-setting.reducer';
import { likeReducer } from './like.reducer';
import { followReducer } from './follow.reducer';
import { commentReducer } from './comment.reducer';


export const sharedReducers = {
    notification: notificationReducer,
    appSetting: appSettingReducer,
    like: likeReducer,
    follow: followReducer,
    comment: commentReducer
}

