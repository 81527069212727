import * as _ from 'lodash';

import { initialState, directoryAdapter } from '../adapters/directory.adapter';
import { DirectoryState } from '../states';
import { directoryActions } from '../actions';
import { DirectoryEntity } from '../entities';
import { UpdateStore } from '@modules/shared/models/common';


export function directoryReducer(state = initialState, action: directoryActions.Actions): DirectoryState {
  switch (action.type) {

    case directoryActions.ActionTypes.LOAD_COMPANY: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        isFailure: false,
      }
    }

    case directoryActions.ActionTypes.LOAD_COMPANY_SUCCESS: {
      const entities = action.response.result.map(item => new DirectoryEntity(item));
      let companyDirectoryIds = state.companyDirectoryIds;
      if (!action.response.prev) {
        // Reset entities
        companyDirectoryIds = entities.map(entity => entity.id);
      } else {
        // Append more
        companyDirectoryIds = [...companyDirectoryIds, ...entities.map(entity => entity.id)];
      }
      return {
        ...directoryAdapter.upsertMany(entities, state),
        isLoaded: true,
        isCompanyLoaded: true,
        isLoading: false,
        isFailure: false,
        companyDirectoryTotal: action.response.count,
        nextUrl: action.response.next,
        prevUrl: action.response.prev,
        companyDirectoryIds: companyDirectoryIds
      };
    }

    case directoryActions.ActionTypes.LOAD_COMPANY_FAILURE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailure: true,
        isCompanyLoaded: false,
      }
    }

    case directoryActions.ActionTypes.LOAD_FOLLOWER: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        isFailure: false,
      }
    }

    case directoryActions.ActionTypes.LOAD_FOLLOWER_SUCCESS: {
      const entities = action.response.result.map(item => new DirectoryEntity(item));
      let followerDirectoryIds = state.followerDirectoryIds;
      if (!action.response.prev) {
        // Reset entities
        followerDirectoryIds = entities.map(entity => entity.id);
      } else {
        // Append more
        followerDirectoryIds = [...followerDirectoryIds, ...entities.map(entity => entity.id)];
      }
      return {
        ...directoryAdapter.upsertMany(entities, state),
        isLoaded: true,
        isLoading: false,
        isFailure: false,
        isFollowerLoaded: true,
        followerDirectoryTotal: action.response.count,
        nextUrl: action.response.next,
        prevUrl: action.response.prev,
        followerDirectoryIds: followerDirectoryIds
      };
    }

    case directoryActions.ActionTypes.LOAD_FOLLOWER_FAILURE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailure: true,
        isFollowerLoaded: false
      }
    }

    case directoryActions.ActionTypes.LOAD_FOLLOWING: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        isFailure: false,
      }
    }

    case directoryActions.ActionTypes.LOAD_FOLLOWING_SUCCESS: {
      const entities = action.response.result.map(item => new DirectoryEntity(item));
      let followingDirectoryIds = state.followingDirectoryIds;
      if (!action.response.prev) {
        // Reset entities
        followingDirectoryIds = entities.map(entity => entity.id);
      } else {
        // Append more
        followingDirectoryIds = [...followingDirectoryIds, ...entities.map(entity => entity.id)];
      }
      return {
        ...directoryAdapter.upsertMany(entities, state),
        isLoaded: true,
        isLoading: false,
        isFailure: false,
        isFollowingLoaded: true,
        followingDirectoryTotal: action.response.count,
        nextUrl: action.response.next,
        prevUrl: action.response.prev,
        followingDirectoryIds: followingDirectoryIds
      };
    }

    case directoryActions.ActionTypes.LOAD_FOLLOWING_FAILURE: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        isFailure: true,
        isFollowingLoaded: false
      }
    }

    case directoryActions.ActionTypes.FOLLOW_SUCCESS: {
      let followingDirectoryIds = _.cloneDeep(state.followingDirectoryIds);
      followingDirectoryIds.unshift(action.response.resource_id);
      const updateStore = new UpdateStore<DirectoryEntity>(action.response.resource_id, { followId: action.response.id });
      return {
        ...directoryAdapter.updateOne(updateStore, state),
        followingDirectoryIds: followingDirectoryIds
      }
    }

    case directoryActions.ActionTypes.UNFOLLOW_SUCCESS: {
      let followingDirectoryIds = _.cloneDeep(state.followingDirectoryIds);
      followingDirectoryIds.splice(followingDirectoryIds.indexOf(action.response.resource_id), 1);
      const updateStore = new UpdateStore<DirectoryEntity>(action.response.resource_id, { followId: null });
      return {
        ...directoryAdapter.updateOne(updateStore, state),
        followingDirectoryIds: followingDirectoryIds
      }
    }

    case directoryActions.ActionTypes.SET_COMPANY_FORCE_LOAD_STATUS: {
      return {
        ...state,
        isCompanyForceLoad: action.isForceLoad
      }
    }

    case directoryActions.ActionTypes.SET_FOLLOWER_FORCE_LOAD_STATUS: {
      return {
        ...state,
        isFollowerForceLoad: action.isForceLoad
      }
    }

    case directoryActions.ActionTypes.SET_FOLLOWING_FORCE_LOAD_STATUS: {
      return {
        ...state,
        isFollowingForceLoad: action.isForceLoad
      }
    }

    default: {
      return state;
    }
  }
}
