<div (click)="gotoDetail()" class="card-page" *ngIf="page">
  <a [routerLink]="href">
    <div [background-image]="page.headerImage" class="card-page__header">
      <div class="card-page__header-inner">
        <h4>{{page.title}}</h4>
      </div>
    </div>
    <div class="card-page__body">
      <div class="form-inline">
        <ng-container *ngFor="let item of facetsSelected | slice:0:2; let i=index;">
          <div *ngIf="isTag(i)" class="chip-tag" [style.color]="item.color">
            <svg role="img" class="icon16">
              <use xlink:href="#icon-tag"></use>
            </svg><span>{{item.name}}</span>
          </div>

          <div *ngIf="isCampaign(i)" class="chip-tag">
            <svg role="img" class="icon16">
              <use xlink:href="#icon-target"></use>
            </svg><span><strong>Campaign</strong> {{item.name}}</span>
          </div>

          <div *ngIf="isPeople(i)" class="chip-tag">
            <div [background-image]="item.avatar" class="avatar"></div><span>{{item.name}}</span>
          </div>
        </ng-container>

        <div class="dropdown mr-3" *ngIf="facetsSelected.length > 3">
          <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button"
            class="btn btn-trans">
            <div class="chip-tag">
              <svg role="img" class="icon16">
                <use xlink:href="#icon-more"></use>
              </svg>
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <form>
              <div class="dropdown-item"
                *ngFor="let item of facetsSelected | slice:3:facetsSelected.length-1; let i=index;">
                <div *ngIf="isTag(i)" class="chip-tag m-0" [style.color]="item.color">
                  <svg role="img" class="icon16">
                    <use xlink:href="#icon-tag"></use>
                  </svg><span>{{item.name}}</span>
                </div>

                <div *ngIf="isCampaign(i)" class="chip-tag m-0">
                  <svg role="img" class="icon16">
                    <use xlink:href="#icon-target"></use>
                  </svg><span><strong>Campaign</strong> {{item.name}}</span>
                </div>

                <div *ngIf="isPeople(i)" class="chip-tag m-0">
                  <div [background-image]="item.avatar" class="avatar"></div><span>{{item.name}}</span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="spacer"></div>
        <span class="text-white">{{showCount(page)}} stories</span>
      </div>
    </div>
  </a>
</div>
