import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { companyActions, companyEmbedActions } from '../actions';
import { CompanyHttpService } from '../../services';
import { InfoCompanyOutputApiModel } from '@modules/company/models/api-models/output';
import { UserHttpService } from '@modules/user/services';

@Injectable()
export class CompanyEffects {

  
  searchEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.Search>(companyActions.ActionTypes.SEARCH),
    switchMap((action: companyActions.Search) => {
      return this.companyHttpService.search(action.domain).pipe(
        map((respone) => {
          return new companyActions.SearchSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new companyActions.SearchFailure(error));
        }));
    })
  ));

  
  searchByNameEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.SearchByName>(companyActions.ActionTypes.SEARCH_BY_NAME),
    switchMap((action: companyActions.SearchByName) => {
      return this.companyHttpService.searchByName(action.name).pipe(
        map((respone) => {
          return new companyActions.SearchByNameSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new companyActions.SearchByNameFailure(error));
        }));
    })
  ));

  
  addMemberEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.AddMember>(companyActions.ActionTypes.ADD_MEMBER),
    switchMap((action: companyActions.AddMember) => {
      return this.companyHttpService.addMember(action.companyId, action.userId).pipe(
        map(() => {
          return new companyActions.AddMemberSuccess();
        }),
        catchError((error: any) => {
          return of(new companyActions.AddMemberFailure(error));
        }));
    })
  ));

  
  removeMemberEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.RemoveMember>(companyActions.ActionTypes.REMOVE_MEMBER),
    switchMap((action: companyActions.RemoveMember) => {
      return this.usersService.removeUser().pipe(
        map(() => {
          return new companyActions.RemoveMemberSuccess();
        }),
        catchError((error: any) => {
          return of(new companyActions.RemoveMemberFailure(error));
        }));
    })
  ));

  
  createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.Create>(companyActions.ActionTypes.CREATE),
    switchMap((action: companyActions.Create) => {
      return this.companyHttpService.create(action.payload).pipe(
        map((data) => {
          return new companyActions.CreateSuccess({id:data['id'],payload:action.payload});
        }),
        catchError((error: any) => {
          return of(new companyActions.CreateFailure(error));
        }));
    })
  ));

  
  loadInfoEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.LoadInfo>(companyActions.ActionTypes.LOAD_INFO),
    switchMap((action: companyActions.LoadInfo) => {
      return this.companyHttpService.loadInfo(action.companyId).pipe(
        map((response: InfoCompanyOutputApiModel) => {
          return new companyActions.LoadInfoSuccess(response);
        }),
        catchError((error: any) => {
          return of(new companyActions.LoadInfoFailure(error));
        })
      )
    })
  ));

  
  loadPublicInfoEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.LoadPublicInfo>(companyActions.ActionTypes.LOAD_PUBLIC_INFO),
    switchMap((action: companyActions.LoadPublicInfo) => {
      return this.companyHttpService.loadPublicInfo(action.companyIdOrSlug).pipe(
        map((response: InfoCompanyOutputApiModel) => {
          return new companyActions.LoadPublicInfoSuccess(response);
        }),
        catchError((error: any) => {
          return of(new companyActions.LoadPublicInfoFailure(error));
        })
      )
    })
  ));

  
  loadShortInfoEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.LoadShortInfo>(companyActions.ActionTypes.LOAD_SHORT_INFO),
    switchMap((action: companyActions.LoadShortInfo) => {
      return this.companyHttpService.loadShortInfo(action.companyIdOrSlug).pipe(
        map((response: InfoCompanyOutputApiModel) => {
          return new companyActions.LoadShortInfoSuccess(response);
        }),
        catchError((error: any) => {
          return of(new companyActions.LoadShortInfoFailure(error));
        })
      )
    })
  ));



  
  updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.UpdateStoryCheckOut>(companyActions.ActionTypes.UPDATE_STORY_CHEKOUT),
    switchMap((action: companyActions.UpdateStoryCheckOut) => {
      return this.companyHttpService.updateStoryCheckOut(action.companyId, action.payload).pipe(
        map(() => {
          return new companyActions.UpdateSuccess();
        }),
        catchError((error: any) => {
          return of(new companyActions.UpdateFailure(error));
        }));
    })
  ));

  getStoryCheckoutEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.GetStoryCheckout>(companyActions.ActionTypes.GET_STORY_CHEKOUT),
    switchMap((action: companyActions.GetStoryCheckout) => {
      return this.companyHttpService.getStoryCheckOut(action.companyId).pipe(
        map((response:any) => {
          return new companyActions.GetStoryCheckoutSuccess(response);
        }),
        catchError((error: any) => {
          return of(new companyActions.GetStoryCheckoutFailure(error));
        }));
    })
  ));

  updateStoryCheckoutEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyActions.Update>(companyActions.ActionTypes.UPDATE),
    switchMap((action: companyActions.Update) => {
      return this.companyHttpService.update(action.companyId, action.payload).pipe(
        map(() => {
          return new companyActions.UpdateSuccess();
        }),
        catchError((error: any) => {
          return of(new companyActions.UpdateFailure(error));
        }));
    })
  ));

  // 
  // changeSlugEffect$: Observable<Action> = this.actions$.pipe(
  //     ofType<companyActions.ChangeSlug>(companyActions.ActionTypes.CHANGE_SLUG),
  //     switchMap((action: companyActions.ChangeSlug) => {
  //         return this.companyHttpService.changeSlug(action.payload).pipe(
  //             map(() => {
  //                 return new companyActions.ChangeSlugSuccess();
  //             }),
  //             catchError((error: any) => {
  //                 return of(new companyActions.ChangeSlugFailure(error));
  //             }))
  //     })
  // );

  constructor(
    private actions$: Actions,
    private companyHttpService: CompanyHttpService,
    private usersService: UserHttpService
  ) { }
}
