import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ProfileInternalEntity } from '../entities';
import { ProfileInternalState } from '../states';

export const profileInternalAdapter: EntityAdapter<ProfileInternalEntity> = createEntityAdapter<ProfileInternalEntity>();

export const initialState: ProfileInternalState = profileInternalAdapter.getInitialState({
    currentCompanyProfileId: null,
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    isLoadingPublic: false,
    isLoadedPublic: false,
    isUpdating: false
});
