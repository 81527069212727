import { Action } from '@ngrx/store';

import { LoadUserSettingOutputApiModel, UserNotificationsOutputApiModel } from '@modules/user/models/output';
import { UpdateUserSettingInputApiModel } from '@modules/user/models/input';
import { UserNotificationsEntity } from '@modules/user/store/entities';
import { UserNotificationsInputApiModel } from "@modules/user/models/input/user-notifications-input.api-model";

export enum ActionTypes {
  LOAD = '[User Setting] Load',
  LOAD_SUCCESS = '[User Setting] Load Success',
  LOAD_FAILURE = '[User Setting] Load Failure',

  UPDATE = '[User Setting] Update',
  UPDATE_SUCCESS = '[User Setting] Update Success',
  UPDATE_FAILURE = '[User Setting] Update Failure',

  SAVE_SETTING = '[User Setting] Save Setting',

  LOAD_NOTIFICATIONS = '[User Setting] Load Notifications',
  LOAD_NOTIFICATIONS_SUCCESS = '[User Setting] Load Notifications Success',
  LOAD_NOTIFICATIONS_FAILURE = '[User Setting] Load Notifications Failure',

  UPDATE_NOTIFICATIONS = '[User Setting] Update Notifications',
  UPDATE_NOTIFICATIONS_SUCCESS = '[User Setting] Update Notifications Success',
  UPDATE_NOTIFICATIONS_FAILURE = '[User Setting] Update Notifications Failure',
}


export class Load implements Action {
  readonly type = ActionTypes.LOAD;

  constructor() {
  }
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public response: LoadUserSettingOutputApiModel) {
  }
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public error: any) {
  }
}

export class Update implements Action {
  readonly type = ActionTypes.UPDATE;

  constructor(public payload: UpdateUserSettingInputApiModel) {
  }
}

export class UpdateSuccess implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;

  constructor() {
  }
}

export class UpdateFailure implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;

  constructor(public error: any) {
  }
}

export class SaveSetting implements Action {
  readonly type = ActionTypes.SAVE_SETTING;
}

export class LoadNotifications implements Action {
  readonly type = ActionTypes.LOAD_NOTIFICATIONS;

  constructor() {
  }
}

export class LoadNotificationsSuccess implements Action {
  readonly type = ActionTypes.LOAD_NOTIFICATIONS_SUCCESS;

  constructor(public response: UserNotificationsEntity) {
  }
}

export class LoadNotificationsFailure implements Action {
  readonly type = ActionTypes.LOAD_NOTIFICATIONS_FAILURE;

  constructor(public error: any) {
  }
}

export class UpdateNotifications implements Action {
  readonly type = ActionTypes.UPDATE_NOTIFICATIONS;

  constructor(public data: any) {
  }
}

export class UpdateNotificationsSuccess implements Action {
  readonly type = ActionTypes.UPDATE_NOTIFICATIONS_SUCCESS;

  constructor(public response: any) {
  }
}

export class UpdateNotificationsFailure implements Action {
  readonly type = ActionTypes.UPDATE_NOTIFICATIONS_FAILURE;

  constructor(public error: any) {
  }
}

export type Actions
  = Load
  | LoadSuccess
  | LoadFailure
  | Update
  | UpdateSuccess
  | UpdateFailure
  | SaveSetting
  | LoadNotifications
  | LoadNotificationsSuccess
  | LoadNotificationsFailure
  | UpdateNotifications
  | UpdateNotificationsSuccess
  | UpdateNotificationsFailure ;
