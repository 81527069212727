import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService } from '@framework/services';
import { ofType, Actions } from '@ngrx/effects';
import { storyActions } from '@modules/story/store/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { AuthService } from '@framework/services';

import * as adminStoryActions from '@modules/company-admin/store/actions';


@Component({
  selector: 'advocacy-link-container',
  templateUrl: './advocacy-link-container.component.html',
  styleUrls: ['./advocacy-link-container.component.scss'],
})

export class AdvocacyContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public copyForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public activeTab: string;
  public fromTrcking: boolean = false;
  public currentUser:any= false;
  private AddtrackingsuccessSubscription: Subscription;
  private loadColorSuccessSubscription: Subscription;
  private rssToken: string;
  public isShow:boolean = false;
  public disabled:boolean = false
  public primaryColor:any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private actions$: Actions,
    private store$: Store<CoreState>,
    private toastService: ToastService,
    private authService: AuthService,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })
    this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.initialState?.companySlug));
    
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
   


    // this.copyForm = this.formBuilder.group({
    //   height: [900, Validators.required],
    //   width: [100, Validators.required],
    //   widthList:['px'],
    //   heightList:['px'],
    //   bodyCode: [{ value: '', disabled: true }],
    //   selectCheckbox: ['public', Validators.required],
    //   selectRadio: [false, Validators.required]
    // })

     this.currentUser = this.authService.cognito.currentUser();
    
    this.copyForm = this.formBuilder.group({
      message:['',[Validators.maxLength(200)]],
      includeGuest: [false],
      includeUsers:[false],
      ilink:[{value: '',disabled: true}],
      elink:[{value: '',disabled: true}],
      ielink:[{value: '',disabled: true}],
      firstname:['',Validators.required],
      lastname:[''],

    })
    this.AddtrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.AddPersonalizeTrackHitSuccess>(storyActions.ActionTypes.ADD_PERSONALIZE_TRACK_SUCCESS)
    ).subscribe((action: any) => {
      if(action  && action.data && action.data.unique_token && this.fromTrcking ){
        this.isShow = true;
        this.fromTrcking = false;
        // c/:companySlug/:idOrSlug/:storytoken/:tracking/unique/people
        // var Url = `${window.location.origin}/c/${this.initialState.companySlug}/${this.initialState.slug}/${action.data.unique_token}/${this.initialState.trackingid}/unique/people`
        var internaladvocacy = `${window.location.origin}/advocacy/${action.data.unique_token}/${this.initialState.companySlug}/${this.initialState.slug}/internal_advocacy`;
        var publicadvocacy = `${window.location.origin}/advocacy/${action.data.unique_token}/${this.initialState.companySlug}/${this.initialState.slug}/public_advocacy`;
        var bothadvocacy = `${window.location.origin}/advocacy/${action.data.unique_token}/${this.initialState.companySlug}/${this.initialState.slug}/advocacy`
         this.copyForm.controls.ilink.setValue(internaladvocacy);
         this.copyForm.controls.elink.setValue(publicadvocacy);
         this.copyForm.controls.ielink.setValue(bothadvocacy);
      }
         

    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primaryColor = action.response.find(item => item.button == 11).color;
       }
      

    })


  }

  addTracking(){

  }

  InternalAdvocacy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.internaladvocacy.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  publicAdvocacy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.publicadvocacy.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  bothAdvocacy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.bothadvocacy.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }


  copyLink(){

  }

  uniqueInternalAdvocacy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyForm.controls.ilink.value.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  // this.copyForm.controls.ilink.setValue(internaladvocacy);
  // this.copyForm.controls.elink.setValue(publicadvocacy);
  // this.copyForm.controls.ielink.setValue(bothadvocacy);
  UniquepublicAdvocacy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyForm.controls.elink.value.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }
  UniquebothAdvocacy(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyForm.controls.ielink.value.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }
  internalVideo(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.videoUrl.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  genrarateLink(){
    this.fromTrcking = true;
    var tarcking = []
    let trackobj;
    if(this.copyForm.value.includeGuest  && this.copyForm.value.includeUsers){
      trackobj ={
        "story_id": this.initialState.storyId,
        "name": this.copyForm.value.name,
        "company_Id": this.initialState.companyId,
        "advocacy_type":"both",
        "resource_type":"advocacy"
       }
    }
    else{
      trackobj ={
        "story_id": this.initialState.storyId,
        "name": this.copyForm.value.name,
        "company_Id": this.initialState.companyId,
        "advocacy_type": this.copyForm.value.includeGuest ? "public" : "internal",
        "resource_type":"advocacy"
       }
    }

    8917358874

    if(this.copyForm.value.message){
      trackobj["message"] = this.copyForm.value.message
    }

     this.disabled = true;
    
     this.store$.dispatch(new storyActions.AddPersonalizeTrackHit(trackobj));
  }

  CopyLink(){
    if(this.copyForm.value.includeGuest  && this.copyForm.value.includeUsers){
        this.UniquebothAdvocacy();
    }
    else if(!this.copyForm.value.includeGuest  && this.copyForm.value.includeUsers){
        this.uniqueInternalAdvocacy();
    }
    else if(this.copyForm.value.includeGuest  && !this.copyForm.value.includeUsers){
      this.UniquepublicAdvocacy();
    }
  }

  close(){
    this.onClose.emit(true)
  }

}
