<div  *ngIf="campaign" class="row mb-3 py-4 mouse_pointer">
  <div class="col-12 col-lg-6 mouse_pointer">
    <a [routerLink]="href">
      <div [background-image]="campaign.headerImage" class="card-page__header mouse_pointer">
        <div class="card-page__header-inner">
          <h4>{{campaign.title}}</h4>
          <p class="campaign-item__desc">{{campaign.description}}</p>
        </div>
      </div>
      <div class="card-page__body mouse_pointer">
        <div class="form-inline">
          <ng-container *ngIf="campaign && !campaign.interview_style">
            <div class="campaign-item__btn" *ngIf="campaign.daysLeft >= 0">
              <a [routerLink]="['/t/' + campaign.companySlug + '/testimonial/create']" [queryParams]="{cs: campaign.slug}" [style.background]="btnsubmit"  [style.color]="txtsubmit" class="btn btn-sm">Submit Story</a>
            </div>
          </ng-container>

          <ng-container *ngIf="campaign && campaign.interview_style">
            <div class="campaign-item__btn" *ngIf="campaign.daysLeft >= 0">
              <a (click)="navgateToInterviewStyle(campaign)"
                 class="btn btn-sm" [style.background]="btnsubmit"  [style.color]="txtsubmit" style="color: #fff !important">Submit Story</a>
            </div>
          </ng-container>
          <div class="spacer"></div>
          <ng-container *ngIf="campaign.daysLeft >= 0">
          <span class="tag-icon text-white">
            <svg role="img">
              <use xlink:href="#icon-time"></use>
            </svg>
            <span class="text">{{campaign.daysLeft}} days <span class="d-none d-md-inline">left</span></span>
          </span>
          </ng-container>
          <ng-container *ngIf="campaign.daysLeft < 0">
          <span class="tag-icon text-white">
            <svg role="img">
              <use xlink:href="#icon-time"></use>
            </svg>
            <span class="text">Finished</span>
          </span>
          </ng-container>
        </div>
      </div>
    </a>
  </div>
  <div class="col-12 col-lg-6 px-3 px-lg-0">
    <div class="campaign-item__none" *ngIf="(!campaign.stories || campaign.stories.length == 0) && campaign.daysLeft >= 0">
      <div class="text-center pt-11">
        <h5>No stories.</h5>
        <!-- <a *ngIf="company.isInboundStoriesEnabled" [routerLink]="['/t/' + campaign.companySlug + '/testimonial/create']" [queryParams]="{ph: storyPrompt, cs: campaign.slug}" class="btn btn-pink text-white">Be the First</a> -->
      </div>
    </div>
    <div class="campaign-item__none" *ngIf="(!campaign.stories || campaign.stories.length == 0) && campaign.daysLeft < 0">
      <div class="text-center pt-11">
        <h5>No stories.</h5>
      </div>
    </div>
    <div class="campaign-item__detail" *ngIf="campaign.stories && campaign.stories.length > 0">
      <table class="newest-stories">
        <colgroup>
          <col class="col-avatar"/>
          <col/>
          <col class="col-counter"/>
          <col class="col-counter"/>
        </colgroup>
        <thead>
        <tr>
          <th colspan="2">Recent Stories</th>
          <th class="text-center text-grey">
            <svg role="img">
              <use xlink:href="#icon-heart"></use>
            </svg>
          </th>
          <th class="text-center text-grey">
            <svg role="img">
              <use xlink:href="#icon-eye"></use>
            </svg>
          </th>
        </tr>
        </thead>
      </table>
      <div class="table-scroll-y scrollbar-macosx">
        <table class="newest-stories">
          <colgroup>
            <col class="col-avatar"/>
            <col/>
            <col class="col-counter"/>
            <col class="col-counter"/>
          </colgroup>
          <tbody>
          <tr *ngFor="let story of campaign.stories" [routerLink]="'/c/' + getSlugCompany(story) + '/' + story.slug">
            <td>
              <div [background-image]="story.author.avatar" class="avatar avatar--sm"></div>
            </td>
            <td><a [href]="'/company/' + story.slug">{{story.title}}</a></td>
            <td class="text-center">{{story.likesCount}}</td>
            <td class="text-center">{{story.commentsCount}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
