export interface SearchQueryBuilder {
    filter: string;
    keyword: string;
    sort: string;
    page: string;
    limit: string;
    uid: string;
    params: any[];
}

export class SearchQueryBuilder {
    private sortMapping = {
        desc: '-',
        asc: ''
    };

    constructor() {
        this.params = [];
    }

    public andParam(field: string, value: any): SearchQueryBuilder {
        this.params.push({
            'key': field,
            'value': value
        });

        return this;
    }

    // Public functions
    public andFilter(field: string, value: string): SearchQueryBuilder {
        if (this.filter) {
            this.filter = `${this.filter}|${field}:${value}`;
        } else {
            this.filter = `${field}:${value}`;
        }

        return this;
    }

    public andQuery(keywords: string[]): SearchQueryBuilder {
        if (this.keyword) {
            const joinKeywords = [`(${this.keyword})`, ...keywords];
            this.keyword = joinKeywords.join(' and ');
        } else {
            // keywords = keywords.length > 1 ? keywords.map(k => `(${k})`) : keywords;
            this.keyword = keywords.join(' and ');
        }

        return this;
    }

    public andUser(uid: string): SearchQueryBuilder {
        this.uid = uid;

        return this;
    }

    public addSort(field: string, value: string): SearchQueryBuilder {
        if (this.sort) {
            this.sort = `${this.sort}|${this.sortMapping[value]}${field}`;
        } else {
            this.sort = `${this.sortMapping[value]}${field}`;
        }

        return this;
    }

    public setPage(quantity: number) {
        this.page = `page=${quantity}`;
    }

    public setLimit(quantity: number) {
        this.limit = `limit=${quantity}`;
    }

    public build(): string {
        let queryString: string = '';
        if (this.keyword) {
            queryString = `keyword=${this.keyword}`;
        }

        if (this.uid) {
            queryString = `${queryString}&uid=${this.uid}`;
        }

        if (this.filter) {
            queryString = `${queryString}&filter=${this.filter}`;
        }

        if (this.sort) {
            queryString = `${queryString}&sort=${this.sort}`;
        }

        if (this.page && this.limit) {
            queryString = `${queryString}&${this.page}&${this.limit}`;
        }

        if (this.params) {
            let extra = '';

            this.params.forEach(item => {
                extra += (extra == '' ? '' : '&') + `${item['key']}=${item['value']}`;
            });

            queryString = extra != '' ? `${queryString}&${extra}` : queryString
        }

        return queryString;
    }
}
