export interface UserSettingState {
    completedFirstStory: boolean,
    completedSchedule: boolean,
    completedInviteMember: boolean,
    isLoading: boolean,
    isLoaded: boolean
}

export const initialState: UserSettingState = {
    completedFirstStory: false,
    completedSchedule: false,
    completedInviteMember: false,
    isLoading: false,
    isLoaded: false
}