import { Component, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component';
import { StoryViewModel, StoryContributionViewModel } from '@modules/story/models/view-models';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';

@Component({
  selector: 'app-pb-contributor-list',
  templateUrl: './contributor-list.component.html',
  styleUrls: ['./contributor-list.component.css']
})
export class ContributorListComponent extends ComponentBase {
  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectLoadStatus$: Observable<StoryLoadStatusViewModel>;

  private selectStoryDetailSubscription: Subscription;
  private selectLoadStatusSubscription: Subscription;
  // Public variables
  public storyDetail: StoryViewModel;
  public loadStatus: StoryLoadStatusViewModel;

  constructor(private store$: Store<CoreState>) {
    super();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectLoadStatus$ = this.store$.select(storySelector.selectLoadStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
    });

    this.selectLoadStatusSubscription = this.selectLoadStatus$.subscribe((loadStatus) => {
      this.loadStatus = loadStatus;
    });
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.selectLoadStatusSubscription.unsubscribe();
  }
  // Public functions
  public trackByFunc(contribution: StoryContributionViewModel) {
    return contribution.id;
  }
}
