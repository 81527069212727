import { Action } from "@ngrx/store";
import { EmailCustomizeSettingOutputApiModel } from "../models/api-models/output";
import { ResponsePaginationApiModel } from "@framework/models";
import { UpdateEmailCustomizeSettingInputApiModel } from "../models/api-models/input";

export enum ActionTypes {
    LOAD = '[Company Admin email customize setting] Load',
    LOAD_SUCCESS = '[Company Admin email customize setting] Load Success',
    LOAD_FAIL = '[Company Admin email customize setting] Load Fail',

    UPDATE = '[Company Admin email customize setting] Update',
    UPDATE_SUCCESS = '[Company Admin email customize setting] Update Success',
    UPDATE_FAIL = '[Company Admin email customize setting] Update Fail',
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<EmailCustomizeSettingOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number,public customizeId: number, public payload: Partial<UpdateEmailCustomizeSettingInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}


export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    Update |
    UpdateSuccess |
    UpdateFailure 
    ;