
import { Component, Input, forwardRef, Output, EventEmitter, OnChanges, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'text-area-auto-height-control',
    templateUrl: './text-area-auto-height-control.component.html',
    styleUrls: ['./text-area-auto-height-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextAreaAutoHeightControlComponent),
            multi: true
        }
    ]
})
export class TextAreaAutoHeightControlComponent implements ControlValueAccessor, OnChanges {

    @Input() placeholder: string;
    @Input() debounceTime: number = 0;

    @Output() blur: EventEmitter<string> = new EventEmitter<string>();
    @Output() focuselement: EventEmitter<string> = new EventEmitter<string>();
    private textAreaValue: string;

    public fnOnChange: any = () => { };
    public fnOnTouch: any = () => { };

    constructor(private el: ElementRef, private renderer: Renderer2) {

    }

    ngOnChanges() {

    }

    ngAfterViewChecked() {

    }

    private debounce(fn, args, delay) {
        let timer = null;

        return () => {
            clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(args);
            }, delay);
        }
    }

    private triggerChange($event) {
        this.fnOnChange($event);
        let event = new CustomEvent('change', { bubbles: true });
        this.el.nativeElement.dispatchEvent(event);
    }

    // Public methods

    public set value(val: string) {
        if (val !== undefined) {
            this.textAreaValue = val;
        }
    }

    public get value() {
        return this.textAreaValue;
    }

    public writeValue(val: string): void {
        this.value = val;
        this.autosize();
    }

    public registerOnChange(fn: any): void {
        this.fnOnChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.fnOnTouch = fn;
    }

    public focus() {
        this.el.nativeElement.firstChild.focus();
    }

    public onChange($event) {
        if (this.debounceTime > 0) {
            this.debounce(this.triggerChange, this.textAreaValue, this.debounceTime);
        } else {
            this.triggerChange(this.textAreaValue);
        }
    }

    public onBlur($event) {
        this.blur.emit(this.textAreaValue);
    }

    public onKeydown($event) {
        this.autosize();
    }
    public onfocus(data)
    {
        this.focuselement.emit(data)
    }

    public autosize() {
        setTimeout(() => {
            if (this.el.nativeElement.firstChild.scrollHeight > 0) {
                this.el.nativeElement.firstChild.style.cssText = 'height:auto; padding:0';
                // for box-sizing other than "content-box" use:
                // el.style.cssText = '-moz-box-sizing:content-box';
                this.el.nativeElement.firstChild.style.cssText = 'height:' + this.el.nativeElement.firstChild.scrollHeight + 'px';
            }
        }, 0);
    }
}
