import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Page } from '@framework/base';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { UserProfileViewModel } from '@modules/user/store/models';
import { ApprovalFlowActions } from '@modules/company-admin/store/actions';

@Component({
  selector: 'app-approval-page',
  templateUrl: './approval-page.html',
  styleUrls: ['./approval-page.scss'],
})
export class ApprovalPage extends Page {

  private currentUserProfile: UserProfileViewModel;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectCurrentUserProfileSubscription: Subscription;
  items = [
    { id: 1, name: 'John Doe', status: 'Pending' },
    { id: 2, name: 'Jane Smith', status: 'Pending' }
    // Add more items as needed
  ];
  constructor(
    private store$: Store<CoreState>,
    private actions$: Actions,
  ) {

    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);

  }

  // Life cycle hook
  protected onInit(): void {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.store$.dispatch(new ApprovalFlowActions.GetListApproval(this.currentUserProfile?.company?.id))
      }
    })
  }
  protected onDestroy(): void {

  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions


  updateStatus(item: any, status: string): void {
    item.status = status;
  }
 
}
