<div class="b-information">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 mb-3">
          <div class="m-infor-intro">
            <gs-skeleton-loader class="m-infor-intro__content" height="108"></gs-skeleton-loader>
            <div class="m-infor-intro__author">
              <gs-skeleton-loader class="avatar avatar--sm" width="32" height="32" [appearance]="'circle'"></gs-skeleton-loader>
              <div class="name text-nowrap">
                <gs-skeleton-loader width="100" height="24"></gs-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 d-md-none d-lg-block px-2 mb-3 mb-md-0">
          <div class="m-infor-boxes">
            <div class="m-infor-boxes__content">
              <div class="boxes-story-count text-center text-lg-left">
                <div class="m-infor-boxes__title">Stories</div>
                <div class="boxes-story-count__focus"><gs-skeleton-loader width="60" height="50"></gs-skeleton-loader></div>
                <div class="boxes-story-count__child d-none d-sm-flex">
                  <div class="boxes-story-count__grid">
                    <gs-skeleton-loader height="50"></gs-skeleton-loader>
                  </div>
                  <div class="boxes-story-count__grid">
                    <gs-skeleton-loader height="50"></gs-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0">
          <div class="m-infor-boxes text-center text-lg-left">
            <div class="m-infor-boxes__title">Talents</div>
            <div class="m-infor-boxes__content">
              <div class="l-chips">
                <div class="l-chips__item">
                  <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                  <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                  <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0">
          <div class="m-infor-boxes text-center text-lg-left">
            <div class="m-infor-boxes__title">Vibes</div>
            <div class="m-infor-boxes__content">
              <div class="l-chips l-chips--purple">
                <div class="l-chips__item">
                  <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                  <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                  <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 col-md-4 px-2 mb-3 mb-md-0">
          <div class="m-infor-boxes text-center text-lg-left">
            <div class="m-infor-boxes__title">Values</div>
            <div class="m-infor-boxes__content">
              <div class="l-chips__item">
                <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
                <gs-skeleton-loader width="85" height="24"></gs-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>