<div class="story-item">
  <div class="story-item__wrap">
    <div class="row align-items-center">
      <div class="col-md-1">
        <div class="story-item__date">
          <div class="story-item__date-month"></div>
          <div class="story-item__date-day"></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="story-item__thumb"><gs-skeleton-loader height="225"></gs-skeleton-loader></div>
      </div>
      <div class="col-md-7 col-xl-6 align-self-end">
        <div class="story-item__conts">
          <div class="story-item__title">
            <gs-skeleton-loader height="27"></gs-skeleton-loader>
          </div>
          <div class="story-item__desc"></div>
          <div class="story-item__info">
            <div class="story-item__user">
              <div class="avatar avatar--xs"><gs-skeleton-loader width="24" height="24" [appearance]="'circle'"></gs-skeleton-loader></div>
              <div class="name text-nowrap"><gs-skeleton-loader width="80" height="20"></gs-skeleton-loader></div>
            </div>
            <div class="story-item__status">
              <span class="tag-icon">
                <gs-skeleton-loader width="20" height="20" [appearance]="'circle'"></gs-skeleton-loader>
                <gs-skeleton-loader width="20" height="20" [appearance]="'circle'"></gs-skeleton-loader>
              </span>
            </div>
          </div>
          <div class="story-item__tags">
            <gs-skeleton-loader class="tag" height="20"></gs-skeleton-loader>
            <gs-skeleton-loader class="tag" height="20"></gs-skeleton-loader>
            <gs-skeleton-loader class="tag" height="20"></gs-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>