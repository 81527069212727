
import { CampaignState } from '../states';
import { campaignActions } from '../actions';
import { CampaignEntity } from '../entities';
import { campaignAdapter, initialState } from '../adapters/campaign.adapter';


export function campaignReducer(state = initialState, action: campaignActions.Actions): CampaignState {
    switch (action.type) {

        case campaignActions.ActionTypes.CREATE_SUCCESS: {
            const campaign = new CampaignEntity(action.response);
            return {
                ...campaignAdapter.addOne(campaign, state),
                selectedId: action.response.id,
            };
        }

        case campaignActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        }

        case campaignActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new CampaignEntity(item));
            let newState;
            if (!action.response.prev) {
                newState = campaignAdapter.setAll(entities, state);
            } else {
                newState = campaignAdapter.addMany(entities, state);
            }
            return {
                ...newState,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev
            };
        }

        case campaignActions.ActionTypes.LOAD_DETAIL: {
            return {
                ...state,
                selectedId: null,
                isLoading: true,
                isLoaded: false
            };
        }

        case campaignActions.ActionTypes.LOAD_DETAIL_SUCCESS: {
            const updateItem: CampaignEntity = new CampaignEntity(action.data);
            return {
                ...campaignAdapter.upsertOne(updateItem, state),
                selectedId: action.data.id,
                isLoading: false,
                isLoaded: true
            };
        }

        case campaignActions.ActionTypes.LOAD_DETAIL_FAIL: {
            return {
                ...state,
                selectedId: null,
                isLoading: false,
                isLoaded: true
            };
        }

        case campaignActions.ActionTypes.RESET_STATE: {
            return {
                ...initialState
            }
        }

        default: {
            return state;
        }
    }
}
