import { Component, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '@app/env/environment';

import { Page } from '@framework/base';
import { CoreState } from '@app/store/core/states';
import { Router, ActivatedRoute } from '@angular/router';
import { companyActions, searchBoxActions } from '@modules/company/store/actions';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { UserProfileViewModel } from '@modules/user/store/models';
import { storyActions } from '@modules/story/store/actions';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from '@modules/shared/models/common/seo.model';
import { ScriptActions } from '@modules/company-admin/store/actions';
import { isPlatformBrowser } from '@angular/common';
import * as Saction from '@modules/company-admin/store/actions'
import { CompanyHttpService } from '@modules/company/services/company.http-service';

declare var $: any;

declare const gtag: Function;
declare var window:any;
declare var dataLayer: any

let pageName;
let globalTag;
let measuremnetId;
@Component({
  selector: 'app-public-page',
  templateUrl: './public.page.html',
  styleUrls: ['./public.page.scss']
})
export class PublicPage extends Page {
  // Private variables
  private selectAuth$: Observable<any>;
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private baseUrl: string = environment.baseUrl;
  public  showtagsboolean:boolean = true;
  public  showHeaderboolean:boolean = true;
  public  isBrowser: boolean;

  private selectCurrentCompanySubscription: Subscription;
  private loadCompanyFailSubscription: Subscription;
  private loadCompanySuccessSubscription: Subscription;
  private LoadSuccessSubscriptin: Subscription;
  // Public variables
  public naviagtion_button_COlor:any;
  public footerColor:any;
  public submit_Story_Color:any;
  public submit_Story_text:any
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public isPermission: boolean = true;
  public currentUser: UserProfileViewModel;
  public slugCompany: string = '';
  public companyBackground: string;
  public showAside:boolean = true;
  public insideIframe:boolean = false;
  public showFooter:boolean = true;
  public LoadButtonColorSuccessSubscriptin: Subscription;
  public isStories: boolean = true;
  public isCampaigns: boolean = true;
  public isSpotlight: boolean = true;
  constructor(
    private store$: Store<CoreState>,
    private actions$: Actions,
    private router: Router,
    private companyHttpService: CompanyHttpService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object,
    private seoService: SeoService
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.activatedRoute.snapshot.params.slug) {
      this.slugCompany = this.activatedRoute.snapshot.params.slug;
      this.store$.dispatch(new companyActions.LoadPublicInfo(this.slugCompany));
    } else {
      this.router.navigate(['/user/login']);
    }
    this.selectAuth$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.captureParams();
  }

  // Life cycle hook
  protected onInit(): void {

    if(this.isBrowser){
      // this.updateTagsForSSR();
      this.subscribe();
      if (window.location !== window.parent.location) {
        this.insideIframe = true;
        var storedata = window.location.href.split("?");
        if(storedata.length == 2){
          if(storedata[1].split('&').includes('showHeader=true')){
            this.showHeaderboolean = true;
          }
          else{
            this.showHeaderboolean = false;
          }
  
          if(storedata[1].split('&').includes('showtagheader=true')){
            this.showtagsboolean = true;
          }
          else{
            this.showtagsboolean = false;
          }
                  
          if(storedata[1].split('&').includes('showSpotlight=true')){
            this.isSpotlight = true;
          }
          else{
            this.isSpotlight = false;
          }
                  
          if(storedata[1].split('&').includes('showStories=true')){
            this.isStories = true;
          }
          else{
            this.isStories = false;
          }
  
                  
          if(storedata[1].split('&').includes('showCampaign=true')){
            this.isCampaigns = true;
          }
          else{
            this.isCampaigns = false;
          }
          if(storedata[1].split('&').includes('showfooter=true')){
            this.showFooter = true;
          }
          else{
            this.showFooter = false;
          }
        }
        else{
          this.showHeaderboolean = false;
          this.showtagsboolean = false;
          this.isStories = false;
          this.isCampaigns = false;
          this.isSpotlight = false;
          this.showFooter = true;
        }
        //document.getElementsByClassName("site-container")[0]['style'].padding = 0;
      } else {
        this.showHeaderboolean = true;
        this.showtagsboolean = true;
        this.isStories = true;
        this.isCampaigns = true;
        this.isSpotlight = true;
        this.showFooter = true;
      }
    }
    else{
      this.updateTagsForSSR();
    }

  }
  protected onDestroy(): void {
    if(this.isBrowser){
      this.unsubscribe();
      this.resetStates();
      this.removeScripts();
    }
    this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  public removeScripts(){
    $("#pvt__scripts").empty();
   }

  /**
   * this method is to update meta tag according to company detail
   */
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
    config.title = this.currentCompany.name;
    config.url = 'https://'+this.currentCompany.slug+'.'+environment.customUrl+'/c/'+this.currentCompany.slug;
    config.image = this.currentCompany.logo;
    config.description = this.currentCompany.slogan;
    config.keywords = this.getSeoKeywords();
    this.seoService.updateMetaTags(config);
  }

  // Private functions
  private getSeoKeywords(): string {
    let s = this.currentCompany.name;
    if (this.currentCompany.frequentTags) {
      for (var k1 in this.currentCompany.frequentTags) {
        for (var k2 in this.currentCompany.frequentTags[k1].data)
          s += ', ' + this.currentCompany.frequentTags[k1].data[k2];
      }
    }
    return s;
  }

  private subscribe() {
    this.loadCompanyFailSubscription = this.actions$.pipe(
      ofType<companyActions.LoadPublicInfoFailure>(companyActions.ActionTypes.LOAD_PUBLIC_INFO_FAILURE)
    ).subscribe((action) => {
      this.router.navigate(['/common/404']);
    });

    this.loadCompanySuccessSubscription = combineLatest(
      this.selectAuth$,
      this.actions$.pipe(ofType<companyActions.LoadPublicInfoSuccess>(companyActions.ActionTypes.LOAD_PUBLIC_INFO_SUCCESS))
    ).subscribe(([user, action]) => {
      this.isPermission = action.response.permission === false ? false : true;
      this.companyBackground = action.response.background;
      this.currentUser = user;
    });
    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.store$.dispatch(new ScriptActions.Load(this.currentCompany.id));
        this.store$.dispatch(new Saction.storyActions.GetColor(this.currentCompany.id));
        pageName = currentCompany.name+"PUBLIC_PAGE"
        
        if (!currentCompany.countStories || currentCompany.countStories == 0  || currentCompany.frequentTags == null || (currentCompany.frequentTags != null &&  this.getResult(currentCompany.frequentTags))) {
          this.showAside = true;
        } else {
          this.showAside = false;
        }
        this.addMetaTags();
      }

    });

    this.LoadSuccessSubscriptin = this.actions$.pipe(
      ofType<ScriptActions.LoadSuccess>(ScriptActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((action:any) => {
         
      if(action && action.response.results && action.response.results.length){
        var scripts = action.response.results.filter(script => script.is_accessible);
        if(this.isBrowser){
          this.appendScriptinProduct(scripts);
        }

  
      }
    });

    this.LoadButtonColorSuccessSubscriptin = this.actions$.pipe(
      ofType<Saction.storyActions.GetColorSuccess>(Saction.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action:any) => {
      
       if(action && action.response && action.response && action.response.length){
          this.naviagtion_button_COlor = action.response.find(item => item.button == 6).color
          this.footerColor = action.response.find(item => item.button == 1).color
          this.submit_Story_Color = action.response.find(item => item.button == 8).color
          this.submit_Story_text = action.response.find(item => item.button == 10).color
       }
    });
  }

  public appendScriptinProduct(script){

    let googleAnalyticsScript = script.find(scripttype => scripttype.type == 'GoogleAnalytics');
    let OtherScripts = script.filter(scripttype => scripttype.type != 'GoogleAnalytics');

    if(googleAnalyticsScript && $("#pvt__scripts").html() === ""){
      this.googleAnalyticsScripts(googleAnalyticsScript.measurement_id)
      measuremnetId = googleAnalyticsScript.measurement_id

      setTimeout(()=>{
        gtag('event', 'page_view', {
          page_title: pageName,
          page_path: '/company',
          page_location: window.location.href,
          send_to: measuremnetId
       })
      },5000)
      
    }
    else if(googleAnalyticsScript && $("#pvt__scripts").html() != ""){
      setTimeout(()=>{
        gtag('event', 'page_view', {
          page_title: pageName,
          page_path: '/company',
          page_location: window.location.href,
          send_to: measuremnetId
       })
      },5000)
    }
     
    if(OtherScripts){
      OtherScripts.forEach((item)=>{
        if(item.head){
        //  let scriptString = $.parseHTML(item.head)
        // const parser = new DOMParser();
        // const doc = parser.parseFromString(item.head, 'text/html');
        //  document.getElementsByTagName('head')[0].append(doc.body.firstChild)
        $(item.head).appendTo(document.head)
  
        }
        if(item.body){
          $(item.body).appendTo(document.getElementById('analytics-gskr-scripts'))
        }
      })
    }




  }

  getResult(tag){
    if(tag){
      if((!tag['2'].is_header_part ) &&
      (!tag['8'].is_header_part) &&
      (!tag['13'].is_header_part ) &&
      (!tag['14'].is_header_part )){
      return true;
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  
  }

  isRouteActive(route: string): boolean {
      if(this.router.url == route){
        return true;
      }
      else{
        return false;
      }
  }

  getDynamicColor(route: string): string {
    let modified = route == 'company' ? '/c/'+this.currentCompany.slug : '/c/'+this.currentCompany.slug+'/'+route
    return this.isRouteActive(modified) ? this.naviagtion_button_COlor : '';
  }


  public googleAnalyticsScripts(tag){
  /**
   *    let elementScript = document.createElement('script');
         elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
         elementScript.async = true;
         document.getElementById('pvt__scripts').appendChild(elementScript);
    let elementScriptText = document.createElement('script');
    elementScriptText.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', ${tag},{send_page_view: false});`
    document.getElementById('pvt__scripts').appendChild(elementScriptText);*/
    globalTag = tag;
    let elementScript = document.createElement('script');
    elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
    elementScript.async = true;
    document.getElementById('pvt__scripts').appendChild(elementScript);
  
    // Wait for the script to load before calling gtag()
    elementScript.onload = () => {
      // Ensure window.dataLayer is initialized
      window.dataLayer = window.dataLayer || [];
      
      // Define gtag function globally
      window['gtag'] = function gtag() {
        window.dataLayer.push(arguments);
      };
  
      // Initialize gtag
      gtag('js', new Date());
  
      // Configure Google Analytics
      gtag('config', globalTag, { send_page_view: false });
    };

  }

  private unsubscribe() {
    this.loadCompanyFailSubscription.unsubscribe();
    this.loadCompanySuccessSubscription.unsubscribe();
    this.selectCurrentCompanySubscription.unsubscribe();
    this.LoadSuccessSubscriptin.unsubscribe();
  }

  private resetStates() {
    this.store$.dispatch(new storyActions.ResetState());
    this.store$.dispatch(new searchBoxActions.ResetState());
  }

  private captureParams() {
    if (this.activatedRoute.snapshot.params.slug) {
      this.store$.dispatch(new companyActions.LoadPublicInfo(this.activatedRoute.snapshot.params.slug));
    } else {
      this.router.navigate(['/user/login']);
    }
  }

  // Public functions
  public login() {
    const nextUrl = window.location.href;
    this.router.navigate(['/user/login'], { queryParams: { nexturl: nextUrl } });
  }
  updateTagsForSSR(){
   this.companyHttpService.loadPublicInfo(this.activatedRoute.snapshot.params.slug).subscribe((res: any)=>{
    // console.log("res>>",res);
    this.currentCompany = res;
    this.addMetaTags();
   })
  }
}
