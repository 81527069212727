import { Component, SimpleChanges, Inject, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { ComponentBase } from '@framework/base/component';
import { StoryViewModel, CtaFormConfigViewModel } from '@modules/story/models/view-models';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { UtilityHelper } from '@framework/utilities';
import { SubmitCtaInputApiModel } from '@modules/story/models/api-models/input';
import { storyActions } from '@modules/story/store/actions';
import { CompanyViewModel } from '@modules/company/models/store-view-models';

import { companySelectors } from '@modules/company/store/selectors';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { Constants } from '@modules/shared/constants';
import { JobLinkacAction, nudgesSettingActions } from '@modules/company-admin/store/actions';
declare var $:any
declare const gtag: Function;

let measuremnetId;
let pagename;

@Component({
  selector: 'app-pb-nudge-preview',
  templateUrl: './nudge-preview.component.html',
  styleUrls: ['./nudge-preview.component.scss'],
})
export class NudgePreviewComponent extends ComponentBase {
  isSmallScreen: boolean = window.innerWidth <= 895;
  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    this.isSmallScreen = window.innerWidth <= 895;
  }
  @Output() nudgeEvnt = new EventEmitter<any>();
  @Input() data: any
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    const body = document.body, html = document.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    this.isBottom = window.scrollY + window.innerHeight === height;
  }

  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectCompany$: Observable<CompanyViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCompanySubscription: Subscription;
  private loadJlinkobsSubscription: Subscription;
  private selectStoryDetailSubscription: Subscription;
  private submitCtaSuccessSubscription: Subscription;
  private selectCurrentUserProfileSubscription: Subscription;
  private getTarackingSuccess: Subscription;
  private currentUserProfile: UserProfileViewModel;
  public loadNudgesettingsSubscription: Subscription;
  
  public alltrackingpayload: any;
  public jobstrackingpayload: any = [];
  // Public variables
  public storyDetail: StoryViewModel;
  public isShowNudge: boolean;
  public showJobslist:any[] = [];
  public isShowNudgeButton: boolean;
  public ctaForm: UntypedFormGroup;
  public showbbuttontext:any;
  public ctaStepActivated: number = 0;
  public interestSelected: CtaFormConfigViewModel;
  public currentCompany: CompanyViewModel;
  public isNudgeBasic: boolean = false;
  public isNudgeComplex: boolean = false;
  public isNudgeDonation: boolean = false;
  public isNudgeJob: boolean = false;
  public isShowToggleActive: boolean = false;
  public isBottom: boolean = false;
  public isFirst: boolean = true;
  public text_color:any;
  public button_color: any;
  public button_text_color:any
  public view_type:any;

  constructor(private store$: Store<CoreState>, private formBuilder: UntypedFormBuilder, private utility: UtilityHelper,
    private actions$: Actions) {
    super();
    this.initForm();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private initForm() {
    this.ctaForm = this.formBuilder.group({
      interested: ['', this.step1Require.bind(this)],
      email: ['', Validators.compose([Validators.email, this.step2Require.bind(this)])],
      fullName: ['', this.step2Require.bind(this)]
    });
  }

  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      if (storyDetail) {
        this.storyDetail = storyDetail;
        this.checkPermission();
        this.loadStorylinkedJobs()
        pagename = this.storyDetail.company.name+'PUBLIC_DETAIL_CTA'
        if(this.storyDetail && this.storyDetail.cta && this.storyDetail.cta.config){
          let data = JSON.parse(this.storyDetail.cta.config)
          if(data.button_text == '' || data.button_text == null){
            this.showbbuttontext = this.storyDetail.cta.button;
          }
          else{
            this.showbbuttontext = data.button_text;
          }
        }
        else{
          if(this.storyDetail && this.storyDetail.cta && this.storyDetail.cta.button){
            this.showbbuttontext = this.storyDetail.cta.button
          }
     
        }

      }
    });

    this.loadNudgesettingsSubscription = this.actions$.pipe(
      ofType<nudgesSettingActions.LoadNudgeSettingsSuccess>(nudgesSettingActions.ActionTypes.LOAD_NUDGE_SETTING_SUCCESS)
    ).subscribe((action:any) => {
       if(action && action.response){
         this.text_color = action.response.text_color;
         this.button_color = action.response.button_color;
         this.button_text_color = action.response.button_text_color
         this.view_type = action.response.view_type;
       }
    });
    
    this.submitCtaSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.SubmitCtaSuccess>(storyActions.ActionTypes.SUBMIT_CTA_SUCCESS)
    ).subscribe(() => {
      this.ctaStepActivated = 3;
    });

    this.loadJlinkobsSubscription = this.actions$.pipe(
      ofType<JobLinkacAction.GetLinkJobsSuccess>(JobLinkacAction.ActionTypes.GET_LINKED_JOBS_SUCCESS)
    ).subscribe((action:any) => {
      if(action.response){
        this.showJobslist = action.response?.filter(job => job.is_accessible);
        // this.addTrackingforall();
      }
    });
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.currentCompany = company;
        this.store$.dispatch(new nudgesSettingActions.LoadNudgeSettings(this.currentCompany.id));
      }
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.checkPermission();
      }
    });

    this.getTarackingSuccess = this.actions$.pipe(
      ofType<storyActions.AddTrackingSuccess>(storyActions.ActionTypes.ADD_TRACKING_SUCCESS)
    ).subscribe((action:any) => {
      this.alltrackingpayload = action.data;
      this.fiterJobsandCtaPayload();
    });
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.submitCtaSuccessSubscription.unsubscribe();
    this.selectCompanySubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }
  public fiterJobsandCtaPayload(){
  
    if(this.alltrackingpayload){

      this.alltrackingpayload.forEach((item,i)=>{

        if(Object.keys(item).includes('job_id')){
          this.jobstrackingpayload.push(item);
        }
      })
    }
  }
  private checkPermission() {
    const isAuthor = this.storyDetail &&
      this.currentUserProfile &&
      this.storyDetail.author.authorId === this.currentUserProfile.id;
    this.isShowNudge = this.storyDetail &&
      this.storyDetail.cta && this.storyDetail.cta.type &&
      (this.storyDetail.public === true || isAuthor);

    this.isNudgeBasic = this.storyDetail && this.storyDetail.cta && this.storyDetail.cta.type === Constants.nudgeMode.basic;
    this.isNudgeComplex = this.storyDetail && this.storyDetail.cta && this.storyDetail.cta.type === Constants.nudgeMode.advanced;
    this.isNudgeDonation = this.storyDetail && this.storyDetail.cta && this.storyDetail.cta.type === Constants.nudgeMode.donation;
    this.isNudgeJob = this.storyDetail && this.storyDetail.cta && this.storyDetail.cta.type === Constants.nudgeMode.jobs;
    this.isShowNudgeButton = (this.storyDetail &&
      this.storyDetail.ctaFormConfigs &&
      this.storyDetail.ctaFormConfigs.length > 0
    ) || this.isNudgeBasic;
  }

  private step1Require(control: UntypedFormControl) {
    if (this.ctaStepActivated !== 1) { return null; }

    const value = control.value;
    if (!value) {
      return {
        isCustom: true,
        message: 'Required.'
      };
    }
    return null;
  }

openComplexForm(){
  this.toggleShowActive();
  this.openStep1();
}
  private step2Require(control: UntypedFormControl) {
    if (this.ctaStepActivated !== 2) { return null; }

    const value = control.value;
    if (!value) {
      return {
        isCustom: true,
        message: 'Required.'
      };
    }
    return null;
  }

  // Public functions
  public openStep1() {
    this.ctaStepActivated = 1;
  }

  public openStep2() {
    this.ctaForm.controls.interested.updateValueAndValidity();
    this.ctaForm.controls.interested.markAsDirty();
    if (!this.ctaForm.valid) { return; }

    this.interestSelected = this.storyDetail.ctaFormConfigs.find(f => f.title === this.ctaForm.controls.interested.value);
    if (this.interestSelected.isRequiredInfo) {
      this.ctaStepActivated = 2;
      return;
    }

    this.ctaFormSubmit();
  }

  public closeStep() {
    this.ctaStepActivated = 0;
    this.toggleShowActive();
  }

  get isMobile() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  private loadStorylinkedJobs(){
    this.store$.dispatch(new JobLinkacAction.GetLinkJobs(this.storyDetail.id));
  }
  public ctaFormSubmit() {
    if(this.data){
      measuremnetId = this.data
      gtag('event', 'click', {
        button_title: pagename,
        send_to: measuremnetId
     })
    }
    this.utility.Form.updateFormGroupValidity(this.ctaForm);
    this.utility.Form.markFormGroupDirty(this.ctaForm);
    if (!this.ctaForm.valid) { return; }

    const submitData: Partial<SubmitCtaInputApiModel> = {
      content: {
        question_text: this.ctaForm.controls.interested.value
      },
      template_id: this.storyDetail.cta.config,
      cta_button: this.storyDetail.cta.button
    };
    if (this.interestSelected.isRequiredInfo) {
      submitData.full_name = this.ctaForm.controls.fullName.value;
      submitData.email = this.ctaForm.controls.email.value;
    }
    this.store$.dispatch(new storyActions.SubmitCta(this.storyDetail.id, submitData));
  }

  public toggleShowActive() {
    this.isShowToggleActive = !this.isShowToggleActive;
    this.isFirst = false;
  }

  getTrackingId(){

  }

  addTrackingforall(){
  //   var store = []
  //   let storypayload = {
  //     "resource_id": this.storyDetail.id,
  //     "resource_type": "story",
  //   }
  //   store.push(storypayload);
  //  if(this.showJobslist.length){
      
  //     this.showJobslist.forEach((item)=>{
  //       let jobspayload = {
  //         "resource_id": this.storyDetail.id,
  //         "resource_type": "story",
  //         "job": item.id
  //       }
  //       store.push(jobspayload)
  //     })
  //  }
  //  if(this.storyDetail && this.storyDetail?.cta?.cta_id_company_cta){
  //    let ctaPayload = {
  //     "resource_id": this.storyDetail.id,
  //     "resource_type": "story",
  //     "cta": this.storyDetail?.cta?.cta_id_company_cta
  //    }
  //    store.push(ctaPayload)
  //  }

  //    this.store$.dispatch(new storyActions.AddTracking(store))
  }

  public jobbHiits(item){
    // if(this.jobstrackingpayload.length){
    // let obj ={"tracking_id":this.jobstrackingpayload.find(job => job.job_id == item.id).tracking_id}
    //  this.addtrackhit(obj);
    // }
    window.open(item.url, '_blank');
  }
  public addCtaHit(url){
    // let obj ={"tracking_id":this.alltrackingpayload.find(cta => Object.keys(cta).includes('cta')).tracking_id}
    // this.addtrackhit(obj)
    if(this.data){
      measuremnetId = this.data
      gtag('event', 'click', {
        button_title: pagename,
        button_url: url,
        send_to: measuremnetId
     })
    }


    if(url.includes("https://") && url.split('//').length > 1){
    window.open(url, '_blank');
    }
    else{
      let data = 'https://'+url;
      window.open(data, '_blank');
    }
   

  }

  openDonation(){
    if(this.data){
      measuremnetId = this.data
      gtag('event', 'click', {
        button_title: pagename+'_DONATION',
        send_to: measuremnetId
     })
    }
    this.isShowToggleActive = false;
    this.isFirst = false;
 this.nudgeEvnt.emit()
  }
  addtrackhit(obj){
    this.store$.dispatch(new storyActions.AddTrackHit(obj));
  }
}
