import { Component, Input, forwardRef, Output, EventEmitter, OnChanges, ElementRef, Renderer2, OnInit, OnDestroy, ViewChildren, HostListener, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CoreState } from '@app/store/core/states';
import { PaginationModel } from '@framework/models/pagination-models';
import { storyActions, storyBuilderActions, storyStepActions } from '@modules/story/store/actions';
import { storyBuilderSelector, storySelector, storyStepSelector } from '@modules/story/store/selectors';
import { LoadUserInputApiModel } from '@modules/user/models/input';
import { userActions } from '@modules/user/store/actions';
import { UserProfileViewModel, UserViewModel } from '@modules/user/store/models';
import { userProfileSelectors, userSelectors } from '@modules/user/store/selectors';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { StoryFacetedSearchViewModel } from '@modules/story/models/store-view-models';
import "quill-mention";
import * as $ from 'jquery';
import * as Quill from 'quill';
import { Actions, ofType } from '@ngrx/effects';
import { ValidateUserOutApiModel } from '@modules/user/models/output';
import {  CompanyViewModel } from '@modules/company/models/store-view-models';
import { UtilityHelper } from '@framework/utilities';
import { StepTagSettingViewModel } from '@modules/company-admin/store/models/store-view-models';
import { CreateTagInputApiModel, SearchStoryInputApiModel } from '@modules/story/models/api-models/input';
import { TagViewModel } from '@modules/story/models/view-models';
import { Constants } from '@modules/shared/constants';
import { companySelectors } from '@modules/company/store/selectors';



let atValues = [];
let atTags = [];
let isTagOpeartion = false;
let isPlayerOperation = false;
let gSearchedTerm = '';
let lastRetainIndex;
let isMobileView = false;
let hideTagsForclose = false;

let Font = Quill.import('formats/font');

Font.whitelist = ['arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'];
Quill.register(Font, true);
// Quill.focus();
// let Parchment = Quill.import('parchment');
// let pspacing = new Parchment.Attributor.Style('pspacing', 'padding-top');
// Quill.register(pspacing, true);
// let insertNewline = function(range, context, formattingChanges) {
//     // Based on modules/keyboard.js handleEnter
//     if (range.length > 0) {
//       Quill.scroll.deleteAt(range.index, range.length);  // So we do not trigger text-change
//     }
//                 console.log(context)
  
//     let lineFormats = Object.keys(context.format).reduce(function(lineFormats, format) {
//       if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
//         lineFormats[format] = context.format[format];
//       }
//       return lineFormats;
//     }, {});
//     console.log(lineFormats)
//     // Quill.insertText(range.index, '\n', lineFormats, Quill.sources.USER);
//     // Earlier scroll.deleteAt might have messed up our selection,
//     // so insertText's built in selection preservation is not reliable
//     // Quill.setSelection(range.index + 1, Quill.sources.SILENT);
//     // Quill.focus();
//     Object.keys(context.format).forEach((name) => {
//       if (lineFormats[name] != null) return;
//       if (Array.isArray(context.format[name])) return;
//       if (name === 'link') return;
//       Quill.format(name, context.format[name], Quill.sources.USER);
//     });
//     // console.log(Quill.formatLine(range.index + 1, 1, formattingChanges, Quill.sources.USER))
//     Quill.formatLine(range.index + 1, 1, formattingChanges, Quill.sources.USER);
//   }

@Component({
    selector: 'rte-control',
    templateUrl: './rte-control.component.html',
    styleUrls: ['./rte-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RteControlComponent),
            multi: true
        }
    ]
})
export class RteControlComponent implements ControlValueAccessor, OnChanges, OnInit, OnDestroy {
    showMentionPlayerForm = false;
    updadateformtags: boolean = false;
    @Input() isEditable: boolean = false;
    @Input() hideQuestion:boolean = false;
    storeActivatedtags = [];
    showtex:any;
    public disabled: boolean = false;
    public selctedDataForTagsHistory: any;
    mentionPlayerForm: UntypedFormGroup;
    selctedDataForTags = [];
    public temporaryStore = [];
    public keyPress: number = 0;
    public isTagAdding: boolean = false
    public stepTags = [];
    public maxtaglength: number = 0;
    public domEles;
    public domlength: 0;
    public maxTagInserted = 5;
    public ismention: boolean = true;
    isPlayerCreating = false;
    private selectTagCreated$: Observable<TagViewModel>;
    quillModule = {
        toolbar: [
            ['bold', 'italic', 'underline',],
            [{ 'align': [] }],
            ['blockquote', 'link'],
            [{ 'color': [] }],
            [{ 'list': 'bullet' }],
            [{ 'header': [2, 3, 4, false] }],
            [{ 'font': ['', 'arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'] }],
            ['clean'] 
        ],
        // keyboard: {
        //     bindings: {
        //       'enter': {
        //         key: 13,
        //         shiftKey: false,
        //         handler: function (range, context) {
        //           insertNewline(range, context, {'pspacing': false});
        //           return false;
        //         }
        //       }
        //     }
        //   },
        mention: {
            allowedChars: /^[A-Za-z0-9\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@", "#"],

            onSelect: (item, insertItem) => {

                if (item.denotationChar == '#') {
                    if (this.selctedDataForTagsHistory) {
                        let keys = Object.keys(this.selctedDataForTagsHistory.steps);
                        var stepstoCheckedWHileInserting;
                        stepstoCheckedWHileInserting = atTags.find(tag => tag.id == +item.id);
                        if (keys.includes(stepstoCheckedWHileInserting.step_active.toString())) {

                            if (this.selctedDataForTagsHistory.steps[stepstoCheckedWHileInserting.step_active].length == this.maxTagsINserted) {
                                return
                            }
                            else if (this.selctedDataForTagsHistory.steps[stepstoCheckedWHileInserting.step_active].includes(+item.id)) {
                                return
                            }
                            else {
                                this.selctedtagsdataforfirst = item;
                                insertItem(item)
                            }
                        }
                        else {
                            this.selctedtagsdataforfirst = item;
                            insertItem(item)
                        }

                    }
                    else {
                        this.selctedtagsdataforfirst = item;
                        insertItem(item)
                    }


                }
                else {
                    this.selctedplayersforfirsttime = item;
                    insertItem(item)
                }

            },
            source: function (searchTerm, renderList, mentionChar) {
                let values;

                if (mentionChar === "@") {
                    values = atValues;
                    isPlayerOperation = true;
                    isTagOpeartion = false;
                }


                if (mentionChar === "#") {
                    values = atTags;
                    isPlayerOperation = false;
                    isTagOpeartion = true;
                }



                if (searchTerm.length === 0) {
                    let form = document.getElementById("custom-form");
                    if (form && values.length) {
                        form.style.display = "none";
                        form.style.position = "absolute";
                        form.style.zIndex = "99999";
                      
                        let tagadd = document.getElementById("custom-tag-add");
                        tagadd.style.display = "none";
                        tagadd.style.position = "absolute";
                        tagadd.style.zIndex = "99999";
                    }
                    renderList(values, searchTerm);
                } else {
                    gSearchedTerm = searchTerm;
                    const item = document.getElementsByClassName("ql-mention-list-container");
                    const matches = [];
                    for (let i = 0; i < values.length; i++)
                        if (mentionChar == "@") {
                            if (
                                ~values[i].displayName.toLowerCase().indexOf(searchTerm.toLowerCase())
                            )
                                matches.push(values[i])
                        }
                        else if (mentionChar == "#") {
                            if (
                                ~values[i].name.toLowerCase().indexOf(searchTerm.toLowerCase())
                            )
                                matches.push(values[i])
                        }
                    ;
                    const searchTermIncludesEnter = (searchTerm.match(/\n/g) || []).length > 0;
                    const searchtermincludespace =(searchTerm.toString().match(/\s/g) || []).length > 0;
                    if(isPlayerOperation){
                        if (!matches.length && !searchTermIncludesEnter && !searchtermincludespace) {
                            let form = document.getElementById("custom-form");
                            if (form) {
                                let formCloseButton = document.getElementById("custom-form-close");
                                formCloseButton.click();
                                form.style.display = "block";
                                form.style.position = "absolute";
                                form.style.zIndex = "99999";
                                if (item && item.length) {
                                    form.style.top = item[0]['offsetTop'] + 'px';
                                    form.style.left = item[0]['offsetLeft'] + 'px';
                                }
                            }
                        }
    
                        let form = document.getElementById("custom-form");
                        if ((form && matches.length)) {
                            form.style.display = "none";
                            form.style.position = "absolute";
                            form.style.zIndex = "99999";
                        }
                    }
                    if(isTagOpeartion){
                        if (!matches.length && !searchTermIncludesEnter && !searchtermincludespace ) {
                            let form = document.getElementById("custom-form");
                            if (form) {
                                let formCloseButton = document.getElementById("custom-form-close");
                                formCloseButton.click();
                                form.style.display = "block";
                                form.style.position = "absolute";
                                form.style.zIndex = "99999";
                                if (item && item.length) {
                                    if(isMobileView){
                                        form.style.top = item[0]['offsetTop'] + 50 +'px';
                                        form.style.left = item[0]['offsetLeft'] + 'px'
                                    }
                                    else{
                                        form.style.top = item[0]['offsetTop'] + 20 +'px';
                                        form.style.left = item[0]['offsetLeft'] + 'px'
                                    }
;
                                }
                            }
                        }
    
                        let form = document.getElementById("custom-form");
                        let tagadd = document.getElementById("custom-tag-add");
                        if ((form && matches.length) || searchtermincludespace) {
                            isTagOpeartion = false;
                            form.style.display = "none";
                            form.style.position = "absolute";
                            form.style.zIndex = "99999";


                            tagadd.style.display = "none";
                            tagadd.style.position = "absolute";
                            tagadd.style.zIndex = "99999";
                            $(".ql-mention-list-container").css('height', 'auto');


                        }
                        if((form && matches.length) && !searchtermincludespace){

                            tagadd.style.display = "block";
                            tagadd.style.position = "absolute";
                            tagadd.style.zIndex = "99999";
                            tagadd.style.top = +item[0]['offsetTop'] + 10+'px';
                            tagadd.style.left = +item[0]['offsetLeft'] +searchTerm.length+30+'px';
                            tagadd.style.width = '36%';
                            $(".ql-mention-list-container").css('height', 'auto');
                            
                        }
                    }

                    renderList(matches, searchTerm);
                }
                const editorElement = document.getElementsByClassName("ql-editor");
                const mentionListElement = document.getElementsByClassName("ql-mention-list-container");
                if (editorElement && editorElement.length && mentionListElement && mentionListElement.length) {
                    let mentionListElementLeft = mentionListElement[0]['offsetLeft'];
                    if (mentionListElementLeft >= editorElement[0]['offsetWidth'] - 270) {
                        mentionListElement[0]['style'].left = (editorElement[0]['offsetWidth'] - 270) + 'px';
                    }
                }
            },
            renderItem: (item, searchTerm, mentionedChar) => {
                if (Object.keys(item).includes('avatar')) {
                    return `
                <div style="display:flex;align-items:center">
                <img src="${item.avatar ? item.avatar : 'assets/front-end-minify/img/avatar-default.png'}"  onerror="this.src='assets/front-end-minify/img/avatar-default.png'" class="avatar">
                <span style="margin-left:10px" class="l-people-result__name">${item.displayName}</span>
                </div>
                `;
                }
                else {
                    return `
                 <div style="display:flex;justify-content:space-between;white-space: nowrap;">
                 <div>
                 <span style="margin-left:10px" class="l-people-result__name">${item.name}</span>
                 </div>
                 <div><span style="color:${item.color}; font-size: 10px;
                 font-weight: 700;">${item.title}</span><div>
                 </div>   

                `;
                }

            }
        }
    }

    quillData: string;
    fontSize = '2em';

    private loadUserInputData: LoadUserInputApiModel = new LoadUserInputApiModel(new PaginationModel(100));
    public members: any = [];
    public selctedtagsdataforfirst:any;
    private getMembers$: Observable<UserViewModel[]>;
    private selectStoryStep$: Observable<any>;
    public  selctedplayersforfirsttime: any;
    private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
    private selectInbound$: Observable<boolean>;
    private selectAuth$: Observable<any>;
    private isInbound: boolean = false;
    public storySteps: any[] = []
    public currentsecltedcell: number = 0;
    private selectInboundSubscription: Subscription;
    private selectStoryStepSubscription: Subscription;
    private selectCurrentUserProfileSubscription: Subscription;
    private validateUserSuccessSubscription: Subscription;
    public checkfornull: boolean = true
    private selectTagCreatedSubscription: Subscription;
    private selectStepSelectedPlayersData$: Observable<any>;
    private selectStepSelectedData$: Observable<any>;
    private selectStepSelectedPlayersDataSubscription: Subscription;
    private getMembersSubscription: Subscription;

    public showPlayerForm: boolean = false;
    public showTagsForm: boolean = false;

    @Input() setup = 'Basic';
    @Input() autoFocus = false;
    @Input() debounceTime = 0;
    @Input() placeholder: string;
    @Input() maxLength = -1;
    @Input() id = '';
    @Input() companyId;
    @Input() mentionRequired = true;
    @Input() placeholderText = 'Start your story here.'
    @Output() blur: EventEmitter<string> = new EventEmitter<string>();
    @Output() focusElement: EventEmitter<string> = new EventEmitter<string>();

    @ViewChildren('box') box: any;
    private isVisible = false;
    public isDisabled = false;
    public charCounter = -1;
    private selectAuthSubscription: Subscription;
    private selectCurrentCompanySubscription: Subscription;
    private selectCurrentCompany$: Observable<CompanyViewModel>;
    private queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
    public currentUserProfile: UserProfileViewModel;

    public fnOnChange: any = () => { };
    public fnOnTouch: any = () => { };
    public mentionedUsers = [];
    public selectedPlayers = [];
    public selectedTagsData = [];
    public selectedTags = [];
    public mentionedTags = [];
    public hastags = [];
    public maxTagslength = 5;
    public maxTagsINserted = 5;
    constructor(private utility: UtilityHelper, private actions: Actions, private store$: Store<CoreState>, private el: ElementRef, private renderer: Renderer2, private changeDector : ChangeDetectorRef) {
        this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
        this.getMembers$ = this.store$.select(userSelectors.selectUsers);
        this.selectInbound$ = this.store$.select(storyBuilderSelector.selectInbound);
        this.selectTagCreated$ = this.store$.select(storyStepSelector.selectCreatedTag);
        this.selectStepSelectedPlayersData$ = this.store$.select(storyBuilderSelector.selectStoryStepSelectedPlayers);
        this.selectStoryStep$ = this.store$.select(storyStepSelector.selectStorySteps);
        // this.selectStepSelectedData$ = this.store$.select(storyBuilderSelector.selectStoryStepSelectedTags);
        this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    }

    // @HostListener('keyup', ['$event'])
    // public keyEvent(event: KeyboardEvent) {
    //     const keyCode = event.keyCode || event.which;
    //     if(!this.showMentionPlayerForm && this.showTagsForm && isTagOpeartion){
    //         if (keyCode === 38) {
    //             if(this.keyPress == 0) {
    //                 this.keyPress = this.maxTagslength - 1;
    //             }
    //             else{
    //                 this.keyPress = this.keyPress - 1;
    //             }
    //             if(this.box){
    //                 setTimeout(()=>{
    //                     // this.box._results[this.keyPress].nativeElement['focus']();
    //                     // this.box._results[this.keyPress].nativeElement['autofocus'] = true;
    //                 },100)
                    
    //             }
               
                
    //         }
    //         if (keyCode === 40) {
    //             if(this.keyPress == this.maxTagslength-1) {
    //                 this.keyPress = 0
    //             }
    //             else{
    //                 this.keyPress = this.keyPress + 1;
    //             }
    //             if(this.box){
    //                 // setTimeout(()=>{
    //                 //     this.box._results[this.keyPress].nativeElement['focus']();
    //                 //     this.box._results[this.keyPress].nativeElement['autofocus'] = true;
    //                 // },100)
    //             }
    //         }
    //         if(keyCode == 13){

    //             this.onTagChange(this.storeActivatedtags[this.keyPress]) 
    //         }
    //     }
       
    // }

    public set value(val: string) {
        if (val !== undefined) {
            this.quillData = val;
        }
        if (val) {
            this.fontSize = "1.375rem";
        } else {
            this.fontSize = "2em";
        }
    }

    public get value() {
        return this.quillData;
    }

    contentChanged(e) {
        lastRetainIndex = e.delta.ops[0].retain;
        var check
        var objectkeys = Object.keys(e.delta.ops[0]).includes('delete')

        this.showPlayerForm = isPlayerOperation;
        this.showTagsForm = isTagOpeartion;
        // if(!isPlayerOperation && !isTagOpeartion){
        //     let form = document.getElementById("custom-form");
        //     let formCloseButton = document.getElementById("custom-form-close");
        //     form.style.display = "none";
        //     form.style.position = "absolute";
        //     form.style.zIndex = "99999";
        // }
        this.showtex =gSearchedTerm
        if(isTagOpeartion){
            // this.domEles = document.querySelectorAll('.hastagsbox > *');

           
               setTimeout(()=>{
                // document.getElementById('hastagsbox').focus();
                // // document.getElementById('tagItem0').focus();
                //   let elemnt = this.box.map(para => {
                //     return para.nativeElement;
                //   })
                //   elemnt[0]['focus']()
                this.keyPress = 0
                // if(this.box){
                //     this.box.first.nativeElement['focus']();
                //     this.box.first.nativeElement['autofocus'] = true;
                // }

               },1000)
           
            // this.domlength = this.domEles.length;
        }

        if (
            e.delta &&
            e.delta.ops &&
            e.delta.ops.length == 2 &&
            e.delta.ops[0].retain &&
            e.delta.ops[1].insert
        ) {
            if (isPlayerOperation) {
                this.mentionedUsers = this.mentionedUsers.map(user => {
                    const mappedUserData = { ...user };
                    if (mappedUserData.retain > e.delta.ops[0].retain) {
                        ++mappedUserData.retain;
                    }
                    return mappedUserData;
                })
            }
            if (isTagOpeartion) {
                // this.mentionedTags = this.mentionedTags.map(tag => {
                //     const mappedTagData = { ...tag };
                //     if (mappedTagData.retain > e.delta.ops[0].retain) {
                //         ++mappedTagData.retain;
                //     }
                //     return mappedTagData;
                // })
            }

            if (e.delta.ops[1].insert.mention) {

                var denotationChar = e.delta.ops[1].insert.mention.denotationChar
                if (denotationChar == '@') {
                    const userId = isNaN(e.delta.ops[1].insert.mention.id) ? e.delta.ops[1].insert.mention.id : Number(e.delta.ops[1].insert.mention.id);
                    const userAvailable = this.mentionedUsers.findIndex(user => user.userId === userId);
                    if (userAvailable == -1) {
                        this.mentionedUsers.push({ retain: e.delta.ops[0].retain, userId: userId });
                        const selectedData = [...this.selectedPlayers];
                        this.mentionedUsers.forEach(user => {
                            if (!selectedData.includes(user.userId)) {
                                selectedData.push(user.userId)
                            }
                        })
                        this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(5, selectedData));
                    }
                }
                else if (denotationChar == '#') {
                    const tagId = isNaN(e.delta.ops[1].insert.mention.id) ? e.delta.ops[1].insert.mention.id : Number(e.delta.ops[1].insert.mention.id);
                    const tagAvailable = this.mentionedTags.findIndex(tag => tagId.tagId === tagId);
                    if (tagAvailable == -1) {
                        this.mentionedTags.push({ retain: e.delta.ops[0].retain, tagId: tagId });
                        var stepId = atTags.find(tag => tag.id == tagId)
                        var tags = this.CheckTagsHistory(stepId.step_active, tagId)
                        isTagOpeartion = false;
                        this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(stepId.step_active, tags, true));
                    }
                }

            }
            else if(e.delta.ops[1].insert == ' ' && this.selctedtagsdataforfirst != null && this.selctedtagsdataforfirst != undefined){
                var stepId = atTags.find(tag => tag.id == +this.selctedtagsdataforfirst.id)
                var tags = this.CheckTagsHistory(stepId.step_active, +this.selctedtagsdataforfirst.id)
                isTagOpeartion = false;
                this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(stepId.step_active, tags, true));
                this.selctedtagsdataforfirst = null;
            }
            else if(e.delta.ops[1].insert == ' ' &&   this.selctedplayersforfirsttime != null && this.selctedplayersforfirsttime != undefined && (this.selectedPlayers.findIndex(x => x.toString() == this.selctedplayersforfirsttime.id.toString())) == -1){
                this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(5, [+this.selctedplayersforfirsttime.id]));
                this.selctedplayersforfirsttime = null; 
            }
        }

        if (
            e.delta &&
            e.delta.ops &&
            e.delta.ops.length == 2 &&
            e.delta.ops[0].retain &&
            e.delta.ops[1].delete
        ) {

            var oldData = this.getoldData(e);
            var newData = this.getNewData(e);
            if ((newData != null && oldData != null) && (newData.length != oldData.length)) {

                var deletedData = this.getRemovedTagsMention(newData, oldData)
                if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '#')) {
                    isTagOpeartion = true;
                    isPlayerOperation = false;
                }
                else if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '@')) {
                    isTagOpeartion = false;
                    isPlayerOperation = true;
                }
            }
            else if (newData == null && oldData) {
                var deletedData = oldData;
                if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '#')) {
                    isTagOpeartion = true;
                    isPlayerOperation = false;
                }
                else if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '@')) {
                    isTagOpeartion = false;
                    isPlayerOperation = true;
                }
            }
            if (isPlayerOperation) {
                this.mentionedUsers = this.mentionedUsers.map(user => {
                    const mappedUserData = { ...user };
                    if (mappedUserData.retain > e.delta.ops[0].retain) {
                        --mappedUserData.retain;
                    }
                    return mappedUserData;
                })
                const userAvailableIndex = this.mentionedUsers.findIndex(user => user.retain === e.delta.ops[0].retain);
                if (userAvailableIndex > -1) {
                    const selectedData = [...this.selectedPlayers];
                    const selectedUserIndex = selectedData.findIndex(user => user === this.mentionedUsers[userAvailableIndex].userId);
                    this.mentionedUsers.splice(userAvailableIndex, 1);
                    if (selectedUserIndex > -1) {
                        selectedData.splice(selectedUserIndex, 1)
                    }
                    this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(5, selectedData));
                }
            }
            if (isTagOpeartion) {
                if (deletedData) {
                    var tagsdeletedData = this.CheckDeleteTagsHistory(+deletedData[0].insert.mention.id);
                    isTagOpeartion = false;
                    this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(tagsdeletedData.stepActive, tagsdeletedData.data, true));
                }
            }

        }

        if( e.delta &&
            e.delta.ops &&
            e.delta.ops.length == 1 &&
            objectkeys &&
            e.delta.ops[0].delete){

                var oldData = this.getoldData(e);
                var newData = this.getNewData(e);
                if ((newData != null && oldData != null) && (newData.length != oldData.length)) {
    
                    var deletedData = this.getRemovedTagsMention(newData, oldData)
                    if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '#')) {
                        isTagOpeartion = true;
                        isPlayerOperation = false;
                    }
                    else if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '@')) {
                        isTagOpeartion = false;
                        isPlayerOperation = true;
                    }
                }
                else if (newData == null && oldData) {
                    var deletedData = oldData;
                    if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '#')) {
                        isTagOpeartion = true;
                        isPlayerOperation = false;
                    }
                    else if (deletedData != null && (deletedData.length && deletedData[0].insert.mention.denotationChar == '@')) {
                        isTagOpeartion = false;
                        isPlayerOperation = true;
                    }
                } 

                if (isTagOpeartion) {
                    if (deletedData) {
                        var tagsdeletedData = this.CheckDeleteTagsHistory(+deletedData[0].insert.mention.id)
                        isTagOpeartion = false;
                        this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(tagsdeletedData.stepActive, tagsdeletedData.data, true));
                    }
                }
                if(isPlayerOperation){
                    if (deletedData) {
                        if(this.selctedDataForTagsHistory && Object.keys(this.selctedDataForTagsHistory.steps).includes('5')){
                            if(this.selctedDataForTagsHistory && this.selctedDataForTagsHistory.steps[5] != null){
                                if(this.selctedDataForTagsHistory.steps[5].findIndex(player=> player.toString() == deletedData[0].insert.mention.id.toString()) > -1){
                                    var players;
                                    var player = this.selctedDataForTagsHistory.steps[5].filter(x => x.toString() != deletedData[0].insert.mention.id.toString())
                                    if(player.length == 0){
                                        player = null
                                    }
                                    this.selctedDataForTagsHistory.steps[5] = player;
        
                                    this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(5,player));
                                }
                               
                            }
                        }
                    }
                }

            }

        if (!this.quillData) {
            this.fontSize = "2em";
        } else {
            this.fontSize = "1.375rem";
        }
    }

    getRemovedTagsMention(newData, oldData) {

        for (let i = 0; i < newData.length; i++) {
            var oIndex = oldData.findIndex(index => JSON.stringify(index) == JSON.stringify(newData[i]))
            if (oIndex > -1) {
                oldData.splice(oIndex, 1);
            }
        }

        if (oldData.length) {
            return oldData
        }
        else {
            return null
        }

    }
    getoldData(e) {
        var oldData = e.oldDelta.ops.filter(x => typeof x.insert == 'object');

        if (oldData.length) {
            return oldData
        }
        else {
            return null
        }
    }

    getNewData(e) {
        var newData = e.content.ops.filter(x => typeof x.insert == 'object');
        if (newData.length) {
            return newData
        }
        else {
            return null
        }
    }

    ngOnInit(): void {
        this.mentionPlayerForm = new UntypedFormGroup({
            firstName: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            tagName: new UntypedFormControl('', [Validators.required]),
            tagId: new UntypedFormControl('', [Validators.required]),
        })

        if(this.isMobile){
            isMobileView = true
        }

        this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {

            if(currentCompany){
                this.companyId = currentCompany.id 

               
            }
        })
        this.getMembersSubscription = this.getMembers$.subscribe((members) => {
            if (this.mentionRequired) {
                this.members = [...members];
                atValues = [...this.members.filter(member => member.storyInviteEnabled).map(member => {
                    const newData = { ...member };
                    newData.value = member.displayName;
                    return newData;
                })];

            } else {
                atValues = [];
            }
        });

        this.selectStepSelectedPlayersDataSubscription = this.selectStepSelectedPlayersData$.subscribe((selectedPlayers) => {

            var playerData = [];
            var tagData = []
            if (selectedPlayers && selectedPlayers.stepActive == 5) {
                playerData = selectedPlayers.steps[5]

                if (playerData && playerData.length) {
                    this.selctedDataForTagsHistory = selectedPlayers
                    this.selectedData = [...playerData];
                    this.selectedPlayers = [...playerData] || [];
                    this.mentionedUsers = [...this.mentionedUsers.filter(item => this.selectedPlayers.includes(item.userId))];
                } else {
                    this.selectedPlayers = [];
                    this.selectedData = []
                    this.mentionedUsers = [];
                }
            }

            else if (selectedPlayers && selectedPlayers.stepActive != 5) {
                this.selctedDataForTagsHistory = selectedPlayers;
                this.selctedDataForTags = this.selctedDataForTagsHistory.steps[selectedPlayers.stepActive]
                this.selectedTags = this.selctedDataForTagsHistory.steps[selectedPlayers.stepActive];

            }

        })


        this.selectTagCreatedSubscription = this.selectTagCreated$.subscribe((tag: TagViewModel) => {
            if (tag && this.isTagAdding) {

                // Do nothing if tag is existed
                var tagInserting = this.CheckTagsHistory(+tag['stepActive'], tag.id)
                var colorandStepname = this.storeActivatedtags.find(actTgs => actTgs.id == +tag['stepActive'])
                let tagObject = {
                    id: tag.id,
                    name: tag.name,
                    step_active: +tag['stepActive'],
                    value: tag.name,
                    color:colorandStepname.color,
                    title:colorandStepname.title,
                }
                this.isTagAdding = false;
                this.updadateformtags = true;
                var step = this.storySteps.find(step => step.id == tagObject.step_active)
                this.updateOutputData(tagObject, tagObject.step_active, tagInserting);
                this.updateSelectedValuesTags()

            }
        });




        this.selectInboundSubscription = this.selectInbound$.subscribe(data => {
            this.isInbound = data;
            this.loadMembers();
        })

        this.selectStoryStepSubscription = this.selectStoryStep$.subscribe((result: Array<StepTagSettingViewModel>) => {
           
           if(this.mentionRequired){
            if (result.length) {
                this.storySteps = result;
                var store = []

                this.storeActivatedtags = result.filter(tag => tag.is_active == 1 && tag.id != 17 && tag.id != 5 && tag.id != 16).map((tag)=>{
                    const newData = { ...tag };
                    newData['checked'] = false;
                    newData['icon'] = Constants.stepIcon.getStepIcon(tag.title.replace(/\s+/gi, ''));
                    return newData
                });
                this.maxTagslength = this.storeActivatedtags.length
                result = result.filter(tag => tag.id !=17)
                result.forEach(nested => {
                    if (nested.tags != null && nested.is_active == 1 && nested.id != 17 && nested.id != 5 && nested.id != 16) {
                        nested.tags.forEach(menu => { menu['step_active'] = nested.id; menu['title'] = nested.title; menu['color'] = nested.color });
                    }
                });
                for (let i = 0; i < result.length; i++) {

                    Array.prototype.push.apply(store, result[i]?.tags);
                }
                this.stepTags = store
                atTags = [...store.filter(tag => tag.is_active == 1).map(tags => {
                    const newData = { ...tags };
                    newData.value = tags.name;
                    return newData;
                })];
            }

           }
           else{
               atTags = [];
           }

        });

        this.validateUserSuccessSubscription = this.actions.pipe(
            ofType<userActions.ValidateUserSuccess>(userActions.ActionTypes.VALIDATE_USER_SUCCESS)
        ).subscribe((action: userActions.ValidateUserSuccess) => {
            this.validateUsersSuccess(action.response);
        });
    }


    get isMobile() {
        const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
    
        return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
        });
      }
    
    ngOnChanges() {
        if (!this.mentionRequired) {
            this.fontSize = "1.375rem";
        }
        this.loadMembers();

        console.log("placeholder",this.placeholder)

    }

    loadMembers() {
        if (this.companyId && !this.members.length) {
            this.loadUserInputData.companyId = this.companyId;
            this.loadUserInputData.inbound = this.isInbound;
            this.store$.dispatch(new userActions.Load(this.loadUserInputData));
        }
    }

    private loadTagsData() {
        this.store$.dispatch(new storyStepActions.Load(this.companyId, this.isInbound));
    }

    private loadMembersWithData(selectedData: any[]) {
        selectedData = selectedData || [];
        this.loadUserInputData.selectedValues = selectedData.filter(id => this.utility.Lodash.isNumber(id) && id > 0);
        let selectedEmails: string[] = [];
        const idsAsString = selectedData.filter(id => this.utility.Lodash.isString(id));
        idsAsString.forEach((sData: string) => {
            const emailParserRes = this.utility.StringHelper.emailParser(sData);
            selectedEmails.push(emailParserRes.email);
        });
        this.loadUserInputData.selectedEmails = selectedEmails;
        this.store$.dispatch(new userActions.Load(this.loadUserInputData));
    }

    private updateOutputData(tag, step, tagInserting) {

        this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(step, tagInserting, true));

        this.closeMentionPlayerForm();
        this.quillData
        setTimeout(() => {
            this.isPlayerCreating = false;
            this.deletehasTagSearchTerm(this.quillEditorInstance, tag);
        }, 500);

    }


    autofocusApplied: boolean = false;

    ngAfterViewChecked() {
        if (this.isVisible === false && this.el.nativeElement.offsetParent != null) {
            this.isVisible = true;
            if (this.autoFocus && !this.autofocusApplied) {
                this.focus(true);
            }
        } else if (this.isVisible === true && this.el.nativeElement.offsetParent == null) {
            this.isVisible = false;
        }
    }

    onBlur() {
        $('#story-edit-boost-box-cta').addClass('sticky');
        this.blur.emit(this.quillData);
    }

    onClick() {
        if (this.id === 'story-flow-post') {
            $('#story-edit-boost-box-cta').removeClass('sticky');
        }
    }

    debounce(fn, args, delay) {
        let timer = null;

        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(args);
            }, delay);
        };
    }

    private triggerChange($event) {
        this.fnOnChange($event);
        const event = new CustomEvent('change', { bubbles: true });
        this.el.nativeElement.dispatchEvent(event);
    }

    public writeValue(val: string): void {
        this.value = val;
    }

    public registerOnChange(fn: any): void {
        this.fnOnChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.fnOnTouch = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    editorCreated(e) {
        this.quillEditorInstance = e;
        // Quill.focus()
        if (this.autoFocus) {
            setTimeout(() => {
                e.focus();
                e.setSelection(this.quillData.length ? this.quillData.length : 0);
                const editableDivs = document.querySelectorAll('.ql-container.ql-bubble') ;
                const firstElemt = editableDivs[0] as HTMLElement
                firstElemt.click()

            }, 100);
        }

    }

    public focus(isFocus: boolean) {
        // setTimeout(() => {
        //     if (!this.editor.selection.inEditor()) {
        //         const focused = this.editor.events.focus(isFocus);
        //         if (focused === undefined) {
        //             this.editor.selection.setAtEnd(this.editor.$el.get(0));
        //             this.editor.selection.restore();
        //             this.autofocusApplied = true;
        //         }
        //     }
        // }, 1000);
    }

    public onChange($event) {
        if (this.debounceTime > 0) {
            this.debounce(this.triggerChange, $event, this.debounceTime);
        } else {
            this.triggerChange($event);
        }
    }

    public getInnerHtml() {
        return this.quillData;
    }

    addNewUser() {
        this.isPlayerCreating = true;
        this.validateUsers();
    }

    private validateUsers() {
        const emails = [this.mentionPlayerForm.value.email];
        this.store$.dispatch(new userActions.ValidateUser(emails));
    }

    private validateUsersSuccess(vuRes: ValidateUserOutApiModel[]) {
        const mentionPlayerFormValue = this.mentionPlayerForm.value;
        const checkAllowAddToStory = vuRes.find(f => f.email === mentionPlayerFormValue.email && f.story_invite_enabled === true);
        if (!checkAllowAddToStory) {
            this.mentionPlayerForm.controls.email.setErrors(
                {
                    isCustom: true,
                    message: 'Unavailable to add. Please try another email!'
                }
            );
        }

        this.addPlayer();
    }

    isPlayerAdding = false;
    public selectedData: any = [];

    private addPlayer() {
        this.isPlayerAdding = true;
        const mentionPlayerFormData = this.mentionPlayerForm.value;
        let createUserData = new UserViewModel(null);
        createUserData.firstname = mentionPlayerFormData.firstName;
        createUserData.lastname = '';
        createUserData.email = mentionPlayerFormData.email || '';
        createUserData.displayName = `${createUserData.firstname}`;
        createUserData.id = `${createUserData.firstname} <${createUserData.email}>`;
        this.members.unshift(createUserData);

        this.selectedData = this.selectedData || [];
        const newUserValue = `${createUserData.firstname} <${createUserData.email}>`;
        this.selectedData.push(newUserValue);
        this.mentionedUsers.push({ retain: lastRetainIndex - gSearchedTerm.length, userId: newUserValue });
        if (this.selectedData) {
            this.loadMembersWithData(this.selectedData);
        }
        this.addPlayerSuccess(createUserData);
    }

    private addPlayerSuccess(createUserData: UserViewModel) {
        this.updateSelectedValues();
        this.updateData(createUserData);
    }

    public updateData(createUserData: UserViewModel) {
        let selectedData = [...this.selectedData];
        if (selectedData && selectedData.length === 0) {
            selectedData = null;
        }
        this.selctedplayersforfirsttime = null;
        this.store$.dispatch(new storyBuilderActions.UpdateStoryStepSelected(5, selectedData));
        this.closeMentionPlayerForm();
        this.quillData
        setTimeout(() => {
            this.isPlayerCreating = false;
            this.deleteMentionSearchTerm(this.quillEditorInstance, createUserData);
        }, 500);
    }

    addMention(quill: any, user: UserViewModel) {
        atValues.push(user);

        quill.getModule("mention").insertItem(
            {
                denotationChar: "@",
                id: user.id,
                value: user.firstname,
            },
            false
        );
    }

    addTags(quill: any, tag: any) {


    }

    deleteMentionSearchTerm(quill: any, user: UserViewModel) {
        if (gSearchedTerm) {
            this.quillEditorInstance.deleteText(lastRetainIndex - gSearchedTerm.length, gSearchedTerm.length + 1);
            gSearchedTerm = '';
            setTimeout(() => {
                this.isPlayerCreating = false;
                this.addMention(quill, user);
            }, 500);
        }
    }

    addTagsMentions(quill, tag) {
        atTags.push(tag);

        quill.getModule("mention").insertItem(
            {
                denotationChar: "#",
                id: tag.id,
                value: tag.name,
            },
            false
        );

    }

    deletehasTagSearchTerm(quill, tag) {
        if (gSearchedTerm) {
            this.quillEditorInstance.deleteText(lastRetainIndex - gSearchedTerm.length, gSearchedTerm.length + 1);
            gSearchedTerm = '';
            setTimeout(() => {
                this.isTagAdding = false;
                this.addTagsMentions(quill, tag);
            }, 500);
        }
    }
    private updateSelectedValues() {
        this.members.forEach((member: UserViewModel) => {
            member.checked = this.selectedData && (this.selectedData.indexOf(member.id) > -1 || this.selectedData.findIndex(f => typeof (f) == 'string' && f.indexOf(member.email) > -1) > -1);
        });
    }

    onFocus(event) {
        this.focusElement.emit(event)
    }

    showMentionPlayerFormEvent() {
        if (isTagOpeartion) {
            this.mentionPlayerForm.controls.tagName.setValue(gSearchedTerm)
        }
        this.showMentionPlayerForm = true;
    }

    closeMentionPlayerForm() {
        this.showMentionPlayerForm = false;
        hideTagsForclose = true;
        this.mentionPlayerForm.reset();
        let form = document.getElementById("custom-form");
        form.style.display = "none";
        form.style.position = "absolute";
        form.style.zIndex = "99999";
        let tagadd = document.getElementById("custom-tag-add");

        tagadd.style.display = "none";
        tagadd.style.position = "absolute";
        tagadd.style.zIndex = "99999";
        $(".ql-mention-list-container").css('height', 'auto');
    }

    quillEditorInstance: any;

    addNewtags() {
        // this.store$.dispatch(new storyBuilderActions.GotoStep(+this.mentionPlayerForm.value.tagId));
        let stepactive = +this.mentionPlayerForm.value.tagId
        this.mentionPlayerForm.controls.tagName.setValue(gSearchedTerm)
        this.store$.dispatch(new storyBuilderActions.SetStepActive(stepactive));
        if (this.selectedTagsData && (this.selectedTagsData.length > 5)) {
            return
        }
        this.store$.dispatch(new storyBuilderActions.UpdateViewMode('EDIT'));
        let createTagModel = new CreateTagInputApiModel();
        createTagModel.name = this.mentionPlayerForm.value.tagName;
        createTagModel.resource_id = +this.mentionPlayerForm.value.tagId
        createTagModel.resource_type = 'storystep';
        createTagModel.payload = { cid: this.companyId, inbound: this.isInbound };
        this.isTagAdding = true;

        this.store$.dispatch(new storyStepActions.CreateTag(+this.mentionPlayerForm.value.tagId, createTagModel));
    }

    updateSelectedValuesTags() {
        this.stepTags.forEach(tag => {
            tag.checked = this.selectedTags && this.selectedTags.indexOf(tag.id) > -1
        });
    }

    onTagChange(event) {
        if(gSearchedTerm.match(/\s/g)){
            return
        }
        isTagOpeartion = false;
        if (this.selctedDataForTagsHistory) {
            this.mentionPlayerForm.controls.tagId.setValue(+event.id)
            let keys = Object.keys(this.selctedDataForTagsHistory.steps);

            if (keys.includes(this.mentionPlayerForm.value.tagId.toString())) {
                if (this.selctedDataForTagsHistory.steps[this.mentionPlayerForm.value.tagId].length == this.maxTagsINserted) {
                    return 
                }
                else {
                    this.addNewtags();
                }
            }
            else {
                this.addNewtags();;
            }

        }
        else {
            this.mentionPlayerForm.controls.tagId.setValue(+event.id)
            this.addNewtags();;
        }
    }
    CheckTagsHistory(stepActive, tagId) {
        var tags = []
        var tagKeys;
        if (this.selctedDataForTagsHistory) {
            tagKeys = Object.keys(this.selctedDataForTagsHistory.steps)

            if (tagKeys.includes(stepActive.toString())) {

                tags = this.selctedDataForTagsHistory.steps[stepActive];
                if (!tags.includes(tagId)) {
                    tags.push(tagId)
                }
                this.selctedDataForTagsHistory.steps[stepActive] = tags;
                return this.selctedDataForTagsHistory.steps
            }
            else {
                if (!tags.includes(tagId)) {
                    tags.push(tagId)
                }
                this.selctedDataForTagsHistory.steps[stepActive] = tags;
                return this.selctedDataForTagsHistory.steps
            }
        }
        else {
            if (!tags.includes(tagId)) {
                tags.push(tagId)
            }
            return { [stepActive]: tags }
        }



    }

    addInstanttag(){
        if (isTagOpeartion) {
            this.mentionPlayerForm.controls.tagName.setValue(gSearchedTerm)
        }
        let form = document.getElementById("custom-form");
        if (form) {
            let formCloseButton = document.getElementById("custom-form-close");
            formCloseButton.click();
            form.style.display = "block";
            form.style.position = "absolute";
            form.style.zIndex = "99999";
            const item = document.getElementsByClassName("ql-mention-list-container");
            if (item && item.length) {
                //before 10 now
                form.style.top = +item[0]['offsetTop'] + 10 +'px';
                form.style.left = +item[0]['offsetLeft'] + 'px';
            }
            $(".ql-mention-list-container").css('height', '0px');
            this.showTagsForm = true;
            this.showMentionPlayerForm = false;
        }
    }
    onItemSeclect(event){}
    OnItemUnselct($event){}

    CheckDeleteTagsHistory(tagId) {
        var tags = []
        var indextobedeletd;
        var keysStore;
        var stepActive;
        var keyTobeSaved;
        if (this.selctedDataForTagsHistory) {

            keysStore = Object.keys(this.selctedDataForTagsHistory.steps)


            for (let i = 0; i < keysStore.length; i++) {

                let keyNumber = +keysStore[i]
                if (this.selctedDataForTagsHistory.steps[keyNumber] != null && this.selctedDataForTagsHistory.steps[keyNumber].length > 0) {
                    if (this.selctedDataForTagsHistory.steps[keyNumber].find(tag => tag == tagId)) {
                        keyTobeSaved = keyNumber
                    }
                }
            }

            tags = this.selctedDataForTagsHistory.steps[keyTobeSaved];



            tags = tags.filter(tg => tg != tagId)

            if (tags.length == 0) {
                tags = [];
            }
            this.selctedDataForTagsHistory.steps[keyTobeSaved] = tags;

            let obj = {
                stepActive: stepActive,
                data: this.selctedDataForTagsHistory.steps
            }

            return obj
        }
    }
    ngOnDestroy() {
        this.getMembersSubscription.unsubscribe();
        this.selectStepSelectedPlayersDataSubscription.unsubscribe();
        this.selectInboundSubscription.unsubscribe();
        this.validateUserSuccessSubscription.unsubscribe();
    }

    moveCell(e){
   
        console.log(e);
    }
    
}
