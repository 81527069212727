export interface DirectoryOutputApiModel {
    id: number;
    displayName: string;
    avatar: string;
}

export class DirectoryOutputApiModel {
    constructor(data?: Partial<DirectoryOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}


