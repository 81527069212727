export interface UpdateStore<T> {
    id: string;
    changes: Partial<T>;
}

export class UpdateStore<T>{
    constructor(id, changes: Partial<T>){
        this.id = id;
        this.changes = changes;
    }
}
