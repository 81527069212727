export interface CtaInputApiModel {
    text: string,
    button: string,
    config: any
}

export class CtaInputApiModel {
    constructor() {

    }
}