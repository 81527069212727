<div class="container">
  <div role="tabpanel" aria-labelledby="tab-story" class="tab-pane fade show active">
    <!-- Start Story-->
    <div class="my-stories m-story">
      <div class="m-story__content">
        <ul role="tablist" class="nav nav-tabs tabs-icon my-4">
          <li class="nav-item" *ngFor="let item of filter.fields" (click)="changeViewTab(item.key)">
            <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active': item.key === filter.active }">
              <span class="text text-uppercase">{{item.name}}</span>
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div class="tab-pane active" id="all" role="tabpanel">
            <skeleton-story-list [hidden]="!loadStoryStatus.isLoading || isLoadingMore" [countItem]="9"
              [cssClassItem]="'col-lg-6 col-xl-4 grid-item'"></skeleton-story-list>

            <div *ngIf="stories.length else nostory" gs-masonry [options]="{itemSelector:'.grid-item', columnWidth: '.grid-item', transitionDuration: '0', initLayout: 'false'}"
              [totalItem]="stories.length" [useImagesLoaded]="true" class="row grid">
              <user-pb-profile-story-item class="col-lg-6 col-xl-4 px-2 grid-item" gsMasonryItem [story]="story"
                [href]="getHref(story)"
                *ngFor="let story of stories; trackBy: trackByFunc" [stepsData]="stepsData">
              </user-pb-profile-story-item>
            </div>
          </div>
        </div>
      </div>
      <ng-template #nostory>
        <div class="text-center" style="margin: 120px auto;">
          <h4>No stories.</h4>
        </div>
      </ng-template>
      <div class="text-center" [hidden]="!isLoadingMore">
        <div class="lds-ellipsis-mint">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <!-- Close Story-->
  </div>
</div>