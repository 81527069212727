import { UserState } from '../states';
import { userActions } from '../actions';
import { UserEntity } from '../entities';
import { userAdapter, initialState } from '../adapters/user.adapter';


export function userReducer(state = initialState, action: userActions.Actions): UserState {
    switch (action.type) {

        case userActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case userActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new UserEntity(item));
            const newSate = userAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: action.response.count
            };
        }

        case userActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}