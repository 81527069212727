import { Component, SimpleChanges } from '@angular/core';

import { ComponentModalBase } from '@framework/base/component';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import {  CompanyViewModel } from '@modules/company/models/store-view-models';
import { storyActions } from '@modules/company-admin/store/actions';
import { CoreState } from '@app/store/core/states';
import { companySelectors } from '@modules/company/store/selectors';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-campaign-prompt-delete',
  templateUrl: './campaign-prompt-delete.component.html',
  styleUrls: ['./campaign-prompt-delete.component.scss']
})
export class CampaignDeleteConfrimation extends ComponentModalBase<any> {
  // Private variables
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  private selectCurrentCompanySubscription: Subscription;
  public loadColorSuccessSubscription:Subscription;
  public primary_color:any;
  public campaignConfrimation: UntypedFormGroup;
  // Public variables

  constructor( private actions$: Actions,   private store$: Store<CoreState>,     private formBuilder: UntypedFormBuilder,) {
    super();
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.campaignConfrimation = this.formBuilder.group({
      selectCheckbox: ['deleteTemporary', Validators.required],
  
    })
    
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {
    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.store$.dispatch(new storyActions.GetColor(currentCompany.id));
      }
    })
    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetColorSuccess>(storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primary_color = action.response.find(item => item.button == 11).color;
       }
      

    })
  }

  private unsubscribe() {
this.selectCurrentCompanySubscription.unsubscribe();
this.loadColorSuccessSubscription.unsubscribe();
  }
  // Public functions

  public cancel() {
    this.onClose.emit(false);
  }

  public confirm() {
    this.onClose.emit(true);
  }
}
