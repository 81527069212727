import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { StepOutputApiModel, TagOutputApiModel, CtaTemplateOutputApiModel } from './models/output';

@Injectable()
export class CompanyHttp1Service extends BaseHttpService {

    private endPoints = {
        stories: 'companies',
    };

    constructor(protected http: HttpClient) {
        super(http, '/companies');
    }

    getSteps(companyId: number, isInbound: boolean = false, tagSelected: string) {
        let apiUrl = '/' + companyId + '/setting/story-steps/';
        if (isInbound) {
            apiUrl = '/' + companyId + '/setting/story-steps/inbound/';
        }
        if (tagSelected) {
            apiUrl += `?selected=${tagSelected}`;
        }
        return this.get(apiUrl).pipe(
            map((response: Array<StepOutputApiModel>) => {
                return response.map(r => Object.assign({}, r, {
                    tags: r.tags && r.tags.map(tag => new TagOutputApiModel(tag))
                }));
            }));
    }

    getCtaTemplates(companyId: number, isInbound: boolean) {
        let url = '/' + companyId + '/setting/cta-templates/';
        if (isInbound === true) {
            url = '/' + companyId + '/setting/cta-templates/inbound/';
        }
        return this.get(url).pipe(
            map((response: Array<CtaTemplateOutputApiModel>) => {
                return response;
            }));
    }
}