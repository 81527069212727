import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { UserAttributeSettingOutputApiModel } from '../models/api-models/output';
import { UpdateUserAttributeInputApiModel, UpdateUserAttributeValueInputApiModel } from '../models/api-models/input';

@Injectable()
export class UserSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/users/attributes/`).pipe(
            map((response: UserAttributeSettingOutputApiModel[]) => {
                return response;
            })
        )
    }

    public addAttribute(companyId: number, payload: Partial<UpdateUserAttributeInputApiModel>) {
        return this.post(`/${companyId}/setting/users/attributes/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateAttribute(companyId: number, attributeId: number, payload: Partial<UpdateUserAttributeInputApiModel>) {
        return this.patch(`/${companyId}/setting/users/attributes/${attributeId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteAttribute(companyId: number, attributeId: number) {
        return this.delete(`/${companyId}/setting/users/attributes/${attributeId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public addAttributeValue(companyId: number, attributeId: number, payload: Partial<UpdateUserAttributeValueInputApiModel>) {
        return this.post(`/${companyId}/setting/users/attributes/${attributeId}/values/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateAttributeValue(companyId: number, valueId: number, payload: Partial<UpdateUserAttributeValueInputApiModel>) {
        return this.patch(`/${companyId}/setting/users/attributes/values/${valueId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteAttributeValue(companyId: number, valueId: number) {
        return this.delete(`/${companyId}/setting/users/attributes/values/${valueId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
