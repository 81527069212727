import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { campaignActions } from '../actions';
import { CampaignHttpService } from '../services';

@Injectable()
export class CampaignEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<campaignActions.Load>(campaignActions.ActionTypes.LOAD),
    switchMap((action: campaignActions.Load) => {
      return this.CampaignHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new campaignActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new campaignActions.LoadFail(error));
        }));
    })
  ));

  
  makeActiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<campaignActions.MakeActive>(campaignActions.ActionTypes.MAKE_ACTIVE),
    switchMap((action: campaignActions.MakeActive) => {
      return this.CampaignHttpService.makeActive(action.companyId, action.ids).pipe(
        map((respone) => {
          return new campaignActions.MakeActiveSuccess();
        }),
        catchError((error: any) => {
          return of(new campaignActions.MakeActiveFail(error));
        }));
    })
  ));

  
  makeInactiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<campaignActions.MakeInactive>(campaignActions.ActionTypes.MAKE_INACTIVE),
    switchMap((action: campaignActions.MakeInactive) => {
      return this.CampaignHttpService.makeInactive(action.companyId, action.ids).pipe(
        map((respone) => {
          return new campaignActions.MakeInactiveSuccess();
        }),
        catchError((error: any) => {
          return of(new campaignActions.MakeInactiveFail(error));
        }));
    })
  ));

  
  makePublicEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<campaignActions.TurnPublicOn>(campaignActions.ActionTypes.TURN_PUBLIC_ON),
    switchMap((action: campaignActions.TurnPublicOn) => {
      return this.CampaignHttpService.makePublic(action.companyId, action.ids).pipe(
        map((respone) => {
          return new campaignActions.TurnPublicOnSuccess();
        }),
        catchError((error: any) => {
          return of(new campaignActions.TurnPublicOnFail(error));
        }));
    })
  ));

  
  makePrivateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<campaignActions.TurnPublicOff>(campaignActions.ActionTypes.TURN_PUBLIC_OFF),
    switchMap((action: campaignActions.TurnPublicOff) => {
      return this.CampaignHttpService.makePrivate(action.companyId, action.ids).pipe(
        map((respone) => {
          return new campaignActions.TurnPublicOffSuccess();
        }),
        catchError((error: any) => {
          return of(new campaignActions.TurnPublicOffFail(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private CampaignHttpService: CampaignHttpService) { }
}
