import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService } from '@framework/services';

@Component({
  selector: 'copy-embed-embed-modal-container',
  templateUrl: './copy-embed-modal-container.component.html',
  styleUrls: ['./copy-embed-modal-container.component.scss'],
})

export class CopyEmbedVideoModalContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public copyForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public videoUrl:any;
  public videoPayload:any[] = [];
  public thumbnailUrl:any;
  public activeTab: string;
  private rssToken: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private rssFeedHttpService: RssFeedHttpService,
    private toastService: ToastService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {
    this.copyForm.patchValue({
      embedbodyCode: this.initialState.videoUrl,
    })
    this.copyForm.controls.styledbodyCode.setValue(`    /* Main area for all stories */
    .container {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
    }

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
`)

if(this.initialState.isvideo){
   var vido =   this.initialState.videoUrl.map((item,i)=>{
       let name = i+1
    return {
      name:'Video'+name,
      token: item.source,
      thumbnail_url:item.thumbnail_url
    }
   })

   this.videoPayload = vido;
   this.copyForm.controls.videourl.setValue(this.videoPayload[0].token)
   this.videoUrl = window.location.origin+'/video/embed/'+this.videoPayload[0].token;
   this.thumbnailUrl =this.videoPayload[0].thumbnail_url ? this.videoPayload[0].thumbnail_url : 'https://image.mux.com/'+this.videoPayload[0].token+'/thumbnail.jpg'

}

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
    this.copyForm = this.formBuilder.group({
      height: [900, Validators.required],
      width: [100, Validators.required],
      storyheight: [900, Validators.required],
      storywidth: [100, Validators.required],
      widthList: ['px'],
      heightList: ['px'],
      storywidthList: ['px'],
      storyheightList: ['px'],
      videourl:['Video 1'],
      bodyCode: [{ value: '', disabled: true }],
      storybodyCode: [{ value: '', disabled: true }],
      framebodyCode: [{ value: '', disabled: true }],
      embedbodyCode: [{ value: '', disabled: true }],
      styledbodyCode: [{ value: '', disabled: true }],
    });



    // this.copyForm = this.formBuilder.group({
    //   height: [900, Validators.required],
    //   width: [100, Validators.required],
    //   widthList:['px'],
    //   heightList:['px'],
    //   bodyCode: [{ value: '', disabled: true }],
    //   selectCheckbox: ['public', Validators.required],
    //   selectRadio: [false, Validators.required]
    // })


    // this.copyForm = this.formBuilder.group({
    //  value:[{ value: '', disabled: true }],
    //  publicValue:[{ value: '', disabled: true }]
    // })

  }

  get updatedIframeCode() {
    if(this.initialState.isvideo){
      var iframeContext = `<div class="container"> <iframe class="responsive-iframe" src="${this.videoUrl}"`
      iframeContext += ' height="' + this.copyForm.value.height + this.getHeight() + '"' + ' width="' + this.copyForm.value.width + this.getwidth() + '"';
      iframeContext += ' frameborder="0"' + "></iframe></div>";
      this.copyForm.controls.bodyCode.setValue(iframeContext)

      var storyiframeContext = `<div class="container"> <iframe class="responsive-iframe" src="${this.initialState.storyUrl}"`
      storyiframeContext += ' height="' + this.copyForm.value.storyheight + this.getStoryHeight() + '"' + ' width="' + this.copyForm.value.storywidth + this.getStorywidth() + '"';
      storyiframeContext += ' frameborder="0"' + "></iframe></div>";
      this.copyForm.controls.storybodyCode.setValue(storyiframeContext)
   
    }else{
      var storyiframeContext = `<div class="container"> <iframe class="responsive-iframe" src="${this.initialState.storyUrl}"`
      storyiframeContext += ' height="' + this.copyForm.value.storyheight + this.getStoryHeight() + '"' + ' width="' + this.copyForm.value.storywidth + this.getStorywidth() + '"';
      storyiframeContext += ' frameborder="0"' + "></iframe></div>";
      this.copyForm.controls.storybodyCode.setValue(storyiframeContext)
    }

   

    return;
  }
  getHeight() {
    if (+this.copyForm.value.height > 100 && this.copyForm.value.heightList == '%') {
      this.copyForm.controls.height.setValue(100);
      return this.copyForm.value.heightList
    }
    else {
      return this.copyForm.value.heightList
    }
  }
  getwidth() {
    if (+this.copyForm.value.width > 100 && this.copyForm.value.widthList == '%') {
      this.copyForm.controls.width.setValue(100);
      return this.copyForm.value.widthList
    }
    else {
      return this.copyForm.value.widthList
    }
  }


  getStoryHeight() {
    if (+this.copyForm.value.storyheight > 100 && this.copyForm.value.heightList == '%') {
      this.copyForm.controls.storyheight.setValue(100);
      return this.copyForm.value.storyheightList
    }
    else {
      return this.copyForm.value.storyheightList
    }
  }
  getStorywidth() {
    if (+this.copyForm.value.storywidth > 100 && this.copyForm.value.storywidthList == '%') {
      this.copyForm.controls.storywidth.setValue(100);
      return this.copyForm.value.storywidthList
    }
    else {
      return this.copyForm.value.storywidthList
    }
  }

  changevideo(data){
    if(typeof data === 'string' ){
       this.videoUrl = window.location.origin+'/video/embed/'+data;
       var thumbnailUrl =this.videoPayload.find(item => item.token == data)?.thumbnail_url
       this.thumbnailUrl = thumbnailUrl  ?  thumbnailUrl  : 'https://image.mux.com/'+data+'/thumbnail.jpg'
    }
  }

  copyLink(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.videoUrl.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  

  public close() {
    this.onClose.emit();
  }


}
