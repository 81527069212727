import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { ImageGallerySettingOutputApiModel } from '../models/api-models/output';
import { UpdateImageGalleryConfigInputApiModel, ImageGallerySettingsInputApiModel, UpdateImageStatusInputApiModel } from '../models/api-models/input';

@Injectable()
export class ImageGallerySettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public loadImages(companyId: number) {
        return this.get(`/${companyId}/setting/images/`).pipe(
            map((response: ResponsePaginationApiModel<ImageGallerySettingOutputApiModel>) => {
                return response;
            })
        )
    }

    public loadConfig(companyId: number) {
        return this.get(`/${companyId}/setting/images/config/`).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    public updateConfig(companyId: number, payload: Partial<UpdateImageGalleryConfigInputApiModel>) {
        return this.patch(`/${companyId}/setting/images/config/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateImageStatus(companyId: number, imageId: number, payload: Partial<UpdateImageStatusInputApiModel>) {
        return this.patch(`/${companyId}/setting/images/${imageId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public addImages(companyId: number, payload: Partial<ImageGallerySettingsInputApiModel>) {
        return this.post(`/${companyId}/setting/images/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteImage(companyId: number, imageId: number, payload: Partial<UpdateImageStatusInputApiModel>) {
        return this.delete(`/${companyId}/setting/images/${imageId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
