

<div class="modal-header">
  <h5 class="modal-title">Share</h5>
  <button type="button" (click)="close()" data-dismiss="modal" aria-label="Close" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg>
    </span>
  </button>
</div>
<div class="modal-body" [formGroup]="shareForm">
  <div #shareholic class="shareaholic-canvas" data-app="share_buttons" data-app-id="33117316"></div>

  <div class="row">
    <div class="col-12 mt-3">
      <gs-input-clipboard label="Link" name="Link" placeholder="Link" formControlName="link"
      [formControl]="shareForm.controls.link"></gs-input-clipboard>
    </div>

  </div>
</div>