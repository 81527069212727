export interface SearchBoxState {
    isShow: boolean;
    isShowResult: boolean;
    isSearching: boolean;
    searchpayload: any;
}

export const initialSearchBoxState: SearchBoxState = {
    isShow: false,
    isShowResult: false,
    isSearching: false,
    searchpayload: ''
};