import { Injectable } from '@angular/core';

@Injectable()
export class OAuthDialogService {
    private defaultWindowOptions = {
        width: 600,
        location: 1,
        toolbar: 0,
        title: 'Authentication'
    };
    private watchPopupTimeout: number = 100;

    constructor() {

    }

    // Private functions
    private addWindowRect() {
        let params: any = {
            ...this.defaultWindowOptions
        };
        const root = document.documentElement;
        let screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
        let screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
        let outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : root.clientWidth;
        let outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : root.clientHeight - 22;
        screenX = screenX < 0 ? window.screen.width + screenX : screenX;
        params.height = Math.floor(outerHeight * 0.8);
        params.left = Math.floor(screenX + (outerWidth - params.width) / 2);
        params.top = Math.floor(screenY + (outerHeight - params.height) / 2.5);
        return params;
    }
    
    private serializeOptions(options) {
        let chunks = [];
        for (let prop in options) {
            if (options.hasOwnProperty(prop)) {
                chunks.push(prop + "=" + options[prop]);
            }
        }
        return chunks.join(',');
    };
    // Public functions
    public open(url: string, resolveOnUri: string): Promise<string> {
        let windowParams = this.addWindowRect();
        const title = windowParams.title;
        delete windowParams.title;
        const popup = window.open(url, title, this.serializeOptions(windowParams));
        const watchDelay = this.watchPopupTimeout;
        console.debug("CALLING oAUTH (" + watchDelay + ") WITH : ", url);
        return new Promise(function (resolve, reject) {
            if (typeof popup.focus === 'function') {
                popup.focus();
            }
            setTimeout(function watchPopup() {
                try {
                    if (popup.closed) {
                        return reject(new Error("The sign in flow was canceled"));
                    }
                    if (popup.location.href.indexOf(resolveOnUri) === 0) {
                        popup.close();
                        resolve(popup.location.href);
                    }
                }
                catch (e) {
                    // By pass
                }
                setTimeout(watchPopup, watchDelay);
            }, watchDelay);
        });
    }
}