import { ThankYouPageSettingModel, QuestionnaireModel } from '../models';

export interface StorySettingState {
    questionnaireSettings: QuestionnaireModel[];
    thankYouPageSettings: ThankYouPageSettingModel;
    storyPublicEnabled: boolean;
}

export const initialState: StorySettingState = {
    questionnaireSettings: [],
    thankYouPageSettings: undefined,
    storyPublicEnabled: true,
};

