import { Component, Input, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component';
import { StoryViewModel } from '@modules/story/models/view-models';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { Constants } from '@modules/shared/constants';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';

@Component({
  selector: 'app-pb-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.css']
})
export class CommentListComponent extends ComponentBase {
  // Private variables
  @Input() isMasterAdmin:boolean = false;
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentUserProfileSubscription: Subscription;
  private selectStoryDetailSubscription: Subscription;
  // Public variables
  public storyDetail: StoryViewModel;
  public showTitle:boolean = false;
  public resourceType: string = Constants.resourceType.story;
  public currentUserProfile: UserProfileViewModel;
  public isLoggedIn: boolean = false;

  constructor(private store$: Store<CoreState>) {
    super();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
      this.isLoggedIn = true;
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.isLoggedIn = true;
      }
    });
  }

  public showComentTitle(data){
    if(data){
      this.showTitle = false;
    }
    else{
      this.showTitle = true;
    }
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }
  // Public functions
}

