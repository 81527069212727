import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { NudgesSettingEntity } from '../entities';
import { NudgesSettingState } from '../states';

export const NudgesSettingAdapter: EntityAdapter<NudgesSettingEntity> = createEntityAdapter<NudgesSettingEntity>();

export const initialState: NudgesSettingState = NudgesSettingAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});