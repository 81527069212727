import { Component, AfterViewInit, OnChanges, Input, Output, EventEmitter, ElementRef, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'gs-chips',
  templateUrl: './gs-chips.component.html',
  styleUrls: ['./gs-chips.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsChipsComponent),
      multi: true
    }
  ]
})
export class GsChipsComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @Input() label: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() secondaryPlaceholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() type: string;
  @Input() class: string;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() separatorKeys: string[] = [];
  @Input() pasteSplitPattern: string | RegExp = /;|,| /;
  @Input() maxItems: number;

  @Output() blur: EventEmitter<any> = new EventEmitter<any>();

  private inputValue: any;

  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };
  public inputClass: string = "form-control";
  public errorMessages: any = {
    required: ''
  };

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges() {
    this.inputClass += ` ${this.class}`;
  }

  private triggerChange($event) {
    this.fnOnChange($event);
    let event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }
  // Public methods

  public set value(val: any) {
    this.inputValue = val;
  }

  public get value() {
    return this.inputValue;
  }

  public writeValue(val: any): void {
    this.value = val;
  }

  public writePlaceholder(val: string): void {
    this.placeholder = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange(e) {
    this.triggerChange(this.inputValue);
  }

  public onItemAdded(e) {

  }
}

