<span *ngIf="isText">{{value}}</span>
<span *ngIf="isCheckBox">
  <label class="switch">
    <input [disabled]="disabled" (change)="onChange()" [(ngModel)]="value" type="checkbox"><span [style.background]="value ? toggleColor : ''" class="slider"></span>
  </label>
</span>
<span *ngIf="isNullableCheckBox && (value != null || value != undefined)">
  <label class="switch">
    <input [disabled]="disabled" (change)="onChange()" [(ngModel)]="value" type="checkbox"><span [style.background]="value ? toggleColor : ''" class="slider"></span>
  </label>
</span>

<div class="px-2">
  <!-- <gs-input-color-picker  [customId]="0" label="Icon Color" name="Background"  
    type="text" style="min-width: 100px; margin-bottom: 0.6rem; border: none;" [(ngModel)]="value" class="form-control p-0"
    (change)="onChange()" [cpPosition]="'left'">
  </gs-input-color-picker> -->
  <div class="color__container" *ngIf="isColor">
    <div
    *ngIf="isColor"
    (ngModelChange)="oncolorChange($event)"
      ngx-colors-trigger
      class="color_picker-s"
      [style.background]="value"
      [(ngModel)]="value"
    ></div>
  </div>

  <!-- <input class="form-control" style="min-width: 100px;"  *ngIf="isColor" [disabled]="disabled" (change)="onChange()" value="{{ value }}" [(ngModel)]="value" type="color"> -->
</div>

<span *ngIf="isDate">{{value | date}}</span>
<span style="color: #90cce1; cursor: pointer;" *ngIf="isModal" (click)="openModalLink()">{{value}}</span>
<span style="color: #90cce1; cursor: pointer; text-transform: capitalize;" *ngIf="isRequestdDraftLink && (value != null)" (click)="copyLink()">{{value}}</span>
<span *ngIf="isStatus" class="{{value}}">{{value}}</span>
<i *ngIf="isIcon" [innerHtml]="value | safeHtml"></i>
<div style="cursor: pointer;" *ngIf="isdIcon && (value != null)" (click)="onClick()"><i class="fa-solid fa-notes"></i></div>
<div *ngIf="isThumbnail" class="image-thumbnail" [ngStyle]="{'background-image': background}"></div>
<div *ngIf="isdsvgThumbnail">
  <ng-container *ngIf="value && isStringss(value)">
    <div  class="image-thumbnail" [ngStyle]="{'background-image': background}"></div>
  </ng-container>
  <ng-container *ngIf="value && !isStringss(value)">
    <i  class="image-thumbnail" [innerHtml]="value | safeHtml"></i>
  </ng-container>
</div>
<ng-container *ngIf="value && consentflag">
 <div  (click)="showConsentModal()" *ngIf="value == 'not_seen'" class="not_seen"></div>
 <div  (click)="showConsentModal()" *ngIf="value == 'not_responded'" class="not_responded"></div>
 <div  (click)="showConsentModal()" *ngIf="value == 'completed'" class="completed"></div>
</ng-container>