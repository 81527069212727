import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TestimonialAudienceSettingEntity } from '../entities/testimonial-audience-setting.entity';
import { TestimonialAudienceSettingState } from '../states';

export const TestimonialAudienceSettingAdapter: EntityAdapter<TestimonialAudienceSettingEntity> = createEntityAdapter<TestimonialAudienceSettingEntity>();

export const initialState: TestimonialAudienceSettingState = TestimonialAudienceSettingAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});