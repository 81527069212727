import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NudgesSettingHttpService } from '../services';
import { nudgesSettingActions } from '../actions';

@Injectable()
export class NudgesSettingEffects {

    constructor(
        private actions$: Actions,
        private nudgesSettingHttpService: NudgesSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.Load>(nudgesSettingActions.ActionTypes.LOAD),
        switchMap((action: nudgesSettingActions.Load) => {
            return this.nudgesSettingHttpService.load(action.companyId).pipe(
                map((response) => {
                    return new nudgesSettingActions.LoadSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.LoadFail(error));
                }));
        })
    ));


    
    loadNudgeSettingsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.LoadNudgeSettings>(nudgesSettingActions.ActionTypes.LOAD_NUDGE_SETTING),
        switchMap((action: nudgesSettingActions.LoadNudgeSettings) => {
            return this.nudgesSettingHttpService.loadNudgeSettings(action.companyId).pipe(
                map((response) => {
                    return new nudgesSettingActions.LoadNudgeSettingsSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.LoadNudgeSettingsFail(error));
                }));
        })
    ));


    
    updateNudgeSettingsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.UpdateNudgeSettings>(nudgesSettingActions.ActionTypes.UPDATE_NUDGE_SETTING),
        switchMap((action: nudgesSettingActions.UpdateNudgeSettings) => {
            return this.nudgesSettingHttpService.updateNudgeSettings(action.companyId,action.payload).pipe(
                map((response) => {
                    return new nudgesSettingActions.UpdateNudgeSettingsSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.UpdateNudgeSettingsFail(error));
                }));
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.Update>(nudgesSettingActions.ActionTypes.UPDATE),
        switchMap((action: nudgesSettingActions.Update) => {
            return this.nudgesSettingHttpService.update(action.companyId, action.audienceId, action.payload).pipe(
                map(() => {
                    return new nudgesSettingActions.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.UpdateFailure(error));
                }))
        })
    ));

    
    updateEColumnffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.UpdateColumn>(nudgesSettingActions.ActionTypes.UPDATE_COLUMN_ORDER),
        switchMap((action: nudgesSettingActions.UpdateColumn) => {
            return this.nudgesSettingHttpService.UpdateColumnOrdr(action.companyId, action.updateList).pipe(
                map(() => {
                    return new nudgesSettingActions.UpdateColumnSuccess();
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.UpdateColumnFailure(error));
                }))
        })
    ));

    
    addEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.Add>(nudgesSettingActions.ActionTypes.ADD),
        switchMap((action: nudgesSettingActions.Add) => {
            return this.nudgesSettingHttpService.add(action.companyId, action.payload).pipe(
                map(() => {
                    return new nudgesSettingActions.AddSuccess();
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.AddFailure(error));
                }))
        })
    ));

    
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<nudgesSettingActions.Delete>(nudgesSettingActions.ActionTypes.DELETE),
        switchMap((action: nudgesSettingActions.Delete) => {
            return this.nudgesSettingHttpService.deleteAdvocacy(action.companyId, action.audienceId).pipe(
                map(() => {
                    return new nudgesSettingActions.DeleteSuccess();
                }),
                catchError((error: any) => {
                    return of(new nudgesSettingActions.DeleteFailure(error));
                }))
        })
    ));
}