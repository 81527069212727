import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { UserEntity } from '../entities';
import { UserState } from '../states';

export const userAdapter: EntityAdapter<UserEntity> = createEntityAdapter<UserEntity>();

export const initialState: UserState = userAdapter.getInitialState({
    loading: false,
    loaded: false,
    prevUrl: null,
    nextUrl: null,
    createdUserId: 0
});