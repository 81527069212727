import { Directive, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[click-outside]',
})
export class ClickOutsideDirective {
  @Output('clickOutside') onClickOutside: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement: any) {
    const preventClickOutsideEvent: boolean = false;

    if (preventClickOutsideEvent === false) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.onClickOutside.emit(true);
      }
    }
  }
  constructor(private elementRef: ElementRef) {}
}
