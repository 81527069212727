import { Action } from '@ngrx/store';
import { UserAttributeSettingOutputApiModel } from '../models/api-models/output';
import { UpdateUserAttributeInputApiModel, UpdateUserAttributeValueInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Admin User Attribute Setting] Load user attribute',
    LOAD_SUCCESS = '[Company Admin User Attribute Setting] Load user attribute Success',
    LOAD_FAIL = '[Company Admin User Attribute Setting] Load user attribute Fail',

    ADD_USER_ATTRIBUTE = '[Company Admin User Attribute Setting] add user attribute',
    ADD_USER_ATTRIBUTE_SUCCESS = '[Company Admin User Attribute Setting] add user attribute Success',
    ADD_USER_ATTRIBUTE_FAIL = '[Company Admin User Attribute Setting] add user attribute Fail',

    DELETE_USER_ATTRIBUTE = '[Company Admin User Attribute Setting] delete user attribute',
    DELETE_USER_ATTRIBUTE_SUCCESS = '[Company Admin User Attribute Setting] delete user attribute Success',
    DELETE_USER_ATTRIBUTE_FAIL = '[Company Admin User Attribute Setting] delete user attribute Fail',

    UPDATE_USER_ATTRIBUTE = '[Company Admin User Attribute Setting] update user attribute',
    UPDATE_USER_ATTRIBUTE_SUCCESS = '[Company Admin User Attribute Setting] update user attribute Success',
    UPDATE_USER_ATTRIBUTE_FAIL = '[Company Admin User Attribute Setting] update user attribute Fail',

    ADD_USER_ATTRIBUTE_VALUE = '[Company Admin User Attribute Setting] add user attribute  value',
    ADD_USER_ATTRIBUTE_VALUE_SUCCESS = '[Company Admin User Attribute Setting] add user attribute value Success',
    ADD_USER_ATTRIBUTE_VALUE_FAIL = '[Company Admin User Attribute Setting] add user attribute value Fail',

    DELETE_USER_ATTRIBUTE_VALUE = '[Company Admin User Attribute Setting] delete user attribute value',
    DELETE_USER_ATTRIBUTE_VALUE_SUCCESS = '[Company Admin User Attribute Setting] delete user attribute value Success',
    DELETE_USER_ATTRIBUTE_VALUE_FAIL = '[Company Admin User Attribute Setting] delete user attribute value Fail',

    UPDATE_USER_ATTRIBUTE_VALUE = '[Company Admin User Attribute Setting] update user attribute value',
    UPDATE_USER_ATTRIBUTE_VALUE_SUCCESS = '[Company Admin User Attribute Setting] update user attribute value Success',
    UPDATE_USER_ATTRIBUTE_VALUE_FAIL = '[Company Admin User Attribute Setting] update user attribute value Fail'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: UserAttributeSettingOutputApiModel[]) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class UpdateAttributeValue implements Action {
    readonly type = ActionTypes.UPDATE_USER_ATTRIBUTE_VALUE;

    constructor(public companyId: number, public valueId: number, public payload: Partial<UpdateUserAttributeValueInputApiModel>) { }
}

export class UpdateAttributeValueSuccess implements Action {
    readonly type = ActionTypes.UPDATE_USER_ATTRIBUTE_VALUE_SUCCESS;
}

export class UpdateAttributeValueFailure implements Action {
    readonly type = ActionTypes.UPDATE_USER_ATTRIBUTE_VALUE_FAIL;

    constructor(public error: any) { }
}

export class DeleteAttributeValue implements Action {
    readonly type = ActionTypes.DELETE_USER_ATTRIBUTE_VALUE;

    constructor(public companyId: number, public valueId: number) { }
}

export class DeleteAttributeValueSuccess implements Action {
    readonly type = ActionTypes.DELETE_USER_ATTRIBUTE_VALUE_SUCCESS;
}

export class DeleteAttributeValueFailure implements Action {
    readonly type = ActionTypes.DELETE_USER_ATTRIBUTE_VALUE_SUCCESS;

    constructor(public error: any) { }
}

export class AddAttributeValue implements Action {
    readonly type = ActionTypes.ADD_USER_ATTRIBUTE_VALUE;

    constructor(public companyId: number, public attributeId: number, public payload: Partial<UpdateUserAttributeValueInputApiModel>) { }
}

export class AddAttributeValueSuccess implements Action {
    readonly type = ActionTypes.ADD_USER_ATTRIBUTE_VALUE_SUCCESS;
}

export class AddAttributeValueFailure implements Action {
    readonly type = ActionTypes.ADD_USER_ATTRIBUTE_VALUE_SUCCESS;

    constructor(public error: any) { }
}

export class UpdateAttribute implements Action {
    readonly type = ActionTypes.UPDATE_USER_ATTRIBUTE;

    constructor(public companyId: number, public attributeId: number, public payload: Partial<UpdateUserAttributeInputApiModel>) { }
}

export class UpdateAttributeSuccess implements Action {
    readonly type = ActionTypes.UPDATE_USER_ATTRIBUTE_SUCCESS;
}

export class UpdateAttributeFailure implements Action {
    readonly type = ActionTypes.UPDATE_USER_ATTRIBUTE_FAIL;

    constructor(public error: any) { }
}

export class DeleteAttribute implements Action {
    readonly type = ActionTypes.DELETE_USER_ATTRIBUTE;

    constructor(public companyId: number, public attributeId: number) { }
}

export class DeleteAttributeSuccess implements Action {
    readonly type = ActionTypes.DELETE_USER_ATTRIBUTE_SUCCESS;
}

export class DeleteAttributeFailure implements Action {
    readonly type = ActionTypes.DELETE_USER_ATTRIBUTE_SUCCESS;

    constructor(public error: any) { }
}

export class AddAttribute implements Action {
    readonly type = ActionTypes.ADD_USER_ATTRIBUTE;

    constructor(public companyId: number, public payload: Partial<UpdateUserAttributeInputApiModel>) { }
}

export class AddAttributeSuccess implements Action {
    readonly type = ActionTypes.ADD_USER_ATTRIBUTE_SUCCESS;
}

export class AddAttributeFailure implements Action {
    readonly type = ActionTypes.ADD_USER_ATTRIBUTE_SUCCESS;

    constructor(public error: any) { }
}

export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    UpdateAttribute |
    UpdateAttributeSuccess |
    UpdateAttributeFailure |
    DeleteAttribute |
    DeleteAttributeSuccess |
    DeleteAttributeFailure |
    AddAttribute |
    AddAttributeSuccess |
    AddAttributeFailure |
    UpdateAttributeValue |
    UpdateAttributeValueSuccess |
    UpdateAttributeValueFailure |
    DeleteAttributeValue |
    DeleteAttributeValueSuccess |
    DeleteAttributeValueFailure |
    AddAttributeValue |
    AddAttributeValueSuccess |
    AddAttributeValueFailure
    ;