import { nudgesSettingActions } from "../actions";
import { NudgesSettingState } from "../states";
import { NudgesSettingEntity } from "../entities";
import { NudgesSettingAdapter, initialState } from "../adapters/nudges-setting.adapter";

export function NudgesSettingReducer(state = initialState, action: nudgesSettingActions.Actions): NudgesSettingState {
    switch (action.type) {

        case nudgesSettingActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case nudgesSettingActions.ActionTypes.LOAD_SUCCESS: {
            let result: any = action.response;
            const entities = result.map(item => new NudgesSettingEntity(item));
            const newSate = NudgesSettingAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: result.length
            };
        }

        case nudgesSettingActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}