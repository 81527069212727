import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { landingPageActions } from '../actions';
import { LandingPageHttpService } from '../services';

@Injectable()
export class LandingPageEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.Load>(landingPageActions.ActionTypes.LOAD),
    switchMap((action: landingPageActions.Load) => {
      return this.LandingPageHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new landingPageActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new landingPageActions.LoadFail(error));
        }));
    })
  ));

  
  makeActiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.MakeActive>(landingPageActions.ActionTypes.MAKE_ACTIVE),
    switchMap((action: landingPageActions.MakeActive) => {
      return this.LandingPageHttpService.makeActive(action.companyId, action.ids).pipe(
        map((respone) => {
          return new landingPageActions.MakeActiveSuccess();
        }),
        catchError((error: any) => {
          return of(new landingPageActions.MakeActiveFail(error));
        }));
    })
  ));

  
  makeInactiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.MakeInactive>(landingPageActions.ActionTypes.MAKE_INACTIVE),
    switchMap((action: landingPageActions.MakeInactive) => {
      return this.LandingPageHttpService.makeInactive(action.companyId, action.ids).pipe(
        map((respone) => {
          return new landingPageActions.MakeInactiveSuccess();
        }),
        catchError((error: any) => {
          return of(new landingPageActions.MakeInactiveFail(error));
        }));
    })
  ));

  
  makePublicEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.TurnPublicOn>(landingPageActions.ActionTypes.TURN_PUBLIC_ON),
    switchMap((action: landingPageActions.TurnPublicOn) => {
      return this.LandingPageHttpService.makePublic(action.companyId, action.ids).pipe(
        map((respone) => {
          return new landingPageActions.TurnPublicOnSuccess();
        }),
        catchError((error: any) => {
          return of(new landingPageActions.TurnPublicOnFail(error));
        }));
    })
  ));

  
  makePrivateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.TurnPublicOff>(landingPageActions.ActionTypes.TURN_PUBLIC_OFF),
    switchMap((action: landingPageActions.TurnPublicOff) => {
      return this.LandingPageHttpService.makePrivate(action.companyId, action.ids).pipe(
        map((respone) => {
          return new landingPageActions.TurnPublicOffSuccess();
        }),
        catchError((error: any) => {
          return of(new landingPageActions.TurnPublicOffFail(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private LandingPageHttpService: LandingPageHttpService) { }
}
