export interface AdvocacyOutputApiModel {
    id: number;
    logo: string;
    name: string;
    url: string;
    is_internal_active: boolean;
    is_public_active: boolean;
    is_active: boolean;
    logo_mediafile?: any;
}

export class AdvocacyOutputApiModel {
    constructor(data?: Partial<AdvocacyOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}