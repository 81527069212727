import { addUserReducer } from './add-user.reducer';
import { campaignReducer } from './campaign.reducer';
import { landingPageReducer } from './landing-page.reducer';
import { profilePublicReducer } from './profile-public.reducer'
import { reportLeadsReducer } from './report-leads.reducer';
import { storyReducer } from './story.reducer';
import { testimonialReducer } from './testimonial.reducer';
import { userReducer } from './user.reducer';
import { profileInternalReducer } from './profile-internal.reducer';
import { testimonialSettingReducer } from './testimonial-setting.reducer';
import { EmailsSettingReducer } from './emails-setting.reducer';
import { emailCustomizeSettingReducer } from './email-customize-setting.reducer';
import { testimonialAudienceSettingReducer } from './testimonial-audience-setting.reducer';
import { NudgesSettingReducer } from './nudges-setting.reducer';
import { ImageGallerySettingReducer } from './image-gallery-setting.reducer';
import { AuthPageSettingReducer } from './auth-page-setting.reducer';

export const companyAdminReducers = {
    addUser: addUserReducer,
    campaign: campaignReducer,
    landingPage: landingPageReducer,
    profilePublic: profilePublicReducer,
    reportLeads: reportLeadsReducer,
    story: storyReducer,
    testimonial: testimonialReducer,
    user: userReducer,
    profileInternal: profileInternalReducer,
    testimonialSetting: testimonialSettingReducer,
    emailsSetting: EmailsSettingReducer,
    emailCustomizeSetting: emailCustomizeSettingReducer,
    testimonialAudienceSetting: testimonialAudienceSettingReducer,
    nudgesSetting: NudgesSettingReducer,
    imageGallerySetting: ImageGallerySettingReducer,
    authPageSetting: AuthPageSettingReducer
};
