import { MemoizedSelector, createSelector } from '@ngrx/store';
import { StoryFeatureState, StoryState } from '../states';
import { selectStoryFeatureState } from './story-feature.selector';
import { storyAdapter } from '../adapters/story.adapter';
import { StoryEntity, StoryEditEntity } from '../entities';
import { StoryViewModel } from '@modules/story/models/view-models';
import { StoryLoadStatusViewModel, StoryFacetedSearchViewModel } from '@modules/story/models/store-view-models';
import { SearchStoryInputApiModel, GetStoriesInputApiModel } from '@modules/story/models/api-models/input';

export const selectStoryState: MemoizedSelector<object, StoryState> = createSelector(selectStoryFeatureState, (state: StoryFeatureState) => state.story);

const {
  selectIds: selectStoryIds,
  selectEntities: selectEntities,
  selectAll: selectAllItems,
  selectTotal: storysCount
} = storyAdapter.getSelectors(selectStoryState);


// -------------[Mapping Functions]-------------
const getLoading = (state: StoryState): boolean => state.loading;
const getLoaded = (state: StoryState): boolean => state.loaded;
const getUpdating = (state: StoryState): boolean => state.updating;

const getEditStory = (state: StoryState): StoryEditEntity => {
  return state.storyEditData;
};

const getSelectedStoryIdOrSlug = (state: StoryState): number | string => state.selectedStoryIdOrSlug;

const getCreating = (state: StoryState): boolean => state.creating;

const getCurrentStory = (entities: StoryEntity[], currentStoryIdOrSlug: number | string): StoryViewModel => {
  const entity = entities.find(f => f.id === currentStoryIdOrSlug || f.slug === currentStoryIdOrSlug);
  return entity ? new StoryViewModel(entity) : null;
};

const getLoadStatus = (state: StoryState): StoryLoadStatusViewModel => {
  return {
    isLoaded: state.loaded,
    isLoading: state.loading,
    isCanLoadMore: state.nextUrl ? state.nextUrl.length > 0 : null,
    queryStoriesData: state.queryStoriesData,
    total: state.total
  };
};

const mapAllToViewModel = (entities: StoryEntity[]): StoryViewModel[] => {
  return entities.map(entity => new StoryViewModel(entity));
};

const getIsEmbedMode = (state: StoryState): boolean => {
  return state.isEmbedMode;
};

const getFacetedSearchResults = (state: StoryState): StoryFacetedSearchViewModel => {
  return state.facetedSearchResult;
};



const getQueryData = (state: StoryState): SearchStoryInputApiModel | GetStoriesInputApiModel => {
  return state.queryStoriesData;
};

// -------------[Public Selectors]-------------
export const selectLoaded: MemoizedSelector<object, boolean> = createSelector(selectStoryState, getLoaded);
export const selectLoading: MemoizedSelector<object, boolean> = createSelector(selectStoryState, getLoading);
export const selectSelectedStoryId: MemoizedSelector<object, number | string> = createSelector(selectStoryState, getSelectedStoryIdOrSlug);
export const selectEditStory: MemoizedSelector<object, StoryEditEntity> = createSelector(selectStoryState, getEditStory);
export const selectCreating: MemoizedSelector<object, boolean> = createSelector(selectStoryState, getCreating);
export const selectUpdating: MemoizedSelector<object, boolean> = createSelector(selectStoryState, getUpdating);
export const selectCurrentStory: MemoizedSelector<object, StoryViewModel> = createSelector(selectAllItems, selectSelectedStoryId, getCurrentStory);

export const selectLoadStatus: MemoizedSelector<object, StoryLoadStatusViewModel> = createSelector(selectStoryState, getLoadStatus);
export const selectAll: MemoizedSelector<object, StoryViewModel[]> = createSelector(selectAllItems, mapAllToViewModel);
export const selectIsEmbedMode: MemoizedSelector<object, boolean> = createSelector(selectStoryState, getIsEmbedMode);
export const selectFacetedSearchResults: MemoizedSelector<object, StoryFacetedSearchViewModel> = createSelector(selectStoryState, getFacetedSearchResults);
export const selectQueryData: MemoizedSelector<object, SearchStoryInputApiModel | GetStoriesInputApiModel> = createSelector(selectStoryState, getQueryData);
