import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import { directoryActions } from '../actions';
import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { DirectoryOutputApiModel } from '@modules/user/models/output';
import { UserHttpService } from '@modules/user/services';
import { followActions } from '@modules/shared/store/actions';

@Injectable()
export class DirectoryEffect {

  
  loadCompanyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<directoryActions.LoadCompany>(directoryActions.ActionTypes.LOAD_COMPANY),
    switchMap((action: directoryActions.LoadCompany) => {
      return this.userHttpService.getFollowCompany(action.queryData).pipe(
        map((response: ResponsePaginationApiModel<DirectoryOutputApiModel>) => {
          return new directoryActions.LoadCompanySuccess(response);
        }),
        catchError((error: any) => {
          return of(new directoryActions.LoadCompanyFailure(error));
        }))
    })
  ));

  
  loadFollowerEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<directoryActions.LoadFollower>(directoryActions.ActionTypes.LOAD_FOLLOWER),
    switchMap((action: directoryActions.LoadFollower) => {
      return this.userHttpService.getFollowers(action.queryData).pipe(
        map((response: ResponsePaginationApiModel<DirectoryOutputApiModel>) => {
          return new directoryActions.LoadFollowerSuccess(response);
        }),
        catchError((error: any) => {
          return of(new directoryActions.LoadFollowerFailure(error));
        }))
    })
  ));

  
  loadFollowingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<directoryActions.LoadFollowing>(directoryActions.ActionTypes.LOAD_FOLLOWING),
    switchMap((action: directoryActions.LoadFollowing) => {
      return this.userHttpService.getFollowings(action.queryData).pipe(
        map((response: ResponsePaginationApiModel<DirectoryOutputApiModel>) => {
          return new directoryActions.LoadFollowingSuccess(response);
        }),
        catchError((error: any) => {
          return of(new directoryActions.LoadFollowingFailure(error));
        }));
    })
  ));

  
  followSuccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<followActions.FollowSuccess>(followActions.ActionTypes.FOLLOW_SUCCESS),
    switchMap((action: followActions.FollowSuccess) => {
      return of(new directoryActions.FollowSuccess(action.response));
    })
  ));

  
  unfollowSuccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<followActions.UnfollowSuccess>(followActions.ActionTypes.UNFOLLOW_SUCCESS),
    switchMap((action: followActions.UnfollowSuccess) => {
      return of(new directoryActions.UnfollowSuccess(action.response));
    })
  ));

  constructor(
    private actions$: Actions,
    private userHttpService: UserHttpService
  ) { }
}
