import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { UpdateEmailCustomizeSettingInputApiModel } from '../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models';
import { EmailCustomizeSettingOutputApiModel } from '../models/api-models/output';

@Injectable()
export class EmailCustomizeSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/emails/customise/`).pipe(
            map((response: ResponsePaginationApiModel<EmailCustomizeSettingOutputApiModel>) => {
                return response;
            })
        );
    }

    public update(companyId: number, customizeId: number, payload: Partial<UpdateEmailCustomizeSettingInputApiModel>) {
        return this.patch(`/${companyId}/setting/emails/customise/${customizeId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
