import { initialState, companyAdapter } from '../adapters/company.adapter';
import { CompanyState } from '../states';
import { companyActions } from '../actions';
import { CompanyEntity } from '../entities';


export function companyReducer(state = initialState, action: companyActions.Actions): CompanyState {
    switch (action.type) {

        case companyActions.ActionTypes.SEARCH: {
            return {
                ...state,
                isSearched: false,
                isSearching: true
            }
        }

        case companyActions.ActionTypes.SEARCH_SUCCESS: {
            if (action.response && action.response.company_id > 0) {
                const company = new CompanyEntity(action.response);
                const newState = Object.assign({}, state, companyAdapter.upsertOne(company, state), { selectedId: company.id });
                return {
                    ...newState,
                    isSearched: true,
                    isSearching: false
                }
            } else {
                return {
                    ...state,
                    selectedId: 0,
                    isSearched: true,
                    isSearching: false
                }
            }
        }

        case companyActions.ActionTypes.SEARCH_FAILURE: {
            return {
                ...state,
                isSearched: true,
                isSearching: false
            }
        }

        case companyActions.ActionTypes.SET_CREATE_COMPANY_DOMAIN: {
            return {
                ...state,
                createCompanyDomain: action.companyDomain
            }
        }

        case companyActions.ActionTypes.LOAD_INFO_SUCCESS: {
            const company = new CompanyEntity(action.response);
            const newState = Object.assign({}, state, companyAdapter.upsertOne(company, state), { currentUserCompanyId: company.id, selectedId: company.id });
            return {
                ...newState,
                isSearched: true,
                isSearching: false
            }
        }

        case companyActions.ActionTypes.LOAD_PUBLIC_INFO_SUCCESS: {
            const company = new CompanyEntity(action.response);
            const newState = Object.assign({}, state, companyAdapter.upsertOne(company, state), { selectedId: company.id });
            return {
                ...newState,
                isSearched: true,
                isSearching: false
            }
        }

        case companyActions.ActionTypes.LOAD_SHORT_INFO_SUCCESS: {
            const company = new CompanyEntity(action.response);
            const newState = Object.assign({}, state, companyAdapter.upsertOne(company, state), { selectedId: company.id });
            return {
                ...newState,
                isSearched: true,
                isSearching: false
            }
        }

        default: {
            return state;
        }
    }
}