import { Component, Inject, SimpleChanges } from '@angular/core';
import { ComponentBase } from '@framework/base';
import { Observable, Subscription } from 'rxjs';
import { StoryViewModel } from '@modules/story/models/view-models';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { Router } from '@angular/router';
import { storySelector } from '@modules/story/store/selectors';

@Component({
  selector: 'app-no-permision-company',
  templateUrl: './no-permission-company.component.html',
  styleUrls: ['./no-permission-company.component.css']
})
export class NoPermissionCompanyComponent extends ComponentBase {
  // Private variables
  // Public variables
  public storyDetail: StoryViewModel;

  constructor(private store$: Store<CoreState>, private router: Router) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
  }

  private unsubscribe() {
  }
  // Public functions
  public login() {
    const nextUrl = window.location.href;
    this.router.navigate(['/user/login'], { queryParams: { nexturl: nextUrl } });
  }

  public signup() {
    this.router.navigate(['/user/signup']);
  }

}
