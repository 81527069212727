export interface EmailsSettingOutputApiModel {
    id: number;
    from_email: string;
    from_name: string;
    is_ssl: boolean;
    smtp_username: string;
    smtp_password: string;
    smtp_address: string;
    smtp_port: number;
}

export class EmailsSettingOutputApiModel {
    constructor(data?: Partial<EmailsSettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
