import { CompanyButtonOutputApiModel } from ".";

export interface ImageOutputApiModel {
    preview: string;
}
export interface ProfileInternalOutputApiModel {
    name: string;
    email: string;
    website: string;
    logo: ImageOutputApiModel;
    background: ImageOutputApiModel;
    buttons: CompanyButtonOutputApiModel[];
    slogan: string;
    author_id: number;
    show_logo_header: boolean;
    default_story_prompt:any;
    logo_default?: any;
}


export class ProfileInternalOutputApiModel {
    constructor(data?: Partial<ProfileInternalOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
