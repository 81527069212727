import { environment } from '@app/env/environment';

export class MetaTagConfig {
    url: string;
    title: string;
    description: string;
    keywords: string;
    image: string;
    alt:string;
    castLarge: boolean = false;

    constructor() { }
}
