<div class="modal-header pb-0">
  <h4 class="modal-title">EMBED LINKS</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="copyForm">
  {{updatedIframeCode}}
  <div class="color-wrapper embed-sub-wrapper">
    <h6 class="mb-3">EMBED STORY CODE</h6>
    <div class="row">
      <div class="col-1" style="position: relative;top: 4px; display: flex; align-items: center">
        <h6>Height</h6>
      </div>
      <div class="col-2">
      <input type="number" class="form-control" formControlName="storyheight" [formControl]="copyForm.controls.storyheight" label="height"
      type="number"> 
      </div>
      <div class="col iFrame-value">
        <!-- <h6>Px</h6> -->
        <select formControlName="storyheightList" class="box">
          <option value="px">px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
      <div class="col-1" style="position: relative;top: 4px; display: flex; align-items: center">
        <h6>Width</h6>
      </div>
      <div class="col-2">
        <!-- <gs-input-material formControlName="storywidth" [formControl]="copyForm.controls.storywidth" label="Width" type="number">
        </gs-input-material> -->
        <input type="number" class="form-control" formControlName="storywidth" [formControl]="copyForm.controls.storywidth" label="width"
        type="number"> 
      </div>
      <div class="col iFrame-value">
        <select formControlName="storywidthList" class="box">
          <option value="px">px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>

    <div class="code-wrapper embed-form-disabled mt-2">
      <h6 class="mt-4">Place the below code inside your <span>&lt;body&gt;</span> tag.</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-input-clipboard name="storybodyCode" placeholder="Body Code" formControlName="storybodyCode"
          [formControl]="copyForm.controls.storybodyCode"></gs-input-clipboard>
      </div>
    </div>
  </div>

  <h6 *ngIf="initialState.isvideo"> EMBED VIDEO LINK AND CODE</h6>

  <div class="row" *ngIf="initialState.isvideo">
    <div class="col-3">
      <h6 class="mt-4 mb-3">SELECT VIDEO&nbsp;</h6></div>
    <div class="col-6 mt-2"><gs-select-material label="Pick a story prompt" name="Question" formControlName="videourl"
      [formControl]="copyForm.controls.videourl" (change)="changevideo($event)" [data]="videoPayload" valueField="token" displayField="name">
    </gs-select-material> </div>
  </div>
  <div class="row" *ngIf="initialState.isvideo">
    <div class="col-3"> <h6 class="mt-4 mb-3">LINK&nbsp;</h6></div>
    <div class="col-1" (click)="copyLink()"> <span class="previousbutton"><i  class="fa-regular fa-link"></i></span></div>
    <!-- <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="LINK" placeholder="LINK" [disabled]="true" formControlName="embedbodyCode"
        [formControl]="copyForm.controls.embedbodyCode"></gs-input-clipboard> -->
    </div>

  <div class="thumbnail" *ngIf="initialState.isvideo">
    <img [src]="thumbnailUrl" style="height:100% ;width:100%" alt="">
  </div>

  <div class="color-wrapper embed-sub-wrapper" *ngIf="initialState.isvideo">
    <h6 class="mb-3">CODE</h6>
    <div class="row">
      <div class="col-1 iFrame-value px-3"  style="position: relative;top: 4px">
        <h6>Height</h6>
      </div>
      <div class="col-2" >
        <input type="number" class="form-control" formControlName="height" [formControl]="copyForm.controls.height" label="height"
        type="number"> 
      </div>
      <div class="col iFrame-value">
        <!-- <h6>Px</h6> -->
        <select formControlName="heightList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
      <div class="col-1 iFrame-value px-3"  style="position: relative;top: 4px">
        <h6>Width</h6>
      </div>
      <div class="col-2">
        <input type="number" class="form-control" formControlName="width" [formControl]="copyForm.controls.width" label="width"
        type="number"> 
      </div>
      <div class="col iFrame-value">
        <select formControlName="widthList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>
    <div class="code-wrapper embed-form-disabled">
      <h6 class="mt-4">Place the below code inside your <span>&lt;body&gt;</span> tag. {{updatedIframeCode}}</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="bodyCode"
          [formControl]="copyForm.controls.bodyCode"></gs-input-clipboard>
      </div>
    </div>
  </div>
  <div class="code-wrapper embed-form-disabled">
    <h6 class="mt-3">Use these classes to override the CSS under <span>&lt;style&gt;</span> tag.</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-textarea-clipboard name="styledbodyCode" placeholder="Body Code" rows="8" formControlName="styledbodyCode"
        [formControl]="copyForm.controls.styledbodyCode"></gs-textarea-clipboard>
    </div>
  </div>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->