import { UserProfileEntity } from '../entities';

export interface CompanyViewModel {
  id: number;
  name: string;
  email: string;
  createdAt: Date;
  logo?: string;
  slug: string;
  isPublic: boolean;
}

export class UserBadgeViewModel {
  id: number;
  image: string;
  name: string;
  text: string;
  color: string;
  givenCase: string;

  constructor(init?: Partial<UserBadgeViewModel>) {
    Object.assign(this, init);
  }
}

export class UserStoryStatisticsViewModel {
  total: number;
  given: number;
  received: number;
  peopleNamed: number;
  peopleAdded: number;

  constructor(init?: Partial<UserStoryStatisticsViewModel>) {
    Object.assign(this, init);
  }
}

export class UserStoryFrequentTagsViewModel {
  id: number;
  title: string;
  color: string;
  data: Array<string>;
  sort: number;
  header_title?:any;
  is_header_part?:any;


  constructor(init?: Partial<UserStoryFrequentTagsViewModel>) {
    Object.assign(this, init);
  }
}

export interface UserProfileViewModel {
  id: number;
  firstName: string;
  lastName: string;
  avatar: string;
  header: string;
  slug: string;
  profileTwitter: string;
  profileLinkedin: string;
  profileFacebook: string;
  workPurpose: string;
  followId: number;
  title: string;
  email: string;
  company: CompanyViewModel;
  badge?: UserBadgeViewModel;
  storyStatistics?: UserStoryStatisticsViewModel;
  storyFrequentTags?: UserStoryFrequentTagsViewModel[];
  adminCompanies: number[];
  workLocation: string;
  workAttributes: any;
  followEnable: boolean;
  public: boolean;
  storyInviteEnable: boolean;
  namePublic: boolean;
  admin_role?: number
  storyPoint: number;
  peerPoint: number;
  status: number;
  isApprovedOutsider: boolean;
  signupDate: Date;
}

export class UserProfileViewModel {
  constructor(data?: UserProfileEntity) {
    if (data) {
      this.convertFromStore(data);
    }
  }

  private convertFromStore(data: UserProfileEntity) {
    Object.assign(this, data);
  }

  get isCompanyAdmin(): boolean {
    return this.adminCompanies && this.adminCompanies.indexOf(this.company.id) > -1;
  }

  get isFollowing() {
    return this.followId > 0;
  }

  get displayName() {
    return this.firstName + ' ' + this.lastName;
  }

  set displayName(name) {
    // nothing
  }
}
