import { NotificationOutputApiModel } from ".";

export interface NotificationObjectOutputApiModel {
    personal: NotificationOutputApiModel[],
    company: NotificationOutputApiModel[]
}

export class NotificationObjectOutputApiModel {
    constructor(init?: Partial<NotificationObjectOutputApiModel>) {
        Object.assign(this, init);
    }
}