import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { ContributionEntity } from '../entities';
import { ContributionState } from '../states/contribution.state';

export const contributionAdapter: EntityAdapter<ContributionEntity> = createEntityAdapter<ContributionEntity>();
export const initialState: ContributionState = contributionAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedId: null,
  contributionBuilderData: null,
  stepActived: null,
  stepCompleted: []
});