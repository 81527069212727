import { Injectable, ElementRef } from '@angular/core';

// import ImageEditor = require('tui-image-editor');

@Injectable()
export class ImageEditorService {

    constructor() {

    }

    public show(elRef: ElementRef, imgPath: string) {
        // const instance = new ImageEditor(elRef.nativeElement, {
        //     includeUI: {
        //         loadImage: {
        //             path: imgPath,
        //             name: 'Image editor'
        //         },
        //         initMenu: 'filter',
        //         menuBarPosition: 'left'
        //     },
        // });

        // return instance;
    }

}