import { imageGalleryActions } from "../actions";
import { ImageGallerySettingState } from "../states";
import { ImageGallerySettingAdapter, initialState } from "../adapters/image-gallery-setting.adapter";
import { ImageGallerySettingEntity } from "../entities";

export function ImageGallerySettingReducer(state = initialState, action: imageGalleryActions.Actions): ImageGallerySettingState {
    switch (action.type) {

        case imageGalleryActions.ActionTypes.LOAD_IMAGES: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case imageGalleryActions.ActionTypes.LOAD_IMAGES_SUCCESS: {
            let result: any = action.response;
            const entities = result.map(item => new ImageGallerySettingEntity(item));
            const newSate = ImageGallerySettingAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: result.length
            };
        }

        case imageGalleryActions.ActionTypes.LOAD_IMAGES_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}