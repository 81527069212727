import { emailCustomizeSettingAction } from "../actions";
import { emailCustomizeSettingAdapter, initialState } from "../adapters/email-customize-setting.adapter";
import { EmailCustomizeSettingEntity } from "../entities";
import { EmailCustomizeSettingState } from "../states";

export function emailCustomizeSettingReducer(state = initialState, action: emailCustomizeSettingAction.Actions): EmailCustomizeSettingState {
    switch (action.type) {

        case emailCustomizeSettingAction.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case emailCustomizeSettingAction.ActionTypes.LOAD_SUCCESS: {
            let emailCustomizeData: any = action.response;
            const entities = emailCustomizeData.map(item => new EmailCustomizeSettingEntity(item));
            const newSate = emailCustomizeSettingAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: emailCustomizeData.length
            };
        }

        case emailCustomizeSettingAction.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}