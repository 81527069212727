import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, Renderer2, AfterViewChecked, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gs-email-nugget',
  templateUrl: './gs-email-nugget.component.html',
  styleUrls: ['./gs-email-nugget.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsEmailNuggetComponent),
      multi: true
    }
  ]
})
export class GsEmailNuggetComponent {
  @Input() name: string;
  @Input() email: string;
  @Input() cssClass: string;
  @Input() editable: boolean;

  @ViewChild('inputControl')
  inputControl: ElementRef;

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges() {

  }

  ngAfterViewChecked() {
  }

  private triggerChange($event) {
  }

  // Public methods

  public registerOnChange(fn: any): void {
  }

  public registerOnTouched(fn: any): void {
  }

  public onChange() {
  }
}
