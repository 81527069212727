import { Component, HostListener, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ofType, Actions } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '@app/env/environment';
import * as _ from 'lodash';
import { Page } from '@framework/base/page';
import { UtilityHelper } from '@framework/utilities';
import { CoreState } from '@app/store/core/states';
import { storyActions } from '@modules/story/store/actions';
import * as adminStoryActions from '@modules/company-admin/store/actions';
import { storySelector } from '@modules/story/store/selectors';
import { StoryViewModel } from '@modules/story/models/view-models';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { companyActions } from '@modules/company/store/actions';

import { GsTokenModel } from '@modules/shared/models/common';
import { Constants } from '@modules/shared/constants';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';
import { StoryOutputApiModel } from '@modules/story/models/api-models/output';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from '@modules/shared/models/common/seo.model';
import { AuthService, LoadingPopupService } from '@framework/services';
import { campaignActions } from '@modules/company/store/actions';
import { CampaignViewModel } from '@modules/company/models/store-view-models';
import { campaignSelectors } from '@modules/company/store/selectors';

import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { inject } from '@angular/core/testing';
import { isPlatformBrowser } from '@angular/common';
import { StoryHttpService } from '@modules/story/services';
import * as Sservice  from '@modules/company-admin/store/services';
@Component({
  selector: 'app-copy-video-url',
  templateUrl: './copy-video-url.component.html',
  styleUrls: ['./copy-video-url.component.css']
})
export class CopyVideoComponent extends Page {
  // Private variables
  public idOrSlug: number | string;
  public  companyId: any;

  private loadStoryDetailSuccessSubscription: Subscription;

  public isDelted:boolean = true;
  // Public variables
  public currentCompany: CompanyViewModel;
  public storyDetail: StoryViewModel;
  public isEmbedMode: boolean = false;
  public  isBrowser: boolean;
  public isClickedPublic:any;
  public token:any;
  public seoDetails: any;
  public mediaPayload:any[] = [];
  public  activeIndex = 0;
  public url: SafeUrl;
  public statusDetail: StoryLoadStatusViewModel;
  public campaigns: CampaignViewModel[] = [];
  public isDeleted: boolean = true;
  public campaignId: any;
  public spotlightQuery: any;
  private loadColorSuccessSubscription: Subscription;
  public primaryColor:any;
  public accentColor:any; 
  quillData:string;
  quillModule = {
    toolbar:[{}],
  }

  constructor(
    private utility: UtilityHelper,
    private store$: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private storyHttpService: StoryHttpService,
    private StoryHttpService: Sservice.StoryHttpService,
    private landingPopupService: LoadingPopupService,
    private actions$: Actions,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private seoService: SeoService,
    private authService: AuthService,
    public sanitizer: DomSanitizer
  ) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    // this.checkIsAuthenticated();

    this.captureParams();
    this.loadDetail();

  }
  @HostListener('window:resize', ['$event'])
  public resizeEvent(event: any) {
  this.setFrameHeight();
  }
  // Life cycle hook
  protected onInit(): void {
    // this.startImport();
    this.subscribe();
   
  }
  protected onDestroy(): void {
    this.unsubscribe();
    // this.destoryScript();
    this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }

  /**
   * this method is to update meta tag according to story detail
   */
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
      config.title = `${this.storyDetail.company.name}`;
      config.description = this.storyDetail.title;
      config.alt=  this.storyDetail.title
   
    config.url = 'https://'+this.storyDetail.slug+'.'+environment.customUrl+this.router.url;
    if(this.storyDetail.media != undefined && this.storyDetail.media != null)
    {
    
      config.image = this.getUrl(this.mediaPayload[0]) ;
    }
  
    config.castLarge = true;
    this.seoService.updateMetaTags(config);
  }


  // public startImport(){
  //   $("#hippo-video-delivery-scripts").empty();
  //   let injectScript = document.createElement('script');
  //   injectScript.type = 'text/javascript';
  //   injectScript.async = true;
  //   injectScript.src = 'https://hippo-embed-scripts.s3.amazonaws.com/video-delivery-embed.js'
  //   document.getElementById('hippo-video-delivery-scripts').appendChild(injectScript);
  // }


  // destoryScript(){
  //   $("#hippo-video-delivery-scripts").empty();
  // }

  private subscribe() {

    this.loadStoryDetailSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.LoadVideoSuccess>(storyActions.ActionTypes.LOAD_VIDEO_SUCCESS)
    ).subscribe(action => {

      if(action && action.data){
        
        this.storyDetail = action.data;
        if(this.storyDetail && this.storyDetail.media &&  this.storyDetail.media && this.storyDetail.media.length){
         
          this.mediaPayload  = [];
           for(let i=0 ; i< this.storyDetail.media.length ; i++){
            var payload:PublicMeediaPayload = new PublicMeediaPayload();
              if(this.storyDetail.media[i].type == "video/mp4"){
                payload.type = this.storyDetail.media[i].type
                payload.url = this.storyDetail.media[i].source;
                payload.source = this.storyDetail.media[i].source
                payload.thumbnail_url = this.storyDetail.media[i].thumbnail_url ? this.storyDetail.media[i].thumbnail_url : 'https://image.mux.com/'+this.storyDetail.media[i].source+'/thumbnail.jpg'
                payload.title = this.storyDetail?.company?.name
                payload.asset_id = this.storyDetail.media[i].asset_id
                this.mediaPayload.push(payload)
              }

              
           } 

        }
      }
    });
    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primaryColor = action.response.find(item => item.button == 12).color;
        this.accentColor = action.response.find(item => item.button == 13).color;
       }
      

    })

  }

  private unsubscribe() {
    this.loadStoryDetailSuccessSubscription.unsubscribe();
    this.loadColorSuccessSubscription.unsubscribe();
  }

  private captureParams() {
   this.idOrSlug = this.activatedRoute.snapshot.paramMap.get('idOrSlug');
    this.companyId = this.activatedRoute.snapshot.paramMap.get('companySlug');
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
  }

  private loadDetail() {
    if(!this.token){
      this.storyHttpService.getVideoDetail(this.idOrSlug, this.companyId,null).subscribe((res)=>{
        this.storyDetail = res;
        if(this.storyDetail && this.storyDetail.media &&  this.storyDetail.media && this.storyDetail.media.length){
          this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.storyDetail?.company?.companyId));
          this.mediaPayload  = [];
           for(let i=0 ; i< this.storyDetail.media.length ; i++){
            var payload:PublicMeediaPayload = new PublicMeediaPayload();
              if(this.storyDetail.media[i].type == "video/mp4"){
                payload.type = this.storyDetail.media[i].type
                payload.url = this.storyDetail.media[i].source;
                payload.source = this.storyDetail.media[i].source
                payload.asset_id = this.storyDetail.media[i].asset_id
                this.mediaPayload.push(payload)
              }

              
           } 
           this.addMetaTags();

        }
      })
      
      // this.store$.dispatch(new storyActions.LoadVideo(this.idOrSlug, this.companyId,null));
    }
    else{
      this.storyHttpService.getVideoDetail(this.idOrSlug, this.companyId,this.token).subscribe((res)=>{
        this.storyDetail = res;
        if(this.storyDetail && this.storyDetail.media &&  this.storyDetail.media && this.storyDetail.media.length){
          this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.storyDetail?.company?.companyId));
          this.mediaPayload  = [];
           for(let i=0 ; i< this.storyDetail.media.length ; i++){
            var payload:PublicMeediaPayload = new PublicMeediaPayload();
              if(this.storyDetail.media[i].type == "video/mp4"){
                payload.type = this.storyDetail.media[i].type
                payload.url = this.storyDetail.media[i].source
                payload.thumbnail_url = this.storyDetail.media[i].thumbnail_url ? this.storyDetail.media[i].thumbnail_url : 'https://image.mux.com/'+this.storyDetail.media[i].source+'/thumbnail.jpg'
                payload.title = this.storyDetail?.company?.name
                payload.asset_id = this.storyDetail.media[i].asset_id
                this.mediaPayload.push(payload)
              }

              
           } 

        }
      })
      
      // this.store$.dispatch(new storyActions.LoadVideo(this.idOrSlug, this.companyId,null));
    }
    // else{
    //   this.storyHttpService.getVideoDetail(this.idOrSlug, this.companyId,this.token).subscribe((res)=>{

    //   })
    // }
    
  }

  plusSlides(data){
    if(data == 1){
      if(this.storyDetail.media.length - 1 == this.activeIndex){
        this.activeIndex = 0
      }
      else{
        this.activeIndex = this.activeIndex + 1
      }
      
   
    }
    if(data == -1){
      if(this.activeIndex == 0){
        this.activeIndex = 0;
      }
      else{
        this.activeIndex = this.activeIndex - 1
      }
    }
    }



  // Public function
  genereateUrl(token){
    if(token){
      var url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://videos.goodseeker.com/video/embed/${token}?autoplay=false`)
   
      return url
    }
  }
  getUrl(media){
    if(media && media.thumbnail_url){
      return media.thumbnail_url
    }
    else{
     return 'https://image.mux.com/'+media.source+'/thumbnail.jpg'
    }
  }
  public isVideo(type) {
    if(type && type.indexOf('video')>-1){
     this.setFrameHeight()
    }
    return type && type.indexOf('video') > -1;
  }
  setFrameHeight(){
    if(this.isBrowser){
      let width = $('iframe#copy-video-board_iframe').width();
      if(width != 0){
      $('iframe#copy-video-board_iframe').css('height',(width/16)*9);
      }
    }

  }
  downloadVideo(media){
    this.landingPopupService.show()
    this.StoryHttpService.CheckStaticRedentionsisEadyorNot(this.currentCompany?.id,media.asset_id).subscribe((res:any)=>{
  
      if(res && res.static_renditions && res?.static_renditions?.files && res?.static_renditions?.files.length){
        let srcVideo = 'https://stream.mux.com/'+media.source+this.getextension(res?.static_renditions?.files)+'?download=media';
        this.landingPopupService.hide()
          let s = document.createElement('a');
          s.href = srcVideo;
          s.download = 'media';
          let divSpace = document.getElementById('downloadfile');
          divSpace.appendChild(s);
          s.click();
          divSpace.innerHTML = '';

        
      }
       console.log(res)
     })
  }

  getextension(data) : string {
    let str = ''
    if(data.length == 1){
      str+='/low.mp4'
    }
    if(data.length == 2){
      str+='/medium.mp4'
    }
    if(data.length == 3){
      str+='/high.mp4'
    }
    return str;
  
  }
}


export interface PublicMeediaPayload {
  type?:any;
  url:SafeUrl;
  imageUrl?:string;
  source?:any
  thumbnail_url?:any;
  title?:any;
  asset_id?:any;
}

export class PublicMeediaPayload {
constructor() {
}
}