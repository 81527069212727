import { PaginationModel } from "@framework/models/pagination-models";

export interface LoadMediaInputApiModel {
    fullinfo: number,
    companyId: number,
    selectedValues: number[],
    isFilterByGlobal: boolean,
    isFilterByCompany: boolean
    searchFrom?: string
    searchText?: string
}

export class LoadMediaInputApiModel {
    constructor(public pagination: PaginationModel) {
        this.fullinfo = 0;
    }

    private buildQuery() {
        let query = '';
        if (this.selectedValues && this.selectedValues.length > 0) {
            query += `selected=${this.selectedValues.join(',')}&`;
        }
        query += this.pagination.query;

        return query;
    }

    get query() {
        return this.buildQuery();
    }
}