import { Component, SimpleChanges, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ComponentControlBase } from '@framework/base';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

// https://github.com/zefoy/ngx-perfect-scrollbar

@Component({
  selector: 'scrollbar',
  templateUrl: './scrollbar.component.html',
  styleUrls: ['./scrollbar.component.scss']
})
export class ScrollbarComponent extends ComponentControlBase {

  @Output() onScrollY: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScrollX: EventEmitter<any> = new EventEmitter<any>();

  @Output() onScrollUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScrollDown: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScrollLeft: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScrollRight: EventEmitter<any> = new EventEmitter<any>();

  @Output() onYReachEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onYReachStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() onXReachEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onXReachStart: EventEmitter<any> = new EventEmitter<any>();

  @Output() onPositionChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  config: any = {};

  @Input()
  css: string = '';

  @ViewChild('scroll')
  scrollbar: PerfectScrollbarComponent;

  constructor() {
    super();
  }

  protected onInit(): void {

  }
  protected onDestroy(): void {

  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  public focus(): void {

  }

  public scrollToBottom(offset?: number, speed?: number): void {
    this.scrollbar.directiveRef.scrollToBottom(offset, speed);
  }

  public scrollToTop(offset?: number, speed?: number): void {
    this.scrollbar.directiveRef.scrollToTop(offset, speed);
  }

  public psScrollY($event: any) {
    this.onPositionChanged.emit();
    this.onScrollY.emit($event);
  };
  public psScrollX($event: any) {
    this.onPositionChanged.emit();
    this.onScrollX.emit($event);
  };
  public psScrollUp($event: any) {
    this.onScrollUp.emit($event);
  };
  public psScrollDown($event: any) {
    this.onScrollDown.emit($event);
  };
  public psScrollLeft($event: any) {
    this.onScrollLeft.emit($event);
  };
  public psScrollRight($event: any) {
    this.onScrollRight.emit($event);
  };

  public psYReachEnd($event: any) {
    this.onYReachEnd.emit($event);
  };
  public psYReachStart($event: any) {
    this.onYReachStart.emit($event);
  };
  public psXReachEnd($event: any) {
    this.onXReachEnd.emit($event);
  };
  public psXReachStart($event: any) {
    this.onXReachStart.emit($event);
  };
}
