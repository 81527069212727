<div class="modal-header">
    <h5 class="modal-title">Add Note</h5>
    <button type="button" (click)="close()" class="close"><span aria-hidden="true">
        <svg role="img">
          <use xlink:href="#icon-close"></use>
        </svg></span></button>
  </div>
  
  <div class="modal-body" [formGroup]="form">
    <gs-area-material
    label=""
    name="about" placeholder="What happened? Why does it matter?" rows="3"
    cssClass="spacing-lg" formControlName="notes" [formControl]="form.controls.notes">
</gs-area-material>

<div class="notes__body">

  <div class="danger row" *ngFor="let item of allnotes; let i=index">
    <div class="col-10 mt-3">
      <p><strong>{{item.note_text}}</strong> </p>
    </div>
  
    <div class="actions col mt-3" style="cursor: pointer;"
    >
      <div  (click)="updateStory(i,item)">
        <i *ngIf="!isupdating" class="fa-regular fa-pencil"></i>
        <i *ngIf="isupdating" class="fas fa-spinner fa-pulse"></i>
      </div>
      <div (click)="deleteStory(i,item)">
        <i *ngIf="!isupdating" class="fa-regular fa-trash"></i>
        <i *ngIf="isupdating" class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
    <div *ngIf="getText(item)" class="col noteinfo">
      <p style="float: right;">{{getText(item)}}</p>
    </div>
   
  </div>

</div>
  </div>
  
  <div class="modal-footer">
    <button (click)="save()" type="button" [disabled]="isupdating || form.invalid" class="btn btn-pink min-width">  <i *ngIf="isupdating" class="fas fa-spinner fa-pulse"></i>{{isUpdate  ? 'Update' : 'Add'}}
    </button>

    <button (click)="close()" type="button" data-dismiss="modal"
      class="btn btn-outline btn-gray-light min-width">Cancel</button>
  </div>
