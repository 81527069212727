import { Action } from '@ngrx/store';

import { CampaignOutputApiModel } from '@modules/company/models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { CreateCampaignInputApiModel, SendInviteInputApiModel, GetCampaignInputApiModel, UpdateCampaignInputApiModel } from '@modules/company/models/api-models/input';

export enum ActionTypes {
  LOAD = '[Campaign] Load',
  LOAD_SUCCESS = '[Campaign] Load Success',
  LOAD_FAIL = '[Campaign] Load Fail',

  UPDATE = '[Campaign] Update',
  UPDATE_SUCCESS = '[Campaign] Update Success',
  UPDATE_FAIL = '[Campaign] Update Fail',

  LOAD_DETAIL = '[Campaign] Load Detail',
  LOAD_DETAIL_SUCCESS = '[Campaign] Load Detail Success',
  LOAD_DETAIL_FAIL = '[Campaign] Load Detail Fail',

  CREATE = '[Campaign] Create',
  CREATE_SUCCESS = '[Campaign] Create Success',
  CREATE_FAIL = '[Campaign] Create Fail',

  SEND_INVITE = '[Campaign] Send Invite',
  SEND_INVITE_SUCCESS = '[Campaign] Send Invite Success',
  SEND_INVITE_FAIL = '[Campaign] Send Invite Fail',

  UPDATE_COLUM = '[Campaign] Update Question Column',
  UPDATE_COLUM_SUCCESS = '[Campaign] Update Question Column Success',
  UPDATE_COLUM_FAIL = '[Campaign] Update Question Column Fail',


  RESET_STATE = '[Campaign] Reset State'
}

export class Load implements Action {
  readonly type = ActionTypes.LOAD;

  constructor(public companyId: number, public payload: GetCampaignInputApiModel) {
  }
}


export class LoadSuccess implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public response: ResponsePaginationApiModel<CampaignOutputApiModel>) {
  }
}

export class LoadFail implements Action {
  readonly type = ActionTypes.LOAD_FAIL;

  constructor(public error: any) {
  }
}

export class UpdateColumn implements Action {
  readonly type = ActionTypes.UPDATE_COLUM;

  constructor(public companyId: number, public payload: any) {
  }
}

export class UpdateColumnSuccess implements Action {
  readonly type = ActionTypes.UPDATE_COLUM_SUCCESS;
}

export class UpdateColumnFail implements Action {
  readonly type = ActionTypes.UPDATE_COLUM_FAIL;

}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public id: number, public payload: any) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(public response:any) { }
}

export class UpdateFail implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}

export class LoadDetail implements Action {
  readonly type = ActionTypes.LOAD_DETAIL;

  constructor(public companyId: number, public idOrSlug: number|string) { }
}

export class LoadDetailSuccess implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_SUCCESS;

  constructor(public data: CampaignOutputApiModel) {
  }
}

export class LoadDetailFail implements Action {
  readonly type = ActionTypes.LOAD_DETAIL_FAIL;

  constructor(public error: any) {
  }
}

export class Create implements Action {
  readonly type = ActionTypes.CREATE;

  constructor(public companyId: number, public payload: CreateCampaignInputApiModel) {
  }
}

export class CreateSuccess implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;

  constructor(public response: CampaignOutputApiModel) {
  }
}

export class CreateFail implements Action {
  readonly type = ActionTypes.CREATE_FAIL;

  constructor(public error: any) {
  }
}

export class SendInvite implements Action {
  readonly type = ActionTypes.SEND_INVITE;

  constructor(public campaignId: number, public payload: SendInviteInputApiModel) {
  }
}

export class Reset implements Action {
  readonly type = ActionTypes.RESET_STATE;
  constructor() { }
}

export class SendInviteSuccess implements Action {
  readonly type = ActionTypes.SEND_INVITE_SUCCESS;

  constructor() {
  }
}

export class SendInviteFail implements Action {
  readonly type = ActionTypes.SEND_INVITE_FAIL;

  constructor(public error: any) {
  }
}

export type Actions =
  Load | LoadSuccess | LoadFail |
  Update | UpdateSuccess | UpdateFail |
  LoadDetail | LoadDetailSuccess | LoadDetailFail |
  Create | CreateSuccess | CreateFail |
  SendInvite | SendInviteSuccess | SendInviteFail | Reset;
