import { MemoizedSelector, createSelector } from "@ngrx/store";
import { StoryFeatureState, StoryStepState } from "../states";
import { selectStoryFeatureState } from "./story-feature.selector";
import { storyStepAdapter } from '../adapters/story-step.adapter';
import { StoryStepEntity } from '../entities';
import { StoryStepViewModel, TagViewModel } from "@modules/story/models/view-models";

export const selectStoryStepState: MemoizedSelector<object, StoryStepState> = createSelector(selectStoryFeatureState, (state: StoryFeatureState) => state.storyStep);

const {
    selectIds: selectStoryStepIds,
    selectEntities: selectStoryStepEntities,
    selectAll: selectAllStorySteps,
    selectTotal: storyStepsCount
} = storyStepAdapter.getSelectors(selectStoryStepState);


// -------------[Mapping Functions]------------- 
const getLoading = (state: StoryStepState): boolean => state.loading;
const getLoaded = (state: StoryStepState): boolean => state.loaded;
const getSelectedStepId = (state: StoryStepState): number => state.stepSelectedId;
const getStorySteps = (entities: Array<StoryStepEntity>) => {
    return entities.map(entity => new StoryStepViewModel(entity));
};

const getTagById = (state: StoryStepState): TagViewModel => {
    if (!state.stepSelectedId || !state.createdTagId) return null;
    const tagCreated = state.entities[state.stepSelectedId].tags.find(tag => tag.id === state.createdTagId);
    tagCreated['stepActive']= state.stepSelectedId
    return new TagViewModel(tagCreated);
};

// -------------[Public Selectors]-------------
export const selectLoaded: MemoizedSelector<object, boolean> = createSelector(selectStoryStepState, getLoaded);
export const selectLoading: MemoizedSelector<object, boolean> = createSelector(selectStoryStepState, getLoading);
export const selectStorySteps: MemoizedSelector<object, Array<StoryStepViewModel>> = createSelector(selectAllStorySteps, getStorySteps);
export const selectSelectedStepId: MemoizedSelector<object, number> = createSelector(selectStoryStepState, getSelectedStepId);

export const selectCreatedTag: MemoizedSelector<object, TagViewModel> = createSelector(selectStoryStepState, getTagById);
export const selectStoryStepById = createSelector(selectStoryStepState, (state: StoryStepState, stepId: number) => state.entities[stepId]);