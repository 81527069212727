import { ProfileInternalOutputApiModel } from "../models/api-models/output";
import { CompanyButtonEntity } from ".";

export interface ImageEntity {
    preview: string;
}

export interface ProfileInternalEntity {
    name: string;
    email: string;
    website: string;
    logo: ImageEntity;
    background: ImageEntity;
    buttons: CompanyButtonEntity[];
    slogan: string;
    logo_default?: any;
}

export class ProfileInternalEntity {
    constructor(data?: ProfileInternalOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: ProfileInternalOutputApiModel) {
        this.name = data.name;
        this.email = data.email;
        this.website = data.website;
        if (data.buttons) {
            this.buttons = data.buttons.map((button) => {
                return {
                    label: button.label,
                    url: button.url,
                    foreground: button.foreground,
                    background: button.background,
                    isSelected: button.isSelected
                };
            });
        }
        this.slogan = data.slogan;
        this.logo = data.logo;
        this.background = data.background;
        this.logo_default = data.logo_default
    }
}
