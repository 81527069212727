import { SimpleChanges, ContentChild, TemplateRef, Component } from '@angular/core';

import { ComponentBase } from '@framework/base/component/component.base';

@Component({
    selector: 'app-toolbar-item',
    template: `
        <li>
            <ng-content></ng-content>
        </li>
    `
})
export class ToolbarItemComponent extends ComponentBase {
    constructor(
    ) {
        super();
    }

    protected onInit(): void {
        
    }
    protected onDestroy(): void {
        
    }
    protected onChanges(changes: SimpleChanges): void {

    }
    protected onDoCheck(): void {

    }
    protected onAfterContentInit(): void {

    }
    protected onAfterContentChecked(): void {

    }
    protected onAfterViewInit(): void {
       
    }
    protected onAfterViewChecked(): void {

    }
}