import { MemoizedSelector, createSelector } from '@ngrx/store';

import { CompanyFeatureState, SearchBoxState } from '../states';
import { selectCompanyFeatureState } from './company-feature.selectors';
import { SearchBoxStateViewModel } from '../../models/store-view-models';


export const selectSearchBoxState: MemoizedSelector<object, SearchBoxState> =
    createSelector(selectCompanyFeatureState, (state: CompanyFeatureState) => state.searchBox);


// -------------[Mapping Functions]-------------

const getSearchBoxState = (state: SearchBoxState): SearchBoxStateViewModel => {
    return {
        isShow: state.isShow,
        isShowResult: state.isShowResult,
        isSearching: state.isSearching,
        searchpayload: state.searchpayload
    };
};

// -------------[Public Selectors]-------------

export const selectSearchBoxStatus: MemoizedSelector<object, SearchBoxStateViewModel> = createSelector(selectSearchBoxState, getSearchBoxState);

