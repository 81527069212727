import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { companyReducers } from './reducers';
import * as effects from './effects';
import { CompanyHttpService, CampaignHttpService, LandingOrderHttpService } from '../services';
import { ProfileInternalEffects, ProfilePublicEffects } from '@modules/company-admin/store/effects';
import { ProfileInternalHttpService, ProfilePublicHttpService } from '@modules/company-admin/store/services';
import { StoryFeedEmbedService } from '../services/story-embed-feed.http-service';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('companyFeature', companyReducers),
        EffectsModule.forFeature([
            effects.CompanyEffects,
            effects.CampaignEffects,
            effects.CompanySettingEffects,
            effects.LandingPageEffects,
            effects.CompanyEmbedEffects,
            ProfileInternalEffects,
            ProfilePublicEffects
        ])
    ],
    providers: [
        CompanyHttpService,
        StoryFeedEmbedService,
        CampaignHttpService,
        ProfileInternalHttpService,
        ProfilePublicHttpService,
        LandingOrderHttpService
    ]
})
export class CompanyStoreModule { }
