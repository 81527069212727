import { Action } from '@ngrx/store';
import { ResponsePaginationApiModel } from '@framework/models';
import { ImageGallerySettingOutputApiModel } from '../models/api-models/output';
import { ImageGallerySettingsInputApiModel, UpdateImageGalleryConfigInputApiModel, UpdateImageStatusInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD_IMAGES = '[Company Admin Image Gallery Setting] Load Images',
    LOAD_IMAGES_SUCCESS = '[Company Admin Image Gallery Setting] Load Images Success',
    LOAD_IMAGES_FAIL = '[Company Admin Image Gallery Setting] Load Images Fail',

    LOAD_CONFIG = '[Company Admin Image Gallery Setting] Load Config',
    LOAD_CONFIG_SUCCESS = '[Company Admin Image Gallery Setting] Load Config Success',
    LOAD_CONFIG_FAIL = '[Company Admin Image Gallery Setting] Load Config Fail',

    ADD = '[Company Admin Image Gallery Setting] add',
    ADD_SUCCESS = '[Company Admin Image Gallery Setting] add Success',
    ADD_FAIL = '[Company Admin Image Gallery Setting] add Fail',

    DELETE = '[Company Admin Image Gallery Setting] delete',
    DELETE_SUCCESS = '[Company Admin Image Gallery Setting] delete Success',
    DELETE_FAIL = '[Company Admin Image Gallery Setting] delete Fail',

    UPDATE_CONFIG = '[Company Admin Image Gallery Setting] update',
    UPDATE_CONFIG_SUCCESS = '[Company Admin Image Gallery Setting] update Success',
    UPDATE_CONFIG_FAIL = '[Company Admin Image Gallery Setting] update Fail',

    UPDATE_IMAGE_STATUS = '[Company Admin Image Gallery Setting] update Image Status',
    UPDATE_IMAGE_STATUS_SUCCESS = '[Company Admin Image Gallery Setting] update Image Status Success',
    UPDATE_IMAGE_STATUS_FAIL = '[Company Admin Image Gallery Setting] update Image Status Fail'
}

export class LoadImages implements Action {
    readonly type = ActionTypes.LOAD_IMAGES;
    constructor(public companyId: number) { }
}

export class LoadImagesSuccess implements Action {
    readonly type = ActionTypes.LOAD_IMAGES_SUCCESS;
    constructor(public response: ResponsePaginationApiModel<ImageGallerySettingOutputApiModel>) { }
}

export class LoadImagesFail implements Action {
    readonly type = ActionTypes.LOAD_IMAGES_FAIL;
    constructor(public error: any) { }
}

export class LoadConfig implements Action {
    readonly type = ActionTypes.LOAD_CONFIG;
    constructor(public companyId: number) { }
}

export class LoadConfigSuccess implements Action {
    readonly type = ActionTypes.LOAD_CONFIG_SUCCESS;
    constructor(public response: any) { }
}

export class LoadConfigFail implements Action {
    readonly type = ActionTypes.LOAD_CONFIG_FAIL;
    constructor(public error: any) { }
}

export class UpdateConfig implements Action {
    readonly type = ActionTypes.UPDATE_CONFIG;
    constructor(public companyId: number, public payload: Partial<UpdateImageGalleryConfigInputApiModel>) { }
}

export class UpdateConfigSuccess implements Action {
    readonly type = ActionTypes.UPDATE_CONFIG_SUCCESS;
}

export class UpdateConfigFailure implements Action {
    readonly type = ActionTypes.UPDATE_CONFIG_FAIL;
    constructor(public error: any) { }
}

export class UpdateImageStatus implements Action {
    readonly type = ActionTypes.UPDATE_IMAGE_STATUS;
    constructor(public companyId: number, public imageId: number, public payload: Partial<UpdateImageStatusInputApiModel>) { }
}

export class UpdateImageStatusSuccess implements Action {
    readonly type = ActionTypes.UPDATE_IMAGE_STATUS_SUCCESS;
}

export class UpdateImageStatusFailure implements Action {
    readonly type = ActionTypes.UPDATE_IMAGE_STATUS_FAIL;
    constructor(public error: any) { }
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;
    constructor(public companyId: number, public imageId: number, public payload: Partial<UpdateImageStatusInputApiModel>) { }
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
}

export class DeleteFailure implements Action {
    readonly type = ActionTypes.DELETE_FAIL;
    constructor(public error: any) { }
}

export class Add implements Action {
    readonly type = ActionTypes.ADD;
    constructor(public companyId: number, public payload: Partial<ImageGallerySettingsInputApiModel>) { }
}

export class AddSuccess implements Action {
    readonly type = ActionTypes.ADD_SUCCESS;
}

export class AddFailure implements Action {
    readonly type = ActionTypes.ADD_FAIL;
    constructor(public error: any) { }
}

export type Actions =
    LoadImages |
    LoadImagesSuccess |
    LoadImagesFail |
    LoadConfig |
    LoadConfigSuccess |
    LoadConfigFail |
    UpdateConfig |
    UpdateConfigSuccess |
    UpdateConfigFailure |
    UpdateImageStatus |
    UpdateImageStatusSuccess |
    UpdateImageStatusFailure |
    Delete |
    DeleteSuccess |
    DeleteFailure |
    Add |
    AddSuccess |
    AddFailure;
