import { LandingPageButtonOutputApiModel } from "./landing-page-button-output.api-model";
import { LandingPageQueryObjectsModel } from "../../commons";

export interface LandingPageAuthorApiModel {
    id: number;
    display_name: string;
}

export interface LandingPageOutputApiModel {
    id: number;
    title: string;
    author: LandingPageAuthorApiModel;
    time_created: Date;
    hidden: boolean;
    archived: boolean;
    slug: string;
    person: string;
    slogan: string;
    header_image: string;
    avatar_image: string;
    query_objects: LandingPageQueryObjectsModel;
    buttons: LandingPageButtonOutputApiModel[];
    allow_inbound_stories: number;
    is_public: boolean;
    company_id_slug: string;
    is_draft?: boolean;
    is_board_visible?: boolean;
    show_submit_story_btn?: any;
    enable_overlay?: boolean;
    cta?: any;
    cta_form_configs?: any;
    is_footnote_visible?: boolean;
}

export class LandingPageOutputApiModel {
    constructor(data?: Partial<LandingPageOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}