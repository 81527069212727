export interface CustomUrlOutputApiModel {
  label: string;
  url: string;
}

export interface AuthorOutputApiModel {
  author_id: number;
  display_name: string;
  title: string;
  avatar: string;
}

export interface SocialSiteOutputApiModel {
  name: string;
  image: string;
  url: string;
}

export interface FrequentTagItem {
  color: string;
  data: string[];
  sort: number;
  title: string;
  is_header_part: boolean;
  header_title?: any;
}

export interface FrequentTag {
  [key: number]: FrequentTagItem;
}

export interface CompanyButtonOutputApiModel {
  label: string;
  url: string;
  foreground: string;
  background: string;
  isSelected: boolean;
}

export interface InfoCompanyOutputApiModel {
  id: number;
  name: string;
  logo: string;
  email: string;
  domains: Domain[];
  website: string;
  background: string;
  buttons: CompanyButtonOutputApiModel[];
  slogan: string;
  blurb: string;
  show_goodseeker_branding?: boolean;
  author: AuthorOutputApiModel;
  social_sites: SocialSiteOutputApiModel[];
  count_stories: number;
  count_authors: number;
  count_contributors: number;
  count_campaigns: number;
  count_featured_pages: number;
  slug: string;
  page_public: boolean;
  allow_inbound_stories: number;
  frequent_tags: FrequentTag;
  permission: boolean;
  is_campaign_company: boolean;
  show_logo_header: boolean;
  is_logo_updated: boolean;
  enable_public?: boolean,
  default_story_prompt: any;
  company_sendgrid_details: any
  internal_stories_approval: any;
  enable_overlay?: boolean;
}

export interface Domain {
  domain: string;
}

export class InfoCompanyOutputApiModel {
  constructor(data?: Partial<InfoCompanyOutputApiModel>) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
