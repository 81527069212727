import { Component, SimpleChanges, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { StoryViewModel } from '@modules/story/models/view-models';

@Component({
  selector: 'app-pb-no-permision-content',
  templateUrl: './no-permision-content.component.html',
  styleUrls: ['./no-permision-content.component.scss']
})
export class NoPermissionContentComponent extends ComponentBase {
  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;

  private selectStoryDetailSubscription: Subscription;
  // Public variables
  public storyDetail: StoryViewModel;

  constructor(private store$: Store<CoreState>, private router: Router) {
    super();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
    });
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
  }
  // Public functions
  public login() {
    const nextUrl = window.location.href;
    this.router.navigate(['/user/login'], { queryParams: { nexturl: nextUrl } });
  }

  public signup() {
    this.router.navigate(['/user/signup']);
  }
}