<div class="modal-header pb-0">
  <h4 class="modal-title">VIDEO LINKS</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="copyForm">
  <div class="code-wrapper embed-form-disabled">
    <h6 class="mt-4 mb-3">SHARE LINK &nbsp;</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="LINK" placeholder="LINK" [disabled]="true" formControlName="framebodyCode"
        [formControl]="copyForm.controls.framebodyCode"></gs-input-clipboard>
    </div>
  </div>
  <div class="code-wrapper embed-form-disabled">
    <h6 class="mt-4 mb-3">EMBED LINK&nbsp;</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="LINK" placeholder="LINK" [disabled]="true" formControlName="embedbodyCode"
        [formControl]="copyForm.controls.embedbodyCode"></gs-input-clipboard>
    </div>
  </div>
  <hr>
  <div class="color-wrapper embed-sub-wrapper mb-4 px-3 pb-0 pt-3">
    <h6 class="mb-3">EMBED CODE</h6>
    <div class="row">
      <div class="col-lg-2 iFrame-value px-3">
        <h6>Height</h6>
      </div>
      <div class="col-lg-4">
        <gs-input-material formControlName="height" [formControl]="copyForm.controls.height" label="height"
          type="number">
        </gs-input-material>
      </div>
      <div class="col iFrame-value">
        <!-- <h6>Px</h6> -->
        <select formControlName="heightList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 iFrame-value px-3">
        <h6>Width</h6>
      </div>
      <div class="col-lg-4">
        <gs-input-material formControlName="width" [formControl]="copyForm.controls.width" label="Width" type="number">
        </gs-input-material>
      </div>
      <div class="col iFrame-value">
        <select formControlName="widthList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>
    <div class="code-wrapper embed-form-disabled">
      <h6 class="mt-4 mb-3">Place the below code inside your <span>&lt;body&gt;</span> tag. {{updatedIframeCode}}</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="bodyCode"
          [formControl]="copyForm.controls.bodyCode"></gs-input-clipboard>
      </div>
    </div>
    <div class="code-wrapper embed-form-disabled">
      <h6>You can use these classes to override the CSS under <span>&lt;style&gt;</span> tag.</h6>
      <div class="form-label-group form-group-copy nolabel">
        <gs-textarea-clipboard name="styledbodyCode" placeholder="Body Code" rows="8" formControlName="styledbodyCode"
          [formControl]="copyForm.controls.styledbodyCode"></gs-textarea-clipboard>
      </div>
    </div>
    <hr>
  </div>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->