import { Component, SimpleChanges, HostListener, Output, EventEmitter, Input } from '@angular/core';

import { ComponentBase } from '@framework/base/component';


@Component({
  selector: 'app-infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.scss']
})
export class InfinityScrollComponent extends ComponentBase {
  @HostListener("window:scroll", [])
  onScroll(): void {
    this.onInfinite();
  }

  // Output 
  @Output() infinite: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled: boolean = false;
  @Input() showIndicator: boolean = false;

  // Private variables
  private threshold: number = 20; // UoM is %
  private isDisabled: boolean = false;
  // Public variables
  public isShowIndicator: boolean = false;

  constructor() {
    super();

  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }

  private onInfinite() {
    if (this.disabled || this.showIndicator) return;

    const thresholdInPx = (this.threshold / 100) * document.body.offsetHeight;
    if ((window.innerHeight + window.pageYOffset) + thresholdInPx >= document.body.offsetHeight) {
      this.infinite.emit();
    }
  }
  // Public functions
  public complete() {
    this.isShowIndicator = false;
  }

  public disable() {
    this.isDisabled = true;
  }

  public enable() {
    this.isDisabled = false;
  }

}
