import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, delay } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { StoryOutputApiModel, ContributionOutputApiModel } from '@modules/story/models/api-models/output';
import { CreateStoryInputApiModel, SubmitCtaInputApiModel, CreateContributionApiModel } from '../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models/api-models';

@Injectable()
export class StoryHttpService extends BaseHttpService {

    constructor(protected http: HttpClient) {
        super(http, '/stories');
    }

    create(payload: CreateStoryInputApiModel) {
        return this.post('/', payload).pipe(map((response: any) => {
            return new StoryOutputApiModel(response);
        }));
    }

    createSEO(payload: any,storyId) {
        return this.patch(`/${storyId}/seo-data/`, payload).pipe(map((response: any) => {
            return response;
        }));
    }

    createInbound(payload: CreateStoryInputApiModel) {
        return this.post(`/inbound/`, payload).pipe(
            map((response: any) => {
                return new StoryOutputApiModel(response);
            }));
    }

    update(storyId: number, payload: Partial<CreateStoryInputApiModel>) {
        return this.patch(`/${storyId}/`, payload).pipe(delay(2000), map((response: Partial<CreateStoryInputApiModel>) => {
            return response;
        }));
    }

    deleteastory(storyid){
        return this.delete(`/${storyid}/?delete=1&deleter=admin`).pipe(map((response: any) => response));
    }

    getStoryEdit(storyIdOrSlug: number | string) {
        return this.get('/' + storyIdOrSlug + '/').pipe(map((response: StoryOutputApiModel) => {
            return new StoryOutputApiModel(response);
        }));
    }

    getSeodetail(storyIdOrSlug: number | string) {
        return this.get('/' + storyIdOrSlug + '/seo-data/').pipe(map((response: any) => {
            return response;
        }));
    }
    getStoryDetail(storyIdOrSlug: number | string, token?: any , isPublic?:boolean , campaignId?:any , spotloghtId?: any) {
        if(!token){
            if(!isPublic){
                if(campaignId){
                    return this.get('/' + storyIdOrSlug + `/?fullinfo=1&id_campaign=${campaignId}`).pipe(map((response: StoryOutputApiModel) => {
                        return new StoryOutputApiModel(response);
                    }));
                }
                else if(spotloghtId){
                    return this.get('/' + storyIdOrSlug + `/?fullinfo=1&id_spotlight=${spotloghtId}`).pipe(map((response: StoryOutputApiModel) => {
                        return new StoryOutputApiModel(response);
                    }));
                }
                else{
                    return this.get('/' + storyIdOrSlug + `/?fullinfo=1`).pipe(map((response: StoryOutputApiModel) => {
                        return new StoryOutputApiModel(response);
                    }));
                }

            }
            else{
                if(campaignId){
                    return this.get('/' + storyIdOrSlug + `/?fullinfo=1&public=1&id_campaign=${campaignId}`).pipe(map((response: StoryOutputApiModel) => {
                        return new StoryOutputApiModel(response);
                    }));
                }
                else if (spotloghtId){
                    return this.get('/' + storyIdOrSlug + `/?fullinfo=1&public=1&id_spotlight=${spotloghtId}`).pipe(map((response: StoryOutputApiModel) => {
                        return new StoryOutputApiModel(response);
                    }));
                }
                else{
                    return this.get('/' + storyIdOrSlug + `/?fullinfo=1&public=1`).pipe(map((response: StoryOutputApiModel) => {
                        return new StoryOutputApiModel(response);
                    }));
                }

            }

        }else
        {
            return this.get('/' + storyIdOrSlug + '/anonymous/?token='+token+'&fullinfo=1').pipe(map((response: StoryOutputApiModel) => {
                return new StoryOutputApiModel(response);
            }));
        }
       
    }

    getVideoDetail(storySlug,companyId,token){
        if(!token){
            return this.get('/' + storySlug + '/'+ companyId + '/video/').pipe(map((response: any) => {
                return response;
            }));
        }
        else{
            return this.get('/' + storySlug + '/'+ companyId + '/video/anonymous/?token='+token).pipe(map((response: any) => {
                return response;
            }));  
        }

        
    }


    getSpotlightStoryDetailToken(storyIdOrSlug: number | string, token?: any , spotlightid?: any) {
        return this.get('/' + storyIdOrSlug + '/anonymous/?fullinfo=1&token='+token+'&id_spotlight='+spotlightid).pipe(map((response: StoryOutputApiModel) => {
            return new StoryOutputApiModel(response);
        }));
    }

    getCampaignStoryDetailToken(storyIdOrSlug: number | string, token?: any , campaignId?:any) {
                return this.get('/' + storyIdOrSlug + '/anonymous/?fullinfo=1&token='+token+'&id_campaign=' +campaignId).pipe(map((response: StoryOutputApiModel) => {
                    return new StoryOutputApiModel(response);
                }));
    }
    publishStory(storyId: number) {
        return this.post(`/${storyId}/publish/`, {});
    }

    deleteStory(storyId: number) {
        return this.delete(`/${storyId}/`).pipe(map((response: any) => response));
    }

    bulkDelteStory(ids){
        return this.delete(`/bulk/ids=${ids.join(",")}/`).pipe(map((response: any) => response));
    }

    submitCta(storyId: number, payload: Partial<SubmitCtaInputApiModel>) {
        return this.post(`/${storyId}/cta-interactions/`, payload);
    }

    putContribution(storyId: number, payload: CreateContributionApiModel) {
        return this.put(`/${storyId}/contributions/`, payload);
    }

    getContributionDetail(id: number) {
        return this.get(`/contributions/${id}/`).pipe(map((response: ContributionOutputApiModel) => {
            return response;
        }));
    }

    deleteContribution(id: number) {
        return this.delete(`/${id}/contributions/`).pipe(map((response: ContributionOutputApiModel) => {
            return response;
        }));
    }

    viewStory(storyId: number) {
        return this.post(`/${storyId}/views/`, {});
    }

    getByIds(ids: number[]) {
        return this.get(`/getbyids/?ids=${ids.join(',')}`).pipe(map((response: ResponsePaginationApiModel<StoryOutputApiModel>) => {
            return response;
        }));
    }

    public likeStoryLag(storyId: number, tagId: number) {
        return this.post(`/${storyId}/${tagId}/likes/`, '').pipe(
          map((response: any) => {
            return response;
          })
        );
      }

      public sendEmails(payload) {
        return this.post(`/request/email/`, payload).pipe(
          map((response: any) => {
            return response;
          })
        );
      }
    
}