export interface CreateTagOutputApiModel {
    resource_type: string,
    resource_id: number,
    name: string,
    id: number
}

export class CreateTagOutputApiModel {
    constructor(init?: Partial<CreateTagOutputApiModel>) {
        Object.assign(this, init);
        this.init();
    }


    private init() {
        this.id = Number(this.id);
    }
}