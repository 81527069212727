

export interface Callback {
    onSubmit: (id, fileName) => void,
    onSubmitted: (id, name) => void,
    onComplete: (id, name, responseJSON, maybeXhr) => void,
    onAllComplete: (successful, failed) => void,
    onCancel: (id, name) => void,
    onUpload: (id, name) => void,
    onUploadChunk: (id, name, chunkData) => void,
    onUploadChunkSuccess: (id, chunkData, responseJSON, xhr) => void,
    onResume: (id, fileName, chunkData) => void,
    onProgress: (id, name, loaded, total) => void,
    onTotalProgress: (loaded, total) => void,
    onError: (id, name, reason, maybeXhrOrXdr) => void,
    onSessionRequestComplete: (response, success, xhrOrXdr) => void,
    onValidate: (data, buttonContainer) => void,
    onValidateBatch: (fileOrBlobDataArray, buttonContainer) => void
}

export class Callback {
    constructor() { }
}