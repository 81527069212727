import { Component, SimpleChanges, Input, EventEmitter, Output, ContentChildren, QueryList, TemplateRef } from '@angular/core';

import { ComponentBase } from '@framework/base/component';
import { ToolbarItemComponent } from './toolbar-item.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends ComponentBase {
  // Private variables

  // Public variables
  public insideIframe: boolean;

  constructor() {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    if (window.location !== window.parent.location) {
      this.insideIframe = true;
      document.getElementsByClassName("site-container")[0]['style'].padding = 0;
    } else {
      this.insideIframe = false;
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {
    
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }
  // Public functions

}
