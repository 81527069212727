import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, far, fab);

export class FontAwesomeIcon {
  name: string;
  preffix: string;
  svgData: any;
  svgTagString: string;
}
@Component({
  selector: 'gs-icon-picker',
  templateUrl: './gs-icon-picker.component.html',
  styleUrls: ['./gs-icon-picker.component.css']
})
export class GsIconPickerComponent implements OnChanges {
  icons: FontAwesomeIcon[] = [];
  filteredIcons: FontAwesomeIcon[] = [];
  @Input() Search: string;
  @Output() selectLogo: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.initIcons();
  }

  initIcons() {
    let fasArray = Object.keys(library['definitions'].fas);
    let farArray = Object.keys(library['definitions'].far);
    let fabArray = Object.keys(library['definitions'].fab);

    fasArray.forEach(icon => this.icons.push(this.getSvgIconData('fas', icon)));
    farArray.forEach(icon => this.icons.push(this.getSvgIconData('far', icon)));
    fabArray.forEach(icon => this.icons.push(this.getSvgIconData('fab', icon)));
    this.filteredIcons = [...this.icons];
  }

  getSvgIconData(prefix: string, icon: string) {
    return {
      preffix: prefix,
      name: icon,
      svgData: library['definitions'][prefix][icon],
      svgTagString: this.getSvgTagString(library['definitions'][prefix][icon])
    }
  }

  getSvgTagString(svgData: any) {
    return `<svg viewBox="0 0 ${svgData[0]} ${svgData[1]}" height="20px"><path d="${svgData[4]}"/></svg>`;
  }

  selectedIcon(icon: FontAwesomeIcon) {
    this.selectLogo.emit(icon);
  }

  ngOnChanges() {
    this.filter(this.Search.toLowerCase());
  }

  filter(iconName: string) {
    if (iconName) {
      this.filteredIcons = [...this.icons.filter(i => i.name.includes(iconName))];
    } else {
      this.selectLogo.emit(null);
      this.filteredIcons = [...this.icons];
    }
  }
}
