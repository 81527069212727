import {Directive, HostListener} from "@angular/core";

@Directive({selector: '[ComponentCanDeactivate]'})
export abstract class ComponentCanDeactivate {
    public allowDeactive: boolean = false;

    constructor() {

    }

    @HostListener('window:beforeunload', ['$event'])
    unloadBrowser($event: any) {
        if (!this.allowDeactive) {
            $event.returnValue = true;
        }
    }
}