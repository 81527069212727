import { initialState, likeAdapter } from '../adapters/like.adapter';
import { LikeState } from '../states';
import { likeActions } from '../actions';


export function likeReducer(state = initialState, action: likeActions.Actions): LikeState {
    switch (action.type) {

        default: {
            return state;
        }
    }
}
