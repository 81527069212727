import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import { landingPageActions } from '@modules/company/store/actions';
import { LandingPageViewModel, CompanyViewModel } from '@modules/company/models/store-view-models';
import { LoadStatusModel } from '@modules/shared/models';
import { GetLandingPageInputApiModel } from '@modules/company/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { landingPageSelectors, companySelectors } from '@modules/company/store/selectors';
import { CompanyHttpService } from '@modules/company/services';

@Component({
  selector: 'co-pu-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends ComponentBase {
  public showIndicator: boolean = false;
  public disabled: boolean = false;

  // Private variables
  private selectLandingPage$: Observable<LandingPageViewModel[]>;
  private selectLandingPageLoadStatus$: Observable<LoadStatusModel>;
  private selectCurrentCompany$: Observable<CompanyViewModel>;

  private selectLandingPageSubscription: Subscription;
  private selectLandingPageLoadStatusSubscription: Subscription;
  private selectCurrentCompanySubscription: Subscription;

  private queryLandingPageData: GetLandingPageInputApiModel = new GetLandingPageInputApiModel(new PaginationModel(6));
  // Public variables
  public landingPages: LandingPageViewModel[] = [];
  public loadLandingPageStatus: LoadStatusModel;
  public currentCompany: CompanyViewModel = new CompanyViewModel();

  constructor(private store$: Store<CoreState>, private _companyService: CompanyHttpService) {
    super();

    this.selectLandingPage$ = this.store$.select(landingPageSelectors.selectAll);
    this.selectLandingPageLoadStatus$ = this.store$.select(landingPageSelectors.selectLoadStatus);
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectLandingPageLoadStatusSubscription = this.selectLandingPageLoadStatus$.subscribe((loadStatus) => {
      this.loadLandingPageStatus = loadStatus;
      if (loadStatus) {
        if (loadStatus.isLoaded && loadStatus.isCanLoadMore) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
    });

    this.selectLandingPageSubscription = this.selectLandingPage$.subscribe((landingPages) => {
      this.landingPages = landingPages;
      this.showIndicator = false;
    });

    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.loadLandingPages();
      }
    });
  }

  private unsubscribe() {
    this.selectCurrentCompanySubscription.unsubscribe();
    this.selectLandingPageLoadStatusSubscription.unsubscribe();
    this.selectLandingPageSubscription.unsubscribe();
  }

  private loadLandingPages() {
    this.queryLandingPageData.pagination.sort = 'desc';
    this.queryLandingPageData.is_board_visible = true;
    this.queryLandingPageData.public = true;
    this._companyService.featuredQueryData$.next(this.queryLandingPageData);
    this.store$.dispatch(new landingPageActions.Load(this.currentCompany.id, this.queryLandingPageData));
  }
  // Public functions
  public trackByFn(item: LandingPageViewModel) {
    return item.id;
  }

  public loadMorePages() {
    if (this.loadLandingPageStatus.isLoaded && this.loadLandingPageStatus.isCanLoadMore) {
      this.showIndicator = true;
      this.queryLandingPageData.pagination.page += 1;
      this.loadLandingPages();
    }
  }
}
