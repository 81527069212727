export interface CtaTemplateSelectValueViewModel {
    value: number,
    name: string,
    isEdit: boolean
}

export class CtaTemplateSelectValueViewModel {
    constructor(id: number, value: string) {
        this.value = id;
        this.name = value;
    }
}