import { Action } from '@ngrx/store';
import { SlackIntegrationOutputApiModel } from '../models/api-models/output';
import { UpdateSlackIntegrationInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Admin Integration Setting] Load',
    LOAD_SUCCESS = '[Company Admin Integration Setting] Load Success',
    LOAD_FAIL = '[Company Admin Integration Setting] Load Fail',

    DELETE = '[Company Admin Integration Setting] delete',
    DELETE_SUCCESS = '[Company Admin Integration Setting] delete Success',
    DELETE_FAIL = '[Company Admin Integration Setting] delete Fail',

    UPDATE = '[Company Admin Integration Setting] update',
    UPDATE_SUCCESS = '[Company Admin Integration Setting] update Success',
    UPDATE_FAIL = '[Company Admin Integration Setting] update Fail',

    LOAD_YAMMER = '[Company Admin Integration Setting] Load Yammer',
    LOAD_YAMMER_SUCCESS = '[Company Admin Integration Setting] Load Yammer Success',
    LOAD_YAMMER_FAIL = '[Company Admin Integration Setting] Load Yammer Fail',

    DELETE_YAMMER = '[Company Admin Integration Setting] delete Yammer',
    DELETE_YAMMER_SUCCESS = '[Company Admin Integration Setting] delete Yammer Success',
    DELETE_YAMMER_FAIL = '[Company Admin Integration Setting] delete Yammer Fail',

    UPDATE_YAMMER = '[Company Admin Integration Setting] update Yammer',
    UPDATE_YAMMER_SUCCESS = '[Company Admin Integration Setting] update Yammer Success',
    UPDATE_YAMMER_FAIL = '[Company Admin Integration Setting] update Yammer Fail',

    ADD_FB_TOKEN = '[Company Admin Integration Setting] add Fb token',
    ADD_FB_TOKEN_SUCCESS = '[Company Admin Integration Setting] add Fb token Success',
    ADD_FB_TOKEN_FAIL = '[Company Admin Integration Setting] add Fb token Fail'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: SlackIntegrationOutputApiModel) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public integrationId: number, public payload: Partial<UpdateSlackIntegrationInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;

    constructor(public companyId: number, public integrationId: number) { }
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
}

export class DeleteFailure implements Action {
    readonly type = ActionTypes.DELETE_FAIL;

    constructor(public error: any) { }
}


export class LoadYammer implements Action {
    readonly type = ActionTypes.LOAD_YAMMER;

    constructor(public companyId: number) { }
}

export class LoadYammerSuccess implements Action {
    readonly type = ActionTypes.LOAD_YAMMER_SUCCESS;

    constructor(public response: any) { }
}

export class LoadYammerFail implements Action {
    readonly type = ActionTypes.LOAD_YAMMER_FAIL;

    constructor(public error: any) { }
}

export class UpdateYammer implements Action {
    readonly type = ActionTypes.UPDATE_YAMMER;

    constructor(public companyId: number, public payload: Partial<any>) { }
}

export class UpdateYammerSuccess implements Action {
    readonly type = ActionTypes.UPDATE_YAMMER_SUCCESS;
}

export class UpdateYammerFailure implements Action {
    readonly type = ActionTypes.UPDATE_YAMMER_FAIL;

    constructor(public error: any) { }
}

export class DeleteYammer implements Action {
    readonly type = ActionTypes.DELETE_YAMMER;

    constructor(public companyId: number) { }
}

export class DeleteYammerSuccess implements Action {
    readonly type = ActionTypes.DELETE_YAMMER_SUCCESS;
}

export class DeleteYammerFailure implements Action {
    readonly type = ActionTypes.DELETE_YAMMER_FAIL;

    constructor(public error: any) { }
}

export class AddFirebaseConfig implements Action {
    readonly type = ActionTypes.ADD_FB_TOKEN;

    constructor(public companyId: number, public payload: any) { }
}

export class AddFirebaseConfigSuccess implements Action {
    readonly type = ActionTypes.ADD_FB_TOKEN_SUCCESS;
}

export class AddFirebaseConfigFailure implements Action {
    readonly type = ActionTypes.ADD_FB_TOKEN_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    Update |
    UpdateSuccess |
    UpdateFailure |
    Delete |
    DeleteSuccess |
    DeleteFailure |
    AddFirebaseConfig |
    AddFirebaseConfigSuccess |
    AddFirebaseConfigFailure ;
