export class StoryFlowViewModel {
    public id: number;
    public email: string;
    public lastName: string;
    public firstName: string;
    
    constructor(init?: Partial<StoryFlowViewModel>){
      Object.assign(this, init);
    }
    
  }