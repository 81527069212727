import { SSOModel } from "../models/sso.model";

export interface AppSettingState {
    sso: SSOModel,
    notAllowedDomains: string[],
    isLoaded: boolean,
    isLoading: boolean
}

export const initialState: AppSettingState = {
    sso: undefined,
    notAllowedDomains: [],
    isLoaded: false,
    isLoading: false
}