import { Action } from '@ngrx/store';
import { AdvocacyOutputApiModel } from '../models/api-models/output';
import { UpdateAdvocacyInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Admin Advocacy Setting] Load',
    LOAD_SUCCESS = '[Company Admin Advocacy Setting] Load Success',
    LOAD_FAIL = '[Company Admin Advocacy Setting] Load Fail',

    ADD = '[Company Admin Advocacy Setting] add',
    ADD_SUCCESS = '[Company Admin Advocacy Setting] add Success',
    ADD_FAIL = '[Company Admin Advocacy Setting] add Fail',

    DELETE = '[Company Admin Advocacy Setting] delete',
    DELETE_SUCCESS = '[Company Admin Advocacy Setting] delete Success',
    DELETE_FAIL = '[Company Admin Advocacy Setting] delete Fail',

    UPDATE = '[Company Admin Advocacy Setting] update',
    UPDATE_SUCCESS = '[Company Admin Advocacy Setting] update Success',
    UPDATE_FAIL = '[Company Admin Advocacy Setting] update Fail'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: AdvocacyOutputApiModel[]) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public advocacyId: number, public payload: Partial<UpdateAdvocacyInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;

    constructor(public companyId: number, public advocacyId: number) { }
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;
}

export class DeleteFailure implements Action {
    readonly type = ActionTypes.DELETE_FAIL;

    constructor(public error: any) { }
}

export class Add implements Action {
    readonly type = ActionTypes.ADD;

    constructor(public companyId: number, public payload: Partial<UpdateAdvocacyInputApiModel>) { }
}

export class AddSuccess implements Action {
    readonly type = ActionTypes.ADD_SUCCESS;
}

export class AddFailure implements Action {
    readonly type = ActionTypes.ADD_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    Update |
    UpdateSuccess |
    UpdateFailure |
    Delete |
    DeleteSuccess |
    DeleteFailure |
    Add |
    AddSuccess |
    AddFailure
    ;