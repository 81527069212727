<div class="modal-header">
  <h5 class="modal-title">Invite People to Share</h5>
  <button type="button" (click)="close()" class="close"><span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span></button>
</div>

<div class="modal-body" [formGroup]="emailConfigForm">
  <div class="row ml-1 mb-4">
   <span class="send__button">Send Message To:</span> 
    <div class="col-3 ml-3">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio"  formControlName="sendTo" value="1"
                    class="custom-control-input">
                <span class="custom-control-label">Author Only</span>
            </label>
        </div>
    </div>
    <div class="col-3">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio"  formControlName="sendTo" value="3"
                    class="custom-control-input">
                <span class="custom-control-label">Mentioned Only</span>
            </label>
        </div>
    </div>
    <div class="col-3 ml-3">
        <div class="custom-control custom-radio">
            <label class="mb-0">
                <input type="radio"  formControlName="sendTo" value="2"
                    class="custom-control-input">
                <span class="custom-control-label">Both</span>
            </label>
        </div>
    </div>
</div>
  <div class="row">
    <div class="col-12">
      <gs-input-material label="Subject" name="subject" placeholder="Subject" formControlName="subject" [formControl]="emailConfigForm.controls.subject" type="text"></gs-input-material>
      <gs-area-wysiwyg label="body" name="body" rows="10" placeholder="body" formControlName="body" [formControl]="emailConfigForm.controls.body" type="text"></gs-area-wysiwyg>
    </div>
    <div class="ml-3" style="display: flex;">
      <!-- <label class="switch mr-2">
        
        <input formControlName="enable_advocacy_link" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text text-gray">Add Advocacy</span> -->
      <!-- <div class="custom-control custom-checkbox link-btn" style="display: flex; text-align:center; align-items:center">
        <input id="includeStories" type="checkbox" formControlName="enable_advocacy_link"
            class="custom-control-input">
        <label for="includeStories" class="custom-control-label mr-1" style="font-weight: 900;">Include Sharing Links</label>

    </div> -->
    <div class="custom-control custom-checkbox cta_font_Control">
      <input id="includeTestimonials" type="checkbox" formControlName="enable_advocacy_link"
          class="custom-control-input">
      <label for="includeTestimonials" class="custom-control-label" style="font-weight: 900;">Include Sharing Links</label>
  </div>
    <a (click)="navigateToAdvocacy()" style="font-size: .75rem; border:none;position: relative;bottom: 3px;" class="btn btn-sm btn-outline-blue-light-extra text-blue-light-extra admin___btn">Edit Options</a>
    </div>

    <!-- <div class="custom-control custom-checkbox cta_font_Control">
      <input id="includeTestimonials" type="checkbox" formControlName="enable_advocacy_link"
          class="custom-control-input">
      <label for="includeTestimonials" class="custom-control-label">Apply to this story</label>
  </div> -->

    <div class="ml-3 mt-1" *ngIf="emailConfigForm.value.enable_advocacy_link">
      <label class="switch mr-2">
        <input formControlName="internal_advocacy" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text text-gray">Internal</span>
    </div>
    <div class="ml-3 mt-1" *ngIf="emailConfigForm.value.enable_advocacy_link">
      <label class="switch mr-2">
        
        <input formControlName="public_advocacy" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text text-gray">Public</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button (click)="close()" type="button" data-dismiss="modal"
  class="btn btn-outline btn-gray-light min-width">Don't Send</button>
  <button (click)="save()" type="button" class="btn btn-pink min-width"> Send Now
  </button>
</div>