<div class="modal-header my-0" style="height: 30px">
<!--<h5 class="modal-title">Invite Users</h5>-->
<button type="button" (click)="close(false)" class="close" style="cursor: pointer;">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body changemodalPadding"[formGroup]="addMemberForm">
    <div class="row mt-1 mb-2">
        <h5 class="invite-heding__link ml-3">Share Signup Page</h5>
        <div class="col-12"> 
        <gs-input-clipboard name="Link" formControlName="link" [disabled]="true" [formControl]="addMemberForm.controls.link">
        </gs-input-clipboard></div>
    </div>
    <!-- <hr class="mb-5"> -->


        <div class="headings_of_invite_content row col-md-12">
        <h5 class="invite-heding__link mt-2 mb-0">Send Email Invite</h5>
           <!-- <div class="col">Name (optional) </div>
            <div class="col">Email address</div>-->
           </div>
               <div class="form-add-people__wrap noPadding" formArrayName="members" style="height: auto" [ngClass]="{'adjust_scroll':playersFormArray?.controls?.length > 4}">
                   <div *ngFor="let item of playersFormArray?.controls; index as i; first as isFirst; last as isLast"
                       [formGroupName]="i" class="row align-items-center mb-0">
                       <div class="col-md-11 pr-md-2">
       
                          <div class="form-row mr-0" style="height:50px">
                               <div class="fullname__text form-group col mb-0">
                                   <gs-input-material hideErrorMessage="true" autoFocus="true"
                                       [tabindex]="i*5 + 3" [customId]="i" label="Name" name="Full Name"
                                       placeholder="optional" formControlName="fullname"
                                       [formControl]="item?.controls?.fullname" type="text">
                                   </gs-input-material>
                               </div>


                               <div class="email__text form-group col mb-0">
                                   <gs-input-material   type="email"
                                       [tabindex]="i*5 + 5" [customId]="i" label="Email" name="Email"
                                       placeholder="Email" formControlName="email"
                                       [formControl]="item?.controls?.email" type="text">
                                   </gs-input-material>
                               </div>
                           </div>
       
                       </div>
                       <div class="col-md-1 pl-md-0">
                           <div class="form-row">
                               <div class="form-group col-md-12 text-center">
                                   <button type="button" [tabindex]="i*5 + 7"
                                       [hidden]="playersFormArray?.controls?.length <= 1"
                                       (click)="removeAddPlayerFormItem($event, i)" class="btn-icon text-grey">
                                       <svg role="img">
                                           <use xlink:href="#icon-trash"></use>
                                       </svg>
                                   </button>
                               </div>
                           </div>
                       </div>
                       <div class="col-md-12 text-left"
                           [hidden]="!isLast || playersFormArray?.controls?.length >= 10">
                           <button type="button" (click)="addPlayerFormItem()"
                               class="btn-icon text-gray-dark text-hover-gray">
                               <svg role="img" class="mr-2">
                                   <use xlink:href="#icon-plus-circle"></use>
                               </svg>
                               Add Another
                           </button>
                       </div>
                   </div>
               </div>
               <br>
           <div class="mesage_subject__container">
            <gs-input-material label="Subject" name="subject" placeholder="Subject" formControlName="subject" [formControl]="addMemberForm.controls.subject" type="text"></gs-input-material>
           </div>
           <div class="message__body" style="height: 274px;">
            <ng-container *ngIf="showEditor">
                <gs-area-wysiwyg label="body" name="body" rows="10" placeholder="body" formControlName="body" [formControl]="addMemberForm.controls.body" type="text"></gs-area-wysiwyg>
            </ng-container>
           </div>

           


</div>
<div class="modal-footer">
    <div class="form-group text-right">
        <a class="btn btn-outline btn-gray-light min-width" (click)="close(false)" style="cursor: pointer;">Cancel</a>
        <button class="btn btn-pink min-width" type="submit"[disabled]="addMemberForm.invalid" (click)="invitePerson()">Invite</button>
    </div>
</div>
