import { SlackIntegrationOutputApiModel } from "../models/api-models/output";

export interface SlackIntegrationSettingEntity {
    id: number;
    config: SlackIntegrationConfigEntity;
}
export interface SlackIntegrationConfigEntity {
    access_token: string;
    channel_id: string;
}

export class SlackIntegrationSettingEntity {
    constructor(data?: SlackIntegrationOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: SlackIntegrationOutputApiModel) {
        this.id = data.id;
        this.config = {
            access_token: data.config.access_token,
            channel_id: data.config.channel_id
        }
    }

}