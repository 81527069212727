import { UserEmailState } from '@modules/user/store/states';
import { userEmailActions } from '@modules/user/store/actions';
import { initialState, userEmailAdapter } from '../adapters/user-email.adapter';
import { UserEmailEntity } from '@modules/user/store/entities';

export function userEmailReducer(state = initialState, action: userEmailActions.Actions): UserEmailState {
  switch (action.type) {
    case userEmailActions.ActionTypes.LOAD: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case userEmailActions.ActionTypes.LOAD_SUCCESS: {
      const entities = action.response.map(item => new UserEmailEntity(item));
      const newState = userEmailAdapter.setAll(entities, state);
      return {
        ...newState,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.REMOVE: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case userEmailActions.ActionTypes.REMOVE_SUCCESS: {
      let newState = state;
      if (action.idEmail) {
        newState = userEmailAdapter.removeOne(action.idEmail, state);
      }
      return {
        ...newState,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.REMOVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.RESEND: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case userEmailActions.ActionTypes.RESEND_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.RESEND_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.ADD_EMAIL: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case userEmailActions.ActionTypes.ADD_EMAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.ADD_EMAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.MAKE_PRIMARY_EMAIL: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case userEmailActions.ActionTypes.MAKE_PRIMARY_EMAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    case userEmailActions.ActionTypes.MAKE_PRIMARY_EMAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true
      };
    }

    default:
      return {
        ...state
      };
  }
}
