import { Injectable } from '@angular/core';
import { FileHelper } from './file.helper';
import { FormHelper } from './form.helper';
import { GuidHelper } from './guid.helper';
import { JqueryHelper } from './jquery.helper';
import * as _ from 'lodash';
import { LoDashStatic } from 'lodash';
import { MomentHelper } from './moment.helper';
import { NumberHelper } from './number.helper';
import { StorageProvider } from './storage/providers';
import { TokenHelper } from './token.helper';
import { MimeTypesHelper } from './mime-types.helper';
import { StringHelper } from './string.helper';
import { EncodeHelper } from './encode.helper';


@Injectable()
export class UtilityHelper {
	public Lodash: LoDashStatic = _;

	constructor(public File: FileHelper,
		public Form: FormHelper,
		public Guid: GuidHelper,
		public Jquery: JqueryHelper,
		public Moment: MomentHelper,
		public Number: NumberHelper,
		public Storage: StorageProvider,
		public Token: TokenHelper,
		public Mime: MimeTypesHelper,
		public StringHelper: StringHelper,
		public EncodeHelper: EncodeHelper) {

	}
}