<div class="profile-account">
  <div class="profile-account__avatar avatar">
    <gs-skeleton-loader width="120" height="120" [appearance]="'circle'"></gs-skeleton-loader>
  </div>
  <div class="profile-account__name"><gs-skeleton-loader width="110" height="25"></gs-skeleton-loader></div>
  <div class="profile-account__position"><gs-skeleton-loader width="90" height="25"></gs-skeleton-loader></div>
  <div class="profile-account__link">
    <gs-skeleton-loader width="22" height="22" [appearance]="'circle'"></gs-skeleton-loader>
    <gs-skeleton-loader width="22" height="22" [appearance]="'circle'"></gs-skeleton-loader>
    <gs-skeleton-loader width="22" height="22" [appearance]="'circle'"></gs-skeleton-loader>
  </div>
  <div class="profile-account__desc"><gs-skeleton-loader height="60"></gs-skeleton-loader></div>
</div>