import { Action } from '@ngrx/store';

import { SearchCompanyOutputApiModel, WorkAttributeOutputApiModel } from '../../models/api-models/output';

export enum ActionTypes {
    LOAD_WORK_ATTRIBUTES = "[Company Setting] Load Work Attributes",
    LOAD_WORK_ATTRIBUTES_SUCCESS = "[Company Setting] Load Work Attributes Success",
    LOAD_WORK_ATTRIBUTES_FAILURE = "[Company Setting] SeaLoad Work Attributesrch Failure",

    LOAD_DETAIL_PAGE = '[Company Campaign] Load Company Campaign',
    LOAD_DETAIL_PAGE_SUCCESS = '[Company Campaign] Load Company Campaign',
    LOAD_DETAIL_PAGE_FAIL = '[Company Campaign] Load Company Campaign'
}


export class LoadWorkAttributes implements Action {
    readonly type = ActionTypes.LOAD_WORK_ATTRIBUTES;

    constructor(public companyIdSlug: number | string) { }
}

export class LoadWorkAttributesSuccess implements Action {
    readonly type = ActionTypes.LOAD_WORK_ATTRIBUTES_SUCCESS;

    constructor(public response: WorkAttributeOutputApiModel[]) { }
}

export class LoadWorkAttributesFailure implements Action {
    readonly type = ActionTypes.LOAD_WORK_ATTRIBUTES_FAILURE;

    constructor(public error: any) { }
}

export class LoadCompanyCampaign implements Action {
    readonly type = ActionTypes.LOAD_DETAIL_PAGE;

    constructor(public companyIdSlug: number | string ,public campaignId:any) { }
}


export class LoadCompanyCampaignSucess implements Action {
    readonly type = ActionTypes.LOAD_DETAIL_PAGE_SUCCESS;

    constructor(public response:any) { }
}

export class LoadCompanyCampaignFail implements Action {
    readonly type = ActionTypes.LOAD_DETAIL_PAGE_FAIL;

}

export type Actions =
    LoadWorkAttributes | LoadWorkAttributesSuccess | LoadWorkAttributesFailure | LoadCompanyCampaign | 
    LoadCompanyCampaignSucess | LoadCompanyCampaignFail;
