export interface GridViewPaginationModel {
    pageIndex?: number;
    pageSize?: number;
    totalCount?: number;
}

export class GridViewPaginationModel {
    constructor() {
        this.pageIndex = 1;
        this.pageSize = 25;
        this.totalCount = 0;
    }
    get totalPageCount(): number {
        return this.totalCount % this.pageSize > 0 ? Math.floor(this.totalCount / this.pageSize) + 1 : this.totalCount / this.pageSize;
    }
}