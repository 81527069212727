import { Directive, EventEmitter, Output, ElementRef, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
    selector: '[contentEditAbleEvent]',
})
export class ContentEditAbleEventDirective implements OnDestroy {

    @Output() onBlur = new EventEmitter();

    private fireBlur$ = new Subject<void>();
    private fireBlurSubscription: Subscription;

    constructor(private el: ElementRef) {
        this.subscription();
        el.nativeElement.addEventListener('input', () => {
            // Fire event
            this.fireBlur$.next();
        });
    }

    private subscription() {
        this.fireBlurSubscription = this.fireBlur$.pipe(
            debounceTime(300)
        ).subscribe(() => {
            this.onBlur.emit();
        });
    }

    private unScription() {
        if (this.fireBlurSubscription)
            this.fireBlurSubscription.unsubscribe();
    }

    public ngOnDestroy(): void {
        this.unScription();
    }

}