import { initialState, commentAdapter } from '../adapters/comment.adapter';
import { CommentState } from '../states';
import { commentActions } from '../actions';
import { CommentEntity } from '../entities';
import { UpdateStore } from '@modules/shared/models/common';


export function commentReducer(state = initialState, action: commentActions.Actions): CommentState {
    switch (action.type) {

        case commentActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case commentActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new CommentEntity(item));
            let newSate;
            if (!action.response.prev) {
                newSate = commentAdapter.setAll(entities, state)
            } else {
                newSate = commentAdapter.addMany(entities, state)
            }
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev
            }
        }

        case commentActions.ActionTypes.LOAD_FAILURE: {
            return {
                ...state,
                isLoaded: true,
                isLoading: false
            }
        }

        case commentActions.ActionTypes.CREATE_SUCCESS: {
            const entity = new CommentEntity(action.response);
            return {
                ...commentAdapter.addOne(entity, state)
            }
        }

        case commentActions.ActionTypes.DELETE_SUCCESS: {
            return {
                ...commentAdapter.removeOne(action.id, state)
            }
        }

        case commentActions.ActionTypes.UPDATE_SUCCESS: {
            const updateStore = new UpdateStore<CommentEntity>(action.id, {content: action.payload.payload.content});
            return {
                ...commentAdapter.updateOne(updateStore, state)
            }
        }

        default: {
            return state;
        }
    }
}
