export interface UploadedFileInfoModel {
    id: number,
    name: string,
    type: string
    path: string;
    meta?: object;
}

export class UploadedFileInfoModel {
    constructor() {

    }
}