export interface CtaTemplateOutputApiModel {
    id: number,
    name: string,
    status: number,
    time_updated: Date,
    time_created: Date,
    button_text: string,
    company_id: number,
    config: any,
    content: string
}

export class CtaTemplateOutputApiModel {
    constructor(init?: Partial<CtaTemplateOutputApiModel>) {
        Object.assign(this, init);
    }
}