import { AuthService } from "@framework/services";

export function JwtOptionsFactory(authService: AuthService) {
    const gsToken = authService.getGsToken();
    return {
        tokenGetter: () => {
            return gsToken ? gsToken : authService.cognito.getIdToken();
        },
        authScheme: gsToken ? 'GSeeker ' : 'Bearer ',
        whitelistedDomains: [],
        blacklistedRoutes: ['/index/activity-feed', /\/app\/[^\/]+\/settings\//i, /\/companies\/[^\/]+\/setting\/media\//i]
    };
}