import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { storyReducers } from './reducers';
import * as effects from './effects';
import * as adminEffects from '../../company-admin/store/effects';
import { StoryHttpService, CompanyHttpService, UserHttpService } from '../services';
import * as adminService from '@modules/company-admin/store/services';
import { TrackingHttpService } from '../services/tracking.http-service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('storyFeature', storyReducers),
    EffectsModule.forFeature([
      effects.StoryEffect,
      effects.StoryStepEffect,
      effects.StoryCtaTemplateEffect,
      effects.StorySettingEffect,
      effects.ContributionEffect,
      adminEffects.StoryEffects,
      adminEffects.TestimonialEffects,
    ])
  ],
  providers: [
    CompanyHttpService,
    StoryHttpService,
    TrackingHttpService,
    UserHttpService,
    adminService.StoryHttpService,
    adminService.TestimonialHttpService,
  ]
})

export class StoryStoreModule { }
