import { companyReducer } from './company.reducer';
import { campaignReducer } from './campaign.reducer';
import { companySettingReducer } from './company-setting.reducer';
import { searchBoxReducer } from './seach-box.reducer';
import { landingPageReducer } from './landing-page.reducer';

export const companyReducers = {
    company: companyReducer,
    campaign: campaignReducer,
    companySetting: companySettingReducer,
    searchBox: searchBoxReducer,
    landingPage: landingPageReducer
};
