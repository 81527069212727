import { MemoizedSelector, createSelector } from "@ngrx/store";
import { NotificationState, SharedFeatureState } from "../states";
import { notificationAdapter } from "../adapters";
import { selectSharedFeature } from "./shared.selector";
import { NotificationViewModel } from "../models";
import { NotificationEntity } from "../entities";
import { Dictionary } from '@ngrx/entity';


export const selectNotificationState: MemoizedSelector<object, NotificationState> = createSelector(selectSharedFeature, (state: SharedFeatureState) => state.notification);

const {
    selectIds: selectNotificationIds,
    selectEntities: selectNotificationEntities,
    selectAll: selectNotificationAll,
    selectTotal: selectNotificationTotal
} = notificationAdapter.getSelectors(selectNotificationState);


// -------------[Mapping Functions]------------- 
const getLoading = (state: NotificationState): boolean => state.loading;
const getLoaded = (state: NotificationState): boolean => state.loaded;
const getClearing = (state: NotificationState): boolean => state.clearing;
const getCleared = (state: NotificationState): boolean => state.cleared;
const getCount = (state: NotificationState): number => state.count;
const getCountUnread = (state: NotificationState): number => state.countUnread;
const getcurrentNotificationId = (state: NotificationState): number => state.selectedId;
const getCurrentNotification = (entities: Dictionary<NotificationEntity>, currentNotificationId: number): NotificationViewModel => {
    const currentNotification: NotificationViewModel = new NotificationViewModel(entities[currentNotificationId]);
    return currentNotification.id && currentNotification;
};
const getNotifications = (entities: Array<NotificationEntity>) => {
    return entities.map(entity => new NotificationViewModel(entity));
};

// -------------[Public Selectors]-------------
export const selectLoaded: MemoizedSelector<object, boolean> = createSelector(selectNotificationState, getLoaded);
export const selectLoading: MemoizedSelector<object, boolean> = createSelector(selectNotificationState, getLoading);
export const selectCleared: MemoizedSelector<object, boolean> = createSelector(selectNotificationState, getCleared);
export const selectClearing: MemoizedSelector<object, boolean> = createSelector(selectNotificationState, getClearing);
export const selectCount: MemoizedSelector<object, number> = createSelector(selectNotificationState, getCount);
export const selectCountUnread: MemoizedSelector<object, number> = createSelector(selectNotificationState, getCountUnread);
export const selectcurrentNotificationId: MemoizedSelector<object, number> = createSelector(selectNotificationState, getcurrentNotificationId);
export const selectCurrentNotification: MemoizedSelector<object, NotificationViewModel> = createSelector(selectNotificationEntities, selectcurrentNotificationId, getCurrentNotification);
export const selectNotifications: MemoizedSelector<object, Array<NotificationViewModel>> = createSelector(selectNotificationAll, getNotifications);
