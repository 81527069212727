import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {campaignActions, landingPageActions} from '../actions';
import { CompanyHttpService, CampaignHttpService } from '../../services';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class CampaignEffects {


    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<campaignActions.Load>(campaignActions.ActionTypes.LOAD),
        switchMap((action: campaignActions.Load) => {
            return this.companyHttpService.loadCampaign(action.companyId, action.payload).pipe(
                map((response) => {
                    return new campaignActions.LoadSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new campaignActions.LoadFail(error));
                }));
        })
    ));


    updateColumeffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<campaignActions.UpdateColumn>(campaignActions.ActionTypes.UPDATE_COLUM),
        switchMap((action: campaignActions.UpdateColumn) => {
            return this.companyHttpService.updateColum(action.companyId, action.payload).pipe(
                map((response) => {
                    return new campaignActions.UpdateColumnSuccess();
                }),
                catchError((error: any) => {
                    return of(new campaignActions.UpdateColumnFail());
                }));
        })
    ));
    

    loadDetailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<campaignActions.LoadDetail>(campaignActions.ActionTypes.LOAD_DETAIL),
        mergeMap((action: campaignActions.LoadDetail) => {
            return this.companyHttpService.loadDetailCampaign(action.companyId, action.idOrSlug).pipe(
                map((response) => {
                    return new campaignActions.LoadDetailSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new campaignActions.LoadDetailFail(error));
                }));
        })
    ));


    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<campaignActions.Update>(campaignActions.ActionTypes.UPDATE),
        switchMap((action: campaignActions.Update) => {
            return this.companyHttpService.updateCampaign(action.companyId, action.id, action.payload).pipe(
                map((response) => {
                    return new campaignActions.UpdateSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new campaignActions.UpdateFail(error));
                }));
        })
    ));


    createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<campaignActions.Create>(campaignActions.ActionTypes.CREATE),
        switchMap((action: campaignActions.Create) => {
            return this.companyHttpService.createCampaign(action.companyId, action.payload).pipe(
                map((response) => {
                    return new campaignActions.CreateSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new campaignActions.CreateFail(error));
                }));
        })
    ));



    sendInviteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<campaignActions.SendInvite>(campaignActions.ActionTypes.SEND_INVITE),
        switchMap((action: campaignActions.SendInvite) => {
            return this.campaignHttpService.sendInvite(action.campaignId, action.payload).pipe(
                map(() => {
                    return new campaignActions.SendInviteSuccess();
                }),
                catchError((error: any) => {
                    return of(new campaignActions.SendInviteFail(error));
                }));
        })
    ));




    constructor(
        private actions$: Actions,
        private companyHttpService: CompanyHttpService,
        private campaignHttpService: CampaignHttpService
    ) { }
}
