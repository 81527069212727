<div class="b-feature">
  <div class="m-tools d-none d-sm-flex" [hidden]="searchBoxStatus.isShow">
    <button class="btn btn-trans text-gray" (click)="showSearchBox()">
      <svg role="img">
        <use xlink:href="#icon-search"></use>
      </svg>
    </button>
    <button data-view class="btn btn-trans-line text-gray ml-3" (click)="changeSortType()">
      <svg role="img" *ngIf="queryStoriesData.pagination.sort == 'asc'">
        <use xlink:href="#icon-filter-dn"></use>
      </svg>
      <svg role="img" *ngIf="queryStoriesData.pagination.sort == 'desc'">
        <use xlink:href="#icon-filter"></use>
      </svg>
    </button>

    <!-- <button data-view class="btn btn-trans-line text-gray ml-3 d-none d-md-block" (click)="changeViewType()">
      <svg role="img" *ngIf="!isViewList">
        <use xlink:href="#icon-grid"></use>
      </svg>
      <svg role="img" *ngIf="isViewList">
        <use xlink:href="#icon-list"></use>
      </svg>
    </button> -->
  </div>
  <div class="container">

    <!-- Start Story-->
    <div class="m-story" [ngClass]="{'m-story--list': isViewList}">
      <div class="m-story__content">
        <skeleton-story-list class="row grid" *ngIf="(!stories || stories.length === 0) && loadStoryStatus.isLoading"
          [countItem]="8" [cssClassItem]="'col-12 col-sm-6 col-md-4 col-xl-3 px-2 grid-item'"></skeleton-story-list>

        <div gs-masonry [options]="{itemSelector:'.grid-item'}" [totalItem]="stories.length" [useImagesLoaded]="true"
          class="row grid">
          <co-pb-cp-story-item [userLables]="userLables" [GuestLables]="GuestLables" gsMasonryItem class="col-12 col-sm-6 col-md-4 col-xl-3 px-2 grid-item" [story]="story"
            [href]="'/c/' + story.companyIdSlug + '/' + story.slug" *ngFor="let story of stories; trackBy: trackByFunc"
            [stepsData]="stepsData">
          </co-pb-cp-story-item>

        </div>

        <div *ngIf="campaignPage?.isInboundStoriesEnabled && (stories.length === 0 && !loadStoryStatus.isLoading)"
          class="row mb-5 text-center text-lg-left">
          <!--<div class="d-none d-lg-block col-12 mb-3 mb-lg-0 offset-lg-1 col-lg-5 pr-lg-7">
            <img src="/assets/front-end-minify/img/catch-a-story.png" />
          </div>-->
          <div class="col-12 col-lg-5">
            <h3 class="mt-lg-6 mb-3">{{ isFinishedBoolean ? 'Share your story.' : 'No Stories'}}</h3>
              <ng-container *ngIf="campaignPage && !campaignPage.interview_style && isFinishedBoolean ">
                <a [routerLink]="['/t/' + campaignPage.companySlug + '/testimonial/create']"
                [queryParams]="{cs: campaignPage.slug}" [style.background]="btnsubmit" [style.color]="txtsubmit" class="btn btn-pink">Get
                Started</a>
              </ng-container>
    
              <ng-container *ngIf="campaignPage && campaignPage.interview_style &&  isFinishedBoolean ">
                     <a (click)="navgateToInterviewStyle()" [style.background]="btnsubmit" [style.color]="txtsubmit" class="btn btn-pink">Get
                     Started</a>
              </ng-container>
          </div>
        </div>

        <div
          *ngIf="!campaignPage?.isInboundStoriesEnabled && (stories.length === 0 && loadStoryStatus.isLoaded && !loadStoryStatus.isLoading)"
          class="row mb-5 text-center text-lg-left">
          <!--<div class="d-none d-lg-block col-12 mb-3 mb-lg-0 offset-lg-1 col-lg-5 pr-lg-7">
            <img src="/assets/front-end-minify/img/catch-a-story.png" />
          </div>-->
          <div class="col-12 col-lg-5">
            <h3 class="mt-lg-6 mb-3">We are on a story seeking mission.</h3>
            <p>Check back soon!</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Close Story-->
  </div>
</div>

<!-- <div class="fixed-storyteller-btn">
  <button class="btn btn-create btn-sq btn-hdr text-small btn-mint btn-outline" (click)="share()">
    <span>Invite Authors</span>
  </button>
</div> -->

<app-infinity-scroll (infinite)="loadMoreStories()" [showIndicator]="showIndicator" [disabled]="disabled">
</app-infinity-scroll>