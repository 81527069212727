export interface TagPayload {
    cid: number,
    inbound: boolean
}

export interface CreateTagInputApiModel {
    resource_type: string,
    resource_id: number,
    payload: TagPayload,
    name: string
}

export class CreateTagInputApiModel {
    constructor() {
      
    }
}