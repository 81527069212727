import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseHttpService } from '@framework/services';
import { map } from 'rxjs/operators';
import { NotificationOutputApiModel } from './models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { LoadNotificationInputApiModel, UpdateNotificationInputApiModel } from './models/input';
import { Observable, of } from 'rxjs';

@Injectable()
export class NotificationService extends BaseHttpService {

    private endPoints = {
        users: 'users',
    };

    constructor(protected http: HttpClient) {
        super(http, '/');
    }

    public getAll(payload: LoadNotificationInputApiModel) {
        const apiUrl = `${this.endPoints.users}/${payload.userId}/notifications/?${payload.queryBuilder}`;
        return this.get(apiUrl).pipe(
            map((response: ResponsePaginationApiModel<NotificationOutputApiModel>) => {
                return response;
            })
        );
    }

    public clearAll(userId: number) {
        const apiUrl = `${this.endPoints.users}/${userId}/notifications/`;
        return this.delete(apiUrl).pipe(map(() => true));
    }

    public update(id: number, data: UpdateNotificationInputApiModel) {
        // const apiUrl = `${this.endPoints.users}/notifications/${id}`;
        // return this.patch(apiUrl, data).pipe(map(() => true));
        return of(true);
    }

    public makeAsRead(id: number, read: number = 1) {
        const data = {};
        const apiUrl = `${this.endPoints.users}/notifications/${id}/read/`;
        return this.post(apiUrl, data).pipe(map(() => true));
    }
}
