
<div class="" *ngIf="showDonor" (click)="closeDonor()"  [ngClass]="{'cross_btn':showDonor}">
  <i class="fa-solid fa-x"></i>
</div>
<div class="" [style.background]="backgroundColor ? backgroundColor : ''"  [ngClass]="{'background':showDonor}">
</div>
 <div class="loader">  <i class="fas fa-spinner fa-pulse"></i></div>
<div id="donation_form"  [ngClass]="{'donor__iframe_container':showDonor && !isSmallScreen , 'donor__iframe_container__mobile': isSmallScreen && showDonor}">
</div>

<div id="downloadfile"></div>



