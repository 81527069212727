import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { TestimonialEntity } from '../entities';
import { TestimonialState } from '../states';

export const testimonialAdapter: EntityAdapter<TestimonialEntity> = createEntityAdapter<TestimonialEntity>();

export const initialState: TestimonialState = testimonialAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});