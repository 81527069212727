import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AppVersionInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map(event => {
            if (event instanceof HttpResponse) {
                const appVersion = event.headers.get('app-version');
                if (appVersion) {
                    const preAppVersion = localStorage.getItem('appVersion');
                    if (!preAppVersion) {
                        localStorage.setItem('appVersion', appVersion);
                    } else {
                        if (preAppVersion !== appVersion) {
                            localStorage.setItem('appVersion', appVersion);
                            window.location.reload();
                        }
                    }
                }
            }
            return event;
        }));
    }
}
