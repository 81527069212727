import { EmailsSettingOutputApiModel } from "../models/api-models/output";

export interface EmailsSettingEntity {
    senderEmail: string;
    senderName: string;
    isSSL: boolean;
    smtpUsername: string;
    smtpPassword: string;
    smtpServer: string;
    smtpPort: number;
}

export class EmailsSettingEntity {
    constructor(data: EmailsSettingOutputApiModel) {
        this.senderName = data.from_name;
        this.senderEmail = data.from_email;
        this.isSSL = data.is_ssl;
        this.smtpUsername = data.smtp_username;
        this.smtpPassword = data.smtp_password;
        this.smtpServer = data.smtp_address;
        this.smtpPort = data.smtp_port;
    }
}