export interface UserIdApiModel {
  id_user: number;
}

export interface ResultApiModel {
  user_details: UserIdApiModel;
}

export interface AddUserOutputApiModel {
  result: ResultApiModel;
}

export class AddUserOutputApiModel {
  constructor(data?: Partial<AddUserOutputApiModel>) {
    if (data) {
        Object.assign(this, data);
    }
  }
}


