export interface LoadUserOutputApiModel {
    id: number | string;
    first_name: string;
    last_name: string;
    display_name: string;
    title: string;
    email: string;
    avatar: string;
    story_invite_enabled: boolean;
}

export class LoadUserOutputApiModel {
    constructor(init?: Partial<LoadUserOutputApiModel>) {
        Object.assign(this, init);
    }
}