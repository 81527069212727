<div class="modal-header">
  <h5 class="modal-title">Spotlight Page Header</h5>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="landingPageForm">
  <div class="row spotlightheader">
    <div class="col-lg-12">
      <gs-input-material label="Page Title" name="Title" placeholder="Page Title" formControlName="title"
        [formControl]="landingPageForm.controls.title" type="text">
      </gs-input-material>
    </div>
    <div class="col-lg-12 mb-4">
      <div class="ld-header">
        <div class="ld-header__inner" [background-image]="landingPageForm.controls.headerImage.value">
          <span class="canva-btn canva-btn-theme-light canva-btn-size-l" (click)="designCanva()">
            <span class="canva-btn-i"></span>
          </span>
          <button class="btn btn-white btn-sm">
            <span #uploadHeaderImage>
              Upload Image
            </span>
          </button>
        </div>
        <div style="display: flex;"><div><p class="text-small text-gray-light mt-2">Png or jpg file, ideal size 2600 x 600px.</p></div> 

        <small class="" style="color: #f40b5f ; position: relative; bottom: 10px;" *ngIf="uploaderHeaderImageViewModel.isError">{{uploaderHeaderImageViewModel.errorMsg}}</small>
        <div class="mt-2" style="margin: 0 0 0 auto">
          <label class="switch mr-2 mb-2">           
            <input formControlName="enable_overlay" type="checkbox"><span class="slider"></span>
          </label>
          <span class="text text-gray">Add Dark Overlay</span>
        </div>

      </div>
    </div>
   
  </div>
  <div class="px-4 mb-3 row">
    <div class="px-2 col-lg-4">
      <div class="personal-settings-photo mb-2">
        <div class="personal-settings-photo__thumbnail">
          <div class="setting-photo-upload setting-photo-upload--small">
            <div [hidden]="uploaderViewModel.isUploading" class="setting-photo-upload__image">
              <img [src]="(landingPageForm.controls.avatarImage.value || 'assets/front-end-minify/img/avatar-default.png') | safe: 'resourceUrl'" alt="">
            </div>
            <div [hidden]="!uploaderViewModel.isUploading" class="setting-photo-upload__counter">
              {{uploaderViewModel.progressCss}}</div>
            <div [hidden]="!uploaderViewModel.isUploading" [style.height]="uploaderViewModel.progressCss"
              class="setting-photo-upload__progress"></div>
          </div>
        </div>
        <div class="personal-settings-photo__content">
          <div class="btn-upload-photo">
            <label #uploadAvatar>Upload Photo</label>
          </div>
        </div>
      </div>
      <div style="display: flex; position: absolute"><div><p class="text-small text-gray-light mt-2">Png or jpg file, minimum 150px.</p>
      </div> <ng-container *ngIf="landingPageForm.controls.avatarImage.value">
        <div (click)="deletelogoavatar()" class="adjust__delete_icon__avatar"><i class="fa-regular fa-trash-can-xmark"></i></div>
      </ng-container> </div>
    </div>
    <div class="px-2 col-lg-8">
      <div class="row">
        <div class="col-12">
          <gs-input-material label="Overlay heading" name="Quote" placeholder="Quote" formControlName="slogan"
            [formControl]="landingPageForm.controls.slogan" type="text">
          </gs-input-material>
        </div>
        <div class="col-12">
          <gs-input-material label="Sub heading" name="Person" placeholder="Said By" formControlName="person"
            [formControl]="landingPageForm.controls.person" type="text">
          </gs-input-material>
        </div>
        <div class="col-12 mb-2">
        </div>
      </div>
    </div>
  </div>
  <ng-container formArrayName="buttons" *ngFor="let item of buttonsFormArray.controls; index as i">
    <ng-container [formGroupName]="i">
      <div class="row px-4">
        <div class="px-2 col-1 mt-1">
          Display
          <div class="custom-control custom-checkbox mb-2">
            <label class="mb-0">
              <input formControlName="isSelected" type="checkbox" class="custom-control-input"><span
                class="custom-control-label">{{i + 1}}</span>
            </label>
          </div>
        </div>
        <div class="px-2 col-11 col-lg-3">
          <gs-input-material [customId]="i" label="Button Label" name="Label"
            placeholder="Label" formControlName="label" [formControl]="item.controls.label"
            type="text">
          </gs-input-material>
        </div>
        <div class="px-2 offset-1 col-11 offset-lg-0 col-lg-4">
          <gs-input-material [customId]="i" label="https://" name="URL" placeholder="URL" formControlName="url"
            [formControl]="item.controls.url" type="text">
          </gs-input-material>
        </div>
        <div class="px-2 offset-1 col-5 offset-lg-0 col-lg-2">
          <gs-input-color-picker [customId]="i" label="Foreground" name="Foreground" placeholder="Foreground" formControlName="foreground" [formControl]="item.controls.foreground" type="text"></gs-input-color-picker>
        </div>
        <div class="px-2 col-5 col-lg-2">
          <gs-input-color-picker [customId]="i" label="Background" name="Background" placeholder="Background" formControlName="background" [formControl]="item.controls.background" type="text"></gs-input-color-picker>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="modal-footer" [formGroup]="landingPageForm">
  <div class="">
    <label class="switch mr-2 ml-2">           
      <input formControlName="show_submit_story_btn" type="checkbox"><span class="slider"></span>
    </label>
    <span class="text text-gray">Accept guest story submissions</span>
  </div>
  <div>
    <button (click)="close()" type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width">Cancel</button>
  <button (click)="save()" type="button" class="btn btn-pink min-width">
    <i *ngIf="isSaving" class="fas fa-spinner fa-pulse"></i> Save
  </button>
  </div>

</div>