import { LoadUserOutputApiModel } from "@modules/user/models/output";

export interface UserEntity {
    id: number;
    firstname: string;
    lastname: string;
    title: string;
    email: string;
    avatar: string;
    displayName: string;
    storyInviteEnabled:boolean;
}

export class UserEntity {
    constructor(init: LoadUserOutputApiModel) {
        Object.assign(this, {
            id: init.id,
            firstname: init.first_name,
            lastname: init.last_name,
            title: init.title,
            email: init.email,
            avatar: init.avatar,
            displayName: init.display_name,
            storyInviteEnabled: init.story_invite_enabled
        });
    }
}