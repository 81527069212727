import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { IntegrationSettingHttpService } from '../services';
import { integrationSettingActions } from '../actions';

@Injectable()
export class IntegrationSettingEffects {

    constructor(
        private actions$: Actions,
        private integrationSettingHttpService: IntegrationSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.Load>(integrationSettingActions.ActionTypes.LOAD),
        switchMap((action: integrationSettingActions.Load) => {
            return this.integrationSettingHttpService.loadSlackSetting(action.companyId).pipe(
                map((response) => {
                    return new integrationSettingActions.LoadSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.LoadFail(error));
                }));
        })
    ));


    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.Update>(integrationSettingActions.ActionTypes.UPDATE),
        switchMap((action: integrationSettingActions.Update) => {
            return this.integrationSettingHttpService.updateSlackSetting(action.companyId, action.integrationId, action.payload).pipe(
                map(() => {
                    return new integrationSettingActions.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.UpdateFailure(error));
                }))
        })
    ));

    
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.Delete>(integrationSettingActions.ActionTypes.DELETE),
        switchMap((action: integrationSettingActions.Delete) => {
            return this.integrationSettingHttpService.deleteSlackSetting(action.companyId, action.integrationId).pipe(
                map(() => {
                    return new integrationSettingActions.DeleteSuccess();
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.DeleteFailure(error));
                }))
        })
    ));
    
    loadYammerEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.LoadYammer>(integrationSettingActions.ActionTypes.LOAD_YAMMER),
        switchMap((action: integrationSettingActions.LoadYammer) => {
            return this.integrationSettingHttpService.loadYammerSetting(action.companyId).pipe(
                map((response) => {
                    return new integrationSettingActions.LoadYammerSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.LoadYammerFail(error));
                }));
        })
    ));


    
    updateYammerEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.UpdateYammer>(integrationSettingActions.ActionTypes.UPDATE_YAMMER),
        switchMap((action: integrationSettingActions.UpdateYammer) => {
            return this.integrationSettingHttpService.updateYammerSetting(action.companyId, action.payload).pipe(
                map(() => {
                    return new integrationSettingActions.UpdateYammerSuccess();
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.UpdateYammerFailure(error));
                }))
        })
    ));

    
    deleteYammerEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.DeleteYammer>(integrationSettingActions.ActionTypes.DELETE_YAMMER),
        switchMap((action: integrationSettingActions.DeleteYammer) => {
            return this.integrationSettingHttpService.deleteYammerSetting(action.companyId).pipe(
                map(() => {
                    return new integrationSettingActions.DeleteYammerSuccess();
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.DeleteYammerFailure(error));
                }))
        })
    ));

    
    addFirebaseConfigrEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<integrationSettingActions.AddFirebaseConfig>(integrationSettingActions.ActionTypes.ADD_FB_TOKEN),
        switchMap((action: integrationSettingActions.AddFirebaseConfig) => {
            return this.integrationSettingHttpService.addFirebaseToken(action.companyId,action.payload).pipe(
                map(() => {
                    return new integrationSettingActions.AddFirebaseConfigSuccess();
                }),
                catchError((error: any) => {
                    return of(new integrationSettingActions.AddFirebaseConfigFailure(error));
                }))
        })
    ));
}
