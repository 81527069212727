import { CtaTemplateSelectValueViewModel } from ".";

export interface SelectReplacementForCtaOutputViewModel {
    selectedItem: CtaTemplateSelectValueViewModel,
    oldItem: CtaTemplateSelectValueViewModel,
    stepId: number
}

export class SelectReplacementForCtaOutputViewModel {
    constructor() {

    }
}