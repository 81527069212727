<div class="modal-header">
  <h5 class="modal-title">Campaign Delete</h5>
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span></button>
</div>
<div class="modal-body" [formGroup]="campaignConfrimation">
  <div class="row">
    <div class="col">
      <div class="custom-control custom-radio">
        <label class="mb-0">
          <input type="radio" formControlName="selectCheckbox" value="deleteTemporary" class="custom-control-input">
          <span class="custom-control-label">Delete from the prompt list but keep in stories</span>
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="custom-control custom-radio">
        <label class="mb-0">
          <input type="radio" formControlName="selectCheckbox" value="deletePermanently" class="custom-control-input">
          <span class="custom-control-label">Delete Permanently</span>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="cancel()"
    class="btn btn-outline btn-gray-light min-width">Cancel</button>
  <button [style.background]="primary_color ? primary_color : ''" type="button" (click)="confirm()" class="btn btn-pink min-width">Continue</button>
</div>