import { Action } from '@ngrx/store';

import { LoadUserProfileOutputApiModel } from '@modules/user/models/output';
import { UpdateUserProfileInputApiModel } from '@modules/user/models/input';
import { ChangeStorySlugInputApiModel } from '@modules/story/models/api-models/input';

export enum ActionTypes {
    LOAD_CURRENT_PROFILE = '[User Profile] Load Current Profile',
    LOAD_CURRENT_PROFILE_SUCCESS = '[User Profile] Load Current Profile Success',
    LOAD_CURRENT_PROFILE_FAILURE = '[User Profile] Load Current Profile Failure',

    LOAD_PUBLIC_PROFILE = '[User Profile] Load Public Profile',
    LOAD_PUBLIC_PROFILE_SUCCESS = '[User Profile] Load Public Profile Success',
    LOAD_PUBLIC_PROFILE_FAILURE = '[User Profile] Load Public Profile Failure',

    UPDATE = '[User Profile] Update',
    UPDATE_SUCCESS = '[User Profile] Update Success',
    UPDATE_FAILURE = '[User Profile] Update Failure',

    CHANGE_SLUG = '[User Profile] Change Slug',
    CHANGE_SLUG_SUCCESS = '[User Profile] Change Slug Success',
    CHANGE_SLUG_FAILURE = '[User Profile] Change Slug Failure'
};


export class LoadCurrentProfile implements Action {
    readonly type = ActionTypes.LOAD_CURRENT_PROFILE;
}

export class LoadCurrentProfileSuccess implements Action {
    readonly type = ActionTypes.LOAD_CURRENT_PROFILE_SUCCESS;

    constructor(public response: LoadUserProfileOutputApiModel) { }
}

export class LoadCurrentProfileFailure implements Action {
    readonly type = ActionTypes.LOAD_CURRENT_PROFILE_FAILURE;

    constructor(public error: any) { }
}

export class LoadPublicProfile implements Action {
    readonly type = ActionTypes.LOAD_PUBLIC_PROFILE;
    constructor(public userIdSlug: string) { }
}

export class LoadPublicProfileSuccess implements Action {
    readonly type = ActionTypes.LOAD_PUBLIC_PROFILE_SUCCESS;

    constructor(public response: LoadUserProfileOutputApiModel) { }
}

export class LoadPublicProfileFailure implements Action {
    readonly type = ActionTypes.LOAD_PUBLIC_PROFILE_FAILURE;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public payload: Partial<UpdateUserProfileInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAILURE;

    constructor(public error: any) { }
}

export class ChangeSlug implements Action {
    readonly type = ActionTypes.CHANGE_SLUG;

    constructor(public payload: Partial<ChangeStorySlugInputApiModel>) { }
}

export class ChangeSlugSuccess implements Action {
    readonly type = ActionTypes.CHANGE_SLUG_SUCCESS;
}

export class ChangeSlugFailure implements Action {
    readonly type = ActionTypes.CHANGE_SLUG_FAILURE;

    constructor(public error: any) { }
}

export type Actions = LoadCurrentProfile
    | LoadCurrentProfileSuccess
    | LoadCurrentProfileFailure
    | LoadPublicProfile
    | LoadPublicProfileSuccess
    | LoadCurrentProfileFailure
    | Update
    | UpdateSuccess
    | UpdateFailure 
    | ChangeSlug
    | ChangeSlugSuccess
    | ChangeSlugFailure;
