

import { PaginationModel } from "@framework/models/pagination-models";

export interface GetLandingPageInputApiModel {
    search: string;
    createdAtFrom: Date;
    createdAtTo: Date;
    archived: boolean;
    is_board_visible?: boolean;
    public?:boolean;
}

export class GetLandingPageInputApiModel {
    constructor(public pagination: PaginationModel) {

    }

    private buildQuery() {
        let query = '';

        if (this.archived) {
            query += `archived=0&`;
        }
        if(!this.is_board_visible){
            query+='is_board_visible=0&'
        }

        if(this.is_board_visible){
            query+='is_board_visible=1&'
        }
        if(this.public){
            query+='public=1&'
        }
        
        query += this.pagination.query;

        return query;
    }

    public get query() {
        return this.buildQuery();
    }
}
