import { DirectoryOutputApiModel } from "@modules/user/models/output";

export interface DirectoryEntity {
  id: number,
  avatar: string,
  countAuthored: number,
  countInvolved: number,
  displayName: string,
  email: string,
  firstName: string,
  followId: number,
  lastName: string,
  slug: string
}

export class DirectoryEntity {
  constructor(data?: DirectoryOutputApiModel) {
    if (data) {
      this.convertFormApi(data);
    }
  }

  private convertFormApi(data: DirectoryOutputApiModel) {
    this.id = data.id;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.avatar = data.avatar;
    this.displayName = data.display_name;
    this.slug = data.slug;
    this.followId = data.follow_id;
    this.countAuthored = data.count_authored;
    this.countInvolved = data.count_involved;
  }
}
