import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { TestimonialAudienceSettingOutputApiModel } from '../models/api-models/output';
import { UpdateTestimonialAudienceSettingInputApiModel } from '../models/api-models/input';

@Injectable()
export class TestimonialAudienceSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/testimonials/audience/`).pipe(
            map((response: ResponsePaginationApiModel<TestimonialAudienceSettingOutputApiModel>) => {
                return response;
            })
        )
    }

    public update(companyId: number, audienceId: number, payload: Partial<UpdateTestimonialAudienceSettingInputApiModel>) {
        return this.patch(`/${companyId}/setting/testimonials/audience/${audienceId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public add(companyId: number, payload: Partial<UpdateTestimonialAudienceSettingInputApiModel>) {
        return this.post(`/${companyId}/setting/testimonials/audience/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteAdvocacy(companyId: number, audienceId: number) {
        return this.delete(`/${companyId}/setting/testimonials/audience/${audienceId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
