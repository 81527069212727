<div *ngIf="!contribution?.data" class="story-contribution">
  <div class="story-contribution__head">
    <div class="story-contribution__avatar">
      <gs-avatar isBackground="true" [src]="contribution?.avatar" classCss="avatar">
      </gs-avatar>
      <div class="name text-nowrap">
        <a *ngIf="contribution?.slug" [href]="'/p/' + contribution?.slug">{{contribution?.displayName}}</a>
        <span class="text-dark" *ngIf="!contribution?.slug">{{contribution?.displayName}}</span>
      </div>
    </div>
    <button *ngIf="isHasPermissionAddTake" (click)="addYourTake()"
      class="btn btn-rounded btn-mint story-contribution__button anim-pulse-cta">Comment Now
    </button>
  </div>
</div>

<article *ngIf="contribution?.data" class="story-contribution plr my-story">
  <div class="story-contribution__head">
    <div class="story-contribution__avatar">
      <gs-avatar isBackground="true" [src]="contribution?.avatar" classCss="avatar">
      </gs-avatar>
      <div class="name text-nowrap">
        <a *ngIf="contribution?.slug" [href]="'/p/' + contribution?.slug">{{contribution?.displayName}}</a>
        <span class="text-dark" *ngIf="!contribution?.slug">{{contribution?.displayName}}</span>
      </div>
    </div>
  </div>
  <div class="story-contribution__body">
    <div [innerHtml]="contribution.data.message">
    </div>
    <div [innerHtml]="tagsHtml() | safeHtml">

    </div>
  </div>
</article>