import { Component, SimpleChanges, ViewChild, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { filter, take } from 'rxjs/operators';

import { ComponentModalBase } from '@framework/base/component';
import { SaveLandingPageModalInitDataModel } from '@modules/company/models/commons';
import { UploaderService, ModalService } from '@framework/services';
import { UploaderConfig, Callback } from '@framework/models';
import { UserProfileViewModel } from '@modules/user/store/models';
import { CoreState } from '@app/store/core/states';
import { UtilityHelper } from '@framework/utilities';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { CreateMediaInputApiModel } from '@app/store/http/models/input';
import { CropImageInitData } from '@modules/shared/models';
import { ConfirmationModalComponent, ConfirmationModalDataModel, CropImageModalComponent } from '@modules/shared/components/commons';
import { CreateLandingPageInputApiModel, LandingPageButtonInputApiModel, UpdateLandingPageInputApiModel } from '@modules/company/models/api-models/input';
import { landingPageActions } from '@modules/company/store/actions';
import { Router } from '@angular/router';
import { LandingPageViewModel } from '@modules/company/models/store-view-models';
import { landingPageSelectors } from '@modules/company/store/selectors';
import { JobLinkacAction } from '@modules/company-admin/store/actions';
import { isPlatformBrowser } from '@angular/common';
declare global {
  interface Window {
    Canva;
    api;
  }
}
@Component({
  selector: 'co-save-landing-page-modal-container',
  templateUrl: './save-landing-page-modal-container.component.html',
  styleUrls: ['./save-landing-page-modal-container.component.scss']
})
export class SaveLandingPageModalContainerComponent extends ComponentModalBase<SaveLandingPageModalInitDataModel> {

  @ViewChild('uploadAvatar')
  uploadAvatarButton: ElementRef;
  @ViewChild('uploadHeaderImage')
  uploadHeaderImageButton: ElementRef;
  // Private variables
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentUserProfileTakeOneSubscription: Subscription;
  private createLandingPageSuccessSubscription: Subscription;
  private updateLandingPageSuccessSubscription: Subscription;

  private uploaderAvatar: any;
  private uploaderHeaderImage: any;
  private uploader: any;
  public isBrowser: boolean;
  private manageFileUploadQueues = {
    avatar: null,
    header: null
  };
  // Public variables
  public uploaderViewModel: any = {
    errorMsg: 'An error occurred while uploading.',
    isError: false,
    progress: 0,
    progressCss: '0%',
    isUploading: false
  };
  public uploaderHeaderImageViewModel: any = {
    errorMsg: 'An error occurred while uploading.',
    isError: false,
    progress: 0,
    progressCss: '0%',
    isUploading: false
  };
  public currentUserProfile: UserProfileViewModel;
  public landingPageForm: UntypedFormGroup;
  public isSaving: boolean = false;
  public Showfrombuilder: boolean = false
  private selectLandingPageDetail$: Observable<LandingPageViewModel>;
  private selectLandingPageDetailSubscription: Subscription;

  constructor(
    private store$: Store<CoreState>,
    private formBuilder: UntypedFormBuilder,
    private utility: UtilityHelper,
    private actions$: Actions,
    @Inject(PLATFORM_ID) platformId: Object,
    private uploaderService: UploaderService,
    private modalService: ModalService,
    private router: Router) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    this.initForm();
    this.selectLandingPageDetail$ = this.store$.select(landingPageSelectors.selectLandingPage);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.initForm();
    if (this.initialState && this.initialState.landingPage) {
      this.Showfrombuilder = true;
      this.landingPageForm.patchValue(this.initialState.landingPage);
      this.landingPageForm.controls.buttons.patchValue(this.initialState.landingPage.buttons);
    } else if (this.initialState['featured'] && this.currentUserProfile && this.initialState['featured'].slug) {
      this.Showfrombuilder = false;
      this.store$.dispatch(new landingPageActions.LoadDetail(this.currentUserProfile.company.id, this.initialState['featured'].slug));
    } else if (this.initialState['featured']) {
      this.Showfrombuilder = false;
      this.landingPageForm.controls.title.setValue(this.initialState['featured'].title);
    }


    if(this.initialState.landingPage['headerMedia']){
      this.landingPageForm.controls.headerImage.setValue('https://ik.imagekit.io/lpzxd7bmi/goodseeker'+this.initialState.landingPage['headerMedia'].path+'/'+this.initialState.landingPage['headerMedia'].filename)
    }
    if(this.initialState.landingPage['avatarMedia']){
      this.landingPageForm.controls.headerImage.setValue('https://ik.imagekit.io/lpzxd7bmi/goodseeker'+this.initialState.landingPage['avatarMedia'].path+'/'+this.initialState.landingPage['avatarMedia'].filename)
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCurrentUserProfileTakeOneSubscription = this.selectCurrentUserProfile$.pipe(
      filter((userProfile) => {
        return userProfile && userProfile.id > 0;
      }),
      take(1)
    ).subscribe((userProfile) => {
      this.currentUserProfile = this.utility.Lodash.cloneDeep(userProfile);
      setTimeout(() => {
        this.initUploader();
      }, 100);
    });

    this.selectLandingPageDetailSubscription = this.selectLandingPageDetail$.subscribe((landingPage) => {
      if (landingPage) {
        this.landingPageForm.patchValue(landingPage);
        this.landingPageForm.controls.enable_overlay.setValue(landingPage.enable_overlay ? true : false)
        this.landingPageForm.controls.buttons.patchValue(landingPage.buttons);
      }
    });

    this.createLandingPageSuccessSubscription = this.actions$.pipe(
      ofType<landingPageActions.CreateSuccess>(landingPageActions.ActionTypes.CREATE_SUCCESS)
    ).subscribe((action) => {
      this.isSaving = false;
      if(this.Showfrombuilder){
        this.store$.dispatch(new JobLinkacAction.LinkSpotlightJobs(action.response.id,this.initialState.landingPage.jobIds))
      }
      this.close();
      var routePath = action.response.is_board_visible ? `/company/featured/${action.response.slug}`: `/company/myspotlight/${action.response.slug}`;
      this.router.navigate([`${routePath}`]);
      // this.router.navigate([`/company/featured/${action.response.slug}`]);
    });

    this.updateLandingPageSuccessSubscription = this.actions$.pipe(
      ofType<landingPageActions.UpdateSuccess>(landingPageActions.ActionTypes.UPDATE_SUCCESS)
    ).subscribe((action) => {
      this.isSaving = false;
      this.store$.dispatch(new landingPageActions.LoadDetail(this.currentUserProfile.company.id, this.landingPageForm.controls.id.value))
      this.close();
      if(this.Showfrombuilder){
        var routePath = this.landingPageForm.controls.is_board_visible.value ? `/company/featured/${this.initialState.landingPage.slug}`: `/company/myspotlight/${this.initialState.landingPage.slug}`;
        this.router.navigate([`${routePath}`]);
      }

      // this.router.navigate([`/company/featured/${this.initialState.landingPage.slug}`]);
    });
  }

  private unsubscribe() {
    this.selectCurrentUserProfileTakeOneSubscription.unsubscribe();
    this.createLandingPageSuccessSubscription.unsubscribe();
    this.updateLandingPageSuccessSubscription.unsubscribe();
    this.selectLandingPageDetailSubscription.unsubscribe();
  }

  private initForm() {
    this.landingPageForm = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      person: [''],
      slogan: [''],
      headerImage: [''],
      avatarImage: [''],
      show_submit_story_btn: [true],
      headerMedia: [],
      is_board_visible:[true],
      is_footnote_visible:[true],
      enable_overlay:[false],
      cta:[],
      avatarMedia: [],
      buttons: this.formBuilder.array([
        this.formBuilder.group({
          label: [''],
          url: [''],
          foreground: ['#000000'],
          background: ['#ffffff'],
          isSelected: [false]
        }),
        this.formBuilder.group({
          label: [''],
          url: [''],
          foreground: ['#000000'],
          background: ['#ffffff'],
          isSelected: [false]
        }),
        this.formBuilder.group({
          label: [''],
          url: [''],
          foreground: ['#000000'],
          background: ['#ffffff'],
          isSelected: [false]
        })
      ])
    });
  }

  private initUploader() {
    // Init Avatar
    let uploadAvatarConfig = new UploaderConfig();
    uploadAvatarConfig.button = this.uploadAvatarButton.nativeElement;
    uploadAvatarConfig.companyId = this.currentUserProfile.company.id;
    uploadAvatarConfig.validate = {
      imageMinHeight: 120,
      imageMinWidth: 120,
      allowedExtensions: ['jpeg', 'jpg', 'png', 'gif']
    };
    uploadAvatarConfig.messages = {
      imageDimensionError: 'Use a larger image - 120 x 120px minimum.',
      typeError: 'Acceptable file formats are jpg, gif and png.'
    };

    let uploadAvatarCallbacks = new Callback();
    uploadAvatarCallbacks.onSubmit = this.onAvatarUploaderSubmit.bind(this);
    uploadAvatarCallbacks.onError = this.onAvatarUploaderError.bind(this);

    this.uploaderAvatar = this.uploaderService.s3Uploader(uploadAvatarConfig, uploadAvatarCallbacks);

    // Init header
    let uploadHeaderConfig = new UploaderConfig();
    uploadHeaderConfig.button = this.uploadHeaderImageButton.nativeElement;
    uploadHeaderConfig.companyId = this.currentUserProfile.company.id;
    uploadHeaderConfig.validate = {
      imageMinHeight: 600,
      imageMinWidth: 2600,
      allowedExtensions: ['jpeg', 'jpg', 'png', 'gif']
    };
    uploadHeaderConfig.messages = {
      imageDimensionError: 'Use a larger image - 2600 x 600px minimum.',
      typeError: 'Acceptable file formats are jpg, gif and png.'
    };

    let uploadHeaderCallbacks = new Callback();
    uploadHeaderCallbacks.onSubmit = this.onHeaderUploaderSubmit.bind(this);
    uploadHeaderCallbacks.onError = this.onHeaderUploaderError.bind(this);

    this.uploaderHeaderImage = this.uploaderService.s3Uploader(uploadHeaderConfig, uploadHeaderCallbacks);

    // Init uploader
    let uploadConfig = new UploaderConfig();
    uploadConfig.companyId = this.currentUserProfile.company.id;
    uploadConfig.isAnonymous = true;
    uploadConfig.autoUpload = false;
    uploadConfig.multiple = true;
    uploadConfig.validate = {
      imageMinHeight: 120,
      imageMinWidth: 120,
      allowedExtensions: ['jpeg', 'jpg', 'png', 'gif']
    };
    uploadConfig.messages = {
      imageDimensionError: 'Use a larger image - 120 x 120px minimum.',
      typeError: 'Acceptable file formats are jpg, gif and png.'
    };

    let uploadCallbacks = new Callback();
    uploadCallbacks.onSubmit = this.onUploaderSubmit.bind(this);
    uploadCallbacks.onComplete = this.onUploaderComplete.bind(this);
    uploadCallbacks.onError = this.onUploaderError.bind(this);
    uploadCallbacks.onProgress = this.onUploaderProgress.bind(this);
    uploadCallbacks.onAllComplete = this.onUploaderAllComplete.bind(this);

    this.uploader = this.uploaderService.s3Uploader(uploadConfig, uploadCallbacks);
  }

  private onUploaderProgress(id: any, name: any, loaded: any, total: any) {
    const percent = Math.round((loaded / total) * 100);
    this.uploaderViewModel.isError = false;
    this.uploaderViewModel.progress = percent;
    this.uploaderViewModel.progressCss = `${percent}%`;
  }

  private onUploaderSubmit(id: any, fileName: any) {
    this.uploaderViewModel.isError = false;

    if (this.manageFileUploadQueues.avatar === -1) {
      this.manageFileUploadQueues.avatar = id;
      return;
    }
    if (this.manageFileUploadQueues.header === -1) {
      this.manageFileUploadQueues.header = id;
      return;
    }
  }

  private onUploaderComplete(id: any, name: any, responseJSON: any, maybeXhr: any) {
    if (responseJSON.success) {
      let createMediaInputData = new CreateMediaInputApiModel();
      createMediaInputData.origname = name;
      createMediaInputData.size = this.uploaderAvatar.getSize(id);
      const fileInfo = this.uploaderService.getUploadedFile(id);
      createMediaInputData.path = fileInfo.path;
      createMediaInputData.source = fileInfo.name;
      createMediaInputData.filename = fileInfo.name;
      createMediaInputData.type = fileInfo.type;

      if (this.manageFileUploadQueues.avatar === id) {
        this.landingPageForm.controls.avatarMedia.setValue(createMediaInputData);
        this.manageFileUploadQueues.avatar = null;
      }

      if (this.manageFileUploadQueues.header === id) {
        this.landingPageForm.controls.headerMedia.setValue(createMediaInputData);
        this.manageFileUploadQueues.header = null;
      }

      if (this.manageFileUploadQueues.avatar === null && this.manageFileUploadQueues.header === null) {
        this.saveLandingPage();
      }
    }
  }

  private onUploaderAllComplete(successful: any, failed: any) {
    this.uploaderViewModel.isUploading = false;
    this.uploaderViewModel.progress = 0;
    this.uploaderViewModel.progressCss = `0%`;
  }

  private onUploaderError(id: any, name: any, reason: any, maybeXhrOrXdr: any) {
    this.uploaderViewModel.isError = true;
    this.uploaderViewModel.errorMsg = reason;
  }

  private onAvatarUploaderSubmit(id: any, fileName: any) {
    this.uploaderViewModel.isError = false;
    const uploadFile = this.uploaderAvatar.getFile(id);
    const tmpUrl = this.utility.File.getUrlFromBlog(uploadFile);
    this.openCropAvatarImage(tmpUrl);
    this.uploaderAvatar.reset();
  }

  private onAvatarUploaderError(id: any, name: any, reason: any, maybeXhrOrXdr: any) {
    this.uploaderViewModel.isError = true;
    this.uploaderViewModel.errorMsg = reason;
  }

  private openCropAvatarImage(imageUrl: string) {
    const initData: CropImageInitData = {
      imageUrl: imageUrl,
      options: {
        width: 120,
        height: 120,
        minCropBoxWidth: 120,
        minCropBoxHeight: 120,
        aspectRatio: 1
      }
    };
    this.modalService.showModal(CropImageModalComponent, initData, { class: 'modal-trans' }).then((data: Blob) => {
      if (data) {
        this.landingPageForm.controls.avatarImage.setValue(this.utility.File.getUrlFromBlog(data));

        this.manageFileUploadQueues.avatar = -1;
        data['path'] = 'featured-avatar';
        this.uploader.addBlobs(data);
      }
    });
  }

  private onHeaderUploaderSubmit(id: any) {
    this.uploaderHeaderImageViewModel.isError = false;
    const uploadFile = this.uploaderHeaderImage.getFile(id);
    const tmpUrl = this.utility.File.getUrlFromBlog(uploadFile);
    this.openCropHeaderImage(tmpUrl);
    this.uploaderHeaderImage.reset();
  }

  private onHeaderUploaderError(id: any, name: any, reason: any, maybeXhrOrXdr: any) {
    this.uploaderHeaderImageViewModel.isError = true;
    this.uploaderHeaderImageViewModel.errorMsg = reason;
  }

  private openCropHeaderImage(imageUrl: string) {
    const initData: CropImageInitData = {
      imageUrl: imageUrl,
      options: {
        width: 2600,
        height: 600,
        minCropBoxWidth: 2600,
        minCropBoxHeight: 600,
        aspectRatio: 26 / 6
      }
    };
    this.modalService.showModal(CropImageModalComponent, initData, { class: 'modal-trans' }).then((data: Blob) => {
      if (data) {
        this.landingPageForm.controls.headerImage.setValue(this.utility.File.getUrlFromBlog(data));

        this.manageFileUploadQueues.header = -1;
        data['path'] = 'featured-header';
        this.uploader.addBlobs(data);
      }
    });
  }

  private saveLandingPage() {
    if (this.landingPageForm.controls.id.value > 0) {
      // Update
      if(this.Showfrombuilder){
        this.landingPageForm.controls.is_board_visible.setValue(this.initialState.landingPage.is_board_visible);
        this.landingPageForm.controls.is_footnote_visible.setValue(this.initialState.landingPage.is_footnote_visible)
        this.landingPageForm.controls.cta.setValue(this.initialState.landingPage.cta)
      }
      let updateData: UpdateLandingPageInputApiModel = {
        title: this.landingPageForm.controls.title.value,
        buttons: [],
        person: this.landingPageForm.controls.person.value,
        slogan: this.landingPageForm.controls.slogan.value,
        is_board_visible: this.landingPageForm.controls.is_board_visible.value,
        show_submit_story_btn : this.landingPageForm.controls.show_submit_story_btn.value ? 1 : 0,
        enable_overlay: this.landingPageForm.controls.enable_overlay.value,
        is_footnote_visible: this.landingPageForm.controls.is_footnote_visible.value,
        
      };
      if (this.initialState && this.initialState.landingPage) {
        updateData.query_objects = this.initialState.landingPage.queryObjects
      }
      if(this.landingPageForm.controls.avatarMedia.value){
          updateData.avatar_image = this.landingPageForm.controls.avatarMedia.value;
         }
         if(this.Showfrombuilder){
          this.store$.dispatch(new JobLinkacAction.LinkSpotlightJobs(this.landingPageForm.controls.id.value,this.initialState.landingPage.jobIds))
        }
        if(this.Showfrombuilder){
          updateData.cta = this.landingPageForm.controls.cta.value
        }


     if(this.landingPageForm.controls.headerMedia.value){
      updateData.header_image = this.landingPageForm.controls.headerMedia.value;
     }

     if((this.landingPageForm.controls.avatarMedia.value == null || this.landingPageForm.controls.avatarMedia.value == "" )&& this.landingPageForm.controls.avatarImage.value == null){
      updateData.avatar_image = this.landingPageForm.controls.avatarImage.value;
     }
        
      updateData.buttons = this.landingPageForm.controls.buttons.value.map(item => {
        return {
          label: item.label,
          url: item.url,
          foreground: item.foreground,
          background: item.background,
          is_selected: item.isSelected
        } as LandingPageButtonInputApiModel;
      });
      this.onClose.emit(updateData);
     this.store$.dispatch(new landingPageActions.Update(this.currentUserProfile.company.id, this.landingPageForm.controls.id.value, updateData));
    } else {
      // Create 
      if(this.Showfrombuilder){
        this.landingPageForm.controls.is_board_visible.setValue(this.initialState.landingPage.is_board_visible)
      }
      let queryObjects = this.initialState.landingPage.queryObjects
      let createData: CreateLandingPageInputApiModel = {
        title: this.landingPageForm.controls.title.value,
        buttons: [],
        person: this.landingPageForm.controls.person.value,
        slogan: this.landingPageForm.controls.slogan.value,
        is_board_visible: this.landingPageForm.controls.is_board_visible.value,
        enable_overlay: this.landingPageForm.controls.enable_overlay.value,
        query_objects: queryObjects,
        is_footnote_visible: this.landingPageForm.controls.is_footnote_visible.value,
      };



      if(this.landingPageForm.controls.avatarMedia.value){
        createData.avatar_image = this.landingPageForm.controls.avatarMedia.value;
      }
      if(this.Showfrombuilder){
        createData.cta = this.landingPageForm.controls.cta.value
      }
      if(this.landingPageForm.controls.headerMedia.value){
        createData.header_image = this.landingPageForm.controls.headerMedia.value;
      }
      if((this.landingPageForm.controls.avatarMedia.value == null || this.landingPageForm.controls.avatarMedia.value == "") && this.landingPageForm.controls.avatarImage.value == null){
        createData.avatar_image = this.landingPageForm.controls.avatarImage.value;
      }

      createData.buttons = this.landingPageForm.controls.buttons.value.map(item => {
        return {
          label: item.label,
          url: item.url,
          foreground: item.foreground,
          background: item.background,
          is_selected: item.isSelected
        } as LandingPageButtonInputApiModel;
      });
      this.onClose.emit(createData);
    
  }
}
  // Public functions
  public close() {
    this.onClose.emit();
  }

  public get buttonsFormArray() {
    return this.landingPageForm.get('buttons') as UntypedFormArray;
  }

  public save() {
    this.utility.Form.markFormGroupDirty(this.landingPageForm);
    if (!this.landingPageForm.valid || this.isSaving) return;

    this.isSaving = true;
    if ((this.manageFileUploadQueues.avatar !== null && this.manageFileUploadQueues.avatar !== -1) || this.manageFileUploadQueues.header !== null && this.manageFileUploadQueues.header !== -1) {
      this.uploader.uploadStoredFiles();
    } else {
      this.saveLandingPage();
    }
  }

  deleteHeaderImage(){
    const initState: ConfirmationModalDataModel = {
      title: 'Are you sure, you want to remove?',
      message: ``,
      yesButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    };
    this.modalService.showModal(ConfirmationModalComponent, initState).then((isConfirm: boolean) => {
      if (isConfirm) {
         this.landingPageForm.controls.headerImage.setValue(null)
      }
    })
  }


  deletelogoavatar(){
    const initState: ConfirmationModalDataModel = {
      title: 'Are you sure, you want to remove?',
      message: ``,
      yesButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    };
    this.modalService.showModal(ConfirmationModalComponent, initState).then((isConfirm: boolean) => {
      if (isConfirm) {
         this.landingPageForm.controls.avatarImage.setValue(null)
      }
    })
  }

  designCanva() {
     if(this.isBrowser){
      const self = this;
      (function (document, url) {
        var script = document.createElement('script');
        script.src = url;
        script.onload = async function () {
          if (window.Canva && window.Canva.DesignButton) {
            const api = await window.Canva.DesignButton.initialize({
              apiKey: 'e2sw-EOGREFYSFClYlIU87eS',
            });
            window.api = api;
            setTimeout(() => {
              window.api.createDesign({
                design: {
                  type: 'BlogBanner',
                },
                onDesignPublish: ({ exportUrl, designId }) => {
                  self.getBase64ImageFromUrl(exportUrl)
                    .then((result: any) => {
                      self.landingPageForm.controls.headerImage.setValue(exportUrl);
                      const imageEditedBlob = self.utility.File.b64ToBlob(result.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''), "image/png");
                      imageEditedBlob['path'] = 'company-profile-header';
                      let createMediaInputData = new CreateMediaInputApiModel();
                      createMediaInputData.origname = `misc_data`;
                      createMediaInputData.size = imageEditedBlob.size;
                      createMediaInputData.path = `/mediafiles/company/${self.currentUserProfile.company.id}/${imageEditedBlob['path']}`;
                      createMediaInputData.source = `header_${Math.floor(1000 + Math.random() * 9000)}.${imageEditedBlob.type.split('/')[1]}`;
                      createMediaInputData.filename = `header_${Math.floor(1000 + Math.random() * 9000)}.${imageEditedBlob.type.split('/')[1]}`;
                      createMediaInputData.type = imageEditedBlob.type;
                      self.landingPageForm.controls.headerMedia.setValue(createMediaInputData);
                      self.manageFileUploadQueues.header = -1;
                      self.uploader.addBlobs(imageEditedBlob);
                    })
                    .catch(err => console.error(err));
                }
              });
            }, 2000);
          }
        }
        document.body.appendChild(script);
      })(document, 'https://sdk.canva.com/designbutton/v2/api.js');
     }


  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }
}
