import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { CoreState } from './store/core/states';
import { appActions, routerActions } from './store/core/actions';
import { SeoService } from '@modules/shared/services/seo.service';
import { filter } from 'rxjs/operators';
import { userPermissionActions } from '@modules/user/store/actions';

import firebase from "firebase/app";
import "firebase/messaging";




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'GoodSeeker';
  token: any;

  public isBrowser:boolean;
  constructor(
    private store$: Store<CoreState>,
    private router: Router,
    @Inject(PLATFORM_ID) platformId: Object,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,

  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const linkQueryParam = sessionStorage.getItem('linkQueryParam');
        if (linkQueryParam) {
          const nextUrl = atob(decodeURI(linkQueryParam));
          if (event.url == nextUrl) {
            sessionStorage.removeItem('linkQueryParam');
          }
        }

      });

      // this.AngularFireMessaging.messages.subscribe(
      //   (_messaging: AngularFireMessaging) => {
      //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      //   }
      // )

  //     this.oneSignal.init({
  //       appId: "a6c595b9-d88b-4b16-b3f2-9c428d8f886b",
  //       safari_web_id: "web.onesignal.auto.01ea4289-b460-45e4-8d90-838752554827",
  //       notifyButton: {
  //         enable: true,
  //       },
  //       subdomainName: "goodseeker-123",
  //       allowLocalhostAsSecureOrigin: true,
  //          serviceWorkerParam: {
  //    scope: 'assets/js/OneSignalSDKWorker.js'
  //  },
  //  serviceWorkerPath: 'assets/js/OneSignalSDKWorker.js'
  //     }).then((response)=>{
  //     console.log("Subscribed",response)
  //     }).catch((error)=>{
  //       console.log(error)
  //     });
  }

  ngOnInit(): void {
    const linkQueryParam = sessionStorage.getItem('linkQueryParam');
    if (linkQueryParam) {
      sessionStorage.removeItem('linkQueryParam');
    }


    // firebase.initializeApp(environment.firebase);
    // const messaging = firebase.messaging();


// Notification.requestPermission().then((permission)=>{

//   if(permission == 'granted'){
//     messaging.getToken({ vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
//       if (currentToken) {
//         // Send the token to your server and update the UI if necessary
//         // ...
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.');
//         // ...
//       }
//     }).catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//       // ...
//     });
//   }
// })




    this.store$.dispatch(new appActions.AppInitialized());
    if(this.isBrowser){
      this.store$.dispatch(new userPermissionActions.LoadPermissions());
    }

    // this.seoService.clearMetaTags();
    // this.meta.updateTag({ property: "og:title", content: 'Mindfire' });

    this.subscribe();
  }

  // Private functions
  private subscribe() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        // Alsway scroll to top when change route 
        this.scrollToTop();
        const lastChild = this.getLastActivatedRouteChild(this.activatedRoute);
        this.store$.dispatch(new routerActions.Navigated(lastChild.snapshot.data.pageName));
      }
    });
  }

  private scrollToTop() {
    if (this.isBrowser) {
      window.scrollTo(0, 0);
    }
  }


  // receiveMessage() {
  //   this.AngularFireMessaging.messages.subscribe(
  //   (payload) => {
  //     console.log("new message received. ", payload);
  //     // this.currentMessage.next(payload);
  //   })
  // }
  private getLastActivatedRouteChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.children.length > 0) {
      return this.getLastActivatedRouteChild(activatedRoute.firstChild);
    }
    return activatedRoute;
  }
}
