import { Component, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';


import { ComponentBase } from '@framework/base/component';
import { LandingPageViewModel } from '@modules/company/models/store-view-models';
import { ModalService } from '@framework/services';
import {ShareModalContainerComponent} from "@modules/shared/components/commons/share-modal-container";

@Component({
  selector: 'co-pub-landing-page-item',
  templateUrl: './landing-page-item.component.html',
  styleUrls: ['./landing-page-item.component.scss']
})
export class LandingPageItemComponent extends ComponentBase {
  @Input() page: LandingPageViewModel;
  @Input() href: string;

  // Public variables
  public facetsSelected: any[] = [];
  constructor(private router: Router, private modalService: ModalService) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.processData();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }

  private processData() {
    if (this.page.queryObjects) {
      this.facetsSelected = [...(this.page.queryObjects.facetedTagSelected || []), ...this.page.queryObjects.facetedCampaignSelected || [], ...this.page.queryObjects.facetedPeopleSelected || []];
    }
  }
  // Public functions
  public isTag(index: number) {
    return index < (this.page.queryObjects.facetedTagSelected || []).length;
  }

  public isCampaign(index: number) {
    return index >= (this.page.queryObjects.facetedTagSelected || []).length && index < (this.page.queryObjects.facetedTagSelected || []).length + ((this.page.queryObjects.facetedCampaignSelected || [])).length;
  }

  public isPeople(index: number) {
    return index >= (this.page.queryObjects.facetedTagSelected || []).length + ((this.page.queryObjects.facetedCampaignSelected || [])).length && index < (this.page.queryObjects.facetedTagSelected || []).length + ((this.page.queryObjects.facetedCampaignSelected || [])).length + (this.page.queryObjects.facetedPeopleSelected || []).length;
  }

  public gotoDetail() {
    this.router.navigate([`/c/${this.page.companySlug}/featured/${this.page.slug}`]);
  }

  public showCount(data) : number {

    let number = data.queryObjects?.totalStoriesCount - data.queryObjects?.facetdeletedIds?.length

    if(number <= 0 && data.queryObjects?.totalStoriesCount > 0 ){
      return data.queryObjects?.totalStoriesCount
    }
    else{
       return number
    }

  }
}
