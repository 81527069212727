import { profileInternalActions } from '../actions';
import { ProfileInternalEntity } from '../entities';
import { profileInternalAdapter, initialState } from '../adapters/profile-internal.adapter';
import { ProfileInternalState } from '../states';


export function profileInternalReducer(state = initialState, action: profileInternalActions.Actions): ProfileInternalState {
    switch (action.type) {

        case profileInternalActions.ActionTypes.LOAD_INTERNAL_PROFILE: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case profileInternalActions.ActionTypes.LOAD_INTERNAL_PROFILE_SUCCESS: {
            const result: any = [action.response];
            const entities = result.map(item => new ProfileInternalEntity(item));
            const newSate = profileInternalAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true
            };
        }

        case profileInternalActions.ActionTypes.LOAD_INTERNAL_PROFILE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        default: {
            return state;
        }
    }
}