<div class="gs-verify-input mb-4" [formGroup]="dataForm">
       <input required #verifyCodeInput numbersOnly (paste)="onPaste($event)" autofocus #firstDigit type="text"
              formControlName="firstDigit" autoFocusNexInput [nextFocusElement]="secondDigit"
              [value]="dataForm.controls.firstDigit.value" [maxLength]="1" maxlength="1" [readOnly]="disabled">
       <input required #verifyCodeInput numbersOnly (paste)="onPaste($event)" #secondDigit type="text"
              formControlName="secondDigit" autoFocusNexInput [nextFocusElement]="thirdDigit"
              [value]="dataForm.controls.secondDigit.value" [maxLength]="1" maxlength="1" autoFocusPrevInputWhenDelete
              [prevFocusElement]="firstDigit" [readOnly]="disabled">
       <input required #verifyCodeInput numbersOnly (paste)="onPaste($event)" #thirdDigit type="text"
              formControlName="thirdDigit" autoFocusNexInput [nextFocusElement]="fourthDigit" [maxLength]="1"
              [value]="dataForm.controls.thirdDigit.value" maxlength="1" autoFocusPrevInputWhenDelete
              [prevFocusElement]="secondDigit" [readOnly]="disabled">
       <input required #verifyCodeInput numbersOnly (paste)="onPaste($event)" #fourthDigit type="text"
              formControlName="fourthDigit" autoFocusNexInput [nextFocusElement]="fivethDigit"
              [value]="dataForm.controls.fourthDigit.value" [maxLength]="1" maxlength="1" autoFocusPrevInputWhenDelete
              [prevFocusElement]="thirdDigit" [readOnly]="disabled">
       <input required #verifyCodeInput numbersOnly (paste)="onPaste($event)" #fivethDigit type="text"
              formControlName="fivethDigit" autoFocusNexInput [nextFocusElement]="sixthDigit"
              [value]="dataForm.controls.fivethDigit.value" [maxLength]="1" maxlength="1" autoFocusPrevInputWhenDelete
              [prevFocusElement]="fourthDigit" [readOnly]="disabled">
       <input required #verifyCodeInput numbersOnly (paste)="onPaste($event)" #sixthDigit type="text"
              formControlName="sixthDigit" maxlength="1" [value]="dataForm.controls.sixthDigit.value"
              autoFocusPrevInputWhenDelete [prevFocusElement]="fivethDigit" [readOnly]="disabled">
</div>