import { CampaignButtonOutputApiModel } from "./campaign-button-output.api-model";
import { CampaignQueryObjectsModel } from "../../commons";
import { StoryOutputApiModel } from "@modules/story/models/api-models/output";

export interface CampaignOutputApiModel {
    id: number;
    title: string;
    query_objects: CampaignQueryObjectsModel;
    buttons: CampaignButtonOutputApiModel[];
    slug: string;
    header_image: string;
    avatar_image: string;
    person: string;
    description: string;
    start_date: Date;
    end_date: Date;
    company_slug: string;
    allow_inbound_stories: number;
    is_public: boolean;
    stories: StoryOutputApiModel[];
    story_prompt:any[];
    is_draft: number;
    creator: string;
    show_story_prompt?:any;
    show_submit_story_btn?:any;
    frequent_tags?: any;
    enable_overlay?: boolean;
    order_campaign?: number;
    interview_style?:number
    story_count?:number;
    request_story_label?:any;
    submit_story_label?:any;
}

export class CampaignOutputApiModel {
    constructor(data?: Partial<CampaignOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}


