import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import { storyActions, testimonialActions } from '../actions';
import { TestimonialHttpService } from '../services';

@Injectable()
export class TestimonialEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.Load>(testimonialActions.ActionTypes.LOAD),
    switchMap((action: testimonialActions.Load) => {
      return this.TestimonialHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new testimonialActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new testimonialActions.LoadFail(error));
        }));
    })
  ));

  
  makeActiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.MakeActive>(testimonialActions.ActionTypes.MAKE_ACTIVE),
    switchMap((action: testimonialActions.MakeActive) => {
      return this.TestimonialHttpService.makeActive(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new testimonialActions.MakeActiveSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.MakeActiveFail(error));
        }));
    })
  ));

  
  makeInactiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.MakeInactive>(testimonialActions.ActionTypes.MAKE_INACTIVE),
    switchMap((action: testimonialActions.MakeInactive) => {
      return this.TestimonialHttpService.makeInactive(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new testimonialActions.MakeInactiveSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.MakeInactiveFail(error));
        }));
    })
  ));

  
  makePublicEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.TurnPublicOn>(testimonialActions.ActionTypes.TURN_PUBLIC_ON),
    mergeMap((action: testimonialActions.TurnPublicOn) => {
      return this.TestimonialHttpService.makePublic(action.companyId, action.adminId, action.ids, action.payload).pipe(
        map((respone) => {
          return new testimonialActions.TurnPublicOnSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.TurnPublicOnFail(error));
        }));
    })
  ));

  
  makeInternalEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.TurnPublicOff>(testimonialActions.ActionTypes.TURN_PUBLIC_OFF),
    mergeMap((action: testimonialActions.TurnPublicOff) => {
      return this.TestimonialHttpService.makeInternal(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new testimonialActions.TurnPublicOffSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.TurnPublicOffFail(error));
        }));
    })
  ));

  
  approveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.Approve>(testimonialActions.ActionTypes.APPROVE),
    mergeMap((action: testimonialActions.Approve) => {
      return this.TestimonialHttpService.approve(action.companyId, action.adminId, action.ids,action.sendemail,action.sendTo,action.payload).pipe(
        map((respone) => {
          return new testimonialActions.ApproveSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.ApproveFail(error));
        }));
    })
  ));

  
  rejectEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.Reject>(testimonialActions.ActionTypes.REJECT),
    mergeMap((action: testimonialActions.Reject) => {
      return this.TestimonialHttpService.reject(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new testimonialActions.RejectSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.RejectFail(error));
        }));
    })
  ));

  
  makeVisibleEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.TurnInternalOn>(testimonialActions.ActionTypes.TURN_INTERNAL_ON),
    switchMap((action: testimonialActions.TurnInternalOn) => {
      return this.TestimonialHttpService.makeVisible(action.companyId, action.ids).pipe(
        map((respone) => {
          return new testimonialActions.TurnInternalOnSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.TurnInternalOnFail(error));
        }));
    })
  ));

  
  makeInvisibleEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.TurnInternalOff>(testimonialActions.ActionTypes.TURN_INTERNAL_OFF),
    switchMap((action: testimonialActions.TurnInternalOff) => {
      return this.TestimonialHttpService.makeInvisible(action.companyId, action.ids).pipe(
        map((respone) => {
          return new testimonialActions.TurnInternalOffSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.TurnInternalOffFail(error));
        }));
    })
  ));

  
  linkToCampaignEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<testimonialActions.LinkToCampaign>(testimonialActions.ActionTypes.LINK_TO_CAMPAIGN),
    switchMap((action: testimonialActions.LinkToCampaign) => {
      return this.TestimonialHttpService.linkToCampaign(action.companyId, action.campaignId, action.id).pipe(
        map((respone) => {
          return new testimonialActions.LinkToCampaignSuccess();
        }),
        catchError((error: any) => {
          return of(new testimonialActions.LinkToCampaignFail(error));
        }));
    })
  ));

  exportGuestStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<testimonialActions.ExportGuestStories>(testimonialActions.ActionTypes.EXPORT_GUEST_STORIES),
      switchMap(action => 
        this.TestimonialHttpService.exportGuestStories(action.companyId, action.queryData).pipe(
          map(() => new testimonialActions.ExportGuestStoriesSuccess()),
          catchError(error => of(new testimonialActions.ExportGuestStoriesFailure(error)))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private TestimonialHttpService: TestimonialHttpService) { }
}
