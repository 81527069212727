import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetStoryInputApiModel } from '../models/api-models/input';
import { StoryOutputApiModel } from '../models/api-models/output';
import { Observable, of } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class StoryHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient, private fileSaverService: FileSaverService) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetStoryInputApiModel) {
        return this.get(`/${companyId}/setting/stories/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<StoryOutputApiModel>) => {
                return response;
            })
        );
    }

    public loadButtonColor(companyId: number | string) {
        return this.get(`/${companyId}/setting/buttons/`).pipe(
            map((response:any) => {
                return response;
            })
        );
    }


    public updateButtonColor(companyId: number,buttonid: number,payload: any) {
        return this.patch(`/${companyId}/setting/buttons/${buttonid}/`,payload).pipe(
            map((response:any) => {
                return response;
            })
        );
    }


    public loadInternalRequested(companyId: number, queryData: GetStoryInputApiModel) {
        return this.get(`/${companyId}/setting/story-request/internal/?${queryData.query}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public loadGuestRequeted(companyId: number, queryData: GetStoryInputApiModel) {
        return this.get(`/${companyId}/setting/story-request/guest/?${queryData.query}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeActive(companyId: number, adminId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/stories/active/?aid=${adminId}&ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInactive(companyId: number, adminId: number, ids: number[], draft?: boolean) {
        if(!draft){
            return this.delete(`/${companyId}/setting/stories/active/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
        else{
            return this.delete(`/${companyId}/setting/stories/active/?aid=${adminId}&ids=${ids.join(',')}&draft=1`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }

    }

    public makePublic(companyId: number, adminId: number, ids: number[], payload) {
        const option = payload.option!=4 ? '&option=' + payload.option : '';
        return this.post(`/${companyId}/setting/stories/public/?aid=${adminId}&ids=${ids.join(',')}${option}&enable_advocacy=${payload.enable_advocacy}&internal_advocacy=${payload.internal_advocacy}&public_advocacy=${payload.public_advocacy}`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    

    public makeInternal(companyId: number, adminId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/stories/public/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeVisible(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/stories/visible/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInvisible(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/stories/visible/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public linkToCampaign(companyId: number, campaignId: number, id: number) {
        return this.post(`/${companyId}/setting/stories/active/?id=${id}&cid=${campaignId}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public sendThankYouEmail(storyId,payload)
    {
        const option = payload.option!=4 ? '&option=' + payload.option : '';
            return this.sendThankyou(`${storyId}/send-thank-you/?${option}&enable_advocacy=${payload.enable_advocacy}`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public sendReminder(storyId,payload)
    {
            return this.sendThankyou(`${storyId}/send-draft-reminder/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public approve(companyId: number, adminId: number, ids: number[], sendEmail,sendTo,payload) {
        if(sendEmail){
            let obj = sendTo !=3 ? 'send='+sendTo : ''
            return this.post(`/${companyId}/setting/stories/approve/?aid=${adminId}&ids=${ids.join(',')}&sm=1&${obj}`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
        else
        {
            return this.post(`/${companyId}/setting/stories/approve/?aid=${adminId}&ids=${ids.join(',')}&sm=0`, payload).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
       
    }

    public reject(companyId: number, adminId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/stories/approve/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public rejectSpotlightOnly(companyId: number, adminId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/stories/spotlight-only/?aid=${adminId}&ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public approveSpotlightOnly(companyId: number, adminId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/stories/spotlight-only/?aid=${adminId}&ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteCampaignandStory(cmpanyid,campignId: number[],delteStory) {
        if(delteStory){
            return this.delete(`/${cmpanyid}/setting/campaigns/activate/?ids=${campignId.join(',')}&delete=1&delete_stories=1`).pipe(map((response: any) => response));
        }
        else{
            return this.delete(`/${cmpanyid}/setting/campaigns/activate/?ids=${campignId.join(',')}&delete=1&delete_stories=0`).pipe(map((response: any) => response));
        }
        
    }

    public deleteSpotlight(companyId,spotlightId: number[]){
        return this.delete(`/${companyId}/setting/landing_pages/activate/?ids=${spotlightId.join(',')}&delete=1&delete_stories=1`).pipe(map((response: any) => response));
    }

    public UpdateColumnOrdr(companyId,updatedList){
        return this.patch(`/${companyId}/campaigns/order/campaigns/`,updatedList).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    pollApiUntilReady(companyId,payload) {
        return this.post(`/${companyId}/setting/mux-video-upload/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    CheckpollApiUntilReady(id,companyId) {
        return this.get(`/${companyId}/setting/mux-video-upload/?id=${id}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    checkassetreadyornot(companyid,assetid) {
        return this.get(`/${companyid}/setting/mux-video/?asset_id=${assetid}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    checkmasterurlreadyornot(companyid,assetid) {
        return this.get(`/${companyid}/setting/mux-video/master-access/?asset_id=${assetid}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    grantmasteraccess(companyid,payload) {
        return this.put(`/${companyid}/setting/mux-video/master-access/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    CheckStaticRedentionsisEadyorNot(companyId,assetid){
        return this.get(`/${companyId}/setting/mux-video/static-renditions/?asset_id=${assetid}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    getTextFile(url: string): Observable<string> {
        return this.http.get(url, { responseType: 'text' });
      }

    exportStories(companyId: number, storyInputApiModel: GetStoryInputApiModel): Observable<void> {
        const queryString = `${storyInputApiModel.query}&format=csv`;
    
        return this.get(`/${companyId}/setting/stories/?${queryString}`, { 
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentType = response.headers.get('content-type');
                const blob = new Blob([response.body], { type: contentType || 'text/csv' });
                this.fileSaverService.save(blob, 'user_stories.csv', 'text/csv');
                return undefined;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.text) {
                    const blob = new Blob([error.error.text], { type: 'text/csv' });
                    this.fileSaverService.save(blob, 'user_stories.csv', 'text/csv');
                    return of(undefined);
                }
                throw error;
            })
        );
    }

    exportInternalStories(companyId: number, storyInputApiModel: GetStoryInputApiModel): Observable<void> {
        const queryString = `${storyInputApiModel.query}&format=csv`;
    
        return this.get(`/${companyId}/setting/story-request/internal/?${queryString}`, { 
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentType = response.headers.get('content-type');
                const blob = new Blob([response.body], { type: contentType || 'text/csv' });
                this.fileSaverService.save(blob, 'requested_internal_stories.csv', 'text/csv');
                return undefined;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.text) {
                    const blob = new Blob([error.error.text], { type: 'text/csv' });
                    this.fileSaverService.save(blob, 'requested_internal_stories.csv', 'text/csv');
                    return of(undefined);
                }
                throw error;
            })
        );
    }

    exportGuestStories(companyId: number, storyInputApiModel: GetStoryInputApiModel): Observable<void> {
        const queryString = `${storyInputApiModel.query}&format=csv`;
    
        return this.get(`/${companyId}/setting/story-request/guest/?${queryString}`, { 
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentType = response.headers.get('content-type');
                const blob = new Blob([response.body], { type: contentType || 'text/csv' });
                this.fileSaverService.save(blob, 'requested_guest_stories.csv', 'text/csv');
                return undefined;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.text) {
                    const blob = new Blob([error.error.text], { type: 'text/csv' });
                    this.fileSaverService.save(blob, 'requested_guest_stories.csv', 'text/csv');
                    return of(undefined);
                }
                throw error;
            })
        );
    }
    
}

// const url = `/api/v3/companies/${companyId}/setting/story-request/${pageType}/?format=csv`;
