import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@framework/services';
import { map } from 'rxjs/operators';
@Injectable()
export class TrackingHttpService extends BaseHttpService {
    constructor(protected http: HttpClient) {
        super(http, '/track');
    }

    addTrackingIdToStoryOrCampaign(payload){
        return this.post(`/details/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    // getTrackingIdjobsforstory(payload){
    //     return this.get(`/details/`, payload).pipe(
    //         map((response: any) => {
    //             return response;
    //         })
    //     );
    // }

   addHitToStoryOrCampaign(payload){
    return this.post(`/hits/`, payload).pipe(
        map((response: any) => {
            return response;
        })
    );
   } 

   addPersonalizeTrackHit(payload){
    return this.post(`/personalize/`,payload).pipe(
        map((response: any) => {
            return response;
        })
    );
   }

   getPersonalizeTrackHit(UniqueToken,fromInner){
    if(fromInner){
        return this.get(`/personalize/?unique_token=${UniqueToken}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    else{
        UniqueToken = +UniqueToken
        return this.get(`/personalize/?story_id=${UniqueToken}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

   }

   getTrackHit(companyId){
    return this.get(`/hits-report/${companyId}/`).pipe(
        map((response: any) => {
            return response;
        })
    );
   }
}