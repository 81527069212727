import { PaginationModel } from "@framework/models/pagination-models";

export interface LoadCommentInputApiModel {

}

export class LoadCommentInputApiModel {
    constructor(public pagination: PaginationModel) {

    }

    private buildQuery() {
        let query = '';

        // Put queries

        query += this.pagination.query;

        return query;
    }

    public get query() {
        return this.buildQuery();
    }
}
