import { Actions,createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';

import { storySettingActions } from '../actions';
import { StorySettingOutputApiModel } from '@modules/story/models/api-models/output';
import { CompanyHttpService } from '@modules/story/services';


@Injectable()
export class StorySettingEffect {

  
  loadTestimonialSettingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storySettingActions.LoadTestimonialSetting>(storySettingActions.ActionTypes.LOAD_TESTIMONIAL_SETTING),
    switchMap((action: storySettingActions.LoadTestimonialSetting) => {
      return this.companyHttpService.getTestimoniaSetting(action.companyId).pipe(
        map((response: StorySettingOutputApiModel) => {
          return new storySettingActions.LoadTestimonialSettingSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storySettingActions.LoadTestimonialSettingFailure(error));
        }));
    })
  ));

  
  LoadStorySettingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storySettingActions.LoadStorySetting>(storySettingActions.ActionTypes.LOAD_STORY_SETTING),
    switchMap((action: storySettingActions.LoadStorySetting) => {
      return this.companyHttpService.getCompanySetting(action.slug).pipe(
        map((response: StorySettingOutputApiModel) => {
          return new storySettingActions.LoadStorySettingSuccess((response));
        }),
        catchError((error: any) => {
          return of(new storySettingActions.LoadStorySettingFailure(error));
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private companyHttpService: CompanyHttpService
  ) { }

}
