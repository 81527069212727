import { Action } from '@ngrx/store';
import { StoryCtaTemplateEntity } from '@modules/shared/entities';
import { CtaTemplateOutputApiModel } from '@app/store/http/models/output';

export enum StoryCtaTemplateActions {
    DO_NOTHING = "[Story-Cta-Template] Do Nothing",

    LOAD = "[Story-Cta-Template] Load",
    LOAD_SUCCESS = "[Story-Cta-Template] Load Success",
    LOAD_FAILURE = "[Story-Cta-Template] Load Failure",

    SELECT = "[Story-Cta-Template] Select",
    SELECT_SUCCESS = "[Story-Cta-Template] Select",
    SELECT_FAILURE = "[Story-Cta-Template] Select Failure",

    CREATE = "[Story-Cta-Template] Create",
    CREATE_SUCCESS = "[Story-Cta-Template] Create Success",
    CREATE_FAILURE = "[Story-Cta-Template] Create Failure",

    UPDATE = "[Story-Cta-Template] Update",
    UPDATE_SUCCESS = "[Story-Cta-Template] Update Success",
    UPDATE_FAILURE = "[Story-Cta-Template] Update Failure",

    DELETE = "[Story-Cta-Template] Delete",
    DELETE_SUCCESS = "[Story-Cta-Template] Delete Success",
    DELETE_FAILURE = "[Story-Cta-Template] Delete Failure",

    RESET_STATE = "[Story-Cta-Template] Reset State"
}

export class Load implements Action {
    readonly type = StoryCtaTemplateActions.LOAD;
    constructor(public companyId: number, public isInbound?: boolean) { };
}

export class LoadSuccess implements Action {
    readonly type = StoryCtaTemplateActions.LOAD_SUCCESS;
    constructor(public data: Array<CtaTemplateOutputApiModel>) {
    }
}

export class LoadFailure implements Action {
    readonly type = StoryCtaTemplateActions.LOAD_FAILURE;
    constructor(public error: any) { }
}

export class Select implements Action {
    readonly type = StoryCtaTemplateActions.SELECT;
    constructor(public storyId: number) { }
}

export class SelectSuccess implements Action {
    readonly type = StoryCtaTemplateActions.SELECT_SUCCESS;
    constructor(public storyId: number) { }
}

export class SelectFailure implements Action {
    readonly type = StoryCtaTemplateActions.SELECT_FAILURE
    constructor(public storyId: any, public error: any) { }
}

export class Create implements Action {
    readonly type = StoryCtaTemplateActions.CREATE;
    constructor(public data: StoryCtaTemplateEntity) { }
}

export class CreateSuccess implements Action {
    readonly type = StoryCtaTemplateActions.CREATE_SUCCESS;
    constructor(public data: StoryCtaTemplateEntity) { }
}

export class CreateFailure implements Action {
    readonly type = StoryCtaTemplateActions.CREATE_FAILURE
    constructor(public error: any) { }
}

export class Delete implements Action {
    readonly type = StoryCtaTemplateActions.DELETE;
    constructor(public storyId: number) { }
}

export class DeleteSuccess implements Action {
    readonly type = StoryCtaTemplateActions.DELETE_SUCCESS;
    constructor(public storyId: number) { }
}

export class DeleteFailure implements Action {
    readonly type = StoryCtaTemplateActions.DELETE_FAILURE;
    constructor(public storyId: number, public error: any) { }
}

export class ResetState implements Action {
    readonly type = StoryCtaTemplateActions.RESET_STATE;
}


export type Actions =
    | Load
    | LoadSuccess
    | LoadFailure
    | Select
    | SelectSuccess
    | SelectFailure
    | Delete
    | DeleteSuccess
    | DeleteFailure
    | ResetState;