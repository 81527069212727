import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ProfileInternalOutputApiModel } from '../models/api-models/output';
import { UpdateProfileInternalInputApiModel } from '../models/api-models/input';


@Injectable()
export class ProfileInternalHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public loadProfile(companyId: number) {
        return this.get(`/${companyId}/setting/profile/`).pipe(
            map((response) => {
                return new ProfileInternalOutputApiModel(response);
            })
        )
    }

    public updateProfile(companyId: number, payload: Partial<UpdateProfileInternalInputApiModel>) {
        return this.patch(`/${companyId}/setting/profile/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getDomainName(data){
        return this.get(`/domain/${data}/`).pipe(
            map((response: any) => {
                return response;
            })
        ); 
    }    
    public getDomainDetail(sendGridid){
        return this.get(`/details/domain/${sendGridid}/`).pipe(
            map((response: any) => {
                return response;
            })
        ); 
    }



    public savedomaintodatbase(compainyid,sendgridid,payload){
        return this.post(`/${compainyid}/dns/${sendgridid}/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        ); 
    }

    public verifydomainfromsendgrid(sendgridid){
        return this.get(`/verify/domain/${sendgridid}/`).pipe(
            map((response: any) => {
                return response;
            })
        ); 
    }

}
