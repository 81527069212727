import { MemoizedSelector, createSelector } from "@ngrx/store";

import { UserState } from "../states";
import { userAdapter } from '../adapters/user.adapter';
import { UserEntity } from '../entities';
import { UserFeatureState } from '../states';
import { selectUserFeatureState } from "./user-feature.selectors";
import { UserViewModel } from "../models";
import { isString } from "ts-util-is";

export const selectUserState: MemoizedSelector<object, UserState> = createSelector(selectUserFeatureState, (state: UserFeatureState) => state.user);

const {
    selectIds: selectUserIds,
    selectEntities: selectUserEntities,
    selectAll: selectAllUsers,
    selectTotal: userCount
} = userAdapter.getSelectors(selectUserState);


// -------------[Mapping Functions]------------- 
const getLoading = (state: UserState): boolean => state.loading;
const getLoaded = (state: UserState): boolean => state.loaded;
const getUsers = (entities: Array<UserEntity>) => {
    let result = entities.map((entity) => new UserViewModel(entity));
    return result;
}
const getNextUrl = (state: UserState): string => state.nextUrl;

const getCreatedUserId = (state: UserState): number => state.createdUserId;

const getByUserIdsOrEmails = (entities: UserEntity[], userIdsOrEmails: any[]) => {
    return entities.filter(e => userIdsOrEmails.indexOf(e.id) > -1 || userIdsOrEmails.findIndex(email => isString(email) && email.indexOf(e.email) > -1) > -1).map((entity) => new UserViewModel(entity))
}

// -------------[Public Selectors]-------------
export const selectLoaded: MemoizedSelector<object, boolean> = createSelector(selectUserState, getLoaded);
export const selectLoading: MemoizedSelector<object, boolean> = createSelector(selectUserState, getLoading);
export const selectUsers: MemoizedSelector<object, Array<UserViewModel>> = createSelector(selectAllUsers, getUsers);
export const selectNextUrl: MemoizedSelector<object, string> = createSelector(selectUserState, getNextUrl);
export const selectUsersByIdsOrEmails = createSelector(selectAllUsers, getByUserIdsOrEmails);
export const selectCreatedUserId: MemoizedSelector<object, number> = createSelector(selectUserState, getCreatedUserId);