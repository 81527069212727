import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StoryEntity } from '../entities';
import { StoryState } from '../states';

export const storyAdapter: EntityAdapter<StoryEntity> = createEntityAdapter<StoryEntity>();
export const initialState: StoryState = storyAdapter.getInitialState({
  loading: false,
  loaded: false,
  prevUrl: '',
  nextUrl: '',
  total: 0,
  creating: false,
  updating: false,
  selectedStoryIdOrSlug: null,
  storyEditData: null,
  queryStoriesData: null,
  isEmbedMode: false,
  facetedSearchResult: null,
});