import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, filter, take } from 'rxjs/operators';

import { CoreState } from '@app/store/core/states';
import { LoadStatusModel } from '../models';
import { userSettingSelectors } from '@modules/user/store/selectors';
import { userSettingActions } from '@modules/user/store/actions';

@Injectable()
export class UserSettingGuard  {
    constructor(private store$: Store<CoreState>, private router: Router) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve) => {
            this.getUserSetting().toPromise().then(() => {
                resolve(true);
            }, () => {
                resolve(false);
                this.router.navigate(['/user/login']);
            });
        });
    }

    private getUserSetting(): Observable<any> {
        return this.store$
            .select(userSettingSelectors.selectLoadStatus)
            .pipe(
                tap((loadStatus: LoadStatusModel) => {
                    if (!loadStatus.isLoaded && !loadStatus.isLoading) {
                        return this.store$.dispatch(new userSettingActions.Load());
                    }
                }),
                filter((loadStatus) => loadStatus.isLoaded === true),
                take(1)
            );
    }
}