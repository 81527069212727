import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';

import { storyCtaTemplateActions } from '../actions';
import { CtaTemplateOutputApiModel } from '@app/store/http/models/output';
import { CompanyHttpService } from '@modules/story/services';


@Injectable()
export class StoryCtaTemplateEffect {


  constructor(
    private actions$: Actions,
    private companyHttpService: CompanyHttpService
  ) { }

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyCtaTemplateActions.Load>(storyCtaTemplateActions.StoryCtaTemplateActions.LOAD),
    switchMap((action: storyCtaTemplateActions.Load) => {
      return this.companyHttpService.getCtaTemplates(action.companyId, action.isInbound).pipe(
        map((data: Array<CtaTemplateOutputApiModel>) => {
          return new storyCtaTemplateActions.LoadSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyCtaTemplateActions.LoadFailure(error));
        }))
    })
  ));

}