import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { ReportLeadsEntity } from '../entities';
import { ReportLeadsState } from '../states';

export const reportLeadsAdapter: EntityAdapter<ReportLeadsEntity> = createEntityAdapter<ReportLeadsEntity>();

export const initialState: ReportLeadsState = reportLeadsAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});