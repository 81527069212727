import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions,createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { StoryReportsAction } from '../actions';
import { StoryReportHttpService } from '../services';

@Injectable()
export class StoryReportEffects {

  
  loadEffec$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<StoryReportsAction.Load>(StoryReportsAction.ActionTypes.LOAD),
    switchMap((action: StoryReportsAction.Load) => {
      return this.storyReportHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new StoryReportsAction.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new StoryReportsAction.LoadFail(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private storyReportHttpService: StoryReportHttpService) { }
}
