import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'skeleton-pb-step-bar',
  templateUrl: './skeleton-step-bar.component.html',
  styleUrls: ['./skeleton-step-bar.component.css']
})
export class SkeletonStepBarComponent implements OnInit {

  @Input() itemRenders: number = 4;

  public listItems: number[] = [];

  constructor() { }

  ngOnInit() {
    this.renderItems();
  }

  private renderItems() {
    for (let i = 0; i < this.itemRenders; i++) {
      this.listItems.push(i);
    }
  }

}
