import { initialState, userAdapter } from '../adapters/user.adapter';
import { UserState } from '../states';
import { userActions } from '../actions';
import { UserEntity } from '../entities';

export function userReducer(state = initialState, action: userActions.Actions): UserState {
    switch (action.type) {

        case userActions.ActionTypes.LOAD: {
            return {
                ...state,
                loaded: false,
                loading: true
            }
        }

        case userActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new UserEntity(item));
            if (!action.response.prev && !action.isKeepCurrentState) {
                // Reset entities
                return {
                    ...userAdapter.setAll(entities, state),
                    loaded: true,
                    loading: false,
                    nextUrl: action.response.next,
                    prevUrl: action.response.prev
                }
            } else {
                // Append more
                return {
                    ...userAdapter.addMany(entities, state),
                    loaded: true,
                    loading: false,
                    nextUrl: action.response.next,
                    prevUrl: action.response.prev
                }
            }
        }

        case userActions.ActionTypes.LOAD_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
            }
        }

        case userActions.ActionTypes.CREATE_SUCCESS: {
            const entity = new UserEntity(action.response);
            return {
                ...userAdapter.addOne(entity, state),
                createdUserId: entity.id
            }
        }

        case userActions.ActionTypes.RESET_ALL: {
            return {
                ...userAdapter.removeAll(state)
            };
        }

        default: {
            return state;
        }
    }
}