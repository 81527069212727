<div class="modal-header pb-0">
  <h4 class="modal-title">{{initialState.isinternal ? 'Copy Unique Link' : 'Copy Public Link'}}</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="copyForm">

  <div class="option-wrapper embed-sub-wrapper mb-4 p-3">
  <ng-container *ngIf="!initialState.isinternal">
    <div class="row"> 
      <div class="col-6">
        <!-- <button  class="btns button_style"(click)="publicStory()"><span class="previousbutton">Story Page &nbsp;</span><i class="fa-thin fa-link"></i></button> -->
        <h6>Full Story</h6>
        <p class="description_text">Share everything on page. Also display on socials.</p>
      </div>
      <div class="col-6" >
        <span class="previousbutton" (click)="publicStory()"><i class="fa-regular fa-link"></i></span>
      </div>
    </div>
    <div class="row" *ngIf="initialState.isvideo"> 
      <div class="col-6">
        <!-- <button  class="btns button_style"(click)="publicStory()"><span class="previousbutton">Story Page &nbsp;</span><i class="fa-thin fa-link"></i></button> -->
        <h6>Video Only</h6>
        <p class="description_text">Link displays a page with video only.</p>
      </div>
      <div class="col-6">
        <span class="previousbutton" (click)="publicVideo()"><i class="fa-thin fa-link"></i></span>
      </div>
    </div>
   <hr>
   <ng-container *ngIf="currentUser">
    <div class="headings_of_invite_content row mb-3 ml-1">
      <div class="mt-1"> Add Personal Message to Shared Page</div>
      <div class="slide_toggle ml-2 mb-2">
          <mat-slide-toggle  class="toglle_color" color="accent" formControlName="addPersonalize">
          </mat-slide-toggle>
      </div>
     </div>


     <ng-container *ngIf="copyForm.value.addPersonalize">
      <div class="row">
        <div class="col-6">
          <!-- <h6>First Name</h6> -->
          <gs-input-material label="First Name" name="First Name" placeholder="First Name" formControlName="firstname" [formControl]="copyForm.controls.firstname" type="text"></gs-input-material>
        </div>
        <div class="col-6">
          <!-- <h6>Last Name</h6> -->
          <gs-input-material label="Last Name" name="Last Name" placeholder="Last Name" formControlName="lastname" [formControl]="copyForm.controls.lastname" type="text"></gs-input-material>
        </div>
      </div>
        <div class="row">
          <div class="col-12">
            <h6>Message</h6>
            <!-- <textarea class="input__style" formControlName="message" name="" id="" cols="30" rows="4"></textarea> -->
            <gs-area-material
            label=""
            name="Message" placeholder="What happened? Why does it matter?" rows="2"
            cssClass="spacing-lg" formControlName="message" [formControl]="copyForm.controls.message">
        </gs-area-material>
          </div>

         </div>
         <div class="row genarate_btn_continer" *ngIf="!isShow">
          <button [style.background]="primaryColor ? primaryColor : ''" class="btn genarate_btn btn-pink min-width" type="submit" [disabled]="disabled || copyForm.invalid" (click)="genrarateLink()">Generate Link</button>
         </div>
         <div class="row genarate_btn_continer" *ngIf="isShow">
          <button class="btn min-width" type="submit"  (click)="CopyLink()">Copy link
            &nbsp; <span><i class="fas fa-link" style="color: #1579f6"></i></span>
          </button>
         </div>

     </ng-container>
  
   </ng-container>


  </ng-container>

  <ng-container *ngIf="initialState.isinternal">
    <div class="row"> 
      <div class="col-6">
        <!-- <button  class="btns button_style"(click)="publicStory()"><span class="previousbutton">Story Page &nbsp;</span><i class="fa-thin fa-link"></i></button> -->
        <h6>Unique Story Link</h6>
        <p class="description_text">Share everything on page. Will NOT display on socials.</p>
      </div>
      <div class="col-6">
        <span class="previousbutton" (click)="internalStory()"><i class="fa-regular fa-link"></i></span>
      </div>
    </div>
    <div class="row" *ngIf="initialState.isvideo"> 
      <div class="col-6">
        <!-- <button  class="btns button_style"(click)="publicStory()"><span class="previousbutton">Story Page &nbsp;</span><i class="fa-thin fa-link"></i></button> -->
        <h6>Unique Video Link</h6>
        <p class="description_text">Link displays a page with video only. Will NOT display on socials.</p>
      </div>
      <div class="col-6">
        <span class="previousbutton" (click)="internalVideo()"><i class="fa-regulars fa-link"></i></span>
      </div>
    </div>
    <div class="headings_of_invite_content row mb-3 ml-1">
      <div class="mt-1"> Add personal message to unique link page</div>
      <div class="slide_toggle ml-2 mb-2">
          <mat-slide-toggle  class="toglle_color" color="accent" formControlName="addPersonalize">
          </mat-slide-toggle>
      </div>
     </div>
    <div class="row" *ngIf="copyForm.value.addPersonalize">
      <div class="col-6">
        <!-- <h6>First Name</h6> -->
        <gs-input-material label="First Name" name="First Name" placeholder="First Name" formControlName="firstname" [formControl]="copyForm.controls.firstname" type="text"></gs-input-material>
      </div>
      <div class="col-6">
        <!-- <h6>Last Name</h6> -->
        <gs-input-material label="Last Name" name="Last Name" placeholder="Last Name" formControlName="lastname" [formControl]="copyForm.controls.lastname" type="text"></gs-input-material>
      </div>
    </div>
      <div class="row" *ngIf="copyForm.value.addPersonalize">
        <div class="col-12">
          <h6>Message</h6>
          <!-- <textarea class="input__style" formControlName="message" name="" id="" cols="30" rows="4"></textarea> -->
          <gs-area-material
          label=""
          name="Message" placeholder="What happened? Why does it matter?" rows="2"
          cssClass="spacing-lg" formControlName="message" [formControl]="copyForm.controls.message">
      </gs-area-material>
        </div>

       </div>
       <div class="row genarate_btn_continer" *ngIf="copyForm.value.addPersonalize && !isShow">
        <button class="btn genarate_btn btn-pink min-width" type="submit" [disabled]="disabled || copyForm.invalid" (click)="genrarateLink()">Generate Link</button>
       </div>
       <div class="row genarate_btn_continer" *ngIf="isShow">
        <button class="btn min-width" type="submit"  (click)="CopyLink()">Copy link
          &nbsp; <span><i class="fas fa-link" style="color: #1579f6"></i></span>
        </button>
       </div>
  </ng-container>
  </div>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->