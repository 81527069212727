import { Component, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ComponentBase } from '@framework/base/component';
import { StoryViewModel } from '@modules/story/models/view-models';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';

@Component({
  selector: 'app-pb-author-informations',
  templateUrl: './author-informations.component.html',
  styleUrls: ['./author-informations.component.css']
})
export class AuthorInformationsComponent extends ComponentBase {
  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectIsEmbedMode$: Observable<boolean>;
  private selectStatusDetail$: Observable<StoryLoadStatusViewModel>;

  private selectStoryDetailSubscription: Subscription;
  private selectIsEmbedModeSubscription: Subscription;
  private selectStatusDetailSubscription: Subscription;

  private isEmbedMode: boolean = false;
  // Public variables
  public storyDetail: StoryViewModel;
  public authorInfo: string = '';
  public statusDetail: StoryLoadStatusViewModel;

  constructor(private store$: Store<CoreState>) {
    super();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectIsEmbedMode$ = this.store$.select(storySelector.selectIsEmbedMode);
    this.selectStatusDetail$ = this.store$.select(storySelector.selectLoadStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
      this.buildAuthorInfo();
    });

    this.selectIsEmbedModeSubscription = this.selectIsEmbedMode$.subscribe((isEmbedMode) => {
      this.isEmbedMode = isEmbedMode;
    });

    this.selectStatusDetailSubscription = this.selectStatusDetail$.subscribe((statusDetail) => {
      this.statusDetail = statusDetail;
    });
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.selectIsEmbedModeSubscription.unsubscribe();
    this.selectStatusDetailSubscription.unsubscribe();
  }

  private buildAuthorInfo() {
    if (!this.storyDetail || !this.storyDetail.author) return;

    let authorInfo: string = ``;
  
    if(this.storyDetail && this.storyDetail?.type == 'inbound'){
      if (this.storyDetail.author.slug && !this.isEmbedMode) {
        if(this.storyDetail?.show_name_public){
          authorInfo = `${authorInfo} <strong><a class="text-blue" href="p/${this.storyDetail.author.slug}">${this.storyDetail.author.displayName}</a></strong>`;
        }
        else{
          authorInfo = `${authorInfo} <strong>${'Anonymous'}</strong>`;
        }
      } else {
        authorInfo = `${authorInfo} <strong>${this.storyDetail?.show_name_public ? this.storyDetail.author.displayName : 'Anonymous'}</strong>`;
      }
  
      if (this.storyDetail.author.relationship && this.storyDetail?.show_relationship_public) {
        authorInfo = `${authorInfo} - ${this.storyDetail.author.relationship}`;
      }
  
      if (this.storyDetail.author.title || this.storyDetail.author.companyName) {
        authorInfo += ' ';
  
        if (this.storyDetail.author.title && !this.storyDetail.author.display_title &&  this.storyDetail?.show_title_public) {
          authorInfo = `${authorInfo} - ${this.storyDetail.author.title} <br/>`;
        }
        if (this.storyDetail.author.title && this.storyDetail.author.display_title && this.storyDetail?.show_title_public) {
          authorInfo = `${authorInfo} - ${this.storyDetail.author.display_title} <br/>`;
        }
        if (!this.storyDetail.author.title && this.storyDetail.author.display_title && this.storyDetail?.show_title_public) {
          authorInfo = `${authorInfo} - ${this.storyDetail.author.display_title} <br/>`;
        }
  
        if (this.storyDetail.author.companyName &&  this.storyDetail?.show_company_public) {
          if (this.storyDetail.author.companySlug && !this.isEmbedMode) {
            authorInfo = `${authorInfo} - <strong><a class="text-blue" href="c/${this.storyDetail.author.companySlug}">${this.storyDetail.author.companyName}</a></strong>`;
          } else if (this.storyDetail.author.companyWebsite && !this.isEmbedMode) {
            authorInfo = `${authorInfo} - <strong><a class="text-blue" href="${this.storyDetail.author.companyWebsite}">${this.storyDetail.author.companyName}</a></strong>`;
          } else {
            authorInfo = `${authorInfo} - <strong>${this.storyDetail.author.companyName}</strong>`;
          }
        }
      }
  
      this.authorInfo = authorInfo;
    }
    else{
      if (this.storyDetail.author.slug && !this.isEmbedMode) {
        authorInfo = `${authorInfo} <strong><a class="text-blue" href="p/${this.storyDetail.author.slug}">${this.storyDetail.author.displayName}</a></strong>`;
      } else {
        authorInfo = `${authorInfo} <strong>${this.storyDetail.author.displayName}</strong>`;
      }
  
      if (this.storyDetail.author.relationship) {
        authorInfo = `${authorInfo} - ${this.storyDetail.author.relationship}`;
      }
  
      if (this.storyDetail.author.title || this.storyDetail.author.companyName) {
        authorInfo += ' - ';
  
        if (this.storyDetail.author.title && !this.storyDetail.author.display_title) {
          authorInfo = `${authorInfo} ${this.storyDetail.author.title} <br/>`;
        }
        if (this.storyDetail.author.title && this.storyDetail.author.display_title) {
          authorInfo = `${authorInfo} ${this.storyDetail.author.display_title} <br/>`;
        }
        if (!this.storyDetail.author.title && this.storyDetail.author.display_title) {
          authorInfo = `${authorInfo} ${this.storyDetail.author.display_title} <br/>`;
        }
  
        if (this.storyDetail.author.companyName) {
          if (this.storyDetail.author.companySlug && !this.isEmbedMode) {
            authorInfo = `${authorInfo} <strong><a class="text-blue" href="c/${this.storyDetail.author.companySlug}">${this.storyDetail.author.companyName}</a></strong>`;
          } else if (this.storyDetail.author.companyWebsite && !this.isEmbedMode) {
            authorInfo = `${authorInfo} <strong><a class="text-blue" href="${this.storyDetail.author.companyWebsite}">${this.storyDetail.author.companyName}</a></strong>`;
          } else {
            authorInfo = `${authorInfo} <strong>${this.storyDetail.author.companyName}</strong>`;
          }
        }
      }
  
      this.authorInfo = authorInfo;
    }

  }
  removeUrl(url){
    if(url &&(url.includes('ik.imagekit.io') || url.includes('images.goodseeker.com'))){
      return url.split("?")[0]
    }
    else{
      return url
    }
  
   }
  // Public functions
}
