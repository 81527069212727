<div class="profile-module">
    <div data-toggle="collapse" data-target="#collapseImpact" aria-expanded="true" aria-controls="collapseImpact"
      class="profile-module__header">
      <div class="profile-module__title">Story Impact</div><i aria-hidden="true" class="fas fa-chevron-up"></i>
    </div>
    <div id="collapseImpact" class="profile-module__content collapse show">
      <div class="slick-story-impact">
        
        <div class="story-impact" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide01"
          aria-describedby="slick-slide-control01">
          <div class="story-impact__title"><gs-skeleton-loader height="24"></gs-skeleton-loader></div>
          <div class="story-impact__count"><gs-skeleton-loader width="78" height="78" [appearance]="'circle'"></gs-skeleton-loader></div>
          <div class="story-impact__detail">
            <div class="story-impact__item">
              <gs-skeleton-loader height="50"></gs-skeleton-loader>
            </div>
            <div class="story-impact__item">
              <gs-skeleton-loader height="50"></gs-skeleton-loader>
            </div>
          </div>
        </div>
        <div class="story-impact" aria-hidden="true" tabindex="-1" role="tabpanel" id="slick-slide02"
          aria-describedby="slick-slide-control02">
          <div class="story-impact__title"><gs-skeleton-loader height="24"></gs-skeleton-loader></div>
          <div class="story-impact__count"><gs-skeleton-loader width="78" height="78" [appearance]="'circle'"></gs-skeleton-loader></div>
          <div class="story-impact__detail">
            <div class="story-impact__item">
              <gs-skeleton-loader height="50"></gs-skeleton-loader>
            </div>
          </div>
        </div>
  
        <div class="story-impact" aria-hidden="true" tabindex="0" role="tabpanel"
          id="slick-slide00" aria-describedby="slick-slide-control00">
          <div class="story-impact__icon">
              <gs-skeleton-loader width="78" height="78" [appearance]="'circle'"></gs-skeleton-loader>
          </div>
          <div class="story-impact__desc"><gs-skeleton-loader height="24"></gs-skeleton-loader></div>
        </div>
      </div>
    </div>
  </div>