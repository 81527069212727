import { Actions,createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { switchMap, map, catchError, tap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { NotificationService } from '@app/store/http';
import { notificationActions } from '../actions';
import { NotificationOutputApiModel } from '@app/store/http/models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { Router } from '@angular/router';
import { CoreState } from '@app/store/core/states';
import { notificationSelector } from '../selectors';
import { NotificationViewModel, ResponsePaginationNotificationApiModel } from '../models';

@Injectable()
export class NotificationEffect {

    constructor(
        private store$: Store<CoreState>,
        private actions$: Actions,
        private notificationService: NotificationService,
        private router: Router
    ) { }

    get currentUrl(): string {
        return encodeURI(btoa(this.router.url));
      }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<notificationActions.Load>(notificationActions.ActionTypes.LOAD),
        switchMap((action: notificationActions.Load) => {
            return this.notificationService.getAll(action.payload).pipe(
                map((respose: ResponsePaginationNotificationApiModel) => {
                    return new notificationActions.LoadSuccess(respose);
                }),
                catchError((error: any) => {
                    return of(new notificationActions.LoadFailure(error));
                }));
        })
    ));

    
    clearEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<notificationActions.Clear>(notificationActions.ActionTypes.CLEAR),
        switchMap((action: notificationActions.Clear) => {
            return this.notificationService.clearAll(action.userId).pipe(
                map((isSuccess: boolean) => {
                    return new notificationActions.ClearSuccess(isSuccess);
                }),
                catchError((error: any) => {
                    return of(new notificationActions.ClearFailure(error));
                }));
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<notificationActions.Update>(notificationActions.ActionTypes.UPDATE),
        switchMap((action: notificationActions.Update) => {
            return this.notificationService.update(action.id, action.data).pipe(
                map((isSuccess: boolean) => {
                    return new notificationActions.UpdateSuccess(action.id, action.data);
                }),
                catchError((error: any) => {
                    return of(new notificationActions.UpdateFailure(error));
                }));
        })
    ));

    
    makeAsReadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<notificationActions.MakeAsRead>(notificationActions.ActionTypes.MAKE_AS_READ),
        switchMap((action: notificationActions.MakeAsRead) => {
            return this.notificationService.makeAsRead(action.id).pipe(
                map((isSuccess: boolean) => {
                    return new notificationActions.MakeAsReadSuccess(action.id);
                }),
                catchError((error: any) => {
                    return of(new notificationActions.MakeAsReadFailure(error));
                }));
        })
    ));

    makeAsReadSuccessEffect$ = createEffect(() => this.actions$.pipe(
        ofType<notificationActions.MakeAsReadSuccess>(notificationActions.ActionTypes.MAKE_AS_READ_SUCCESS),
        withLatestFrom(this.store$.select(notificationSelector.selectCurrentNotification)),
        tap(([action, currentNotification]) => {
            if (currentNotification.link) {
                const splitLink = currentNotification.link.split('/');
                const storySlug = splitLink[3];
                // Handle resource type "story" only based on current business logic
                if(currentNotification.requested){
                    if(currentNotification.interview_style){
                        var  url =  'interview/campaign/edit/'+currentNotification.story_id+'/'+currentNotification.company_slug
                        this.router.navigate([url],{ queryParams: { requestedby: 1,  prevUrl: this.currentUrl} ,queryParamsHandling: "merge" })
                    }
                    else{
                        var  url =  'story/'+currentNotification.story_id+'/0/edit'
                        this.router.navigate([url],{ queryParams: { requestedby: 1,  prevUrl: this.currentUrl} ,queryParamsHandling: "merge" })
                    }

                }
                else{
                    window.location.href = `${window.location.protocol}//${window.location.host}/company/${storySlug}`;
                }

               
            }
        })
    ), { dispatch: false });

}