import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorHandlerService {
    // Private variables
    public errorFormServer:any;
    private errorCodeMessageMapping = {
        // Unverified user
        461: `User's email is not verified.`,
        // Register user error
        462: `Something's wrong with registering new user.`,
        // Disabled user
        463: 'User is enabled by admin.',
        
        //400: 'domain not exist',
        // Permission denied
        // 403: `You don't have permission to perform this action.`
    };

    // Public variables

    constructor() {

    }

    // Private functions

    // Public functions
    public getServerMessage(error: HttpErrorResponse): string {
      if(error && error.status==400){
        return error.error.result.errors[0].message
      }
        return this.errorCodeMessageMapping[error.status];
    }
}