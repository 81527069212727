<div class="form-label-group force-focus" [ngClass]="{cssClass: cssClass && cssClass.length > 0}">
  <select [id]="name" [disabled]="formControl.disabled" [(ngModel)]="value" (ngModelChange)="onChange()"
    class="form-control" [ngClass]="{'is-invalid': !formControl.valid && formControl.dirty}">
    <option *ngFor="let opt of data" [value]="opt[valueField]">{{opt[displayField]}}</option>
  </select>
  <label [for]="name">{{label}} <small *ngIf="labelSmall">{{labelSmall}}</small></label>

  <ng-container *ngIf="formControl.dirty && !formControl.valid && !hideErrorMessage">
    <div *ngIf="formControl.errors.required" class="invalid-feedback">{{name}} is required.</div>
    <div *ngIf="formControl.errors.email" class="invalid-feedback">{{name}} must be valid.</div>
    <div *ngIf="formControl.errors.maxlength" class="invalid-feedback">{{name}} must be less than
      {{formControl.errors.maxlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.minlength" class="invalid-feedback">{{name}} Min
      {{formControl.errors.minlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.isCustom" class="invalid-feedback">{{formControl.errors.message}}</div>
  </ng-container>
</div>
