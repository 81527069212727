import { UserAttributeSettingOutputApiModel } from "../models/api-models/output";

export interface UserAttributeSettingEntity {
    id: number;
    name: string;
    values: UserAttributeValueSettingEntity[];
}

export class UserAttributeValueSettingEntity {
    id: number;
    value: string;
}

export class UserAttributeSettingEntity {
    constructor(data: UserAttributeSettingOutputApiModel) {
        this.id = data.id;
        this.name = data.name;
        this.values = data.values;
    }
}