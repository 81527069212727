import { Component, Input, OnChanges, ViewChild, ElementRef, AfterViewInit, Inject, Output, EventEmitter } from '@angular/core';
import * as ImageEditor from 'tui-image-editor/dist/tui-image-editor.min.js';
import { DOCUMENT } from '@angular/common';

import { ImageEditModel } from './models';

@Component({
    selector: 'image-editor-control',
    templateUrl: './image-editor-control.component.html',
    styleUrls: ['./image-editor-control.component.scss']
})
export class ImageEditorControlComponent implements OnChanges, AfterViewInit {
    @Input() imageEdit: ImageEditModel;

    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSave: EventEmitter<Blob> = new EventEmitter<Blob>();

    @ViewChild('imageEditorControl')
    imageEditorControl: ElementRef

    private imageEditor: any;
    private config = {
        includeUI: {
            loadImage: {
                path: '',
                name: 'Edit Image'
            },
            initMenu: 'filter',
            menuBarPosition: 'left',
            theme: {
                'menu.normalIcon.path': '/assets/libs/tui-image-editor/svg/icon-d.svg',
                'menu.activeIcon.path': '/assets/libs/tui-image-editor/svg/icon-b.svg',
                'menu.disabledIcon.path': '/assets/libs/tui-image-editor/svg/icon-a.svg',
                'menu.hoverIcon.path': '/assets/libs/tui-image-editor/svg/icon-c.svg',
            }
        },
        cssMaxWidth: 700,
        cssMaxHeight: 500,
    };

    constructor(@Inject(DOCUMENT) private document: Document) {

    }

    ngOnChanges() {
        this.initConfig();
    }

    ngAfterViewInit() {
        this.createEditor();
    }

    // Private functions
    private initConfig() {
        if (this.imageEdit) {
            this.config.includeUI.loadImage.path = this.imageEdit.src;
        }
    }

    private createEditor() {
        this.imageEditor = new ImageEditor(this.imageEditorControl.nativeElement, this.config);
        this.addCustomHeaderButtons();
    }

    private addCustomHeaderButtons() {

        // Cancel button
        let cancelButton = this.document.createElement('button');
        cancelButton.innerText = 'Cancel';
        cancelButton.classList.add('tui-image-editor-cancel-btn');
        cancelButton.onclick = this.onCancelClick.bind(this);

        // Save button
        let saveButton = this.document.createElement('button');
        saveButton.innerText = 'Save';
        saveButton.classList.add('tui-image-editor-save-btn');
        saveButton.onclick = this.onSaveClick.bind(this);

        let menuHeaderContainer = this.imageEditor.ui._selectedElement.getElementsByClassName('tui-image-editor-header-buttons')[0];
        menuHeaderContainer.innerHTML = '';
        menuHeaderContainer.appendChild(cancelButton);
        menuHeaderContainer.appendChild(saveButton);
    }

    private onCancelClick() {
        this.onCancel.emit();
    }

    private onSaveClick() {
        const imageEditedB64 = this.imageEditor.toDataURL();
        this.onSave.emit(imageEditedB64);
    }
}
