<div class="modal-header pb-0">
  <h4 class="modal-title">Steps</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="copyForm">

  <div class="option-wrapper mb-4 pt-3">
   <div class="row">
    <div class="col-12">
      <gs-input-material label="Step Title" name="Step title" placeholder="Step title" [formControl]="copyForm.controls.title" type="text"></gs-input-material>
    </div>
    <div class="col-12">
      <gs-input-material label="Step Message" name="Step subtitle" placeholder="Step subtitle" [formControl]="copyForm.controls.name" type="text"></gs-input-material>
    </div>
   </div>
  </div>
</div>
<div class="modal-footer">
  <div class="form-group text-right">
      <a class="btn btn-outline btn-gray-light min-width" (click)="close()" style="cursor: pointer;">Cancel</a>
      <button class="btn btn-pink min-width" type="submit"[disabled]="copyForm.invalid" (click)="save()">Save</button>
  </div>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->