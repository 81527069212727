import { ContributionEntity } from "@modules/story/store/entities";

export interface ContributionViewModel {
    id: number,
    avatar: string,
    displayName: string,
    playerId: number,
    data: any,
    likeId: number,
    storyId: number
}

export class ContributionViewModel {
    constructor(data?: ContributionEntity) {
        if (data)
            this.convertFromStore(data);
    }

    private convertFromStore(data: ContributionEntity) {
        Object.assign(this, data);
    }

    public get isLiked(): boolean {
        return this.likeId > 0;
    }
}