export interface WorkAttributeValueViewModel {
    id: number;
    value: string;
}

export interface WorkAttributeViewModel {
    id: number;
    name: string;
    values: WorkAttributeValueViewModel[]
}

export class WorkAttributeViewModel {
    constructor(data?: Partial<WorkAttributeViewModel>) {
        if (data)
            Object.assign(this, data);
    }
}