export class UpdateStoryInputApiModel{
    public id: number;
    public title: string;
    public slug: string;
    public content: string;
    public thumbnail: string;
    public cta_text: string;
    public cta_button: string;
    public cta_config: any;
    public company_id: number;
    public completed: boolean;
    public public: boolean;
    public steps: any;
    
    constructor(init?: Partial<UpdateStoryInputApiModel>){
    }
  }