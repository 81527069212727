import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { UserEntity } from '../entities';
import { UserState } from '../states';

export const userAdapter: EntityAdapter<UserEntity> = createEntityAdapter<UserEntity>();

export const initialState: UserState = userAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});