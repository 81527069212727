import { Action } from '@ngrx/store';

import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { LoadUserInputApiModel, CreateUserInputApiModel } from '@modules/user/models/input';
import { LoadUserOutputApiModel, ValidateUserOutApiModel } from '@modules/user/models/output';

export enum ActionTypes {
    LOAD = '[User] Load',
    LOAD_SUCCESS = '[User] Load Success',
    LOAD_FAILURE = '[User] Load Failure',

    LOAD_AUTHORS = '[User] Load Authors',
    LOAD_AUTHORS_SUCCESS = '[User] Load Authors Success',
    LOAD_AUTHORS_FAILURE = '[User] Load Authors Failure',

    LOAD_CONTRIBUTOR = '[User] Load Contributor',

    RESET_ALL = '[User] Reset Al;',


    CREATE = '[User] Create',
    CREATE_SUCCESS = '[User] Create Success',
    CREATE_FAILURE = '[User] Create Failure',

    VALIDATE_USER = '[User] Validate User',
    VALIDATE_USER_SUCCESS = '[User] Validate User Success',
    VALIDATE_USER_FAILURE = '[User] Validate User Failure'
}


export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public payload: LoadUserInputApiModel, public isKeepCurrentState: boolean = false) { }
}

export class LoadContributor implements Action {
    readonly type = ActionTypes.LOAD_CONTRIBUTOR;

    constructor(public storyId: number, public payload: LoadUserInputApiModel, public isKeepCurrentState: boolean = false) { }
}

export class ResetAll implements Action {
    readonly type = ActionTypes.RESET_ALL;
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<LoadUserOutputApiModel>, public isKeepCurrentState: boolean = false) { }
}

export class LoadFailure implements Action {
    readonly type = ActionTypes.LOAD_FAILURE;

    constructor(public error: any) { }
}

export class LoadAuthor implements Action {
    readonly type = ActionTypes.LOAD_AUTHORS;

    constructor(public companyId: number) { }
}

export class LoadAuthorSuccess implements Action {
    readonly type = ActionTypes.LOAD_AUTHORS_SUCCESS;

    constructor(public response: any) { }
}

export class LoadAuthorFailure implements Action {
    readonly type = ActionTypes.LOAD_AUTHORS_FAILURE;

    constructor(public error: any) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(public storyId: number, public payload: CreateUserInputApiModel) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(public response: LoadUserOutputApiModel) { }
}

export class CreateFailure implements Action {
    readonly type = ActionTypes.CREATE_FAILURE;

    constructor(public error: any) { }
}

export class ValidateUser implements Action {
    readonly type = ActionTypes.VALIDATE_USER;

    constructor(public emails: string[]) { }
}

export class ValidateUserSuccess implements Action {
    readonly type = ActionTypes.VALIDATE_USER_SUCCESS;

    constructor(public response: ValidateUserOutApiModel[]) { }
}

export class ValidateUserFailure implements Action {
    readonly type = ActionTypes.VALIDATE_USER_FAILURE;

    constructor(public error: any) { }
}

export type Actions = Load | LoadSuccess | LoadFailure
    | Create | CreateSuccess | CreateFailure | ResetAll
    | ValidateUser | ValidateUserSuccess | ValidateUserFailure | LoadAuthor
    | LoadAuthorSuccess
    | LoadAuthorFailure;
