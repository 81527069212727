import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { commentActions } from '../actions';
import { CommentHttpService } from '../../services';

@Injectable()
export class CommentEffects {
    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<commentActions.Load>(commentActions.ActionTypes.LOAD),
        switchMap((action: commentActions.Load) => {
            return this.commentHttpService.load(action.resourceId, action.resourceType, action.queryData).pipe(
                map((response) => {
                    return new commentActions.LoadSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new commentActions.LoadFailure(error));
                }))
        })
    ));

    
    createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<commentActions.Create>(commentActions.ActionTypes.CREATE),
        switchMap((action: commentActions.Create) => {
            return this.commentHttpService.create(action.payload).pipe(
                map((response) => {
                    return new commentActions.CreateSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new commentActions.CreateFailure(error));
                }))
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<commentActions.Update>(commentActions.ActionTypes.UPDATE),
        switchMap((action: commentActions.Update) => {
            return this.commentHttpService.update(action.id, action.payload).pipe(
                map(() => {
                    return new commentActions.UpdateSuccess(action.id, action.payload);
                }),
                catchError((error: any) => {
                    return of(new commentActions.UpdateFailure(error));
                }))
        })
    ));

    
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<commentActions.Delete>(commentActions.ActionTypes.DELETE),
        switchMap((action: commentActions.Delete) => {
            return this.commentHttpService.deleteComment(action.id, action.resourceType).pipe(
                map(() => {
                    return new commentActions.DeleteSuccess(action.id);
                }),
                catchError((error: any) => {
                    return of(new commentActions.DeleteFailure(error));
                }))
        })
    ));

    constructor(
        private actions$: Actions,
        private commentHttpService: CommentHttpService
    ) { }
}