import { Directive, AfterContentInit, ElementRef, Input, AfterViewChecked } from '@angular/core';

@Directive({
    selector: '[autoFocus]',
})
export class AutoFocusDirective implements AfterViewChecked {

    @Input() appAutoFocus: boolean;

    private isVisible: boolean = false;

    constructor(private el: ElementRef) {

    }

    public ngAfterViewChecked() {
        if (this.isVisible == false && this.el.nativeElement.offsetParent != null) {
            // isVisible switched from false to true
            this.isVisible = true;
            this.el.nativeElement.focus();
        }
        else if (this.isVisible == true && this.el.nativeElement.offsetParent == null) {
            // isVisible switched from true to false
            this.isVisible = false;
        }
    }

}