import { AdvocacyOutputApiModel } from "../models/api-models/output";

export interface SocialReviewEntity {
    id: number;
    logo: string;
    name: string;
    url: string;
    is_internal_active: boolean;
    is_public_active: boolean;
    is_active: boolean;
    logo_mediafile?: any;
    islogo?:boolean;
    preview?: any;
}

export class SocialReviewEntity {
    constructor(data: AdvocacyOutputApiModel) {
        this.id = data.id;
        this.logo = data.logo;
        this.name = data.name;
        this.url = data.url;
        this.is_internal_active = data.is_internal_active;
        this.is_public_active = data.is_public_active;
        this.is_active = data.is_active;
        if(data.logo_mediafile){
          this.islogo = true;
           this.preview = data.logo_mediafile?.url
        }
        else{
            this.islogo = false;
            this.preview = data.logo;
        }

    }
}