import { MemoizedSelector, createSelector } from "@ngrx/store";

import { UserProfileState } from "../states";
import { userProfileAdapter } from '../adapters/user-profile.adapter';
import { UserFeatureState } from '../states';
import { selectUserFeatureState } from "./user-feature.selectors";
import { UserProfileEntity } from '../entities';
import { Dictionary } from '@ngrx/entity';
import { UserProfileViewModel } from "../models";

export const selectUserProfileState: MemoizedSelector<object, UserProfileState> = createSelector(selectUserFeatureState, (state: UserFeatureState) => state.userProfile);

const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAllItems,
    selectTotal: selectTotal
} = userProfileAdapter.getSelectors(selectUserProfileState);


// -------------[Mapping Functions]-------------
const getCurrentProfileId = (state: UserProfileState): number => {
    return state.currentUserProfileId;
};
const getCurrentProfile = (entities: Dictionary<UserProfileEntity>, currentUserProfileId: number): UserProfileViewModel => {
    const currentUserProfile: UserProfileViewModel = new UserProfileViewModel(entities[currentUserProfileId]);
    return currentUserProfile.id && currentUserProfile;
};
const getIsLoaded = (state: UserProfileState) => state.isLoaded;

const getSelectedId = (state: UserProfileState): number => {
    return state.selectedId;
};
const getSelectedProfile = (entities: Dictionary<UserProfileEntity>, selectedId: number): UserProfileViewModel => {
    const userProfile: UserProfileViewModel = new UserProfileViewModel(entities[selectedId]);
    return userProfile.id && userProfile;
};
const getIsLoadedPublic = (state: UserProfileState) => state.isLoadedPublic;
const getUpdateStatus = (state: UserProfileState) => state.isUpdating;

// -------------[Public Selectors]-------------
export const selectCurrentProfileId: MemoizedSelector<object, number> = createSelector(selectUserProfileState, getCurrentProfileId);
export const selectCurrentProfile: MemoizedSelector<object, UserProfileViewModel> = createSelector(selectEntities, selectCurrentProfileId, getCurrentProfile);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(selectUserProfileState, getIsLoaded);

export const selectSelectedId: MemoizedSelector<object, number> = createSelector(selectUserProfileState, getSelectedId);
export const selectSelectedProfile: MemoizedSelector<object, UserProfileViewModel> = createSelector(selectEntities, selectSelectedId, getSelectedProfile);
export const selectIsLoadedPublic: MemoizedSelector<object, boolean> = createSelector(selectUserProfileState, getIsLoadedPublic);
export const selectUpdateStatus: MemoizedSelector<object, boolean> = createSelector(selectUserProfileState, getUpdateStatus);
