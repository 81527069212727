import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ThankyouPageEntity } from '../entities';
import { AuthPageSettingState } from '../states';


export const authPageSettingAdapter: EntityAdapter<ThankyouPageEntity> = createEntityAdapter<ThankyouPageEntity>();
export const initialState: AuthPageSettingState = authPageSettingAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    data: null
});