<div class="gs-notlogin">
  <div class="gs-notlogin__content">
    <div class="container">
      <div class="row">
        <div class="col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center">
          <h5 class="mb-4">Please sign in.</h5>
          <div class="gs-group-btn">
            <a href="javascript:void(0)" (click)="login()" class="btn btn-white">Login</a>
            <a href="javascript:void(0)" (click)="signup()" class="btn btn-mint">Register</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
