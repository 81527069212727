import { Directive, ElementRef, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Directive({
  selector: '[truncateString], truncateString',
})
export class TruncateDirective {

  @Input() valueTruncate: string;
  @Output() valueTruncateChange = new EventEmitter<string>();

  constructor(private element: ElementRef, private change: ChangeDetectorRef) {
    this.element.nativeElement.focusout = (event: any) => {
      const str = this.valueTruncate ? this.valueTruncate.replace(/\s+/gi, ' ').trim() : this.valueTruncate;
      this.valueTruncateChange.emit(str);
    };
  }
}
