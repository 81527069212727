import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { addUserActions } from '../actions';
import { AddUserHttpService } from '../services';

@Injectable()
export class AddUserEffects {

  addUserEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<addUserActions.Add>(addUserActions.ActionTypes.ADD),
    switchMap((action: addUserActions.Add) => {
      return this.AddUserHttpService.add(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new addUserActions.AddSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new addUserActions.AddFail(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private AddUserHttpService: AddUserHttpService) { }
}
