import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { storySelector } from '@modules/story/store/selectors';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';
import { CoreState } from '@app/store/core/states';

@Component({
  selector: 'skeleton-pb-story-toolbar',
  templateUrl: './skeleton-story-toolbar.component.html',
  styleUrls: ['./skeleton-story-toolbar.component.scss']
})
export class SkeletonStoryToolbarComponent implements OnInit, OnDestroy {

  @Input() itemRenders: number = 5;

  private selectStatusDetail$: Observable<StoryLoadStatusViewModel>;
  private selectStatusDetailSubscription: Subscription;

  public listItems: number[] = [];
  public statusDetail: StoryLoadStatusViewModel;

  constructor(private store$: Store<CoreState>) {
    this.selectStatusDetail$ = this.store$.select(storySelector.selectLoadStatus);
  }

  ngOnInit() {
    this.renderItems();
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private subscribe() {
    this.selectStatusDetailSubscription = this.selectStatusDetail$.subscribe((statusDetail) => {
      this.statusDetail = statusDetail;
    });
  }

  private unsubscribe() {
    this.selectStatusDetailSubscription.unsubscribe();
  }

  private renderItems() {
    for (let i = 0; i < this.itemRenders; i++) {
      this.listItems.push(i);
    }
  }


}
