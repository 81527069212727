<div class="comment-single">
  <div class="comment-single__avatar">
    <!--<gs-avatar isBackground="true" [src]="currentUserProfile?.avatar" classCss="avatar"></gs-avatar>-->
  </div>
  <div class="comment-single__content">
    <form [formGroup]="commentForm" (ngSubmit)="post()">
      <app-text-area-emoji (focus)="textAreaFocus()" formControlName="content" classCss="input"
        placeholder="Click to comment">
      </app-text-area-emoji>
      <div class="comment-single__button" [hidden]="!isShowControl">
        <button type="submit" class="btn btn-xs btn-blue-light-extra btn-rounded">
          Post
          <i *ngIf="isPostProcessing" class="fas fa-spinner fa-pulse"></i>
        </button>
        <button (click)="cancel()" type="button" class="btn btn-xs btn-empty btn-rounded">Cancel</button>
      </div>
    </form>
  </div>
  <div class="comment-single__modal" [ngClass]="{'is-open': isConfirmCanceling}">
    <div class="comment-single__modal-content">
      <div class="comment-single__modal-header">Are you sure?</div>
      <div class="comment-single__modal-footer">
        <button type="button" (click)="confirmNo()" class="btn btn-xs btn-gray-light btn-rounded delete-comment-no">No</button>
        <button type="button" (click)="confirmYes()"
          class="btn btn-xs btn-blue-light-extra btn-rounded delete-comment-yes">Yes</button>
      </div>
    </div>
  </div>
</div>