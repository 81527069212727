
// Document https://github.com/collab-project/videojs-record

import { Component, Input, AfterViewInit, OnChanges, OnDestroy, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import Cropper from 'cropperjs';

import { ImageCropperConfig, ImageCropperResult } from './models';

@Component({
    selector: 'image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ImageCropperComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() config: Partial<ImageCropperConfig>;
    @Input() imageUrl: string;
    @Input() cropbox: Cropper.CropBoxData;

    @ViewChild('image')
    imageEl: ElementRef;
    // Private variables
    public defaultConfig: Partial<ImageCropperConfig> = {
        checkCrossOrigin: true,
        zoomable: true,
        zoomOnWheel: true,
        cropBoxResizable: false,
        responsive: true,
        center: true,
        background: true,
        autoCrop: true,

    };

    // Public variables
    public isLoading: boolean = true;
    public cropper: Cropper;
    public imageElement: HTMLImageElement;
    public loadError: any;

    constructor() {

    }

    ngOnChanges() {
        // Init config
        this.initConfig();
    }

    ngAfterViewInit() {
        this.initCropper();
    }

    ngOnDestroy() {

    }
    // Private functions
    private initConfig() {
        // Configurations
        Object.assign(this.defaultConfig, this.config || {});
    }

    private initCropper() {
        //
        // Setup aspect ratio according to settings
        let aspectRatio = NaN;
        if (this.defaultConfig.width && this.defaultConfig.height) {
            const { width, height } = { width: this.defaultConfig.width, height: this.defaultConfig.height };
            aspectRatio = width / height;
        }

        //
        // Set crop options
        // extend default with custom config
        this.defaultConfig = Object.assign({
            // aspectRatio,
            movable: false,
            scalable: false,
            zoomable: false,
            viewMode: 1,
            checkCrossOrigin: true
        }, this.defaultConfig);

        //
        // Set cropperjs
        const options: any = this.defaultConfig;
        this.cropper = new Cropper(this.imageEl.nativeElement, options);
    }

    // Public methods
    public exportData(isBase64: boolean = false): Promise<ImageCropperResult> {
        return new Promise((resolve) => {
            // Get and set image, crop and canvas data
            const imageData = this.cropper.getImageData();
            const cropData = this.cropper.getCropBoxData();
            const canvas = this.cropper.getCroppedCanvas({
                minWidth: this.defaultConfig.width,
                minHeight: this.defaultConfig.height,
                imageSmoothingEnabled: true,
                imageSmoothingQuality: 'high'
            });
            const data = { imageData, cropData };

            // Create promise to resolve canvas data
            const promise = new Promise(resolve => {
                // Resolve promise with dataUrl
                if (isBase64) {
                    return resolve({
                        dataUrl: canvas.toDataURL('image/png')
                    });
                }
                canvas.toBlob(blob => resolve({ blob }),'',1);
            });

            // Emit export data when promise is ready
            promise.then(res => {
                resolve(Object.assign(data, res));
            });
        });
    }

    /**
     * Image loaded
     * @param ev
     */
    public imageLoaded(ev: Event) {

        //
        // Unset load error state
        this.loadError = false;

        //
        // Setup image element
        const image = ev.target as HTMLImageElement;
        this.imageElement = image;

        //
        // Add crossOrigin?
        if (this.defaultConfig.checkCrossOrigin) image.crossOrigin = 'anonymous';
    }

    /**
     * Image load error
     * @param event
     */
    public imageLoadError(ev: Event) {

        //
        // Set load error state
        this.loadError = true;

        //
        // Unset loading state
        this.isLoading = false;
    }
}
