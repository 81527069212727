import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { companySettingActions } from '../actions';
import { CompanyHttpService } from '../../services';

@Injectable()
export class CompanySettingEffects {

  loadWorkAttributeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companySettingActions.LoadWorkAttributes>(companySettingActions.ActionTypes.LOAD_WORK_ATTRIBUTES),
    switchMap((action: companySettingActions.LoadWorkAttributes) => {
      return this.companyHttpService.getWorkAttribute(action.companyIdSlug).pipe(
        map((respone) => {
          return new companySettingActions.LoadWorkAttributesSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new companySettingActions.LoadWorkAttributesFailure(error));
        }));
    })
  ));

  loadCompanyCampaignEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companySettingActions.LoadCompanyCampaign>(companySettingActions.ActionTypes.LOAD_DETAIL_PAGE),
    switchMap((action: companySettingActions.LoadCompanyCampaign) => {
      return this.companyHttpService.loadCompanyDetailCampaign(action.companyIdSlug,action.campaignId).pipe(
        map((respone) => {
          return new companySettingActions.LoadCompanyCampaignSucess(respone);
        }),
        catchError((error: any) => {
          return of(new companySettingActions.LoadCompanyCampaignFail());
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private companyHttpService: CompanyHttpService) { }
}
