

import { userActions, userPermissionActions } from '../actions';
import { UserEntity } from '../entities';
import { permissionInitialState, UserPermissionState } from '../states/user-permission.state';

export function userPermissionReducer(state = permissionInitialState, action: userPermissionActions.Actions): UserPermissionState {

    switch (action.type) {
        case userPermissionActions.ActionTypes.LOAD_PERMISSION: {
            return {
                ...state,
                loaded: false,
                loading: true,
                response:''
            }
        }

        case userPermissionActions.ActionTypes.LOAD_PERMISSION_SUCCESS: {
            const entities = action.response
            if (!action.response) {
                // Reset entities
                return {
                    ...state,
                    loaded: true,
                    loading: false,
                    response:action
                }
            } else {
                // Append more
                return {
                    ...state,
                    loaded: true,
                    loading: false,
                    response: action.response
                }
            }
        }

        case userPermissionActions.ActionTypes.LOAD_PERMISSION_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false,
                response: ''
            }
        }

        default :{
            return state;
        }

    }
}