import { MemoizedSelector, createSelector } from "@ngrx/store";

import { StoryFeatureState, StoryBuilderState } from "../states";
import { selectStoryFeatureState } from "./story-feature.selector";
import { StoryOutputApiModel } from "@modules/story/models/api-models/output";

export const selectStoryBuilderState: MemoizedSelector<object, StoryBuilderState> = createSelector(selectStoryFeatureState, (state: StoryFeatureState) => state.storyBuilder);

// -------------[Mapping Functions]------------- 
const getStepsCompleted = (state: StoryBuilderState): number[] => state.stepCompletedIds;
const getViewMode = (state: StoryBuilderState): string => state.viewMode;
const getShowHoverFirstStep = (state: StoryBuilderState): boolean => state.showHoverFirstStep;
const getStepActive = (state: StoryBuilderState): any => state.stepActive;
const getStoryData = (state: StoryBuilderState): StoryOutputApiModel => state.storyData;
const getInbound = (state: StoryBuilderState): boolean => state.inbound;
const getCompanyId = (state: StoryBuilderState): number => state.companyId;
const getStoryStepSelected = (state: StoryBuilderState): any => {
    if (!state.storyData || !state.storyData.steps) return null;
    return !state.storyData ? null : state.storyData.steps[state.stepActive && state.stepActive.id];
}
const getStoryStepSelectedTags = (state: StoryBuilderState): any => {
    if (!state.storyData || !state.storyData.steps ) return null;
    return !state.storyData && !state.storyData.steps[state.storyData['stepActive']] ? null : state.storyData
}
// const getStoryStepSelectedTags = (state: StoryBuilderState): any => {
//     if (!state.storyData || !state.storyData.steps || state.storyData['stepActive'] == 5 ) return null ;
//     return !state.storyData && !state.storyData.steps[state.storyData['stepActive']] && state.storyData['stepActive'] == 5  ? null : state.storyData.steps[state.storyData['stepActive']];
// }
const getCanPreview = (state: StoryBuilderState): boolean => state.canPreview;
// -------------[Public Selectors]-------------
export const selectStepCompleted = createSelector(selectStoryBuilderState, getStepsCompleted);
export const selectViewMode = createSelector(selectStoryBuilderState, getViewMode);
export const selectShowHoverFirstStep = createSelector(selectStoryBuilderState, getShowHoverFirstStep);
export const selectStepActive = createSelector(selectStoryBuilderState, getStepActive);
export const selectStoryData = createSelector(selectStoryBuilderState, getStoryData);
export const selectStoryStepSelected = createSelector(selectStoryBuilderState, getStoryStepSelected);
export const selectInbound = createSelector(selectStoryBuilderState, getInbound);
export const selectCompanyId = createSelector(selectStoryBuilderState, getCompanyId);
export const selectCanPreview = createSelector(selectStoryBuilderState, getCanPreview);
export const selectStoryStepSelectedPlayers = createSelector(selectStoryBuilderState, getStoryStepSelectedTags);
// export const selectStoryStepSelectedTags = createSelector(selectStoryBuilderState, getStoryStepSelectedTags);