import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@framework/services';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaService extends BaseHttpService {

    constructor(protected http: HttpClient) {
        super(http, '');
    }

    public verifyToken(token: string) {
        return this.get(`/app/grecaptcha/verify/?token=${token}`);
    }
}