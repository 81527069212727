import { Action } from '@ngrx/store';

import { StorySettingOutputApiModel } from '@modules/story/models/api-models/output';

export enum ActionTypes {
    LOAD_TESTIMONIAL_SETTING = '[Story Setting] Load Testimonial Setting',
    LOAD_TESTIMONIAL_SETTING_SUCCESS = '[Story Setting] Load Testimonial Setting Success',
    LOAD_TESTIMONIAL_SETTING_FAILURE = '[Story Setting] Load Testimonial Setting Failure',

    LOAD_THANK_YOU_PAGE_SETTING = '[Story Setting] Load Thank You Page Setting',
    LOAD_THANK_YOU_PAGE_SETTING_SUCCESS = '[Story Setting] Load Thank You Page Setting Success',
    LOAD_THANK_YOU_PAGE_SETTING_FAILURE = '[Story Setting] Load Thank You Page Setting Failure',

    LOAD_STORY_SETTING = '[Story Setting] Load Story Setting',
    LOAD_STORY_SETTING_SUCCESS = '[Story Setting] Load Story Setting Success',
    LOAD_STORY_SETTING_FAILURE = '[Story Setting] Load Story Setting Failure',
}

export class LoadTestimonialSetting implements Action {
    readonly type = ActionTypes.LOAD_TESTIMONIAL_SETTING;

    constructor(public companyId: number) { };
}

export class LoadTestimonialSettingSuccess implements Action {
    readonly type = ActionTypes.LOAD_TESTIMONIAL_SETTING_SUCCESS;

    constructor(public response: StorySettingOutputApiModel) { }
}

export class LoadTestimonialSettingFailure implements Action {
    readonly type = ActionTypes.LOAD_TESTIMONIAL_SETTING_FAILURE;

    constructor(public error: any) { }
}

export class LoadStorySetting implements Action {
    readonly type = ActionTypes.LOAD_STORY_SETTING;

    constructor(public slug: string) {}
}

export class LoadStorySettingSuccess implements Action {
    readonly type = ActionTypes.LOAD_STORY_SETTING_SUCCESS;

    constructor(public data: StorySettingOutputApiModel) {}
}

export class LoadStorySettingFailure implements Action {
    readonly type = ActionTypes.LOAD_STORY_SETTING_FAILURE;

    constructor(public error: any) {}
}

export type Actions =
    | LoadTestimonialSetting | LoadTestimonialSettingSuccess | LoadTestimonialSettingFailure
| LoadStorySetting | LoadStorySettingSuccess | LoadStorySettingFailure;
