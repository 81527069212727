import { testimonialAudienceSettingActions } from "../actions";
import { TestimonialAudienceSettingEntity } from "../entities/testimonial-audience-setting.entity";
import { TestimonialAudienceSettingAdapter, initialState } from "../adapters/testimonial-audience-setting.adapter";
import { TestimonialAudienceSettingState } from "../states";

export function testimonialAudienceSettingReducer(state = initialState, action: testimonialAudienceSettingActions.Actions): TestimonialAudienceSettingState {
    switch (action.type) {

        case testimonialAudienceSettingActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case testimonialAudienceSettingActions.ActionTypes.LOAD_SUCCESS: {
            let result: any = action.response;
            const entities = result.map(item => new TestimonialAudienceSettingEntity(item));
            const newSate = TestimonialAudienceSettingAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: result.length
            };
        }

        case testimonialAudienceSettingActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}