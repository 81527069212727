import { StoryFacetedSearchOutputApiModel } from "@modules/story/models/api-models/output";

export interface StoryFacetedSearchTagItemModel {
    name: string;
    color: string;
    total: number;
    selected: boolean;
}

export interface StoryFacetedSearchPeopleItemModel {
    id: number;
    name: string;
    avatar: string;
    total: number;
    selected: boolean;
}

export interface StoryFacetedSearchCampaignItemModel {
    id: number;
    name: string;
    total: number;
    selected: boolean;
}

export interface StoryFacetedSearchModel {
    tags: StoryFacetedSearchTagItemModel[];
    players: StoryFacetedSearchPeopleItemModel[];
    campaigns: StoryFacetedSearchCampaignItemModel[];
    frequent_tags?:any;
    internal_tags?:any;
}

export class StoryFacetedSearchModel {
    constructor(data?: StoryFacetedSearchOutputApiModel) {
        if (data)
            this.convertFromApi(data);
    }

    private convertFromApi(data: StoryFacetedSearchOutputApiModel) {
        this.frequent_tags = data.frequent_tags;
        if(data.internal_tags){
            this.internal_tags  = data.internal_tags
        }
        if (data.tags) {
            this.tags = data.tags.map(tag => {
                return {
                    name: tag.name,
                    color: tag.color,
                    total: tag.stories_count,
                    selected: tag.selected
                } as StoryFacetedSearchTagItemModel
            });
        }
        if (data.players) {
            this.players = data.players.map(player => {
                return {
                    id: player.id,
                    name: player.name,
                    avatar: player.avatar,
                    total: player.stories_count,
                    selected: player.selected
                } as StoryFacetedSearchPeopleItemModel
            });
        }
        if (data.campaigns) {
            this.campaigns = data.campaigns.map(campaign => {
                return {
                    id: campaign.id,
                    name: campaign.name,
                    total: campaign.stories_count,
                    selected: campaign.selected
                } as StoryFacetedSearchCampaignItemModel
            });
        }
    }
}