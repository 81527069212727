import { Component, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ComponentBase } from '@framework/base/component';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { commentActions } from '@modules/shared/store/actions';
import { LoadCommentInputApiModel, CommentInputApiModel } from '@modules/shared/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { Observable, Subscription } from 'rxjs';
import { CommentViewModel, LoadStatusModel } from '@modules/shared/models';
import { commentSelectors } from '@modules/shared/store/selectors';
import { skip } from 'rxjs/operators';
import { ApprovalFlowActions } from '@modules/company-admin/store/actions';

@Component({
  selector: 'app-approver-comments',
  templateUrl: './approver-comment.component.html',
  styleUrls: ['./approver-comment.component.css']
})
export class ApproverCommentComponent extends ComponentBase {
  @Input() resourceType: string;
  @Input() resourceId: number;
  @Input() inbound:boolean = false;
  @Input() isMasterAdmin:boolean = false;
  @Output() noComents:EventEmitter<boolean> = new EventEmitter<boolean>();

  // Private variables
  private selectComments$: Observable<CommentViewModel[]>;
  private selectLoadCommentStatus$: Observable<LoadStatusModel>;

  private selectCommentsSubscription: Subscription;
  private selectLoadCommentStatusSubscription: Subscription;
  private queryCommentsData: LoadCommentInputApiModel = new LoadCommentInputApiModel(new PaginationModel(5));
  // Public variables
  public comments: CommentViewModel[] = [];
  public loadCommentStatus: LoadStatusModel;


  constructor(private store$: Store<CoreState>) {
    super();

    this.selectComments$ = this.store$.select(commentSelectors.selectAll);
    this.selectLoadCommentStatus$ = this.store$.select(commentSelectors.selectLoadStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unscubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {
    if (this.resourceType && this.resourceId) {
      this.loadComments();
    }
    if(this.inbound){
      this.isMasterAdmin = false;
    }
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCommentsSubscription = this.selectComments$.pipe(
      skip(1)
    ).subscribe((comments) => {
      this.comments = comments || [];
      if(this.comments.length){
        this.noComents.emit(false)
      }
      else{
        this.noComents.emit(true)
      }
    });

    this.selectLoadCommentStatusSubscription = this.selectLoadCommentStatus$.subscribe((loadStatus) => {
      this.loadCommentStatus = loadStatus;
    });
  }

  private unscubscribe() {
    this.selectCommentsSubscription.unsubscribe();
    this.selectLoadCommentStatusSubscription.unsubscribe();
  }

  private loadComments() {
    this.queryCommentsData.pagination.sort = 'desc';
    this.store$.dispatch(new commentActions.Load(this.resourceId, this.resourceType, this.queryCommentsData));
    this.store$.dispatch(new ApprovalFlowActions.GetComment(this.resourceId, this.resourceType));
  }
  // Public functions
  public loadMore() {
    this.queryCommentsData.pagination.page += 1;
    this.loadComments();
  }

  public trackByFunc(comment: CommentViewModel) {
    return comment.id;
  }
}
