import { Component, Input, ElementRef, HostBinding, SimpleChanges } from '@angular/core';

import { ComponentBase } from '@framework/base/component';

declare var $: any, jQuery: any;
@Component({
    selector: 'app-owl-carousel-child',
    template: '<ng-content></ng-content>'
})
export class OwlChildComponent extends ComponentBase {
    @HostBinding('class.owl-carousel') owlClass = true;
    $owl: any;
    @Input() options: any = {};
    currentSlideIndex: number;
    constructor(private el: ElementRef) {
        super();
        if (typeof $ === 'undefined' && typeof jQuery !== 'undefined') {
            $ = jQuery;
        }
    }

    protected onInit(): void {
        if ((typeof window !== 'undefined') && $ && typeof $.fn.owlCarousel === 'function') {
            this.$owl = $(this.el.nativeElement);
        }
    }
    protected onDestroy(): void {
        this.destroyOwl();
        delete this.$owl;
    }
    protected onChanges(changes: SimpleChanges): void {

    }
    protected onDoCheck(): void {

    }
    protected onAfterContentInit(): void {

    }
    protected onAfterContentChecked(): void {

    }
    protected onAfterViewInit(): void {
        this.initOwl();
    }
    protected onAfterViewChecked(): void {

    }

    // Public functions
    public initOwl() {
        if (this.$owl) {
            let options: any = {};
            Object.assign(options, this.options);
            if (this.currentSlideIndex) {
                options.startPosition = this.currentSlideIndex;
            }
            this.$owl.owlCarousel(options);
            this.$owl.on('changed.owl.carousel', (event: any) => {
                this.currentSlideIndex = event.item.index;
            });
        }
    }

    public trigger(action: string, options?: any[]) {
        if (this.$owl) {
            this.$owl.trigger(action, options);
        }
    }

    public destroyOwl() {
        if (this.$owl) {
            this.$owl.trigger('destroy.owl.carousel')
                .removeClass('owl-loaded owl-hidden')
                .find('.owl-stage:empty, .owl-item:empty')
                .remove();
        }
    }
}