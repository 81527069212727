import { Component, SimpleChanges } from '@angular/core';
import { ComponentModalBase } from '@framework/base';
import { TagSettingViewModel, TagSettingModalViewModal, StepTagSettingViewModel } from '@modules/company-admin/store/models/store-view-models';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CreateTagInputApiModel } from '@modules/story/models/api-models/input';
import { storyStepActions } from '@modules/story/store/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { storyStepSelector } from '@modules/story/store/selectors';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { UpdateStepTagSettingInputApiModel } from '@modules/company-admin/store/models/api-models/input';

@Component({
  selector: 'app-create-tags-modal',
  templateUrl: './create-tags-modal.component.html',
  styleUrls: ['./create-tags-modal.component.css']
})
export class CreateTagsModalComponent extends ComponentModalBase<TagSettingModalViewModal> {
  private selectStepId$: Observable<number>;
  private selectStoryStep$: Observable<any>;
  private selectStepIdSubscription: Subscription;
  private createSuccessSubscription: Subscription;
  private createFailureSubscription: Subscription;
  private updateSuccessSubscription: Subscription;
  private updateFailureSubscription: Subscription;
  public showEditWarning:boolean = false;
  private formChangeSubscription: Subscription;
  private createQuestionSuccessSubscription: Subscription;
  private createQuestionFailureSubscription: Subscription;
  private updateQuestionSuccessSubscription: Subscription;
  private updateQuestionFailureSubscription: Subscription;
  private selectStoryStepSubscription: Subscription;
  private stepId: number;
  public storeTags : any[] = [];
  public isUpdating: boolean = false;
  public updateForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<CoreState>,
    private actions$: Actions
  ) {
    super();
    this.initForm();
    this.selectStepId$ = this.store$.select(storyStepSelector.selectSelectedStepId);
    this.selectStoryStep$ = this.store$.select(storyStepSelector.selectStorySteps);
  }

  protected onInit(): void {
    if(this.initialState.fromTag){
      if (this.initialState.data) {
        this.updateForm.controls.title.setValue(this.initialState.data.name);
      }
    }
    else{
      if (this.initialState.data) {
        this.updateForm.controls.title.setValue(this.initialState.data.question_text);
      }
    }

    this.subscribe();
  }

  protected onDestroy(): void {
    this.unsubscribe();
  }

  protected onChanges(changes: SimpleChanges): void {

  }

  protected onDoCheck(): void {

  }

  protected onAfterContentInit(): void {

  }

  protected onAfterContentChecked(): void {

  }

  protected onAfterViewInit(): void {

  }

  protected onAfterViewChecked(): void {

  }

  private subscribe() {
    this.selectStepIdSubscription = this.selectStepId$.subscribe((stepId) => {
      if (stepId) {
        this.stepId = stepId;
      }
    });

    this.formChangeSubscription = this.updateForm.controls.title.valueChanges.subscribe((res)=>{
      if(res && !this.initialState.fromTag && this.initialState.data?.story_count > 0 && (res.toLowerCase() != this.initialState.data?.question_text.toLowerCase())){
        this.showEditWarning = true;
      }
      else{
        this.showEditWarning = false;
      }

    })

    this.selectStoryStepSubscription = this.selectStoryStep$.subscribe((result: Array<StepTagSettingViewModel>) => {
      if(this.initialState.fromTag){
        if (result.length) {
          if(this.initialState.data){
            this.storeTags = result.find( tag => tag.id == this.initialState.data.id).tags;
          }
          else{
            this.storeTags = result.find( tag => tag.id == this.stepId).tags;
          }
      }
          
      }
    });

    this.formChangeSubscription = 

    this.createSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.CreateTagSettingSuccess>(storyStepActions.ActionTypes.CREATE_STEP_TAG_SETTING_SUCCESS)
    ).subscribe(() => {
      this.isUpdating = false;
      this.onClose.emit(true);
    });

    this.createFailureSubscription = this.actions$.pipe(
      ofType<storyStepActions.CreateTagSettingFailure>(storyStepActions.ActionTypes.CREATE_STEP_TAG_SETTING_FAIL)
    ).subscribe(() => {
      this.isUpdating = false;
    });

    this.updateSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.UpdateStepTagSuccess>(storyStepActions.ActionTypes.UPDATE_STEP_TAG_SETTING_SUCCESS)
    ).subscribe(() => {
      this.isUpdating = false;
      this.onClose.emit(true);
    });

    this.updateFailureSubscription = this.actions$.pipe(
      ofType<storyStepActions.UpdateStepTagFailure>(storyStepActions.ActionTypes.UPDATE_STEP_TAG_SETTING_FAIL)
    ).subscribe(() => {
      this.isUpdating = false;
    });

    this.createQuestionSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.CreateQuestionSuccess>(storyStepActions.ActionTypes.CREATE_QUESTION_SUCCESS)
    ).subscribe(() => {
      this.isUpdating = false;
      this.onClose.emit(true);
    });

    this.createQuestionFailureSubscription = this.actions$.pipe(
      ofType<storyStepActions.CreateQuestionFailure>(storyStepActions.ActionTypes.CREATE_QUESTION_FAILURE)
    ).subscribe(() => {
      this.isUpdating = false;
    });

    this.updateQuestionSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.UpdateQuestionSuccess>(storyStepActions.ActionTypes.UPDATE_QUESTION_SUCCESS)
    ).subscribe(() => {
      this.isUpdating = false;
      this.onClose.emit(true);
    });

    this.updateQuestionFailureSubscription = this.actions$.pipe(
      ofType<storyStepActions.UpdateQuestionFailure>(storyStepActions.ActionTypes.UPDATE_QUESTION_FAIL)
    ).subscribe(() => {
      this.isUpdating = false;
    });
  }

  private unsubscribe() {
    this.selectStepIdSubscription.unsubscribe();
    this.createSuccessSubscription.unsubscribe();
    this.createFailureSubscription.unsubscribe();
    this.updateSuccessSubscription.unsubscribe();
    this.updateFailureSubscription.unsubscribe();
    this.createQuestionSuccessSubscription.unsubscribe();
    this.createQuestionFailureSubscription.unsubscribe();
    this.updateQuestionSuccessSubscription.unsubscribe();
    this.updateQuestionFailureSubscription.unsubscribe();
  }

  private initForm() {
    this.updateForm = this.formBuilder.group({
      title: new UntypedFormControl(''),
      is_active: new UntypedFormControl(true)
    })
  }

  public close() {
    this.onClose.emit(false);
  }

  private createTag() {
    const createTagModel: UpdateStepTagSettingInputApiModel = {
      text: this.updateForm.value.title
    };
    this.store$.dispatch(new storyStepActions.CreateTagSetting(this.initialState.companyId, this.stepId, this.initialState.inbound, false, createTagModel));
  }

  private UpdateTag() {
    const request: UpdateStepTagSettingInputApiModel = {
      text: this.updateForm.value.title
    }
    this.store$.dispatch(new storyStepActions.UpdateStepTag(this.initialState.companyId, this.initialState.data.id, this.initialState.inbound, false, request));
  }

  private createQuestion() {
    if(!this.initialState.main_board){
      const createTagModel: any = {
        question_text: this.updateForm.value.title,
        inbound: this.initialState.inbound
      };
      this.store$.dispatch(new storyStepActions.CreateQuestion(this.initialState.companyId,createTagModel));
    }else{
      const createTagModel: any = {
        question_text: this.updateForm.value.title,
        inbound: this.initialState.inbound,
        is_active:this.updateForm.value.is_active ? 1 : 0
      }; 
      this.store$.dispatch(new storyStepActions.CreateQuestion(this.initialState.companyId,createTagModel));
    }

    
  }

  private UpdateQuestion() {
    const request: any = {
      question_text: this.updateForm.value.title,
      inbound: this.initialState.inbound
    }
    this.store$.dispatch(new storyStepActions.UpdateQuestion(this.initialState.companyId,this.initialState.data.id,request));
  }



  public save() {
    this.isUpdating = true;
    if(this.IsDisabled) { return;}
    if(this.initialState.fromTag){
      if (this.initialState.data) {
        this.UpdateTag();
      } else {
        this.createTag();
      }
    }
    else{
      if (this.initialState.data) {
        this.UpdateQuestion();
      } else {
        this.createQuestion();
      }
    }

  }

  get IsDisabled(): boolean {
    if(this.updateForm.controls.title.value){

      if(this.storeTags.filter(tag => tag.name.toLocaleLowerCase() == this.updateForm.controls.title.value.toLocaleLowerCase()).length){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      return true
    }
  }
}
