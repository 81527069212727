export interface EngagementReportOutputApiModel {
    comments_given: number;
    date_joined: string;
    display_name: string;
    last_login: string;
    last_published: string;
    likes_given: number;
    points_earned: number;
    shares_made: number;
    stories_as_author: number;
    stories_as_player: number;
    total_named: number;
    total_views: number;
    unique_named: number;
    unique_views: number;
    user_days: number;
}

export class EngagementReportOutputApiModel {
    constructor(data?: Partial<EngagementReportOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}


