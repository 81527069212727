import {Directive, HostListener} from "@angular/core";

import { Page } from "./page";

@Directive({selector: '[CandeactivePage]'})
export abstract class CandeactivePage extends Page {
    public canDeactive: boolean = true;
    @HostListener('window:beforeunload', ['$event'])
    unloadBrowser($event: any) {
        if (!this.canDeactive) {
            $event.returnValue = true;
        }
    }
}