export class StoryCtaTemplateConfigItem {
    title: string;
    text: string;
    link: string = null;
}

export class StoryCtaTemplateEntity {
    public id: number;
    public name: string;
    public content: string;
    public status: number;
    public button_text: string;
    public company_id: number;
    public order_cta: number;
    public show_author_information?: boolean;
    public  show_jobs?:boolean;

    constructor(init?: Partial<StoryCtaTemplateEntity>) {
        Object.assign(this, init);
    }
}