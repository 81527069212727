import { Action } from '@ngrx/store';

import { LandingPageOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetLandingPageInputApiModel } from '../models/api-models/input';


export enum ActionTypes {
    LOAD = '[Company Admin Landing Page] Load',
    LOAD_SUCCESS = '[Company Admin Landing Page] Load Success',
    LOAD_FAIL = '[Company Admin Landing Page] Load Fail',

    MAKE_ACTIVE = '[Company Admin Landing Page] Make Active',
    MAKE_ACTIVE_SUCCESS = '[Company Admin Landing Page] Make Active Success',
    MAKE_ACTIVE_FAIL = '[Company Admin Landing Page] Make Active Fail',

    MAKE_INACTIVE = '[Company Admin Landing Page] Make Inactive',
    MAKE_INACTIVE_SUCCESS = '[Company Admin Landing Page] Make Inactive Success',
    MAKE_INACTIVE_FAIL = '[Company Admin Landing Page] Make Inactive Fail',

    TURN_PUBLIC_OFF = '[Company Admin Landing Page] Turn Public Off',
    TURN_PUBLIC_OFF_SUCCESS = '[Company Admin Landing Page] Turn Public Off Success',
    TURN_PUBLIC_OFF_FAIL = '[Company Admin Landing Page] Turn Public Off Fail',

    TURN_PUBLIC_ON = '[Company Admin Landing Page] Turn Public On',
    TURN_PUBLIC_ON_SUCCESS = '[Company Admin Landing Page] Turn Public On Success',
    TURN_PUBLIC_ON_FAIL = '[Company Admin Landing Page] Turn Public On Fail'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public queryData: GetLandingPageInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<LandingPageOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class MakeInactive implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE;

    constructor(public companyId: number, public ids: number[]) { }
}

export class MakeInactiveSuccess implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE_SUCCESS;
}

export class MakeInactiveFail implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE_FAIL;

    constructor(public error: any) { }
}

export class MakeActive implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE;

    constructor(public companyId: number, public ids: number[]) { }
}

export class MakeActiveSuccess implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE_SUCCESS;
}

export class MakeActiveFail implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE_FAIL;

    constructor(public error: any) { }
}

export class TurnPublicOff implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF;

    constructor(public companyId: number, public ids: number[]) { }
}

export class TurnPublicOffSuccess implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF_SUCCESS;
}

export class TurnPublicOffFail implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF_FAIL;

    constructor(public error: any) { }
}

export class TurnPublicOn implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON;

    constructor(public companyId: number, public ids: number[]) { }
}

export class TurnPublicOnSuccess implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON_SUCCESS;
}

export class TurnPublicOnFail implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load | LoadSuccess | LoadFail |
    MakeActive | MakeActiveSuccess | MakeActiveFail |
    MakeInactive | MakeInactiveSuccess | MakeInactiveFail |
    TurnPublicOn | TurnPublicOnSuccess | TurnPublicOnFail |
    TurnPublicOff | TurnPublicOffSuccess | TurnPublicOffFail;
