import { Component, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import { NotificationViewModel } from '@modules/shared/store/models';
import { authActions } from "@modules/auth/store/actions";
import { CampaignViewModel, CompanyViewModel, LandingPageViewModel } from '@modules/company/models/store-view-models';
import { UserProfileViewModel, UserSettingViewModel } from '@modules/user/store/models';
import { userSettingActions, userProfileActions } from '@modules/user/store/actions';
import { userProfileSelectors, userSettingSelectors } from '@modules/user/store/selectors';
import { AuthService, ToastService } from '@framework/services';
import { campaignSelectors, companySelectors, landingPageSelectors } from "@modules/company/store/selectors";
import { StoryViewModel } from "@modules/story/models/view-models";
import { storySelector } from "@modules/story/store/selectors";
import { userActions, userPermissionActions } from '@modules/user/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { storyActions } from '@modules/story/store/actions';
import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';
import { searchBoxActions } from '@modules/company/store/actions';
import { isPlatformBrowser } from '@angular/common';
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent extends ComponentBase {
  // Private variables
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectUserSetting$: Observable<UserSettingViewModel>;
  private selectCampaignDetail$: Observable<CampaignViewModel>;
  private selectLandingPageDetail$: Observable<LandingPageViewModel>;
  private selectSelectedProfile$: Observable<UserProfileViewModel>;
  private selectStoryDetail$: Observable<StoryViewModel>;

  private selectCurrentCompanySubscription: Subscription;
  private selectCurrentUserProfileSubscription: Subscription;
  private selectUserSettingSubscription: Subscription;
  private routerChangeSubscription: Subscription;
  private selectCampaignDetailSubscription: Subscription;
  private selectLandingPageDetailSubscription: Subscription;
  private selectSelectedProfileSubscription: Subscription;
  private selectStoryDetailSubscription: Subscription;
  public permissionSubscription$: Subscription;
  private queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  public rolesAndPermission: any[] = [];
  public showCompanySettingsPage: boolean = false;
  // Public variables
  public userSetting: UserSettingViewModel = new UserSettingViewModel();
  public currentUserProfile: UserProfileViewModel;
  public campaign: CampaignViewModel;
  public featuredPage: LandingPageViewModel;
  public campaignId: any;
  public link: any;
  public spotlightId: any;
  public campaignIsPublic: boolean = false;
  public spotlightIsPublic: boolean = false;
  public spotlightQuery: any;
  public inCompleteCount: number = 0;
  public notifications: NotificationViewModel[] = [];
  public completedAll: boolean = false;
  public currentUrl: string = '';
  public isLoggedIn: Promise<boolean>;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public profile: UserProfileViewModel;
  public storyDetail: StoryViewModel;
  public hasCompany: boolean = false;
  public isAllowView: boolean = false;
  public isAllowCampaignView: boolean = false;
  public isAllowFeaturedPageView: boolean = false;
  public isAllowProfileView: boolean = false;
  public isAllowStoryView: boolean = false;
  public insideIframe: boolean;
  public isallowuserStories:boolean =false;
  public isallowspotlight:boolean = false;
  public  isBrowser: boolean;

  constructor(private store$: Store<CoreState>, private router: Router, private activatedRoute: ActivatedRoute, private actions$: Actions,public authService: AuthService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) platformId: Object) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    this.loadCurrentUserProfile();

    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectUserSetting$ = this.store$.select(userSettingSelectors.selectUserSetting);
    this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    this.selectLandingPageDetail$ = this.store$.select(landingPageSelectors.selectLandingPage);
    this.selectSelectedProfile$ = this.store$.select(userProfileSelectors.selectSelectedProfile);
    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.currentUrl = this.router.url;
    this.store$.dispatch(new userPermissionActions.LoadPermissions())
    this.loadUserRolesAndPermission();
  }

  // Life cycle hook
  protected onInit(): void {
    this.CaptureParams();
    this.subscribe();
    this.isLoggedIn = this.authService.isAuthenticated();
    if (window.location !== window.parent.location) {
      this.insideIframe = true;
      document.getElementsByClassName("site-container")[0]['style'].padding = 0;
    } else {
      this.insideIframe = false;
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.countInComplete();
        this.isAllowView = userProfile.company.isPublic;
        this.isAllowProfileView = userProfile.public;
        this.hasCompany = !(userProfile.company.id == null);
      }
    });

    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.setDefaultValues();
        this.isAllowView = this.currentCompany.pagePublic;
      }
    });

    this.selectLandingPageDetailSubscription = this.selectLandingPageDetail$.subscribe((landingPage) => {
      if (landingPage) {
        this.featuredPage = landingPage;
        this.isAllowFeaturedPageView = landingPage.isPublic;
      }
    });

    this.selectCampaignDetailSubscription = this.selectCampaignDetail$.subscribe((campaign) => {
      if (campaign) {
        if(campaign.slug == this.activatedRoute.snapshot.paramMap.get('pageSlug')){
          this.campaign = campaign;
          this.isAllowCampaignView = campaign.isPublic;

          if(this.isAllowCampaignView){

            if(this.campaign?.interview_style){
             this.link =  window.location.origin+'/interview/inbound/campaign/create/'+this.currentUserProfile?.company?.slug+'/'+this.campaign?.slug;
            }
            else{
              this.link = window.location.origin+'/t/'+this.currentUserProfile?.company?.slug+'/testimonial/create?cs='+this.campaign?.slug
            }
          }
        }
        
      }
    });

    this.selectUserSettingSubscription = this.selectUserSetting$.subscribe((userSetting) => {
      this.userSetting = userSetting;
      this.countInComplete();
    });

    this.selectSelectedProfileSubscription = this.selectSelectedProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.profile = userProfile;
      }
    });

    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
    });

    this.routerChangeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
      }
    });
  }

  private unsubscribe() {
    this.selectCurrentCompanySubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.selectUserSettingSubscription.unsubscribe();
    this.routerChangeSubscription.unsubscribe();
    this.selectCampaignDetailSubscription.unsubscribe();
    this.selectLandingPageDetailSubscription.unsubscribe();
    this.selectSelectedProfileSubscription.unsubscribe();
    this.selectStoryDetailSubscription.unsubscribe();
  }

  private countInComplete() {
    if (!this.currentUserProfile) return;

    this.inCompleteCount = 0;
    if (!this.userSetting.completedFirstStory) {
      this.inCompleteCount += 1;
    }
    if (!this.userSetting.completedInviteMember) {
      this.inCompleteCount += 1;
    }
    if (!this.userSetting.completedSchedule && this.currentUserProfile.isCompanyAdmin) {
      this.inCompleteCount += 1;
    }
    // Check completed
    if (this.currentUserProfile)
      this.completedAll = this.inCompleteCount === 0;
  }

  private loadUserRolesAndPermission() {

    this.permissionSubscription$ = this.actions$.pipe(
      ofType<userPermissionActions.LoadPermissionsSuccess>(userPermissionActions.ActionTypes.LOAD_PERMISSION_SUCCESS)
    ).subscribe(action => {
      this.rolesAndPermission = action.response;
      this.CheckRolesandPermission();
    });
  }

  CheckRolesandPermission() {
    if (this.rolesAndPermission.length) {
      this.showCompanySettingsPage = this.rolesAndPermission.find(permission => permission.name === 'activity_user_stories_list');
      this.isallowuserStories = this.rolesAndPermission.find(permission => permission.name === 'activity_user_stories_list');
      this.isallowspotlight = this.rolesAndPermission.find(permission => permission.name === 'activity_spotlight_pages_list');

  

    }
  }
  private setDefaultValues() {
    this.currentCompany.background = this.currentCompany.background ? this.currentCompany.background : 'https://source.unsplash.com/1900x200/?mountain';
    this.currentCompany.logo = this.currentCompany.logo ? this.currentCompany.logo : 'assets/front-end-minify/img/logo-short.png';
  }

  private async loadCurrentUserProfile() {
    const isLoggedIn = await this.authService.isAuthenticated();
    if (isLoggedIn) {
      this.store$.dispatch(new userSettingActions.Load());
      this.store$.dispatch(new userProfileActions.LoadCurrentProfile());
    }
  }
  // Public functions
  public logout(e) {
    e.preventDefault();
    this.store$.dispatch(new authActions.SignOut());
  }
  public navigateToAdminPage() {

    if(this.showCompanySettingsPage)
    {
      this.router.navigate(['/company/settings/stories']);
    }
    else if(!this.showCompanySettingsPage && this.isallowspotlight)
    {
      this.router.navigate(['/company/settings/featured'])
    }
    
  }
  refreshApi(){
    this.store$.dispatch(new searchBoxActions.Clear())
   this.loadStories();


  }

  private CaptureParams(){
    var queryParams = this.activatedRoute.snapshot.queryParams; 
    if(!_.isEmpty(queryParams)){
      var keys = Object.keys(queryParams);
      if(keys && keys.length && keys.includes('campaignId')){
        this.campaignId = queryParams.campaignId;
        this.campaignIsPublic = keys.includes('public') ? true : false
      }
      else if(keys && keys.length && keys.includes('spotlightId')){
        this.spotlightId = queryParams.spotlightId
        this.spotlightIsPublic = keys.includes('public') ? true : false;
      }
      else{
        this.campaignId = null;
        this.spotlightId = null;
      }
    }
    else{
      this.campaignId = null;
        this.spotlightId = null;
    }
  
    }

  queryParamsDeatil(){
    if(this.campaignId && this.campaignIsPublic){
        return {'campaignId':this.campaignId, 'public':1}
    }
    else if(this.spotlightId && this.spotlightIsPublic){
        return {'spotlightId':this.spotlightId, 'public':1}
    }
    else{
      return {'public':1}
    }

 }

 removeUrl(url){
  if(url.includes('ik.imagekit.io') || url.includes('images.goodseeker.com')){
    return url.split("?")[0]
  }
  else{
    return url
  }

 }
  private loadStories() {
    this.queryStoriesData.companyId = this.currentUserProfile.company.id;
    this.queryStoriesData.userId = this.currentUserProfile.id;
    this.queryStoriesData.pagination.sort = 'desc';
    this.queryStoriesData.pagination.sortBy = 'published_at';
    this.store$.dispatch(new storyActions.LoadByCompany(this.queryStoriesData));
  }
 getCopyLink(){
  if(this.isBrowser){
    var baseUrl = window.location.origin+'/c/'
    if(this.isAllowView && this.currentUrl === '/company'){
      this.link = baseUrl+this.currentUserProfile?.company?.slug
    }
    if(this.isAllowView && this.currentUrl === '/company/campaigns'){
      this.link = baseUrl+this.currentUserProfile?.company?.slug+'/campaigns'
    }
    if(this.isAllowView && this.currentUrl === '/company/featured'){
      this.link = baseUrl+this.currentUserProfile?.company?.slug+'/featured'
    }
    if(this.currentUrl.indexOf('/company/campaigns/') > -1 && this.isAllowCampaignView){
      this.link = baseUrl+this.currentUserProfile?.company?.slug+'/campaigns/'+this.campaign?.slug
    }
    if(this.currentUrl.indexOf('/company/featured/') > -1 && this.isAllowFeaturedPageView){
      this.link = baseUrl+this.currentUserProfile?.company?.slug+'/featured/'+this.featuredPage?.slug
    }

    if((this.isAllowProfileView && this.currentUrl === '/profile')){
      this.link = window.location.origin+'/p/'+this.profile?.slug
    }
    if(((this.currentUrl.indexOf('/company/') > -1 && !(this.currentUrl.indexOf('/company/spotlight-builder') > -1) && !(this.currentUrl.indexOf('/company/settings') > -1) && !(this.currentUrl.indexOf('/company/campaigns') > -1) && !(this.currentUrl.indexOf('/company/featured') > -1) && !(this.currentUrl.indexOf('/company/welcome') > -1) && !(this.currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(this.currentUrl.indexOf('/company/launch-your-first-mission') > -1) && !(this.currentUrl.indexOf('/company/first-schedule') > -1) && this.storyDetail?.public))){
      this.link = baseUrl+this.currentUserProfile?.company?.slug+'/'+this.storyDetail?.slug+'?public=1'
    }
  }

 }
  copyLink(){
    this.getCopyLink();
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.link.toString()
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  
}
}
