import { StepType } from './step-type.constant';
import { StepIcon } from './step-icon.constant'
import { StoryType } from './story-type.constant';
import { ResourceType } from './resource-type.constant';
import { StoryStep } from './story-step.constant';
import { NudgeMode } from './nudge-mode.constant';
import { ColumnType, ActionEventName } from './table.constant';
import { Questionaire } from './questionaire.constant';

export const Constants = {
    stepType: StepType,
    stepIcon: StepIcon,
    storyType: StoryType,
    resourceType: ResourceType,
    storyStep: StoryStep,
    nudgeMode: NudgeMode,
    columnType: ColumnType,
    actionEventName: ActionEventName,
    questionaire: Questionaire
};