export interface NotificationOutputApiModel {
    id: number;
    content: string;
    link: string;
    photo: string;
    read_date: Date;
    sender: string;
    sender_profile: string;
    resource_type: string;
    requested?: boolean;
    story_id?:any;
    interview_style?:boolean;
    company_slug?:string;
}

export class NotificationOutputApiModel {
    constructor(init?: Partial<NotificationOutputApiModel>) {
        Object.assign(this, init);
    }
}