<div class="form-label-group" [ngClass]="{cssClass: cssClass && cssClass.length > 0}">
  <input #inputControl truncateString [tabindex]="tabindex" [id]="name + customId" [type]="type" [placeholder]="placeholder"
    [(ngModel)]="value" (ngModelChange)="onChange()" class="form-control" [disabled]="formControl.disabled"
    [ngClass]="{'is-invalid': !formControl.valid && formControl.dirty}" [(valueTruncate)]="value" (valueTruncateChange)="onChange()">
  <label [for]="name + customId">{{label}} <small *ngIf="labelSmall">{{labelSmall}}</small></label>

  <ng-container *ngIf="formControl.errors && formControl.dirty && !formControl.valid && !hideErrorMessage">
    <div *ngIf="formControl.errors.required" class="invalid-feedback">{{name}} is required.</div>
    <div *ngIf="formControl.errors.email" class="invalid-feedback">{{name}} must be valid.</div>
    <div *ngIf="formControl.errors.maxlength" class="invalid-feedback">{{name}} must be less than
      {{formControl.errors.maxlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.minlength" class="invalid-feedback">{{name}} Min
      {{formControl.errors.minlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.isCustom" class="invalid-feedback">{{formControl.errors.message}}</div>
  </ng-container>
</div>
