import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'co-pu-banner-skeleton',
  templateUrl: './banner-skeleton.component.html',
  styleUrls: ['./banner-skeleton.component.scss']
})
export class BannerSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
