import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { storyBuilderSelector, storyStepSelector } from '@modules/story/store/selectors';
import { Observable, Subscription } from 'rxjs';
import { CoreState } from '@app/store/core/states';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StepTagSettingViewModel } from '@modules/company-admin/store/models/store-view-models/steps-tags-setting.view-model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { UserProfileViewModel } from '@modules/user/store/models';
import { storyActions, storyBuilderActions, storyStepActions } from '@modules/story/store/actions';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { TagViewModel } from '@modules/story/models/view-models';
import { withLatestFrom } from 'rxjs/operators';
import { CreateTagInputApiModel } from '@modules/story/models/api-models/input';
@Component({
  selector: 'app-internaltags',
  templateUrl: './internaltags.component.html',
  styleUrls: ['./internaltags.component.scss']
})
export class InternaltagsComponent extends ComponentModalBase<any> {
  private selectStoryStep$: Observable<any>;
  private selectStoryStepSubscription: Subscription;
  public currentUserProfile: UserProfileViewModel;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectStepActive$: Observable<any>;
  private selectCurrentUserProfileSubscription: Subscription;
  private selectStepActiveSubscription: Subscription;
  private selectStepSelectedData$: Observable<any>;
  private selectTagCreated$: Observable<TagViewModel>;
  private selectTagCreatedSubscription: Subscription;
  private selectInbound$: Observable<boolean>;
  private selectCompanyId$: Observable<number>;
  public viewmodel = {
    isStoryCreating: false,
    isShowCreateTagErrorMsg: false,
    isTagCreating: false,
    inbound: false,
    companyId: 0,
    bacgroundHover: ''
  };
  public form: UntypedFormGroup;
  public tagPagination = {
    pageIndex: 1,
    pageSize: 100,
    isAllowLoadMore: true
  };
  public stepActive: any = {};
  public selectedData: any = [];
  public tags: any[] = [];
  constructor(private actions: Actions, private store$: Store<CoreState>,private formBuilder: UntypedFormBuilder, private actions$: Actions,private detector: ChangeDetectorRef) { 
    super();
    this.selectStoryStep$ = this.store$.select(storyStepSelector.selectStorySteps);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectTagCreated$ = this.store$.select(storyStepSelector.selectCreatedTag);
    this.selectStepActive$ = this.store$.select(storyBuilderSelector.selectStepActive);
    this.selectStepSelectedData$ = this.store$.select(storyBuilderSelector.selectStoryStepSelected);
    this.selectInbound$ = this.store$.select(storyBuilderSelector.selectInbound);
    this.selectCompanyId$ = this.store$.select(storyBuilderSelector.selectCompanyId);
  }

  protected onInit(): void {
    this.form = this.formBuilder.group({
      tag: ['',Validators.required]
    });
    
    this.actions$.pipe(
      ofType<storyStepActions.LoadSuccess>(storyStepActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result) => {

      if(result){
        this.tags = result.data[0].tags;
        this.stepActive = result.data[0]
        this.selectedData = this.initialState?.steps[17] || [];
        this.updateSelectedValues();
      }

    })
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
      this.store$.dispatch(new storyStepActions.Load(this.currentUserProfile.company.id, false));
    })

    this.selectTagCreatedSubscription = this.selectTagCreated$.subscribe((tag: TagViewModel) => {
      if (tag && this.viewmodel.isTagCreating === true) {
        this.selectedData = this.selectedData || [];
        // Do nothing if tag is existed
        if (this.selectedData.indexOf(tag.id) > -1) return;

        this.selectedData.push(tag.id);
   
        this.stepActive.tags.unshift(tag);
   
        this.initialState.steps[17] = this.selectedData;
        let obj = {steps: this.initialState.steps}

        // this.viewmodel.isTagCreating = false;
        this.updateSelectedValues();
        this.updateData(obj);
        this.viewmodel.isTagCreating = false;
        this.form.reset();
      }
    });

    this.selectStepActiveSubscription = this.selectStepActive$.pipe(
      withLatestFrom(
        this.selectInbound$,
        this.selectCompanyId$,
        this.selectStepSelectedData$
      )
    ).subscribe(([stepActive, inbound, companyId, selectedData]) => {


      this.selectedData = selectedData;
      if(this.selectedData){
        this.loadTagsData();
      }
    });
  }

  loadTagsData(){
    this.store$.dispatch(new storyStepActions.Load(this.currentUserProfile.company.id, false));
  }

  public updateData(obj) {
    if (!this.stepActive) return;


    this.store$.dispatch(new storyActions.Update(this.initialState.id,obj));
  }

  
  CheckTagsHistory(tagId) {
    var tags = []
    var tagKeys;
    var stepactive = 17
    if (this.initialState.steps) {
        tagKeys = Object.keys(this.initialState.steps)

        if (tagKeys.includes(stepactive)) {

            tags = this.initialState.steps[stepactive];
            if (!tags.includes(tagId)) {
                tags.push(tagId)
            }
            this.initialState.steps[stepactive] = tags;
            return  this.initialState.steps
        }
        else {
            if (!tags.includes(tagId)) {
                tags.push(tagId)
            }
            this.initialState.steps[stepactive] = tags;
            return this.initialState.steps
        }
    }
    else {
        if (!tags.includes(tagId)) {
            tags.push(tagId)
        }
        return { [stepactive]: tags }
    }



}

  protected onDestroy(): void {

  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }


  close()
  {
    this.onClose.emit(false)
  }
  public tagItemClick(tagItem) {
    // if (!this.checkDisabled(tagItem)) return;

    // this.manageEventShake[tagItem.id] = true;
    // setTimeout(() => {
    //   this.manageEventShake[tagItem.id] = false;
    // }, 500); // 5s

  }
  public tagTrackByFn(index, tagItem) {
    return tagItem.id;
  }
  selectItem(tag){
    let obj;
    if (tag.checked === true) {
      this.selectedData.push(tag.id);
      this.initialState.steps[17] = this.selectedData;
      obj = {steps: this.initialState.steps}
    } else {
      const index = this.selectedData.findIndex(f => f === tag.id);
      this.selectedData.splice(index, 1);
      this.initialState.steps[17] = this.selectedData;
      obj = {steps: this.initialState.steps}
    }

    this.updateData(obj)

  }
  public loadTags() {
    const tags = this.tags.filter(tag => !this.form.controls.tag.value || tag.name.toLocaleLowerCase().indexOf(this.form.controls.tag.value.toLocaleLowerCase()) > -1 || tag.checked);

    const startIndex = 0;
    const endIndex = this.tagPagination.pageIndex * this.tagPagination.pageSize

    const pagedTags = tags.slice(startIndex, endIndex);

    this.tagPagination.isAllowLoadMore = pagedTags.length < tags.length;
    return pagedTags.filter(tag =>  (tag.is_active != 0 && tag.is_active == 1) || (tag.is_active == 1 && tag.checked) || (tag.is_active == 0 && tag.checked));
  }

  private updateSelectedValues() {
    

    // this.tags = this.tags.filter(tag => (tag.is_active != 0) || (tag.is_active != 0 && (this.selectedData && this.selectedData.indexOf(tag.id) > -1)));
    this.tags.forEach(tag => {
      tag.checked = this.selectedData && this.selectedData.indexOf(tag.id) > -1
    });

    this.detector.detectChanges();
  }
  public addTag() {
    if (!this.form.valid) return;

    // this.viewmodel.isShowCreateTagErrorMsg = false;
    // if (this.selectedData && this.selectedData.length === this.maxSelectdTags) {
    //   this.viewmodel.isShowCreateTagErrorMsg = true;
    // }

    this.viewmodel.isTagCreating = true;

    let createTagModel = new CreateTagInputApiModel();
    createTagModel.name = this.form.controls.tag.value;
    createTagModel.resource_id = this.stepActive.id;
    createTagModel.resource_type = 'storystep';
    createTagModel.payload = { cid: this.currentUserProfile.company.id, inbound: false };

    this.store$.dispatch(new storyStepActions.CreateTag(this.stepActive.id, createTagModel));
  }
}
