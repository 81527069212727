import { Action } from '@ngrx/store';

import { CommentOutputApiModel } from '@modules/shared/models/api-models/output';
import { LoadCommentInputApiModel, CommentInputApiModel } from '@modules/shared/models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models/api-models';

export enum ActionTypes {
    LOAD = '[Comment] Load',
    LOAD_SUCCESS = '[Comment] Load Success',
    LOAD_FAILURE = '[Comment] Load Failure',

    UPDATE = '[Comment] Update',
    UPDATE_SUCCESS = '[Comment] Update Success',
    UPDATE_FAILURE = '[Comment] Update Failure',

    CREATE = '[Comment] Create',
    CREATE_SUCCESS = '[Comment] Create Success',
    CREATE_FAILURE = '[Comment] Create Failure',

    DELETE = '[Comment] Delete',
    DELETE_SUCCESS = '[Comment] Delete Success',
    DELETE_FAILURE = '[Comment] Delete Failure'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public resourceId: number, public resourceType: string, public queryData: LoadCommentInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<CommentOutputApiModel>) { }
}

export class LoadFailure implements Action {
    readonly type = ActionTypes.LOAD_FAILURE;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public id: number, public payload: Partial<CommentInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;

    constructor(public id: number, public payload: Partial<CommentInputApiModel>) { }
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAILURE;

    constructor(public error: any) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(public payload: CommentInputApiModel) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(public response: CommentOutputApiModel) { }
}

export class CreateFailure implements Action {
    readonly type = ActionTypes.CREATE_FAILURE;

    constructor(public error: any) { }
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;

    constructor(public id: number, public resourceType: string) { }
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;

    constructor(public id: number) { }
}

export class DeleteFailure implements Action {
    readonly type = ActionTypes.DELETE_FAILURE;

    constructor(public error: any) { }
}

export type Actions =
    Load | LoadSuccess | LoadFailure |
    Update | UpdateSuccess | UpdateFailure |
    Create | CreateSuccess | CreateFailure |
    Delete | DeleteSuccess | DeleteFailure;
