import { PaginationModel } from "@framework/models/pagination-models";

export interface LoadUserInputApiModel {
    search: string,
    fullinfo: number,
    inbound: boolean,
    companyId: number,
    selectedValues: number[],
    selectedEmails: string[],
    excludedValues: number[]
}

export class LoadUserInputApiModel {
    constructor(public pagination: PaginationModel, inbound?: boolean) {
        this.fullinfo = 0;
        this.inbound = inbound || false;
    }

    private buildQuery() {
        let query = '';
        if (this.search && this.search.length > 0) {
            query += `search=${this.search}&`;
        }
        if (this.selectedValues && this.selectedValues.length > 0) {
            query += `selected=${this.selectedValues.join(',')}&`;
        }
        if (this.selectedEmails && this.selectedEmails.length > 0) {
            query += `email_selected=${this.selectedEmails.join(',')}&`;
        }
        if (this.excludedValues && this.excludedValues.length > 0) {
            query += `excluded=${this.excludedValues.join(',')}&`;
        }
        query += this.pagination.query;

        return query;
    }

    get query() {
        return this.buildQuery();
    }
}