import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { userEmailActions } from '../actions';
import { UserHttpService } from '../../services';
import { UserEmailEntity } from '../entities';
import { UserEmailOutputApiModel } from '@modules/user/models/output';

@Injectable()
export class UserEmailEffect {
  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userEmailActions.Load>(userEmailActions.ActionTypes.LOAD),
    switchMap((action: userEmailActions.Load) => {
      return this.userHttpService.getEmails().pipe(
        map((response: UserEmailOutputApiModel[]) => {
          return new userEmailActions.LoadSuccess(response);
        }),
        catchError((error: any) => {
          return of(new userEmailActions.LoadFailure(error));
        }));
    })
  ));

  
  removeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userEmailActions.Remove>(userEmailActions.ActionTypes.REMOVE),
    switchMap((action: userEmailActions.Remove) => {
      return this.userHttpService.deleteEmail(action.idEmail).pipe(
        map((response: any) => {
          return new userEmailActions.RemoveSuccess(response, action.idEmail);
        }),
        catchError((response: any) => {
          return of(new userEmailActions.RemoveFailure(response.error));
        }));
    })
  ));

  
  resendEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userEmailActions.Resend>(userEmailActions.ActionTypes.RESEND),
    switchMap((action: userEmailActions.Resend) => {
      return this.userHttpService.resendVerificationEmail(action.idEmail).pipe(
        map((response: any) => {
          return new userEmailActions.ResendSuccess(response);
        }),
        catchError((error: any) => {
          return of(new userEmailActions.ResendFailure(error));
        }));
    })
  ));

  
  addEmailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userEmailActions.AddEmail>(userEmailActions.ActionTypes.ADD_EMAIL),
    switchMap((action: userEmailActions.AddEmail) => {
      return this.userHttpService.addEmail(action.data).pipe(
        map((response: any) => {
          return new userEmailActions.AddEmailSuccess(response);
        }),
        catchError((error: any) => {
          return of(new userEmailActions.AddEmailFailure(error));
        }));
    })
  ));

  
  makePrimaryEmailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userEmailActions.MakePrimaryEmail>(userEmailActions.ActionTypes.MAKE_PRIMARY_EMAIL),
    switchMap((action: userEmailActions.MakePrimaryEmail) => {
      return this.userHttpService.makePrimaryEmail(action.id).pipe(
        map((response: any) => {
          return new userEmailActions.MakePrimaryEmailSuccess(response);
        }),
        catchError((error: any) => {
          return of(new userEmailActions.MakePrimaryEmailFailure(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private userHttpService: UserHttpService
  ) { }
}
