<div class="comment-single is-focus fadeInDown is-active transform-none">
  <div class="comment-single__avatar">
    <gs-skeleton-loader width="24" height="24" appearance="circle"></gs-skeleton-loader>
  </div>
  <div class="comment-single__content">
    <div class="comment-single__head">
      <div class="comment-single__name">
        <gs-skeleton-loader width="100"></gs-skeleton-loader>
      </div>
    </div>
    <div class="comment-single__desc">
      <gs-skeleton-loader></gs-skeleton-loader>
      <gs-skeleton-loader></gs-skeleton-loader>
    </div>
  </div>
</div>
