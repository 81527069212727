<div class="modal-header">
  <h5 class="modal-title">Share</h5>
  <button type="button" (click)="close()" data-dismiss="modal" aria-label="Close" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg>
    </span>
  </button>
</div>
<div class="modal-body" [formGroup]="shareForm">

  <div class="row">
    <div class="col-12">
      <h6>Open experience to:</h6>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="" value="" class="custom-control-input">
            <span class="custom-control-label">Modal Window</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="" value="" class="custom-control-input">
            <span class="custom-control-label">New Window</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h6>Display stories in</h6>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="" value="" class="custom-control-input">
            <span class="custom-control-label">Simpler List View (SEO friendly)</span>
          </label>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <label class="mb-0">
            <input type="radio" formControlName="" value="" class="custom-control-input">
            <span class="custom-control-label">Fancy Tile View (out of box, looks better)</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="addthis_inline_share_toolbox_aniv" #addthis></div>

  <div class="form-label-group mb-0 form-group-copy">
    <gs-input-clipboard label="Link" name="Link" placeholder="Link" formControlName="link"
      [formControl]="shareForm.controls.link"></gs-input-clipboard>
  </div>
</div>
