import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { ErrorHandlerService, ToastService } from '../services';

export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private errorHandlerService: ErrorHandlerService, private toastService: ToastService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                // retry(1), retry strategy
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (error.error) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        let err:any=error
                       // console.log('sadasd', err, err.error.result.errors[0].message)  
                       // this.errorHandlerService.errorFormServer = err.error.result.errors[0].message;
                        errorMessage = this.errorHandlerService.getServerMessage(error);
                        console.log("HttpErrorInterceptor -> errorMessage", errorMessage)
                        if (errorMessage) {
                            this.toastService.error(errorMessage);
                        }
                    }
                    return throwError(error);
                })
            )
    }
}