import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { CampaignEntity } from '../entities';
import { CampaignState } from '../states';

export const campaignAdapter: EntityAdapter<CampaignEntity> = createEntityAdapter<CampaignEntity>();

export const initialState: CampaignState = campaignAdapter.getInitialState({
    selectedId: undefined,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});