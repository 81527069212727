import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class LoadingPopupService {
    constructor(private spinnerService: NgxUiLoaderService) {

    }

    // Private functions

    // Public functions
    public show() {
        this.spinnerService.start();
    }

    public hide() {
        this.spinnerService.stop();
    }
}