import { Action } from '@ngrx/store';

import { LandingPageOutputApiModel } from '../../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetLandingPageInputApiModel, UpdateLandingPageInputApiModel, CreateLandingPageInputApiModel } from '../../models/api-models/input';


export enum ActionTypes {
    LOAD = '[Company Landing Page] Load',
    LOAD_SUCCESS = '[Company Landing Page] Load Success',
    LOAD_FAIL = '[Company Landing Page] Load Fail',

    UPDATE = '[Company Landing Page] Update',
    UPDATE_SUCCESS = '[Company Landing Page] Update Success',
    UPDATE_FAIL = '[Company Landing Page] Update Fail',

    LOAD_DETAIL = '[Company Landing Page] Load Detail',
    LOAD_DETAIL_SUCCESS = '[Company Landing Page] Load Detail Success',
    LOAD_DETAIL_FAIL = '[Company Landing Page] Load Detail Fail',

    CREATE = '[Company Landing Page] Add',
    CREATE_SUCCESS = '[Company Landing Page] Add Success',
    CREATE_FAIL = '[Company Landing Page] Add Fail',

    SUBMIT_CTA = '[Company Landing Page] Submit Cta',
    SUBMIT_CTA_SUCCESS = '[Company Landing Page] Submit Cta Success',
    SUBMIT_CTA_FAIL = '[Company Landing Page] Submit Cta Fail',

    ORDER_STORIES = '[Company Landing Page] Order Landing Page',
    ORDER_STORIES_SUCCESS = '[Company Landing Page] Order Landing Page Success',
    ORDER_STORIES_FAIL = '[Company Landing Page] Order Landing Page Fail',

    GET_ORDER_STORIES = '[Company Landing Page]  Get Order Landing Page',
    GET_ORDER_STORIES_SUCCESS = '[Company Landing Page] Get Order Landing Page Success',
    GET_ORDER_STORIES_FAIL = '[Company Landing Page] Get Order Landing Page Fail',

    RESET_STATE = '[Company Landing Page] Rest',
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public queryData: GetLandingPageInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<LandingPageOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class SubmitCta implements Action {
    readonly type = ActionTypes.SUBMIT_CTA;
  
    constructor(public companyId ,public spotlightId: any, public payload: any) { }
  }
  
  export class SubmitCtaSuccess implements Action {
    readonly type = ActionTypes.SUBMIT_CTA_SUCCESS;
  }
  
  export class SubmitCtaFailure implements Action {
    readonly type = ActionTypes.SUBMIT_CTA_FAIL;
  
    constructor(public error: any) { }
  }

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public id: number, public payload: UpdateLandingPageInputApiModel) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
    constructor(public response: any) { }
}

export class UpdateFail implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}

export class LoadDetail implements Action {
    readonly type = ActionTypes.LOAD_DETAIL;

    constructor(public companyId: number, public idOrSlug: number|string) { }
}

export class LoadDetailSuccess implements Action {
    readonly type = ActionTypes.LOAD_DETAIL_SUCCESS;

    constructor(public response: LandingPageOutputApiModel) { }
}

export class LoadDetailFail implements Action {
    readonly type = ActionTypes.LOAD_DETAIL_FAIL;

    constructor(public error: any) { }
}

export class Create implements Action {
    readonly type = ActionTypes.CREATE;

    constructor(public companyId:number, public payload: CreateLandingPageInputApiModel) { }
}

export class CreateSuccess implements Action {
    readonly type = ActionTypes.CREATE_SUCCESS;

    constructor(public response: LandingPageOutputApiModel) { }
}

export class CreateFail implements Action {
    readonly type = ActionTypes.CREATE_FAIL;

    constructor(public error: any) { }
}

export class OrderStories implements Action {
    readonly type = ActionTypes.ORDER_STORIES;

    constructor(public id:any, public payload: any) { }
}

export class OrderStoriesSuccess implements Action {
    readonly type = ActionTypes.ORDER_STORIES_SUCCESS;
}

export class OrderStoriesFail implements Action {
    readonly type = ActionTypes.ORDER_STORIES_FAIL;
}

export class GetOrderStories implements Action {
    readonly type = ActionTypes.GET_ORDER_STORIES;

    constructor(public id:any) { }
}

export class GetOrderStoriesSuccess implements Action {
    readonly type = ActionTypes.GET_ORDER_STORIES_SUCCESS;

    constructor(public response:any) { }
}

export class GetOrderStoriesFail implements Action {
    readonly type = ActionTypes.GET_ORDER_STORIES_FAIL;
}

export class Reset implements Action {
    readonly type = ActionTypes.RESET_STATE;
    constructor() { }
  }

export type Actions =
    Load | LoadSuccess | LoadFail |
    Update | UpdateSuccess | UpdateFail |
    LoadDetail | LoadDetailSuccess | LoadDetailFail |
    Create | CreateSuccess | CreateFail | OrderStories | OrderStoriesSuccess | OrderStoriesFail
    | GetOrderStoriesSuccess | GetOrderStories | GetOrderStoriesFail | Reset;
