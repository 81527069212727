<div [ngClass]="{'site-container': !isIframeMode}">
  <ng-container *ngIf="isBrowser">
    <app-external-header></app-external-header>
    <ng-container *ngIf="showHeaderboolean">
      <co-pb-cp-banner [txtsubmit]="submit_text_Color"  [btnsubmit]="submit_btn"  [showSubmitStory]="campaign?.show_submit_story_btn"></co-pb-cp-banner>
    </ng-container>
    <co-pb-cp-toolbar [campaign]="campaign"></co-pb-cp-toolbar>
    <ng-container *ngIf="showtagsboolean">
      <div class="container" *ngIf="campaign && frequenttags">
        <div class="d-flex justify-content-around mx-7 mobile-view contents hide-board_tags campaignboardstyle">
          <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(ourteams)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title" *ngIf="frequenttags">{{frequenttags[ourteams]?.header_title}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--impact">
                  <ng-container *ngIf="frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of frequenttags[ourteams]?.data">
                      <a (click)="filterTag(tag)" class="chip-item"
                        [ngStyle]="{'background': hexToRgbA(frequenttags[ourteams]?.color) ,'color':frequenttags[ourteams]?.color}">{{tag}}</a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of counter">
                      <a  class="onboarding__chips chip-item"></a>
                    </div>
                  </ng-container>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!frequenttags || !frequenttags[ourteams] || frequenttags[ourteams]?.data.length === 0">
                  Stories reveal trending skills and talents here.
                </div>
              </div>
            </div>
          </div>
          <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(weAppreciate)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title"*ngIf="frequenttags">{{frequenttags[weAppreciate]?.header_title}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--impact">
                  <ng-container *ngIf="frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of frequenttags[weAppreciate]?.data">
                      <a (click)="filterTag(tag)" class="chip-item"
                        [ngStyle]="{'background': hexToRgbA(frequenttags[weAppreciate]?.color) ,'color':frequenttags[weAppreciate]?.color}">{{tag}}</a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of counter">
                      <a  class="onboarding__chips chip-item"></a>
                    </div>
                  </ng-container>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!frequenttags || !frequenttags[weAppreciate] || frequenttags[weAppreciate]?.data.length === 0">
                  Stories reveal trending skills and talents here.
                </div>
              </div>
            </div>
          </div>
          <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(ourVibes)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title" *ngIf="frequenttags">{{frequenttags[ourVibes]?.header_title}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--purple" >
                  <ng-container *ngIf="frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of frequenttags[ourVibes]?.data">
                      <a (click)="filterTag(tag)" class="chip-item"
                        [ngStyle]="{'background': hexToRgbA(frequenttags[ourVibes]?.color) , 'color': frequenttags[ourVibes]?.color}">{{tag}}</a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of counter">
                      <a  class="onboarding__chips chip-item"></a>
                    </div>
                  </ng-container>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!frequenttags || !frequenttags[ourVibes] || frequenttags[ourVibes]?.data.length === 0">
                  How your crew is feeling on display here.
                </div>
              </div>
            </div>
          </div>
          <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(weValue)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title"*ngIf="frequenttags">{{frequenttags[weValue]?.header_title}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--green">
                  <ng-container *ngIf="frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of frequenttags[weValue]?.data">
                      <a (click)="filterTag(tag)" class="chip-item"
                        [ngStyle]="{'background': hexToRgbA(frequenttags[weValue]?.color) , 'color':frequenttags[weValue]?.color}">{{tag}}</a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of counter">
                      <a  class="onboarding__chips chip-item"></a>
                    </div>
                  </ng-container>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!frequenttags || !frequenttags[weValue] || frequenttags[weValue]?.data.length === 0">
                  The latest values in action featured here.
                </div>
              </div>
            </div>
          </div>
          <div class="px-2 mb-3 mb-md-0" *ngIf="showorhide(ourChallenges)">
            <div class="m-infor-boxes text-center text-lg-left">
              <div class="m-infor-boxes__title"*ngIf="frequenttags">{{frequenttags[ourChallenges]?.header_title}}</div>
              <div class="m-infor-boxes__content">
                <div class="l-chips l-chips--green">
                  <ng-container *ngIf="frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of frequenttags[ourChallenges]?.data">
                      <a (click)="filterTag(tag)" class="chip-item"
                        [ngStyle]="{'background': hexToRgbA(frequenttags[ourChallenges]?.color) , 'color':frequenttags[ourChallenges]?.color}">{{tag}}</a>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!frequenttags">
                    <div class="l-chips__item" *ngFor="let tag of counter">
                      <a  class="onboarding__chips chip-item"></a>
                    </div>
                  </ng-container>
                </div>
                <div class="l-chips text-center text-gray" ng-cloak
                  *ngIf="!frequenttags || !frequenttags[ourChallenges] || frequenttags[ourChallenges]?.data.length === 0">
                  The latest values in action featured here.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- <co-pb-cp-search-box [companyId]="companyId"></co-pb-cp-search-box> -->
    <!-- <co-pub-search-box [campaignId]="campaignid" [campaignSlug]="campaignSlug" ></co-pub-search-box> -->
    <div class="search_box__hidden_mobile">
      <co-pb-cp-search-box [companyId]="companyId"></co-pb-cp-search-box>
    </div>
   
    <co-pb-cp-result [txtsubmit]="submit_text_Color"  [btnsubmit]="submit_btn" [showSubmitStory]="campaign?.show_submit_story_btn" ></co-pb-cp-result>
  </ng-container>
 
</div>
<app-site-footer *ngIf="!isIframeMode"></app-site-footer>
