import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { appSettingActions } from '../actions';
import { AppSettingHttpService } from '@modules/shared/services';
import { LoadAppSettingOutputApiModel } from "@modules/shared/models/api-models/output";

@Injectable()
export class AppSettingEffects {
    
    loadAppSettingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<appSettingActions.LoadAppSetting>(appSettingActions.ActionTypes.LOAD_APP_SETTING),
        switchMap((action: appSettingActions.LoadAppSetting) => {
            return this.appSettingHttpService.getSSOSetting().pipe(
                map((response: LoadAppSettingOutputApiModel) => {
                    return new appSettingActions.LoadAppSettingSuccess(response)
                }),
                catchError((error: any) => {
                    return of(new appSettingActions.LoadAppSettingFailure(error))
                })
            )
        })
    ));

    constructor(
        private actions$: Actions,
        private appSettingHttpService: AppSettingHttpService,
        private router: Router
    ) { }
}
