import { Component, SimpleChanges, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';

import { ComponentBase } from '@framework/base/component';
import { StoryViewModel, StoryContributionViewModel } from '@modules/story/models/view-models';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { LikeInputApiModel } from '@modules/shared/models/api-models/input';
import { likeActions } from '@modules/shared/store/actions';
import { Constants } from '@modules/shared/constants';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';

@Component({
  selector: 'app-pb-contributor-item',
  templateUrl: './contributor-item.component.html',
  styleUrls: ['./contributor-item.component.css']
})
export class ContributorItemComponent extends ComponentBase {
  @Input() contribution: StoryContributionViewModel;
  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectStoryDetailSubscription: Subscription;
  private selectCurrentUserProfileSubscription: Subscription;
  private likeSuccessSubscription: Subscription;
  private unlikeSuccessSubscription: Subscription;

  private token: string;
  // Public variables
  public storyDetail: StoryViewModel;
  public currentUserProfile: UserProfileViewModel;
  public isHasPermissionAddTake: boolean = false;
  public isLikeProcessing: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(private store$: Store<CoreState>, private actions$: Actions, private router: Router, private activatedRoute: ActivatedRoute) {
    super();
    this.captureParams();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {
    if (this.contribution) {
      this.contribution = new StoryContributionViewModel(this.contribution);
    }
    this.checkPermission();
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
      this.checkPermission();
    });

    this.likeSuccessSubscription = this.actions$.pipe(
      ofType<likeActions.LikeSuccess>(likeActions.ActionTypes.LIKE_SUCCESS),
      filter((action) => {
        return this.isLikeProcessing === true;
      })
    ).subscribe((action) => {
      this.isLikeProcessing = false;
      this.contribution.likeId = action.response.id;
    });

    this.unlikeSuccessSubscription = this.actions$.pipe(
      ofType<likeActions.UnlikeSuccess>(likeActions.ActionTypes.UNLIKE_SUCCESS),
      filter((action) => {
        return this.isLikeProcessing === true;
      })
    ).subscribe((action) => {
      this.isLikeProcessing = false;
      this.contribution.likeId = null;
    });
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.likeSuccessSubscription.unsubscribe();
    this.unlikeSuccessSubscription.unsubscribe();
  }

  private checkPermission() {
    if (!this.currentUserProfile || !this.contribution) {
      this.isHasPermissionAddTake = false;
    } else {
      this.isLoggedIn = true;
      this.isHasPermissionAddTake = this.currentUserProfile.id === this.contribution.playerId;
    }
  }

  private captureParams() {
    this.token = this.activatedRoute.snapshot.params['token'];
  }
  // Public functions
  public tagsHtml() {
    let tagsHtml: string[] = this.contribution.data.tags.map((tag) => {
      return `<span class="tag-item type-tag" style="color: ${tag.color}">#${tag.label}</span>`;
    });
    let html: string = '';
    tagsHtml.forEach((tagHtml, index) => {
      if (index === 0) {
        html = tagHtml;
      } else {
        html += ` ${tagHtml}`;
      }
    });
    return html;
  }

  public toggleLike() {
    if (!this.contribution) return;

    this.isLikeProcessing = true;
    if (!this.contribution.isLiked) {
      // Like action
      const likeInputData: LikeInputApiModel = {
        resource_type: Constants.resourceType.contribution,
        resource_id: this.contribution.id
      };
      this.store$.dispatch(new likeActions.Like(likeInputData));
    } else {
      // Unlike action
      this.store$.dispatch(new likeActions.Unlike(this.contribution.likeId, Constants.resourceType.contribution));
    }
  }

  public addYourTake() {
    let url = `/story/${this.storyDetail.id}/contribution`;
    if (this.token) {
      url += `/${this.token}`;
    }
    this.router.navigate([url]);
  }

  public editContribution(){
    let url = `/contribution/${this.contribution.id}`;
    if (this.token) {
      url += `/${this.token}`;
    }
    this.router.navigate([url]);
  }
}
