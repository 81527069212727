import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { followActions } from '../actions';
import { FollowHttpService } from '../../services';
import { FollowOutputApiModel } from '@modules/shared/models/api-models/output';

@Injectable()
export class FollowEffects {
    
    followEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<followActions.Follow>(followActions.ActionTypes.FOLLOW),
        switchMap((action: followActions.Follow) => {
            return this.followHttpService.follow(action.payload).pipe(
                map((response) => {
                    response.resource_id = action.payload.resource_id;
                    response.resource_type = action.payload.resource_type;
                    return new followActions.FollowSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new followActions.FollowFailure(error));
                }))
        })
    ));

    
    unfollowEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<followActions.Unfollow>(followActions.ActionTypes.UNFOLLOW),
        switchMap((action: followActions.Unfollow) => {
            return this.followHttpService.unfollow(action.id, action.resourceType).pipe(
                map(() => {
                    const response: FollowOutputApiModel = {
                        id: action.id,
                        resource_id: action.resourceId,
                        resource_type: action.resourceType
                    };
                    return new followActions.UnfollowSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new followActions.UnfollowFailure(error));
                }))
        })
    ));

    constructor(
        private actions$: Actions,
        private followHttpService: FollowHttpService
    ) { }
}