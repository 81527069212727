export interface TestimonialAudienceSettingOutputApiModel {
    id: number;
    name: string;
    is_active: boolean;
}

export class TestimonialAudienceSettingOutputApiModel {
    constructor(data?: Partial<TestimonialAudienceSettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
