import { UserEmailOutputApiModel } from '@modules/user/models/output';

export interface UserEmailEntity {
  id: number;
  email: string;
  status: string;
}

export class UserEmailEntity {
  constructor(data?: UserEmailOutputApiModel) {
    if (data) {
      this.convertFormApi(data);
    }
  }

  private convertFormApi(data: UserEmailOutputApiModel) {
    this.id = data.id;
    this.email = data.email;
    this.status = data.status;
  }
}
