import { LandingPageEntity } from "../../store/entities";
import { LandingPageQueryObjectsModel } from "../commons";

export interface LandingPageButtonViewModel {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface LandingPageViewModel {
    id: number;
    title: string;
    authorName: string;
    createdAt: Date;
    hidden: boolean;
    archived: boolean;
    headerImage: string;
    avatarImage: string;
    slug: string;
    person: string;
    slogan: string;
    buttons: LandingPageButtonViewModel[];
    queryObjects: LandingPageQueryObjectsModel;
    isInboundStoriesEnabled: boolean;
    isPublic: boolean;
    companySlug: string;
    is_board_visible?: boolean;
    show_submit_story_btn?: boolean;
    enable_overlay?: any;
    cta?:any;
    cta_form_configs?: any
    is_footnote_visible?: boolean;
    jobIds?:any;
}

export class LandingPageViewModel {
    constructor(entity: LandingPageEntity) {
        if(entity){
            this.id = entity.id;
            this.title = entity.title;
            this.authorName = entity.author && entity.author.displayName || '';
            this.show_submit_story_btn = entity.show_submit_story_btn == 1 ? true : false;
            this.createdAt = entity.createdAt;
            this.hidden = entity.hidden;
            this.archived = entity.archived;
            this.headerImage = entity.headerImage;
            this.avatarImage = entity.avatarImage;
            this.slug = entity.slug;
            this.person = entity.person;
            this.is_board_visible = entity.is_board_visible
            this.slogan = entity.slogan;
            this.buttons = entity.buttons;
            this.queryObjects = entity.queryObjects;
            this.isInboundStoriesEnabled = entity.isInboundStoriesEnabled;
            this.isPublic = entity.isPublic;
            this.companySlug = entity.companySlug;
            this.enable_overlay = entity.enable_overlay
            this.cta = entity.cta
            this.cta_form_configs = entity.cta_form_configs;
            this.is_footnote_visible = entity.is_footnote_visible
        }
       
    }
}