import { Action } from '@ngrx/store';

export enum ActionsType {
    NAVIGATED = "[Router] Navigated",
}

export class Navigated implements Action {
    readonly type: string = ActionsType.NAVIGATED

    constructor(public pageName: string) { }
}


export type Actions = Navigated;