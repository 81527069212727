import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
    selector: 'input[numbersOnly]',
})
export class NumberOnlyDirective implements OnInit {
    constructor(private el: ElementRef) {

    }

    public ngOnInit() {
        this.el.nativeElement.onkeydown = (event: KeyboardEvent) => {
            const initalValue = event.key;
            const keyCode = event.keyCode || event.which;
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(keyCode) !== -1 ||
                // Allow: Ctrl+A
                (keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
                // Allow: Ctrl+C
                (keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
                // Allow: Ctrl+V
                (keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
                // Allow: Ctrl+X
                (keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
                // Allow: home, end, left, right
                (keyCode >= 35 && keyCode <= 39)) {
                return;
            }
            if (/^\d+$/g.test(initalValue) === false) {
                event.preventDefault();
            }
        };
    }
}