import { Component, SimpleChanges, ViewChild } from '@angular/core';

import { ComponentModalBase } from '@framework/base';
import { CropImageInitData } from '../../../models/common';
import { ImageCropperComponent } from '../image-cropper';
import { ImageCropperResult } from '@modules/shared/components/commons/image-cropper/models';

@Component({
  selector: 'app-crop-image-modal',
  templateUrl: './crop-image-modal.component.html',
  styleUrls: ['./crop-image-modal.component.scss']
})
export class CropImageModalComponent extends ComponentModalBase<CropImageInitData> {
  @ViewChild(ImageCropperComponent)
  imageCropperComponent: ImageCropperComponent
  // Private variables

  // Public variables

  constructor(
  ) {
    super();

  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }
  // Public functions
  public exportData() {
    this.imageCropperComponent.exportData().then((data: ImageCropperResult) => {
      this.onClose.emit(data.blob);
    });
  }

  public close() {
    this.onClose.emit(null);
  }
}
