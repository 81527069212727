import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ApprovalFlowHttpService } from '../services';
import { ApprovalFlowActions } from '../actions';
import { forkJoin } from 'rxjs';


@Injectable()
export class ApprovalFlowEffects {

    constructor(
        private actions$: Actions,
        private approvalFlowService: ApprovalFlowHttpService
    ) { }

    
    getApprovalFlow$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.GetListApproval>(ApprovalFlowActions.ActionTypes.GET_APPROVAL_LIST_USER),
        switchMap((action: ApprovalFlowActions.GetListApproval) => {
            return this.approvalFlowService.GetListApproval(action.companyId).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.GetListApprovalSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.GetListApprovalFail());
                }));
        })
    ));


    
    getComment$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.GetComment>(ApprovalFlowActions.ActionTypes.GET_COMMENT),
        switchMap((action: ApprovalFlowActions.GetComment) => {
            return this.approvalFlowService.GetComment(action.compnay_Id,action.response_Id).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.GetCommentSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.GetCommentFail());
                }));
        })
    ));

    
    getStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.Getstep>(ApprovalFlowActions.ActionTypes.GET_STEP),
        switchMap((action: ApprovalFlowActions.Getstep) => {
            return this.approvalFlowService.getStep(action.compnay_Id,action.campaign_id).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.GetstepSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.GetStepFail());
                }));
        })
    ));

  

    delteStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.DeleteStep>(ApprovalFlowActions.ActionTypes.DELETE_STEP),
        switchMap((action: ApprovalFlowActions.DeleteStep) => {
            return this.approvalFlowService.deleteStep(action.compnay_Id,action.campaign_id,action.steps_id).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.DeleteStepSuccess();
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.DeleteStepFail());
                }));
        })
    ));

    createLabels$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.CreateStep>(ApprovalFlowActions.ActionTypes.CREATE_STEP),
        switchMap((action: ApprovalFlowActions.CreateStep) => {
            return this.approvalFlowService.createStep(action.compnay_Id,action.campaign_id,action.payload).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.CreateStepSucces();
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.CreateStepFail());
                }));
        })
    ));

    
    updateComments$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.UpdateComment>(ApprovalFlowActions.ActionTypes.UPDATE_COMMENT),
        switchMap((action: ApprovalFlowActions.UpdateComment) => {
            return this.approvalFlowService.UpdateComment(action.companyId,action.response_Id,action.payload).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.UpdateCommentSuccess();
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.UpdateCommentFail());
                }));
        })
    ));   


    delteComment$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.DeleteComment>(ApprovalFlowActions.ActionTypes.DELETE_COMMENT),
        switchMap((action: ApprovalFlowActions.DeleteComment) => {
            return this.approvalFlowService.DeleteComment(action.compnay_Id,action.response_Id,action.comment_Id).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.DeleteCommentSuccess();
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.DeleteCommentFail());
                }));
        })
    ));

    createComment$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.CreateComment>(ApprovalFlowActions.ActionTypes.CREATE_COMMENT),
        switchMap((action: ApprovalFlowActions.CreateComment) => {
            return this.approvalFlowService.CreateComment(action.companyId,action.response_Id,action.payload).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.CreateCommentSuccess();
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.CreateCommentFail());
                }));
        })
    ));

    approveRejectStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.ApproveRejectStep>(ApprovalFlowActions.ActionTypes.APPROVE_REJECT_STEP),
        switchMap((action: ApprovalFlowActions.ApproveRejectStep) => {
            return this.approvalFlowService.ApproveRejectStep(action.companyId,action.responseId,action.payload).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.ApproveRejectStepSuccess();
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.ApproveRejectStepFail());
                }));
        })
    ));

    getUserList$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<ApprovalFlowActions.GetUserList>(ApprovalFlowActions.ActionTypes.USER_LIST),
        switchMap((action: ApprovalFlowActions.GetUserList) => {
            let parameter = ['user','master_admin','content_user','content_manager']
            const apiCalls = parameter.map(param => this.approvalFlowService.GetUserList(action.companyId,param));
            return forkJoin(apiCalls).pipe(
                map((respone) => {
                    return new ApprovalFlowActions.GetUserListSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new ApprovalFlowActions.GetUserListFail());
                }));
        })
    ));

}