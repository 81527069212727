import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { profilePublicActions } from '../actions';
import { ProfilePublicHttpService } from '../services';
import { ProfilePublicOutputApiModel } from '../models/api-models/output';

@Injectable()
export class ProfilePublicEffects {

    
    loadProfileEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profilePublicActions.Load>(profilePublicActions.ActionTypes.LOAD),
        switchMap((action: profilePublicActions.Load) => {
            return this.ProfilePublicHttpService.loadProfile(action.companyId).pipe(
                map((response: ProfilePublicOutputApiModel) => {
                    return new profilePublicActions.LoadSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new profilePublicActions.LoadFailure(error));
                })
            )
        })
    ));

    
    changeSlugEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profilePublicActions.ChangeSlug>(profilePublicActions.ActionTypes.CHANGE_SLUG),
        switchMap((action: profilePublicActions.ChangeSlug) => {
            return this.ProfilePublicHttpService.changeSlug(action.companyId, action.payload).pipe(
                map(() => {
                    return new profilePublicActions.ChangeSlugSuccess();
                }),
                catchError((error: any) => {
                    return of(new profilePublicActions.ChangeSlugFailure(error));
                }))
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<profilePublicActions.UpdateProfile>(profilePublicActions.ActionTypes.UPDATE_PROFILE),
        switchMap((action: profilePublicActions.UpdateProfile) => {
            return this.ProfilePublicHttpService.updateProfile(action.companyId, action.payload).pipe(
                map(() => {
                    return new profilePublicActions.UpdateProfileSuccess();
                }),
                catchError((error: any) => {
                    return of(new profilePublicActions.UpdateProfileFailure(error));
                }))
        })
    ));



    constructor(
        private actions$: Actions,
        private ProfilePublicHttpService: ProfilePublicHttpService) { }
}
