import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackDropService } from '@framework/services/back-drop.service';
import { Observable, Subscription } from 'rxjs';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { companyActions } from '@modules/company/store/actions';
import { RequestStoryComponent } from '../request-story';
import { ModalService } from '@framework/services';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as Saction from '@modules/company-admin/store/actions'

@Component({
  selector: 'button-story-actions',
  templateUrl: './button-story-actions.component.html',
  styleUrls: ['./button-story-actions.component.css']
})
export class ButtonStoryActionsComponent implements OnInit, OnDestroy {

  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentUserProfileSubscription: Subscription;

  private LoadButtonColorSuccessSubscriptin: Subscription;

  public subject: string = 'GoodSeeker story nudge for you about …';
  public body: string = 'Hi there - GoodSeeker is a great way for us to capture workplace stories ' +
    'quickly. I thought you would be the best person to share one about ...';

  public currentUserProfile: UserProfileViewModel;
  public isShow: boolean = false;
  includesCampaign: boolean = false;

  public submit_text_Color:any;
  public requet_text_Color:any
  public submit_btn:any;
  public requt_btn:any;

  constructor(
    private backDropService: BackDropService,
    private store$: Store<CoreState>,
    private actions$: Actions,
    private modalService: ModalService,
    private router: Router
  ) {
    this.subject = this.subject.split(' ').join('%20');
    this.body = this.body.split(' ').join('%20');
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.checkRoute(router.url);
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.checkRoute(event.url);
      });
  }

  checkRoute(route: string) {
    if (route.includes('/company/campaigns')) {
      this.includesCampaign = true;
    } else {
      this.includesCampaign = false;
    }
  }

  ngOnInit(): void {
    this.subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    if (this.isShow) {
      this.backDropService.hide();
    }
  }

  // Private Functions

  private subscribe() {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
      if (userProfile && userProfile.company && userProfile.company.id > 0) {
        this.store$.dispatch(new companyActions.LoadInfo(this.currentUserProfile.company.id));
        this.store$.dispatch(new Saction.storyActions.GetColor(this.currentUserProfile.company.id));
      }
    });

    this.LoadButtonColorSuccessSubscriptin = this.actions$.pipe(
      ofType<Saction.storyActions.GetColorSuccess>(Saction.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action:any) => {
      
       if(action && action.response && action.response && action.response.length){
          this.submit_text_Color = action.response.find(item => item.button == 10).color;
          this.requet_text_Color = action.response.find(item => item.button == 9).color;
          this.submit_btn = action.response.find(item => item.button == 7).color;
          this.requt_btn = action.response.find(item => item.button == 8).color;
       }
    });
  }

  private unsubscribe() {
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }

  //  Public Functions

  public toggleShow() {
    if (this.isShow) {
      this.backDropService.hide();
    } else {
      this.backDropService.show();
    }
    this.isShow = !this.isShow;
  }

  public closeBackDrop(isOutSide) {
    if (isOutSide && this.isShow) {
      this.backDropService.hide();
      this.isShow = false;
    }
  }

  requestStory() {
    this.modalService.showModal(RequestStoryComponent,null).then((saved: boolean) => { })
  }

  get currentUrl(): string {
    return encodeURI(btoa(this.router.url));
  }
}
