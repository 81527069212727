import { Component, ElementRef, Inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { StoryViewModel } from '@modules/story/models/view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { searchBoxActions } from '@modules/company/store/actions';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';
import { ModalService, SocialSharingService } from '@framework/services';
import { EmbedIframeContainerComponent } from "@modules/story/pages/private/components";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { CompanyHttpService } from "@modules/company/services";
import { environment } from '@app/env/environment';
import { Router } from '@angular/router';
import { storyActions } from '@modules/story/store/actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'co-pb-cp-toolbar',
  templateUrl: './landing-toolbar.component.html',
  styleUrls: ['./landing-toolbar.component.scss']
})
export class LandingToolbarComponent extends ComponentBase {
  @ViewChild("addthis") addthis: ElementRef;

  @Input() campaign:any
  // Private variables
  private selectSelectedCompany$: Observable<CompanyViewModel>;

  private selectSelectedCompanySubscription: Subscription;
  // Public variables
  public storyDetail: StoryViewModel;
  public isLoading: boolean = true;
  public AddtrackingsuccessSubscription: Subscription
  public selectedCompany: CompanyViewModel;
  public shareForm: UntypedFormGroup;
  public trackingid: string;
  private shareUrl: string;

  constructor(
    private store$: Store<CoreState>,
    private modalService: ModalService,
    private socialSharingService: SocialSharingService,
    private actions$: Actions,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    super();

    this.selectSelectedCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.initForm();
  }
  private initForm() {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl]
    });
  }
  // Life cycle hook
  protected onInit(): void {
    this.shareUrl = window.location.origin + this.router.url;
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(): void {
    if(this.campaign){
      this.getTracking();
    }

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
    if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectSelectedCompanySubscription = this.selectSelectedCompany$.subscribe((selectedCompany) => {
      if (selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.isLoading = false;
      }
      // this.addthis.nativeElement.setAttribute("data-url", this.shareUrl);
      if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
      this.shareForm.controls.link.setValue(this.shareUrl);
    });

    this.AddtrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.AddTrackingSuccess>(storyActions.ActionTypes.ADD_TRACKING_SUCCESS)
    ).subscribe(action => {
         this.trackingid = action.data[0].tracking_id
    });
  }

  private unsubscribe() {
    this.selectSelectedCompanySubscription.unsubscribe();
  }
  // Public functions
  public share() {
    this.modalService
      .showModal(ShareModalContainerComponent, {campaigntracking:this.trackingid})
      .then(() => { });
  }

  public getTracking(){
    var campaigntrack = []
    let trackobj ={
     "resource_id": this.campaign.id.toString(),
     "resource_type":"Campaign"
    }
    campaigntrack.push(trackobj)
    this.store$.dispatch(new storyActions.AddTracking(campaigntrack));
   }
}
