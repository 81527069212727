import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { FollowEntity } from '../entities';
import { FollowState } from '../states';

export const followAdapter: EntityAdapter<FollowEntity> = createEntityAdapter<FollowEntity>();

export const initialState: FollowState = followAdapter.getInitialState({
    isLoading: false,
    isLoaded: false
});