export interface DirectoryOutputApiModel {
  id: number,
  avatar: string,
  count_authored: number,
  count_involved: number,
  display_name: string,
  email: string,
  first_name: string,
  follow_id: number,
  last_name: string,
  slug: string
}

export class DirectoryOutputApiModel {
  constructor(init?: Partial<DirectoryOutputApiModel>) {
    Object.assign(this, init);
  }
}
