export interface ReportLeadsOutputApiModel {
    id: number;
    story_author: string;
    cta_text: string;
    lead_name: string;
    lead_email: string;
    cta_response: string;
    submitted: Date;
}

export class ReportLeadsOutputApiModel {
    constructor(data?: Partial<ReportLeadsOutputApiModel>) {
        if (data){
            Object.assign(this, data);
        }
    }
}


