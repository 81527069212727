import { UserProfileState } from '../states/user-profile.state';
import { userProfileActions } from '../actions';
import { initialState, userProfileAdapter } from '../adapters/user-profile.adapter';
import { UserProfileEntity } from '../entities';

export function userProfileReducer(state = initialState, action: userProfileActions.Actions): UserProfileState {
    switch (action.type) {

        case userProfileActions.ActionTypes.LOAD_CURRENT_PROFILE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        case userProfileActions.ActionTypes.LOAD_CURRENT_PROFILE_SUCCESS: {
            const currentUserProfile = new UserProfileEntity(action.response);
            const newState = Object.assign({}, state, userProfileAdapter.upsertOne(currentUserProfile, state),
                { currentUserProfileId: currentUserProfile.id }
            );
            return {
                ...newState,
                isLoading: false,
                isLoaded: true
            }
        }

        case userProfileActions.ActionTypes.LOAD_CURRENT_PROFILE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        }

        case userProfileActions.ActionTypes.LOAD_PUBLIC_PROFILE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        case userProfileActions.ActionTypes.LOAD_PUBLIC_PROFILE_SUCCESS: {
            const publicUserProfile = new UserProfileEntity(action.response);
            const newState = Object.assign({}, state, userProfileAdapter.upsertOne(publicUserProfile, state),
                { publicUserProfileId: publicUserProfile.id }
            );
            return {
                ...newState,
                selectedId: publicUserProfile.id,
                isLoading: false,
                isLoaded: true
            }
        }

        case userProfileActions.ActionTypes.UPDATE: {
            return {
                ...state,
                isUpdating: true,
            }
        }

        case userProfileActions.ActionTypes.UPDATE_SUCCESS: {
            return {
                ...state,
                isUpdating: false,
            }
        }

        case userProfileActions.ActionTypes.UPDATE_FAILURE: {
            return {
                ...state,
                isUpdating: false,
            }
        }

        default: {
            return state;
        }
    }
}