import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from '@app/env/environment';
import { MediaOriginEnum } from '../enums';

@Pipe({ name: 'imageCloudinary' })

export class ImageCloudinaryPipe implements PipeTransform {
    private blackList: string[] = ['https://source.unsplash.com'];

    constructor(private sanitizer: DomSanitizer) {

    }

    transform(value: string, saveIn?: MediaOriginEnum, width?: number, height?: number): any {
        if (value) {
            return this.generateImageSource(value, saveIn, width, height);
        }
        return '';
    }

    private generateImageSource(value: string, saveIn: MediaOriginEnum, width?: number, height?: number) {
        if (!value || this.blackList.findIndex(item => value.indexOf(item) > -1) > -1 || value.indexOf('http') === -1) return value;
        if (value.indexOf('blob') > -1) return value;

        let cdnSrc = '';

        if( value.indexOf('https://ui-avatars.com') >= 0) {
            return value;
        }

        switch (saveIn) {
            case MediaOriginEnum.Server:
                cdnSrc = this.serverCase(value, width, height);
                break;
            case MediaOriginEnum.Amazon:
                cdnSrc = this.amazonCase(value, width, height);
                break;
            case MediaOriginEnum.Cloudinary:
                cdnSrc = this.cloudinaryCase(value, width, height);
                break;
            default:
                cdnSrc = this.defaultCase(value, width, height);
                break;
        }

        return cdnSrc;
    }

    private serverCase(value: string, width?: number, height?: number): string {
        let src = `${environment.cloudinary.cdnImageRootUrl}/fetch/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}/${value}`;

        return src;
    }

    private amazonCase(value: string, width?: number, height?: number): string {
        const s3RelativePath = value.split(environment.cloudinary.s3RootUrl).pop();
        let src = `${environment.cloudinary.cdnImageRootUrl}/upload/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}/${environment.cloudinary.keyPrefix}${s3RelativePath}`;

        return src;
    }

    private cloudinaryCase(value: string, width?: number, height?: number): string {
        const oldCloudinaryRelativePath = environment.cloudinary.convertToNewCdnUrl(value).split(`${environment.cloudinary.cdnImageRootUrl}/upload`).pop();
        let src = `${environment.cloudinary.cdnImageRootUrl}/upload/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}${oldCloudinaryRelativePath}`;

        return src;
    }

    private defaultCase(value: string, width?: number, height?: number): string {
        let src = `${environment.cloudinary.cdnImageRootUrl}/fetch/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}/${value}`;
        return src;
    }
}
