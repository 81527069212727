import {Component, SimpleChanges, Inject, ViewChild, ElementRef} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { filter } from 'rxjs/operators';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { StoryViewModel } from '@modules/story/models/view-models';
import { SocialSharingService, ModalService, AuthService } from '@framework/services';
import { LikeInputApiModel, FollowInputApiModel } from '@modules/shared/models/api-models/input';
import { likeActions, followActions } from '@modules/shared/store/actions';
import { EmbedIframeContainerComponent } from '../embed-iframe-container';
import { DeleteStoryContainerComponent } from '../delete-story-container';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import { storyActions } from '@modules/story/store/actions';
import { ActivatedRoute } from '@angular/router';
import * as adminStoryActions from '@modules/company-admin/store/actions';
import { emailCustomizeSettingAction, testimonialActions } from '@modules/company-admin/store/actions';
import { EmailConfirmationModalComponent } from '@modules/shared/components';

@Component({
  selector: 'app-pb-story-toolbar',
  templateUrl: './story-toolbar.component.html',
  styleUrls: ['./story-toolbar.component.css']
})
export class StoryToolbarComponent extends ComponentBase {
  // @ViewChild("addthis") addthis: ElementRef;
  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectStatusDetail$: Observable<StoryLoadStatusViewModel>;

  private selectStoryDetailSubscription: Subscription;
  private selectCurrentUserProfileSubscription: Subscription;
  private likeSuccessSubscription: Subscription;
  private unlikeSuccessSubscription: Subscription;
  private followSuccessSubscription: Subscription;
  private unfollowSuccessSubscription: Subscription;
  private selectStatusDetailSubscription: Subscription;
  private  AddtrackingsuccessSubscription: Subscription;
  private sendAndDontSendEmailSubscription: Subscription;
  private resultList: any[] = [];

  public currentUserProfile: UserProfileViewModel;
  // Public variables
  public storyDetail: StoryViewModel;
  public trackingid: string;
  public isLoggedIn: boolean = false;
  public isAllowEdit: boolean = false;
  public isCanShare: boolean = false;
  public isLikeProcessing: boolean = false;
  public isFollowProcessing: boolean = false;
  public statusDetail: StoryLoadStatusViewModel;
  public shareForm: UntypedFormGroup;
  private shareUrl: string;
  public isLoggedInuser:any;
  public queryTrackingId: any;

  constructor(
    private store$: Store<CoreState>,
    private socialSharingService: SocialSharingService,
    private actions$: Actions,
    private authService: AuthService,
    private actiactivatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder) {
    super();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectStatusDetail$ = this.store$.select(storySelector.selectLoadStatus);
    this.initForm();
  }
  private initForm() {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl]
    });
  }
  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.actiactivatedRoute.queryParams.subscribe((res)=>{
      if(res && res.tr){
        this.queryTrackingId =res.tr;
      }
    })
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
    if(typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
      if(this.storyDetail && this.storyDetail.id){
        const currentUser = this.authService.cognito.currentUser();
        this.isLoggedInuser = this.authService.cognito.currentUser();
        if(currentUser){
          this.getTracking()
        }
  
      }
      this.checkPermission();
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
      this.checkPermission();
      const currentUser = this.authService.cognito.currentUser();
      if(currentUser){
        this.store$.dispatch(new emailCustomizeSettingAction.Load(this.currentUserProfile?.company?.id));}
      // this.addthis.nativeElement.setAttribute("data-url", this.shareUrl);
      // if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
      this.shareForm.controls.link.setValue(this.shareUrl);
    });

    this.sendAndDontSendEmailSubscription = this.actions$.pipe(
      ofType<emailCustomizeSettingAction.LoadSuccess>(emailCustomizeSettingAction.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result: any) => {
      if(result && result.response &&  result.response.length){
         this.resultList = result.response;}
    });


    this.likeSuccessSubscription = this.actions$.pipe(
      ofType<likeActions.LikeSuccess>(likeActions.ActionTypes.LIKE_SUCCESS),
      filter(() => {
        return this.isLikeProcessing === true;
      })
    ).subscribe((action) => {
      this.isLikeProcessing = false;
      this.storyDetail.likeId = action.response.id;
    });

    this.unlikeSuccessSubscription = this.actions$.pipe(
      ofType<likeActions.UnlikeSuccess>(likeActions.ActionTypes.UNLIKE_SUCCESS),
      filter(() => {
        return this.isLikeProcessing === true;
      })
    ).subscribe(() => {
      this.isLikeProcessing = false;
      this.storyDetail.likeId = null;
    });

    this.followSuccessSubscription = this.actions$.pipe(
      ofType<followActions.FollowSuccess>(followActions.ActionTypes.FOLLOW_SUCCESS)
    ).subscribe((action) => {
      this.isFollowProcessing = false;
      this.storyDetail.followId = action.response.id;
    });

    this.unfollowSuccessSubscription = this.actions$.pipe(
      ofType<followActions.UnfollowSuccess>(followActions.ActionTypes.UNFOLLOW_SUCCESS)
    ).subscribe(() => {
      this.isFollowProcessing = false;
      this.storyDetail.followId = null;
    });

    this.selectStatusDetailSubscription = this.selectStatusDetail$.subscribe((statusDetail) => {
      this.statusDetail = statusDetail;
    });


    this.AddtrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.AddTrackingSuccess>(storyActions.ActionTypes.ADD_TRACKING_SUCCESS)
    ).subscribe(action => {
         this.trackingid = action.data[0].tracking_id
    });
  
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.likeSuccessSubscription.unsubscribe();
    this.unlikeSuccessSubscription.unsubscribe();
    this.followSuccessSubscription.unsubscribe();
    this.unfollowSuccessSubscription.unsubscribe();
    this.selectStatusDetailSubscription.unsubscribe();
    this.sendAndDontSendEmailSubscription.unsubscribe();
  }

  private checkPermission() {
    this.isAllowEdit = (
      (this.storyDetail && this.storyDetail.author && this.storyDetail.author.authorId) && 
      (this.storyDetail && this.storyDetail.author.authorId) === (this.currentUserProfile && this.currentUserProfile.id)) || 
      (this.storyDetail && this.currentUserProfile && this.currentUserProfile.isCompanyAdmin && this.storyDetail.company && this.storyDetail.company.companyId === this.currentUserProfile.company.id);
    this.isCanShare = this.storyDetail && this.storyDetail.public;
    this.isLoggedIn = this.currentUserProfile && this.currentUserProfile.id > 0;
  }
  // Public functions
  public delete() {
    this.modalService.showModal(DeleteStoryContainerComponent, null);
  }

  public toggleLike() {
    if (!this.currentUserProfile) return;

    this.isLikeProcessing = true;
    if (!this.storyDetail.isLiked) {
      // Like action
      const likeInputData: LikeInputApiModel = {
        resource_type: 'story',
        resource_id: this.storyDetail.id
      };
      this.store$.dispatch(new likeActions.Like(likeInputData));
    } else {
      // Unlike action
      this.store$.dispatch(new likeActions.Unlike(this.storyDetail.likeId, 'story'));
    }
  }

  public toggleBookmark() {
    if (!this.currentUserProfile) return;

    this.isFollowProcessing = true;
    if (!this.storyDetail.isFollowed) {
      // Follow action
      const followInputData: FollowInputApiModel = {
        resource_type: 'story',
        resource_id: this.storyDetail.id
      };
      this.store$.dispatch(new followActions.Follow(followInputData));
    } else {
      // Unfollow action
      this.store$.dispatch(new followActions.Unfollow(this.storyDetail.followId, 'story', this.storyDetail.id));
    }
  }

  public share() {
    const currentUser = this.authService.cognito.currentUser();
    if(currentUser && this.queryTrackingId){
      this.modalService
      .showModal(ShareModalContainerComponent, {})
      .then(() => {});
    }
    else if(currentUser && !this.queryTrackingId) {
      this.modalService
      .showModal(ShareModalContainerComponent, {tracking:this.trackingid})
      .then(() => {});
    }
    else{
      this.modalService
      .showModal(ShareModalContainerComponent, {})
      .then(() => {});
    }

  }
  public getTracking(){
    var addTracking = []
    let trackobj ={
     "resource_id": this.storyDetail.id.toString(),
     "resource_type":"story"
    }
    addTracking.push(trackobj)
    this.store$.dispatch(new storyActions.AddTracking(addTracking));
   }

   approvePublic() {
    let obj;
    if (this.storyDetail.type == 'inbound') {
       obj = {
        subject: this.resultList.find(email => email.title == 'TO ALL IN A GUEST STORY - when their story is approved to be public').subject,
        body: this.resultList.find(email => email.title == 'TO ALL IN A GUEST STORY - when their story is approved to be public').body,
       }
    }else{
      obj = {
        subject: this.resultList.find(email => email.title == 'TO ALL IN A USER STORY - when their story is approved to be public').subject,
        body: this.resultList.find(email => email.title == 'TO ALL IN A USER STORY - when their story is approved to be public').body,
       }
    }
    this.modalService.showModal(EmailConfirmationModalComponent, obj, {class: 'modal-lg'}).then(result => {
      if (this.storyDetail.type == 'inbound') {
        this.store$.dispatch(new testimonialActions.TurnPublicOn(this.currentUserProfile.company.id, this.currentUserProfile.id, [this.storyDetail.id], result));
      } else {
        this.store$.dispatch(new adminStoryActions.storyActions.TurnPublicOn(this.currentUserProfile.company.id, this.currentUserProfile.id, [this.storyDetail.id], result));
      }
    })
}
}
