import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { ProfilePublicEntity } from '../entities';
import { ProfilePublicState } from '../states';

export const profilePublicAdapter: EntityAdapter<ProfilePublicEntity> = createEntityAdapter<ProfilePublicEntity>();

export const initialState: ProfilePublicState = profilePublicAdapter.getInitialState({
    currentCompanyProfileId: null,
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    isLoadingPublic: false,
    isLoadedPublic: false,
    isUpdating: false
});
