import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import { campaignActions, storyActions } from '../actions';
import { GetUniquelinkService, StoryHttpService } from '../services';
import { GetUniquelinkServiceForspotlightandCampaign } from '../services/get-unique-link-spotlight-campaign.http-service';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class StoryEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Load>(storyActions.ActionTypes.LOAD),
    switchMap((action: storyActions.Load) => {
      return this.StoryHttpService.load(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new storyActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadFail(error));
        }));
    })
  ));

  GetButtonColor$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.GetColor>(storyActions.ActionTypes.GET_BUTTON_COLOR),
    switchMap((action: storyActions.GetColor) => {
      return this.StoryHttpService.loadButtonColor(action.companyId).pipe(
        map((respone) => {
          return new storyActions.GetColorSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.GetColorFailure(error));
        }));
    })
  ));

  GetMasterSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.GetMaster>(storyActions.ActionTypes.GET_MASTER_ACCESS),
    switchMap((action: storyActions.GetMaster) => {
      return this.StoryHttpService.grantmasteraccess(action.companyId,action.payload).pipe(
        map((respone) => {
          return new storyActions.GetMasterSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.GetMasterFailure(error));
        }));
    })
  ));

  UpdateButtonColor$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.UpdateColor>(storyActions.ActionTypes.UPDATE_BUTTON_COLOR),
    switchMap((action: storyActions.UpdateColor) => {
      return this.StoryHttpService.updateButtonColor(action.companyId,action.id,action.payload).pipe(
        map((respone) => {
          return new storyActions.UpdateColorSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.UpdateColorFailure(error));
        }));
    })
  ));

  
  loadInternalRequestedffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadInternalRequested>(storyActions.ActionTypes.LOAD_INTERNAL_REQUESTED),
    switchMap((action: storyActions.LoadInternalRequested) => {
      return this.StoryHttpService.loadInternalRequested(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new storyActions.LoadInternalRequestedSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadInternalRequestedFail(error));
        }));
    })
  ));

  
  loadGuestRequestedEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadGuestRequested>(storyActions.ActionTypes.LOAD_GUEST_REQUESTED),
    switchMap((action: storyActions.LoadGuestRequested) => {
      return this.StoryHttpService.loadGuestRequeted(action.companyId, action.queryData).pipe(
        map((respone) => {
          return new storyActions.LoadGuestRequestedSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadGuestRequestedFail(error));
        }));
    })
  ));


  
  updateEColumnffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
      ofType<storyActions.UpdateColumn>(storyActions.ActionTypes.UPDATE_COLUMN_ORDER),
      switchMap((action: storyActions.UpdateColumn) => {
          return this.StoryHttpService.UpdateColumnOrdr(action.companyId, action.updateList).pipe(
              map(() => {
                  return new storyActions.UpdateColumnSuccess();
              }),
              catchError((error: any) => {
                  return of(new storyActions.UpdateColumnFailure(error));
              }))
      })
  ));
  
  getUniquelink$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.AddUniqueLink>(storyActions.ActionTypes.ADD_UNIQUE_LINK),
    switchMap((action: storyActions.AddUniqueLink) => {
      return this.UniqueLinkhttpService.getuniqueLink(action.payload,action.fromvideo).pipe(
        map((respone) => {
          return new storyActions.AddUniqueLinkSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.AddUniqueLinkFail(error));
        }));
    })
  ));

  
  getUniqueVideolink$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.AddUniqueVideoLink>(storyActions.ActionTypes.ADD_UNIQUE_VIDEO_LINK),
    switchMap((action: storyActions.AddUniqueVideoLink) => {
      return this.UniqueLinkhttpService.getuniqueVideoLink(action.payload,action.fromvideo).pipe(
        map((respone) => {
          return new storyActions.AddUniqueLinkVideoSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.AddUniqueLinkVideoFail(error));
        }));
    })
  ));
  
  makeActiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.MakeActive>(storyActions.ActionTypes.MAKE_ACTIVE),
    switchMap((action: storyActions.MakeActive) => {
      return this.StoryHttpService.makeActive(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new storyActions.MakeActiveSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.MakeActiveFail(error));
        }));
    })
  ));

  
  makeInactiveEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.MakeInactive>(storyActions.ActionTypes.MAKE_INACTIVE),
    switchMap((action: storyActions.MakeInactive) => {
      return this.StoryHttpService.makeInactive(action.companyId, action.adminId, action.ids, action.draft).pipe(
        map((respone) => {
          return new storyActions.MakeInactiveSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.MakeInactiveFail(error));
        }));
    })
  ));

  
  makePublicEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.TurnPublicOn>(storyActions.ActionTypes.TURN_PUBLIC_ON),
    mergeMap((action: storyActions.TurnPublicOn) => {
      return this.StoryHttpService.makePublic(action.companyId, action.adminId, action.ids, action.emailOption).pipe(
        map((respone) => {
          return new storyActions.TurnPublicOnSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.TurnPublicOnFail(error));
        }));
    })
  ));

  
  makeInternalEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.TurnPublicOff>(storyActions.ActionTypes.TURN_PUBLIC_OFF),
    mergeMap((action: storyActions.TurnPublicOff) => {
      return this.StoryHttpService.makeInternal(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new storyActions.TurnPublicOffSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.TurnPublicOffFail(error));
        }));
    })
  ));

  
  makeVisibleEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.TurnInternalOn>(storyActions.ActionTypes.TURN_INTERNAL_ON),
    switchMap((action: storyActions.TurnInternalOn) => {
      return this.StoryHttpService.makeVisible(action.companyId, action.ids).pipe(
        map((respone) => {
          return new storyActions.TurnInternalOnSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.TurnInternalOnFail(error));
        }));
    })
  ));

  
  makeInvisibleEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.TurnInternalOff>(storyActions.ActionTypes.TURN_INTERNAL_OFF),
    switchMap((action: storyActions.TurnInternalOff) => {
      return this.StoryHttpService.makeInvisible(action.companyId, action.ids).pipe(
        map((respone) => {
          return new storyActions.TurnInternalOffSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.TurnInternalOffFail(error));
        }));
    })
  ));

  
  linkToCampaignEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LinkToCampaign>(storyActions.ActionTypes.LINK_TO_CAMPAIGN),
    switchMap((action: storyActions.LinkToCampaign) => {
      return this.StoryHttpService.linkToCampaign(action.companyId, action.campaignId, action.id).pipe(
        map((respone) => {
          return new storyActions.LinkToCampaignSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.LinkToCampaignFail(error));
        }));
    })
  ));

  
  sendThankYouEmailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.SendThankYouEmail>(storyActions.ActionTypes.SEND_THANK_YOU_EMAIL),
    switchMap((action: storyActions.SendThankYouEmail) => {
      return this.StoryHttpService.sendThankYouEmail(action.storyId,action.emailTemplate).pipe(
        map((respone) => {
          return new storyActions.SendThankYouEmailSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.SendThankYouEmailFail(error));
        }));
    })
  ));

  
  updateReminderEmailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.UpdateReminder>(storyActions.ActionTypes.UPDATE_REMINDER_MAIL),
    switchMap((action: storyActions.UpdateReminder) => {
      return this.StoryHttpService.sendReminder(action.storyId,action.payload).pipe(
        map((respone) => {
          return new storyActions.UpdateReminderSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.UpdateReminderFail(error));
        }));
    })
  ));

  
  approvaleffects$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Approval>(storyActions.ActionTypes.TURN_APPROVAL_OFF),
    switchMap((action: storyActions.Approval) => {
      return this.StoryHttpService.approve(action.companyId, action.adminId, action.ids,action.sendEmail,action.sendTo,action.payload).pipe(
        map((respone) => {
          return new storyActions.ApprovalSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.ApprovalFail(error));
        }));
    })
  ));

  
  rejectEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Reject>(storyActions.ActionTypes.REJECT),
    mergeMap((action: storyActions.Reject) => {
      return this.StoryHttpService.reject(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new storyActions.RejectSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.RejectFail(error));
        }));
    })
  ));

  
  rejectSpotlightOnlyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.RejectSpotlightOnly>(storyActions.ActionTypes.REJECT_SPOTLIGHT_ONLY),
    mergeMap((action: storyActions.RejectSpotlightOnly) => {
      return this.StoryHttpService.rejectSpotlightOnly(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new storyActions.RejectSpotlightOnlySuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.RejectSpotlightOnlyFail(error));
        }));
    })
  ));

  
  approveSpotlightonlyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.ApproveSpotlightOnly>(storyActions.ActionTypes.APPROVE_SPOTLIGHT_ONLY),
    mergeMap((action: storyActions.ApproveSpotlightOnly) => {
      return this.StoryHttpService.approveSpotlightOnly(action.companyId, action.adminId, action.ids).pipe(
        map((respone) => {
          return new storyActions.ApproveSpotlightOnlySuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.ApproveSpotlightOnlyFail(error));
        }));
    })
  ));

  
  deleteCampaignStoryEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.DeleteCmapignandStory>(storyActions.ActionTypes.DELETE_CAMPAIGN_STORY),
    switchMap((action: storyActions.DeleteCmapignandStory) => {
      return this.StoryHttpService.deleteCampaignandStory(action.comanyid,action.campignid,action.deleteStory).pipe(
        map(() => {
          return new storyActions.DeleteCmapignandStorySuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.DeleteCmapignandStoryFailure(error));
        })
      );
    })
  ));

  
  deleteSpotlightEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.DeleteSpotlight>(storyActions.ActionTypes.DELETE_SPOTLIGHT),
    switchMap((action: storyActions.DeleteSpotlight) => {
      return this.StoryHttpService.deleteSpotlight(action.comanyid,action.spotlightId).pipe(
        map(() => {
          return new storyActions.DeleteSpotlightSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.DeleteSpotlightFailure(error));
        })
      );
    })
  ));

  
  getUniquelink$forSpotlightandCampaign: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.AddUniqueLinkSpotlightandCampaign>(storyActions.ActionTypes.ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN),
    switchMap((action: storyActions.AddUniqueLinkSpotlightandCampaign) => {
      return this.spotlightCampaignlinkuniquelinksvc.getuniqueLinkSpotlight(action.payload, action.fromSpptlight).pipe(
        map((respone) => {
          return new storyActions.AddUniqueLinkSpotlightandCampaignSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new storyActions.AddUniqueLinkSpotlightandCampaignFail(error));
        }));
    })
  ));

  exportStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<storyActions.ExportStories>(storyActions.ActionTypes.EXPORT_STORIES),
      switchMap(action => 
        this.StoryHttpService.exportStories(action.companyId, action.queryData).pipe(
          map(() => new storyActions.ExportStoriesSuccess()),
          catchError(error => of(new storyActions.ExportStoriesFailure(error)))
        )
      )
    )
  );

  exportInternalStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<storyActions.ExportInternalStories>(storyActions.ActionTypes.EXPORT_INTERNAL_STORIES),
      switchMap(action => 
        this.StoryHttpService.exportInternalStories(action.companyId, action.queryData).pipe(
          map(() => new storyActions.ExportInternalStoriesSuccess()),
          catchError(error => of(new storyActions.ExportInternalStoriesFailure(error)))
        )
      )
    )
  );

  exportGuestStories$ = createEffect(() =>
    this.actions$.pipe(
      ofType<storyActions.ExportGuestStories>(storyActions.ActionTypes.EXPORT_GUEST_STORIES),
      switchMap(action => 
        this.StoryHttpService.exportGuestStories(action.companyId, action.queryData).pipe(
          map(() => new storyActions.ExportGuestStoriesSuccess()),
          catchError(error => of(new storyActions.ExportGuestStoriesFailure(error)))
        )
      )
    )
  );

  

  constructor(
    private actions$: Actions,
    private StoryHttpService: StoryHttpService,
    private UniqueLinkhttpService: GetUniquelinkService,
    private spotlightCampaignlinkuniquelinksvc: GetUniquelinkServiceForspotlightandCampaign) { }
}
