import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetReportLeadsInputApiModel } from '../models/api-models/input';
import { ReportLeadsOutputApiModel } from '../models/api-models/output';

@Injectable()
export class StoryReportHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetReportLeadsInputApiModel) {
        return this.get(`/${companyId}/setting/storyreport/?${queryData.query}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public exportCSV(companyId: number, videoonly:boolean) {
        if(videoonly){
            return this.download(`/${companyId}/setting/storyreport/csv/?report_type=video`)
        }
        else{
            return this.download(`/${companyId}/setting/storyreport/csv/`)
        }
        
    }

}
