import { Component, Input, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CommentViewModel } from '@modules/shared/models';
import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { CommentInputApiModel, LikeInputApiModel } from '@modules/shared/models/api-models/input';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { commentActions, likeActions } from '@modules/shared/store/actions';
import { Constants } from '@modules/shared/constants';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';

@Component({
  selector: 'app-approver-comment-item',
  templateUrl: './approver-comment-item.component.html',
  styleUrls: ['./approver-comment-item.component.scss']
})
export class ApproverCommentItemComponent extends ComponentBase {
  @Input() comment: CommentViewModel;
  @Input() inbound:boolean = false;
  @Input() isMasterAdmin:boolean = false;
  // Private variables
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentUserProfileSubscription: Subscription;
  private likeSuccessSubscription: Subscription;
  private unlikeSuccessSubscription: Subscription;
  private updateCommentSubscription: Subscription;
  // Public variables
  public commentForm: UntypedFormGroup;
  public isLikeProcessing: boolean = false;
  public isEditing: boolean = false;
  public isAllowEdit: boolean = false;
  public currentUserProfile: UserProfileViewModel;
  public isUpdateProcessing: boolean = false;
  public isConfirmCanceling: boolean = false;
  public isActiveAnimation: boolean = false;

  constructor(private store$: Store<CoreState>, private formBuilder: UntypedFormBuilder, private actions$: Actions) {
    super();

    this.initForm();

    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.playAnimation();
  }
  protected onDestroy(): void {
    this.unscubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {
    this.checkPermission();
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
  
  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private initForm() {
    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required]
    });
  }

  private subscribe() {
    this.likeSuccessSubscription = this.actions$.pipe(
      ofType<likeActions.LikeSuccess>(likeActions.ActionTypes.LIKE_SUCCESS),
      filter((action) => {
        return this.isLikeProcessing === true;
      })
    ).subscribe((action) => {
      this.isLikeProcessing = false;
      this.comment.likeId = action.response.id;
    });

    this.unlikeSuccessSubscription = this.actions$.pipe(
      ofType<likeActions.UnlikeSuccess>(likeActions.ActionTypes.UNLIKE_SUCCESS),
      filter((action) => {
        return this.isLikeProcessing === true;
      })
    ).subscribe((action) => {
      this.isLikeProcessing = false;
      this.comment.likeId = null;
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
      this.checkPermission();
    });

    this.updateCommentSubscription = this.actions$.pipe(
      ofType<commentActions.UpdateSuccess>(commentActions.ActionTypes.UPDATE_SUCCESS),
      filter((action) => {
        return this.isUpdateProcessing === true;
      })
    ).subscribe((action) => {
      this.isUpdateProcessing = false;
      this.isEditing = false;
    });
  }

  private unscubscribe() {
    this.likeSuccessSubscription.unsubscribe();
    this.unlikeSuccessSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.updateCommentSubscription.unsubscribe();
  }

  private checkPermission() {
    if (!this.currentUserProfile || !this.comment) {
      this.isAllowEdit = false;
      return;
    }
    this.isAllowEdit = this.currentUserProfile.id === this.comment.author.authorId || (this.isMasterAdmin);
  }

  private delete() {
    if (!this.comment) return;

    this.store$.dispatch(new commentActions.Delete(this.comment.id, Constants.resourceType.story));
  }

  private playAnimation() {
    setTimeout(() => {
      this.isActiveAnimation = true;
    }, 0)
  }
  // Public functions
  public update() {
    if (!this.commentForm.valid || !this.comment || this.isUpdateProcessing) return;

    this.isUpdateProcessing = true;
    const updateData: Partial<CommentInputApiModel> = {
      payload: {
        content: this.commentForm.controls.content.value
      }
    };
    this.store$.dispatch(new commentActions.Update(this.comment.id, updateData));
  }

  public toggleLike() {
    if (!this.comment) return;

    this.isLikeProcessing = true;
    if (!this.comment.isLiked) {
      // Like action
      const likeInputData: LikeInputApiModel = {
        resource_type: Constants.resourceType.comment,
        resource_id: this.comment.id
      };
      this.store$.dispatch(new likeActions.Like(likeInputData));
    } else {
      // Unlike action
      this.store$.dispatch(new likeActions.Unlike(this.comment.likeId, Constants.resourceType.comment));
    }
  }

  public edit() {
    this.commentForm.controls.content.setValue(this.comment.content);
    this.isEditing = true;
  }

  public cancel() {
    this.isEditing = false;
    this.commentForm.controls.content.setValue(this.comment.content);
  }

  public deleteComfirm() {
    this.isConfirmCanceling = true;
  }

  public confirmYes() {
    this.isConfirmCanceling = false;
    this.delete();
  }

  public confirmNo() {
    this.isConfirmCanceling = false;
  }
}
