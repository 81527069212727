import { LandingPageOutputApiModel } from "../../models/api-models/output";

export interface LandingPageAuthorEntity {
    id: number;
    displayName: string;
}

export interface LandingPageButtonEntity {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface LandingPageEntity {
    id: number;
    title: string;
    author: LandingPageAuthorEntity;
    createdAt: Date;
    hidden: boolean;
    archived: boolean;
    headerImage: string;
    avatarImage: string;
    slug: string;
    person: string;
    slogan: string;
    buttons: LandingPageButtonEntity[];
    queryObjects: any;
    isInboundStoriesEnabled: boolean;
    isPublic: boolean;
    companySlug: string;
    is_board_visible?: boolean;
    is_footnote_visible?: boolean;
    show_submit_story_btn:any;
    enable_overlay?: any;
    cta?: any;
    cta_form_configs?: any;
}

export class LandingPageEntity {
    constructor(data?: LandingPageOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: LandingPageOutputApiModel) {
        this.id = data.id;
        this.title = data.title;
        if (data.author) {
            this.author = {
                id: data.author.id,
                displayName: data.author.display_name
            };
        }
        this.show_submit_story_btn = data.show_submit_story_btn == 1 ? true : false;
        this.createdAt = data.time_created;
        this.hidden = data.hidden;
        this.is_board_visible = data.is_board_visible;
        this.is_footnote_visible = data.is_footnote_visible
        this.archived = data.archived;
        this.headerImage = data.header_image;
        this.avatarImage = data.avatar_image;
        this.enable_overlay = data.enable_overlay
        this.slug = data.slug;
        this.cta = data.cta
        if (data.buttons) {
            this.buttons = data.buttons.map((button) => {
                return {
                    label: button.label,
                    url: button.url,
                    foreground: button.foreground,
                    background: button.background,
                    isSelected: button.is_selected
                };
            });
        }
        this.person = data.person;
        this.slogan = data.slogan;
        this.queryObjects = data.query_objects;
        this.isInboundStoriesEnabled = data.allow_inbound_stories == 1 ? true : false;
        this.isPublic = data.is_public;
        this.companySlug = data.company_id_slug;
        this.cta_form_configs = data.cta_form_configs
    }

}
