import { UserStoryStatisticsViewModel, UserStoryFrequentTagsViewModel, UserBadgeViewModel } from '../models';
import { LoadUserProfileOutputApiModel } from '@modules/user/models/output';

export interface CompanyEntity {
    id: number;
    name: string;
    email: string;
    createdAt: Date;
    logo: string;
    slug: string;
    isPublic: boolean;
}

export interface UserProfileEntity {
    id: number;
    displayName: string;
    firstName: string;
    lastName: string;
    avatar: string;
    header: string;
    slug: string;
    email: string;
    profileTwitter: string;
    profileLinkedin: string;
    profileFacebook: string;
    workPurpose: string;
    followId: number;
    title: string;
    company: CompanyEntity;
    badge?: UserBadgeViewModel;
    storyStatistics?: UserStoryStatisticsViewModel;
    storyFrequentTags?: UserStoryFrequentTagsViewModel[];
    adminCompanies: number[];
    workLocation: string;
    workAttributes: any;
    followEnable: boolean;
    public: boolean;
    storyInviteEnable: boolean;
    namePublic: boolean;
    storyPoint: number;
    peerPoint: number;
    status: number;
    admin_role?:number
    isApprovedOutsider: boolean;
    signupDate: Date;
}

export class UserProfileEntity {
    constructor(data?: LoadUserProfileOutputApiModel) {
        if (data) {
            this.convertFromApi(data);
        }
    }

    private convertFromApi(data: LoadUserProfileOutputApiModel) {
        this.id = data.id;
        this.signupDate = data.signed_up_at;
        this.isApprovedOutsider = data.is_approved_outsider === 0 ? false : true;
        this.displayName = data.display_name;
        this.admin_role = data.admin_role
        this.firstName = data.first_name;
        this.lastName = data.last_name;
        this.avatar = data.avatar;
        this.header = data.bg_header;
        this.slug = data.slug;
        this.email = data.email;
        this.profileTwitter = data.profile_twitter;
        this.profileLinkedin = data.profile_linkedin;
        this.profileFacebook = data.profile_facebook;
        this.workPurpose = data.work_purpose;
        this.followId = data.follow_id;
        this.title = data.title;
        this.workLocation = data.work_location;
        this.workAttributes = data.work_attributes;
        this.followEnable = data.follow_enable;
        this.public = data.public;
        this.storyInviteEnable = data.story_invite_enable;
        this.namePublic = data.name_public;
        this.storyPoint = data.user_points;
        this.peerPoint = data.company.avg_points;
        this.status = data.status;
        if (data.company) {
            this.company = {
                id: data.company.company_id,
                name: data.company.company_name,
                createdAt: data.company.time_created,
                email: data.company.company_email,
                logo: data.company.avatar,
                slug: data.company.slug,
                isPublic: data.company.is_public
            };
        }

        this.adminCompanies = data.admin_companies;
        this.public = data.public;
        if (data.badge) {
            this.badge = new UserBadgeViewModel({
                id: data.badge.id,
                image: data.badge.image,
                name: data.badge.name,
                text: data.badge.text,
                color: data.badge.color,
                givenCase: data.badge.given_case,
            });
        }

        if (data.story_statistics) {
            this.storyStatistics = new UserStoryStatisticsViewModel({
                total: data.story_statistics.total,
                given: data.story_statistics.given,
                received: data.story_statistics.received,
                peopleNamed: data.story_statistics.people_named,
                peopleAdded: data.story_statistics.people_added
            });
        }

        if (data.frequent_tags) {
            this.storyFrequentTags = Object.keys(data.frequent_tags).map(index => {
                const item = data.frequent_tags[index];
                return new UserStoryFrequentTagsViewModel({
                    id: Number(index),
                    title: item.title,
                    color: item.color,
                    data: item.data,
                    sort: item.sort,
                    is_header_part:item.is_header_part,
                    header_title:item.header_title
                });
            });
        }

    }
}
