import { SearchBoxState, initialSearchBoxState } from '../states';
import { searchBoxActions } from '../actions';


export function searchBoxReducer(state = initialSearchBoxState, action: searchBoxActions.Actions): SearchBoxState {
    switch (action.type) {

        case searchBoxActions.ActionTypes.SHOW: {
            return {
                ...state,
                isShow: true
            }
        }

        case searchBoxActions.ActionTypes.HIDE: {
            return {
                ...state,
                isShow: false
            }
        }

        case searchBoxActions.ActionTypes.CLEAR: {
            return {
                ...state,
                isSearching: false
            }
        }

        case searchBoxActions.ActionTypes.SEARCH: {

            return {

                ...state,
                isSearching: true
            }
        }

        case searchBoxActions.ActionTypes.SEARCHPAYLOAD: {
            state['searchpayload']=action.tagPayload

            return {

                ...state,
                isSearching: true
            }
        }

        case searchBoxActions.ActionTypes.ResetState: {
            return {
                ...initialSearchBoxState
            }
        }

        default: {
            return state;
        }
    }
}