<div class="modal-header">
    <h5 class="modal-title">Send Notice to Users</h5>
    <button type="button" (click)="noemailtoall()" class="close"><span aria-hidden="true">
        <svg role="img">
          <use xlink:href="#icon-close"></use>
        </svg></span></button>
  </div>
  
  <div class="modal-body" [formGroup]="approvalConfigForm">
    <div class="row ml-2 mb-4">
      <span class="send__button">Send To</span> 
       <div class="col-3 ml-3">
           <div class="custom-control custom-radio">
               <label class="mb-0">
                   <input type="radio"  formControlName="sendTo" value="1"
                       class="custom-control-input">
                   <span class="custom-control-label" style="white-space: nowrap;">All Users</span>
               </label>
           </div>
       </div>
       <div class="col-3 ml-3">
           <div class="custom-control custom-radio">
               <label class="mb-0">
                   <input type="radio"  formControlName="sendTo" value="2"
                       class="custom-control-input">
                   <span class="custom-control-label">Only People Named</span>
               </label>
           </div>
       </div>
   </div>
   <div class="row">
    <div class="col-12">
      <gs-input-material label="Subject" name="subject" placeholder="Subject" formControlName="subject" [formControl]="approvalConfigForm.controls.subject" type="text"></gs-input-material>
      <gs-area-wysiwyg label="body" name="body" rows="10" placeholder="body" formControlName="body" [formControl]="approvalConfigForm.controls.body" type="text"></gs-area-wysiwyg>
    </div>
  </div>
  </div>
  
  <div class="modal-footer">
    <button (click)="noemailtoall()" [disabled]="isSendMail" type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width"><i *ngIf="isSendMail" class="fas fa-spinner fa-pulse"></i> Don't Send</button>
    <button (click)="yesemailtoall()" [disabled]="isSendMail" type="button" class="btn btn-pink min-width"> 
        <i *ngIf="isSendMail" class="fas fa-spinner fa-pulse"></i>Send Now
    </button>
  </div>
