import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ImageGallerySettingHttpService } from '../services';
import { imageGalleryActions } from '../actions';

@Injectable()
export class ImageGallerySettingEffects {

    constructor(
        private actions$: Actions,
        private imageGallerySettingHttpService: ImageGallerySettingHttpService
    ) { }

   
    loadImagesEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<imageGalleryActions.LoadImages>(imageGalleryActions.ActionTypes.LOAD_IMAGES),
        switchMap((action: imageGalleryActions.LoadImages) => {
            return this.imageGallerySettingHttpService.loadImages(action.companyId).pipe(
                map((response) => {
                    return new imageGalleryActions.LoadImagesSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new imageGalleryActions.LoadImagesFail(error));
                }));
        })
    ));

   
    loadConfigEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<imageGalleryActions.LoadConfig>(imageGalleryActions.ActionTypes.LOAD_CONFIG),
        switchMap((action: imageGalleryActions.LoadConfig) => {
            return this.imageGallerySettingHttpService.loadConfig(action.companyId).pipe(
                map((response) => {
                    return new imageGalleryActions.LoadConfigSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new imageGalleryActions.LoadConfigFail(error));
                }));
        })
    ));


   
    updateImageStatusEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<imageGalleryActions.UpdateImageStatus>(imageGalleryActions.ActionTypes.UPDATE_IMAGE_STATUS),
        switchMap((action: imageGalleryActions.UpdateImageStatus) => {
            return this.imageGallerySettingHttpService.updateImageStatus(action.companyId, action.imageId, action.payload).pipe(
                map(() => {
                    return new imageGalleryActions.UpdateImageStatusSuccess();
                }),
                catchError((error: any) => {
                    return of(new imageGalleryActions.UpdateImageStatusFailure(error));
                }))
        })
    ));

   
    updateConfigEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<imageGalleryActions.UpdateConfig>(imageGalleryActions.ActionTypes.UPDATE_CONFIG),
        switchMap((action: imageGalleryActions.UpdateConfig) => {
            return this.imageGallerySettingHttpService.updateConfig(action.companyId, action.payload).pipe(
                map(() => {
                    return new imageGalleryActions.UpdateConfigSuccess();
                }),
                catchError((error: any) => {
                    return of(new imageGalleryActions.UpdateConfigFailure(error));
                }))
        })
    ));

   
    addEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<imageGalleryActions.Add>(imageGalleryActions.ActionTypes.ADD),
        switchMap((action: imageGalleryActions.Add) => {
            return this.imageGallerySettingHttpService.addImages(action.companyId, action.payload).pipe(
                map(() => {
                    return new imageGalleryActions.AddSuccess();
                }),
                catchError((error: any) => {
                    return of(new imageGalleryActions.AddFailure(error));
                }))
        })
    ));

   
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<imageGalleryActions.Delete>(imageGalleryActions.ActionTypes.DELETE),
        switchMap((action: imageGalleryActions.Delete) => {
            return this.imageGallerySettingHttpService.deleteImage(action.companyId, action.imageId, action.payload).pipe(
                map(() => {
                    return new imageGalleryActions.DeleteSuccess();
                }),
                catchError((error: any) => {
                    return of(new imageGalleryActions.DeleteFailure(error));
                }))
        })
    ));
}