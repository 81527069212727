import { Action } from '@ngrx/store';

import { GetProfilePublicInputApiModel, UpdateProfilePublicInputApiModel } from '@modules/company-admin/store/models/api-models/input';
import { UpdateSlugInputApiModel } from '@modules/company-admin/store/models/api-models/input';
import { ProfilePublicOutputApiModel } from '../models/api-models/output';

export enum ActionTypes {
  LOAD = '[Company Profile] Load',
  LOAD_SUCCESS = '[Company Profile] Load Success',
  LOAD_FAILURE = '[Company Profile] Load Failure',

  UPDATE = '[Company Profile] Update',
  UPDATE_SUCCESS = '[Company Profile] Update Success',
  UPDATE_FAILURE = '[Company Profile] Update Failure',

  CHANGE_SLUG = '[Company Profile] Change Slug',
  CHANGE_SLUG_SUCCESS = '[Company Profile] Change Slug Success',
  CHANGE_SLUG_FAILURE = '[Company Profile] Change Slug Failure',

  UPDATE_PROFILE = '[Company Profile] Update Profile',
  UPDATE_PROFILE_SUCCESS = '[Company Profile] Update Profile Success',
  UPDATE_PROFILE_FAILURE = '[Company Profile] Update Profile Failure'
};


export class Load implements Action {
  readonly type = ActionTypes.LOAD;
  payload: Partial<GetProfilePublicInputApiModel>;

  constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public response: ProfilePublicOutputApiModel) { }
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public error: any) { }
}

export class Update implements Action {
  readonly type = ActionTypes.UPDATE;

  constructor(public payload: Partial<UpdateProfilePublicInputApiModel>) { }
}

export class UpdateSuccess implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;

  constructor(public error: any) { }
}

export class ChangeSlug implements Action {
  readonly type = ActionTypes.CHANGE_SLUG;

  constructor(public companyId: number, public payload: Partial<UpdateProfilePublicInputApiModel>) {
  }
}

export class ChangeSlugSuccess implements Action {
  readonly type = ActionTypes.CHANGE_SLUG_SUCCESS;
}

export class ChangeSlugFailure implements Action {
  readonly type = ActionTypes.CHANGE_SLUG_FAILURE;

  constructor(public error: any) { }
}

export class UpdateProfile implements Action {
  readonly type = ActionTypes.UPDATE_PROFILE;

  constructor(public companyId: number, public payload: Partial<UpdateProfilePublicInputApiModel>) {
  }
}

export class UpdateProfileSuccess implements Action {
  readonly type = ActionTypes.UPDATE_PROFILE_SUCCESS;
}

export class UpdateProfileFailure implements Action {
  readonly type = ActionTypes.UPDATE_PROFILE_FAILURE;

  constructor(public error: any) { }
}



export type Actions = Load
  | LoadSuccess
  | LoadFailure
  | Update
  | UpdateSuccess
  | UpdateFailure
  | ChangeSlug
  | ChangeSlugSuccess
  | ChangeSlugFailure
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileFailure;
