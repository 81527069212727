import { Component, SimpleChanges, Input } from '@angular/core';
import { ComponentBase } from '@framework/base/component';

@Component({
  selector: 'gs-skeleton-loader',
  templateUrl: './gs-skeleton-loader.component.html',
  styleUrls: ['./gs-skeleton-loader.component.css']
})
export class GsSkeletonLoaderComponent extends ComponentBase {
  // Input data
  @Input() count: number = 1;
  @Input() appearance: string;
  @Input() theme: any;
  @Input() width: number;
  @Input() height: number;
  @Input() fromVideo:boolean = false
  @Input() videoStatusText: any = 'Uploading'
  // Private variables

  // Public variables

  constructor() {
    super();
  }

  // Life cyle hooks
  protected onInit(): void {

  }
  protected onDestroy(): void {

  }
  protected onChanges(changes: SimpleChanges): void {
    this.theme = this.theme || {};
    this.theme.width = `${this.width}px`;
    this.theme.height = `${this.height}px`;
    this.videoStatusText = this.videoStatusText;
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions

  // Public functions

}
