import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { TestimonialSettingOutputApiModel, QuestionOptionOutputApiModel } from '../models/api-models/output';
import { UpdateTestimonialSettingInputApiModel, UpdateQuestionOptionInputApiModel, UpdateStepTagSettingInputApiModel } from '../models/api-models/input';

@Injectable()
export class TestimonialSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/testimonials/options/`).pipe(
            map((response) => {
                return new TestimonialSettingOutputApiModel(response);
            })
        )
    }

    public loadOption(companyId: number, questionId: number) {
        return this.get(`/${companyId}/setting/testimonials/options/${questionId}/values/`).pipe(
            map((response: QuestionOptionOutputApiModel[]) => {
                return response;
            })
        )
    }

    public update(companyId: number, payload: Partial<UpdateTestimonialSettingInputApiModel>) {
        return this.patch(`/${companyId}/setting/testimonials/options/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public addOption(companyId: number, questionId: number, payload: Partial<UpdateQuestionOptionInputApiModel>) {
        return this.post(`/${companyId}/setting/testimonials/options/${questionId}/values/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateOption(companyId: number, questionId: number, optionId: number, payload: Partial<UpdateQuestionOptionInputApiModel>) {
        return this.patch(`/${companyId}/setting/testimonials/options/${questionId}/values/${optionId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteOption(companyId: number, questionId: number, optionId: number) {
        return this.delete(`/${companyId}/setting/testimonials/options/${questionId}/values/${optionId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
