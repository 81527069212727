<div class="modal-header pb-0">
  <h4 class="modal-title">Embed Testimonial Button</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>
<div class="modal-body" [formGroup]="embedForm">

  <div class="option-wrapper embed-sub-wrapper mb-4 p-3" *ngIf="!IsScriptCreated">
    <h6>Button Name And Font Size</h6>
    <div class="row">
      <div class="col-3">  
            <gs-input-material formControlName="buttonText" [formControl]="embedForm.controls.buttonText" label="Button Text"
            type="text">
          </gs-input-material>
      </div>
      <div class="col-3 ml-7">
        <gs-input-material formControlName="fontsize" [formControl]="embedForm.controls.fontsize" label="Font"
          type="number">
        </gs-input-material>
      </div>
      <div class="col-1 iFrame-value">
        <select formControlName="fontmeasurelist" class="box">
          <option value="px">Px</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    </div>
    <h6>Background Color and Text Color</h6>
    <div class="row">
        <div class="col-3">
            <!-- <gs-input-color-picker  formControlName="backgroundColor" [formControl]="embedForm.controls.backgroundColor" label="Background" name="BackgroundColor" type="text"></gs-input-color-picker> -->
            <div class="color__container" >
              <div
             
              (ngModelChange)="onBackgroundColorChange($event)"
                ngx-colors-trigger
                class="color_picker-testimonial"
                [style.background]="background_color"
                [(ngModel)]="background_color"
                [ngModelOptions]="{standalone: true}"
              ></div>
            </div>
        </div>
        <div class="col-3 ml-7">
          <!-- <gs-input-color-picker  formControlName="textColor" [formControl]="embedForm.controls.textColor" label="Text Color" name="Textcolor" type="text"></gs-input-color-picker> -->
          <div class="color__container" >
            <div
           
            (ngModelChange)="onTextColorChange($event)"
              ngx-colors-trigger
              class="color_picker-testimonial"
              [style.background]="color"
              [(ngModel)]="color"
              [ngModelOptions]="{standalone: true}"
            ></div>
          </div>
        </div>
    
    </div>

    <h6>Diamensions</h6>
    <div class="row">
      <div class="col-3">
        <gs-input-material formControlName="width" [formControl]="embedForm.controls.width" label="Width"
          type="number">
        </gs-input-material>
      </div>
      <div class="col-1 iFrame-value">
        <select formControlName="widthList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
      <div class="col-3">
        <gs-input-material formControlName="height" [formControl]="embedForm.controls.height" label="Height"
          type="number">
        </gs-input-material>
      </div>
      <div class="col-1 iFrame-value">
        <select formControlName="heightList" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>
    
    </div>
    <h6>Allignment</h6>
    <div class="row">
      <div class="col-3">
        <gs-input-material formControlName="text_align" [formControl]="embedForm.controls.text_align" label="Text Align"
          type="text">
        </gs-input-material>
      </div> 
      <div class="col-3 ml-7">
        <gs-input-material formControlName="border_radius" [formControl]="embedForm.controls.border_radius" label="Border Radius"
          type="number">
        </gs-input-material>
      </div>
      <div class="col-1 iFrame-value">
        <select formControlName="bordervalue" class="box">
          <option value="px">Px</option>
          <option value="%">%</option>
          <option value="rem">rem</option>
          <option value="vh">vh</option>
        </select>
      </div>   
    </div>

    <div class="row">

      <div class="col">
        <gs-area-material
        label="" name="" placeholder="" rows="2"
        cssClass="spacing-lg" formControlName="customStyleCode" [formControl]="embedForm.controls.customStyleCode">
    </gs-area-material>
      </div>
    </div>
  </div>

  <div class="radio-wrapper embed-sub-wrapper mb-4 p-3" *ngIf="!IsScriptCreated">
    <div class="row">
      <div class="col-12">
        <h6>Open In</h6>
        <div class="row">
          <div class="col-6">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="selectRadio" value="_self" class="custom-control-input">
                  <span class="custom-control-label">Current Window</span>
                </label>
              </div>
          </div>
          <div class="col-6">
              <div class="custom-control custom-radio">
                <label class="mb-0">
                  <input type="radio" formControlName="selectRadio" value="_blank" class="custom-control-input">
                  <span class="custom-control-label">Popup</span>
                </label>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="option-wrapper embed-sub-wrapper mb-4 p-3" *ngIf="!IsScriptCreated">
    <div class="row">
      <div class="col-12" [ngStyle]="{'text-align':text_align}">
        <a type="button" id="embed_btn" target="_self" href="http://localhost:4200/t/goodseeker3/testimonial/create" >
          {{embedForm.controls.buttonText.value}}
           </a>
      </div>
    </div>
  </div>

  <div class="button-wrapper text-center" *ngIf="!IsScriptCreated">
    <button (click)="getScript()" type="button" class="btn btn-pink min-width">
      Get Script
    </button>
  </div>

  <div class="code-wrapper" *ngIf="IsScriptCreated">
    <h6>Place the below code inside your <span>&lt;body&gt;</span> tag.</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="bodyCode"
        [formControl]="embedForm.controls.bodyCode"></gs-input-clipboard>
    </div>
    <h6>Place the below just before the ending <span>&lt;/body&gt;</span> tag.</h6>
    <div class="form-label-group form-group-copy nolabel">
      <gs-textarea-clipboard rows="6" name="headerCode" placeholder="Header Code" formControlName="headerCode"
        [formControl]="embedForm.controls.headerCode"></gs-textarea-clipboard>
    </div>
  </div>
</div>
