export interface ResponsePaginationApiModel<TResult, TExtendData = any> {
    count: number;
    next: string;
    prev: string;
    result: TResult[];
    extended_data: TExtendData;
    frequent_tags?: any;
    internal_tags?: any;
}

export class ResponsePaginationApiModel<TResult, TExtendData = any>{
    constructor() { }
}