export interface StepOutputViewModel {
    isCompleted: boolean,
    isGoNextStep: boolean,
    data: any,
    id: number,
    thumbnail: string
}

export class StepOutputViewModel {
    constructor() {
        this.isCompleted = false;
        this.isGoNextStep = false;
    }
}