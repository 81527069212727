import { UserSettingState, initialState } from '../states';
import { userSettingActions } from '../actions';

export function userSettingReducer(state = initialState, action: userSettingActions.Actions): UserSettingState {
    switch (action.type) {
        case userSettingActions.ActionTypes.LOAD: {

            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        }

        case userSettingActions.ActionTypes.LOAD_SUCCESS: {

            return {
                ...state,
                completedFirstStory: action.response.completed_first_story,
                completedInviteMember: action.response.completed_invite_member,
                completedSchedule: action.response.completed_schedule,
                isLoading: false,
                isLoaded: true
            };
        }

        case userSettingActions.ActionTypes.LOAD_FAILURE: {

            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        }

        case userSettingActions.ActionTypes.LOAD_NOTIFICATIONS: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };
        }

        case userSettingActions.ActionTypes.LOAD_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        }

        case userSettingActions.ActionTypes.LOAD_NOTIFICATIONS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            };
        }

        default: {
            return state;
        }
    }
}
