import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { likeActions } from '../actions';
import { LikeHttpService } from '../../services';

@Injectable()
export class LikeEffects {
    
    likeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<likeActions.Like>(likeActions.ActionTypes.LIKE),
        switchMap((action: likeActions.Like) => {
            return this.likeHttpService.like(action.payload).pipe(
                map((response) => {
                    return new likeActions.LikeSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new likeActions.LikeFailure(error));
                }))
        })
    ));

    
    unlikeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<likeActions.Unlike>(likeActions.ActionTypes.UNLIKE),
        switchMap((action: likeActions.Unlike) => {
            return this.likeHttpService.unlike(action.id, action.resource).pipe(
                map(() => {
                    return new likeActions.UnlikeSuccess();
                }),
                catchError((error: any) => {
                    return of(new likeActions.UnlikeFailure(error));
                }))
        })
    ));

    constructor(
        private actions$: Actions,
        private likeHttpService: LikeHttpService
    ) { }
}