export interface CompanyButtonOutputApiModel {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface AuthorOutputApiModel {
    authorId: number;
    displayName: string;
    title: string;
    avatar: string;
}


export interface ProfilePublicOutputApiModel {
    companyId: number;
    name: string;
    email: string;
    website: string;
    avatar: string;
    buttons: CompanyButtonOutputApiModel[];
    author: AuthorOutputApiModel;
    slogan: string;
    slug: string;
    frequent_tags?:any;
    internal_stories_approval: any;
}


export class ProfilePublicOutputApiModel {
    constructor(data?: Partial<ProfilePublicOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
