import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD = '[Admin Scripts] Load',
    LOAD_SUCCESS = '[Admin Scripts] Load Success',
    LOAD_FAIL = '[Admin Scripts] Load Fail',


    ADD = '[Admin Scripts] Add',
    ADD_SUCCESS = '[Admin Scripts] Add Success',
    ADD_FAIL = '[Admin Scripts] Add Fail',


    EDIT = '[Admin Scripts] Edit',
    EDIT_SUCCESS = '[Admin Scripts] Edit Success',
    EDIT_FAIL = '[Admin Scripts] Edit Fail',

    DELETE = '[Admin Scripts] Delete',
    DELETE_SUCCESS = '[Admin Scripts] Delete Success',
    DELETE_FAIL = '[Admin Scripts] Delete Fail',
}


export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number ) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: any) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}
export class Add implements Action {
    readonly type = ActionTypes.ADD;

    constructor(public companyId: number,public payload: any ) { }
}

export class AddSuccess implements Action {
    readonly type = ActionTypes.ADD_SUCCESS;
}

export class AddFail implements Action {
    readonly type = ActionTypes.ADD_FAIL;

}


export class Edit implements Action {
    readonly type = ActionTypes.EDIT;

    constructor(public companyId: number, public scriptid: any ,public payload: any) { }
}

export class EditSuccess implements Action {
    readonly type = ActionTypes.EDIT_SUCCESS;

    constructor(public response: any) { }
}

export class EditFail implements Action {
    readonly type = ActionTypes.EDIT_FAIL;

    constructor(public error: any) { }
}

export class Delete implements Action {
    readonly type = ActionTypes.DELETE;

    constructor(public companyId: number, public scriptid: any ) { }
}

export class DeleteSuccess implements Action {
    readonly type = ActionTypes.DELETE_SUCCESS;

}

export class DeleteFail implements Action {
    readonly type = ActionTypes.DELETE_FAIL;
}

export type Actions = 
Load | LoadSuccess | LoadFail | Add | AddSuccess | AddFail | Edit | EditSuccess | EditFail | Delete | DeleteSuccess | DeleteFail

