import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { storyActions } from '@modules/story/store/actions';
import { SegmentAnalyticsService } from '@framework/analytics/segment-analytics/services';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';

@Injectable()
export class MyProfilePageAnalyticsEffects {

    loadStoryEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.LoadByCompany>(storyActions.ActionTypes.LOAD_BY_USER),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action, userProfile]) => {
            this.loadStory(action.queryData, userProfile);
        })
    ), { dispatch: false });

    constructor(
        private actions$: Actions,
        private store$: Store<CoreState>,
        private segmentAnalyticsService: SegmentAnalyticsService
    ) {

    }

    getUserType(type: number) {
        switch (type) {
            case 1:
                return 'Invited';
            case 2:
                return 'Named';
            case 3:
                return 'Registered';
        }
    }

    private loadStory(queryData: any, userProfile: UserProfileViewModel) {

    }

}
