import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { companyActions, companyEmbedActions } from '../actions';
import { CompanyHttpService } from '../../services';
import { InfoCompanyOutputApiModel } from '@modules/company/models/api-models/output';
import { UserHttpService } from '@modules/user/services';
import { StoryFeedEmbedService } from '@modules/company/services/story-embed-feed.http-service';

@Injectable()
export class CompanyEmbedEffects {


  loadEmbedEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyEmbedActions.LoadEmbed>(companyEmbedActions.ActionTypes.LOAD_EMBED),
    switchMap((action: companyEmbedActions.LoadEmbed) => {
      return this.companyHttpService.getcompanyEmbed(action.isCompany,action.Id).pipe(
        map((response: any) => {
          return new companyEmbedActions.LoadEmbedSuccess(response);
        }),
        catchError((error: any) => {
          return of(new companyEmbedActions.LoadEmbedFailure(error));
        })
      )
    })
  ));

  postPatchEmbedEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyEmbedActions.PostPatchEmbed>(companyEmbedActions.ActionTypes.CREATE_UPDATE_EMBED),
    switchMap((action: companyEmbedActions.PostPatchEmbed) => {
      return this.companyHttpService.postPatchcompanyEmbed(action.payload,action.typeofPost).pipe(
        map((response: any) => {
          return new companyEmbedActions.PostPatchEmbedSuccess();
        }),
        catchError((error: any) => {
          return of(new companyEmbedActions.PostPatchEmbedFailure());
        })
      )
    })
  ));

  loadEmbedFeedEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyEmbedActions.LoadEmbedFeed>(companyEmbedActions.ActionTypes.LOAD_EMBED_FEED),
    switchMap((action: companyEmbedActions.LoadEmbedFeed) => {
      return this.storyEmbedFeedService.getcompanyEmbedFedd(action.typeOfPost,action.Id).pipe(
        map((response: any) => {
          return new companyEmbedActions.LoadEmbedFeedSuccess(response);
        }),
        catchError((error: any) => {
          return of(new companyEmbedActions.LoadEmbedFeedFailure(error));
        })
      )
    })
  ));

  postPatchEmbedFeedEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<companyEmbedActions.PostPatchEmbedFeed>(companyEmbedActions.ActionTypes.CREATE_UPDATE_EMBED_FEED),
    switchMap((action: companyEmbedActions.PostPatchEmbedFeed) => {
      return this.storyEmbedFeedService.postPatchcompanyEmbedFeed(action.payload,action.typeofPost).pipe(
        map((response: any) => {
          return new companyEmbedActions.PostPatchEmbedFeedSuccess();
        }),
        catchError((error: any) => {
          return of(new companyEmbedActions.PostPatchEmbedFailure());
        })
      )
    })
  ));

  






  // 
  // changeSlugEffect$: Observable<Action> = this.actions$.pipe(
  //     ofType<companyActions.ChangeSlug>(companyActions.ActionTypes.CHANGE_SLUG),
  //     switchMap((action: companyActions.ChangeSlug) => {
  //         return this.companyHttpService.changeSlug(action.payload).pipe(
  //             map(() => {
  //                 return new companyActions.ChangeSlugSuccess();
  //             }),
  //             catchError((error: any) => {
  //                 return of(new companyActions.ChangeSlugFailure(error));
  //             }))
  //     })
  // );

  constructor(
    private actions$: Actions,
    private companyHttpService: CompanyHttpService,
    private storyEmbedFeedService: StoryFeedEmbedService,
    private usersService: UserHttpService
  ) { }
}
