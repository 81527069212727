import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class BackDropService {

  constructor(@Inject(DOCUMENT) private document: Document) {}

  // Private Functions

  private createNewBackDrop() {
    const backdrop = this.document.createElement('div', );
    backdrop.className = 'gs-backdrop fade show';
    backdrop.id = 'gs-back-drop';
    this.document.body.append(backdrop);
  }

  private getBackDrop() {
    return this.document.getElementById('gs-back-drop');
  }

  // Public Functions

  public show() {
    const checkElement = this.getBackDrop();
    if (!checkElement) {
      this.createNewBackDrop();
    }
  }

  public hide() {
    const checkElement = this.getBackDrop();
    if (checkElement) { checkElement.remove(); }
  }
}
