import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { SlackIntegrationOutputApiModel } from '../models/api-models/output';
import { UpdateSlackIntegrationInputApiModel } from '../models/api-models/input';

@Injectable()
export class IntegrationSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public loadSlackSetting(companyId: number) {
        return this.get(`/${companyId}/setting/integration/slack/`).pipe(
            map((response: SlackIntegrationOutputApiModel) => {
                return response;
            })
        )
    }

    public getSlackAccessToken(companyId: number, code: string) {
        return this.post(`/${companyId}/setting/integration/slack/code/`, { code: code }).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    public getSlackChannels(code: string) {
        return this.http.get(`https://slack.com/api/conversations.list?scope=channels:read&limit=1000&token=${code}`).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    public updateSlackSetting(companyId: number, id: number, payload: Partial<UpdateSlackIntegrationInputApiModel>) {
        return this.patch(`/${companyId}/setting/integration/slack/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteSlackSetting(companyId: number, id: number) {
        return this.delete(`/${companyId}/setting/integration/slack/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public loadYammerSetting(companyId: number) {
        return this.get(`/${companyId}/setting/integration/yammer/`).pipe(
            map((response: SlackIntegrationOutputApiModel) => {
                return response;
            })
        )
    }

    public getYammerAccessToken(companyId: number, code: string) {
        return this.post(`/${companyId}/setting/integration/yammer/code/`, { code: code }).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    public updateYammerSetting(companyId: number,  payload: Partial<any>) {
        return this.patch(`/${companyId}/setting/integration/yammer/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteYammerSetting(companyId: number) {
        return this.delete(`/${companyId}/setting/integration/yammer/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public addFirebaseToken(companyId: number,payload) {
        return this.post(`/${companyId}/setting/integration/fcm/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
