import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetReportLeadsInputApiModel } from '../models/api-models/input';
import { ReportLeadsOutputApiModel } from '../models/api-models/output';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';

@Injectable()
export class ReportLeadsHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient, private fileSaverService: FileSaverService) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetReportLeadsInputApiModel) {
        return this.get(`/${companyId}/report/leads/?${queryData.query}&spotlight=0`).pipe(
            map((response: ResponsePaginationApiModel<ReportLeadsOutputApiModel>) => {
                return response;
            })
        );
    }

    public loadSpotlightLeads(companyId: number, queryData: GetReportLeadsInputApiModel) {
        return this.get(`/${companyId}/report/leads/?${queryData.query}&spotlight=1`).pipe(
            map((response: ResponsePaginationApiModel<ReportLeadsOutputApiModel>) => {
                return response;
            })
        );
    }

    public exportStories(companyId: number, reportInputApiModel: GetReportLeadsInputApiModel): Observable<void> {
        const queryString = `${reportInputApiModel.query}&format=csv`;
    
        return this.get(`/${companyId}/report/leads/?${queryString}`, { 
            responseType: 'blob',
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Blob>) => {
                const contentType = response.headers.get('content-type');
                const blob = new Blob([response.body], { type: contentType || 'text/csv' });
                this.fileSaverService.save(blob, 'report_leads.csv', 'text/csv');
                return undefined;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.error.text) {
                    const blob = new Blob([error.error.text], { type: 'text/csv' });
                    this.fileSaverService.save(blob, 'report_leads.csv', 'text/csv');
                    return of(undefined);
                }
                throw error;
            })
        );
    }
}
