<ng-container *ngIf="!inbound">
  <app-approver-comment-post [resourceType]="resourceType" [resourceId]="resourceId"></app-approver-comment-post>
</ng-container>


<app-approver-comment-item [inbound]="inbound" [isMasterAdmin]="isMasterAdmin" [comment]="comment" *ngFor="let comment of comments; trackBy: trackByFunc"></app-approver-comment-item>
<skeleton-comment-item *ngIf="loadCommentStatus.isLoading"></skeleton-comment-item>
<div class="text-center" *ngIf="loadCommentStatus?.isCanLoadMore && !loadCommentStatus.isLoading" (click)="loadMore()">
  <button class="btn btn-sm btn-link"><span class="text-small">Load more</span></button>
</div>
