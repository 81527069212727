import { Actions,createEffect,ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { map, switchMap, catchError, withLatestFrom, mergeMap } from 'rxjs/operators';

import { storyActions, storyBuilderActions } from '../actions';
import { StoryEntity } from '../entities';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '../selectors';
import { StoryHttpService, CompanyHttpService, UserHttpService } from '@modules/story/services';
import { StoryOutputApiModel } from '@modules/story/models/api-models/output';
import { TrackingHttpService } from '@modules/story/services/tracking.http-service';

@Injectable()
export class StoryEffect {

  constructor(
    private actions$: Actions,
    private storyHttpService: StoryHttpService,
    private trackHttpService: TrackingHttpService,
    private store$: Store<CoreState>,
    private companyHttpService: CompanyHttpService,
    private userHttpService: UserHttpService,
  ) { }

  
  loadByCompanyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadByCompany>(storyActions.ActionTypes.LOAD_BY_COMPANY),
    switchMap((action: storyActions.LoadByCompany) => {
      return this.companyHttpService.getStories(action.queryData).pipe(
        map((response) => {
          return new storyActions.LoadSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadFailure(error));
        }));
    })
  ));

  
  loadReviewSiteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.ReviewaStory>(storyActions.ActionTypes.REVIEW_A_STORY),
    switchMap((action: storyActions.ReviewaStory) => {
      return this.companyHttpService.getReview(action.companyId,action.storyId,action.queryParams).pipe(
        map((response) => {
          return new storyActions.ReviewaStorySuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.ReviewaStoryFailure());
        }));
    })
  ));

changeUserTitle$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.changeUserTitle>(storyActions.ActionTypes.CHANGE_USER_TITLE),
    switchMap((action: storyActions.changeUserTitle) => {
      return this.companyHttpService.changeUserTitle(action.companyId,action.uId,action.payload).pipe(
        map((response) => {
          return new storyActions.changeUserTitleSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.changeUserTitleFailure());
        }));
    })
  ));


  
  loadSpotlightFrequentTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadSpotlightFrequentTags>(storyActions.ActionTypes.LOAD_SPOTLIGHT_FREQUENT_TAGS),
    switchMap((action: storyActions.LoadSpotlightFrequentTags) => {
      return this.companyHttpService.getFrequentTags(action.queryData).pipe(
        map((response) => {
          return new storyActions.LoadSpotlightFrequentTagsSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadSpotlightFrequentTagsFailure(error));
        }));
    })
  ));

  
  loadByUserEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadByUser>(storyActions.ActionTypes.LOAD_BY_USER),
    switchMap((action: storyActions.LoadByUser) => {
      return this.userHttpService.getStories(action.queryData).pipe(
        map((response) => {
          return new storyActions.LoadSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadFailure(error));
        }));
    })
  ));

  
  likeStoryTagEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LikeStoryTag>(storyActions.ActionTypes.LIKE_STORY_TAG),
    mergeMap((action: storyActions.LikeStoryTag) => {
      return from(this.storyHttpService.likeStoryLag(action.queryData.storyId, action.queryData.tagId)).pipe(
        map((response) => {
          return new storyActions.LikeStoryTagSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.LikeStoryTagFailure(error));
        }));
    })
  ));

  
  sendEmailInternalAndExternal$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.SendEamilInternalExternal>(storyActions.ActionTypes.SEND_EMAILS_INTERNAL_EXTERNAL),
    mergeMap((action: storyActions.SendEamilInternalExternal) => {
      return from(this.storyHttpService.sendEmails(action.payload)).pipe(
        map((response) => {
          return new storyActions.SendEamilInternalExternalSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.SendEamilInternalExternalFailure());
        }));
    })
  ));

  
  loadForMySettingEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadForMySetting>(storyActions.ActionTypes.LOAD_FOR_MY_SETTING),
    switchMap((action: storyActions.LoadForMySetting) => {
      return this.userHttpService.getStoriesForMySettingSetting(action.queryData).pipe(
        map((response) => {
          return new storyActions.LoadSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadFailure(error));
        }));
    })
  ));

  
  createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Create>(storyActions.ActionTypes.CREATE),
    switchMap((action: storyActions.Create) => {
      return this.storyHttpService.create(action.data).pipe(
        map((data: StoryOutputApiModel) => {
          return new storyActions.CreateSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyActions.CreateFailure(action.data, error));
        })
      );
    })
  ));

  
  createSeoEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.CreateSeo>(storyActions.ActionTypes.CREATE_SEO),
    switchMap((action: storyActions.CreateSeo) => {
      return this.storyHttpService.createSEO(action.data,action.storyId).pipe(
        map((data: any) => {
          return new storyActions.CreateSeoSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyActions.CreateSeoFailure(action.data, error));
        })
      );
    })
  ));

  
  loadEditEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadEdit>(storyActions.ActionTypes.LOAD_EDIT),
    switchMap((action: storyActions.LoadEdit) => {
      return this.storyHttpService.getStoryEdit(action.storyId).pipe(
        map((response) => {
          return new storyActions.LoadEditSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadEditFailure(error));
        })
      )
    })
  ));

  
  loadSeoffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadSeo>(storyActions.ActionTypes.LOAD_SEO),
    switchMap((action: storyActions.LoadSeo) => {
      return this.storyHttpService.getSeodetail(action.storyId).pipe(
        map((response) => {
          return new storyActions.LoadSeoSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadSeoFailure(error));
        })
      )
    })
  ));

  
  loadDetailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadDetail>(storyActions.ActionTypes.LOAD_DETAIL),
    switchMap((action: storyActions.LoadDetail) => {
      return this.storyHttpService.getStoryDetail(action.storyIdOrSlug,action.token,action.isPublic, action.campaign,action.spotlightId).pipe(
        map((response) => {
          return new storyActions.LoadDetailSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadDetailFailure(error));
        })
      )
    })
  ));

  
  loadDetailVideoEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadVideo>(storyActions.ActionTypes.LOAD_VIDEO),
    switchMap((action: storyActions.LoadVideo) => {
      return this.storyHttpService.getVideoDetail(action.storyIdOrSlug,action.companyId,action.token).pipe(
        map((response) => {
          return new storyActions.LoadVideoSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadVideoFailure(error));
        })
      )
    })
  ));

  
  loadStoryDetailWithCampaignStoryTokenEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadCampaignStoryWithTokenDetail>(storyActions.ActionTypes.LOAD_DETAIL_CAMPAIGN_STORY_TOKEN),
    switchMap((action: storyActions.LoadCampaignStoryWithTokenDetail) => {
      return this.storyHttpService.getCampaignStoryDetailToken(action.storyIdOrSlug,action.token,action.campaignid).pipe(
        map((response) => {
          return new storyActions.LoadDetailSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadDetailFailure(error));
        })
      )
    })
  ));
  
  loadStoryDetailWithSpotlightStoryTokenEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.LoadSpotlightStoryWithTokenDetail>(storyActions.ActionTypes.LOAD_DETAIL_SPOTLIGHT_STORY_TOKEN),
    switchMap((action: storyActions.LoadSpotlightStoryWithTokenDetail) => {
      return this.storyHttpService.getSpotlightStoryDetailToken(action.storyIdOrSlug,action.token,action.spotlightid).pipe(
        map((response) => {
          return new storyActions.LoadDetailSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.LoadDetailFailure(error));
        })
      )
    })
  ));
  
  addTrackhitTostoryOrCampaign$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.AddTrackHit>(storyActions.ActionTypes.ADD_TRACK_HIT),
    switchMap((action: storyActions.AddTrackHit) => {
      return this.trackHttpService.addHitToStoryOrCampaign(action.trackingid).pipe(
        map((response) => {
          return new storyActions.AddTrackHitSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.AddTrackHitFailure(error));
        })
      )
    })
  ));

  
  getTrackhistory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.GetTrackHit>(storyActions.ActionTypes.GET_TRACK_HIT),
    switchMap((action: storyActions.GetTrackHit) => {
      return this.trackHttpService.getTrackHit(action.companyId).pipe(
        map((response) => {
          return new storyActions.GetTrackHitSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.GetTrackHitFailure(error));
        })
      )
    })
  ));

  
  addTrackingToStory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.AddTracking>(storyActions.ActionTypes.ADD_TRACKING),
    switchMap((action: storyActions.AddTracking) => {
      return this.trackHttpService.addTrackingIdToStoryOrCampaign(action.payload).pipe(
        map((response) => {
          return new storyActions.AddTrackingSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.AddTrackingFailure(error));
        })
      )
    })
  ));


  
  getPerosnalozeTrackhistory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.GetPersonalizeTrackHit>(storyActions.ActionTypes.GET_PERSONALIZE_TRACK_HIT),
    switchMap((action: storyActions.GetPersonalizeTrackHit) => {
      return this.trackHttpService.getPersonalizeTrackHit(action.uniqueToken,action.isInbound).pipe(
        map((response) => {
          return new storyActions.GetPersonalizeTrackHitSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.GetPersonalizeTrackHitFailure(error));
        })
      )
    })
  ));

  
  addpersonalzeTrackingToStory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.AddPersonalizeTrackHit>(storyActions.ActionTypes.ADD_PERSONALIZE_TRACK),
    switchMap((action: storyActions.AddPersonalizeTrackHit) => {
      return this.trackHttpService.addPersonalizeTrackHit(action.payload).pipe(
        map((response) => {
          return new storyActions.AddPersonalizeTrackHitSuccess(response);
        }),
        catchError((error: any) => {
          return of(new storyActions.AddPersonalizeTrackHitFailure(error));
        })
      )
    })
  ));

  
  updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Update>(storyActions.ActionTypes.UPDATE),
    switchMap((action) => {
      return this.storyHttpService.update(action.id, action.data).pipe(
        map((data: StoryOutputApiModel) => {
          return new storyActions.UpdateSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyActions.UpdateFailure(action.data, error));
        })
      );
    })
  ));

  
  updateMySettingStoryEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.UpdateMySettingStory>(storyActions.ActionTypes.UPDATE_MY_SETTING_STORY),
    mergeMap((action) => {
      return this.userHttpService.updateMySettingStory(action.id, action.data).pipe(
        map((data: StoryOutputApiModel) => {
          return new storyActions.UpdateMySettingStorySuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyActions.UpdateMySettingStoryFailure(error));
        })
      );
    })
  ));

  
  createInboundEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.CreateInbound>(storyActions.ActionTypes.CREATE_INBOUND),
    switchMap((action: storyActions.CreateInbound) => {
      return this.storyHttpService.createInbound(action.data).pipe(
        map((data: StoryOutputApiModel) => {
          return new storyActions.CreateSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyActions.CreateFailure(action.data, error));
        })
      );
    })
  ));

  
  publishEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Publish>(storyActions.ActionTypes.PUBLISH),
    switchMap((action: storyActions.Publish) => {
      return this.storyHttpService.publishStory(action.storyId).pipe(
        map((data) => {
          return new storyActions.PublishSuccess(data);
        }),
        catchError((error: any) => {
          return of(new storyActions.PublishFailure(error));
        })
      );
    })
  ));

  
  deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Delete>(storyActions.ActionTypes.DELETE),
    switchMap((action: storyActions.Delete) => {
      return this.storyHttpService.deleteStory(action.storyId).pipe(
        map(() => {
          return new storyActions.DeleteSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.DeleteFailure(error));
        })
      );
    })
  ));

  bulkDeleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.BulkDelete>(storyActions.ActionTypes.BULK_DELETE),
    switchMap((action: storyActions.BulkDelete) => {
      return this.storyHttpService.bulkDelteStory(action.ids).pipe(
        map(() => {
          return new storyActions.DeleteSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.DeleteFailure(error));
        })
      );
    })
  ));

  
  deleteasstory$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.DeleteaStory>(storyActions.ActionTypes.DELETE_A_STORY),
    switchMap((action: storyActions.DeleteaStory) => {
      return this.storyHttpService.deleteastory(action.storyid).pipe(
        map(() => {
          return new storyActions.DeleteaStorySuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.DeleteaStoryFailure(error));
        })
      );
    })
  ));

  
  deletesEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Deletes>(storyActions.ActionTypes.DELETES),
    switchMap((action: storyActions.Deletes) => {
      return this.userHttpService.deleteStories(action.storyIds).pipe(
        map(() => {
          return new storyActions.DeletesSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.DeletesFailure(error));
        })
      );
    })
  ));




  

  
  submitCtaEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.SubmitCta>(storyActions.ActionTypes.SUBMIT_CTA),
    switchMap((action: storyActions.SubmitCta) => {
      return this.storyHttpService.submitCta(action.storyId, action.payload).pipe(
        map(() => {
          return new storyActions.SubmitCtaSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.SubmitCtaFailure(error));
        })
      );
    })
  ));

  
  selectEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.Select>(storyActions.ActionTypes.SELECT),
    withLatestFrom(this.store$.select(storySelector.selectCurrentStory)),
    switchMap(([action, currentStory]) => {
      return [new storyActions.LoadDetail(action.idOrSlug,null,action.isPublic, action.campaign, action.spotlightId)]; 
    })
  ));

  
  viewEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<storyActions.View>(storyActions.ActionTypes.VIEW),
    switchMap((action: storyActions.View) => {
      return this.storyHttpService.viewStory(action.storyId).pipe(
        map(() => {
          return new storyActions.ViewSuccess();
        }),
        catchError((error: any) => {
          return of(new storyActions.ViewFailure(error));
        })
      );
    })
  ));
}
