import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { landingPageActions } from '../actions';
import { CompanyHttpService, LandingOrderHttpService } from '../../services';

@Injectable()
export class LandingPageEffects {

  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.Load>(landingPageActions.ActionTypes.LOAD),
    switchMap((action: landingPageActions.Load) => {
      return this.companyHttpService.loadLandingPage(action.companyId, action.queryData).pipe(
        map((response) => {
          return new landingPageActions.LoadSuccess(response);
        }),
        catchError((error: any) => {
          return of(new landingPageActions.LoadFail(error));
        }));
    })
  ));

  
  orderStories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.OrderStories>(landingPageActions.ActionTypes.ORDER_STORIES),
    switchMap((action: landingPageActions.OrderStories) => {
      return this.landingOrderHttpService.OrderStories(action.id, action.payload).pipe(
        map((response) => {
          return new landingPageActions.OrderStoriesSuccess();
        }),
        catchError((error: any) => {
          return of(new landingPageActions.OrderStoriesFail());
        }));
    })
  ));

  
  getorderStories$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.GetOrderStories>(landingPageActions.ActionTypes.GET_ORDER_STORIES),
    switchMap((action: landingPageActions.GetOrderStories) => {
      return this.landingOrderHttpService.GetOrderStories(action.id).pipe(
        map((response) => {
          return new landingPageActions.GetOrderStoriesSuccess(response);
        }),
        catchError((error: any) => {
          return of(new landingPageActions.GetOrderStoriesFail());
        }));
    })
  ));

  
  loadDetailEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.LoadDetail>(landingPageActions.ActionTypes.LOAD_DETAIL),
    switchMap((action: landingPageActions.LoadDetail) => {
      return this.companyHttpService.loadDetailLandingPage(action.companyId, action.idOrSlug).pipe(
        map((response) => {
          return new landingPageActions.LoadDetailSuccess(response);
        }),
        catchError((error: any) => {
          return of(new landingPageActions.LoadDetailFail(error));
        }));
    })
  ));

  
  submitCtaEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.SubmitCta>(landingPageActions.ActionTypes.SUBMIT_CTA),
    switchMap((action: landingPageActions.SubmitCta) => {
      return this.companyHttpService.submitCta(action.companyId, action.spotlightId,action.payload).pipe(
        map((response) => {
          return new landingPageActions.SubmitCtaSuccess();
        }),
        catchError((error: any) => {
          return of(new landingPageActions.LoadDetailFail(error));
        }));
    })
  ));

  
  updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.Update>(landingPageActions.ActionTypes.UPDATE),
    switchMap((action: landingPageActions.Update) => {
      return this.companyHttpService.updateLandingPage(action.companyId, action.id, action.payload).pipe(
        map((response) => {
          return new landingPageActions.UpdateSuccess(response);
        }),
        catchError((error: any) => {
          return of(new landingPageActions.UpdateFail(error));
        }));
    })
  ));

  
  createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<landingPageActions.Create>(landingPageActions.ActionTypes.CREATE),
    switchMap((action: landingPageActions.Create) => {
      return this.companyHttpService.createLandingPage(action.companyId, action.payload).pipe(
        map((response) => {
          return new landingPageActions.CreateSuccess(response);
        }),
        catchError((error: any) => {
          return of(new landingPageActions.CreateFail(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private companyHttpService: CompanyHttpService,
    private landingOrderHttpService: LandingOrderHttpService) { }
}
