export interface QuestionOptionOutputApiModel {
    id: number;
    value: string;
    is_active: boolean;
}

export class QuestionOptionOutputApiModel {
    constructor(data?: Partial<QuestionOptionOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}