import {Component, SimpleChanges, Inject, ViewChild, ElementRef} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { SocialSharingService, ModalService } from '@framework/services';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
  selector: 'user-pub-toolbar',
  templateUrl: './profile-toolbar.component.html',
  styleUrls: ['./profile-toolbar.component.css']
})
export class ProfileToolbarComponent extends ComponentBase {
  @ViewChild("addthis") addthis: ElementRef;

  // Private variables
  private selectSelectedProfile$: Observable<UserProfileViewModel>;
  private selectCurrentProfile$: Observable<UserProfileViewModel>;

  private selectSelectedProfileSubscription: Subscription;
  private selectCurrentProfileSubscription: Subscription;

  public profile: UserProfileViewModel;
  public currentProfile: UserProfileViewModel;

  public isCanShare: boolean = true;
  public shareForm: UntypedFormGroup;
  private shareUrl: string;

  constructor(
    private store$: Store<CoreState>,
    private socialSharingService: SocialSharingService,
    private actions$: Actions,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder) {
    super();

    this.selectSelectedProfile$ = this.store$.select(userProfileSelectors.selectSelectedProfile);
    this.selectCurrentProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.shareForm = this.formBuilder.group({
      link: [this.shareUrl]
    });
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectSelectedProfileSubscription = this.selectSelectedProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.profile = userProfile;
        // this.addthis.nativeElement.setAttribute("data-url", this.shareUrl);
        if (typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }
        this.shareForm.controls.link.setValue(this.shareUrl);
      }
    });

    this.selectCurrentProfileSubscription = this.selectCurrentProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.currentProfile = userProfile;
      }
    });
  }

  private unsubscribe() {
    this.selectSelectedProfileSubscription.unsubscribe();
    this.selectCurrentProfileSubscription.unsubscribe();
  }

  public share() {
    const linkToShare: string = `${window.location.origin}/u/${this.profile.slug}`;
    this.modalService.showModal(ShareModalContainerComponent, linkToShare).then(() => {

    });
  }
}
