import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-pb-author-informations',
  templateUrl: './skeleton-author-informations.component.html',
  styleUrls: ['./skeleton-author-informations.component.css']
})
export class SkeletonAuthorInformationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
