import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AdvocacySettingHttpService, SocialReviewSettingHttpService } from '../services';
import { socialReviewSettingAction } from '../actions';


@Injectable()
export class SocialReviewSettingEffects {

    constructor(
        private actions$: Actions,
        private socialReviewSettingHttpService: SocialReviewSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<socialReviewSettingAction.Load>(socialReviewSettingAction.ActionTypes.LOAD),
        switchMap((action: socialReviewSettingAction.Load) => {
            return this.socialReviewSettingHttpService.load(action.companyId).pipe(
                map((respone) => {
                    return new socialReviewSettingAction.LoadSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new socialReviewSettingAction.LoadFail(error));
                }));
        })
    ));


    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<socialReviewSettingAction.Update>(socialReviewSettingAction.ActionTypes.UPDATE),
        switchMap((action: socialReviewSettingAction.Update) => {
            return this.socialReviewSettingHttpService.update(action.companyId, action.advocacyId, action.payload).pipe(
                map(() => {
                    return new socialReviewSettingAction.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new socialReviewSettingAction.UpdateFailure(error));
                }))
        })
    ));

    
    addEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<socialReviewSettingAction.Add>(socialReviewSettingAction.ActionTypes.ADD),
        switchMap((action: socialReviewSettingAction.Add) => {
            return this.socialReviewSettingHttpService.add(action.companyId, action.payload).pipe(
                map(() => {
                    return new socialReviewSettingAction.AddSuccess();
                }),
                catchError((error: any) => {
                    return of(new socialReviewSettingAction.AddFailure(error));
                }))
        })
    ));

    
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<socialReviewSettingAction.Delete>(socialReviewSettingAction.ActionTypes.DELETE),
        switchMap((action: socialReviewSettingAction.Delete) => {
            return this.socialReviewSettingHttpService.deleteAdvocacy(action.companyId, action.advocacyId).pipe(
                map(() => {
                    return new socialReviewSettingAction.DeleteSuccess();
                }),
                catchError((error: any) => {
                    return of(new socialReviewSettingAction.DeleteFailure(error));
                }))
        })
    ));
}