import { DatePipe } from '@angular/common';
import { OnInit, forwardRef, Renderer2, ElementRef, AfterViewInit, Component, ViewChild, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
declare var $: any, jQuery: any;
@Component({
    selector: 'gs-input-date-range',
    templateUrl: './gs-input-date-range.component.html',
    styleUrls: ['./gs-input-date-range.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GsInputDateRangeComponent),
            multi: true
        }
    ]
})
export class GsInputDateRangeComponent implements ControlValueAccessor, OnInit, AfterViewInit {
    @ViewChild('inputControl')
    inputControl: ElementRef;


    // Inputs
    @Input() placeholder: string;

    // Private variables
    private inputValue: Date[];

    // Public variables
    public fnOnChange: any = () => { };
    public fnOnTouch: any = () => { };
    public dateStringValue: string;

    constructor(private renderer: Renderer2) {
        if (typeof $ === 'undefined' && typeof jQuery !== 'undefined') {
            $ = jQuery;
        }
    }

    // Life cycle hooks
    ngOnInit() {

    }

    ngAfterViewInit() {
        this.initDateRagePicker();
    }

    // Private functions
    private initDateRagePicker() {
        const datePipe = new DatePipe('en-US');
        $(this.inputControl.nativeElement).daterangepicker({
            autoUpdateInput: false,
            ranges: {
                'All Time': [moment().startOf('year').subtract(1200,'month'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Year': [moment().startOf('year'), moment()],
                'Last Year': [moment().subtract(12, 'month').startOf('year'), moment().startOf('year').subtract(1, 'day')]

            }
        });

        this.listenEvents();
    }

    private triggerChange($event) {
        this.fnOnChange($event);
        const event = new CustomEvent('change', { bubbles: true });
        this.inputControl.nativeElement.dispatchEvent(event);
    }

    private listenEvents() {
        $(this.inputControl.nativeElement).on('apply.daterangepicker', (ev, picker) => {
            const startDate = picker.startDate;
            const endDate = picker.endDate;
            this.value = [startDate, endDate];
            this.onChange();
            this.dateStringValue = `${picker.startDate.format('MM/DD/YYYY')} - ${picker.endDate.format('MM/DD/YYYY')}`;
        });
    }
    // Public functions
    public set value(val: Date[]) {
        if (val !== undefined) {
            this.inputValue = val;
        }
    }

    public get value() {
        return this.inputValue;
    }

    public writeValue(val: Date[]): void {
        this.value = val;
    }

    public registerOnChange(fn: any): void {
        this.fnOnChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.fnOnTouch = fn;
    }

    public onChange() {
        this.triggerChange(this.inputValue);
    }
}
