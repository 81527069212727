export interface SearchCompanyOutputApiModel {
    company_id: number,
    name: string,
    avatar: string,
    members_count: number,
    background: string,
    website: string
}

export class SearchCompanyOutputApiModel {
    constructor(data?: Partial<SearchCompanyOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}