import { TestimonialState } from '../states';
import { testimonialActions } from '../actions';
import { TestimonialEntity } from '../entities';
import { testimonialAdapter, initialState } from '../adapters/testimonial.adapter';


export function testimonialReducer(state = initialState, action: testimonialActions.Actions): TestimonialState {
    switch (action.type) {

        case testimonialActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case testimonialActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new TestimonialEntity(item));
            const newSate = testimonialAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: action.response.count
            };
        }

        case testimonialActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}