import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { userReducers } from './reducers';
import * as effects from './effects';
import { UserHttpService } from '../services';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('userFeature', userReducers),
        EffectsModule.forFeature([
            effects.UserEffects,
            effects.UserProfileEffects,
            effects.UserSettingEffects,
            effects.DirectoryEffect,
            effects.UserEmailEffect,
            effects.UserPermissionEffects
        ])
    ],
    providers: [
        UserHttpService
    ],
})

export class UserStoreModule { }
