<user-pub-informations-skeleton [hidden]="profile"></user-pub-informations-skeleton>

<div #info class="b-information gsSlideUp" *ngIf="profile">
  <div class="container">
    <div class="row justify-content-center">
      <div class="box-story-count-container col-4">
        <!-- <div class="m-infor-boxes d-flex">
          <div class="boxes-story-count text-center text-lg-left">
            <div class="m-infor-boxes__title">Stories</div>
            <div class="boxes-story-count__focus">{{profile.storyStatistics?.total || 0}}</div>
          </div>
          <div class="d-none d-md-flex boxes-story-count__row">
            <span class="boxes-story-count__count">{{profile.storyStatistics?.given || 0}}</span>
            <span class="boxes-story-count__title">author</span>
          </div>
          <div class="d-none d-md-flex boxes-story-count__row">
            <span class="boxes-story-count__count">{{profile.storyStatistics?.received || 0}}</span>
            <span class="boxes-story-count__title">contributor</span>
          </div>
        </div> -->

        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="m-infor-boxes d-flex">
                <div class="boxes-story-count text-center text-lg-left">
                  <div class="m-infor-boxes__title larger_font">Stories</div>
                  <div class="boxes-story-count__focus" style="font-size: 2.625rem !important">{{profile.storyStatistics?.total || 0}}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="m-infor-boxes d-flex">
                <div class="d-none d-md-flex boxes-story-count__row">
                  <span class="boxes-story-count__count" style="margin-top: 0px !important;">{{profile.storyStatistics?.given || 0}}</span>
                  <span class="boxes-story-count__title">Author</span>
                </div>
                <div class="d-none d-md-flex boxes-story-count__row ml-4">
                  <span class="boxes-story-count__count" style="margin-top: 0px !important;">{{profile.storyStatistics?.received || 0}}</span>
                  <span class="boxes-story-count__title">Contributor</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <ng-container *ngFor="let item of profile.storyFrequentTags">
        <div class="col-12 col-lg-2 col-md-4 px-2" *ngIf="item.is_header_part">
          <div class="m-infor-boxes text-center text-lg-left">
            <div class="m-infor-boxes__title">
              {{getModifiedNames(item.id)}}
            </div>
            <div class="m-infor-boxes__content">
              <div class="l-chips l-chips--impact" *ngIf="item.data.length > 0">
                <div class="l-chips__item" *ngFor="let tag of item.data">
                  <a class="chip-item"
                  [ngStyle]="{'background': hexToRgbA(item.color) ,'color':item.color}">{{tag}}</a>
                </div>
              </div>
              <div class="l-chips text-center text-gray" ng-cloak *ngIf="item.data.length > 0">
                Stories reveal trending skills and talents here.
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>
