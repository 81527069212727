import { StoryOutputApiModel } from "@modules/story/models/api-models/output";

export interface StoryBuilderState {
    stepCompletedIds: number[],
    canPreview: boolean,
    viewMode: string,
    showHoverFirstStep: boolean,
    stepActive: any,
    storyData: StoryOutputApiModel,
    inbound: boolean,
    companyId: number
}

export const initialStoryBuilderState = {
    stepCompletedIds: [],
    canPreview: false,
    viewMode: 'EDIT',
    showHoverFirstStep: false,
    stepActive: null,
    storyData: null,
    inbound: false,
    companyId: 0
}