import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { CompanyEntity } from '../entities';
import { CompanyState } from '../states';

export const companyAdapter: EntityAdapter<CompanyEntity> = createEntityAdapter<CompanyEntity>();

export const initialState: CompanyState = companyAdapter.getInitialState({
    selectedId: undefined,
    isSearching: false,
    isSearched: false,
    createCompanyDomain: '',
    currentUserCompanyId: undefined
});