import { Action } from '@ngrx/store';

import { CreateStoryInputApiModel } from '@modules/story/models/api-models/input';

export enum StoryBuilderActionTypes {
    UPDATE_STEPS_COMPLETED = '[StoryBuilder] Update Steps Completed',
    UPDATE_VIEW_MODE = '[StoryBuilder] Update View Mode',
    UPDATE_SHOW_HOVER_FIRST_STEP = '[StoryBuilder] Show Hover First Step',
    SET_STEP_ACTIVE = '[StoryBuilder] Set Step Active',
    UPDATE_STORY_DATA = '[StoryBuilder] Update Story Data',
    UPDATE_STORY_STEP_SELECTED = '[StoryBuilder] Update Story Step Selected',
    SET_INBOUND = '[StoryBuilder] Set Inbound',
    SET_COMPANY_ID = '[StoryBuilder] Set CompanyId',
    SET_CAN_PREVIEW = '[StoryBuilder] Set Can Preview',
    GO_TO_PREV_STEP = '[StoryBuilder] Go To Prev Step',
    GO_TO_NEXT_STEP = '[StoryBuilder] Go To Next Step',
    FOCUS_TO_CONTENT_RTE = '[StoryBuilder] Focus To Content RTE',
    GO_TO_STEP = '[StoryBuilder] Go To Step',
    VALIDATE_STEP = '[StoryBuilder] Validate Step',
    RESET_STATE =  '[StoryBuilder] Reset State',
    SAVE_DRAFT = '[StoryBuilder] Save Draft'
};


export class UpdateStepsCompleted implements Action {
    readonly type = StoryBuilderActionTypes.UPDATE_STEPS_COMPLETED;

    constructor(public stepIds: number[]) { }
}

export class UpdateViewMode implements Action {
    readonly type = StoryBuilderActionTypes.UPDATE_VIEW_MODE;

    constructor(public viewMode: string) { }
}

export class UpdateShowHoverFirstStep implements Action {
    readonly type = StoryBuilderActionTypes.UPDATE_SHOW_HOVER_FIRST_STEP;

    constructor(public showHoverFirstStep: boolean) { }
}

export class SetStepActive implements Action {
    readonly type = StoryBuilderActionTypes.SET_STEP_ACTIVE;

    constructor(public stepActive: any) { }
}

export class UpdateStoryData implements Action {
    readonly type = StoryBuilderActionTypes.UPDATE_STORY_DATA;

    constructor(public storyData: Partial<CreateStoryInputApiModel>) { }
}

export class UpdateStoryStepSelected implements Action {
    readonly type = StoryBuilderActionTypes.UPDATE_STORY_STEP_SELECTED;

    constructor(public stepId: number, public dataSelected: any, public fromRte:boolean = false) { }
}

export class SetInbound implements Action {
    readonly type = StoryBuilderActionTypes.SET_INBOUND;

    constructor(public inbound: boolean) { }
}

export class SetCompanyId implements Action {
    readonly type = StoryBuilderActionTypes.SET_COMPANY_ID;

    constructor(public companyId: number) { }
}

export class SetCanPreview implements Action {
    readonly type = StoryBuilderActionTypes.SET_CAN_PREVIEW;

    constructor(public canPreview: boolean) { }
}

export class GotoPrevStep implements Action {
    readonly type = StoryBuilderActionTypes.GO_TO_PREV_STEP;
}

export class saveDraft implements Action {
    readonly type = StoryBuilderActionTypes.SAVE_DRAFT;
}

export class GotoNextStep implements Action {
    readonly type = StoryBuilderActionTypes.GO_TO_NEXT_STEP;
}

export class FocusToContentRte implements Action {
    readonly type = StoryBuilderActionTypes.FOCUS_TO_CONTENT_RTE;
}

export class GotoStep implements Action {
    readonly type = StoryBuilderActionTypes.GO_TO_STEP;

    constructor(public stepId: number) { }
}

export class ValidateStep implements Action {
    readonly type = StoryBuilderActionTypes.VALIDATE_STEP;

    constructor() { }
}

export class ResetState implements Action {
    readonly type = StoryBuilderActionTypes.RESET_STATE;

    constructor() { }
}


export type StoryBuilderActions
    = UpdateStepsCompleted | UpdateViewMode |
    UpdateShowHoverFirstStep | SetStepActive |
    UpdateStoryData | SetInbound | SetCompanyId |
    UpdateStoryStepSelected | SetCanPreview | GotoPrevStep | GotoNextStep | FocusToContentRte | GotoStep |
    ValidateStep | ResetState;
