import { Component, ViewEncapsulation, Inject, ViewChild, ElementRef } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { Actions, ofType } from '@ngrx/effects';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as Saction from '@modules/company-admin/store/actions'
import { storyActions } from '@modules/story/store/actions';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService, UploaderService } from '@framework/services';
import SignaturePad from 'signature_pad';
import { Callback, UploaderConfig } from '@framework/models';
import { CreateMediaInputApiModel } from '@app/store/http/models/input';
import { UtilityHelper } from '@framework/utilities';
import { Action, Store } from '@ngrx/store';
import { Page } from '@framework/base';
import { companyActions } from '@modules/company/store/actions';

@Component({
  selector: 'app-open-consent',
  templateUrl: './open-consent.page.html',
  styleUrls: ['./open-consent.page.scss'],
})
export class OpenConsentPage extends Page {
  public consentForm: UntypedFormGroup;
  signatureNeeded!: boolean;
  public storyDetails:any;
  public company_response:any;
  public loadDetailSuccess: Subscription;
  public story_details:any;
  signaturePad!: SignaturePad;
  @ViewChild('canvas') canvasEl!: ElementRef;
  signatureImg!: string;
  private uploader: any;
  public storyId:any;
  public show_image:boolean = false;
  public show_name:boolean = false;
  public show_voice:boolean = false;
  public show_other:boolean = false;
  public isUpdating:boolean = false;
  public isDisabled:boolean = false;
  public template:any;
  public consent_name:any;
  public orignalSource:any;
  public loadConsentSucess: Subscription;
  public saveImage:any;
  public uploaderViewModel: any = {
    errorMsg: 'An error occurred while uploading.',
    isError: false,
    progress: 0,
    progressCss: '0%',
    isUploading: false
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private actions$: Actions,
    private store$: Store,
    private activateRoute: ActivatedRoute,
    private utility:UtilityHelper,
    private uploaderService: UploaderService,
    private rssFeedHttpService: RssFeedHttpService,
    private toastService: ToastService
  ) {
    super();
    this.captureParams()
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {
if(this.storyId){
  this.store$.dispatch(new storyActions.LoadDetail(this.storyId));
}
this.store$.dispatch(new Saction.PolicyActons.LoadConsent(this.activateRoute.snapshot.params.companyId));
this.store$.dispatch(new companyActions.LoadInfo(this.activateRoute.snapshot.params.companyId));

this.loadConsentSucess = this.actions$.pipe(
  ofType<companyActions.LoadInfoSuccess>(companyActions.ActionTypes.LOAD_INFO_SUCCESS)
).subscribe((consent: any) => {
    console.log(">consent",consent)
    if(consent && consent?.response){
      this.company_response = consent?.response
    }
})
this.loadConsentSucess = this.actions$.pipe(
  ofType<Saction.PolicyActons.LoadConsentSuccess>(Saction.PolicyActons.ActionTypes.LOAD_CONSENT_SUCCESS)
).subscribe((consent: any) => {

  if (consent && consent.response && consent.response.data) {
    const response = consent.response.data;
    this.consent_name = response?.consent_name
    this.template = response?.consent_template
    this.consentForm.controls.consent_template.setValue(this.template);
    const checkIfAnyTrue = (fields: string[]) => {
      return fields.some(field => response[field]);
    };
    if (checkIfAnyTrue(['is_fake_name', 'is_first_name', 'is_initials_name', 'is_real_name'])) {
      this.consentForm.get('from_name').setValidators([Validators.required]);
      this.show_name = true;
    } else {
      this.consentForm.get('from_name').clearValidators();
    }
    
    if (checkIfAnyTrue(['is_blurred_image', 'is_full_image', 'is_image_in_any_media', 'is_image_social_media', 'not_use_image'])) {
      this.consentForm.get('from_image').setValidators([Validators.required]);
      this.show_image = true;
    } else {
      this.consentForm.get('from_image').clearValidators();
    }
    
    if (checkIfAnyTrue(['is_masked_voice', 'is_real_voice', 'is_voice_transcription'])) {
      this.consentForm.get('from_voice').setValidators([Validators.required]);
      this.show_voice = true;
    } else {
      this.consentForm.get('from_voice').clearValidators();
    }

    if (checkIfAnyTrue(['is_image_media_advertising', 'is_name_advertising'])) {
      this.show_other = true;
    } else {
      this.consentForm.get('from_other').clearValidators();
    }

    
    this.signatureImg = this.consentForm.controls.esignature.value;
    this.signatureNeeded = false;
    }

this.consentForm.get('from_name').updateValueAndValidity();
this.consentForm.get('from_image').updateValueAndValidity();
this.consentForm.get('from_voice').updateValueAndValidity();
this.consentForm.get('from_other').updateValueAndValidity();


  this.consentForm.patchValue({
    from_image:'',
    from_name:'',
    from_voice:'',
    from_other:'',
    esignature:''
});  
})
this.loadConsentSucess = this.actions$.pipe(
  ofType<Saction.PolicyActons.UpdateConsentStorySuccess>(Saction.PolicyActons.ActionTypes.UPDATE_CONSENT_STORY_SUCCESS)
).subscribe((consent: any) => {
     this.isUpdating = false;
     this.isDisabled = false;

})
    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })

    this.loadDetailSuccess = this.actions$.pipe(
      ofType<storyActions.LoadDetailSuccess>(storyActions.ActionTypes.LOAD_DETAIL_SUCCESS)
    ).subscribe(action => {
     
      if(action && action?.data){
        this.story_details = action?.data;

      }





    })
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();    
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
  }
  protected onAfterViewChecked(): void {

  }

  private captureParams() {
   this.storyId = this.activateRoute.snapshot.params.storyId;
  }

  private initForm() {
    this.consentForm = this.formBuilder.group({
          from_image:[''],
          from_name:[''],
          from_voice:[''],
          from_other:[''],
          consent_template:[''],
          consent_filled:[true],
          consent_visible:[true],
          esignature:['',Validators.required]
    });

    setTimeout(()=>{
      this.initUploader();
    },1000)

    // this.consentForm = this.formBuilder.group({
    //   height: [900, Validators.required],
    //   width: [100, Validators.required],
    //   widthList:['px'],
    //   heightList:['px'],
    //   bodyCode: [{ value: '', disabled: true }],
    //   selectCheckbox: ['public', Validators.required],
    //   selectRadio: [false, Validators.required]
    // })


    // this.consentForm = this.formBuilder.group({
    //  value:[{ value: '', disabled: true }],
    //  publicValue:[{ value: '', disabled: true }]
    // })

  }


  private initUploader() {
    let config = new UploaderConfig();
    // config.button = this.browseFileButton.toArray()[0].nativeElement;
    config.companyId = -1;
    config.isAnonymous = true;
    config.validate = {
      allowedExtensions: ['gif', 'jpeg', 'jpg',  'webp','heic']
    };
    config.messages = {
      imageDimensionError: 'Use a larger image - 640 x 480px minimum.',
      typeError: 'Acceptable file formats are gif, jpg, jpeg, png, mp4, mpg and mov.'
    };

    let callbacks = new Callback();
    callbacks.onSubmit = this.onUploaderSubmit.bind(this);
    callbacks.onComplete = this.onUploaderComplete.bind(this);
    callbacks.onError = this.onUploaderError.bind(this);
    callbacks.onProgress = this.onProgress.bind(this);
    // callbacks.onAllComplete = this.onUploaderAllComplete.bind(this);

    this.uploader = this.uploaderService.s3Uploader(config, callbacks);
  }

  private onProgress(id, name, loaded, total) {
    const percent = (loaded / total) * 100;
    this.uploaderViewModel.progress = percent;
    this.uploaderViewModel.progressCss = `${percent}%`;
  }

  private onUploaderSubmit(id, fileName) {
    this.uploaderViewModel.progress = 0;
    this.uploaderViewModel.progressCss = '0%';
    this.uploaderViewModel.isUploading = true;
    this.uploaderViewModel.isError = false;
  }

  private onUploaderComplete(id, name, responseJSON, maybeXhr) {
    this.uploaderViewModel.isUploading = false;

    if (responseJSON.success) {
      let createMediaInputData = new CreateMediaInputApiModel();
      createMediaInputData.origname = name;
      createMediaInputData.size = this.uploader.getSize(id);
      const fileInfo = this.uploaderService.getUploadedFile(id);
      createMediaInputData.path = fileInfo.path;
      createMediaInputData.source = fileInfo.name;
      createMediaInputData.filename = fileInfo.name;
      createMediaInputData.type = fileInfo.type;
      this.saveImage = 'https://ik.imagekit.io/lpzxd7bmi/goodseeker'+fileInfo.path+'/'+fileInfo.name+'?tr=n-stories_list'
      this.consentForm.controls.esignature.setValue(this.saveImage)
      this.isUpdating = false;


    }
  }

  private onUploaderError(id, name, reason, maybeXhrOrXdr) {
    this.uploaderViewModel.isError = true;
    this.uploaderViewModel.errorMsg = reason;
  }

  // Clear the canvas

  startDrawing(event: Event) {
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  clearPad() {
    this.signaturePad.clear();
    this.signatureImg = '';
    this.consentForm.controls.esignature.setValue(null);
    this.signatureNeeded = true;
  }

  savePad() {
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
    const imageEditedBlob = this.utility.File.b64ToBlob(base64Data.replace(/^data:(image|binary)\/(png|jpeg|jpg|octet-stream|webp|heic);base64,/, ''), "image/png");
    imageEditedBlob['path'] = 'story-image';
    this.uploader.addBlobs(imageEditedBlob);
    this.isUpdating = true;
    this.signatureNeeded = this.signaturePad.isEmpty();
    if (!this.signatureNeeded) {
      this.signatureNeeded = false;
    }
  }

  public close() {
  }

  submitForm(){
    this.store$.dispatch(new Saction.PolicyActons.UpdateConsentStory(this.activateRoute.snapshot.params.companyId,this.consentForm.value,this.activateRoute.snapshot.params.storyId));
    this.isUpdating = true;
    this.isDisabled = true;
  }
  
}
