import { Action } from '@ngrx/store';

import { ReportLeadsOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetReportLeadsInputApiModel } from '../models/api-models/input';


export enum ActionTypes {
    LOAD = '[Company Admin Story Report Leads] Load',
    LOAD_SUCCESS = '[Company Admin Story Report Leads] Load Success',
    LOAD_FAIL = '[Company Admin Story Report Leads] Load Fail',

    
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public queryData: GetReportLeadsInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: any) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load | LoadSuccess | LoadFail;
