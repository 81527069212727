import { TagViewModel } from "./tag.view-model";

export class StoryStepViewModel {
    public id: number;
    public sort: number;
    public title: string;
    public text: string;
    public type: string;
    public color: string;
    public icon: string;
    public tags: Array<TagViewModel>;

    constructor(init?: Partial<StoryStepViewModel>) {
        Object.assign(this, init);
    }
}