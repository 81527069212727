import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmEmailService } from '@modules/shared/services/confirm-email.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css']
})
export class ConfirmEmailComponent implements OnDestroy {
  private activeRouteSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private confirmEmailService: ConfirmEmailService,
    private router: Router
  ) {
    this.activeRouteSubscription = this.activatedRoute.params.subscribe(params => {
      if (params.token) {
        this.confirmEmailService.confirmEmail(params.token).subscribe(data => {
          this.router.navigate(["/user/settings/emails"]);
        })
      }
    });
  }

  ngOnDestroy() {
    this.activeRouteSubscription.unsubscribe();
  }

}
