import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { LikeEntity } from '../entities';
import { LikeState } from '../states';

export const likeAdapter: EntityAdapter<LikeEntity> = createEntityAdapter<LikeEntity>();

export const initialState: LikeState = likeAdapter.getInitialState({
    isLoading: false,
    isLoaded: false
});