import { DirectoryOutputApiModel } from '../models/api-models/output';

export interface DirectoryEntity {
    id: number;
    displayName: string;
    avatar: string;
}

export class DirectoryEntity {
    constructor(data?: DirectoryOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: DirectoryOutputApiModel) {
        this.id = data.id;
        this.displayName = data.displayName;
        this.avatar = data.avatar;
    }
}
