export interface ImageGallerySettingOutputApiModel {
    id: number;
    is_active: boolean;
    image: {
        height: number;
        id: number;
        origname: string;
        size: number;
        url: string;
        width: number;
    }
}

export class ImageGallerySettingOutputApiModel {
    constructor(data?: Partial<ImageGallerySettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}