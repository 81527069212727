import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService } from '@framework/services';
import { ofType, Actions } from '@ngrx/effects';
import { storyActions, storyStepActions } from '@modules/story/store/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { AuthService } from '@framework/services';

@Component({
  selector: 'Edit-steps-container',
  templateUrl: './Edit-steps-container.component.html',
  styleUrls: ['./Edit-steps-container.component.scss'],
})

export class EditStepsContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public copyForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public activeTab: string;
  public fromTrcking: boolean = false;
  public currentUser:any= false;
  private AddtrackingsuccessSubscription: Subscription;
  private rssToken: string;
  public isShow:boolean = false;
  public disabled:boolean = false
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private actions$: Actions,
    private store$: Store<CoreState>,
    private toastService: ToastService,
    private authService: AuthService,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })
    this.copyForm.controls.title.setValue(this.initialState.title);
    this.copyForm.controls.name.setValue(this.initialState.text);
    
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
    this.copyForm = this.formBuilder.group({
       title:['',Validators.required],
       name:['',[Validators.required,Validators.maxLength(100)]]
    })


  }
  save(){

     if(this.initialState.fromInbound){
      let testObj = {
        title:this.copyForm.value.title,
        text:this.copyForm.value.name
      }
      this.store$.dispatch(new storyStepActions.UpdateStepTag(this.initialState.companyId, this.initialState.id, true, true, testObj));
      setTimeout(() =>{
        this.close();
      },300)
     }
     else{
      let testObj = {
        title:this.copyForm.value.title,
        text:this.copyForm.value.name
      }
      this.store$.dispatch(new storyStepActions.UpdateStepTag(this.initialState.companyId, this.initialState.id, false, true, testObj));
      setTimeout(() =>{
        this.close();
      },300)
     }

  }


  close(){
    this.onClose.emit(true)
  }

}
