import { ContributionOutputApiModel } from "./contribution-output.api-model";

export interface StoryDetailAuthorOutputApiModel {
    author_id: number;
    avatar: string;
    display_name: string;
    display_title?:any;
    title: string;
    relationship: string;
    company_name: string;
    company_slug: string;
    company_website: string;
    slug: string;
}

export interface StoryDetailMediaOutputApiModel {
    fullsize: string;
    id: number;
    preview: string;
    type: string;
    save_in?: number
    source?: any;
    asset_id?: any;
    url?:any
    aspect_ratio?:any
    thumbnail_url?:any;
    
}

export interface StoryDetailCtaOutputApiModel {
    text: string;
    button: string;
    config: any;
    donate_button_content?: any
    donate_button_script?: any;
    donate_button_text?:any
    show_author_information?: boolean;
    show_jobs?:boolean;
    form_title?:any;
    link: string;
    type: string
}

export class StoryDetailCtaOutputApiModel {
    constructor() { }
}

export interface StoryDetailCommentApiModel {
    comment_id: number;
    content: string;
    player_id: number;
}

export interface StoryDetailCompanyApiModel {
    company_id: number;
    name: string;
    website: string;
    logo: string;
}

export interface StoryFollowApiModel {
    id: number;
    player_id: number;
}

export interface StoryContributionApiModel {
    id: number;
    avatar: string;
    display_name: string;
    player_id: number;
    data: any;
    like_id: number;
    slug: string;
}

export interface HighlightTagsApiModel {
    text: string;
    color: string;
    sort: number;
}

export interface ContributorApiModel {
    player_id: number;
    display_name: string;
    avatar: string;
}

export interface StepDataPlayerApiModel {
    display_name: string;
    slug: string;
}

export interface StepDataTagApiModel {
    id: number;
    name: string;
}

export interface StepDataItemApiModel {
    title: string;
    type: string;
    color: string;
    sort: number;
    data: any;
}

export interface StepDataApiModel {
    [key: number]: StepDataItemApiModel
}

export interface CtaFormConfigApiModel {
    title: string;
    link: string;
    text: string;
    is_redirection: boolean;
    is_required_info: boolean;
    thank_you_text: string;
    is_enabled?:boolean;
}

export interface StoryOutputApiModel {
    id: number;
    title: string;
    slug: string;
    content: string;
    content_raw: string;
    campaign_id_slug: number | string;
    media: StoryDetailMediaOutputApiModel[];
    company_id_slug: number | string;
    public: boolean;
    internal?: boolean;
    published_to_draft?: any
    approved: boolean;
    completed: boolean;
    time_created: Date;
    created_by: number;
    published_at: Date;
    published_by: number;
    steps_data: StepDataApiModel;
    steps: any;
    cta: StoryDetailCtaOutputApiModel;
    author: StoryDetailAuthorOutputApiModel;
    author_inbound_type: string;
    likes_count: number;
    comments_count: number;
    inbound: boolean;
    like_id: number;
    follow_id: number;

    comments: StoryDetailCommentApiModel[];
    company: StoryDetailCompanyApiModel;
    follows: StoryFollowApiModel[];
    status: number;
    type: string;
    contributions: ContributionOutputApiModel[];
    liked: boolean;
    highlight_tags: HighlightTagsApiModel[];
    contributors: ContributorApiModel[];
    cta_form_configs: CtaFormConfigApiModel[];

    public_requested: boolean;
    permission: boolean;

    unique_id: string;
    internal_profile_public: boolean;
    external_profile_public: boolean;
    external_anonymize: boolean;
    cta_interactions_count: number;
    campaign_id: string | number;
    id_campaign: string | number;
    prev_story_slug: string;
    next_story_slug: string;
    archived: boolean;
    boost_requested: boolean;
    is_name_promotable: number;
    tags_count_data: TagsCountData[];
    requested_by:any;
    deleted:any;
    video_token?: any;
    story_prompt?: any;
    question_id_campaign?:any;
    is_footnote_included?: boolean;
    interview_content?:any;
    views_count?:any;
    interview_style?:boolean;
    default_prompt?:any;
    thumbnail_url?:any
    show_prompt?:any;
    show_relationship_public?:boolean;
    show_company_public?: boolean;
    show_name_public?: boolean;
    show_title_public?: boolean;

    is_relationship_public?:boolean;
    is_company_public?: boolean;
    is_name_public?: boolean;
    is_title_public?: boolean;
    consent_details?: any;
}

export interface TagsCountData {
    count: number;
    tag_id: number;
}

export class StoryOutputApiModel {
    constructor(data?: Partial<StoryOutputApiModel>) {
        if (data)
            Object.assign(this, data);
        this.formatFields();
    }

    private formatFields() {
        if (this.id) {
            this.id = Number(this.id);
        }

        if (this.steps) {
            for (const key in this.steps) {
                if (Array.isArray(this.steps[key])) {
                    this.steps[key] = this.steps[key].map(r => {
                        return Number(r) || r;
                    })
                } else {
                    this.steps[key] = Number(this.steps[key]) || this.steps[key];
                }
            }
        }

        this.content_raw = this.content;
    }
}
