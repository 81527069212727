import { MemoizedSelector, createSelector, createFeatureSelector } from "@ngrx/store";


import { isString } from "util";

import { UserFeatureState, UserPermissionState, UserSettingState } from "../states";
import { selectUserFeatureState } from "./user-feature.selectors";

export const selectUserPermissionState: MemoizedSelector<object, UserPermissionState> = createSelector(selectUserFeatureState, (state: UserFeatureState) => state.userPermission);


// -------------[Mapping Functions]------------- 
const getPermission = (state: UserPermissionState) => {
    return state;
}


// -------------[Public Selectors]-------------

export const selectPermsion: MemoizedSelector<any, any> = createSelector(selectUserPermissionState, getPermission);
