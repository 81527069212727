import { MemoizedSelector, createSelector } from '@ngrx/store';

import { CompanyFeatureState, CampaignState } from '../states';
import { selectCompanyFeatureState } from './company-feature.selectors';
import { campaignAdapter } from '../adapters/campaign.adapter';
import { LoadStatusModel } from '@modules/shared/models';
import { CampaignViewModel } from '@modules/company/models/store-view-models';
import { CampaignEntity } from '../entities';


export const selectCampaignState: MemoizedSelector<object, CampaignState> = createSelector(selectCompanyFeatureState, (state: CompanyFeatureState) => state.campaign);

const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAllItems,
    selectTotal: selectTotal
} = campaignAdapter.getSelectors(selectCampaignState);


// -------------[Mapping Functions]-------------
const getLoadStatus = (state: CampaignState): LoadStatusModel => {
    return {
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
        isCanLoadMore: state.nextUrl ? state.nextUrl.length > 0 : null,
    };
};
const getIsLoaded = (state: CampaignState) => state.isLoaded;
const getSelectedId = (state: CampaignState): number => {
    return state.selectedId;
};
const mapAllToViewModel = (entities: CampaignEntity[]): CampaignViewModel[] => {
    return entities.map(entity => new CampaignViewModel(entity));
};
const getSelectedCampaign = (entities: CampaignEntity[], selectedId: number): CampaignViewModel => {
    if (!selectedId) return null;
    const result = entities.find(item => item.id === selectedId);
    return result ? new CampaignViewModel(result) : null;
};
// -------------[Public Selectors]-------------
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> = createSelector(selectCampaignState, getLoadStatus);
export const selectIsLoaded: MemoizedSelector<object, boolean> = createSelector(selectCampaignState, getIsLoaded);
export const selectSelectedId: MemoizedSelector<object, number> = createSelector(selectCampaignState, getSelectedId);
export const selectAll: MemoizedSelector<object, CampaignViewModel[]> = createSelector(selectAllItems, mapAllToViewModel);
export const selectCampaign: MemoizedSelector<object, CampaignViewModel> =
    createSelector(selectAllItems, selectSelectedId, getSelectedCampaign);
