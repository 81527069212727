import { Component, SimpleChanges } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { ComponentModalBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { companySelectors } from '@modules/company/store/selectors';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { storySelector } from '@modules/story/store/selectors';
import { StoryViewModel } from '@modules/story/models/view-models';
import { storyActions } from '@modules/story/store/actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pb-delete-story-container',
  templateUrl: './delete-story-container.component.html',
  styleUrls: ['./delete-story-container.component.css']
})
export class DeleteStoryContainerComponent extends ComponentModalBase<null> {
  // Private variables
  private selectCompany$: Observable<CompanyViewModel>;
  private selectStoryDetail$: Observable<StoryViewModel>;

  private selectCompanySubscription: Subscription;
  private deleteStorySuccessSubscription: Subscription;
  // Public variables
  public currentCompany: CompanyViewModel;
  public storyDetail: StoryViewModel;

  constructor(private store$: Store<CoreState>, private actions$: Actions, private router: Router) {
    super();

    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.
      pipe(
        withLatestFrom(
          this.selectStoryDetail$
        )
      )
      .subscribe(([company, storyDetail]) => {
        if (company && storyDetail) {
          this.currentCompany = company;
          this.storyDetail = storyDetail;
        }
      });

    this.deleteStorySuccessSubscription = this.actions$.pipe(
      ofType<storyActions.DeleteSuccess>(storyActions.ActionTypes.DELETE_SUCCESS)
    ).subscribe(() => {
      this.onClose.emit();
      this.router.navigate(['/company']);
    });
  }

  private unsubscribe() {
    this.selectCompanySubscription.unsubscribe();
    this.deleteStorySuccessSubscription.unsubscribe();
  }
  // Public functions
  public cancel() {
    this.onClose.emit();
  }

  public delete() {
    this.store$.dispatch(new storyActions.Delete(this.storyDetail.id));
  }
}
