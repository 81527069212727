
import { Component, Input, ElementRef, OnChanges, Renderer2, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';

import { environment } from '@app/env/environment';

@Component({
    selector: 'video-control',
    templateUrl: './video-control.component.html',
    styleUrls: ['./video-control.component.scss']
})
export class VideoControlComponent implements OnChanges, OnDestroy {
    @Input() src: string = '';
    @Input() type: string;
    @Input() autoPlay: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() start?: number;
    @Input() end?: number;

    @Output() videoLoaded: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('videoControl')
    videoControl: ElementRef;
    RetryInterval;

    private cloudinaryDomain: string = 'res.cloudinary.com';
    private isIgnoreOnPlayEvent: boolean = true;
    private isManualMode: boolean = false;

    public srcWebm: string = '';
    public srcOgv: string = '';
    public srcMp4: string = '';
    public srcPoster: string = '';
    constructor(private el: ElementRef, private renderer: Renderer2) {

    }

    ngOnChanges() {
        this.generateVideoSource();

        if (this.autoPlay) {
            setTimeout(() => {
                this.previewCase();
            }, 1000);
        } else {
            setTimeout(() => {
                this.normalCase();
            }, 1000);
        }

        setTimeout(() => {
            this.reloadVideo();
        }, 200);
    }

    // Private functions
    private previewCase() {
        if (this.videoControl) {
            // this.renderer.setAttribute(this.videoControl.nativeElement, 'playsinline', 'playsinline');
            //  this.renderer.setAttribute(this.videoControl.nativeElement, 'loop', 'loop');
            this.renderer.setAttribute(this.videoControl.nativeElement, 'autoplay', 'autoplay');

            this.renderer.setAttribute(this.videoControl.nativeElement, 'controls', 'controls');
            setTimeout(()=>{
            this.videoControl.nativeElement.pause();
            },1000)
            this.videoControl.nativeElement.currentTime = 0;
   
            this.videoControl.nativeElement.muted = true;
        }
    }

    private normalCase() {
        // this.renderer.setAttribute(this.videoControl.nativeElement, 'playsinline', 'playsinline');
        // this.renderer.setAttribute(this.videoControl.nativeElement, 'loop', 'loop');
        // this.renderer.setAttribute(this.videoControl.nativeElement, 'autoplay', 'autoplay');
        this.videoControl.nativeElement.muted = true;
        this.renderer.setAttribute(this.videoControl.nativeElement, 'controls', 'controls');
        this.videoControl.nativeElement.onmouseover = this.onMouseOver.bind(this);
        this.videoControl.nativeElement.onmouseout = this.onMouseOut.bind(this);
        this.videoControl.nativeElement.onplay = this.onPlay.bind(this);
    }

    private reloadVideo() {
        this.el.nativeElement.firstElementChild.load();
        this.videoControl.nativeElement.onloadeddata = this.onVideoLoaded.bind(this);
    }

    private generateVideoSource() {
        if (!this.src) return;

        if (this.src.indexOf(this.cloudinaryDomain) > -1) {
            this.oldCloudinaryCase();
            return;
        }

        if (this.src.indexOf(environment.cloudinary.s3RootUrl) > -1) {
            this.s3Case();
            return;
        }

        this.default();
    }

    private oldCloudinaryCase() {
        let videoSrc = environment.cloudinary.convertToNewCdnUrl(this.src);
        const relativePath = videoSrc.split(environment.cloudinary.cdnVideoRootUrl).pop();
        const relativePathWithoutExt = relativePath.indexOf('.') > -1 ? videoSrc.substring(0, videoSrc.lastIndexOf('.')) : videoSrc;

        // Webm
        this.srcWebm = `${relativePathWithoutExt}.x-matroska`;
        // Ogv
        this.srcOgv = `${relativePathWithoutExt}.ogv`;
        // Mp4
        this.srcMp4 = `${relativePathWithoutExt}.mp4`;
        // Poster
        this.srcPoster = `${relativePathWithoutExt}.jpg`;
    }

    private s3Case() {
        const s3RelativePath = this.src.split(environment.cloudinary.s3RootUrl).pop();
        const s3RelativePathWithoutExt = s3RelativePath.split('.').shift();

        let transform = '';

        if (this.start && this.end) {
            transform = `so_${this.start},eo_${this.end}` + transform;
        }

        if (this.height > 0) {
            transform += `,h_${this.height}`;
        }

        if (this.width > 0) {
            transform += `,w_${this.width}`;
        }

        // Webm
        const transform_webm = transform + (transform.length > 0 ? ',f_webm' : 'f_webm');
        this.srcWebm = `${environment.cloudinary.cdnVideoRootUrl}/upload/${transform_webm}/${environment.cloudinary.keyPrefix}${s3RelativePathWithoutExt}.x-matroska`;
        // Ogv
        const transform_ogv = transform + (transform.length > 0 ? ',f_ogv' : 'f_ogv');
        this.srcOgv = `${environment.cloudinary.cdnVideoRootUrl}/upload/${transform_ogv}/${environment.cloudinary.keyPrefix}${s3RelativePathWithoutExt}.ogv`;
        // Mp4
        const transform_mp4 = transform + (transform.length > 0 ? ',f_mp4' : 'f_mp4');
        this.srcMp4 = `${environment.cloudinary.cdnVideoRootUrl}/upload/${transform_mp4}/${environment.cloudinary.keyPrefix}${s3RelativePathWithoutExt}.mp4`;
        // Poster
        this.srcPoster = this.getPoster();
    }

    private default() {
        // Webm
        this.srcWebm = this.src;
        // Ogv
        this.srcOgv = this.src;
        // Mp4
        this.srcMp4 = this.src;
        // Poster
        this.srcPoster = this.src;
    }

    private onVideoLoaded() {
        if (this.RetryInterval) {
            clearInterval(this.RetryInterval);
        }
        this.videoLoaded.emit();
    }

    private getPoster() {
        const s3RelativePath = this.src.split(environment.cloudinary.s3RootUrl).pop();
        const s3RelativePathWithoutExt = s3RelativePath.split('.').shift();

        let transform = 'e_preview';

        if (this.width > 0 || this.height > 0) {
            transform += ',c_fill';
        }

        if (this.width > 0) {
            transform += `,w_${this.width}`;
        }

        if (this.height > 0) {
            transform += `,h_${this.height}`;
        }

        return `${environment.cloudinary.cdnVideoRootUrl}/upload/${transform}/${environment.cloudinary.keyPrefix}${s3RelativePathWithoutExt}.jpg`;
    }
    // Public methods
    public onMouseOver() {
        if (this.isManualMode) return;
        // this.videoControl.nativeElement.pause();
        // this.videoControl.nativeElement.currentTime = 0;
        this.isIgnoreOnPlayEvent = false;
    }

    public onMouseOut() {
        if (this.isManualMode) return;
        // this.videoControl.nativeElement.play();
        this.isIgnoreOnPlayEvent = true;
    }

    public onPlay() {
        if (!this.isIgnoreOnPlayEvent) {
            this.videoControl.nativeElement.volume = 1;
            this.videoControl.nativeElement.muted = false;
            this.isManualMode = true;
        }
    }

    waitAndReload() { 
        if (!this.RetryInterval) {
            this.RetryInterval = setInterval(() => {
                this.reloadVideo();
            }, 2000);
        }
    }          

    ngOnDestroy() {
        if (this.RetryInterval) {
            clearInterval(this.RetryInterval);
        }
    }
}
