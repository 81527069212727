import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EmailsSettingHttpService } from '../services';
import { emailsSettingAction } from '../actions';


@Injectable()
export class EmailsSettingEffects {

    constructor(
        private actions$: Actions,
        private emailsSettingHttpService: EmailsSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<emailsSettingAction.Load>(emailsSettingAction.ActionTypes.LOAD),
        switchMap((action: emailsSettingAction.Load) => {
            return this.emailsSettingHttpService.load(action.companyId).pipe(
                map((respone) => {
                    return new emailsSettingAction.LoadSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new emailsSettingAction.LoadFailure(error));
                }));
        })
    ));


    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<emailsSettingAction.Update>(emailsSettingAction.ActionTypes.UPDATE),
        switchMap((action: emailsSettingAction.Update) => {
            return this.emailsSettingHttpService.update(action.companyId, action.payload).pipe(
                map(() => {
                    return new emailsSettingAction.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new emailsSettingAction.UpdateFailure(error));
                }))
        })
    ));
}