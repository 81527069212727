export interface EmailCustomizeSettingOutputApiModel {
    id: number;
    title: string;
    subject: string;
    body: string;
    is_active: boolean;
    email_user_type: string;
}

export class EmailCustomizeSettingOutputApiModel {
    constructor(data?: Partial<EmailCustomizeSettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
