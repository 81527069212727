import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';

@Component({
  selector: 'copy-video-embed-modal-container',
  templateUrl: './copy-video-modal-container.component.html',
  styleUrls: ['./copy-video-modal-container.component.scss'],
})

export class CopyVideoModalContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public copyForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public activeTab: string;
  private rssToken: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {
    this.copyForm.patchValue({
      framebodyCode: this.initialState.videoLink,
      embedbodyCode: this.initialState.embedLink,
    })
    this.copyForm.controls.styledbodyCode.setValue(`    /* Main area for all stories */
    .container {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding-top: 56.25%; /* 16:9 Aspect Ratio */
    }

    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
`)

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
    this.copyForm = this.formBuilder.group({
      height: [900, Validators.required],
      width: [100, Validators.required],
      widthList: ['px'],
      heightList: ['px'],
      bodyCode: [{ value: '', disabled: true }],
      framebodyCode: [{ value: '', disabled: true }],
      embedbodyCode: [{ value: '', disabled: true }],
      styledbodyCode: [{ value: '', disabled: true }],
    });



    // this.copyForm = this.formBuilder.group({
    //   height: [900, Validators.required],
    //   width: [100, Validators.required],
    //   widthList:['px'],
    //   heightList:['px'],
    //   bodyCode: [{ value: '', disabled: true }],
    //   selectCheckbox: ['public', Validators.required],
    //   selectRadio: [false, Validators.required]
    // })


    // this.copyForm = this.formBuilder.group({
    //  value:[{ value: '', disabled: true }],
    //  publicValue:[{ value: '', disabled: true }]
    // })

  }

  get updatedIframeCode() {
    var iframeContext = `<div class="container"> <iframe class="responsive-iframe" src="${this.initialState.embedLink}"`
    iframeContext += ' height="' + this.copyForm.value.height + this.getHeight() + '"' + ' width="' + this.copyForm.value.width + this.getwidth() + '"';
    iframeContext += ' frameborder="0"' + "></iframe></div>";
    this.copyForm.controls.bodyCode.setValue(iframeContext)

    return;
  }
  getHeight() {
    if (+this.copyForm.value.height > 100 && this.copyForm.value.heightList == '%') {
      this.copyForm.controls.height.setValue(100);
      return this.copyForm.value.heightList
    }
    else {
      return this.copyForm.value.heightList
    }
  }
  getwidth() {
    if (+this.copyForm.value.width > 100 && this.copyForm.value.widthList == '%') {
      this.copyForm.controls.width.setValue(100);
      return this.copyForm.value.widthList
    }
    else {
      return this.copyForm.value.widthList
    }
  }

  public close() {
    this.onClose.emit();
  }


}
