import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ComponentModalBase } from '@framework/base';

@Component({
  selector: 'app-email-confirmation-modal',
  templateUrl: './email-confirmation-modal.component.html',
  styleUrls: ['./email-confirmation-modal.component.css']
})
export class EmailConfirmationModalComponent extends ComponentModalBase<any> {

  public emailConfigForm: UntypedFormGroup;
  constructor( private formBuilder: UntypedFormBuilder,private router: Router) {
    super();
    this.initForm();
  }

  protected onInit(): void {
    console.log(this.initialState);
    this.setValuetoSubjectandBody()
   }

  protected onDestroy(): void { }

  protected onChanges(): void { }

  protected onDoCheck(): void { }

  protected onAfterContentInit(): void { }

  protected onAfterContentChecked(): void { }

  protected onAfterViewInit(): void { }

  protected onAfterViewChecked(): void { }

  public close() {
    let payload = {
      suject:this.emailConfigForm.value.subject,
      body:this.emailConfigForm.value.body,
      enable_advocacy: this.emailConfigForm.value.enable_advocacy_link,
      internal_advocacy:this.emailConfigForm.value.enable_advocacy_link ? this.emailConfigForm.value.internal_advocacy : false,
      public_advocacy: this.emailConfigForm.value.public_advocacy ? this.emailConfigForm.value.public_advocacy : false,
      option:4
    }
    this.onClose.emit(payload);
  }

  private initForm() {
    this.emailConfigForm = this.formBuilder.group({
      subject: ['Thank You'],
      body: [`Thank you for your role in the story <a href=\"{storyLink}\" style=\"color: #ed804b; text-decoration: none; font-weight: bold;\">{storyTitle}</a>. If you feel inspired there are a number of places online where you can share your experience. These are all important to us.`],
      enable_advocacy_link: [false],
      sendTo:['1'],
      internal_advocacy:[false],
      public_advocacy:[false]
    });

  }

  public save() {
    let payload = {
      subject:this.emailConfigForm.value.subject,
      body:this.emailConfigForm.value.body,
      enable_advocacy: this.emailConfigForm.value.enable_advocacy_link,
      option:+this.emailConfigForm.value.sendTo,
      internal_advocacy:this.emailConfigForm.value.enable_advocacy_link ? this.emailConfigForm.value.internal_advocacy : false,
      public_advocacy: this.emailConfigForm.value.public_advocacy ? this.emailConfigForm.value.public_advocacy : false,
    }
    this.onClose.emit(payload);
  }
  setValuetoSubjectandBody(){
    this.emailConfigForm.controls.subject.setValue(this.initialState.subject);
    this.emailConfigForm.controls.body.setValue(this.initialState.body);

  }

  navigateToAdvocacy(){ 
    this.router.navigate(['/company/settings/advocacy'])
     this.close();
  }

}
