import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'gs-icon',
  templateUrl: './gs-icon.component.html',
  styleUrls: ['./gs-icon.component.scss'],
})
export class GsIconComponent implements OnInit {

  @Input('name')
  iconName: string;

  constructor() { }

  ngOnInit() {}

}
