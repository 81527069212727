
import { Component, HostListener, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ofType, Actions } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from '@app/env/environment';
import * as _ from 'lodash';
import { Page } from '@framework/base/page';
import { UtilityHelper } from '@framework/utilities';
import { CoreState } from '@app/store/core/states';
import { storyActions } from '@modules/story/store/actions';
import { storySelector } from '@modules/story/store/selectors';
import { StepDataItemViewModel, StoryViewModel } from '@modules/story/models/view-models';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { companyActions } from '@modules/company/store/actions';

import { GsTokenModel } from '@modules/shared/models/common';
import { Constants } from '@modules/shared/constants';
import { StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';
import { StoryOutputApiModel } from '@modules/story/models/api-models/output';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from '@modules/shared/models/common/seo.model';
import { AuthService } from '@framework/services';
import { campaignActions } from '@modules/company/store/actions';
import { CampaignViewModel } from '@modules/company/models/store-view-models';
import { campaignSelectors } from '@modules/company/store/selectors';
import * as adminStoryActions from '@modules/company-admin/store/actions';
import { ApprovalmodalComponent, CopyEmbedVideoModalContainerComponent, CopyLinkContainerComponent, CopyVideoModalContainerComponent, EmailConfirmationModalComponent, ShareModalContainerComponent } from '@modules/shared/components';
import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalService, ToastService } from '@framework/services';
import { ScriptActions } from '@modules/company-admin/store/actions';
import { isPlatformBrowser } from '@angular/common';
import { StoryHttpService } from '@modules/story/services';
import * as Sservice  from '@modules/company-admin/store/services';
import {  LoadingPopupService } from '@framework/services';
declare const gtag: Function;
declare var dataLayer;
declare var window:any;
declare var $: any;
let globalTag;
let measuremnetId;
let pagename;
@Component({
  selector: 'app-unique-story-detail',
  templateUrl: './unique-people-detail-page.component.html',
  styleUrls: ['./unique-people-detail-page.component.scss']
})
export class UniqueStoryDetailComponent extends Page {
  // Private variables
  isSmallScreen: boolean = window.innerWidth <= 895;

  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    this.isSmallScreen = window.innerWidth <= 895;
  }
  private idOrSlug: number | string;
  public backgroundImageUrl:string;
  public showPromptonInterviewStyle:boolean = false;
  private selectCompany$: Observable<CompanyViewModel>;
  private selectStatusDetail$: Observable<StoryLoadStatusViewModel>;
  private baseUrl: string = environment.baseUrl;
  public showQuestion:any;
  public spotlightId: any;
  public backgroundImageColor:boolean = false;
  private imageUrl: any;
  public isShowText:boolean = true
  public mediaPayload:any[] = [];
  public  activeIndex = 0;

  private selectCompanySubscription: Subscription;
  private storyDetailSuccessSubscription: Subscription;
  private loadStoryDetailFailureSubscription: Subscription;
  private loadStoryDetailSuccessSubscription: Subscription;
  private selectStatusDetailSubscription: Subscription;
  private LoadSeoSuccessSubscription: Subscription;
  private selectCampaignSubscription: Subscription;
  private getCampaignDetailSubscriptiom: Subscription;
  private LoadSuccessSubscriptin: Subscription;
  private selectCampaignDetail$: Observable<CampaignViewModel>;
  private AddtrackingsuccessSubscription: Subscription;
  private GettrackingsuccessSubscription: Subscription;
  private selectCampaign$: Observable<CampaignViewModel[]>;
  private queryCampaignsData: GetCampaignInputApiModel = new GetCampaignInputApiModel(new PaginationModel(10));
  public isDelted:boolean = true;
  public companySlug:any;
  public isvideo: boolean = false;
  public messageData: any;
  // Public variables
  public currentCompany: CompanyViewModel;
  public storyDetail: StoryViewModel;
  public isBackgroundBlack:boolean = false;
  public isEmbedMode: boolean = false;
  public isClickedPublic:any;
  public seoDetails: any;
  public url: SafeUrl;
  public trackingId: any;
  public statusDetail: StoryLoadStatusViewModel;
  public campaigns: CampaignViewModel[] = [];
  public isDeleted: boolean = true;
  public campaignId: any;
  public spotlightQuery: any;
  public isCopyShareUrl: boolean = false;
  public isShareSocialUrl: boolean = false;
  public isshowTrackinid: boolean = false;
  public queeryTrackingId:any;
  public showToken: boolean = false;
  public showMessageBx:boolean = true;
  public isnotSeen:boolean = true;
  public showmessagebody:boolean = false;
  public showEntireProcess: boolean = false
  public isLoadCampaignDeatil:boolean = false;
  private loadColorSuccessSubscription: Subscription;
  public primaryColor:any;
  public accentColor:any; 
  public trackingid: any;
  public  isBrowser: boolean;
  public showDetailBoard:boolean = false;
  public uniqueToken: any;
  public no_Tags: boolean = false;
  public interviewcontent:any;
  quillData:string;
  quillModule = {
    toolbar:[{}],
  }

  constructor(
    private utility: UtilityHelper,
    private store$: Store<CoreState>,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions,
    private storyHttpService: StoryHttpService,
    @Inject(PLATFORM_ID) platformId: Object,
    private router: Router,
    private landingPopupService: LoadingPopupService,
    private seoService: SeoService,
    private authService: AuthService,
    private StoryHttpService: Sservice.StoryHttpService,
    public sanitizer: DomSanitizer,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
    // this.checkIsAuthenticated();
    this.isBrowser = isPlatformBrowser(platformId);
    this.captureParams();
    this.loadDetail();

    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectStatusDetail$ = this.store$.select(storySelector.selectLoadStatus);
    this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    this.selectCampaign$ = this.store$.select(campaignSelectors.selectAll);
  }
  @HostListener('window:resize', ['$event'])
  public resizeEvent(event: any) {
  this.setFrameHeight();
  }
  // Life cycle hook
  protected onInit(): void {
  
    this.subscribe();
    this.activatedRoute.queryParams.subscribe((res)=>{
      if(res && res.tr){
        let obj ={"tracking_id":res.tr}
        this.trackingId =res.tr;
        this.queeryTrackingId = res.tr;
        this.addtrackhit(obj)
      }
    })
  }
  protected onDestroy(): void {
    this.unsubscribe();
    this.removeScripts();
    // this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }

  /**
   * this method is to update meta tag according to story detail
   */
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
    console.log(">>>data<<<")
    // if(this.seoDetails)
    // {
    //   config.title = this.seoDetails.title ? this.seoDetails.title : this.storyDetail.title;
    //   config.description = this.seoDetails.description ? this.seoDetails.description : this.storyDetail.content;
    //   config.alt= this.seoDetails.altImage ? this.seoDetails.altImage : this.storyDetail.title
    // }
    // else
    // {
    //   config.title = `${this.storyDetail.company.name} - ${this.storyDetail.author.displayName} - ${this.storyDetail.title}`;
    //   config.description = this.storyDetail.content;
    //   config.alt= this.seoDetails.altImage ? this.seoDetails.altImage : this.storyDetail.title
    // }
    // config.title = this.seoDetails.title ? this.seoDetails.title : this.storyDetail.title;
    // config.description = this.seoDetails.description ? this.seoDetails.description : this.storyDetail.content;
    // config.alt= this.seoDetails.altImage ? this.seoDetails.altImage : this.storyDetail.title
    ///cchanges bfor multiple image below
  //  config.title =  this.storyDetail.title;
  //   config.description =  this.storyDetail.content;
  //   config.alt=  this.storyDetail.title
    
  //   config.url = `${environment.baseUrl}${this.router.url}`;
  //   if(this.storyDetail.media != undefined && this.storyDetail.media != null)
  //   {
    
  //     config.image = this.storyDetail.media.type !='video/mp4' ? this.storyDetail.media.preview : this.imageUrl;
  //   }
  //   config.keywords = this.getSeoKeywords();
  //   config.castLarge = true;
  //   this.seoService.updateMetaTags(config);

  config.title = `${this.storyDetail.company.name} - ${this.storyDetail.title}`;
  config.description = this.storyDetail.content;
  config.alt= this.storyDetail.title
config.url = 'https://'+this.companySlug+'.'+environment.customUrl+this.router.url;
if(this.imageUrl)
{
config.image = this.imageUrl
}
config.keywords = this.getSeoKeywords();
config.castLarge = true;
this.seoService.updateMetaTags(config);
  }

  public removeScripts(){
    if(this.isBrowser){
      $("#pvt__scripts").empty();
    }

   }

  // Private functions
  private getSeoKeywords(): string {
    let s = this.storyDetail.company.name;
    if (this.storyDetail.author.displayName) s += ', ' + this.storyDetail.author.displayName;
    if (this.storyDetail.stepsData) {
      for (var k1 in this.storyDetail.stepsData) {
        for (var k2 in this.storyDetail.stepsData[k1].data)
          s += ', ' + this.storyDetail.stepsData[k1].data[k2].label;
      }
    }
    return s;
  }

  checkIsAuthenticated() {
    const idOrSlugParam = this.activatedRoute.snapshot.paramMap.get('idOrSlug');
    this.authService.isAuthenticated().then(data => {
      if (data) {
        // this.router.navigate([`/company/${idOrSlugParam}`]);
      }
    })
  }


  currentSlide(i){
    if(this.mediaPayload[i].type && this.mediaPayload[i].type.indexOf('video') == -1){
      const originalUrl = this.mediaPayload[i].imageUrl;
  
      // Base URL
      const baseUrl = 'https://ik.imagekit.io/lpzxd7bmi';
      
      // Transformation string
      const transformation = 'tr:bl-100';
      
      // Path after the base URL
      const path = originalUrl.substring(baseUrl.length);
      
      // Construct the new URL
      const newUrl = `${baseUrl}/${transformation}${path}`;
  
      this.backgroundImageUrl = `url(${newUrl})`
    }
    this.activeIndex = i; 
  }
  
  private subscribe() {
    this.storyDetailSuccessSubscription = this.storyHttpService.getStoryDetail(this.idOrSlug,null,true,null,null).subscribe((response:any)=>{
      this.storyDetail = response;
      if (this.storyDetail && this.storyDetail.id > 0) {
        var data = this.getStepsData();
        if(data && data.length){
          this.no_Tags = false;
        }
        else{
          this.no_Tags = true;
        }
       if(this.storyDetail && this.storyDetail.media &&  this.storyDetail.media && this.storyDetail.media.length  && this.storyDetail.media[0].type =='video/mp4' ){
           this.imageUrl = this.getUrl(this.storyDetail.media[0])
           }
        else if(this.storyDetail && this.storyDetail.media &&  this.storyDetail.media && this.storyDetail.media.length  && this.storyDetail.media[0].type !='video/mp4'){
        this.imageUrl = this.storyDetail.media[0].preview
         }
           this.addMetaTags();
           this.loadDetail();
           this.isDeleted = this.storyDetail.deleted
           if(this.isDeleted){
             this.router.navigate(['/common/not-found']);
           }
           if(this.storyDetail && this.storyDetail?.story_prompt){
             this.showQuestion = this.storyDetail?.story_prompt;
           }
           if(this.storyDetail && this.storyDetail.media &&  this.storyDetail.media && this.storyDetail.media.length){
            
             this.mediaPayload  = [];
              for(let i=0 ; i< this.storyDetail.media.length ; i++){
               var payload:PublicMeediaPayload = new PublicMeediaPayload();
                 if(this.storyDetail.media[i].type != "video/mp4"){
                   payload.type = this.storyDetail.media[i].type
                   payload.imageUrl = this.storyDetail.media[i].fullsize
                   this.mediaPayload.push(payload)
                 }
                 else{
                   payload.type = this.storyDetail.media[i].type
                   payload.url = this.storyDetail.media[i].source;
                   payload.source = this.storyDetail.media[i].source
                   payload.thumbnail_url = this.storyDetail.media[i].thumbnail_url ? this.storyDetail.media[i].thumbnail_url : 'https://image.mux.com/'+this.storyDetail.media[i].source+'/thumbnail.jpg'
                   payload.title = this.storyDetail?.company?.name
                   payload.asset_id = this.storyDetail.media[i].asset_id
                   this.mediaPayload.push(payload)
                 }
   
                 
              }

              if(this.mediaPayload.length){
                this.currentSlide(0)
              }
   
           }
   

            
           this.quillData = this.storyDetail?.content
           this.interviewcontent = this.storyDetail?.interview_content
           // this.store$.dispatch(new storyActions.LoadSeo(this.storyDetail.id));
           /*Check SSR */
           if(this.storyDetail && this.storyDetail?.campaign_id && this.storyDetail?.campaign_id.toString() != '-1'){
            // this.loadCampaigns();
            this.isLoadCampaignDeatil = true;
             this.loadDetailCampaign();
          }
          else if(this.storyDetail && this.storyDetail?.story_prompt){
            this.showQuestion = this.storyDetail?.story_prompt
          }
          else if(this.storyDetail && (!this.storyDetail?.campaign_id || +this.storyDetail?.campaign_id == -1 ) && !this.storyDetail?.campaign_id && !this.storyDetail?.story_prompt) {
            if(this.storyDetail?.default_prompt){
              this.showQuestion = this.storyDetail?.default_prompt
            }
            else{
              this.showQuestion = ''
            }
          }
          else{
            this.showQuestion = ''
          }
           
           this.updateViewStatus();
           this.showDetailBoard = true
         }
   },(error)=>{
     console.log("detail function error")
   })

   this.getCampaignDetailSubscriptiom = this.actions$.pipe(
    ofType<campaignActions.LoadDetailSuccess>(campaignActions.ActionTypes.LOAD_DETAIL_SUCCESS)
  ).subscribe((action) => {
      if(action && action.data && this.isLoadCampaignDeatil){
        let question = action.data
    
          if(question && question.show_story_prompt){
            this.showPromptonInterviewStyle = true;
            let questionPromt = question.story_prompt.find(item => item.id == this.storyDetail.question_id_campaign);
            if(questionPromt){
              this.showQuestion = questionPromt.story_prompt;
        }
      }
        else{
          this.showPromptonInterviewStyle = false;;
              this.showQuestion = '';
        }

        this.isLoadCampaignDeatil = false
      }
  })
    this.GettrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetPersonalizeTrackHitSuccess>(storyActions.ActionTypes.GET_PERSONALIZE_TRACK_HIT_SUCCESS)
    ).subscribe(action => {
      if(action && action.data.length && this.showToken){
        this.showToken = false;
       this.messageData =  action.data[0];
        // c/:companySlug/:idOrSlug/:storytoken/:tracking/unique/people
      }
         

    });

    this.AddtrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.AddTrackingSuccess>(storyActions.ActionTypes.ADD_TRACKING_SUCCESS)
    ).subscribe(action => {
         this.trackingid = action.data[0].tracking_id
         if(this.trackingid && this.isshowTrackinid){
           this.isshowTrackinid = false;
           if(this.isCopyShareUrl){
             this.isCopyShareUrl = false;
             const linkToShare: string = `${window.location.origin}/c/${this.companySlug}/${this.storyDetail.slug}/?tr=${this.trackingid}`;
             this.copyStoryLink(linkToShare);
           }
           if(this.isShareSocialUrl){
             this.isShareSocialUrl = false;
             this.share();
           }

         }

    });
    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primaryColor = action.response.find(item => item.button == 12).color;
        this.accentColor = action.response.find(item => item.button == 13).color;
        // this.backgroundImageColor = action.response.find(item => item.button == 14).color;
       }
      

    })

    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) { 
        this.currentCompany = company; 
        pagename = this.currentCompany.name+'UNIQUE_DETAIL_PAGE';
        this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.storyDetail?.company?.companyId));
        this.loadScripts();
        //  this.loadCampaigns();
        }
     
    });

    this.LoadSuccessSubscriptin = this.actions$.pipe(
      ofType<ScriptActions.LoadSuccess>(ScriptActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((action:any) => {
         
      if(action && action.response.results && action.response.results.length){
        var scripts = action.response.results.filter(script => script.is_accessible);
        if(this.isBrowser){
          this.appendScriptinProduct(scripts);
        }

  
      }
    });

    this.selectCampaignSubscription = this.selectCampaign$.subscribe((campaigns) => {
      if(this.currentCompany && campaigns){
        var  currentDate = new Date()
        this.campaigns = campaigns;
        this.campaigns = this.campaigns.filter((campaign => (currentDate <= new Date(campaign.end_date)) && campaign.show_story_prompt));
        if(this.campaigns && this.campaigns.length){
          var question = this.campaigns.find(campaign=> +campaign.id == +this.storyDetail.campaign_id)
          if(question){
            let questionPromt = question.storyPrompt.find(item => item.id == this.storyDetail.question_id_campaign);
            if(questionPromt){
              this.showQuestion = questionPromt.story_prompt
            }
          }
        }
        else{
          this.showQuestion = ''
        }
      }
    });
    this.loadStoryDetailFailureSubscription = this.actions$.pipe(
      ofType<storyActions.LoadDetailFailure>(storyActions.ActionTypes.LOAD_DETAIL_FAILURE)
    ).subscribe(action => {
      if(this.isBrowser){
        this.router.navigate(['/user/login'], { queryParams: { link: encodeURI(btoa(this.router.url)) } });
      }

    });

    this.loadStoryDetailSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.LoadDetailSuccess>(storyActions.ActionTypes.LOAD_DETAIL_SUCCESS)
    ).subscribe(action => {
      this.checkPermission(action.data);
        // this.updateViewStatus();
      

    });

    this.selectStatusDetailSubscription = this.selectStatusDetail$.subscribe((statusDetail) => {
      this.statusDetail = statusDetail;
    });

    this.LoadSeoSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.LoadSeoSuccess>(storyActions.ActionTypes.LOAD_SEO_SUCCESS)
    ).subscribe((actions) => {
      if(actions.data)
      {
       this.seoDetails = actions.data
      //  this.addMetaTags();
    }
    else
    {
      // this.addMetaTags();
    }

    })

    this.activatedRoute.params.subscribe((data: any) => {
      if (data && data.idOrSlug) {
        this.idOrSlug = this.utility.Lodash.isString(data.idOrSlug) ? data.idOrSlug : parseInt(data.idOrSlug);
        this.loadDetail();
      }

    })
  }


  downloadVideo(media){
    this.landingPopupService.show()
    this.StoryHttpService.CheckStaticRedentionsisEadyorNot(this.currentCompany?.id,media.asset_id).subscribe((res:any)=>{
  
      if(res && res.static_renditions && res?.static_renditions?.files && res?.static_renditions?.files.length){
        let srcVideo = 'https://stream.mux.com/'+media.source+this.getextension(res?.static_renditions?.files)+'?download=media';
        this.landingPopupService.hide()
          let s = document.createElement('a');
          s.href = srcVideo;
          s.download = 'media';
          let divSpace = document.getElementById('downloadfile');
          divSpace.appendChild(s);
          s.click();
          divSpace.innerHTML = '';

        
      }
       console.log(res)
     })
  }

  getextension(data) : string {
    let str = ''
    if(data.length == 1){
      str+='/low.mp4'
    }
    if(data.length == 2){
      str+='/medium.mp4'
    }
    if(data.length == 3){
      str+='/high.mp4'
    }
    return str;
  
  }

  loadDetailCampaign(){

    this.store$.dispatch(new campaignActions.LoadDetail(this.storyDetail?.company['company_id'], this.storyDetail.campaign_id));
  }

  private unsubscribe() {
    if(this.storyDetailSuccessSubscription){
      this.storyDetailSuccessSubscription.unsubscribe();
    }

    this.loadStoryDetailFailureSubscription.unsubscribe();
    this.selectCompanySubscription.unsubscribe();
    this.selectStatusDetailSubscription.unsubscribe();
    this.loadStoryDetailSuccessSubscription.unsubscribe();
    this.getCampaignDetailSubscriptiom.unsubscribe();
    this.loadColorSuccessSubscription.unsubscribe();
    if(this.LoadSuccessSubscriptin){
      this.LoadSuccessSubscriptin.unsubscribe();
     }
   }

  
   get isMobile() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  private captureParams() {
    const idOrSlugParam = this.activatedRoute.snapshot.paramMap.get('idOrSlug');
    const companySlug = this.activatedRoute.snapshot.paramMap.get('companySlug');
    const storyUniqueToken = this.activatedRoute.snapshot.paramMap.get('storytoken');
    const tracingToken = this.activatedRoute.snapshot.paramMap.get('tracking');
    if(storyUniqueToken){
      this.uniqueToken = storyUniqueToken;
      this.queeryTrackingId = tracingToken;
      this.trackingId = tracingToken;
      let obj ={"tracking_id":this.queeryTrackingId};
      this.addtrackhit(obj)
    }
    else{
       this.router.navigate(['login'])
    }
    this.companySlug = companySlug
    const rawToken = this.activatedRoute.snapshot.paramMap.get('token');

    const currentUser = this.authService.cognito.currentUser();
    if (currentUser) {
      this.authService.setGsToken('', '');
      this.authService.cognito.userSession(currentUser).then((session) => {
        if (session.isValid()) {
          const rawToken = this.activatedRoute.snapshot.paramMap.get('token');
          const idOrSlugParam = this.activatedRoute.snapshot.paramMap.get('idOrSlug');
          this.activatedRoute.queryParams.subscribe((res)=>{
            console.log(res)
            this.isClickedPublic = res.public;
          })
          if (rawToken && idOrSlugParam) {
            this.router.navigate([`/company/${idOrSlugParam}`]);
          }
          else if(this.authService.isAuthenticated() && this.isClickedPublic != 1)
          {
            this.router.navigate([`/company/${idOrSlugParam}`]);
          }

        }
      });
    } else if (rawToken) {
      const data: GsTokenModel = {
        resource_id_slug: idOrSlugParam,
        resource_type: Constants.resourceType.story,
        token: rawToken
      };
      const dataBase64 = this.utility.EncodeHelper.base64(data);
      this.authService.setGsToken(dataBase64, rawToken);
    } 

    else if(this.authService.isAuthenticated())
    {

    }
    
    else {
      this.authService.setGsToken('', '');
    }


    this.store$.dispatch(new companyActions.LoadPublicInfo(companySlug));
    this.idOrSlug = this.utility.Lodash.isString(idOrSlugParam) ? idOrSlugParam : parseInt(idOrSlugParam);

    // Check embed mode
    const embedCode = this.activatedRoute.snapshot.queryParams.embed;
    if (embedCode && embedCode.length > 0) {
      this.isEmbedMode = true;
    }
    this.store$.dispatch(new storyActions.SetEmbedModeStatus(this.isEmbedMode));
  }


  get queryParamsDeatil(){
    if(this.campaignId){
      return {'campaignId':this.campaignId, 'public':1}
    }
    else if(this.spotlightId){
      return {'spotlightId':this.spotlightId, 'public':1}
    }
    else{
      return ''
    }

 }

 public appendScriptinProduct(script){

  let googleAnalyticsScript = script.find(scripttype => scripttype.type == 'GoogleAnalytics');

  if(googleAnalyticsScript && $("#pvt__scripts").html() === ""){
    this.googleAnalyticsScripts(googleAnalyticsScript.measurement_id)
    measuremnetId = googleAnalyticsScript.measurement_id
    setTimeout(()=>{
      gtag('event', 'page_view', {
        page_title: pagename,
        page_path: window.location.pathname,
        page_location: window.location.href,
        send_to: measuremnetId
     })
    },1000)
  }
  else{
    setTimeout(()=>{
      gtag('event', 'page_view', {
        page_title: pagename,
        page_path: window.location.pathname,
        page_location: window.location.href,
        send_to: measuremnetId
     })
    },1000)
  }
}

public googleAnalyticsScripts(tag){
 /**  let elementScript = document.createElement('script');
       elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
       elementScript.async = true;
       document.getElementById('pvt__scripts').appendChild(elementScript);
  let elementScriptText = document.createElement('script');
  elementScriptText.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', ${tag},{send_page_view: false});`
  document.getElementById('pvt__scripts').appendChild(elementScriptText);*/


  globalTag = tag;
  let elementScript = document.createElement('script');
  elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
  elementScript.async = true;
  document.getElementById('pvt__scripts').appendChild(elementScript);

  // Wait for the script to load before calling gtag()
  elementScript.onload = () => {
    // Ensure window.dataLayer is initialized
    window.dataLayer = window.dataLayer || [];
    
    // Define gtag function globally
    window['gtag'] = function gtag() {
      window.dataLayer.push(arguments);
    };

    // Initialize gtag
    gtag('js', new Date());

    // Configure Google Analytics
    gtag('config', globalTag, { send_page_view: false });
  };
}

  private loadDetail() {
    this.CaptureParams();
    // if(this.campaignId){
    //   this.store$.dispatch(new storyActions.Select(this.idOrSlug ,true,this.campaignId,null));
    // }
    // else if(this.spotlightId){
    //   this.store$.dispatch(new storyActions.Select(this.idOrSlug ,true,null,this.spotlightQuery));
    // }
    // else{
      this.store$.dispatch(new storyActions.Select(this.idOrSlug ,true,null,null));
    // }
    // this.store$.dispatch(new storyActions.Select(this.idOrSlug, true));
  }
  private CaptureParams(){
    // var queryParams = this.activatedRoute.snapshot.queryParams; 
    // if(!_.isEmpty(queryParams)){
    //   var keys = Object.keys(queryParams);
    //   console.log(keys);
    //   if(keys && keys.length && keys.includes('campaignId')){
    //     this.campaignId = queryParams.campaignId
    //   }
    //   else if(keys && keys.length && keys.includes('spotlightId')){
    //     let query = localStorage.getItem('publicfilterDetails')
    //     if(!query){
    //       query = localStorage.getItem('privatefilterDetails')
    //     }
    //     this.spotlightId = queryParams.spotlightId
    //     this.spotlightQuery = queryParams.spotlightId+'&'+query
    //   }
    //   else{
    //     this.campaignId = null;
    //     this.spotlightId = null;
    //     this.spotlightQuery = null;
    //   }
    // }
    // else{
    //   this.campaignId = null
    //   this.spotlightId = null;
    //   this.spotlightQuery = null;
    // }

    }
 
  private loadCampaigns() {
    this.queryCampaignsData.pagination.sort = 'desc';
    this.queryCampaignsData.isIncludeStories = true;
    this.queryCampaignsData.public = false;
    this.buildFilter();
    this.store$.dispatch(new campaignActions.Load(this.currentCompany.id, this.queryCampaignsData));
  }
  private buildFilter() {
    this.queryCampaignsData.isLoadExpired = true;
  }
  
  private updateViewStatus() {
    this.store$.dispatch(new storyActions.View(this.storyDetail.id));
  }

  private checkPermission(data: StoryOutputApiModel) {
    if (!data || data.archived) {
      this.router.navigate(['/common/not-found']);
    }
    if(!data.status && !data.archived){
      this.router.navigate(['/common/story-in-progress']);
    }
  }

  story(){
    this.isshowTrackinid = true;
    this.isCopyShareUrl = true;
    const currentUser = this.authService.cognito.currentUser();
    if(currentUser){
      this.getTracking();
    }
    else{
      this.isCopyShareUrl = false;
      const linkToShare: string = `${window.location.href}`;
      this.copyStoryLink(linkToShare);
    }
    }
    social(){
      this.isshowTrackinid = true;
      this.isShareSocialUrl = true;
      const currentUser = this.authService.cognito.currentUser();
      if(currentUser && !this.queeryTrackingId){
        this.getTracking();
      }
      else if(currentUser && this.queeryTrackingId){
        this.modalService
        .showModal(ShareModalContainerComponent,{})
        .then(() => {});
      }
      else{
        this.modalService
        .showModal(ShareModalContainerComponent, {})
        .then(() => {});
      }
    }
    video(){
      // const selBox = document.createElement('textarea');
      // selBox.style.position = 'fixed';
      // selBox.style.left = '0';
      // selBox.style.top = '0';
      // selBox.style.opacity = '0';
      // selBox.value = (window.location.origin+'/video/'+this.storyDetail.slug+'/'+this.storyDetail.company.companyId).toString();
      // document.body.appendChild(selBox);
      // selBox.focus();
      // selBox.select();
      // document.execCommand('copy');
      // document.body.removeChild(selBox);
      // let obj = {
      //   videoLink:(window.location.origin+'/video/'+this.storyDetail.slug+'/'+this.storyDetail.company.companyId).toString(),
      //   embedLink:'https://videos.goodseeker.com/video/embed/'+this.storyDetail.media.source
      // }
      // this.modalService.showModal(CopyVideoModalContainerComponent, obj, {class: 'modal-lg'}).then(result => {})

      // this.toastService.success("Copied");
    }
  

  // Public functions
  addtrackhit(obj){
    this.store$.dispatch(new storyActions.AddTrackHit(obj));
  }
  genereateUrl(token){
    if(token){
      var url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://videos.goodseeker.com/video/embed/${token}`)
   
      return url
    }
  }

  genereateCopyUrl(token){
    if(token){
      var url = 'https://videos.goodseeker.com/video/embed/'+token
   
      return url
    }
  }

  public loadScripts(){
    this.store$.dispatch(new ScriptActions.Load(this.currentCompany.id));
  }

  showMessage(){
    this.isnotSeen = false;
    this.showmessagebody =  !this.showmessagebody;
    // this.showMessageBx = !this.showMessageBx;
  }


  public copyStoryLink(linkUrl){
    const currentUser = this.authService.cognito.currentUser();
    if(currentUser){
      var storyUrl = '';
      var videoUrl = '';
      if(this.storyDetail && this.storyDetail?.public){
         if(this.mediaPayload && this.mediaPayload.length && this.mediaPayload.filter((item)=> item.type =='video/mp4').length){
          this.isvideo = true
           videoUrl = (window.location.origin+'/video/'+this.storyDetail.slug+'/'+this.storyDetail.company.companyId).toString();
          storyUrl = `${window.location.origin}/c/${this.currentCompany.slug}/${this.storyDetail.slug}/${this.uniqueToken}/${this.queeryTrackingId}/unique/people`;
        }
        else {
          storyUrl = `${window.location.origin}/c/${this.currentCompany.slug}/${this.storyDetail.slug}/${this.uniqueToken}/${this.queeryTrackingId}/unique/people`;
        }
  
      }

       let payloadToshare = {
        isvideo : this.isvideo,
        videoUrl : videoUrl,
        storyUrl: storyUrl,
        isinternal: false ,
        slug:this.storyDetail.slug,
        companySlug:this.currentCompany.slug,
        companyId: this.currentCompany.id,
        storyId:this.storyDetail?.id,
        trackingid:this.queeryTrackingId,
       }
      //  commmons.CopyEmbedVideoModalContainerComponent,
      //  commmons.CopyLinkContainerComponent,
       this.modalService.showModal(CopyLinkContainerComponent,payloadToshare).then(result => {})
    }
    else{
      var storyUrl = '';
      var videoUrl = '';
      if(this.storyDetail && this.storyDetail?.public){
         if(this.mediaPayload && this.mediaPayload.length && this.mediaPayload.filter((item)=> item.type =='video/mp4').length){
          this.isvideo = true
           videoUrl = (window.location.origin+'/video/'+this.storyDetail.slug+'/'+this.storyDetail.company.companyId).toString();
          storyUrl = window.location.href;
        }
        else {
          storyUrl = window.location.href;
        }
  
      }
  
  
  
       let payloadToshare = {
        isvideo : this.isvideo,
        videoUrl : videoUrl,
        storyUrl: storyUrl,
        isinternal: false 
       }
      //  commmons.CopyEmbedVideoModalContainerComponent,
      //  commmons.CopyLinkContainerComponent,
       this.modalService.showModal(CopyLinkContainerComponent,payloadToshare).then(result => {})
    }
    // const selBox = document.createElement('textarea');
    // selBox.style.position = 'fixed';
    // selBox.style.left = '0';
    // selBox.style.top = '0';
    // selBox.style.opacity = '0';
    // selBox.value = linkUrl.toString();
    // document.body.appendChild(selBox);
    // selBox.focus();
    // selBox.select();
    // document.execCommand('copy');
    // document.body.removeChild(selBox);
    // this.toastService.success("Copied");
 
  }


  plusSlides(data){
    if(data == 1){
      if(this.storyDetail.media.length - 1 == this.activeIndex){
        this.activeIndex = 0
      }
      else{
        this.activeIndex = this.activeIndex + 1
      }
      
   
    }
    if(data == -1){
      if(this.activeIndex == 0){
        this.activeIndex = 0;
      }
      else{
        this.activeIndex = this.activeIndex - 1
      }
    }
    }
  private getTracking(){
    var tarcking = []
    let trackobj ={
     "resource_id": this.storyDetail.id.toString(),
     "resource_type":"story"
    }
    tarcking.push(trackobj)
    this.store$.dispatch(new storyActions.AddTracking(tarcking));
  }

  public share() {
    this.modalService
        .showModal(ShareModalContainerComponent, {tracking:this.trackingid})
        .then(() => {});
}

  getUrl(media){
    if(media && media.thumbnail_url){
      return media.thumbnail_url
    }
    else{
     return 'https://image.mux.com/'+media.source+'/thumbnail.jpg'
    }
  }

  removeUrl(url){
    if( url && (url.includes('ik.imagekit.io') || url.includes('images.goodseeker.com'))){
      return url.split("?")[0]
    }
    else{
      return url
    }
  
   }
  public isVideo(type) {
    if(type && type.indexOf('video')>-1){
     this.setFrameHeight()
     this.isBackgroundBlack = true;
    }
    else{
      this.isBackgroundBlack = false;
    }
    return type && type.indexOf('video') > -1;
  }
  setFrameHeight(){
    if(this.isBrowser){
      let width = $('iframe#detail-unique-private-board_iframe').width();
      if(width != 0){
      $('iframe#detail-unique-private-board_iframe').css('height',(width/16)*9)
      }
    }

  }
  public getStepsData() {
    const data: StepDataItemViewModel[] = [];

    this.utility.Lodash.forEach(this.storyDetail && this.storyDetail.stepsData || [], (value, key) => {
      data.push(value);
    });

    return data;
  }
}


export interface PublicMeediaPayload {
  type?:any;
  url:SafeUrl;
  imageUrl?:string;
  source?:any
  thumbnail_url?:any;
  title?:any;
  asset_id?:any;
}

export class PublicMeediaPayload {
constructor() {
}
}
