import { Component, SimpleChanges, ViewChild, Inject, Input, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ComponentBase } from '@framework/base/component';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SearchBoxStateViewModel, CompanyViewModel, CampaignViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { searchBoxSelectors, campaignSelectors, companySelectors } from '@modules/company/store/selectors';
import { searchBoxActions } from '@modules/company/store/actions';
import { ModalService } from '@framework/services';
import { SearchStoryInputApiModel } from '@modules/story/models/api-models/input';
import { StoryFacetedSearchCampaignItemViewModel, StoryFacetedSearchPeopleItemViewModel, StoryFacetedSearchTagItemViewModel, StoryFacetedSearchViewModel, StoryLoadStatusViewModel } from '@modules/story/models/store-view-models';
import { storySelector } from '@modules/story/store/selectors';
import { storyActions } from '@modules/story/store/actions';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';
import { LoadStatusModel } from '@modules/shared/models';
import { StoryViewModel } from '@modules/story/models/view-models';
import { CampaignModalContainerComponent } from '@modules/company/components/campaign-modal-container/campaign-modal-container.component';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { Actions,ofType } from '@ngrx/effects';
import { UserProfileViewModel } from '@modules/user/store/models';
import { SelectedModel } from '@framework/models/commons';
import { OwlCarouselComponent } from '@modules/shared/components';
import { trigger, state, style, transition, animate } from '@angular/animations';
@Component({
  selector: 'co-pb-cp-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  animations: [
    trigger('showhide', [
      // ...
      state('show', style({
        opacity: 1,
        zIndex: 1000
      })),
      state('hide', style({
        opacity: 0,
        zIndex: -1
      })),
      transition('show => hide', [
        animate('0.5s ease-in-out')
      ]),
      transition('hide => show', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})
export class SearchBoxComponent extends ComponentBase {
  // @Input() companyId: any;
  // public showIndicator: boolean = false;
  // public disabled: boolean = false;
  // public loadStoryStatus: LoadStatusModel;

  // // Private variables
  // private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  // private selectStoryLoadStatus$: Observable<StoryLoadStatusViewModel>;
  // private selectLoadStoryStatus$: Observable<LoadStatusModel>;
  // private selectStories$: Observable<StoryViewModel[]>;
  // private selectCampaignDetail$: Observable<CampaignViewModel>;

  // private selectStoriesSubscription: Subscription;
  // private selectLoadStoryStatusSubscription: Subscription;
  // private selectSearchBoxStatusSubscription: Subscription;
  // private searchFormChangeWithDebounceSubscription: Subscription;
  // private selectStoryLoadStatusSubscription: Subscription;
  // private selectCampaignDetailSubscription: Subscription;

  // private queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  // private campaignQueryObjects: SearchStoryInputApiModel;
  // // Public variables
  // public searchBoxStatus: SearchBoxStateViewModel;
  // public searchForm: FormGroup;
  // public storyLoadStatus: StoryLoadStatusViewModel;
  // public campaign: CampaignViewModel;

  // constructor(
  //   private store$: Store<CoreState>,
  //   private modalService: ModalService,
  //   private formBuilder: FormBuilder,
  //   private activatedRoute: ActivatedRoute,
  //   ) {
  //   super();
  //   this.initForm();
  //   this.captureParams();

  //   this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);
  //   this.selectStoryLoadStatus$ = this.store$.select(storySelector.selectLoadStatus);
  //   this.selectLoadStoryStatus$ = this.store$.select(storySelector.selectLoadStatus);
  //   this.selectStories$ = this.store$.select(storySelector.selectAll);
  //   this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
  // }

  // // Life cycle hook
  // protected onInit(): void {
  //   this.subscribe();
  // }
  // protected onDestroy(): void {
  //   this.unsubscribe();
  // }
  // protected onChanges(changes: SimpleChanges): void {

  // }
  // protected onDoCheck(): void {

  // }
  // protected onAfterContentInit(): void {

  // }
  // protected onAfterContentChecked(): void {

  // }
  // protected onAfterViewInit(): void {

  // }
  // protected onAfterViewChecked(): void {

  // }
  // // Private functions
  // private subscribe() {
  //   this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: SearchBoxStateViewModel) => {
  //     this.searchBoxStatus = state;
  //   });

  //   this.searchFormChangeWithDebounceSubscription = this.searchForm.valueChanges.pipe(
  //     debounceTime(500)
  //   ).subscribe((values) => {
  //     this.buildSearch();
  //     this.search();
  //   });

  //   this.selectStoryLoadStatusSubscription = this.selectStoryLoadStatus$.subscribe((status) => {
  //     this.storyLoadStatus = status;
  //   });

  //   this.selectStoriesSubscription = this.selectStories$.subscribe(() => {
  //     this.showIndicator = false;
  //   });

  //   this.selectLoadStoryStatusSubscription = this.selectLoadStoryStatus$.subscribe((loadStoryStatus) => {
  //     this.loadStoryStatus = loadStoryStatus;
  //     if (loadStoryStatus) {
  //       if (loadStoryStatus.isLoaded && loadStoryStatus.isCanLoadMore) {
  //         this.disabled = false;
  //       } else {
  //         this.disabled = true;
  //       }
  //     }
  //   });

  //   this.selectCampaignDetailSubscription = this.selectCampaignDetail$.subscribe((campaign) => {
  //     if (campaign) {
  //       this.campaign = campaign;
  //       this.campaignQueryObjects = Object.assign(new SearchStoryInputApiModel(), campaign?.queryObjects?.queryStories);
  //       this.campaignQueryObjects.pagination = this.queryStoriesData.pagination;
  //       this.queryStoriesData = Object.assign(new SearchStoryInputApiModel(), this.campaignQueryObjects);
  //       this.queryStoriesData.public = true;
  //       this.queryStoriesData.campaigns = [this.campaign.id];
  //       this.queryStoriesData.companyId = this.companyId;
  //       this.search();
  //     }
  //   });
  // }
  // private unsubscribe() {
  //   this.selectSearchBoxStatusSubscription.unsubscribe();
  //   this.selectStoryLoadStatusSubscription.unsubscribe();
  //   this.searchFormChangeWithDebounceSubscription.unsubscribe();
  //   this.selectStoriesSubscription.unsubscribe();
  //   this.selectLoadStoryStatusSubscription.unsubscribe();
  //   this.selectCampaignDetailSubscription.unsubscribe();
  // }

  // private initForm() {
  //   this.searchForm = this.formBuilder.group({
  //     searchText: ['', Validators.compose([Validators.minLength(3), Validators.required])]
  //   });
  // }

  // private buildSearch() {
  //   if (this.searchForm.controls.searchText.valid) {
  //     this.queryStoriesData.keywords = [...this.campaignQueryObjects.keywords, this.searchForm.controls.searchText.value];
  //   } else {
  //     this.queryStoriesData.keywords = this.campaignQueryObjects.keywords;
  //   }
  // }

  // private loadStories() {
  //   this.queryStoriesData.pagination.sort = 'desc';
  //   this.buildFilter();
  //   this.store$.dispatch(new storyActions.LoadByCompany(this.queryStoriesData));
  // }

  // private buildFilter() {
  //   if (this.searchBoxStatus && this.searchBoxStatus.isSearching) {
  //     const queryString = this.queryStoriesData.queryWithoutPaination;
  //     const url = `/company?${queryString}`;
  //     this.updateBrowserUrl(url);
  //   }
  // }

  // private updateBrowserUrl(url) {
  //   window.history.pushState({}, 'GoodSeeker', url);
  // }

  // private showSearchBox() {
  //   this.store$.dispatch(new searchBoxActions.Show());
  // }

  // private captureParams() {
  //   const queryParams = this.activatedRoute.snapshot.queryParams;
  //   if (!_.isEmpty(queryParams)) {
  //     this.queryStoriesData.init(queryParams);
  //     this.searchForm.patchValue({
  //       searchText: this.queryStoriesData.keywords[0]
  //     });
  //     this.search();
  //     this.showSearchBox();
  //   }
  // }

  // private search() {
  //   this.loadStories();
  // }

  // // Public functions
  // public closeSearchBox() {
  //   this.store$.dispatch(new searchBoxActions.Hide());
  // }

  // public showShareModal() {
  //   this.modalService.showModal(ShareModalContainerComponent, {}, { animated: false }).then(() => {

  //   });
  // }

  // public openSaveCampaignModal() {
  //   this.modalService.showModal(CampaignModalContainerComponent, { queryStory: this.queryStoriesData }, { class: 'modal-lg' }).then(() => {

  //   });
  // }

  // public tagsTrackByFunc(item: StoryFacetedSearchTagItemViewModel) {
  //   return item.name;
  // }

  // public campaignsTrackByFunc(item: StoryFacetedSearchCampaignItemViewModel) {
  //   return item.id;
  // }

  // public peoplesTrackByFunc(item: StoryFacetedSearchPeopleItemViewModel) {
  //   return item.id;
  // }

  // public clear() {
  //   this.searchForm.reset();
  // }

  // public loadMoreStories() {
  //   if (this.loadStoryStatus.isLoaded && this.loadStoryStatus.isCanLoadMore) {
  //     this.showIndicator = true;
  //     this.queryStoriesData.pagination.page += 1;
  //     this.loadStories();
  //   }
  // }

  @ViewChild('owlElement') owlElement: OwlCarouselComponent;
  @ViewChild('noOptions') noOptions;
  @Input() companyId: any;
  // Private variables
  private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  private selectFacetedSearchResult$: Observable<StoryFacetedSearchViewModel>;
  private selectStoryLoadStatus$: Observable<StoryLoadStatusViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

  private selectCurrentCompany$: Observable<CompanyViewModel>;

  private selectCurrentUserProfileSubscription: Subscription;
  private selectSearchBoxStatusSubscription: Subscription;
  private selectFacetedSearchResultSubscription: Subscription;
  private searchFormChangeSubscription: Subscription;
  private searchFormChangeWithValidationSubscription: Subscription;
  private searchTextChangeSubscription: Subscription;
  private selectStoryLoadStatusSubscription: Subscription;
  private searchActionSubscription: Subscription;
  private clearActionSubscription: Subscription;
  private selectCurrentCompanySubscription: Subscription;
  private searchTextEvent: EventEmitter<string> = new EventEmitter();
  private selectCampaignDetail$: Observable<CampaignViewModel>;

    private selectCampaignDetailSubscription: Subscription;
    private campaignQueryObjects: SearchStoryInputApiModel;

  private queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  private preventFormTrigger: boolean = false;
  private isSearchTextChange: boolean = false;
  public campaign: CampaignViewModel;
  // Public variables
  public searchBoxStatus: SearchBoxStateViewModel;
  public owlOptions: any = {
    dots: false,
    autoWidth: true,
    loop: false,
    rewind: true,
    nav: true,
  items: 10
  };

  public searchForm: UntypedFormGroup;
  public facetedTags: StoryFacetedSearchTagItemViewModel[];
  public facetedCampaigns: StoryFacetedSearchCampaignItemViewModel[];
  public facetedPeoples: StoryFacetedSearchPeopleItemViewModel[];
  public facetedTagSelected: StoryFacetedSearchTagItemViewModel[];
  public facetedCampaignSelected: StoryFacetedSearchCampaignItemViewModel[];
  public facetedPeopleSelected: StoryFacetedSearchPeopleItemViewModel[];
  public storyLoadStatus: StoryLoadStatusViewModel;
  public currentUserProfile: UserProfileViewModel;
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public searchText: any;
  public inputFocus: boolean = false;
  public isShowForm: boolean = true;
  public insideIframe:boolean = false;
  constructor(
    private store$: Store<CoreState>,
    private modalService: ModalService,
    private formBuilder: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions) {
    super();
    this.initForm();
    if (window.location !== window.parent.location) {
      this.insideIframe = true;
      //document.getElementsByClassName("site-container")[0]['style'].padding = 0;
    } else {
      this.insideIframe = false;
    }
    // this.captureParams();

    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);
    this.selectFacetedSearchResult$ = this.store$.select(storySelector.selectFacetedSearchResults);
    this.selectStoryLoadStatus$ = this.store$.select(storySelector.selectLoadStatus);
    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }

  protected onChanges(): void {
   
  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.queryStoriesData.pagination.sort = 'desc';
      }
    });

    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.queryStoriesData.pagination.sort = 'desc';
        this.loadStories();
      }
    });

    this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: SearchBoxStateViewModel) => {
      this.searchBoxStatus = state;
    });

    this.selectFacetedSearchResultSubscription = this.selectFacetedSearchResult$.subscribe((result: StoryFacetedSearchViewModel) => {
      if (result) {
        this.updateSearchForm(result);
      }
    });

    this.selectCampaignDetailSubscription = this.selectCampaignDetail$.subscribe((campaign) => {
          if (campaign) {
            this.campaign = campaign;
            this.campaignQueryObjects = Object.assign(new SearchStoryInputApiModel(), campaign?.queryObjects?.queryStories);
            this.campaignQueryObjects.pagination = this.queryStoriesData.pagination;
            this.queryStoriesData = Object.assign(new SearchStoryInputApiModel(), this.campaignQueryObjects);
            this.queryStoriesData.public = true;
            this.queryStoriesData.campaigns = [this.campaign.id];
            this.queryStoriesData.companyId = this.companyId;
            this.loadStories();
          }
        });
  
    this.searchFormChangeSubscription = this.searchForm.valueChanges.pipe(
      filter(() => !this.preventFormTrigger)
    ).subscribe(() => {
      this.getSelectedValues();
    });

    this.searchTextChangeSubscription = this.searchTextEvent.pipe(
      tap(() => {
        this.isSearchTextChange = true;
      }),
      filter(() => !this.preventFormTrigger),
      debounceTime(500)
    ).subscribe((_searchText) => {
      this.searchText = _searchText;

      this.queryStoriesData.pagination.page = 1;

      this.resetFacetedSeletected();
      this.buildSearch();
      this.search();
      this.isSearchTextChange = false;
    });

    this.searchFormChangeWithValidationSubscription = this.searchForm.valueChanges.pipe(
      filter(() => !this.preventFormTrigger && this.searchForm.valid && !this.isSearchTextChange)
    ).subscribe(() => {
      this.queryStoriesData.pagination.page = 1;

      this.buildSearch();
      this.search();
    });

    this.selectStoryLoadStatusSubscription = this.selectStoryLoadStatus$.subscribe((status) => {
      this.storyLoadStatus = status;
    });

    this.searchActionSubscription = this.actions$.pipe(
      ofType<searchBoxActions.Search>(searchBoxActions.ActionTypes.SEARCH)
    ).subscribe((action) => {
      if (action.searchPayload) {
        this.queryStoriesData = Object.assign(this.queryStoriesData, action.searchPayload);
      }
      this.loadStories();
    });

    this.clearActionSubscription = this.actions$.pipe(
      ofType<searchBoxActions.Clear>(searchBoxActions.ActionTypes.CLEAR)
    ).subscribe((action) => {
      this.searchForm.patchValue({
        searchText: [''],
        includeStories: true,
        includeTestimonials: true
      });
      this.searchTextEvent.emit('');
      this.store$.dispatch(new searchBoxActions.Hide());
    });
  }
  private unsubscribe() {
    this.selectSearchBoxStatusSubscription.unsubscribe();
    this.selectFacetedSearchResultSubscription.unsubscribe();
    this.searchFormChangeSubscription.unsubscribe();
    this.selectStoryLoadStatusSubscription.unsubscribe();
    this.searchFormChangeWithValidationSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.selectCurrentCompanySubscription.unsubscribe();
    this.searchActionSubscription.unsubscribe();
    this.clearActionSubscription.unsubscribe();
    this.searchTextChangeSubscription.unsubscribe();
    this.selectCampaignDetailSubscription.unsubscribe();
  }

  private initForm() {
    this.searchForm = this.formBuilder.group({
      searchText: ['', Validators.minLength(3)],
      includeStories: [true],
      includeTestimonials: [true],
      isMyStoryOnly: [false]
    });
  }

  private createFacetedControl(facetedSearchItems: SelectedModel[]) {
    const fArr = facetedSearchItems.map(item => {
      return new UntypedFormControl(item.selected || false);
    });
    return new UntypedFormArray(fArr);
  }

  private updateSearchForm(facetedSearchResult: StoryFacetedSearchViewModel) {
    this.preventFormTrigger = true;

    this.facetedTags = facetedSearchResult.tags || [];
    this.searchForm.setControl('tags', this.createFacetedControl(this.facetedTags));

    this.facetedCampaigns = facetedSearchResult.campaigns || [];
    this.searchForm.setControl('campaigns', this.createFacetedControl(this.facetedCampaigns));

    this.facetedPeoples = facetedSearchResult.players || [];
    this.searchForm.setControl('peoples', this.createFacetedControl(this.facetedPeoples));

    this.getSelectedValues();
    this.preventFormTrigger = false;
  }

  private buildSearch() {
    this.queryStoriesData.keywords = [this.searchText];
    this.queryStoriesData.campaigns = this.facetedCampaignSelected ? this.facetedCampaignSelected.map(m => m.id) : [];
    this.queryStoriesData.tags = this.facetedTagSelected ? this.facetedTagSelected.map(m => m.name) : [];
    this.queryStoriesData.peoples = this.facetedPeopleSelected ? this.facetedPeopleSelected.map(m => m.id) : [];
    this.queryStoriesData.includeStory = this.searchForm.controls.includeStories.value;
    this.queryStoriesData.includeTestimonial = this.searchForm.controls.includeTestimonials.value;
  }

  private getSelectedValues() {
    window.document.querySelectorAll('.dropdown-select.show, .dropdown-menu.show').forEach(item => {
      item.classList.remove('show');
    });

    // Tag
    if (this.searchForm.controls.tags) {
      this.facetedTagSelected = [];

      this.searchForm.controls.tags["controls"].forEach((tag, i) => {
        if (tag.value === true) {
          this.facetedTagSelected.push(this.facetedTags[i]);
        }
      });
      if(this.facetedTagSelected.length){
        var payload = {tags:this.facetedTagSelected.map(m => m.name)}
        this.store$.dispatch(new searchBoxActions.SearchPayload(payload))
      }
      else{
        this.store$.dispatch(new searchBoxActions.SearchPayload(null))
      }
    }

    // Campaign
    if (this.searchForm.controls.campaigns) {
      this.facetedCampaignSelected = [];
      this.searchForm.controls.campaigns["controls"].forEach(
        (campaign, i) => {
          if (campaign.value === true) {
            this.facetedCampaignSelected.push(this.facetedCampaigns[i]);
          }
        }
      );
    }

    // People
    if (this.searchForm.controls.peoples) {
      this.facetedPeopleSelected = [];

      this.searchForm.controls.peoples["controls"].forEach(
        (people, i) => {
          if (people.value === true) {
            this.facetedPeopleSelected.push(this.facetedPeoples[i]);
          }
        }
      );
    }

    if (this.owlElement) {
      this.owlElement.reInit();
    }
  }

  private loadStories() {
    this.queryStoriesData.pagination.sortBy = 'published_at';
    this.queryStoriesData.public = true;
    if(this.campaign && this.campaign.id){
      this.queryStoriesData.campaigns = [this.campaign.id];
      this.queryStoriesData.companyId = this.companyId;
      this.buildFilter();
      this.store$.dispatch(new storyActions.LoadByCompany(this.queryStoriesData));
    }
  }

  private buildFilter() {
    if (!this.searchBoxStatus || !this.searchBoxStatus.isSearching) return;

    const queryString = this.queryStoriesData.queryWithoutPaination;
    if (!queryString || queryString.length <= 0) {
      const url = `/c/${this.currentCompany.slug}/featured/${this.campaign.slug}`;
      // this.updateBrowserUrl(url);
    } else {
      const url = `/c/${this.currentCompany.slug}/featured/${this.campaign.slug}?${queryString}`;
      // this.updateBrowserUrl(url);
    }
  
  }

  private updateBrowserUrl(url) {
    window.history.pushState({}, 'GoodSeeker', url);
  }

  private showSearchBox() {
    this.store$.dispatch(new searchBoxActions.Show());
  }

  private captureParams() {
    const queryParams = this.activatedRoute.snapshot.queryParams;
    if (!_.isEmpty(queryParams)) {
      var storekeysParms = Object.keys(queryParams);
      var checkforfilters = (storekeysParms.includes("keyword") || storekeysParms.includes("filter") 
      || storekeysParms.includes("includeTestimonials") || storekeysParms.includes("includeTestimonials") );
      this.queryStoriesData.init(queryParams);
      this.searchForm.patchValue({
        searchText: this.queryStoriesData.keywords[0],
        includeStories: this.queryStoriesData.includeStory,
        includeTestimonials: this.queryStoriesData.includeTestimonial,
        userId: this.queryStoriesData.userId
      });
      this.searchText = this.queryStoriesData.keywords[0];
      this.search();
      if(this.insideIframe && checkforfilters){
        this.showSearchBox();
      }
    else if(!this.insideIframe){
      this.showSearchBox();
    }
    }
  }

  private search() {
    this.store$.dispatch(new searchBoxActions.Search(null));
  }

  private resetFacetedSeletected() {
    this.facetedCampaignSelected = [];
    this.facetedPeopleSelected = [];
    this.facetedTagSelected = [];
  }
  // Public functions
  public closeSearchBox() {
    if (this.isShowSearchSelected()) {
      this.isShowForm = false;
    }
    else {
      this.store$.dispatch(new searchBoxActions.Hide());
    }
  }

  public showShareModal() {
    this.modalService.showModal(ShareModalContainerComponent, {}, { animated: false }).then(() => {

    });
  }

 

  public doSearch() {
    this.searchTextEvent.emit(this.searchForm.controls.searchText.value);
  }

  public isShowSearchFilter() {
    return this.facetedPeoples || this.facetedTags || this.facetedCampaigns;
  }

  public isShowSearchSelected() {
    return this.searchText || this.queryStoriesData.isMyStoriesOnly || !this.queryStoriesData.includeTestimonial || !this.queryStoriesData.includeStory || (this.facetedPeopleSelected && this.facetedPeopleSelected.length) || (this.facetedCampaignSelected && this.facetedCampaignSelected.length) || (this.facetedTagSelected && this.facetedTagSelected.length);
  }

  public tagsTrackByFunc(index: number, item: StoryFacetedSearchTagItemViewModel) {
    return item.name;
  }

  public campaignsTrackByFunc(index: number, item: StoryFacetedSearchCampaignItemViewModel) {
    return item.id;
  }

  public peoplesTrackByFunc(index: number, item: StoryFacetedSearchPeopleItemViewModel) {
    return item.id;
  }

  public clear() {
    this.searchForm.reset();
    this.isShowForm = true;
    this.store$.dispatch(new searchBoxActions.Clear());
  }

  public get tagsFormArr() {
    return this.searchForm.controls['tags'] as UntypedFormArray;
  }

  public get campaignsFormArr() {
    return this.searchForm.controls['campaigns'] as UntypedFormArray;
  }

  public get peoplesFormArr() {
    return this.searchForm.controls['peoples'] as UntypedFormArray;
  }

  public deleteTagItem(index: number, item: StoryFacetedSearchTagItemViewModel) {
    this.facetedTagSelected.splice(index, 1);
    const controlIndex = this.facetedTags.findIndex(tag => tag.name === item.name);
    this.tagsFormArr.controls[controlIndex].setValue(false);
    if (!this.isShowSearchSelected()) {
      this.isShowForm = true;
      this.store$.dispatch(new searchBoxActions.Hide());
    }
  }

  public deleteCampaignItem(index: number, item: StoryFacetedSearchCampaignItemViewModel) {
    this.facetedCampaignSelected.splice(index, 1);
    const controlIndex = this.facetedCampaigns.findIndex(campaign => campaign.id === item.id);
    this.campaignsFormArr.controls[controlIndex].setValue(false);
    if (!this.isShowSearchSelected()) {
      this.isShowForm = true;
      this.store$.dispatch(new searchBoxActions.Hide());
    }
  }

  public deletePeopleItem(index: number, item: StoryFacetedSearchPeopleItemViewModel) {
    this.facetedPeopleSelected.splice(index, 1);
    const controlIndex = this.facetedPeoples.findIndex(people => people.id === item.id);
    this.peoplesFormArr.controls[controlIndex].setValue(false);
    if (!this.isShowSearchSelected()) {
      this.isShowForm = true;
      this.store$.dispatch(new searchBoxActions.Hide());
    }
  }
}
