import { StoryStepViewModel } from "./story-step.view-model";
import { CtaInputApiModel } from "../api-models/input/cta-input.api-mode";

export interface CardCtaTemplateInputViewModel {
    stepTagData: any,
    stepTags: StoryStepViewModel[],
    data: CtaInputApiModel,
    companyId: number,
    inbound: boolean
}

export class CardCtaTemplateInputViewModel {
    constructor() {

    }
}