import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { NotificationEntity } from '../entities';
import { NotificationState } from '../states';

export const notificationAdapter: EntityAdapter<NotificationEntity> = createEntityAdapter<NotificationEntity>();
export const initialState: NotificationState = notificationAdapter.getInitialState({
    loading: false,
    loaded: false,
    cleared: false,
    clearing: false,
    count: 0,
    query: null,
    countUnread: 0,
    selectedId: 0
});