<div role="tabpanel" aria-labelledby="tab-story" class="tab-pane fade show active">

  <div class="m-tools d-none d-sm-flex" *ngIf="currentCompany.countStories > 0 && stories.length >  0">
    <button class="btn btn-trans text-gray" (click)="showSearchBox()">
      <svg role="img">
        <use xlink:href="#icon-search"></use>
      </svg>
    </button>

    <button data-view class="btn btn-trans-line text-gray ml-3" (click)="changeSortType()">
      <svg role="img" *ngIf="queryStoriesData.pagination.sort == 'asc'">
        <use xlink:href="#icon-filter-dn"></use>
      </svg>
      <svg role="img" *ngIf="queryStoriesData.pagination.sort == 'desc'">
        <use xlink:href="#icon-filter"></use>
      </svg>
    </button>

    <button data-view class="btn btn-trans-line text-gray ml-3 d-none d-md-block" (click)="changeViewType()">
      <svg role="img" *ngIf="!isViewList">
        <use xlink:href="#icon-grid"></use>
      </svg>
      <svg role="img" *ngIf="isViewList">
        <use xlink:href="#icon-list"></use>
      </svg>
    </button>
  </div>

  <!-- Start Story-->
  <div class="m-story" [ngClass]="{'m-story--list': isViewList}">
    <div class="m-story__content">
      <skeleton-story-list class="row grid"
        *ngIf="(!stories || stories.length === 0) && !loadStoryStatus.isLoaded && loadStoryStatus.isLoading"
        [countItem]="8" [cssClassItem]="'col-12 col-sm-6 col-md-4 col-xl-3 px-2 grid-item'"></skeleton-story-list>

      <div gs-masonry
        [options]="{itemSelector:'.grid-item', columnWidth: '.grid-item', transitionDuration: '0', initLayout: 'false'}"
        [totalItem]="stories.length" [useImagesLoaded]="true" class="row grid">
        <co-pb-story-item gsMasonryItem class="col-12 col-sm-6 col-md-4 col-xl-3 px-2 grid-item" [userLabels]="userLables" [guestLabels]="GuestLables"  [story]="story"
          [href]="'/c/' + story.companyIdSlug + '/' + story.slug" *ngFor="let story of stories; trackBy: trackByFunc"
          [stepsData]="stepsData" (componentRendered)="componentRendered()">
        </co-pb-story-item>
      </div>

      <div *ngIf="stories.length === 0 && loadStoryStatus.isLoaded && !loadStoryStatus.isLoading"
        class="row mb-5 text-center text-lg-left">
        <!--<div class="d-none d-lg-block col-12 mb-3 mb-lg-0 offset-lg-1 col-lg-5 pr-lg-7">
          <img src="/assets/front-end-minify/img/catch-a-story.png" />
        </div>-->
        <div class="col-12 col-lg-5">
          <div class="welcome-msg">
            <h4 class="mt-1">No public stories to share just yet.</h4>
            <p>Check back soon!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Close Story-->
</div>
<app-infinity-scroll (infinite)="loadMoreStories()" [showIndicator]="showIndicator" [disabled]="disabled">
</app-infinity-scroll>