import { Component, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import { FollowInputApiModel } from '@modules/shared/models/api-models/input';
import { followActions } from '@modules/shared/store/actions';
import { Constants } from '@modules/shared/constants';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';

@Component({
  selector: 'user-pub-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class UserProfileBannerComponent extends ComponentBase {

  // Private variables
  private selectSelectedProfile$: Observable<UserProfileViewModel>;
  private selectCurrentProfile$: Observable<UserProfileViewModel>;

  private selectSelectedProfileSubscription: Subscription;
  private selectCurrentProfileSubscription: Subscription;
  private followSuccessSubscription: Subscription;
  private unFollowSuccessSubscription: Subscription;

  // Public variables
  public profile: UserProfileViewModel;
  public currentProfile: UserProfileViewModel;
  public isFollowProcessing: boolean = false;
  public bageDefaultIcon: string = '/assets/front-end-minify/img/badge-default.svg';
  public defaultHeaderImage: string = '/assets/front-end-minify/img/backdrops/bg-16.jpg';

  constructor(
      private store$: Store<CoreState>,
      private actions$: Actions,
  ) {
    super();
    this.selectSelectedProfile$ = this.store$.select(userProfileSelectors.selectSelectedProfile);
    this.selectCurrentProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectSelectedProfileSubscription = this.selectSelectedProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.profile = userProfile;
      }
    });

    this.selectCurrentProfileSubscription = this.selectCurrentProfile$.subscribe(userProfile => {
      if (userProfile) {
        this.currentProfile = userProfile;
      }
    });

    this.followSuccessSubscription = this.actions$.pipe(
        ofType<followActions.FollowSuccess>(followActions.ActionTypes.FOLLOW_SUCCESS)).subscribe(action => {
      this.profile.followId = action.response.id;
      this.isFollowProcessing = false;
    });

    this.unFollowSuccessSubscription = this.actions$.pipe(
        ofType<followActions.UnfollowSuccess>(followActions.ActionTypes.UNFOLLOW_SUCCESS)).subscribe(() => {
      this.profile.followId = null;
      this.isFollowProcessing = false;
    });
  }

  private unsubscribe() {
    this.selectSelectedProfileSubscription.unsubscribe();
    this.followSuccessSubscription.unsubscribe();
    this.unFollowSuccessSubscription.unsubscribe();
    this.selectCurrentProfileSubscription.unsubscribe();
  }

  private doFollow() {
    const payload: FollowInputApiModel = {
      resource_type: 'user',
      resource_id: Number(this.profile.id),
    };
    this.store$.dispatch(new followActions.Follow(payload));
  }

  private unFollow() {
    this.store$.dispatch(new followActions.Unfollow(this.profile.followId, Constants.resourceType.user, Number(this.profile.id)));
  }
  // Public functions
  public toggleFollow() {
    this.isFollowProcessing = true;
    if (this.profile.isFollowing) {
      this.unFollow();
    } else {
      this.doFollow();
    }
  }
}
