import { initialState } from "../adapters/emails-setting.adapter";
import { emailsSettingAction } from "../actions";
import { EmailsSettingState } from "../states";
import { EmailsSettingEntity } from "../entities";

export function EmailsSettingReducer(state = initialState, action: emailsSettingAction.Actions): EmailsSettingState {
    switch (action.type) {

        case emailsSettingAction.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case emailsSettingAction.ActionTypes.LOAD_SUCCESS: {

            const result: EmailsSettingEntity = new EmailsSettingEntity(action.response);

            return {
                ...state,
                data: result,
                isLoading: false,
                isLoaded: true
            };
        }

        case emailsSettingAction.ActionTypes.LOAD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}