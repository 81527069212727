<div class="form-label-group gs-input-label-group">
  <input [id]="id" class="form-control" [type]="inputType" [ngClass]="{'is-invalid': !formControl.valid && formControl.dirty}"
         [(ngModel)]="value" [placeholder]="placeholder" (ngModelChange)="onChange()" required>
  <label [for]="id">{{label}}</label>
  <span class="icon-append" (click)="showPassword()">
    <svg role="img" *ngIf="!isShowPassword">
      <use xlink:href="#icon-eye"></use>
    </svg>
    <svg role="img" *ngIf="isShowPassword">
      <use xlink:href="#icon-eye-hide"></use>
    </svg>
  </span>
</div>
<ng-container *ngIf="formControl.errors && formControl.dirty && !formControl.valid">
  <div *ngIf="formControl.errors.required" class="invalid-feedback mb-2">Password is required.</div>
  <div *ngIf="formControl.errors.minlength" class="invalid-feedback mb-2">Min 8 characters.</div>
  <div *ngIf="formControl.errors.isCustom" class="invalid-feedback mb-2">{{formControl.errors.message}}</div>
</ng-container>
