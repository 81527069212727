export interface StepsTagsOutputApiModel {
    id: number;
    sort: number;
    color: string;
    title: string;
    text: string;
    values_type: string;
    type: string;
    is_active: boolean;
    tags: TagsOutputApiModel[];
}

export interface TagsOutputApiModel {
    id: number;
    name: string;
    description: string;
    is_active: boolean;
}

export class StepsTagsOutputApiModel {
    constructor(data?: Partial<StepsTagsOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}