import { GridViewFilterItemModel } from "./grid-view-filter-item.model";

export interface GridViewFilterOptionsModel {
    filters: GridViewFilterItemModel[];
}

export class GridViewFilterOptionsModel {
    constructor(){
        this.filters = [];
    }
}