import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseHttpService } from '@framework/services';
import { Injectable } from '@angular/core';

@Injectable()
export class ChangeUsernameService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/users');
    }

    public changeusername(payload,id){
        return this.patch(`/${id}/profileid/`, payload).pipe(map((response: any) => {
            return response;
        }));
    }
}

