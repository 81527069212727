<div class="site-container">
 <ng-container *ngIf="isBrowser">
    <app-external-header></app-external-header>

    <ng-container *ngIf="isPermission else notSigninDenied">
        <!-- START CONTENT-->
        <!-- Start Timeline-->
        <ng-container *ngIf="showHeaderboolean">
         <co-pu-banner [btnColor]="submit_Story_Color" [textColor]="submit_Story_text"></co-pu-banner>
        </ng-container>

        <!-- End Timeline-->
        <!-- Start Information-->
        <co-pu-informations [showIFrame]="insideIframe"[showtags]="showtagsboolean"></co-pu-informations>
        <!-- End Information-->
       <div class="search_box__hidden_mobile">
        <co-pub-search-box></co-pub-search-box>
       </div>
        

        <co-pub-company-toolbar></co-pub-company-toolbar>
        <!-- [ngClass]="{'pull-right': showAside}" -->
        <!-- Start Feature-->
        <div class="b-feature" [ngClass]="{'add_padding': showAside}">
            <div class="container">
                <div class="b-feature__wrap">
                    <!-- Start Tabs-->
                    <ul role="tablist" class="nav nav-tabs tabs-icon" *ngIf="currentCompany.id > 0 && currentCompany.countStories > 0">
                        <ng-container *ngIf="isStories">
                            <li class="nav-item" *ngIf="currentCompany.countCampaigns > 0 || currentCompany.countFeaturedPages > 0">
                                <a  [style.backgroundColor]="getDynamicColor('company')" data-toggle="tab" routerLinkActive="active"
                                    [routerLink]="['/c/' + currentCompany.slug]"
                                    [routerLinkActiveOptions]="{exact: true}" role="tab"
                                    aria-controls="story" aria-selected="true" class="nav-link">
                                        <span class="text text-uppercase">Stories</span></a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="isCampaigns">
                            <li class="nav-item" *ngIf="currentCompany.countCampaigns > 0">
                                <a [style.backgroundColor]="getDynamicColor('campaigns')" data-toggle="tab" routerLinkActive="active"
                                [routerLink]="['/c/' + currentCompany.slug + '/campaigns']"
                                [routerLinkActiveOptions]="{exact: true}" role="tab" aria-controls="campaign" aria-selected="false" class="nav-link">
                                    <span class="text text-uppercase">Campaigns</span></a>
                            </li>
                        </ng-container>

                        <ng-container *ngIf="isSpotlight">
                            <li class="nav-item" *ngIf="currentCompany.countFeaturedPages > 0">
                                <a [style.backgroundColor]="getDynamicColor('featured')" data-toggle="tab" routerLinkActive="active"
                                [routerLink]="['/c/' + currentCompany.slug + '/featured']"
                                [routerLinkActiveOptions]="{exact: true}"
                                role="tab" aria-controls="page" aria-selected="false" class="nav-link">
                                    <span class="text text-uppercase">Spotlights</span></a>
                            </li>
                        </ng-container>

                    </ul>
                    <div class="tab-content">
                        <router-outlet></router-outlet>
                    </div>
                    <!-- Close Tabs-->
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #notSigninDenied>
        <div class="gs-notlogin" *ngIf="!currentUser else signedInDenied">
            <div class="gs-notlogin-dopbox" [style.background]="'url('+companyBackground+')'"></div>
            <div class="gs-notlogin__content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center">
                            <h5 class="mb-4">Please sign in.</h5>
                            <div class="gs-group-btn">
                                <a href="javascript:void(0)" (click)="login()" class="btn btn-white">Login</a>
                                <a [routerLink]="['/user/logout']" class="btn btn-mint">Register</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #signedInDenied>
        <div class="gs-notlogin">
            <div class="gs-notlogin-dopbox" [style.background]="'url('+companyBackground+')'"></div>
            <div class="gs-notlogin__content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-center">
                            <h5 class="mb-4">This page isn't public.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
 </ng-container>

</div>

<app-site-footer *ngIf="showFooter"></app-site-footer>
