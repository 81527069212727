import { ContributionOutputApiModel } from "@modules/story/models/api-models/output";

export interface ContributionEntity {
    id: number,
    avatar: string,
    displayName: string,
    playerId: number,
    data: any,
    likeId: number,
    storyId: number
}

export class ContributionEntity {
    constructor(data?: ContributionOutputApiModel) {
        if (data)
            this.convertFormApi(data);
    }

    private convertFormApi(data: ContributionOutputApiModel) {
        this.id = data.id;
        this.avatar = data.avatar;
        this.displayName = data.display_name;
        this.playerId = data.player_id;
        this.data = data.data;
        this.likeId = data.like_id;
        this.storyId = data.story_id;
    }
}