export interface ValidateUserOutApiModel {
    email: string;
    success: boolean;
    story_invite_enabled: boolean;
}

export class ValidateUserOutApiModel {
    constructor(init: Partial<ValidateUserOutApiModel>) {
        Object.assign(this, init);
    }
}