import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models/api-models';
import {
  LoadUserInputApiModel,
  CreateUserInputApiModel,
  UpdateUserSettingInputApiModel,
  LoadDirectoryInputApiModel,
  UpdateUserProfileInputApiModel
} from '../models/input';
import {
  LoadUserOutputApiModel,
  LoadUserProfileOutputApiModel,
  LoadUserSettingOutputApiModel,
  DirectoryOutputApiModel,
  UserEmailOutputApiModel,
  UserNotificationsOutputApiModel,
  ValidateUserOutApiModel
} from '../models/output';
import { ChangeStorySlugInputApiModel } from '@modules/story/models/api-models/input/change-story-slug-input.api-model';
import { UserNotificationsInputApiModel } from "@modules/user/models/input/user-notifications-input.api-model";

@Injectable()
export class UserHttpService extends BaseHttpService {

  private endPoints = {
    companies: 'companies',
    stories: 'stories',
    users: 'users',
    follows: 'follows',
    adminroles:'admin-roles'
  };

  constructor(protected http: HttpClient) {
    super(http, '/');
  }

  public load(payload: LoadUserInputApiModel) {
    let apiUrl = `${this.endPoints.companies}/${payload.companyId}/members/?${payload.query}`;
    if (payload.inbound) {
      apiUrl = `${this.endPoints.companies}/${payload.companyId}/members/inbound/?${payload.query}`;
    }

    return this.get(apiUrl).pipe(
      map((response: ResponsePaginationApiModel<LoadUserOutputApiModel>) => {
        return response;
      })
    );
  }

  public removeUser() {
    return this.patch(`${this.endPoints.users}/company/remove-current/`, {}).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public loadAuthors(companyId: number) {
    return this.get(`${this.endPoints.users}/${companyId}/authors/`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public loadContributor(storyId: number, payload: LoadUserInputApiModel) {
    return this.get(`${this.endPoints.stories}/${storyId}/contributors/?${payload.query}`).pipe(
      map((response: ResponsePaginationApiModel<LoadUserOutputApiModel>) => {
        return response;
      })
    );
  }

  public create(storyId: number, payload: CreateUserInputApiModel) {
    return this.post(`${this.endPoints.stories}/${storyId}/players/`, payload).pipe(
      map((response: any) => {
        return new LoadUserOutputApiModel(response);
      })
    );
  }

  public getCurrentUserProfile() {
    return this.get(`${this.endPoints.users}/profile/`).pipe(
      map((response: LoadUserProfileOutputApiModel) => {
        return response;
      })
    );
  }

  public getCurrentUserPermission()
  { 
    return this.get(`${this.endPoints.adminroles}/permissions/`).pipe(
      map((response: LoadUserProfileOutputApiModel) => {
        return response;
      })
    );
  }
  public getUserSetting() {
    return this.get(`${this.endPoints.users}/settings/`).pipe(
      map((response: LoadUserSettingOutputApiModel) => {
        return response;
      })
    );
  }

  public checkCustomUrl(email: string) {
    return this.get(`${this.endPoints.users}/company/slug/?email=${email}`);
  }

  public getPublicUserProfile(userIdSlug: string) {
    return this.get(`${this.endPoints.users}/${userIdSlug}/`).pipe(
      map((response: LoadUserProfileOutputApiModel) => {
        return response;
      })
    );
  }

  public updateUserSetting(payload: UpdateUserSettingInputApiModel) {
    return this.put(`${this.endPoints.users}/settings/${payload.setting_key}/`, {}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getFollowCompany(queryData: LoadDirectoryInputApiModel) {
    return this.get(`${this.endPoints.users}/directory/?${queryData.query}`).pipe(
      map((response: ResponsePaginationApiModel<DirectoryOutputApiModel>) => {
        return response;
      })
    );
  }

  public getFollowers(queryData: LoadDirectoryInputApiModel) {
    return this.get(`${this.endPoints.users}/directory/followers/?${queryData.query}`).pipe(
      map((response: ResponsePaginationApiModel<DirectoryOutputApiModel>) => {
        return response;
      })
    );
  }

  public getFollowings(queryData: LoadDirectoryInputApiModel) {
    return this.get(`${this.endPoints.users}/directory/followings/?${queryData.query}`).pipe(
      map((response: ResponsePaginationApiModel<DirectoryOutputApiModel>) => {
        return response;
      })
    );
  }

  public update(payload: Partial<UpdateUserProfileInputApiModel>) {
    return this.patch(`${this.endPoints.users}/profile/`, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public changeSlug(payload: Partial<ChangeStorySlugInputApiModel>) {
    return this.put(`${this.endPoints.users}/slug/`, payload).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getEmails() {
    const url: string = `${this.endPoints.users}/emails/`;
    return this.get(url).pipe(
      map((response: UserEmailOutputApiModel[]) => {
        return response;
      })
    );
  }

  public deleteEmail(idEmail: number) {
    const url: string = `${this.endPoints.users}/emails/${idEmail}/`;
    return this.delete(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public resendVerificationEmail(idEmail: number) {
    const url: string = `${this.endPoints.users}/emails/${idEmail}/send/verifications/`;
    const data = {};
    return this.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public addEmail(data: any) {
    const url: string = `${this.endPoints.users}/emails/`;
    return this.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  // public getNotifications() {
  //   const url = `${this.endPoints.users}/settings/notifications/`;
  //   return this.get(url).pipe(
  //     map((response: UserNotificationsOutputApiModel) => {
  //       return response;
  //     })
  //   );
  // }

  // public updateNotifications(data: UserNotificationsInputApiModel) {
  //   const url = `${this.endPoints.users}/settings/notifications/`;
  //   return this.patch(url, data).pipe(
  //     map((response: any) => {
  //       return response;
  //     })
  //   );
  // }


    public getNotifications() {
    const url = `${this.endPoints.users}/settings/notifications/reminders`;
    return this.get(url).pipe(
      map((response: UserNotificationsOutputApiModel) => {
        return response;
      })
    );
  }

  public updateNotifications(data: any) {
    const url = `${this.endPoints.users}/settings/notifications/reminders`;
    return this.patch(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public makePrimaryEmail(id: number) {
    const url: string = `${this.endPoints.users}/emails/${id}/primary/`;
    const data: any = {};
    return this.post(url, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public validateUser(emails: string[]) {
    return this.post(`${this.endPoints.users}/emails/validations/`, emails).pipe(
      map((response: any) => {
        return response.map(item => new ValidateUserOutApiModel(item));
      })
    );
  }
}
