import { AddUserState } from '../states';
import { addUserActions } from '../actions';
import { AddUserEntity } from '../entities';
import { addUserAdapter, initialState } from '../adapters/add-user.adapter';


export function addUserReducer(state = initialState, action: addUserActions.Actions): AddUserState {
    switch (action.type) {

        case addUserActions.ActionTypes.ADD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case addUserActions.ActionTypes.ADD_SUCCESS: {
            const result: any = [action.response.result];
            const entities = result.map(item => new AddUserEntity(item));
            const newSate = addUserAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                // nextUrl: action.response.next,
                // prevUrl: action.response.prev,
                // totalCount: action.response.count
            };
        }

        case addUserActions.ActionTypes.ADD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        default: {
            return state;
        }
    }
}