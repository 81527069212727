import { FollowOutputApiModel } from '../../models/api-models/output';

export interface FollowEntity {
    id: number;
    resourceType: string;
    resourceId: number;
}

export class FollowEntity {
    constructor(data?: FollowOutputApiModel) {
        if (data) {
            this.convertFromApi(data);
        }
    }

    private convertFromApi(data: FollowOutputApiModel) {
       this.id = data.id;
       this.resourceId = data.resource_id;
       this.resourceType = data.resource_type;
    }
}
