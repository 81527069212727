export interface TestimonialSettingOutputApiModel {
    config: TestimonialSettingDataOutputApiModel;
}

export interface TestimonialSettingDataOutputApiModel {
    thank_you_page: ThankyouPageOutputApiModel;
    questions: QuestionsOutputApiModel[];
}

export interface ThankyouPageOutputApiModel {
    type: string;
    image: string;
    profile_id: number;
    title: string;
    content: string;
    buttons: ThankyouButtonOutputApiModel[];
}

export interface QuestionsOutputApiModel {
    id: number;
    text: string;
    isActive: boolean;
    isrequired: boolean;
}

export interface ThankyouButtonOutputApiModel {
    text: string;
    url: string;
    color: string;
    background: string;
    isActive: boolean;
}

export class TestimonialSettingOutputApiModel {
    constructor(data?: Partial<TestimonialSettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}