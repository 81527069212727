import { Injectable, Type } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ModalService {
  private modalConfig: ModalOptions = {
    animated: true
  };
  constructor(private modalService: BsModalService) {

  }

  public showModal(component: Type<any>, initialState: any, config?: Partial<ModalOptions>): Promise<any> {
    this.modalConfig.initialState = { initialState: initialState };
    const mConfig = Object.assign({}, this.modalConfig, config || {});
    const modalRef = this.modalService.show(component, mConfig);
    return new Promise((resolve, reject) => {
      const onCloseSubscribe = modalRef.content.onClose.subscribe((data: any) => {
        resolve(data);
        onCloseSubscribe.unsubscribe();
        modalRef.hide();
      });
    });
  }
}