import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { AdvocacyOutputApiModel } from '../models/api-models/output';
import { UpdateAdvocacyInputApiModel } from '../models/api-models/input';

@Injectable()
export class AdvocacySettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/reviewsites/`).pipe(
            map((response: AdvocacyOutputApiModel[]) => {
                return response;
            })
        )
    }

    public update(companyId: number, advocayId: number, payload: Partial<UpdateAdvocacyInputApiModel>) {
        return this.patch(`/${companyId}/setting/reviewsites/${advocayId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public add(companyId: number, payload: Partial<UpdateAdvocacyInputApiModel>) {
        return this.post(`/${companyId}/setting/reviewsites/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteAdvocacy(companyId: number, advocayId: number) {
        return this.delete(`/${companyId}/setting/reviewsites/${advocayId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
