import { NudgesSettingOutputApiModel, NudgesConfigOutputApiModel } from "../models/api-models/output";

export interface NudgesSettingEntity {
    id: number;
    name: string;
    text: string;
    isSelected: boolean;
    isInternal: boolean;
    isPublic: boolean;
    nudge_name?: any;
    order_cta?: any;
    show_author_information?:boolean
    show_jobs?:boolean;
    allow_change?:boolean;
    conditional_button_text?: any;
    story_count?: any;
    config: NudgesConfigEntity;
}

export interface NudgesConfigEntity {
    form: NudgesButtonFormEntity[];
    formTitle: string;
    link: string;
    selectionText: string;
    type: string;
    donate_button_content?: any;
    donate_button_script?: any;
    donate_button_text?: any;
    show_author_information?:boolean;
    show_jobs?:boolean
    conditional_button_text?: any;
}

export interface NudgesButtonFormEntity {
    title: string;
    isSelected: boolean;
    thankyouText: string;
    isRedirection: boolean;
    redirectionLink: string;
    redirectionText: string;
    isRequiredInfo: boolean;
   
}

export class NudgesSettingEntity {
    constructor(data?: NudgesSettingOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: NudgesSettingOutputApiModel) {
        this.id = data.id;
        this.name = data.name;
        this.isSelected = data.is_active;
        this.text = data.text;
        this.isSelected = data.is_active;
        this.isInternal = data.is_for_internal;
        this.isPublic = data.is_for_public;
        this.nudge_name = data.nudge_name;
        this.allow_change = data.allow_change
        this.order_cta = data.order_cta;
        this.conditional_button_text = data.conditional_button_text;
        this.show_author_information = data.show_author_information;
        this.show_jobs = data.show_jobs;
        this.story_count = data.story_count
        this.config = this.convertConfigFroMApi(data.config);
    }

    private convertConfigFroMApi(data: NudgesConfigOutputApiModel): NudgesConfigEntity {
        return {
            form: data.form.map(data => {
                return {
                    title: data.title,
                    isSelected: data.isEnabled,
                    thankyouText: data.thankyouText,
                    isRedirection: data.isRedirection,
                    redirectionLink: data.redirectionLink,
                    redirectionText: data.redirectionText,
                    isRequiredInfo: data.isRequiredInfo
                }
            }),
            formTitle: data.form_title,
            link: data.button_link,
            selectionText: data.button_text,
            type: data.button_type,
            donate_button_content: data?.donate_button_content,
            donate_button_script: data?.donate_button_script,
            show_author_information: data?.show_author_information,
            show_jobs:data?.show_jobs,
            donate_button_text: data.donate_button_text,
            conditional_button_text: data.conditional_button_text
        }
    }

}