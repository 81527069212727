import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[scrollbar]' // Directive selector
})

export class SrollbarDirective {
  constructor(elem: ElementRef, renderer: Renderer2) {
    
  }
}