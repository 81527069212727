import { MemoizedSelector, createSelector } from '@ngrx/store';

import { LandingPageState, CompanyFeatureState } from '../states';
import { LoadStatusModel } from '@modules/shared/models';
import { landingPageAdapter } from '../adapters/landing-page.adapter';
import { selectCompanyFeatureState } from './company-feature.selectors';
import { LandingPageEntity } from '../entities';
import { LandingPageViewModel } from '../../models/store-view-models';
import { Dictionary } from '@ngrx/entity';


export const selectLandingPageState: MemoizedSelector<object, LandingPageState> = createSelector(selectCompanyFeatureState, (state: CompanyFeatureState) => state.landingPage);

const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAllItems,
    selectTotal: selectTotal
} = landingPageAdapter.getSelectors(selectLandingPageState);


// -------------[Mapping Functions]-------------
const getLoadStatus = (state: LandingPageState): LoadStatusModel => {
    return {
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
        isCanLoadMore: state.nextUrl ? state.nextUrl.length > 0 : null,
    };
};
const getSelectedId = (state: LandingPageState): number => {
    return state.selectedId;
};
const getTotalCount = (state: LandingPageState): number => {
    return state.totalCount;
};
const mapAllToViewModel = (entities: LandingPageEntity[]): LandingPageViewModel[] => {
    return entities.map(entity => new LandingPageViewModel(entity));
};
const getSelectedLandingPage = (entities: Dictionary<LandingPageEntity>, selectedId: number): LandingPageViewModel => {
    if (!selectedId) return null;
    return new LandingPageViewModel(entities[selectedId]);
};
// -------------[Public Selectors]-------------
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> = createSelector(selectLandingPageState, getLoadStatus);
export const selectSelectedId: MemoizedSelector<object, number> = createSelector(selectLandingPageState, getSelectedId);
export const selectAll: MemoizedSelector<object, LandingPageViewModel[]> = createSelector(selectAllItems, mapAllToViewModel);
export const selectLandingPage: MemoizedSelector<object, LandingPageViewModel> =
    createSelector(selectEntities, selectSelectedId, getSelectedLandingPage);
export const selectTotalCount: MemoizedSelector<object, number> = createSelector(selectLandingPageState, getTotalCount);
