import { MemoizedSelector, createSelector } from '@ngrx/store';

import { CompanyFeatureState, CompanySettingState } from '../states';
import { selectCompanyFeatureState } from './company-feature.selectors';
import { WorkAttributeViewModel } from '../../models/store-view-models';


export const selectCompanySettingState: MemoizedSelector<object, CompanySettingState> =
    createSelector(selectCompanyFeatureState, (state: CompanyFeatureState) => state.companySetting);

// -------------[Mapping Functions]-------------

const getWorkAttributes = (state: CompanySettingState): WorkAttributeViewModel[] => {
    return state.workAttibutes.map(m => new WorkAttributeViewModel(m));
};
// -------------[Public Selectors]-------------

export const selectWorkAttributes: MemoizedSelector<object, WorkAttributeViewModel[]> = createSelector(selectCompanySettingState, getWorkAttributes);
