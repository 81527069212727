import { Directive, Inject, ElementRef, forwardRef, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ComponentBase } from '@framework/base/component/component.base';

import { GsMasonryComponent } from './gs-masonry.component';

interface MutationWindow extends Window {
    MutationObserver: any;
    WebKitMutationObserver: any;
}

declare var window: MutationWindow;

@Directive({
    selector: '[gsMasonryItem], gsMasonryItem'
})
export class GsMasonryDirective extends ComponentBase {

    constructor(
        private element: ElementRef,
        @Inject(forwardRef(() => GsMasonryComponent))
        private parent: GsMasonryComponent,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        super();
    }

    protected onInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.parent.add(this.element.nativeElement);
            this.watchForHtmlChanges();
        }
    }
    protected onDestroy(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.parent.remove(this.element.nativeElement);
        }
    }
    protected onChanges(changes: SimpleChanges): void {

    }
    protected onDoCheck(): void {

    }
    protected onAfterContentInit(): void {

    }
    protected onAfterContentChecked(): void {

    }
    protected onAfterViewInit(): void {
       
    }
    protected onAfterViewChecked(): void {

    }

    /** When HTML in brick changes dinamically, observe that and change layout */
    private watchForHtmlChanges(): void {
        MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

        if (MutationObserver) {
            /** Watch for any changes to subtree */
            const observer = new MutationObserver((mutations, observerFromElement) => {
                this.parent.layout();
            });

            // define what element should be observed by the observer
            // and what types of mutations trigger the callback
            observer.observe(this.element.nativeElement, {
                subtree: true,
                childList: true
            });
        }
    }
}