import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EmailCustomizeSettingEntity } from '../entities';
import { EmailCustomizeSettingState } from '../states';

export const emailCustomizeSettingAdapter: EntityAdapter<EmailCustomizeSettingEntity> = createEntityAdapter<EmailCustomizeSettingEntity>();

export const initialState: EmailCustomizeSettingState = emailCustomizeSettingAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});