import { Action } from '@ngrx/store';

import { AddUserOutputApiModel } from '@modules/company-admin/store/models/api-models/output';
import { GetAddUserInputApiModel } from '@modules/company-admin/store/models/api-models/input';


export enum ActionTypes {
    ADD = '[Add User] Add',
    ADD_SUCCESS = '[Add User] Add Success',
    ADD_FAIL = '[Add User] Add Fail',
}

export class Add implements Action {
    readonly type = ActionTypes.ADD;
    constructor(public companyId: number, public queryData: GetAddUserInputApiModel) {
    }
}

export class AddSuccess implements Action {
    readonly type = ActionTypes.ADD_SUCCESS;
    constructor(public response: AddUserOutputApiModel) { }
}

export class AddFail implements Action {
    readonly type = ActionTypes.ADD_FAIL;
    constructor(public error: any) {
    }
}

export type Actions =
    Add | AddSuccess | AddFail;
