<div role="tabpanel" aria-labelledby="tab-page" class="tab-pane fade show active">
  <div class="row">
    <!-- <co-campaign-item-skeleton
      *ngIf="(!landingPages || landingPages.length === 0) && !loadLandingPageStatus.isLoaded && loadLandingPageStatus?.isLoading">
    </co-campaign-item-skeleton> -->
    <div class="row mb-5 text-center text-lg-left"
      *ngIf="landingPages.length === 0 && loadLandingPageStatus.isLoaded && !loadLandingPageStatus?.isLoading">
      <!--<div class="d-none d-lg-block col-12 mb-3 mb-lg-0 offset-lg-1 col-lg-5 pr-lg-7">
        <img src="/assets/front-end-minify/img/readers-reading-stories.svg" />
      </div>-->
      <div class="col-12 col-lg-5">
        <h3 class="mt-lg-6 mb-3">Some special stories are brewing.</h3>
        <p class="mb-4">Check back soon!</p>
      </div>
    </div>
    <div class="col-12 col-lg-6 mb-3" *ngFor="let page of landingPages; trackBy: trackByFn">
      <co-pub-landing-page-item [page]="page" [href]="'/c/' + this.currentCompany.slug + '/featured/' + page.slug">
      </co-pub-landing-page-item>
    </div>
  </div>
  <app-infinity-scroll (infinite)="loadMorePages()" [showIndicator]="showIndicator" [disabled]="disabled"></app-infinity-scroll>
</div>