export interface UpdateMySettingStoryInputApiModel {
  internal_profile_public?: boolean;
  external_profile_public?: boolean;
  external_anonymize?: boolean;
  public?: boolean;
  campaign_id?: number | string;
}

export class UpdateMySettingStoryInputApiModel {
  constructor() {

  }
}