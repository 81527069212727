export interface TagViewModel {
    id: number;
    name: string;
    checked: boolean;
    disabled: boolean;
    is_active?:number;
}

export class TagViewModel {
    constructor(init?: Partial<TagViewModel>) {
        Object.assign(this, init);
    }
}