export interface UploaderValidate{
    imageMinHeight?: number,
    imageMinWidth?: number,
    allowedExtensions?: string[],
    sizeLimit?: number
}

export interface UploaderMessages{
    imageDimensionError?: string,
    maxSizeError?: string,
    typeError?: string
}

export interface UploaderConfig {
    button: HTMLElement,
    companyId: number,
    autoUpload: boolean,
    multiple: boolean,
    isAnonymous: boolean,
    validate: UploaderValidate, 
    messages: UploaderMessages
    
}

export class UploaderConfig {
    constructor() {
        this.multiple = false;
        this.autoUpload = true;
    }
}