import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import { environment } from '@app/env/environment';

export class CognitoHelper {

  constructor() { }

  public static getSecretHash(username: string) {
    // Generate secret hash
    const secretHash = HmacSHA256(`${username}${environment.aws.cognito.clientId}`);
    const secretHashInBase64 = Base64.stringify(secretHash);
    return secretHashInBase64;
  }
}
