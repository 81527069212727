import { ImageGallerySettingOutputApiModel } from "../models/api-models/output";

export interface ImageGallerySettingEntity {
    id: number;
    is_active: boolean;
    image: {
        height: number;
        id: number;
        origname: string;
        size: number;
        url: string;
        width: number;
    }
}

export class ImageGallerySettingEntity {
    constructor(data: ImageGallerySettingOutputApiModel) {
        this.id = data.id;
        this.is_active = data.is_active;
        this.image = data.image;
    }
}