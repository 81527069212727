import { initialStoryBuilderState, StoryBuilderState } from '../states';
import { StoryBuilderActionTypes, StoryBuilderActions, SetStepActive } from '../actions/story-builder.action';
import * as _ from 'lodash';

export function storyBuilderReducer(state = initialStoryBuilderState, action: StoryBuilderActions): StoryBuilderState {
    switch (action.type) {

        case StoryBuilderActionTypes.UPDATE_STEPS_COMPLETED: {
            return {
                ...state,
                stepCompletedIds: action.stepIds
            }
        }

        case StoryBuilderActionTypes.UPDATE_VIEW_MODE: {
            return {
                ...state,
                viewMode: action.viewMode
            }
        }

        case StoryBuilderActionTypes.UPDATE_SHOW_HOVER_FIRST_STEP: {
            return {
                ...state,
                showHoverFirstStep: action.showHoverFirstStep
            }
        }

        case StoryBuilderActionTypes.SET_STEP_ACTIVE: {
            return {
                ...state,
                stepActive: action.stepActive
            }
        }

        case StoryBuilderActionTypes.UPDATE_STORY_DATA: {
            return {
                ...state,
                storyData: {
                    ...state.storyData,
                    ...action.storyData
                }
            }
        }

        case StoryBuilderActionTypes.UPDATE_STORY_STEP_SELECTED: {
            let storyData;
            if(action.fromRte){
                storyData = _.cloneDeep(state.storyData || {});
                storyData.steps = action.dataSelected || {};
                storyData['stepActive']=action.stepId
            }
            else{
                storyData = _.cloneDeep(state.storyData || {});
                storyData.steps = storyData.steps || {};
                storyData.steps[action.stepId] = action.dataSelected;
                storyData['stepActive']=action.stepId
            }

            return {
                ...state,
                storyData: {
                    ...state.storyData,
                    ...storyData
            
                }
            }
        }


        case StoryBuilderActionTypes.SET_INBOUND: {
            return {
                ...state,
                inbound: action.inbound
            }
        }

        case StoryBuilderActionTypes.SET_COMPANY_ID: {
            return {
                ...state,
                companyId: action.companyId
            }
        }

        case StoryBuilderActionTypes.SET_CAN_PREVIEW: {
            return {
                ...state,
                canPreview: action.canPreview
            }
        }

        case StoryBuilderActionTypes.RESET_STATE: {
            return {
                ...initialStoryBuilderState
            }
        }

        default: {
            return state;
        }
    }
}