import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import * as effects from './effects';
import { StoryStoreModule } from '@modules/story/store';
import { UserStoreModule } from '@modules/user/store';
import { environment } from '@app/env/environment';
import * as adminUserStoreModule from '@modules/company-admin/store';
import { CompanyAdminStoreModule } from '@modules/company-admin/store';


let prodAnalyticsEffects = [
    EffectsModule.forFeature([
        effects.CommonAnalyticsEffects,
        effects.StoryAnalyticsEffects,
        effects.CompanyPageAnalyticsEffects,
        effects.MyProfilePageAnalyticsEffects
    ])
];

if (process.env.NODE_ENV === 'development' || environment.isProduction === false) {
    prodAnalyticsEffects = [];
}

@NgModule({
    imports: [
        CommonModule,
        ...prodAnalyticsEffects,
        UserStoreModule,
        StoryStoreModule,
        CompanyAdminStoreModule
    ],
    providers: []
})

export class AnalyticsStoreModule { }
