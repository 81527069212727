import { PaginationModel } from "@framework/models/pagination-models";

export interface GetCampaignInputApiModel {
    isIncludeStories: boolean;
    isLoadExpired: boolean;
    isLoadDrafts: boolean;
    search: string;
    public: boolean;
}

export class GetCampaignInputApiModel {
    constructor(public pagination: PaginationModel) {

    }

    private buildQuery() {
        let query = '';

        if (this.isIncludeStories) {
            query += 'stories_limit=10&';
        }

        if (this.isLoadExpired) {
            query += 'expired=1&';
        }

        if (this.isLoadDrafts) {
            query += 'is_draft=1&';
        }

        if (this.search) {
            query += `search=${this.search}&`;
        }

        if (this.public) {
            query += `public=1&`;
        }

        query += this.pagination.query;

        return query;
    }

    public get query() {
        return this.buildQuery();
    }
}
