import { LandingPageOutputApiModel } from "../models/api-models/output";

export interface LandingPageEntity {
    id: number;
    title: string;
    slug: string;
    author: string;
    draft: any;
    countStories: number;
    createdAt: Date;
    public: boolean;
    active: boolean;
    creator_name?: string;
    board_visible?: boolean;
}

export class LandingPageEntity {
    constructor(data?: LandingPageOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: LandingPageOutputApiModel) {
        this.id = data.id;
        this.title = data.title;
        this.creator_name = data.creator_name,
        this.board_visible = data.board_visible
        this.slug = data.slug;
        this.draft = data.draft
        this.author = data.author;
        this.countStories = data.story_count;
        this.createdAt = data.time_created;
        this.public = data.public;
        this.active = data.active;
    }

}
