import { MemoizedSelector, createSelector } from '@ngrx/store';

import { CompanyState, CompanyFeatureState } from '../states';
import { companyAdapter } from '../adapters/company.adapter';
import { selectCompanyFeatureState } from './company-feature.selectors';
import { Dictionary } from '@ngrx/entity';
import { CompanyEntity } from '../entities';
import { CompanyViewModel, CompanyLoadStatusViewModel } from '../../models/store-view-models';


export const selectCompanyState: MemoizedSelector<object, CompanyState> =
    createSelector(selectCompanyFeatureState, (state: CompanyFeatureState) => state.company);

const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAllItems,
    selectTotal: selectTotal
} = companyAdapter.getSelectors(selectCompanyState);


// -------------[Mapping Functions]-------------

const getSearchedCompany = (state: CompanyState, enitites: Dictionary<CompanyEntity>): CompanyViewModel => {
    return enitites[state.selectedId] as CompanyViewModel;
};
const getLoadStatus = (state: CompanyState): CompanyLoadStatusViewModel => {
    return {
        isSearched: state.isSearched,
        isSearching: state.isSearching
    };
};
const getCreateCompanyDomain = (state: CompanyState): string => {
    return state.createCompanyDomain;
};

const getCurrentUserCompany = (state: CompanyState, enitites: Dictionary<CompanyEntity>): CompanyViewModel => {
    return enitites[state.currentUserCompanyId] as CompanyViewModel;
};

const getSelectedCompany = (state: CompanyState, enitites: Dictionary<CompanyEntity>): CompanyViewModel => {
    return enitites[state.selectedId] as CompanyViewModel;
};

// -------------[Public Selectors]-------------

export const selectSearchedCompany: MemoizedSelector<object, CompanyViewModel> = createSelector(selectCompanyState, selectEntities, getSearchedCompany);
export const selectLoadStatus: MemoizedSelector<object, CompanyLoadStatusViewModel> = createSelector(selectCompanyState, getLoadStatus);
export const selectCreateCompanyDomain: MemoizedSelector<object, string> = createSelector(selectCompanyState, getCreateCompanyDomain);
export const selectCurrentUserCompany: MemoizedSelector<object, CompanyViewModel> = createSelector(selectCompanyState, selectEntities, getCurrentUserCompany);
export const selectSelectedCompany: MemoizedSelector<object, CompanyViewModel> = createSelector(selectCompanyState, selectEntities, getSelectedCompany);

