import { QuestionnaireOutApiModel } from "@modules/story/models/api-models/output";

export interface QuestionnaireSelectionModel {
    id: number;
    name: string;
}

export interface QuestionnaireModel {
    id: number;
    title: string;
    selections: QuestionnaireSelectionModel[];
}

export class QuestionnaireModel {
    constructor(data?: QuestionnaireOutApiModel) {
        if (data)
            this.convertFromApi(data);
    }

    private convertFromApi(data: QuestionnaireOutApiModel) {
        this.title = data.title;
        this.selections = data.selections;
        this.id = data.id;
    }
}