
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { GetAddUserInputApiModel, GetUserInputApiModel } from '../models/api-models/input';

@Injectable()
export class JobsHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/jobs');
    }

    public addJobs(companyId: number, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.post(`/companies/${companyId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public getJobs(companyId: number,paylod:GetUserInputApiModel) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.get(`/companies/${companyId}/?&${paylod.query}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateJobs(JobId: number, payload) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.patch(`/${JobId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public loadLinkjobsStories(companyid: number, jobid:number) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.get(`/companies/${companyid}/${jobid}/stories/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }


    public loadLinkjobsSpotlight(companyid: number, jobid:number) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.get(`/companies/${companyid}/${jobid}/spotlights/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public addRemoveitemsformjobs(companyid:number,jobid:number,isspotlight:boolean,add:boolean,ids){
        let is_spotlight_code = isspotlight ? 1 : 0
        if(!add){
            return this.post(`/companies/${companyid}/${jobid}/active/?is_spotlight=${is_spotlight_code}&ids=${ids.join(',')}`,{}).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
        else{
            return this.delete(`/companies/${companyid}/${jobid}/active/?is_spotlight=${is_spotlight_code}&ids=${ids.join(',')}`).pipe(
                map((response: any) => {
                    return response;
                })
            );
        }
 
    }

    public removeonebyonejobsstories(companyid:number,jobid:number,payload: any){
        return this.patch(`/companies/${companyid}/${jobid}/stories/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public removeonebyonejobspotlights(companyid:number,jobid:number,payload: any){
        return this.patch(`/companies/${companyid}/${jobid}/spotlights/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteJob(JobId) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.delete(`/${JobId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public LinkJobs(storyId,jobIds) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.post(`/stories/${storyId}/`,jobIds).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public SpotlightLinkJobs(spotlightId,jobIds) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.post(`/landing_pages/${spotlightId}/`,jobIds).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public getSpotlightLinkedJobs(spotlightId) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
            if(spotlightId){
                return this.get(`/landing_pages/${spotlightId}/`).pipe(
                    map((response: any) => {
                        return response;
                    })
                );
            }

    }

    public getLinkedJobs(storyId) {
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.get(`/stories/${storyId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public addJobsToStories(companyid,jobID,payload,fromStories){
    if(fromStories){
        return this.post(`/companies/${companyid}/${jobID}/stories/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    else{
        return this.post(`/companies/${companyid}/${jobID}/spotlights/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    }

}

