import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetLandingPageInputApiModel } from '../models/api-models/input';
import { LandingPageOutputApiModel } from '../models/api-models/output';

@Injectable()
export class LandingPageHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetLandingPageInputApiModel) {
        return this.get(`/${companyId}/setting/landing_pages/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<LandingPageOutputApiModel>) => {
                return response;
            })
        );
    }

    public makeActive(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/landing_pages/activate/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInactive(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/landing_pages/activate/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makePublic(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/landing_pages/state/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makePrivate(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/landing_pages/state/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
