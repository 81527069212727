<div role="tabpanel" aria-labelledby="tab-campaign" class="tab-pane fade show active">

  <div class="m-tools d-none d-sm-flex">
    <div [formGroup]="filterForm">
      <label class="switch">
        <input formControlName="isLoadExpired" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text-small ml-2">
        Past Campaigns</span>
    </div>
  </div>

  <!-- Start Campaign-->
  <div class="m-campaign">
    <div class="m-campaign__content">
      <div class="s-campaign">
        <div class="s-campaign__content">
          <co-pb-campaign-item *ngFor="let campaign of campaigns; trackBy: trackByFn" [btnsubmit]="submit_btn" [txtsubmit]="submit_text_Color" [campaign]="campaign"
            [company]="currentCompany" [href]="'/c/' + campaign.companySlug + '/campaigns/' + campaign.slug">
          </co-pb-campaign-item>
          <!-- <co-campaign-item-skeleton
            *ngIf="(!campaigns || campaigns.length === 0) && !loadCampaignStatus.isLoaded && loadCampaignStatus?.isLoading">
          </co-campaign-item-skeleton> -->
          <div class="row mb-5 text-center text-lg-left"
            *ngIf="campaigns.length === 0 && loadCampaignStatus.isLoaded && !loadCampaignStatus?.isLoading">
            <div class="d-none d-lg-block col-12 mb-3 mb-lg-0 offset-lg-1 col-lg-5 pr-lg-7">
              <img src="/assets/front-end-minify/img/motivate-your-peeps.svg" />
            </div>
            <div class="col-12 col-sm-4">
              <h3 class="mt-lg-6 mb-3">From time to time, we launch campaigns in search of stories of good.</h3>
              <p>Check back soon!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="fixed-storyteller-btn">
      <button class="btn btn-create btn-sq btn-hdr text-small btn-mint btn-outline" (click)="share()">
        <span>Invite Authors</span>
      </button>
    </div> -->
    <app-infinity-scroll (infinite)="loadMoreCampaigns()" [showIndicator]="showIndicator" [disabled]="disabled">
    </app-infinity-scroll>
  </div>
  <!-- Close Campaign-->
</div>