import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { StoryEntity } from '../entities';
import { StoryState } from '../states';

export const storyAdapter: EntityAdapter<StoryEntity> = createEntityAdapter<StoryEntity>();

export const initialState: StoryState = storyAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});