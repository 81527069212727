
<div class="modal-header"  style="background-color: #fff;">
	<h2 class="heading__style"><span>Internal Tags</span>
	</h2>
	<button type="button" (click)="close()" class="close">
		<span aria-hidden="true">
			<svg role="img">
				<use xlink:href="#icon-close"></use>
			</svg></span>
	</button>
</div>
<div class="modal-body>">
	<div 
  class="story-flow-view__box view-box-tags  animated outline-none"
 style="background-color: #fff;">
  <div class="view-box-tags__tools">
    <form class="view-box-tags__tools-wrapper" [formGroup]="form" 
    (ngSubmit)="addTag()">
      <div class="view-box-tags__tools-input">
        <input #addTagInput formControlName="tag" maxlength="50" type="text" placeholder="Search or add">
      </div>
      <div class="view-box-tags__tools-button">
        <div [hidden]="!viewmodel.isTagCreating" class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <button [hidden]="viewmodel.isTagCreating" type="submit"  ><i
            class="fas fa-plus-circle"></i></button>
      </div>
    </form>
  </div>
  <!-- <div class="show__tagscount">
    <span class="tagscounttext">{{ ChecktheTagcount() + '/5'}}</span>
  </div> -->
  <div class="view-box-tags__content spacer">

		<div class="view-box-tags__wrapper">
			<div class="l-story-box-tags">
        <ng-container *ngIf="tags.length">
          <ng-container  *ngFor="let item of tags; index as i; trackBy: tagTrackByFn" >
            <label class="l-story-box-tags__item" *ngIf="item.is_active != 0 || (item.is_active == 0 && item.checked)" (click)="tagItemClick(item)"
           >
            <input type="checkbox" [(ngModel)]="item.checked" 
              (change)="selectItem(item)"><span>{{item.name}}</span>
            </label>
          </ng-container>
        </ng-container>

			 
			</div>
		  </div>



    <!-- <div class="view-box-mask__before" [ngStyle]="{'background': viewmodel.bacgroundHover}">
    </div>
    <div class="view-box-mask__after" [ngStyle]="{'background': viewmodel.bacgroundHover}">
    </div> -->
  </div>
</div>
</div>