import { ReportLeadsOutputApiModel } from "../models/api-models/output";

export interface ReportLeadsEntity {
    id: number;
    storyAuthor: string;
    ctaText: string;
    leadName: string;
    leadEmail: string;
    ctaResponse: string;
    submitted: Date;
}

export class ReportLeadsEntity {
    constructor(data?: ReportLeadsOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: ReportLeadsOutputApiModel) {
        this.id = data.id;
        this.storyAuthor = data.story_author;
        this.ctaText = data.cta_text;
        this.leadName = data.lead_name;
        this.leadEmail = data.lead_email;
        this.ctaResponse = data.cta_response;
        this.submitted = data.submitted;
    }

}
