import { initialState, storyAdapter } from '../adapters/story.adapter';
import { StoryState } from '../states';
import { StoryEditEntity, StoryEntity } from '../entities';
import { storyActions } from '../actions';
import { ActionTypes } from "@modules/story/store/actions/story.action";
import { StoryFacetedSearchModel } from '../models';

export function storyReducer(state = initialState, action: storyActions.Actions): StoryState {
  switch (action.type) {

    case storyActions.ActionTypes.LOAD_BY_COMPANY: {
      return {
        ...state,
        loading: true,
        loaded: false,
        queryStoriesData: action.queryData
      }
    }

    case storyActions.ActionTypes.LOAD_BY_USER: {
      return {
        ...state,
        loading: true,
        loaded: false,
        queryStoriesData: action.queryData
      }
    }

    case storyActions.ActionTypes.LOAD_FOR_MY_SETTING: {
      return {
        ...state,
        loading: true,
        loaded: false,
        queryStoriesData: action.queryData
      }
    }

    case storyActions.ActionTypes.LOAD_SUCCESS: {
      const entities = action.response.result.map(item => new StoryEntity(item,action.response.count));
      let newSate;
      if (!action.response.prev) {
        newSate = storyAdapter.setAll(entities, state)
      } else {
        newSate = storyAdapter.addMany(entities, state)
      }
      let facetedSearchResult: StoryFacetedSearchModel = null;
      if (action.response.extended_data) {
        action.response.extended_data['frequent_tags'] = action.response.frequent_tags;
        action.response.extended_data['internal_tags'] = action.response.internal_tags;
        facetedSearchResult = new StoryFacetedSearchModel(action.response.extended_data);
      }
  

      return {
        ...newSate,
        loading: false,
        loaded: true,
        nextUrl: action.response.next,
        prevUrl: action.response.prev,
        total: action.response.count,
        facetedSearchResult: facetedSearchResult,
      }
    }

    case storyActions.ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      }
    }

    case storyActions.ActionTypes.SELECT: {
      return {
        ...state,
        selectedStoryIdOrSlug: action.idOrSlug
      }
    }

    case storyActions.ActionTypes.CREATE: {
      return {
        ...state,
        creating: true
      }
    }

    case storyActions.ActionTypes.CREATE_SUCCESS: {
      const entity: StoryEditEntity = Object.assign(
        Object.create(StoryEditEntity.prototype),
        action.data
      );
      return {
        ...state,
        storyEditData: entity,
        creating: false
      }
    }

    case storyActions.ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        creating: false
      };
    }

    case storyActions.ActionTypes.LOAD_EDIT_SUCCESS: {
      const entity: StoryEditEntity = Object.assign(
        Object.create(StoryEditEntity.prototype),
        action.data
      );

      if (action.data.archived) {
        return {
          ...state
        };
      }

      return {
        ...state,
        storyEditData: entity
      };
    }

    case ActionTypes.LOAD_DETAIL: {
      return {
        ...state,
        loading: true,
        loaded: false
      };
    }

    case storyActions.ActionTypes.LOAD_DETAIL_SUCCESS: {
      const storyUpdate = new StoryEntity(action.data);

      if (action.data.archived) {
        return {
          ...state,
          selectedStoryIdOrSlug: 0,
          loading: false,
          loaded: true
        };
      }

      const curStory = state.entities[storyUpdate.id];
      storyUpdate.highlightTags = curStory && curStory.highlightTags;
      return {
        ...storyAdapter.upsertOne(storyUpdate, state),
        selectedStoryIdOrSlug: storyUpdate.id,
        loading: false,
        loaded: true
      };
    }

    case ActionTypes.LOAD_DETAIL_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true
      };
    }

    case storyActions.ActionTypes.UPDATE: {
      return {
        ...state,
        updating: true
      }
    }

    case storyActions.ActionTypes.UPDATE_SUCCESS: {
      let storyEditData = state.storyEditData;
      if (!action.data || !storyEditData) {
        return {
          ...state,
          updating: false
        }
      }

      storyEditData.slug = action.data.slug;
      return {
        ...state,
        updating: false,
        storyEditData: storyEditData
      }
    }

    case storyActions.ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        updating: false
      }
    }

    case storyActions.ActionTypes.CREATE_INBOUND: {
      return {
        ...state,
        creating: true
      }
    }

    case storyActions.ActionTypes.RESET_STORY_EDIT_DATA: {
      return {
        ...state,
        storyEditData: null
      }
    }

    case storyActions.ActionTypes.SET_EMBEB_MODE_STATUS: {
      return {
        ...state,
        isEmbedMode: action.isEmbedMode
      }
    }

    case storyActions.ActionTypes.RESET_STATE: {
      return {
        ...initialState
      }
    }

    default: {
      return state;
    }
  }
}
