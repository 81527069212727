import { Action } from '@ngrx/store';

import { NotificationOutputApiModel } from '@app/store/http/models/output';
import { LoadNotificationInputApiModel, UpdateNotificationInputApiModel } from '@app/store/http/models/input';
import { ResponsePaginationNotificationApiModel } from '../models/notification-response-pagination.api-model';

export enum ActionTypes {
    LOAD = '[Notification] Load Notification',
    LOAD_SUCCESS = '[Notification] Load Notification Success',
    LOAD_FAILURE = '[Notification] Load Notification Failure',

    CLEAR = '[Notification] Clear Notification',
    CLEAR_SUCCESS = '[Notification] Clear Notification Success',
    CLEAR_FAILURE = '[Notification] Clear Notification Failure',

    UPDATE = '[Notification] Update',
    UPDATE_SUCCESS = '[Notification] Update Success',
    UPDATE_FAILURE = '[Notification] Update Failure',

    MAKE_AS_READ = '[Notification] Make As Read',
    MAKE_AS_READ_SUCCESS = '[Notification] Make As Read Success',
    MAKE_AS_READ_FAILURE = '[Notification] Make As Read Failure',
}


export class Load implements Action {
    readonly type = ActionTypes.LOAD;
    constructor(public payload: LoadNotificationInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationNotificationApiModel) { }
}

export class LoadFailure implements Action {
    readonly type = ActionTypes.LOAD_FAILURE;

    constructor(public error: any) { }
}

export class Clear implements Action {
    readonly type = ActionTypes.CLEAR;
    constructor(public userId: number) { }
}

export class ClearSuccess implements Action {
    readonly type = ActionTypes.CLEAR_SUCCESS;

    constructor(public isSuccess: boolean) { }
}

export class ClearFailure implements Action {
    readonly type = ActionTypes.CLEAR_FAILURE;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;
    constructor(public id: number, public data: UpdateNotificationInputApiModel) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;

    constructor(public id: number, public data: UpdateNotificationInputApiModel) { }
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAILURE;

    constructor(public error: any) { }
}

export class MakeAsRead implements Action {
    readonly type = ActionTypes.MAKE_AS_READ;
    constructor(public id: number) { }
}

export class MakeAsReadSuccess implements Action {
    readonly type = ActionTypes.MAKE_AS_READ_SUCCESS;

    constructor(public id: number) { }
}

export class MakeAsReadFailure implements Action {
    readonly type = ActionTypes.MAKE_AS_READ_FAILURE;

    constructor(public error: any) { }
}

export type Actions = Load
| LoadSuccess
| LoadFailure
| Clear
| ClearSuccess
| ClearFailure
| Update
| UpdateSuccess
| UpdateFailure
| MakeAsRead
| MakeAsReadSuccess
| MakeAsReadFailure;
