import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { userAttributeSettingAction } from '../actions';
import { UserSettingHttpService } from '../services';


@Injectable()
export class UserSettingEffects {

    constructor(
        private actions$: Actions,
        private userSettingHttpService: UserSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.Load>(userAttributeSettingAction.ActionTypes.LOAD),
        switchMap((action: userAttributeSettingAction.Load) => {
            return this.userSettingHttpService.load(action.companyId).pipe(
                map((respone) => {
                    return new userAttributeSettingAction.LoadSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.LoadFail(error));
                }));
        })
    ));


    
    updateAttributeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.UpdateAttribute>(userAttributeSettingAction.ActionTypes.UPDATE_USER_ATTRIBUTE),
        switchMap((action: userAttributeSettingAction.UpdateAttribute) => {
            return this.userSettingHttpService.updateAttribute(action.companyId, action.attributeId, action.payload).pipe(
                map(() => {
                    return new userAttributeSettingAction.UpdateAttributeSuccess();
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.UpdateAttributeFailure(error));
                }))
        })
    ));

    
    addAttributeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.AddAttribute>(userAttributeSettingAction.ActionTypes.ADD_USER_ATTRIBUTE),
        switchMap((action: userAttributeSettingAction.AddAttribute) => {
            return this.userSettingHttpService.addAttribute(action.companyId, action.payload).pipe(
                map(() => {
                    return new userAttributeSettingAction.AddAttributeSuccess();
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.AddAttributeFailure(error));
                }))
        })
    ));

    
    deleteAttributeEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.DeleteAttribute>(userAttributeSettingAction.ActionTypes.DELETE_USER_ATTRIBUTE),
        switchMap((action: userAttributeSettingAction.DeleteAttribute) => {
            return this.userSettingHttpService.deleteAttribute(action.companyId, action.attributeId).pipe(
                map(() => {
                    return new userAttributeSettingAction.DeleteAttributeSuccess();
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.DeleteAttributeFailure(error));
                }))
        })
    ));

    
    updateAttributeValueEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.UpdateAttributeValue>(userAttributeSettingAction.ActionTypes.UPDATE_USER_ATTRIBUTE_VALUE),
        switchMap((action: userAttributeSettingAction.UpdateAttributeValue) => {
            return this.userSettingHttpService.updateAttributeValue(action.companyId, action.valueId, action.payload).pipe(
                map(() => {
                    return new userAttributeSettingAction.UpdateAttributeSuccess();
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.UpdateAttributeFailure(error));
                }))
        })
    ));

    
    addAttributeValueEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.AddAttributeValue>(userAttributeSettingAction.ActionTypes.ADD_USER_ATTRIBUTE_VALUE),
        switchMap((action: userAttributeSettingAction.AddAttributeValue) => {
            return this.userSettingHttpService.addAttributeValue(action.companyId, action.attributeId, action.payload).pipe(
                map(() => {
                    return new userAttributeSettingAction.AddAttributeValueSuccess();
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.AddAttributeValueFailure(error));
                }))
        })
    ));

    
    deleteAttributeValueEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userAttributeSettingAction.DeleteAttributeValue>(userAttributeSettingAction.ActionTypes.DELETE_USER_ATTRIBUTE_VALUE),
        switchMap((action: userAttributeSettingAction.DeleteAttributeValue) => {
            return this.userSettingHttpService.deleteAttributeValue(action.companyId, action.valueId).pipe(
                map(() => {
                    return new userAttributeSettingAction.DeleteAttributeValueSuccess();
                }),
                catchError((error: any) => {
                    return of(new userAttributeSettingAction.DeleteAttributeValueFailure(error));
                }))
        })
    ));
}