import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '@framework/services';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CreateTagOutputApiModel } from '../models/api-models/output';
import { CreateTagInputApiModel } from '../models/api-models/input';

@Injectable({
    providedIn: 'root'
})
export class TagService extends BaseHttpService {

    constructor(protected http: HttpClient) {
        super(http, '/tags');
    }

    public createTag(data: CreateTagInputApiModel) {
        return this.post(`/`, data).pipe(
            map((response: any) => {
                return new CreateTagOutputApiModel(response);
            })
        );
    }
}