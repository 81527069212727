import { CognitoUser } from "amazon-cognito-identity-js";
import BigInteger from 'amazon-cognito-identity-js/src/BigInteger';
import CryptoJS from 'crypto-js/core';
import Base64 from 'crypto-js/enc-base64';
import HmacSHA256 from 'crypto-js/hmac-sha256';
import { Buffer } from 'buffer';

import { CognitoHelper } from './cognito.helper';
import { AuthenticationHelper } from "./authentication-helper";
import { DateHelper } from "./date-helper";

export class GsCognitoUser extends CognitoUser {
    pool: any;
    storage: any;
    username: any;
    deviceKey: any;

    /**
   * Constructs a new CognitoUser object
   * @param {object} data Creation options
   * @param {string} data.Username The user's username.
   * @param {CognitoUserPool} data.Pool Pool containing the user.
   * @param {object} data.Storage Optional storage object.
   */
    constructor(data) {
        super(data);
    }

    /**
  * This uses the refreshToken to retrieve a new session
  * @param {CognitoRefreshToken} refreshToken A previous session's refresh token.
  * @param {nodeCallback<CognitoUserSession>} callback Called on success or error.
  * @returns {void}
  */
    refreshSession(refreshToken, callback) {
        const authParameters = {};
        authParameters['REFRESH_TOKEN'] = refreshToken.getToken();
        const keyPrefix = `CognitoIdentityServiceProvider.${this.pool.getClientId()}`;
        const lastUserKey = `${keyPrefix}.LastAuthUser`;

        if (this.storage.getItem(lastUserKey)) {
            this.username = this.storage.getItem(lastUserKey);
            const deviceKeyKey = `${keyPrefix}.${this.username}.deviceKey`;
            this.deviceKey = this.storage.getItem(deviceKeyKey);
            authParameters['DEVICE_KEY'] = this.deviceKey;
        }

        // Append secret hash
        // authParameters['SECRET_HASH'] = CognitoHelper.getSecretHash(this.username);

        const jsonReq: any = {
            ClientId: this.pool.getClientId(),
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            AuthParameters: authParameters,
        };
        if (this['getUserContextData']()) {
            jsonReq.UserContextData = this['getUserContextData']();
        }
        this['client'].request('InitiateAuth', jsonReq, (err, authResult) => {
            if (err) {
                if (err.code === 'NotAuthorizedException') {
                    this['clearCachedUser']();
                }
                return callback(err, null);
            }
            if (authResult) {
                const authenticationResult = authResult.AuthenticationResult;
                if (!Object.prototype.hasOwnProperty.call(authenticationResult, 'RefreshToken')) {
                    authenticationResult.RefreshToken = refreshToken.getToken();
                }
                this['signInUserSession'] = this['getCognitoUserSession'](authenticationResult);
                this['cacheTokens']();
                return callback(null, this['signInUserSession']);
            }
            return undefined;
        });
    }

    /**
   * PRIVATE ONLY: This is an internal only method and should not
   * be directly called by the consumers.
   * It calls the AuthenticationHelper for SRP related
   * stuff
   * @param {AuthenticationDetails} authDetails Contains the authentication data
   * @param {object} callback Result callback map.
   * @param {onFailure} callback.onFailure Called on any error.
   * @param {newPasswordRequired} callback.newPasswordRequired new
   *        password and any required attributes are required to continue
   * @param {mfaRequired} callback.mfaRequired MFA code
   *        required to continue.
   * @param {customChallenge} callback.customChallenge Custom challenge
   *        response required to continue.
   * @param {authSuccess} callback.onSuccess Called on success with the new session.
   * @returns {void}
   */
    authenticateUserDefaultAuth(authDetails, callback) {
        const authenticationHelper = new AuthenticationHelper(
            this.pool.getUserPoolId().split('_')[1]);
        const dateHelper = new DateHelper();

        let serverBValue;
        let salt;
        const authParameters: any = {};

        if (this.deviceKey != null) {
            authParameters.DEVICE_KEY = this.deviceKey;
        }

        authParameters.USERNAME = this.username;
        authenticationHelper.getLargeAValue((errOnAValue, aValue) => {
            // getLargeAValue callback start
            if (errOnAValue) {
                callback.onFailure(errOnAValue);
            }

            authParameters.SRP_A = aValue.toString(16);

            if (this['authenticationFlowType'] === 'CUSTOM_AUTH') {
                authParameters.CHALLENGE_NAME = 'SRP_A';
            }

            // Append secret hash
            // authParameters.SECRET_HASH = CognitoHelper.getSecretHash(this.username);

            const jsonReq: any = {
                AuthFlow: this['authenticationFlowType'],
                ClientId: this.pool.getClientId(),
                AuthParameters: authParameters,
                ClientMetadata: authDetails.getValidationData(),
            };
            if (this['getUserContextData'](this.username)) {
                jsonReq.UserContextData = this['getUserContextData'](this.username);
            }

            this['client'].request('InitiateAuth', jsonReq, (err, data) => {
                if (err) {
                    return callback.onFailure(err);
                }

                const challengeParameters = data.ChallengeParameters;

                this.username = challengeParameters.USER_ID_FOR_SRP;
                serverBValue = new BigInteger(challengeParameters.SRP_B, 16);
                salt = new BigInteger(challengeParameters.SALT, 16);
                this['getCachedDeviceKeyAndPassword']();

                authenticationHelper.getPasswordAuthenticationKey(
                    this.username,
                    authDetails.getPassword(),
                    serverBValue,
                    salt,
                    (errOnHkdf, hkdf) => {
                        // getPasswordAuthenticationKey callback start
                        if (errOnHkdf) {
                            callback.onFailure(errOnHkdf);
                        }

                        const dateNow = dateHelper.getNowString();

                        const message = CryptoJS.lib.WordArray.create(
                            Buffer.concat([
                                Buffer.from(this.pool.getUserPoolId().split('_')[1], 'utf8'),
                                Buffer.from(this.username, 'utf8'),
                                Buffer.from(challengeParameters.SECRET_BLOCK, 'base64'),
                                Buffer.from(dateNow, 'utf8'),
                            ])
                        );
                        const key = CryptoJS.lib.WordArray.create(hkdf);
                        const signatureString = Base64.stringify(HmacSHA256(message, key));

                        const challengeResponses: any = {};

                        challengeResponses.USERNAME = this.username;
                        challengeResponses.PASSWORD_CLAIM_SECRET_BLOCK = challengeParameters.SECRET_BLOCK;
                        challengeResponses.TIMESTAMP = dateNow;
                        challengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;

                        if (this.deviceKey != null) {
                            challengeResponses.DEVICE_KEY = this.deviceKey;
                        }

                        // Append secret hash
                        // challengeResponses.SECRET_HASH = CognitoHelper.getSecretHash(this.username);

                        const respondToAuthChallenge = (challenge, challengeCallback) =>
                            this['client'].request('RespondToAuthChallenge', challenge,
                                (errChallenge, dataChallenge) => {
                                    if (errChallenge && errChallenge.code === 'ResourceNotFoundException' &&
                                        errChallenge.message.toLowerCase().indexOf('device') !== -1) {
                                        challengeResponses.DEVICE_KEY = null;
                                        this.deviceKey = null;
                                        this['randomPassword'] = null;
                                        this['deviceGroupKey'] = null;
                                        this['clearCachedDeviceKeyAndPassword']();
                                        return respondToAuthChallenge(challenge, challengeCallback);
                                    }
                                    return challengeCallback(errChallenge, dataChallenge);
                                });


                        const jsonReqResp: any = {
                            ChallengeName: 'PASSWORD_VERIFIER',
                            ClientId: this.pool.getClientId(),
                            ChallengeResponses: challengeResponses,
                            Session: data.Session
                        };
                        if (this['getUserContextData']()) {
                            jsonReqResp.UserContextData = this['getUserContextData']();
                        }
                        respondToAuthChallenge(jsonReqResp, (errAuthenticate, dataAuthenticate) => {
                            if (errAuthenticate) {
                                return callback.onFailure(errAuthenticate);
                            }

                            return this['authenticateUserInternal'](
                                dataAuthenticate,
                                authenticationHelper,
                                callback
                            );
                        });
                        return undefined;
                        // getPasswordAuthenticationKey callback end
                    });
                return undefined;
            });
            // getLargeAValue callback end
        });
    }

    /**
     * This is used by an authenticated users to get the userData
     * @param {nodeCallback<UserData>} callback Called on success or error.
     * @returns {void}
     */
    getGsUserData(callback, params) {
        if (!(this['signInUserSession'] != null && this['signInUserSession'].isValid())) {
            this['clearCachedUserData']();
            return callback(new Error('User is not authenticated'), null);
        }

        const bypassCache = params ? params.bypassCache : false;

        const userData = this.storage.getItem(this['userDataKey']);
        // get the cached user data

        if (!userData || bypassCache) {
            this['client'].request('GetUser', {
                AccessToken: this['signInUserSession'].getAccessToken().getJwtToken(),
            }, (err, latestUserData) => {
                if (err) {
                    return callback(err, null);
                }
                this['cacheUserData'](latestUserData);
                const refresh = this['signInUserSession'].getRefreshToken();
                if (refresh && refresh.getToken()) {
                    this.refreshSession(refresh, (refreshError, data) => {
                        if (refreshError) {
                            return callback(refreshError, null);
                        }
                        return callback(null, latestUserData);
                    });
                } else {
                    return callback(null, latestUserData);
                }
            });
        } else {
            try {
                return callback(null, JSON.parse(userData));
            } catch (err) {
                this['clearCachedUserData']();
                return callback(err, null);
            }
        }
        return undefined;
    }

    /**
     * This is used for a certain user to confirm the registration by using a confirmation code
     * @param {string} confirmationCode Code entered by user.
     * @param {bool} forceAliasCreation Allow migrating from an existing email / phone number.
     * @param {nodeCallback<string>} callback Called on success or error.
     * @returns {void}
     */
    confirmRegistration(confirmationCode, forceAliasCreation, callback) {
        const jsonReq: any = {
            ClientId: this.pool.getClientId(),
            ConfirmationCode: confirmationCode,
            Username: this.username,
            ForceAliasCreation: forceAliasCreation,
            // SecretHash: CognitoHelper.getSecretHash(this.username)
        };
        if (this['getUserContextData']()) {
            jsonReq.UserContextData = this['getUserContextData']();
        }
        this['client'].request('ConfirmSignUp', jsonReq, err => {
            if (err) {
                return callback(err, null);
            }
            return callback(null, 'SUCCESS');
        });
    }

    /**
    * This is used by a user to resend a confirmation code
    * @param {nodeCallback<string>} callback Called on success or error.
    * @returns {void}
    */
    resendConfirmationCode(callback) {
        const jsonReq = {
            ClientId: this.pool.getClientId(),
            Username: this.username,
            // SecretHash: CognitoHelper.getSecretHash(this.username)
        };

        this['client'].request('ResendConfirmationCode', jsonReq, (err, result) => {
            if (err) {
                return callback(err, null);
            }
            return callback(null, result);
        });
    }

    /**
   * This is used to initiate a forgot password request
   * @param {object} callback Result callback map.
   * @param {onFailure} callback.onFailure Called on any error.
   * @param {inputVerificationCode?} callback.inputVerificationCode
   *    Optional callback raised instead of onSuccess with response data.
   * @param {onSuccess} callback.onSuccess Called on success.
   * @returns {void}
   */
    forgotPassword(callback) {
        const jsonReq: any = {
            ClientId: this.pool.getClientId(),
            Username: this.username,
            // SecretHash: CognitoHelper.getSecretHash(this.username)
        };
        if (this['getUserContextData']()) {
            jsonReq.UserContextData = this['getUserContextData']();
        }
        this['client'].request('ForgotPassword', jsonReq, (err, data) => {
            if (err) {
                return callback.onFailure(err);
            }
            if (typeof callback.inputVerificationCode === 'function') {
                return callback.inputVerificationCode(data);
            }
            return callback.onSuccess(data);
        });
    }

    /**
  * This is used to confirm a new password using a confirmationCode
  * @param {string} confirmationCode Code entered by user.
  * @param {string} newPassword Confirm new password.
  * @param {object} callback Result callback map.
  * @param {onFailure} callback.onFailure Called on any error.
  * @param {onSuccess<void>} callback.onSuccess Called on success.
  * @returns {void}
  */
    confirmPassword(confirmationCode, newPassword, callback) {
        const jsonReq: any = {
            ClientId: this.pool.getClientId(),
            Username: this.username,
            ConfirmationCode: confirmationCode,
            Password: newPassword,
            // SecretHash: CognitoHelper.getSecretHash(this.username)
        };
        if (this['getUserContextData']()) {
            jsonReq.UserContextData = this['getUserContextData']();
        }
        this['client'].request('ConfirmForgotPassword', jsonReq, err => {
            if (err) {
                return callback.onFailure(err);
            }
            return callback.onSuccess();
        });
    }

    /**
   * This is used by an authenticated user to change the current password
   * @param {string} oldUserPassword The current password.
   * @param {string} newUserPassword The requested new password.
   * @param {nodeCallback<string>} callback Called on success or error.
   * @returns {void}
   */
    changePassword(oldUserPassword, newUserPassword, callback) {
        if (!(this['signInUserSession'] != null && this['signInUserSession'].isValid())) {
            return callback(new Error('User is not authenticated'), null);
        }

        this['client'].request('ChangePassword', {
            PreviousPassword: oldUserPassword,
            ProposedPassword: newUserPassword,
            AccessToken: this['signInUserSession'].getAccessToken().getJwtToken(),
            // SecretHash: CognitoHelper.getSecretHash(this.username)
        }, err => {
            if (err) {
                return callback(err, null);
            }
            return callback(null, 'SUCCESS');
        });
        return undefined;
    }
}