import { Action } from '@ngrx/store';

import { LandingPageOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetLandingPageInputApiModel, GetUserInputApiModel } from '../models/api-models/input';


export enum ActionTypes {
    LOAD = '[Admin Jobs] Load',
    LOAD_SUCCESS = '[Admin Jobs] Load Success',
    LOAD_FAIL = '[Admin Jobs] Load Fail',

     ADD_JOBS = '[Add Admin Jobs] Add Jobs',
     ADD_JOBS_SUCCESS = '[Add Admin Jobs] Add Jobs Success',
     ADD_JOBS_FAIL = '[Add Admin Jobs] Add Jobs Fail',

     REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES = '[Remove Jobs from Spotlight and Stories] Remove jobs from spotlight and stories',
     REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES_SUCCESS = '[Remove Jobs from Spotlight and Stories] Remove jobs from spotlight and stories success',
     REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES_FAIL = '[Remove Jobs from Spotlight and Stories] Remove jobs from spotlight and stories fail',

     REMOVE_JOBS_FROM_SPOTLIGHT = '[Remove Jobs From Spotlight] Remove Jobs from Spotlight',
     REMOVE_JOBS_FROM_SPOTLIGHT_SUCCESS = '[Remove Jobs From Spotlight] Remove Jobs from Spotlight Success',
     REMOVE_JOBS_FROM_SPOTLIGHT_FAIL = '[Remove Jobs From Spotlight] Remove Jobs from Spotlight Fail',

     REMOVE_JOBS_FROM_STORIES = '[Remove Jobs From Stories] Remove Jobs from Stories',
     REMOVE_JOBS_FROM_STORIES_SUCCESS = '[Remove Jobs From Stories] Remove Jobs from Stories Success',
     REMOVE_JOBS_FROM_STORIES_FAIL = '[Remove Jobs From Stories] Remove Jobs from Stories Fail',

     LINK_JOBS_SPOTLIGHT = '[Add Admin Jobs] Add Jobs Spotlight',
     LINK_JOBS_SPOTLIGHT_SUCCESS = '[Add Admin Jobs] Add Jobs Spotlight Success',
     LINK_JOBS_SPOTLIGHT_FAIL = '[Add Admin Jobs] Add Jobs Spotlight Fail',

    UPDATE_JOBS = '[Update Admin Jobs] Update Admin Jobs',
    UPDATE_JOBS_SUCCESS = '[Update Admin Jobs] Update Admin Jobs Success',
    UPDATE_JOBS_FAIL = '[Update Admin Jobs] Update Admin Jobs Fail',

    DELETE_JOBS = '[Delete Admin Jobs] Delete Admin Jobs',
    DELETE_JOBS_SUCCESS = '[Delete Admin Jobs] Delete Admin Jobs Success',
    DELETE_JOBS_FAIL = '[Delete Admin JUpdate] Delete Admin Jobs Fail',

    LINK_JOBS = '[Link Admin Jobs] Link Admin Jobs',
    LINK_JOBS_SUCCESS = '[Link Admin Jobs] Link Admin Jobs Success',
    LINK_JOBS_FAIL = '[Link Admin JUpdate] Link Admin Jobs Fail',

    ADD_ADMIN_JOBS_STORIES = '[Link Admin Jobs to Stories] Link Admin Jobs to Stories',
    ADD_ADMIN_JOBS_STORIES_SUCCESS = '[Link Admin Jobs to Stories] Link Admin Jobs to Stories SUCCESS',
    ADD_ADMIN_JOBS_STORIES_FAIL = '[Link Admin Jobs to Stories] Link Admin Jobs to Stories FAIL',
    
    ADMIN_LINKED_JOBS = '[Admin Linked Jobs] Admin Linked Jobs',
    ADMIN_LINKED_JOBS_SUCCESS = '[Admin Linked Jobs] Admin Linked Jobs Success',
    ADMIN_LINKED_JOBS_FAIL = '[Admin Linked Jobs] Admin Linked Jobs Success Fail',

    ADMIN_SPOTLIGHT_JOBS = '[Admin Linked Spotlight Jobs] Admin Linked Spotlight Jobs',
    ADMIN_SPOTLIGHT_JOBS_SUCCESS = '[Admin Linked Spotlight Jobs] Admin Linked Spotlight Jobs Success',
    ADMIN_SPOTLIGHT_JOBS_FAIL = '[Admin Linked Spotlight Jobs] Admin Linked Spotlight Jobs Fail',

    GET_LINKED_JOBS = '[Linked Admin Jobs] Get Linked Admin Jobs',
    GET_LINKED_JOBS_SUCCESS = '[Linked Admin Jobs] Get Linked Admin Jobs Success',
    GET_LINKED_JOBS_FAIL = '[Linked Admin Jobs] Get Linked Admin Jobs Fail',

    GET_SPOTLIGHT_JOBS = '[Linked Spotlight Jobs] Get Linked Admin Spotlight Jobs',
    GET_SPOTLIGHT_JOBS_SUCCESS = '[Linked Spotlight Jobs] Get Linked Admin Spotlight Jobs Success',
    GET_SPOTLIGHT_JOBS_FAIL = '[Linked Spotlight Jobs] Get Linked Admin Spotlight Jobs Fail',

}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public query: GetUserInputApiModel ) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: any) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class AddAdminJobstoStories implements Action {
    readonly type = ActionTypes.ADD_ADMIN_JOBS_STORIES;

    constructor(public companyId: number, public jobId, public payload, public fromStories:boolean) { }
}

export class AddAdminJobstoStoriesSuccess implements Action {
    readonly type = ActionTypes.ADD_ADMIN_JOBS_STORIES_SUCCESS;
}

export class AddAdminJobstoStoriesFail implements Action {
    readonly type = ActionTypes.ADD_ADMIN_JOBS_STORIES_FAIL;
}

export class UpdateJobs implements Action {
    readonly type = ActionTypes.UPDATE_JOBS;

    constructor(public jobid: number, public payload: any) { }
}

export class UpdateJobsSuccess implements Action {
    readonly type = ActionTypes.UPDATE_JOBS_SUCCESS;
}

export class UpdateJobsFail implements Action {
    readonly type = ActionTypes.UPDATE_JOBS_FAIL;

    constructor(public error: any) { }
}

export class AddJobs implements Action {
    readonly type = ActionTypes.ADD_JOBS;

    constructor(public companyId: number, public payload) { }
}

export class AddJobsSuccess implements Action {
    readonly type = ActionTypes.ADD_JOBS_SUCCESS;
}

export class AddJobsFail implements Action {
    readonly type = ActionTypes.ADD_JOBS_FAIL;

    constructor(public error: any) { }
}

export class LinkSpotlightJobs implements Action {
    readonly type = ActionTypes.LINK_JOBS_SPOTLIGHT;

    constructor(public spotlightId:any,public jobid: any) { }
}

export class LinkSpotlightJobsSuccess implements Action {
    readonly type = ActionTypes.LINK_JOBS_SPOTLIGHT_SUCCESS;
}

export class LinkSpotlightJobsFail implements Action {
    readonly type = ActionTypes.LINK_JOBS_SPOTLIGHT_FAIL;

    constructor(public error: any) { }
}

export class DeleteJobs implements Action {
    readonly type = ActionTypes.DELETE_JOBS;

    constructor(public jobid: number) { }
}

export class DeleteJobsSuccess implements Action {
    readonly type = ActionTypes.DELETE_JOBS_SUCCESS;
}

export class DeleteJobsFail implements Action {
    readonly type = ActionTypes.DELETE_JOBS_FAIL;

    constructor(public error: any) { }
}

export class LinkJobs implements Action {
    readonly type = ActionTypes.LINK_JOBS;

    constructor(public storyId:any,public jobid: any) { }
}

export class LinkJobsSuccess implements Action {
    readonly type = ActionTypes.LINK_JOBS_SUCCESS;
}

export class LinkJobsFail implements Action {
    readonly type = ActionTypes.LINK_JOBS_FAIL;

    constructor(public error: any) { }
}

export class GetLinkJobs implements Action {
    readonly type = ActionTypes.GET_LINKED_JOBS;

    constructor(public storyId:any) { }
}

export class GetLinkJobsSuccess implements Action {
    readonly type = ActionTypes.GET_LINKED_JOBS_SUCCESS;
    constructor(public response: any) { }
}

export class GetLinkJobsFail implements Action {
    readonly type = ActionTypes.GET_LINKED_JOBS_FAIL;

    constructor(public error: any) { }
}

export class GetSpotlightJobs implements Action {
    readonly type = ActionTypes.GET_SPOTLIGHT_JOBS;

    constructor(public spotlightId: any) { }
}

export class GetSpotlightJobsSuccess implements Action {
    readonly type = ActionTypes.GET_SPOTLIGHT_JOBS_SUCCESS

    constructor(public response: any) { }
}

export class GetSpotlightJobsFail implements Action {
    readonly type = ActionTypes.GET_SPOTLIGHT_JOBS_FAIL;

    constructor(public error: any) { }
}

export class RemoveAllJobsFromSpotlightAndStories implements Action {
    readonly type = ActionTypes.REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES;

    constructor(public cid: any, public jid: any, public isSpotlight:boolean, public isAddorRemove: boolean, public id:any ) { }
}

export class RemoveAllJobsFromSpotlightAndStoriesSuccess implements Action {
    readonly type = ActionTypes.REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES_SUCCESS;
}
export class RemoveAllJobsFromSpotlightAndStoriesFail implements Action {
    readonly type = ActionTypes.REMOVE_ALL_JOBS_SPOTLIGHT_AND_STORIES_FAIL;
}

export class AdminLinkedJobs implements Action {
    readonly type = ActionTypes.ADMIN_LINKED_JOBS;

    constructor(public cid: any,public jid: any) { }
}

export class AdminLinkedJobsSuccess implements Action {
    readonly type = ActionTypes.ADMIN_LINKED_JOBS_SUCCESS;
    constructor(public response: any) { }
}
export class AdminLinkedJobsFail implements Action {
    readonly type = ActionTypes.ADMIN_LINKED_JOBS_FAIL;
}

export class AdminLinkedSpotlightJobs implements Action {
    readonly type = ActionTypes.ADMIN_SPOTLIGHT_JOBS;

    constructor(public cid: any, public jid) { }
}

export class AdminLinkedSpotlightJobsSuccess implements Action {
    readonly type = ActionTypes.ADMIN_SPOTLIGHT_JOBS_SUCCESS;
    constructor(public response: any) { }
}
export class AdminLinkedSpotlightJobsFail implements Action {
    readonly type = ActionTypes.ADMIN_SPOTLIGHT_JOBS_FAIL;
}


export class RemoveJobsFromStories implements Action {
    readonly type = ActionTypes.REMOVE_JOBS_FROM_STORIES;

    constructor(public companyId: any, public jobid: any, public payload: any) { }
}

export class RemoveJobsFromStoriesSuccess implements Action {
    readonly type = ActionTypes.REMOVE_JOBS_FROM_STORIES_SUCCESS;
}
export class RemoveJobsFromStoriesFail implements Action {
    readonly type = ActionTypes.REMOVE_JOBS_FROM_STORIES_FAIL;
}

export class RemoveJobsFromSpotlight implements Action {
    readonly type = ActionTypes.REMOVE_JOBS_FROM_SPOTLIGHT;

    constructor(public companyId: any,public jobId: any, public payload:any) { }
}

export class RemoveJobsFromSpotlightSuccess implements Action {
    readonly type = ActionTypes.REMOVE_JOBS_FROM_SPOTLIGHT_SUCCESS;
}
export class RemoveJobsFromSpotlightFail implements Action {
    readonly type = ActionTypes.REMOVE_JOBS_FROM_SPOTLIGHT_FAIL;
}



export type Actions =
     Load | LoadSuccess | LoadFail 
    | AddJobs | AddJobsSuccess | AddJobsFail
    | UpdateJobs | UpdateJobsSuccess | UpdateJobsFail 
    | DeleteJobs | DeleteJobsSuccess | DeleteJobsFail
    | UpdateJobs | UpdateJobsSuccess | UpdateJobsFail
    | LinkJobs   | LinkJobsSuccess   | LinkJobsFail
    | GetLinkJobs | GetLinkJobsSuccess | GetLinkJobsFail
    | GetSpotlightJobs | GetSpotlightJobsSuccess | GetSpotlightJobsFail
    | LinkSpotlightJobs | LinkSpotlightJobsFail | LinkSpotlightJobsSuccess
    | RemoveAllJobsFromSpotlightAndStories | RemoveAllJobsFromSpotlightAndStoriesFail | RemoveAllJobsFromSpotlightAndStoriesSuccess
    | RemoveJobsFromSpotlight | RemoveJobsFromSpotlightFail | RemoveJobsFromSpotlightSuccess
    | RemoveJobsFromStories | RemoveJobsFromStoriesSuccess | RemoveJobsFromStoriesFail
    | AdminLinkedJobs | AdminLinkedJobsSuccess | AdminLinkedJobsFail
    | AdminLinkedSpotlightJobs | AdminLinkedSpotlightJobsSuccess | AdminLinkedSpotlightJobsFail
    | AddAdminJobstoStories | AddAdminJobstoStoriesSuccess | AddAdminJobstoStoriesFail

 