import { MemoizedSelector, createSelector } from "@ngrx/store";

import { DirectoryState, UserFeatureState } from "../states";
import { directoryAdapter } from '../adapters/directory.adapter';
import { selectUserFeatureState } from "./user-feature.selectors";
import { DirectoryEntity } from '../entities';
import { DirectoryViewModel, DirectoryLoadStatusViewModel } from "@modules/user/models/store-view-models";

export const selectDirectoryState: MemoizedSelector<object, DirectoryState> = 
createSelector(selectUserFeatureState, (state: UserFeatureState) => state.directory);

const {
  selectIds: selectIds,
  selectEntities: selectMediaEntities,
  selectAll: selectAll,
  selectTotal: selectTotal
} = directoryAdapter.getSelectors(selectDirectoryState);


// -------------[Mapping Functions]-------------
const getLoadStatus = (state: DirectoryState): DirectoryLoadStatusViewModel => {
  return {
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
    isCanLoadMore: state.nextUrl && state.nextUrl.length > 0,
    total: state.total,
    isCompanyLoaded: state.isCompanyLoaded,
    isFollowerLoaded: state.isFollowerLoaded,
    isFollowingLoaded: state.isFollowingLoaded,
    companyDirectoryTotal: state.companyDirectoryTotal,
    followerDirectoryTotal: state.followerDirectoryTotal,
    followingDirectoryTotal: state.followingDirectoryTotal,
    isCompanyCanLoadMore: state.companyDirectoryIds.length < state.companyDirectoryTotal,
    isFollowerCanLoadMore: state.followerDirectoryIds.length < state.followerDirectoryTotal,
    isFollowingCanLoadMore: state.followingDirectoryIds.length < state.followingDirectoryTotal,
    isCompanyForceLoad: state.isCompanyForceLoad,
    isFollowerForceLoad: state.isFollowerForceLoad,
    isFollowingForceLoad: state.isFollowingForceLoad,
  };
};

const getDirectory = (entities: Array<DirectoryEntity>, ids: number[]) => {
  ids = ids || [];
  return entities.filter(entity => ids.indexOf(entity.id) > -1).map((entity) => new DirectoryViewModel(entity));
};

const getCompanyDirectoryIds = (state: DirectoryState) => {
  return state.companyDirectoryIds;
};

const getFollowerDirectoryIds = (state: DirectoryState) => {
  return state.followerDirectoryIds;
};

const getFollowingDirectoryIds = (state: DirectoryState) => {
  return state.followingDirectoryIds;
};

// -------------[Public Selectors]-------------
export const selectLoadStatus: MemoizedSelector<object, DirectoryLoadStatusViewModel> = createSelector(selectDirectoryState, getLoadStatus);
export const selectDirectoriesByIds = createSelector(selectAll, getDirectory);

export const selectCompanyDirectoryIds: MemoizedSelector<object, Array<number>> = createSelector(selectDirectoryState, getCompanyDirectoryIds);
export const selectFollowerDirectoryIds: MemoizedSelector<object, Array<number>> = createSelector(selectDirectoryState, getFollowerDirectoryIds);
export const selectFollowingDirectoryIds: MemoizedSelector<object, Array<number>> = createSelector(selectDirectoryState, getFollowingDirectoryIds);

