import { IComponentControlBase } from './component-control.interface.base';
import { ComponentBase } from './component.base';

export abstract class ComponentControlBase extends ComponentBase implements IComponentControlBase {
  
  public abstract focus(): void;

  constructor(){
    super();
  }
}