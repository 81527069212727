import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@framework/services';

@Injectable({
  providedIn: 'root'
})
export class ConfirmEmailService extends BaseHttpService {

  constructor(httpClient: HttpClient) {
    super(httpClient, '');
  }

  confirmEmail(token: string) {
    return this.post('/users/emails/verifications/', { token });
  }
}
