import { Component, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import * as Saction from '@modules/company-admin/store/actions'
import { companySelectors } from '@modules/company/store/selectors';
import { CompanyViewModel } from '@modules/company/models/store-view-models';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent extends ComponentBase {
  // Private variables
  private selectCompany$: Observable<CompanyViewModel>;

  private selectCompanySubscription: Subscription;

  private LoadButtonColorSuccessSubscriptin: Subscription;

  public footerColor:any;
  // Public variables
  public selectedCompany: CompanyViewModel;

  constructor(private store$: Store<CoreState>,private actions$: Actions) {
    super();

    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.selectedCompany = company;
        this.store$.dispatch(new Saction.storyActions.GetColor(this.selectedCompany.id));
      }
    });
    this.LoadButtonColorSuccessSubscriptin = this.actions$.pipe(
      ofType<Saction.storyActions.GetColorSuccess>(Saction.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action:any) => {
      
       if(action && action.response && action.response && action.response.length){
        this.footerColor = action.response.find(item => item.button == 3).color;
       }
    })
  }
  private unsubscribe() {
    if(this.selectCompanySubscription)
    {
      this.selectCompanySubscription.unsubscribe();
    }

  }

  // Public functions

}
