import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { CommentEntity } from '../entities';
import { CommentState } from '../states';

export const commentAdapter: EntityAdapter<CommentEntity> = createEntityAdapter<CommentEntity>();

export const initialState: CommentState = commentAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: ''
});