import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { StoryStepEntity } from '../entities';
import { StoryStepState } from '../states';

export const storyStepAdapter: EntityAdapter<StoryStepEntity> = createEntityAdapter<StoryStepEntity>();
export const initialState: StoryStepState = storyStepAdapter.getInitialState({
  loading: false,
  loaded: false,
  createdTagId: 0,
  stepSelectedId: 0
});