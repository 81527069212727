import * as _ from 'lodash';

import { initialState, storyStepAdapter } from '../adapters/story-step.adapter';
import { StoryStepState } from '../states';
import { StoryStepEntity } from '../entities';
import { storyStepActions } from '../actions';
import { TagViewModel } from '@modules/story/models/view-models';

export function storyStepReducer(state = initialState, action: storyStepActions.Actions): StoryStepState {
  switch (action.type) {

    case storyStepActions.ActionTypes.LOAD: {
      return {
        ...state,
        loaded: false,
        loading: true
      }
    }

    case storyStepActions.ActionTypes.SELECT_STEP_SETTING: {
      return {
        ...state,
        stepSelectedId: action.stepId
      }
    }

    case storyStepActions.ActionTypes.LOAD_SUCCESS: {
      const entities = action.data.map(item => new StoryStepEntity(item));
      return {
        ...storyStepAdapter.setAll(entities, state),
        loaded: true,
        loading: false
      }
    }

    case storyStepActions.ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      }
    }

    case storyStepActions.ActionTypes.LOAD_CONTRIBUTION_STEP: {
      return {
        ...state,
        loaded: false,
        loading: true
      }
    }

    case storyStepActions.ActionTypes.LOAD_CONTRIBUTION_STEP_SUCCESS: {
      const entities = action.reponse.map(item => new StoryStepEntity(item));
      return {
        ...storyStepAdapter.setAll(entities, state),
        loaded: true,
        loading: false
      }
    }

    case storyStepActions.ActionTypes.LOAD_CONTRIBUTION_STEP_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      }
    }

    case storyStepActions.ActionTypes.CREATE_TAG_SUCCESS: {
      let stepUpdate = _.cloneDeep(state.entities[action.stepId]);
      const createdTag: TagViewModel = {
        id: action.data.id,
        name: action.data.name,
        checked: false,
        disabled: false
      };
      stepUpdate.tags.unshift(createdTag);

      return {
        ...storyStepAdapter.upsertOne(stepUpdate, state),
        createdTagId: action.data.id,
        stepSelectedId: action.stepId
      }
    }

    case storyStepActions.ActionTypes.CREATE_TAG_FAILURE: {
      return {
        ...state,
        createdTagId: 0,
        stepSelectedId: 0
      }
    }

    case storyStepActions.ActionTypes.RESET_STATE: {
      return {
        ...initialState
      }
    }

    default: {
      return state;
    }
  }
}