import { MemoizedSelector, createSelector } from "@ngrx/store";
import { StoryFeatureState, StoryStepState, ContributionState } from "../states";
import { selectStoryFeatureState } from "./story-feature.selector";
import { storyStepAdapter } from '../adapters/story-step.adapter';
import { StoryStepEntity, ContributionEntity } from '../entities';
import { StoryStepViewModel, TagViewModel, ContributionViewModel } from "@modules/story/models/view-models";
import { contributionAdapter } from "../adapters/contribution.adapter";
import { LoadStatusModel } from "@modules/shared/models";
import { CreateContributionApiModel } from "@modules/story/models/api-models/input";
import { Dictionary } from "@ngrx/entity";

export const selectContributionState: MemoizedSelector<object, ContributionState> = createSelector(selectStoryFeatureState, (state: StoryFeatureState) => state.contribution);

const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAll,
    selectTotal: selectTotal
} = contributionAdapter.getSelectors(selectContributionState);


// -------------[Mapping Functions]------------- 
const getLoadStatus = (state: ContributionState): LoadStatusModel => {
    return {
        isLoaded: state.isLoaded,
        isLoading: state.isLoading
    };
};
const getStepActived = (state: ContributionState): StoryStepViewModel => {
    return state.stepActived;
};
const getStepCompleted = (state: ContributionState): number[] => {
    return state.stepCompleted;
};
const getStepValue = (state: ContributionState): any => {
    if (!state.stepActived || !state.contributionBuilderData) return null;
    return state.contributionBuilderData[state.stepActived.id];
};
const getBuilderData = (state: ContributionState): CreateContributionApiModel => {
    return state.contributionBuilderData;
};
const getSelectedId = (state: ContributionState): number => {
    return state.selectedId;
};
const getContributionSelected = (selectedId: number, entities: Dictionary<ContributionEntity>): ContributionViewModel => {
    if (!selectedId) return null;
    return new ContributionViewModel(entities[selectedId]);
};

// -------------[Public Selectors]-------------
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> = createSelector(selectContributionState, getLoadStatus);
export const selectStepActived: MemoizedSelector<object, StoryStepViewModel> = createSelector(selectContributionState, getStepActived);
export const selectStepCompleted: MemoizedSelector<object, number[]> = createSelector(selectContributionState, getStepCompleted);
export const selectStepValue: MemoizedSelector<object, any> = createSelector(selectContributionState, getStepValue);
export const selectBuilderData: MemoizedSelector<object, CreateContributionApiModel> = createSelector(selectContributionState, getBuilderData);
export const selectSelectedId: MemoizedSelector<object, number> = createSelector(selectContributionState, getSelectedId);
export const selectContributionSelected: MemoizedSelector<object, ContributionViewModel> = createSelector(selectSelectedId, selectEntities, getContributionSelected);