<!-- <div class="b-search">

  <div class="b-search__inner">
    <div class="container">
      <div class="m-search-form" [formGroup]="searchForm">
        <div class="m-search-form__content">
          <div class="m-search-form__input">
            <input formControlName="searchText" type="text" placeholder="Type to search" autofocus class="form-control">
          </div>
        </div>
        <div class="m-search-form__buttons">
          <div class="form-inline">
            <button class="btn btn-trans text-gray" (click)="clear()" *ngIf="searchForm.controls.searchText.valid">
              <svg role="img">
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-infinity-scroll (infinite)="loadMoreStories()" [showIndicator]="showIndicator" [disabled]="disabled"></app-infinity-scroll> -->

<!-- <div class="b-search">
  <div class="b-search__inner">
    <div class="container">
      <div class="m-search-form" [formGroup]="searchForm">
        <div class="m-search-form__content">
          <div class="m-search-form__input">
            <input formControlName="searchText" type="text" placeholder="Type to search" autofocus class="form-control">
          </div>
        </div>
        <div class="m-search-form__buttons">
          <div class="form-inline">
            <button class="btn btn-trans text-gray" (click)="clear()" *ngIf="searchForm.controls.searchText.valid">
              <svg role="img">
                <use xlink:href="#icon-close"></use>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-infinity-scroll (infinite)="loadMoreStories()" [showIndicator]="showIndicator" [disabled]="disabled"></app-infinity-scroll> -->


<div class="b-search b-search--cover" [@showhide]="searchBoxStatus?.isShow ? 'show' : 'hide'">
  <div class="b-search__inner">
    <div class="container" [formGroup]="searchForm">
      <div *ngIf="isShowForm">
        <div class="m-search-form" >
          <div class="m-search-form__content">
            <div class="m-search-form__input">
              <input formControlName="searchText" type="text" placeholder="Type to search" autoFocus="true"
                class="form-control" (keyup.enter)="doSearch()" (focus)="inputFocus = true" (blur)="inputFocus = false">
            </div>
          </div>

          <div class="m-search-form__right">

          <div class="m-search-form__filters">
            <!-- <div class="m-search-form__filters__tags mr-2" *ngIf="searchForm?.controls.tags?.controls.length > 0">
              <div class="dropdown dropdown-select">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        class="btn btn-block dropdown-toggle">Tags</button>
                <div class="dropdown-menu">
                  <scrollbar>
                    <form *ngIf="searchForm?.controls.tags && searchForm?.controls.tags?.controls.length > 0 else noOptions">
                      <div class="dropdown-item"
                           *ngFor="let tag of searchForm?.controls.tags?.controls; trackBy: tagsTrackByFunc; let i=index;">
                        <div class="custom-control custom-checkbox" *ngIf="facetedTags[i]">
                          <label class="mb-0">
                            <input type="checkbox" [formControl]="tag" [value]="facetedTags[i].name" class="custom-control-input"><span
                                  class="custom-control-label">{{facetedTags[i].name}} ({{facetedTags[i].total}})</span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </scrollbar>
                </div>
              </div>
            </div> -->
            <!-- <div class="m-search-form__filters__campaigns mr-2" *ngIf="searchForm?.controls.campaigns?.controls.length > 0">
              <div class="dropdown dropdown-select">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        class="btn btn-block dropdown-toggle">Campaigns</button>
                <div class="dropdown-menu">
                  <form *ngIf="searchForm?.controls.campaigns && searchForm?.controls.campaigns?.controls.length > 0 else noOptions">
                    <div class="dropdown-item"
                         *ngFor="let campaign of searchForm?.controls.campaigns?.controls; trackBy: campaignsTrackByFunc; let i=index;">
                      <div class="custom-control custom-checkbox" *ngIf="facetedCampaigns[i]">
                        <label class="mb-0">
                          <input type="checkbox" [formControl]="campaign" class="custom-control-input"><span
                                class="custom-control-label">{{facetedCampaigns[i].name}} ({{facetedCampaigns[i].total}})</span>
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> -->
            <!-- <div class="m-search-form__filters__people mr-3" *ngIf="searchForm?.controls.peoples?.controls.length > 0">
              <div class="dropdown dropdown-select">
                <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        class="btn btn-block dropdown-toggle">People</button>
                <div class="dropdown-menu">
                  <scrollbar>
                    <form *ngIf="searchForm?.controls.peoples && searchForm?.controls.peoples?.controls.length > 0 else noOptions">
                      <div class="dropdown-item"
                           *ngFor="let people of searchForm?.controls.peoples?.controls; trackBy: peoplesTrackByFunc; let i=index;">
                        <div class="custom-control custom-checkbox" *ngIf="facetedPeoples[i]">
                          <label class="mb-0">
                            <input type="checkbox" [formControl]="people" class="custom-control-input">
                            <span class="custom-control-label">
                              <gs-avatar [src]="facetedPeoples[i] && facetedPeoples[i].avatar | imageCloudinary" isBackground="true" classCss="avatar avatar--xs mr-2"></gs-avatar>
                              <span class="spacer">{{facetedPeoples[i].name}} ({{facetedPeoples[i].total}})</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </scrollbar>
                </div>
              </div>
            </div> -->
            <div class="m-search-form__filters__options">
              <label class="margin__right_3rem">
                <span class="switch mr-2">
                  <input type="checkbox" formControlName="includeStories" class="custom-control-input">
                  <span class="slider"></span>
                </span>
                <span class="text-small">From Our Team</span>
              </label>
              <label class="margin__right_3rem">
                <span class="switch mr-2">
                  <input type="checkbox" formControlName="includeTestimonials" class="custom-control-input">
                  <span class="slider"></span>
                </span>
                <span class="text-small">From Guests</span>
              </label>
            </div>
          </div>
          <div class="m-search-form__buttons">
            <div class="form-inline">
              <button class="btn btn-trans text-gray" (click)="clear()">
                <svg role="img">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
            </div>
          </div>
          </div>

        </div>
        <div class="m-search-option" *ngIf="isShowSearchFilter()">
          <ng-template #noOptions>
            <div class="dropdown-item">
              <span>Not available.</span>
            </div>
          </ng-template>

        </div>
      </div>
      <div class="m-search-result">
        <div class="m-search-result__counter mr-3">{{storyLoadStatus?.total}} result<span *ngIf="storyLoadStatus?.total!=1">s</span></div>
        <div class="m-search-result__inner mt-3 mt-md-0">
          <owl-carousel [options]="owlOptions" [items]="facetedTagSelected" [carouselClasses]="['list-chip-tag owl-carousel', 'sliding']" >
            <div class="chip-tag" *ngIf="searchText">
              <span>{{searchText}}</span>
            </div>

            <!-- Tag -->
            <div class="chip-tag" [style.color]="item.color" *ngFor="let item of facetedTagSelected; let i=index">
              <svg role="img" class="icon16 mx-1">
                <use xlink:href="#icon-tag"></use>
              </svg><span>{{item.name}}</span>
              <button class="btn btn-trans text-gray" (click)="deleteTagItem(i, item)">
                <svg role="img" class="icon16">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
            </div>

            <!-- People -->
            <div class="chip-tag" *ngFor="let item of facetedPeopleSelected; let i=index">
              <gs-avatar [src]="item && item.avatar" isBackground="true" classCss="avatar">
              </gs-avatar>
              <span>{{item.name}}</span>
              <button class="btn btn-trans" (click)="deletePeopleItem(i, item)">
                <svg role="img" class="icon16">
                  <use xlink:href="#icon-close"></use>
                </svg>
              </button>
            </div>

          </owl-carousel>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-infinity-scroll (infinite)="loadMoreStories()" [showIndicator]="showIndicator" [disabled]="disabled"></app-infinity-scroll>  -->