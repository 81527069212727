export interface StepPreviewDataViewModel {
    id: number;
    title: string;
    values: { id: number | string; name: string }[];
    sort: number;
    icon: string;
    color: string;
}

export class StepPreviewDataViewModel {
    constructor() {

    }
}
