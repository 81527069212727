import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TestimonialAudienceSettingHttpService } from '../services';
import { testimonialAudienceSettingActions } from '../actions';


@Injectable()
export class TestimonialAudienceSettingEffects {

    constructor(
        private actions$: Actions,
        private testimonialAudienceSettingHttpService: TestimonialAudienceSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialAudienceSettingActions.Load>(testimonialAudienceSettingActions.ActionTypes.LOAD),
        switchMap((action: testimonialAudienceSettingActions.Load) => {
            return this.testimonialAudienceSettingHttpService.load(action.companyId).pipe(
                map((response) => {
                    return new testimonialAudienceSettingActions.LoadSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new testimonialAudienceSettingActions.LoadFail(error));
                }));
        })
    ));


    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialAudienceSettingActions.Update>(testimonialAudienceSettingActions.ActionTypes.UPDATE),
        switchMap((action: testimonialAudienceSettingActions.Update) => {
            return this.testimonialAudienceSettingHttpService.update(action.companyId, action.audienceId, action.payload).pipe(
                map(() => {
                    return new testimonialAudienceSettingActions.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new testimonialAudienceSettingActions.UpdateFailure(error));
                }))
        })
    ));

    
    addEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialAudienceSettingActions.Add>(testimonialAudienceSettingActions.ActionTypes.ADD),
        switchMap((action: testimonialAudienceSettingActions.Add) => {
            return this.testimonialAudienceSettingHttpService.add(action.companyId, action.payload).pipe(
                map((response) => {
                    return new testimonialAudienceSettingActions.AddSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new testimonialAudienceSettingActions.AddFailure(error));
                }))
        })
    ));

    
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialAudienceSettingActions.Delete>(testimonialAudienceSettingActions.ActionTypes.DELETE),
        switchMap((action: testimonialAudienceSettingActions.Delete) => {
            return this.testimonialAudienceSettingHttpService.deleteAdvocacy(action.companyId, action.audienceId).pipe(
                map(() => {
                    return new testimonialAudienceSettingActions.DeleteSuccess();
                }),
                catchError((error: any) => {
                    return of(new testimonialAudienceSettingActions.DeleteFailure(error));
                }))
        })
    ));
}