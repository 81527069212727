import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from "@angular/common";
import {DomSanitizer} from "@angular/platform-browser";


@Pipe({
    name: 'prettyDate'
})

export class PrettyDatePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(value: any) {

        // Assume DB value as input, which is UTC
        var _value = value + 'Z';
        return convertToNiceDate(_value);
    }
}

function getDiff( start, end ) {
    const datePipe = new DatePipe('en-US');
    var _start = new Date(start);
    var _end =  new Date(end);
    var diff = Math.floor((_end.getTime() - _start.getTime()) / 1000);;
    return diff
}

function getDayDiff( start, end ) {
    const datePipe = new DatePipe('en-US');
    var _start = new Date(start);
    var _end = new Date(end);
    var diff =  Math.floor((_end.getTime() - _start.getTime()) / (1000 * 60 * 60 * 24));
    return diff;
}

function convertToNiceDate( date: string ) {

    var _date = new Date(date);
    var _daydiff = getDayDiff(new Date(_date), Date.now());
    var _diff = getDiff(new Date(_date), Date.now());

    return _daydiff == 0 && (
        _diff < 60 && "just now" ||
        _diff < 120 && "1 minute ago" ||
        _diff < 3600 && Math.floor(_diff / 60) + " minutes ago" ||
        _diff < 7200 && "1 hour ago" ||
        _diff < 86400 && Math.floor(_diff / 3600) + " hours ago") ||
        _daydiff == 1 && "yesterday" ||
        _daydiff < 7 && _daydiff + " days ago" ||
        _daydiff < 14 && "1 week ago" ||
        _daydiff < 28 && Math.ceil(_daydiff / 7) + " weeks ago" ||
        _daydiff < 45 && "1 month ago" ||
        _daydiff < 365 && Math.floor( _daydiff / 30 ) + " months ago" ||
        _daydiff < (365*2) && Math.floor( _daydiff / 365) + " year ago" ||
        _daydiff >= (365*2) && Math.floor( _daydiff / 365) + " years ago";
}