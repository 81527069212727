import { MemoizedSelector, createSelector } from "@ngrx/store";
import { StoryFeatureState, StoryCtaTemplateState } from "../states";
import { selectStoryFeatureState } from "./story-feature.selector";
import { storyCtaTemplateAdapter } from '../adapters/story-cta-template.adapter';
import { StoryCtaTemplateEntity } from '@modules/shared/entities';

export const selectStoryCtaTemplateState: MemoizedSelector<object, StoryCtaTemplateState> = createSelector(selectStoryFeatureState, (state: StoryFeatureState) => state.storyCtaTemplate);

const {
    selectIds: selectStoryCtaTemplateIds,
    selectEntities: selectStoryCtaTemplateEntities,
    selectAll: selectAllStoryCtaTemplates,
    selectTotal: storyCtaTemplatesCount
} = storyCtaTemplateAdapter.getSelectors(selectStoryCtaTemplateState);


  // -------------[Mapping Functions]------------- 
const getLoading = (state: StoryCtaTemplateState): boolean => state.loading;
const getLoaded = (state: StoryCtaTemplateState): boolean => state.loaded;
const getStoryCtaTemplates = (entities: Array<StoryCtaTemplateEntity>) => {
    return entities.map(entity => new StoryCtaTemplateEntity(entity));
}

// -------------[Public Selectors]-------------
export const selectLoaded : MemoizedSelector<object, boolean> = createSelector(selectStoryCtaTemplateState, getLoaded);
export const selectLoading : MemoizedSelector<object, boolean> = createSelector(selectStoryCtaTemplateState, getLoading);
export const selectStoryCtaTemplates : MemoizedSelector<object, Array<StoryCtaTemplateEntity>> = createSelector(selectAllStoryCtaTemplates, getStoryCtaTemplates);
