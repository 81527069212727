
import { userPermissionActions } from '../actions';
import { UserHttpService } from '../../services';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
@Injectable()
export class UserPermissionEffects {
    
    loadCurrentPermissioneffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userPermissionActions.LoadPermissions>(userPermissionActions.ActionTypes.LOAD_PERMISSION),
        switchMap((action: userPermissionActions.LoadPermissions) => {
            return this.userHttpService.getCurrentUserPermission().pipe(
                map((respone) => {
                    return new userPermissionActions.LoadPermissionsSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new userPermissionActions.LoadPermissionsFailure(error));
                }));
        })
    ));



    constructor(
        private actions$: Actions,
        private userHttpService: UserHttpService
    ) { }
}