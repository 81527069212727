import { PaginationModel } from "@framework/models/pagination-models";

export interface LoadDirectoryInputApiModel {
  searchText: string
}

export class LoadDirectoryInputApiModel {

  constructor(public pagination: PaginationModel) {

  }

  private buildQuery() {
    let query = '';

    if (this.searchText) {
      query += `search=${this.searchText}&`;
    }

    query += this.pagination.query;

    return query;
  }

  public get query() {
    return this.buildQuery();
  }
}
