<div class="form-label-group spacing-lg" [ngClass]="{cssClass: cssClass && cssClass.length > 0}">
  <textarea #textArea truncateString [id]="name" [placeholder]="placeholder" [rows]="rows" [(ngModel)]="value" [disabled]="formControl.disabled" [readonly]="readOnly" (ngModelChange)="onChange()" [ngClass]="{'is-invalid': !formControl.valid && formControl.dirty}"
    class="form-control" [(valueTruncate)]="value" (valueTruncateChange)="onChange()" required></textarea>
  <label (click)="labelClick()" [id]="name">{{label}}</label>

  <ng-container *ngIf="formControl.dirty && !formControl.valid && !hideErrorMessage">
    <div *ngIf="formControl.errors.required" class="invalid-feedback">{{name}} is required.</div>
    <div *ngIf="formControl.errors.email" class="invalid-feedback">{{name}} must be valid.</div>
    <div *ngIf="formControl.errors.maxlength" class="invalid-feedback">{{name}} must be less than
      {{formControl.errors.maxlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.minlength" class="invalid-feedback">{{name}} Min
      {{formControl.errors.minlength.requiredLength}} characters.</div>
    <div *ngIf="formControl.errors.isCustom" class="invalid-feedback">{{formControl.errors.message}}</div>
  </ng-container>
</div>
