import { LandingPageState } from '../states';
import { landingPageActions } from '../actions';
import { LandingPageEntity } from '../entities';
import { landingPageAdapter, initialState } from '../adapters/landing-page.adapter';


export function landingPageReducer(state = initialState, action: landingPageActions.Actions): LandingPageState {
    switch (action.type) {

        case landingPageActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case landingPageActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new LandingPageEntity(item));
            let newSate: LandingPageState;
            if (!action.response.prev) {
                newSate = landingPageAdapter.setAll(entities, state);
            } else {
                newSate = landingPageAdapter.addMany(entities, state);
            }
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: action.response.count
            };
        }

        case landingPageActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        case landingPageActions.ActionTypes.LOAD_DETAIL_SUCCESS: {
            const entity = new LandingPageEntity(action.response);
            const newSate = landingPageAdapter.upsertOne(entity, state);
            return {
                ...newSate,
                selectedId: entity.id
            };
        }

        case landingPageActions.ActionTypes.RESET_STATE: {
           
            return {
            ...initialState
            };
        }

        default: {
            return state;
        }
    }
}