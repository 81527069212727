import { Component, Inject, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import { NotificationViewModel } from '@modules/shared/store/models';
import { authActions } from '@modules/auth/store/actions';
import { CampaignViewModel, CompanyViewModel, LandingPageViewModel } from '@modules/company/models/store-view-models';
import { UserProfileViewModel, UserSettingViewModel } from '@modules/user/store/models';
import { userSettingActions, userProfileActions } from '@modules/user/store/actions';
import { userProfileSelectors, userSettingSelectors } from '@modules/user/store/selectors';
import { AuthService, ToastService } from '@framework/services';
import { campaignSelectors, companySelectors, landingPageSelectors } from '@modules/company/store/selectors';
import { StoryViewModel } from '@modules/story/models/view-models';
import { storySelector } from '@modules/story/store/selectors';
import { environment } from '@app/env/environment';
import { isPlatformBrowser } from '@angular/common';
import { Actions, ofType } from '@ngrx/effects';
import { storySettingActions } from '@modules/story/store/actions';


@Component({
  selector: 'app-external-header',
  templateUrl: './external-header.component.html',
  styleUrls: ['./external-header.component.scss']
})
export class ExternalHeaderComponent extends ComponentBase {
  // Private variables
  private selectSelectedCompany$: Observable<CompanyViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectUserSetting$: Observable<UserSettingViewModel>;
  private selectCampaignDetail$: Observable<CampaignViewModel>;
  private selectLandingPageDetail$: Observable<LandingPageViewModel>;
  private selectStoryDetail$: Observable<StoryViewModel>;

  private selectSelectedCompanySubscription: Subscription;
  
  private selectCurrentUserProfileSubscription: Subscription;
  private selectUserSettingSubscription: Subscription;
  private routerChangeSubscription: Subscription;
  private selectCampaignDetailSubscription: Subscription;
  private selectLandingPageDetailSubscription: Subscription;
  private selectStoryDetailSubscription: Subscription;

  // Public variables
  public campaignId: any;
  public spotlightId: any;
  public campaignIsPublic: boolean = false;
  public spotlightIsPublic: boolean = false;
  public userSetting: UserSettingViewModel = new UserSettingViewModel();
  public currentUserProfile: UserProfileViewModel;
  public loadsettingsSuccesscompany:Subscription;
  public login:any;
  public isEmbed:boolean = false
  public register:any;
  public inCompleteCount: number = 0;
  public notifications: NotificationViewModel[] = [];
  public completedAll: boolean = false;
  public currentUrl: string = '';
  public isLoggedIn: Promise<boolean>;
  public selectedCompany: CompanyViewModel = new CompanyViewModel();
  public campaign: CampaignViewModel;
  public featuredPage: LandingPageViewModel;
  public storyDetail: StoryViewModel;
  public redirectParam: string;
  public  isBrowser: boolean;
  public insideIframe: boolean;

  constructor(private activatedRoute: ActivatedRoute, private toastService:ToastService,private actions$: Actions,   @Inject(PLATFORM_ID) platformId: Object, private store$: Store<CoreState>, private router: Router, public authService: AuthService) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    this.loadCurrentUserProfile();

    this.selectSelectedCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectUserSetting$ = this.store$.select(userSettingSelectors.selectUserSetting);
    this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    this.selectLandingPageDetail$ = this.store$.select(landingPageSelectors.selectLandingPage);
    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.currentUrl = this.router.url;
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    this.activatedRoute.queryParams.subscribe((res) => {
      console.log('fromEmbed',res);
      if (res && res.fromEmbed) {
        this.isEmbed = true;
      }
    })
    this.CaptureParams();
    this.isLoggedIn = this.authService.isAuthenticated();
    if(this.isBrowser){
      if (window.location !== window.parent.location) {
        this.insideIframe = true;
        document.getElementsByClassName("site-container")[0]['style'].padding = 0;
      } else {
        this.insideIframe = false;
      }
    }

  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectSelectedCompanySubscription = this.selectSelectedCompany$.subscribe((selectedCompany) => {
      if (selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.setDefaultValues();
      }
    });

    this.loadsettingsSuccesscompany = this.actions$.pipe(
      ofType<storySettingActions.LoadStorySettingSuccess>(storySettingActions.ActionTypes.LOAD_STORY_SETTING_SUCCESS)
    ).subscribe((result) => {
      if(result.data){
        this.register = +result.data['enable_register_button'];
        this.login =result.data['enable_sign_in_button'];
      }
    });
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.countInComplete();
      }
    });

    this.selectLandingPageDetailSubscription = this.selectLandingPageDetail$.subscribe((landingPage) => {
      if (landingPage) {
        this.featuredPage = landingPage;
      }
    });

    this.selectCampaignDetailSubscription = this.selectCampaignDetail$.subscribe((campaign) => {
      if (campaign) {
        this.campaign = campaign;
      }
    });

    this.selectUserSettingSubscription = this.selectUserSetting$.subscribe((userSetting) => {
      this.userSetting = userSetting;
      this.countInComplete();
    });

    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
    });

    this.routerChangeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = this.router.url;
      }
    });
  }

  private unsubscribe() {
    this.selectSelectedCompanySubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.selectUserSettingSubscription.unsubscribe();
    this.routerChangeSubscription.unsubscribe();
    this.selectCampaignDetailSubscription.unsubscribe();
    this.selectLandingPageDetailSubscription.unsubscribe();
    this.selectStoryDetailSubscription.unsubscribe();
  }

  private countInComplete() {
    if (!this.currentUserProfile) { return; }

    this.inCompleteCount = 0;
    if (!this.userSetting.completedFirstStory) {
      this.inCompleteCount += 1;
    }
    if (!this.userSetting.completedInviteMember) {
      this.inCompleteCount += 1;
    }
    if (!this.userSetting.completedSchedule && this.currentUserProfile.isCompanyAdmin) {
      this.inCompleteCount += 1;
    }
    // Check completed
    if (this.currentUserProfile) {
      this.completedAll = this.inCompleteCount === 0;
    }
  }

  private setDefaultValues() {
    this.selectedCompany.background = this.selectedCompany.background ? this.selectedCompany.background : 'https://source.unsplash.com/1900x200/?mountain';
    this.selectedCompany.logo = this.selectedCompany.logo ? this.selectedCompany.logo : 'assets/front-end-minify/img/logo-short.png';
    const idOrSlugParam = this.activatedRoute.snapshot.paramMap.get('idOrSlug');
    if (idOrSlugParam && this.isBrowser) {
      this.redirectParam = encodeURI(btoa(`${environment.baseUrl}/company/${idOrSlugParam}`));
    }
  }


  private CaptureParams(){
    var queryParams = this.activatedRoute.snapshot.queryParams; 
    if(!_.isEmpty(queryParams)){
      var keys = Object.keys(queryParams);
      if(keys && keys.length && keys.includes('campaignId')){
        this.campaignId = queryParams.campaignId;
        this.campaignIsPublic = keys.includes('public') ? true : false
      }
      else if(keys && keys.length && keys.includes('spotlightId')){
        this.spotlightId = queryParams.spotlightId
        this.spotlightIsPublic = keys.includes('public') ? true : false;
      }
      else{
        this.campaignId = null;
        this.spotlightId = null;
      }
    }
    else{
      this.campaignId = null;
        this.spotlightId = null;
    }
  
    }

  queryParamsDeatil(){
    if(this.campaignId && this.campaignIsPublic){
        return {'campaignId':this.campaignId, 'public':1}
    }
    else if(this.spotlightId && this.spotlightIsPublic){
        return {'spotlightId':this.spotlightId, 'public':1}
    }
    else{
      return ''
    }

 }

  private async loadCurrentUserProfile() {
    const isLoggedIn = await this.authService.isAuthenticated();
    if (isLoggedIn) {

      this.store$.dispatch(new userSettingActions.Load());
      this.store$.dispatch(new userProfileActions.LoadCurrentProfile());
    }
  }

  removeUrl(url){
    if( url && (url.includes('ik.imagekit.io') || url.includes('images.goodseeker.com'))){
      return url.split("?")[0]
    }
    else{
      return url
    }
  
   }

   public copyExternalLink(){
    if(this.isBrowser){
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = window.location.href.toString()
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastService.success("Copied");
    }

   }

  // Public functions
  public logout(e) {
    e.preventDefault();
    this.store$.dispatch(new authActions.SignOut());
  }

}
