import { Action } from '@ngrx/store';

import { ContributionOutputApiModel } from '@modules/story/models/api-models/output';
import { CreateContributionApiModel } from '@modules/story/models/api-models/input';
import { StoryStepViewModel } from '@modules/story/models/view-models';

export enum ActionTypes {
  LOAD_EDIT = "[Contribution] Load Edit",
  LOAD_EDIT_SUCCESS = "[Contribution] Load Edit Success",
  LOAD_EDIT_FAILURE = "[Contribution] Load Edit Failure",

  CREATE = "[Contribution] Create",
  CREATE_SUCCESS = "[Contribution] Create Success",
  CREATE_FAILURE = "[Contribution] Create Failure",

  UPDATE = "[Contribution] Update",
  UPDATE_SUCCESS = "[Contribution] Update Success",
  UPDATE_FAILURE = "[Contribution] Update Failure",

  UPDATE_BUILDER = "[Contribution] Update Builder",

  SET_ACTIVE_STEP = "[Contribution] Set Active Step",
  SET_STEP_COMPLETED = "[Contribution] Set Step Completed",

  RESET_SELECTED_ID = "[Contribution] Reset Selected ID",

  DELETE = "[Contribution] Delete",
  DELETE_SUCCESS = "[Contribution] Delete Success",
  DELETE_FAILURE = "[Contribution] Delete Failure",
}

export class LoadEdit implements Action {
  readonly type = ActionTypes.LOAD_EDIT;
  constructor(public id: number) { };
}

export class LoadEditSuccess implements Action {
  readonly type = ActionTypes.LOAD_EDIT_SUCCESS;
  constructor(public response: ContributionOutputApiModel) { }
}

export class LoadEditFailure implements Action {
  readonly type = ActionTypes.LOAD_EDIT_FAILURE;
  constructor(public error: any) { }
}

export class Create implements Action {
  readonly type = ActionTypes.CREATE;
  constructor(public storyId: number, public payload: CreateContributionApiModel) { }
}

export class CreateSuccess implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
  constructor(public response: ContributionOutputApiModel) { }
}

export class CreateFailure implements Action {
  readonly type = ActionTypes.CREATE_FAILURE
  constructor(public error: any) { }
}

export class Update implements Action {
  readonly type = ActionTypes.UPDATE;
  constructor(public storyId: number, public payload: CreateContributionApiModel) { }
}

export class UpdateSuccess implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
  constructor(public response: ContributionOutputApiModel) { }
}

export class UpdateFailure implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE
  constructor(public error: any) { }
}

export class Delete implements Action {
  readonly type = ActionTypes.DELETE;
  constructor(public contributionId: number) { }
}

export class DeleteSuccess implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
}

export class DeleteFailure implements Action {
  readonly type = ActionTypes.DELETE_FAILURE
}

export class UpdateBuilder implements Action {
  readonly type = ActionTypes.UPDATE_BUILDER
  constructor(public data: Partial<CreateContributionApiModel>) { }
}

export class SetActiveStep implements Action {
  readonly type = ActionTypes.SET_ACTIVE_STEP
  constructor(public step: StoryStepViewModel) { }
}

export class SetStepCompleted implements Action {
  readonly type = ActionTypes.SET_STEP_COMPLETED
  constructor(public stepId: number, public isCompleted: boolean) { }
}

export class ResetSelectedId implements Action {
  readonly type = ActionTypes.RESET_SELECTED_ID
}

export type Actions =
  | LoadEdit | LoadEditSuccess | LoadEditFailure
  | Create | CreateSuccess | CreateFailure
  | Update | UpdateSuccess | UpdateFailure
  | UpdateBuilder | SetActiveStep | SetStepCompleted | ResetSelectedId;