import { initialState, followAdapter } from '../adapters/follow.adapter';
import { FollowState } from '../states';
import { followActions } from '../actions';


export function followReducer(state = initialState, action: followActions.Actions): FollowState {
    switch (action.type) {

        default: {
            return state;
        }
    }
}
