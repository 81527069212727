import { EngagementReportOutputApiModel } from "../models/api-models/output";

export interface EngagementReportEntity {
    commentsGiven: number;
    dateJoined: string;
    displayName: string;
    lastLogin: string;
    lastPublished: string;
    likesGiven: number;
    pointsEarned: number;
    sharesMade: number;
    storiesAsAuthor: number;
    storiesAsPlayer: number;
    totalNamed: number;
    totalViews: number;
    uniqueNamed: number;
    uniqueViews: number;
    userDays: number;
}

export class EngagementReportEntity {
    constructor(data?: EngagementReportOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: EngagementReportOutputApiModel) {
        this.commentsGiven = data.comments_given;
        this.dateJoined = data.date_joined;
        this.displayName = data.display_name;
        this.lastLogin = data.last_login;
        this.lastPublished = data.last_published;
        this.likesGiven = data.likes_given;
        this.pointsEarned = data.points_earned;
        this.sharesMade = data.shares_made;
        this.storiesAsAuthor = data.stories_as_author;
        this.storiesAsPlayer = data.stories_as_player;
        this.totalNamed = data.total_named;
        this.totalViews = data.total_views;
        this.uniqueNamed = data.unique_named;
        this.uniqueViews = data.unique_views;
        this.userDays = data.user_days;
    }

}
