import { Action } from '@ngrx/store';

import {
    SignInModel,
    SignUpModel,
    ForgotPasswordSubmitModel,
    ChangePasswordModel,
    UserAttributeModel
} from '@framework/cloud-authentication/cognito/models';

export enum ActionTypes {
    SIGNIN = '[Auth] Sign In',
    SIGNIN_SUCCESS = '[Auth] Sign In Success',
    SIGNIN_FAILURE = '[Auth] Sign In Failure',

    SSO = '[Auth] SSO',
    SSO_SUCCESS = '[Auth] SSO Success',
    SSO_FAILURE = '[Auth] SSO Failure',

    SIGNUP = '[Auth] Sign Up',
    SIGNUP_SUCCESS = '[Auth] Sign Up Success',
    SIGNUP_FAILURE = '[Auth] Sign Up Failure',

    VERIFY_ACCOUNT = '[Auth] Verify Account',
    VERIFY_ACCOUNT_SUCCESS = '[Auth] Verify Account Success',
    VERIFY_ACCOUNT_FAILURE = '[Auth] Verify Account Failure',

    RESEND_VERIFY_CODE = '[Auth] Resend Verify Code',
    RESEND_VERIFY_CODE_SUCCESS = '[Auth] Resend Verify Code Success',
    RESEND_VERIFY_CODE_FAILURE = '[Auth] Resend Verify Code Failure',

    SIGNOUT = '[Auth] Sign Out',

    FORGOT_PASSWORD = '[Auth] Forgot Password',
    FORGOT_PASSWORD_SUCCESS = '[Auth] Forgot Password Success',
    FORGOT_PASSWORD_FAILURE = '[Auth] Forgot Password Failure',

    FORGOT_PASSWORD_SUBMIT = '[Auth] Forgot Password Submit',
    FORGOT_PASSWORD_SUBMIT_SUCCESS = '[Auth] Forgot Password Submit Success',
    FORGOT_PASSWORD_SUBMIT_FAILURE = '[Auth] Forgot Password Submit Failure',

    CHANGE_PASSWORD = '[Auth] Change Password',
    CHANGE_PASSWORD_SUCCESS = '[Auth] Change Password Success',
    CHANGE_PASSWORD_FAILURE = '[Auth] Change Password Failure',

    UPDATE_ATTRIBUTE = '[Auth] Update Attribute',
    UPDATE_ATTRIBUTE_SUCCESS = '[Auth] Update Attribute Success',
    UPDATE_ATTRIBUTE_FAILURE = '[Auth] Update Attribute Failure',

    GOTO_SIGNIN = '[Auth] Goto Signin',
    SET_REDIRECT_URL = '[Auth] Set Redirect URL',

    SET_GS_TOKEN = '[Auth] Set Gs Token'
}


export class SignIn implements Action {
    readonly type = ActionTypes.SIGNIN;

    constructor(public data: SignInModel) { }
}

export class SignInSuccess implements Action {
    readonly type = ActionTypes.SIGNIN_SUCCESS;

    constructor() { }
}

export class SignInFailure implements Action {
    readonly type = ActionTypes.SIGNIN_FAILURE;

    constructor(public error: any) { }
}

export class SSO implements Action {
    readonly type = ActionTypes.SSO;

    constructor(public data: SignInModel) { }
}

export class SSOSuccess implements Action {
    readonly type = ActionTypes.SSO_SUCCESS;

    constructor() { }
}

export class SSOFailure implements Action {
    readonly type = ActionTypes.SSO_FAILURE;

    constructor(public error: any) { }
}

export class SignUp implements Action {
    readonly type = ActionTypes.SIGNUP;

    constructor(public data: SignUpModel) { }
}

export class SignUpSuccess implements Action {
    readonly type = ActionTypes.SIGNUP_SUCCESS;

    constructor() { }
}

export class SignUpFailure implements Action {
    readonly type = ActionTypes.SIGNUP_FAILURE;

    constructor(public error: any) { }
}

export class VerifyAccount implements Action {
    readonly type = ActionTypes.VERIFY_ACCOUNT;

    constructor(public username: string, public code: string) { }
}

export class VerifyAccountSuccess implements Action {
    readonly type = ActionTypes.VERIFY_ACCOUNT_SUCCESS;

    constructor() { }
}

export class VerifyAccountFailure implements Action {
    readonly type = ActionTypes.VERIFY_ACCOUNT_FAILURE;

    constructor(public error: any) { }
}

export class ResendVerifyCode implements Action {
    readonly type = ActionTypes.RESEND_VERIFY_CODE;

    constructor(public username: string) { }
}

export class ResendVerifyCodeSuccess implements Action {
    readonly type = ActionTypes.RESEND_VERIFY_CODE_SUCCESS;

    constructor() { }
}

export class ResendVerifyCodeFailure implements Action {
    readonly type = ActionTypes.RESEND_VERIFY_CODE_FAILURE;

    constructor(public error: any) { }
}

export class SignOut implements Action {
    readonly type = ActionTypes.SIGNOUT;
}

export class ForgotPassword implements Action {
    readonly type = ActionTypes.FORGOT_PASSWORD;

    constructor(public username: string) { }
}

export class ForgotPasswordSuccess implements Action {
    readonly type = ActionTypes.FORGOT_PASSWORD_SUCCESS;

    constructor() { }
}

export class ForgotPasswordFailure implements Action {
    readonly type = ActionTypes.FORGOT_PASSWORD_FAILURE;

    constructor(public error: any) { }
}

export class ForgotPasswordSubmit implements Action {
    readonly type = ActionTypes.FORGOT_PASSWORD_SUBMIT;

    constructor(public data: ForgotPasswordSubmitModel) { }
}

export class ForgotPasswordSubmitSuccess implements Action {
    readonly type = ActionTypes.FORGOT_PASSWORD_SUBMIT_SUCCESS;

    constructor() { }
}

export class ForgotPasswordSubmitFailure implements Action {
    readonly type = ActionTypes.FORGOT_PASSWORD_SUBMIT_FAILURE;

    constructor(public error: any) { }
}

export class ChangePassword implements Action {
    readonly type = ActionTypes.CHANGE_PASSWORD;

    constructor(public data: ChangePasswordModel) { }
}

export class ChangePasswordSuccess implements Action {
    readonly type = ActionTypes.CHANGE_PASSWORD_SUCCESS;

    constructor() { }
}

export class ChangePasswordFailure implements Action {
    readonly type = ActionTypes.CHANGE_PASSWORD_FAILURE;

    constructor(public error: any) { }
}

export class UpdateAttribute implements Action {
    readonly type = ActionTypes.UPDATE_ATTRIBUTE;

    constructor(public data: UserAttributeModel) { }
}

export class UpdateAttributeSuccess implements Action {
    readonly type = ActionTypes.UPDATE_ATTRIBUTE_SUCCESS;

    constructor() { }
}

export class UpdateAttributeFailure implements Action {
    readonly type = ActionTypes.UPDATE_ATTRIBUTE_FAILURE;

    constructor(public error: any) { }
}

export class GotoSignIn implements Action {
    readonly type = ActionTypes.GOTO_SIGNIN;
}

export class SetRedirectUrl implements Action {
    readonly type = ActionTypes.SET_REDIRECT_URL;

    constructor(public url: string) { }
}

export class SetGsToken implements Action {
    readonly type = ActionTypes.SET_GS_TOKEN;

    constructor(public base64Token: string, public rawToken: string) { }
}

export type Actions
    = SignIn
    | SignInSuccess
    | SignInFailure

    | SignUp
    | SignUpSuccess
    | SignUpFailure

    | SignOut

    | ForgotPassword
    | ForgotPasswordSuccess
    | ForgotPasswordFailure

    | ForgotPasswordSubmit
    | ForgotPasswordSubmitSuccess
    | ForgotPasswordSubmitFailure

    | ChangePassword
    | ChangePasswordSuccess
    | ChangePasswordFailure

    | SSO
    | SSOSuccess
    | SSOFailure

    | UpdateAttribute
    | UpdateAttributeSuccess
    | UpdateAttributeFailure

    | GotoSignIn
    | SetRedirectUrl;
