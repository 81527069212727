<div class="modal-header">
  <h5 class="modal-title">Send Thank You?</h5>
  <button type="button" (click)="close()" class="close"><span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span></button>
</div>

<div class="modal-body" [formGroup]="emailConfigForm">

  <div class="row ml-2 mb-4">
    <span class="send__button">Send To</span> 
     <div class="col-3 ml-3">
         <div class="custom-control custom-radio">
             <label class="mb-0">
                 <input type="radio"  formControlName="sendTo" value="1"
                     class="custom-control-input">
                 <span class="custom-control-label">Author</span>
             </label>
         </div>
     </div>
     <div class="col-3 ml-3">
         <div class="custom-control custom-radio">
             <label class="mb-0">
                 <input type="radio"  formControlName="sendTo" value="3"
                     class="custom-control-input">
                 <span class="custom-control-label">Named</span>
             </label>
         </div>
     </div>
     <div class="col-3 ml-3">
         <div class="custom-control custom-radio">
             <label class="mb-0">
                 <input type="radio"  formControlName="sendTo" value="2"
                     class="custom-control-input">
                 <span class="custom-control-label">Everyone</span>
             </label>
         </div>
     </div>
 </div>
  <div class="row">
    <div class="col-12">
      <gs-input-material label="Subject" name="subject" placeholder="Subject" formControlName="subject" [formControl]="emailConfigForm.controls.subject" type="text"></gs-input-material>
      <gs-area-wysiwyg label="body" name="body" rows="10" placeholder="body" formControlName="body" [formControl]="emailConfigForm.controls.body" type="text"></gs-area-wysiwyg>
    </div>
    <div class="ml-3">
      <!-- <label class="switch mr-2">
        
        <input formControlName="enable_advocacy_link" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text text-gray">Add Advocacy</span> -->
      <div class="custom-control custom-checkbox" style="font-weight: 700;">
        <input id="includeStories" type="checkbox" formControlName="enable_advocacy_link"
            class="custom-control-input">
        <label for="includeStories" class="custom-control-label">Add Links to Share</label>
        <a [routerLink]="['/company/settings/advocacy']" class="nav-link">Update link options</a>
    </div>
    </div>
    <div class="ml-3 mt-1" *ngIf="emailConfigForm.value.enable_advocacy_link">
      <label class="switch mr-2">
        
        <input formControlName="internal_advocacy" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text text-gray">Internal</span>
    </div>
    <div class="ml-3 mt-1" *ngIf="emailConfigForm.value.enable_advocacy_link">
      <label class="switch mr-2">
        
        <input formControlName="public_advocacy" type="checkbox"><span class="slider"></span>
      </label>
      <span class="text text-gray">Public</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button (click)="save()" type="button" class="btn btn-pink min-width"> Send
  </button>

  <button (click)="close()" type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width">Don't Send</button>
</div>