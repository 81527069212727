<div *ngIf="profile" [background-image]="profile.header? profile.header : defaultHeaderImage"
  class="b-timeline">
  <div class="container userprofwrapper">
    <div class="row">
      <div class="col-12 mt-5">
        <div class="m-ld-info userprofhead">
          <div *ngIf="profile.avatar" class="m-ld-info__logo text-center">
            <img class="avatar avatar--xl" [src]="profile.avatar" />
            <p *ngIf="profile.displayName" class="mb-0 mt-1 p-0 pt-1">{{profile.displayName}}</p>
            <p *ngIf="profile.title" class="mb-0 p-0">{{profile.title}}</p>
          </div>
          <div class="m-ld-info__content userprofquote">
            <h4 *ngIf="profile.workPurpose">{{profile.workPurpose}}</h4>
            <div class="profile-account__link mt-3"
              *ngIf="profile.profileFacebook || profile.profileLinkedin || profile.profileTwitter">
              <a *ngIf="profile.profileFacebook" target="_bkank" [href]="profile.profileFacebook">
                <span class="icon-social icon-facebook"><i class="fab fa-facebook-f"></i></span>
              </a>
              <a *ngIf="profile.profileLinkedin" target="_bkank" [href]="profile.profileLinkedin">
                <span class="icon-social icon-linkedin"><i class="fab fa-linkedin"></i></span>
              </a>
              <a *ngIf="profile.profileTwitter" target="_bkank" [href]="profile.profileTwitter">
                <span class="icon-social icon-twitter"><i class="fab fa-twitter"></i></span>
              </a>
            </div>
          </div>
          <!--<div *ngIf="profile.avatar" class="d-none d-lg-block m-ld-info__logo text-center">
            <img class="avatar avatar--xl"
              [src]="profile.badge?.image?.length > 0 ? profile.badge.image : bageDefaultIcon" /><br />
            <span class="pt-2">{{profile.badge?.text || 'Good Getter'}}</span>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>