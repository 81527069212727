export class PaginationDjangoRestModel{
    public count: number;
    public next: string;
    public prev: string;
    public result: Array<any>;
    
    constructor(init?: Partial<PaginationDjangoRestModel>){
        Object.assign(this, init);
        this.init();
    }

    private init(){        
    }
}