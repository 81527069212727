import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '@framework/base';
import { CoreState } from '@app/store/core/states';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { companySelectors } from '@modules/company/store/selectors';
import { PolicyActons } from '@modules/company-admin/store/actions';

@Component({
  selector: 'app-policy',
  templateUrl: './open-policy.page.html',
  styleUrls: ['./open-policy.page.scss'],
})
export class OpenPolicyPage extends Page {
  private selectCompany$: Observable<CompanyViewModel>;
  private loadPolicySucess: Subscription;
  public template:any;
  public policy_name:any;
  private selectCompanySubscription: Subscription;

  constructor(
    private store$: Store<CoreState>,
    private actions$: Actions,
    private router: Router,
    private activateRoute: ActivatedRoute
  ) {

    super();
    this.captureParams();
    // this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany)
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {
    this.loadPolicySucess = this.actions$.pipe(
      ofType<PolicyActons.LoadSuccess>(PolicyActons.ActionTypes.LOAD_SUCCESS)
    ).subscribe((policy: any) => {

      if (policy && policy.response) {
        this.policy_name =  policy.response.data.policy_name
        this.template  = policy.response.data.policy_template
      }
    })


  }

  captureParams(){
    const companyId = this.activateRoute.snapshot.params.companyId;
    this.store$.dispatch(new PolicyActons.Load(companyId))
  }

 




  private unsubscribe() {
    this.loadPolicySucess.unsubscribe();
  }
}
