import { UserEmailEntity } from '@modules/user/store/entities';

export interface UserEmailViewModel {
  id: number;
  email: string;
  status: string;
}

export class UserEmailViewModel {
  constructor(data?: Partial<UserEmailEntity>) {
    if (data) {
      this.convertFromStore(data);
    }
  }

  private convertFromStore(data: Partial<UserEmailEntity>) {
    Object.assign(this, data);
  }
}
