import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService } from '@framework/services';
import { ofType, Actions } from '@ngrx/effects';
import { storyActions } from '@modules/story/store/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { AuthService } from '@framework/services';
import * as adminStoryActions from '@modules/company-admin/store/actions';

@Component({
  selector: 'copy-link-modal-container',
  templateUrl: './copy-link-modal-container.component.html',
  styleUrls: ['./copy-link-modal-container.component.scss'],
})

export class CopyLinkContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public copyForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public activeTab: string;
  public fromTrcking: boolean = false;
  public currentUser:any= false;
  private AddtrackingsuccessSubscription: Subscription;
  private rssToken: string;
  public isShow:boolean = false;
  public disabled:boolean = false
  private loadColorSuccessSubscription: Subscription;
  public primaryColor:any;
  public accentColor:any; 
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private actions$: Actions,
    private store$: Store<CoreState>,
    private toastService: ToastService,
    private authService: AuthService,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })
    
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
   
    if(this.initialState?.companyId)
    this.store$.dispatch(new adminStoryActions.storyActions.GetColor(this.initialState?.companyId));

    // this.copyForm = this.formBuilder.group({
    //   height: [900, Validators.required],
    //   width: [100, Validators.required],
    //   widthList:['px'],
    //   heightList:['px'],
    //   bodyCode: [{ value: '', disabled: true }],
    //   selectCheckbox: ['public', Validators.required],
    //   selectRadio: [false, Validators.required]
    // })

     this.currentUser = this.authService.cognito.currentUser();
    this.copyForm = this.formBuilder.group({
      message:['',[Validators.required,Validators.maxLength(200)]],
      addPersonalize:[false],
      link:[{value: '',disabled: true}],
      firstname:[''],
      lastname:[''],

    })
    this.AddtrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.AddPersonalizeTrackHitSuccess>(storyActions.ActionTypes.ADD_PERSONALIZE_TRACK_SUCCESS)
    ).subscribe((action: any) => {
      if(action  && action.data && action.data.unique_token && this.fromTrcking ){
        this.isShow = true;
        this.fromTrcking = false;
        // c/:companySlug/:idOrSlug/:storytoken/:tracking/unique/people
        if(!this.initialState.isinternal){
          var Url = `${window.location.origin}/c/${this.initialState.companySlug}/${this.initialState.slug}/${action.data.unique_token}/${this.initialState.trackingid}/unique/people`
          this.copyForm.controls.link.setValue(Url);
        }
        else{
          var Url = `${this.initialState.storyUrl}/${action.data.unique_token}`
          this.copyForm.controls.link.setValue(Url);
        }

      }
         

    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primaryColor = action.response.find(item => item.button == 11).color;
        // this.accentColor = action.response.find(item => item.button == 13).color;
       }
      

    })

  }

  addTracking(){

  }

  publicStory(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.storyUrl.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  publicVideo(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.videoUrl.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  internalStory(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.storyUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  customizeInternalLink(Url){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyForm.controls.link.value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

internalVideo(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.initialState.videoUrl.toString();
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  CopyLink(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyForm.controls.link.value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.success("Copied");
  }

  genrarateLink(){
    this.fromTrcking = true;
    var tarcking = []
    let trackobj ={
      "story_id": this.initialState.storyId,
      "message": this.copyForm.value.message,
      "name": this.copyForm.value.name,
      "company_Id": this.initialState.companyId,
      "firstname": this.copyForm.value.firstname,
      "lastname": this.copyForm.value.lastname,
      "resource_type":"story"
     }
     this.disabled = true;
    
     this.store$.dispatch(new storyActions.AddPersonalizeTrackHit(trackobj));
  }

  close(){
    this.onClose.emit(true)
  }

}
