import { AddUserOutputApiModel } from '../models/api-models/output';

export interface UserIdEntity {
  id_user: Number
}

export interface ResultEntity {
  user_details: UserIdEntity
}

export interface AddUserEntity {
  result: ResultEntity
}

export class AddUserEntity {
    constructor(data?: AddUserOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: AddUserOutputApiModel) {
        if (data.result) {
          this.result = data.result;
        }
    }

}
