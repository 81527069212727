import { TagOutputApiModel } from "./tag-output.api-model";

export interface StepOutputApiModel {
    id: number,
    sort: number,
    title: string,
    text: string,
    type: string,
    color: string,
    tags: Array<TagOutputApiModel>,
}

export class StepOutputApiModel {
    constructor() {

    }
}