<div>
  <co-pu-banner-skeleton [hidden]="currentCompany.background"></co-pu-banner-skeleton>
  <div class="b-timeline" [ngClass]="{'add-overlay': currentCompany.enable_overlay}" [hidden]="!currentCompany.background" [background-image]="currentCompany.background">
    <div class="container">
      <div class="container">
        <div class="d-block  d-md-inline-flex btn-group mr-5">
          <a type="button" *ngFor="let button of getButtons()" [style.background-color]="button.background"
            [style.color]="button.foreground" type="button" class="btn mobile-view btn-sq btn-white"
            href="{{button.url}}" target="_blank">
            {{button.label}}
          </a>
          <ng-container *ngIf="!insideIframe">
            <a *ngIf="currentCompany.isInboundStoriesEnabled"
            [style.background]="btnColor"
            [style.color]="textColor"
            [routerLink]="['/t/' + currentCompany.slug + '/testimonial/create']" class="btn btn-sq btn-pink"
            type="button">Submit Story</a>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</div>