import { Component, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreState } from '@app/store/core/states';
import { environment } from '@app/env/environment';
import { UtilityHelper } from '@framework/utilities';
import { Page } from '@framework/base';
import * as adminStoryActions from '@modules/company-admin/store/actions';
import { landingPageActions, companyActions, searchBoxActions } from '@modules/company/store/actions';
import { companySelectors, landingPageSelectors, searchBoxSelectors } from '@modules/company/store/selectors';
import { CompanyViewModel, LandingPageViewModel, SearchBoxStateViewModel } from '@modules/company/models/store-view-models';
import { storyActions } from '@modules/story/store/actions';
import { SeoService } from '@modules/shared/services/seo.service';
import { MetaTagConfig } from "@modules/shared/models/common/seo.model";
import { storySelector } from '@modules/story/store/selectors';
import { StoryFacetedSearchViewModel } from '@modules/story/models/store-view-models';
import { Constants } from '@modules/shared/constants';
import { CtaFormConfigViewModel } from '@modules/story/models/view-models';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchStoryInputApiModel, SubmitCtaInputApiModel } from '@modules/story/models/api-models/input';
import { JobLinkacAction, nudgesSettingActions, ScriptActions } from '@modules/company-admin/store/actions';
import { isPlatformBrowser } from '@angular/common';
import { CompanyHttpService } from '@modules/company/services/company.http-service';

declare const gtag: Function;
declare var dataLayer;
declare var window:any;
declare var $: any;

let measuremnetId;
let pagename;
let globalTag;
@Component({
  selector: 'app-public-featured-page',
  templateUrl: './public-featured.page.html',
  styleUrls: ['./public-featured.page.scss']
})
export class LandingPage extends Page {
  // Private variables
  private selectCompany$: Observable<CompanyViewModel>;
  private selectLandingPageDetail$: Observable<LandingPageViewModel>;
  private baseUrl: string = environment.baseUrl;

  private selectCompanySubscription: Subscription;
  private selectLandingPageDetailSubscription: Subscription;
  private submitCtaSuccessSubscription: Subscription;
  public primary_cmarr:any;
  private LoadSuccessSubscriptin: Subscription;
  private loadFrequentTagsSuccess: Subscription;
  public showbbuttontext: any;
  public loadColorSuccessSubscription: Subscription;

  private landingPageSlug: string = '';
  public ctaForm: UntypedFormGroup;
  private selectSearchBoxStatus$: Observable<SearchBoxStateViewModel>;
  private queryStoriesData: SearchStoryInputApiModel = new SearchStoryInputApiModel();
  // Public variables
  public showJobslist:any[] = [];
  public selectedCompany: CompanyViewModel;
  public landingPage: LandingPageViewModel;
  public isIframeMode: boolean = false;
  public frequenttags: any;
  public showtagsboolean: boolean = true;
  public showHeaderboolean: boolean = true;
  public showDonor:boolean = false;
  public isShowNudgeButton: boolean;
  public ctaStepActivated: number = 0;
  public interestSelected: any;
  public weAppreciate: number = 8;
  public weValue: number = 14;
  public isShowNudge: boolean;
  public ourVibes: number = 13;
  public ourteams: number = 2;
  public ourChallenges: number = 11;
  counter = new Array(3);
  public searchBoxStatus: SearchBoxStateViewModel;
  public selectFacetedSearchResultSubscription: Subscription;
  public selectSearchBoxStatusSubscription: Subscription;
  private selectFacetedSearchResult$: Observable<StoryFacetedSearchViewModel>;
  public loadDetailFromAPISubcription: Subscription;
  public loadNudgesettingsSubscription: Subscription;
  public isNudgeBasic: boolean = false;
  public isNudgeComplex: boolean = false;
  public isNudgeDonation: boolean = false;
  public isShowToggleActive: boolean = false;
  public  isBrowser: boolean;
  public companySlug:string;
  public isBottom: boolean = false;
  public isFirst: boolean = true;
  public text_color:any;
  public button_color: any;
  public view_type:any;
  public showFooter: boolean = true;
  private loadJlinkobsSubscription: Subscription;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, 
    private store$: Store<CoreState>,
    private utility: UtilityHelper,
    private actions$: Actions,
    @Inject(PLATFORM_ID) platformId: Object,
    private companyHttpService: CompanyHttpService,
    private seoService: SeoService,private formBuilder: UntypedFormBuilder) {
    super();
    this.isBrowser = isPlatformBrowser(platformId);
    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectLandingPageDetail$ = this.store$.select(landingPageSelectors.selectLandingPage);
    this.selectFacetedSearchResult$ = this.store$.select(storySelector.selectFacetedSearchResults);
    this.selectSearchBoxStatus$ = this.store$.select(searchBoxSelectors.selectSearchBoxStatus);


    this.captureParams();
  }

  // Life cycle hook
  protected onInit(): void {
    if(this.isBrowser){
      this.updateTagsForSSR();
      this.subscribe();
      this.ctaForm = this.formBuilder.group({
        interested: ['', this.step1Require.bind(this)],
        email: ['', Validators.compose([Validators.email, this.step2Require.bind(this)])],
        fullName: ['', this.step2Require.bind(this)]
      });
      if (window.location !== window.parent.location) {
        // this.insideIframe = true;
        var storedata = window.location.href.split("?");
        localStorage.setItem('iframehref',window.location.href)
        if(storedata.length == 2){
          if(storedata[1].split('&').includes('showHeader=true')){
            this.showHeaderboolean = true;
          }
          else{
            this.showHeaderboolean = false;
          }
  
          if(storedata[1].split('&').includes('showtagheader=true')){
            this.showtagsboolean = true;
          }
          else{
            this.showtagsboolean = false;
          }
          if(storedata[1].split('&').includes('showfooter=true')){
            this.showFooter = true;
          }
          else{
            this.showFooter = false;
          }
        }
        else{
          this.showHeaderboolean = false;
          this.showtagsboolean = false;
          this.showFooter = true;
        }
        //document.getElementsByClassName("site-container")[0]['style'].padding = 0;
      } else {
        this.showHeaderboolean = true;
        this.showtagsboolean = true;
        this.showFooter = true;
      }
    }
    else{
       this.updateTagsForSSR();
    }

  }
  protected onDestroy(): void {
    if(this.isBrowser){
      this.unsubscribe();
      this.resetStates();
      this.removeScripts();
      this.closeDonor();
    }

    this.seoService.clearMetaTags();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    
  }
  protected onAfterViewChecked(): void {
    if (this.isIframeMode) {
      this.sendPostMessage();
    }
  }

  // Private functions
  private addMetaTags(): void {
    const config: MetaTagConfig = new MetaTagConfig();
    config.title = this.landingPage.title;
    config.url = `${environment.baseUrl}${this.router.url}`;
    config.image = this.landingPage.headerImage;
    config.description = this.landingPage.slogan;
    this.seoService.updateMetaTags(config);
  }

  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.selectedCompany = company;
        this.store$.dispatch(new nudgesSettingActions.LoadNudgeSettings(this.selectedCompany.id));
        pagename = this.selectedCompany.name+'PUBLIC_SPOTLIGHT_PAGE'
        this.loadScripts();
        this.loadDetail();
      }
    });

    this.LoadSuccessSubscriptin = this.actions$.pipe(
      ofType<ScriptActions.LoadSuccess>(ScriptActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((action:any) => {
         
      if(action && action.response.results && action.response.results.length){
        var scripts = action.response.results.filter(script => script.is_accessible);
        if(this.isBrowser){
          this.appendScriptinProduct(scripts);
        }

  
      }
    });
    this.loadJlinkobsSubscription = this.actions$.pipe(
      ofType<JobLinkacAction.GetSpotlightJobsSuccess>(JobLinkacAction.ActionTypes.GET_SPOTLIGHT_JOBS_SUCCESS)
    ).subscribe((action:any) => {
      if(action.response){
        this.showJobslist = action.response?.filter(job => job.is_accessible);
 
      }
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<adminStoryActions.storyActions.GetColorSuccess>(adminStoryActions.storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.primary_cmarr= action.response.find(item => item.button == 11).color;
        // this.backgroundImageColor = action.response.find(item => item.button == 14).color;
       }
      

    })

    this.loadDetailFromAPISubcription = this.actions$.pipe(
      ofType<landingPageActions.LoadDetailSuccess>(landingPageActions.ActionTypes.LOAD_DETAIL_SUCCESS)
    ).subscribe((action) => {
      if(action.response){
         this.getfrequentTags();
      }
    });

    this.loadNudgesettingsSubscription = this.actions$.pipe(
      ofType<nudgesSettingActions.LoadNudgeSettingsSuccess>(nudgesSettingActions.ActionTypes.LOAD_NUDGE_SETTING_SUCCESS)
    ).subscribe((action:any) => {
       if(action && action.response){
         this.text_color = action.response.text_color;
         this.button_color = action.response.button_color;
         this.view_type = action.response.view_type;
       }
    });

    this.submitCtaSuccessSubscription = this.actions$.pipe(
      ofType<landingPageActions.SubmitCtaSuccess>(landingPageActions.ActionTypes.SUBMIT_CTA_SUCCESS)
    ).subscribe(() => {
      this.ctaStepActivated = 3;
    });
    this.selectLandingPageDetailSubscription = this.selectLandingPageDetail$.subscribe((landingPage) => {
      if (landingPage) {
        this.landingPage = landingPage;
        this.loadStorylinkedJobs();
        if(this.landingPage && this.landingPage.cta && this.landingPage.cta.config){
          let data = JSON.parse(this.landingPage.cta.config)
          if(data.button_text == '' || data.button_text == null){
            this.showbbuttontext = this.landingPage.cta.button;
          }
          else{
            this.showbbuttontext = data.button_text;
          }
        }
        else{
          this.showbbuttontext = this.landingPage?.cta?.button
        }
        this.checkPermission();
        this.addMetaTags();
      }
    });

    // this.selectFacetedSearchResultSubscription = this.selectFacetedSearchResult$.subscribe((result: StoryFacetedSearchViewModel) => {
    //   if (result) {
    //     this.frequenttags = result.frequent_tags
    //   }
    // });
    this.loadFrequentTagsSuccess = this.actions$.pipe(
      ofType<storyActions.LoadSpotlightFrequentTagsSuccess>(storyActions.ActionTypes.LOAD_SPOTLIGHT_FREQUENT_TAGS_SUCCESS)
    ).subscribe((action: any) => {
      if(action){
        this.frequenttags = action.data?.frequent_tags;
      }
    });
    this.selectSearchBoxStatusSubscription = this.selectSearchBoxStatus$.subscribe((state: any) => {
      this.searchBoxStatus = state;
    });

  }

  public removeScripts(){
    $("#pvt__scripts").empty();
   }

  

  private unsubscribe() {
    this.selectCompanySubscription.unsubscribe();
    this.selectLandingPageDetailSubscription.unsubscribe();
    this.submitCtaSuccessSubscription.unsubscribe();
    this.loadDetailFromAPISubcription.unsubscribe();
    this.loadFrequentTagsSuccess.unsubscribe();
    this.LoadSuccessSubscriptin.unsubscribe();
    this.loadNudgesettingsSubscription.unsubscribe();
    this.store$.dispatch(new landingPageActions.Reset())
  }

  private loadScripts() {
    this.store$.dispatch(new ScriptActions.Load(this.selectedCompany.id));
  }



  get isMobile() {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }


  public appendScriptinProduct(script){

    let googleAnalyticsScript = script.find(scripttype => scripttype.type == 'GoogleAnalytics');

    if(googleAnalyticsScript && $("#pvt__scripts").html() === ""){
      this.googleAnalyticsScripts(googleAnalyticsScript.measurement_id)
      measuremnetId = googleAnalyticsScript.measurement_id
      setTimeout(()=>{
        gtag('event', 'page_view', {
          page_title: pagename,
          page_path: window.location.pathname,
          page_location: window.location.href,
          send_to: measuremnetId
       })
      },5000)
    }
    // else{
    //   setTimeout(()=>{
    //     gtag('event', 'page_view', {
    //       page_title: pagename,
    //       page_path: window.location.pathname,
    //       page_location: window.location.href,
    //       send_to: measuremnetId
    //    })
    //   },1000)
    // }
  }


  public googleAnalyticsScripts(tag){
  /**   let elementScript = document.createElement('script');
         elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
         elementScript.async = true;
         document.getElementById('pvt__scripts').appendChild(elementScript);
    let elementScriptText = document.createElement('script');
    elementScriptText.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date()); gtag('config', ${tag},{send_page_view: false});`
    document.getElementById('pvt__scripts').appendChild(elementScriptText); */

    globalTag = tag;
    let elementScript = document.createElement('script');
    elementScript.src = `https://www.googletagmanager.com/gtag/js?id=${tag}`;
    elementScript.async = true;
    document.getElementById('pvt__scripts').appendChild(elementScript);
  
    // Wait for the script to load before calling gtag()
    elementScript.onload = () => {
      // Ensure window.dataLayer is initialized
      window.dataLayer = window.dataLayer || [];
      
      // Define gtag function globally
      window['gtag'] = function gtag() {
        window.dataLayer.push(arguments);
      };
  
      // Initialize gtag
      gtag('js', new Date());
  
      // Configure Google Analytics
      gtag('config', globalTag, { send_page_view: false });
    };

  }

  private loadDetail() {
    this.store$.dispatch(new landingPageActions.LoadDetail(this.selectedCompany.id, this.landingPageSlug));
  }

  private captureParams() {
    this.landingPageSlug = this.activatedRoute.snapshot.params['pageSlug'];
    const companySlug = this.activatedRoute.snapshot.params['slug'];
    this.companySlug = companySlug
    this.isIframeMode = this.activatedRoute.snapshot.queryParams.iframe == 1;

    this.store$.dispatch(new companyActions.LoadPublicInfo(companySlug));
  }

  private getDocHeight() {
    const doc = window.document;
    // from http://stackoverflow.com/questions/1145850/get-height-of-entire-document-with-javascript
    const body = doc.body, html = doc.documentElement;
    const height = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height;
  }

  private sendPostMessage() {
    if (window) {
      const message = JSON.stringify({ 'docHeight': this.getDocHeight() })
      window.parent.postMessage(message, '*');
    }
  }

  private resetStates(){
    this.store$.dispatch(new storyActions.ResetState());
    this.store$.dispatch(new searchBoxActions.ResetState());
  }
  // Public functions

  public filterTag(tag){
    var tagInserted = this.checktagshistory(tag)
    var payload = {tags: tagInserted}
    this.store$.dispatch(new searchBoxActions.SearchPayload(payload))
    this.store$.dispatch(new searchBoxActions.Show());
    this.store$.dispatch(new searchBoxActions.Search({ tags: tagInserted }));
  }

  private checkPermission() {
    this.isShowNudge = this.landingPage &&
      this.landingPage.cta && this.landingPage.cta.type 

    this.isNudgeBasic = this.landingPage && this.landingPage.cta && this.landingPage.cta.type === Constants.nudgeMode.basic;
    this.isNudgeDonation = this.landingPage && this.landingPage.cta && this.landingPage.cta.type === Constants.nudgeMode.donation;
    this.isNudgeComplex = this.landingPage && this.landingPage.cta && this.landingPage.cta.type === Constants.nudgeMode.advanced;
    this.isShowNudgeButton = (this.landingPage &&
      this.landingPage?.cta_form_configs &&
      this.landingPage?.cta_form_configs.length > 0
    ) || this.isNudgeBasic;
  }
  checktagshistory(tag){

    var tagStore = [];
    if(this.searchBoxStatus && this.searchBoxStatus.searchpayload){

      if(this.searchBoxStatus.searchpayload.tags.length){
        
        tagStore = this.searchBoxStatus.searchpayload.tags;
        tagStore.push(tag);
      }
      else{
          
         tagStore = [tag]
      }
    }
    else{
      tagStore = [tag];
    }
    return tagStore;
  }

  closeDonor(){
    if(this.showDonor){
      this.showDonor = false;
      document.getElementById('donation_form').innerHTML = ''
    }
    else{
      return;
    }

  }


  showDonation(){
    if(this.isBrowser){
      let tokenurl = '';
      let cls = btoa(this.primary_cmarr)
      let left = (screen.width - 1200) / 2;
      let top = (screen.height - 640) / 4;
      if(this.landingPageSlug && this.landingPage?.id && this.landingPage?.cta  && this.landingPage?.cta?.cta_slug){
        tokenurl =this.landingPage?.cta?.cta_slug;
      }
      else{
        tokenurl = 'action';
      }
      let url = window.location.origin+'/cta-action/'+tokenurl+'?'+'sid='+this.landingPageSlug+'&cid='+this.selectedCompany?.id+'&cls='+cls
      window.open(url, "_blank", 'resizable=yes, width=' + 1200
                + ', height=' + 640 + ', top='
                + top + ', left=' + left);
    }

  }
  public hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.3)';
    }
  }
  public openStep1() {
    this.ctaStepActivated = 1;
  }

  public openStep2() {
    this.ctaForm.controls.interested.updateValueAndValidity();
    this.ctaForm.controls.interested.markAsDirty();
    if (!this.ctaForm.valid) { return; }

    this.interestSelected = this.landingPage?.cta_form_configs.find(f => f.title === this.ctaForm.controls.interested.value);
    if (this.interestSelected.is_required_info) {
      this.ctaStepActivated = 2;
      return;
    }

    this.ctaFormSubmit();
  }

  public closeStep() {
    this.ctaStepActivated = 0;
    this.toggleShowActive();
  }

  private step1Require(control: UntypedFormControl) {
    if (this.ctaStepActivated !== 1) { return null; }

    const value = control.value;
    if (!value) {
      return {
        isCustom: true,
        message: 'Required.'
      };
    }
    return null;
  }
  private loadStorylinkedJobs(){
    this.store$.dispatch(new JobLinkacAction.GetSpotlightJobs(this.landingPage.id));
  }
  public ctaFormSubmit() {
    this.utility.Form.updateFormGroupValidity(this.ctaForm);
    this.utility.Form.markFormGroupDirty(this.ctaForm);
    if (!this.ctaForm.valid) { return; }

    const submitData: Partial<SubmitCtaInputApiModel> = {
      content: {
        question_text: this.ctaForm.controls.interested.value
      },
      template_id: this.landingPage.cta.config,
      cta_button: this.landingPage.cta.button
    };
    if (this.interestSelected.is_required_info) {
      submitData.full_name = this.ctaForm.controls.fullName.value;
      submitData.email = this.ctaForm.controls.email.value;
    }
    this.store$.dispatch(new landingPageActions.SubmitCta(this.selectedCompany.id, this.landingPage.slug,submitData));
  }

  getfrequentTags(){
    this.queryStoriesData.companyId = this.selectedCompany.id;
    this.queryStoriesData.campaigns = this.landingPage.queryObjects.facetedCampaignSelected ? this.landingPage.queryObjects.facetedCampaignSelected.map(m => m.id) : [];
    this.queryStoriesData.tags = this.landingPage.queryObjects.facetedTagSelected ? this.landingPage.queryObjects.facetedTagSelected.map(m => m.name) : [];
    this.queryStoriesData.peoples = this.landingPage.queryObjects.facetedPeopleSelected ? this.landingPage.queryObjects.facetedPeopleSelected.map(m => m.id) : [];
    this.queryStoriesData.includeStory = this.landingPage?.queryObjects?.queryStories?.includeStory;
    this.queryStoriesData.includeTestimonial = this.landingPage?.queryObjects?.queryStories?.includeTestimonial;
    this.queryStoriesData.internal = this.landingPage?.queryObjects?.queryStories?.internal;
    this.queryStoriesData.spotlightOnly = this.landingPage?.queryObjects?.queryStories?.spotlightOnly;
    this.queryStoriesData.public = true;
    this.queryStoriesData.spotlightOnly = true;
    this.queryStoriesData.pagination.sort ="desc"
    this.queryStoriesData.pagination.sortBy = 'published_at';

    this.store$.dispatch(new storyActions.LoadSpotlightFrequentTags(this.queryStoriesData));
   }

  public toggleShowActive() {
    this.isShowToggleActive = !this.isShowToggleActive;
    this.isFirst = false;
  }
  private step2Require(control: UntypedFormControl) {
    if (this.ctaStepActivated !== 2) { return null; }

    const value = control.value;
    if (!value) {
      return {
        isCustom: true,
        message: 'Required.'
      };
    }
    return null;
  }
  public addCtaHit(url){
    // let obj ={"tracking_id":this.alltrackingpayload.find(cta => Object.keys(cta).includes('cta')).tracking_id}
    // this.addtrackhit(obj)
    if(url.includes("https://") && url.split('//').length > 1){
    window.open(url, '_blank');
    }
    else{
      let data = 'https://'+url;
      window.open(data, '_blank');
    }
   

  }
  updateTagsForSSR(){
    this.companyHttpService.loadDetailLandingPage(this.companySlug, this.landingPageSlug).subscribe((res: any)=>{
      if(res){
        this.ssraddMetaTags(res);
      }
  
    })
   }
  private ssraddMetaTags(res): void {
    const config: MetaTagConfig = new MetaTagConfig();
    config.title = res.title;
    config.url = 'https://'+this.companySlug+'.'+environment.customUrl+'/c/'+this.companySlug+'/featured/'+this.landingPageSlug;
    if(!res.header_media){
      config.image ='https://'+this.companySlug+'.'+environment.customUrl+res.header_image;
    }
    else{
      config.image = res.header_image;
    }
    config.alt = res.title
    config.description = 'Spotlight Page'
    
    this.seoService.updateMetaTags(config);
  }

  showorhide(data){
    if(this.frequenttags && this.frequenttags && this.frequenttags[data.toString()]){
      return this.frequenttags[data.toString()]?.is_header_part;
    }
    // return true
  }
}

