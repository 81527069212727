import { Action } from '@ngrx/store';
import { EmailsSettingOutputApiModel } from '../models/api-models/output';
import { UpdateEmailSettingInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Emails Setting] Load',
    LOAD_SUCCESS = '[Company Emails Setting] Load Success',
    LOAD_FAILURE = '[Company Emails Setting] Load Failure',

    UPDATE = '[Company Emails Setting] Update',
    UPDATE_SUCCESS = '[Company Emails Setting] Update Success',
    UPDATE_FAILURE = '[Company Emails Setting] Update Failure'
};


export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) {}
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: EmailsSettingOutputApiModel) { }
}

export class LoadFailure implements Action {
    readonly type = ActionTypes.LOAD_FAILURE;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public payload: Partial<UpdateEmailSettingInputApiModel>) {
    }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAILURE;

    constructor(public error: any) { }
}



export type Actions = Load
    | LoadSuccess
    | LoadFailure
    | Update
    | UpdateSuccess
    | UpdateFailure;
