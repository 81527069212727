import { TestimonialAudienceSettingOutputApiModel } from "../models/api-models/output";

export interface TestimonialAudienceSettingEntity {
    id: number;
    name: string;
    isSelected: boolean;
}

export class TestimonialAudienceSettingEntity {
    constructor(data?: TestimonialAudienceSettingOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: TestimonialAudienceSettingOutputApiModel) {
        this.id = data.id;
        this.name = data.name;
        this.isSelected = data.is_active;
    }

}
