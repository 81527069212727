import { UserOutputApiModel } from '../models/api-models/output';

export interface UserEntity {
    id: number;
    name: string;
    slug: string;
    email: string;
    authorCount: number;
    contributorCount: number;
    joined: Date;
    registered: string;
    access: boolean;
    admin: boolean;
    status: string;
    userRole: string;
    draft_count: any
    display_title?:any;
    profile_title?:any;
}

export class UserEntity {
    constructor(data?: UserOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: UserOutputApiModel) {
        this.id = data.id;
        this.name = data.name;
        this.draft_count = data.draft_count
        this.slug = data.slug;
        this.email = data.email;
        this.authorCount = data.author_count;
        this.contributorCount = data.contributor_count;
        this.joined = data.joined;
        this.registered = data.registered;
        this.access = data.access;
        this.admin = data.admin;
        this.status = data.status;
        this.userRole = data.user_role;
        this.display_title = data?.display_title;
        this.profile_title = data?.profile_title
    }

}
