import { Component, OnInit, OnChanges, EventEmitter, Output, ElementRef, Renderer2, forwardRef, Input, Inject, Optional, Host, SkipSelf, ViewChild, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, NG_VALIDATORS, UntypedFormControl, ControlContainer, NgModel } from '@angular/forms';

@Component({
  selector: 'app-password-control',
  templateUrl: './password-control.component.html',
  styleUrls: ['./password-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordControlComponent),
      multi: true
    }
  ]
})
export class PasswordControlComponent implements ControlValueAccessor, AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() id: string = Date.now().toString();

  @Output() blur: EventEmitter<string> = new EventEmitter<string>();

  private inputValue: string;

  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  public isShowPassword: boolean = false;
  public inputType: string = 'password';

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {

  }

  private triggerChange($event) {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  // Public methods

  public set value(val: string) {
    if (val !== undefined) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public writePlaceholder(val: string): void {
    this.placeholder = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public showPassword() {
    this.isShowPassword = !this.isShowPassword;
    this.inputType = this.isShowPassword ? 'text' : 'password';
  }

  public onChange() {
    this.triggerChange(this.inputValue);
  }
}
