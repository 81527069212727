<div class="modal-header">
    <div style="display: inline-block">
      <h5 class="modal-title">Add Policy</h5>
      <p>Add Policy to your guest story checkout and signUp Page</p>
    </div>
    <button type="button" (click)="cancel()"  class="close"><span aria-hidden="true">
            <svg role="img">
                <use xlink:href="#icon-close"></use>
            </svg></span></button>
</div>
<div class="modal-body" [formGroup]="policyForm"> 
 <div class="row mb-3">
  <div class="custom-control custom-checkbox cta_font_Control col-4">
    <input id="isCampaign" type="checkbox" formControlName="isCampaign"
        class="custom-control-input">
    <label for="isCampaign" class="custom-control-label">Include Campaign Story</label>
</div>

<div class="custom-control custom-checkbox cta_font_Control col-4">
  <input id="isRegister" type="checkbox" formControlName="isRegister"
      class="custom-control-input">
  <label for="isRegister" class="custom-control-label">Include SignUp</label>
</div>

<div class="custom-control custom-checkbox cta_font_Control col-4">
  <input id="isDefault" type="checkbox" formControlName="isDefault"
      class="custom-control-input">
  <label for="isDefault" class="custom-control-label">Include all Guest Story</label>
</div>
 </div>
     <div class="row" style="width: 101%;">
      <div class="col-6 mt-2">
        <gs-input-material label="Name" name="Name" placeholder="Name"
        [formControl]="policyForm.controls.policy_name" formControlName="policy_name" type="text">
      </gs-input-material>
      </div>

      <div class="col-3 mt-2">
        <gs-input-material label="Policy Slug" name="Policy Slug" placeholder="Policy Slug"
        [formControl]="policyForm.controls.policy_slug" formControlName="policy_slug" type="text">
      </gs-input-material>

      </div>
      <div class="checkpolicy col-3 mt-2">
        <button class="btn btn_check ml-3" (click)="checkPolicySlug()">
         Check slug
        </button>
      </div>
     </div>

     <div class="row">
      <div class="col-12">
        <h6>Add a message.</h6>
        <gs-area-wysiwyg label="Content" name="Content" rows="10" placeholder="" formControlName="policyTemplateHtml"
        [formControl]="policyForm.controls.policyTemplateHtml" type="text"></gs-area-wysiwyg>
      </div>
     </div>

     <div class="row">
      <div class="col-12">
        <h6>Copy Policy</h6>
      <gs-input-clipboard name="bodyCode" placeholder="Body Code" formControlName="link"
      [formControl]="policyForm.controls.link"></gs-input-clipboard>
      </div>
     </div>
</div>

<div class="modal-footer">
  <button (click)="cancel()"  type="button" data-dismiss="modal"
    class="btn btn-outline btn-gray-light min-width">Cancel</button>
  <button (click)="save()"  type="button" [disabled]="policyForm.invalid" class="btn btn-pink min-width">
    Save
  </button>
</div>
