<div class="m-story-tags__inner">
  <div class="l-story-tags">
    <div *ngFor="let item of listItems" class="l-story-tags__item">
      <div class="l-story-tags__header">
        <div class="l-story-tags__icon">
          <div class="icon">
            <gs-skeleton-loader appearance="circle"></gs-skeleton-loader>
          </div>
        </div>
        <div class="l-story-tags__title">
          <gs-skeleton-loader width="60"></gs-skeleton-loader>
        </div>
      </div>
      <div class="l-story-tags__content">
        <div class="l-story-tags__lists">
          <div class="story-tag-item">
            <gs-skeleton-loader class="story-tag-item" width="100"></gs-skeleton-loader>
          </div>
          <div class="story-tag-item">
            <gs-skeleton-loader class="story-tag-item" width="90"></gs-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
