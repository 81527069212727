<ng-container *ngIf="!sharelink && !spotlightlink && !draftLink">
    <a [routerLink]="this.href" class="text-blue-light">{{value}}</a>
</ng-container>
<ng-container *ngIf="sharelink && !spotlightlink && !draftLink">
    <a [href]="this.href" target="_blank" class="text-blue-light">{{value}}</a>
</ng-container>
<ng-container *ngIf="!sharelink && !draftLink && spotlightlink">
    <a [href]="this.href" target="_blank" class="text-blue-light">{{value}}</a>
</ng-container>

<ng-container *ngIf="!sharelink  && !spotlightlink && draftLink" >
    <a [href]="this.href" target="_blank" class="text-blue-light">{{value}}</a>
</ng-container>