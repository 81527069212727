import { Action } from '@ngrx/store';
import { UserEmailOutputApiModel } from '@modules/user/models/output';

export enum ActionTypes {
  LOAD = '[User Email] Load',
  LOAD_SUCCESS = '[User Email] Load Success',
  LOAD_FAILURE = '[User Email] Load Failure',

  REMOVE = '[User Email] Remove',
  REMOVE_SUCCESS = '[User Email] Remove Success',
  REMOVE_FAILURE = '[User Email] Remove Failure',

  RESEND = '[User Email] Resend',
  RESEND_SUCCESS = '[User Email] Resend Success',
  RESEND_FAILURE = '[User Email] Resend Failure',

  ADD_EMAIL = '[User Email] Add Email',
  ADD_EMAIL_SUCCESS = '[User Email] Add Email Success',
  ADD_EMAIL_FAILURE = '[User Email] Add Email Failure',

  MAKE_PRIMARY_EMAIL = '[User Email] Make Primary Email',
  MAKE_PRIMARY_EMAIL_SUCCESS = '[User Email] Make Primary Email Success',
  MAKE_PRIMARY_EMAIL_FAILURE = '[User Email] Make Primary Email Failure',
}


export class Load implements Action {
  readonly type = ActionTypes.LOAD;

  constructor() {
  }
}

export class LoadSuccess implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;

  constructor(public response: UserEmailOutputApiModel[]) {
  }
}

export class LoadFailure implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;

  constructor(public error: any) {
  }
}

export class Remove implements Action {
  readonly type = ActionTypes.REMOVE;

  constructor(public idEmail: number) {
  }
}

export class RemoveSuccess implements Action {
  readonly type = ActionTypes.REMOVE_SUCCESS;

  constructor(public response: any, public idEmail?: number) {
  }
}

export class RemoveFailure implements Action {
  readonly type = ActionTypes.REMOVE_FAILURE;

  constructor(public error: any) {
  }
}

export class Resend implements Action {
  readonly type = ActionTypes.RESEND;

  constructor(public idEmail: number) {
  }
}

export class ResendSuccess implements Action {
  readonly type = ActionTypes.RESEND_SUCCESS;

  constructor(public response: any) {
  }
}

export class ResendFailure implements Action {
  readonly type = ActionTypes.RESEND_FAILURE;

  constructor(public error: any) {
  }
}

export class AddEmail implements Action {
  readonly type = ActionTypes.ADD_EMAIL;

  constructor(public data: any) {
  }
}

export class AddEmailSuccess implements Action {
  readonly type = ActionTypes.ADD_EMAIL_SUCCESS;

  constructor(public response: any) {
  }
}

export class AddEmailFailure implements Action {
  readonly type = ActionTypes.ADD_EMAIL_FAILURE;

  constructor(public error: any) {
  }
}

export class MakePrimaryEmail implements Action {
  readonly type = ActionTypes.MAKE_PRIMARY_EMAIL;

  constructor(public id: number) {
  }
}

export class MakePrimaryEmailSuccess implements Action {
  readonly type = ActionTypes.MAKE_PRIMARY_EMAIL_SUCCESS;

  constructor(public response: any) {
  }
}

export class MakePrimaryEmailFailure implements Action {
  readonly type = ActionTypes.MAKE_PRIMARY_EMAIL_FAILURE;

  constructor(public error: any) {
  }
}

export type Actions = Load | LoadSuccess | LoadFailure
  | Remove | RemoveSuccess | RemoveFailure
  | Resend | ResendSuccess | ResendFailure
  | AddEmail | AddEmailSuccess | AddEmailFailure
  | MakePrimaryEmail | MakePrimaryEmailSuccess | MakePrimaryEmailFailure;
