import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseHttpService } from '@framework/services';
import { Injectable } from '@angular/core';

@Injectable()
export class GetUniquelinkService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/stories');
    }

    public getuniqueLink(payload,fromVideo){
        if(!fromVideo){
            return this.post(`/unique/story-token/
            `,payload).pipe(map((response: any) => {
                return response;
            }));
        }
        else{
            return this.post(`/unique/video-story-token/
            `,payload).pipe(map((response: any) => {
                return response;
            }));
        }

    }

    public getuniqueVideoLink(payload,fromVideo){
   
            return this.post(`/unique/video-story-token/
            `,payload).pipe(map((response: any) => {
                return response;
            }));

    }
}