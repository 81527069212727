import { NotificationOutputApiModel } from '@app/store/http/models/output';

export interface NotificationEntity {
    id: number;
    content: string;
    link: string;
    photo: string;
    readDate: Date;
    sender: string;
    senderProfile: string;
    resourceType: string;
    requested?: boolean;
    story_id?: any;
    interview_style?:boolean;
    company_slug?:string;

}

export class NotificationEntity {
    constructor(data?: NotificationOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: NotificationOutputApiModel) {
        this.id = data.id;
        this.content = data.content;
        this.link = data.link;
        this.photo = data.photo;
        this.sender = data.sender;
        this.readDate = data.read_date;
        this.senderProfile = data.sender_profile;
        this.resourceType = data.resource_type;
        this.story_id = data.story_id;
        this.requested = data.requested
        this.interview_style = data.interview_style;
        this.company_slug = data.company_slug
    }
}