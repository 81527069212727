import { Component, SimpleChanges, Input, HostBinding } from '@angular/core';
import { Router } from '@angular/router';

import { ComponentBase } from '@framework/base/component';
import { CampaignViewModel, CompanyViewModel } from '@modules/company/models/store-view-models';

@Component({
  selector: 'co-pb-campaign-item',
  templateUrl: './campaign-item.component.html',
  styleUrls: ['./campaign-item.component.scss']
})
export class CampaignItemComponent extends ComponentBase {
  @Input() campaign: CampaignViewModel;
  @Input() company: CompanyViewModel;
  @Input() btnsubmit:any = '';
  @Input() txtsubmit: any = '';
  @Input() href: string;
  @HostBinding('class.campaign-item') campaignItemClass: boolean = true;
  @HostBinding('class.custom-div') customDivClass: boolean = true;

  constructor(private router: Router) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }
  // Public functions

  public getSlugCompany(story) {
    return story.companyIdSlug;
  }

  navgateToInterviewStyle(camapaigns){
    let url  = 'interview/inbound/campaign/create/' + camapaigns.companySlug + '/' + camapaigns.slug
    this.router.navigate([url])
  }

  get storyPrompt() {
    if (this.campaign.storyPrompt) {
      // return btoa(unescape(encodeURIComponent(this.campaign.storyPrompt)));
    } else {
      return null;
    }
  }
}
