import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, Renderer2, AfterViewChecked, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gs-input-material',
  templateUrl: './gs-input-material.component.html',
  styleUrls: ['./gs-input-material.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsInputMaterialComponent),
      multi: true
    }
  ]
})
export class GsInputMaterialComponent implements ControlValueAccessor, AfterViewInit, OnChanges, AfterViewChecked {
  @Input() label: string;
  @Input() labelSmall: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() type: string;
  @Input() cssClass: string;
  @Input() hideErrorMessage: boolean = false;
  @Input() autoFocus: boolean;
  @Input() tabindex: number;
  @Input() customId: number;
  @Input() maxlength: number;

  @Output() blur: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputControl')
  inputControl: ElementRef;

  private inputValue: string;
  private isVisible: boolean = false;

  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges() {

  }

  ngAfterViewChecked() {
    if (this.isVisible == false && this.inputControl.nativeElement.offsetParent != null) {
      // isVisible switched from false to true
      this.isVisible = true;
      if (this.autoFocus) {
        this.inputControl.nativeElement.focus();
      }
    }
    else if (this.isVisible == true && this.inputControl.nativeElement.offsetParent == null) {
      // isVisible switched from true to false
      this.isVisible = false;
    }
  }

  private triggerChange($event) {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  // Public methods

  public set value(val: string) {
    if (val !== undefined) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public writePlaceholder(val: string): void {
    this.placeholder = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange() {
    this.triggerChange(this.inputValue);
  }
}
