import { CommentOutputApiModel } from '../../models/api-models/output';

export interface CommentAuthorEntity {
    authorId: number,
    avatar: string,
    displayName: string,
    slug: string
}

export interface CommentEntity {
    content: string
    id: number
    likeId: number
    likesCount: number,
    createdAt: Date,
    author: CommentAuthorEntity
}

export class CommentEntity {
    constructor(data?: CommentOutputApiModel) {
        if (data)
            this.convertFromApi(data);
    }

    private convertFromApi(data: CommentOutputApiModel) {
        this.id = data.id;
        this.content = data.content;
        this.likesCount = data.likes_count;
        this.likeId = data.like_id;
        this.createdAt = data.time_created;
        if (data.commenter) {
            this.author = {
                authorId: data.commenter.commenter_id,
                avatar: data.commenter.avatar,
                displayName: data.commenter.display_name,
                slug: data.commenter.slug
            }
        }
    }
}
