export interface WorkAttributeValueOutputApiModel {
    id: number;
    value: string;
}

export interface WorkAttributeOutputApiModel {
    id: number;
    name: string;
    values: WorkAttributeValueOutputApiModel[]
}

export class WorkAttributeOutputApiModel {
    constructor(data?: Partial<WorkAttributeOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}