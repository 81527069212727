export interface PaginationModel {
    page: number,
    limit: number,
    sort: string,
    sortBy: string
}

export class PaginationModel {
    /**
     * @param limit: default is 10 
     */
    constructor(limit?: number) {
        this.page = 1;
        this.limit = limit || 10;
        this.sort = 'asc'
    }

    private buildQuery() {
        let query = `page=${this.page}&limit=${this.limit}&sort=${this.sort}`;

        if (this.sortBy) {
            query += `&sortby=${this.sortBy}`;
        }

        return query;
    }

    get query() {
        return this.buildQuery();
    }
}