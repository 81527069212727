export interface LikeOutputApiModel {
    name: string,
    resource_type: string,
    resource_id: number,
    payload: string,
    id: number
}

export class LikeOutputApiModel {
    constructor(data?: Partial<LikeOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}