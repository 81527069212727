import { Action } from '@ngrx/store';

import { LikeInputApiModel } from '../../models/api-models/input';
import { LikeOutputApiModel } from '@modules/shared/models/api-models/output';

export enum ActionTypes {
    LIKE = '[Story] Like',
    LIKE_SUCCESS = '[Story] Like Success',
    LIKE_FAILURE = '[Story] Like Failure',

    UNLIKE = '[Story] Unlike',
    UNLIKE_SUCCESS = '[Story] Unlike Success',
    UNLIKE_FAILURE = '[Story] Unlike Failure'
}

export class Like implements Action {
    readonly type = ActionTypes.LIKE;

    constructor(public payload: LikeInputApiModel) { }
}

export class LikeSuccess implements Action {
    readonly type = ActionTypes.LIKE_SUCCESS;

    constructor(public response: LikeOutputApiModel) { }
}

export class LikeFailure implements Action {
    readonly type = ActionTypes.LIKE_FAILURE;

    constructor(public error: any) { }
}

export class Unlike implements Action {
    readonly type = ActionTypes.UNLIKE;

    constructor(public id: number, public resource: string) { }
}

export class UnlikeSuccess implements Action {
    readonly type = ActionTypes.UNLIKE_SUCCESS;

    constructor() { }
}

export class UnlikeFailure implements Action {
    readonly type = ActionTypes.UNLIKE_FAILURE;

    constructor(public error: any) { }
}

export type Actions = Like | LikeSuccess | LikeFailure |
    Unlike | UnlikeSuccess | UnlikeFailure;
