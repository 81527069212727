import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@app/env/environment';
import { MediaOriginEnum } from '../enums';

@Pipe({ name: 'videoPosterCloudinary' })
export class VideoPosterCloudinaryPipe implements PipeTransform {
    transform(value: string, saveIn: MediaOriginEnum, width?: number, height?: number) {
        return this.generateImageSource(value, saveIn, width, height);
    }

    private generateImageSource(value: string, saveIn: MediaOriginEnum, width?: number, height?: number) {
        if (!value) return value;

        let cdnSrc = '';

        switch (saveIn) {
            case MediaOriginEnum.Server:
                cdnSrc = this.serverCase(value, width, height);
                break;
            case MediaOriginEnum.Amazon:
                cdnSrc = this.amazonCase(value, width, height);
                break;
            case MediaOriginEnum.Cloudinary:
                cdnSrc = this.cloudinaryCase(value, width, height);
                break;
            default:
                cdnSrc = this.defaultCase(value, width, height);
                break;
        }

        return cdnSrc;
    }

    private serverCase(value: string, width?: number, height?: number): string {
        let src = `${environment.cloudinary.cdnVideoRootUrl}/fetch/c_fill,f_auto`;
        if (height > 0) {
            src += `,h_${height}`;
        }

        if (width > 0) {
            src += `,w_${width}`;
        }

        src = `${src}/${value}`;

        const directoryPath = src.substring(0, src.lastIndexOf('/'));
        let fileName = src.substring(src.lastIndexOf('/') + 1);
        if (fileName.indexOf('.') > -1) {
            fileName = `${fileName.split('.').shift()}.png`;
        } else {
            fileName = `${fileName}.png`;
        }

        return `${directoryPath}/${fileName}`;
    }

    private amazonCase(value: string, width?: number, height?: number): string {
        const s3RelativePath = value.split(environment.cloudinary.s3RootUrl).pop();
        let src = `${environment.cloudinary.cdnVideoRootUrl}/upload/c_fill,f_auto`;
        if (height > 0) {
            src += `,h_${height}`;
        }

        if (width > 0) {
            src += `,w_${width}`;
        }
        src = `${src}/${environment.cloudinary.keyPrefix}${s3RelativePath}`;

        const directoryPath = src.substring(0, src.lastIndexOf('/'));
        let fileName = src.substring(src.lastIndexOf('/') + 1);
        if (fileName.indexOf('.') > -1) {
            fileName = `${fileName.split('.').shift()}.png`;
        } else {
            fileName = `${fileName}.png`;
        }

        return `${directoryPath}/${fileName}`;
    }

    private cloudinaryCase(value: string, width?: number, height?: number): string {
        const oldCloudinaryRelativePath = value.split(`${environment.cloudinary.cdnVideoRootUrl}/upload`).pop();
        let src = `${environment.cloudinary.cdnVideoRootUrl}/upload/c_fill,f_auto`;
        if (height > 0) {
            src += `,h_${height}`;
        }

        if (width > 0) {
            src += `,w_${width}`;
        }
        src = `${src}${oldCloudinaryRelativePath}`;

        const directoryPath = src.substring(0, src.lastIndexOf('/'));
        let fileName = src.substring(src.lastIndexOf('/') + 1);
        if (fileName.indexOf('.') > -1) {
            fileName = `${fileName.split('.').shift()}.png`;
        } else {
            fileName = `${fileName}.png`;
        }

        return `${directoryPath}/${fileName}`;
    }

    private defaultCase(value: string, width?: number, height?: number): string {
        let src = `${environment.cloudinary.cdnVideoRootUrl}/fetch/c_fill,f_auto`;
        if (height > 0) {
            src += `,h_${height}`;
        }

        if (width > 0) {
            src += `,w_${width}`;
        }
        src = `${src}/${value}`;

        const directoryPath = src.substring(0, src.lastIndexOf('/'));
        let fileName = src.substring(src.lastIndexOf('/') + 1);
        if (fileName.indexOf('.') > -1) {
            fileName = `${fileName.split('.').shift()}.png`;
        } else {
            fileName = `${fileName}.png`;
        }

        return `${directoryPath}/${fileName}`;
    }
}