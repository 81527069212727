import { MemoizedSelector, createSelector } from "@ngrx/store";

import { UserSettingState } from "../states";
import { UserFeatureState } from '../states';
import { selectUserFeatureState } from "./user-feature.selectors";
import { UserSettingViewModel } from "../models";
import { LoadStatusModel } from "@modules/shared/models";

export const selectUserSettingState: MemoizedSelector<object, UserSettingState> = createSelector(selectUserFeatureState, (state: UserFeatureState) => state.userSetting);


// -------------[Mapping Functions]-------------
const getUserSetting = (state: UserSettingState): UserSettingViewModel => {
    const setting: UserSettingViewModel = {
        completedFirstStory: state.completedFirstStory,
        completedInviteMember: state.completedInviteMember,
        completedSchedule: state.completedSchedule
    };
    return setting;
};

const getLoadStatus = (state: UserSettingState): LoadStatusModel => {
    const loadStatus: LoadStatusModel = {
        isLoading: state.isLoading,
        isLoaded: state.isLoaded
    };
    return loadStatus;
};


// -------------[Public Selectors]-------------
export const selectUserSetting: MemoizedSelector<object, UserSettingViewModel> = createSelector(selectUserSettingState, getUserSetting);
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> = createSelector(selectUserSettingState, getLoadStatus);