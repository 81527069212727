import { GridViewColumnModel } from "./grid-view-column.model";
import { GridViewActionModel } from "./grid-view-action.model";

export interface GridViewOptionsModel<T> {
    columns?: GridViewColumnModel[];
    data?: T[];
    actions?: GridViewActionModel[];
    selections?:any[];
    identityColumn?: string;
    hideFilter?: boolean;
    totalCount?: number;
}

export class GridViewOptionsModel<T>{
    constructor() {

    }
}