import { Component, SimpleChanges, Inject, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { companySelectors, campaignSelectors } from '@modules/company/store/selectors';
import { ComponentBase } from '@framework/base/component/component.base';
import { CompanyViewModel, CampaignViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { ModalService } from '@framework/services';
import {  UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { ShareModalContainerComponent } from "@modules/shared/components/commons/share-modal-container";
import { take } from 'rxjs/operators';
import { campaignActions, companyActions } from '@modules/company/store/actions';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'co-pb-cp-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends ComponentBase {
  

  // Private variables
  @Input() showSubmitStory:boolean = true;

  @Input() txtsubmit: any = '';
  @Input() btnsubmit:any = '';

  private selectCampaignDetail$: Observable<CampaignViewModel>;
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectCompanySubscription: Subscription;
  private selectCampaignDetailSubscription: Subscription;
  private selectCompany$: Observable<CompanyViewModel>;
  private campaignSlug: string = '';
  // Public variables
  public selectedCompany: CompanyViewModel;

  private selectCurrentUserProfileSubscription: Subscription;
  // Public variables
  public campaign: CampaignViewModel;
  public currentUserProfile: UserProfileViewModel;
  public insideIframe:boolean = false;

  constructor( private activatedRoute: ActivatedRoute,private store$: Store<CoreState>, private modalService: ModalService, private router: Router) {
    super();

    this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.captureParams();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    if (window.location !== window.parent.location) {
      this.insideIframe = true;
    } else {
      this.insideIframe = false;
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectCompanySubscription = this.selectCompany$.subscribe((company) => {
      if (company) {
        this.selectedCompany = company;
        this.loadDetail();
      }
    });
    this.selectCampaignDetailSubscription = this.selectCampaignDetail$.pipe(
  
    ).subscribe((campaign) => {
      if (campaign) {
        this.campaign = campaign;
      }
    });

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      this.currentUserProfile = userProfile;
    });
  }

  private unsubscribe() {
    this.selectCampaignDetailSubscription.unsubscribe();
    this.selectCurrentUserProfileSubscription.unsubscribe();
  }

  // Public functions
  public openShareModal() {
    this.modalService.showModal(ShareModalContainerComponent, {}, { animated: false }).then(() => {

    });
  }

  navgateToInterviewStyle(){
    let url  = 'interview/inbound/campaign/create/' + this.campaign.companySlug + '/' + this.campaign.slug
    this.router.navigate([url])
  }

  private loadDetail() {
    this.store$.dispatch(new campaignActions.LoadDetail(this.selectedCompany.id, this.campaignSlug));
  }

  private captureParams() {
    this.campaignSlug = this.activatedRoute.snapshot.params['pageSlug'];
    const companySlug = this.activatedRoute.snapshot.params['slug'];

    this.store$.dispatch(new companyActions.LoadPublicInfo(companySlug));
  }

  public getButtons() {
    if (this.campaign.buttons) {
      return this.campaign.buttons.filter(f => f.isSelected);
    }
    return null;
  }

  get storyPrompt() {
    if (this.campaign && this.campaign.storyPrompt) {
      // return btoa(unescape(encodeURIComponent(this.campaign.storyPrompt)));
    } else {
      return null;
    }
  }
}
