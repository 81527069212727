import { initialState, contributionAdapter } from '../adapters/contribution.adapter';
import { ContributionState } from '../states';
import { StoryEntity, ContributionEntity } from '../entities';
import { contributionActions } from '../actions';

export function contributionReducer(state = initialState, action: contributionActions.Actions): ContributionState {
    switch (action.type) {

        case contributionActions.ActionTypes.CREATE_SUCCESS: {
            const entity: ContributionEntity = new ContributionEntity(action.response);
            return {
                ...contributionAdapter.addOne(entity, state),
                selectedId: null
            }
        }

        case contributionActions.ActionTypes.LOAD_EDIT_SUCCESS: {
            const entity: ContributionEntity = new ContributionEntity(action.response);
            const newState = Object.assign({}, state, contributionAdapter.upsertOne(entity, state), { selectedId: entity.id });
            return {
                ...newState
            }
        }

        case contributionActions.ActionTypes.UPDATE_SUCCESS: {
            const entity = Object.assign(
                Object.create(StoryEntity.prototype),
                action.response
            );
            return {
                ...contributionAdapter.upsertOne(entity, state),
                selectedId: null
            }
        }

        case contributionActions.ActionTypes.UPDATE_BUILDER: {
            const contributionBuilder = Object.assign({}, state.contributionBuilderData || {}, action.data);
            return {
                ...state,
                contributionBuilderData: contributionBuilder
            }
        }

        case contributionActions.ActionTypes.SET_ACTIVE_STEP: {
            return {
                ...state,
                stepActived: action.step
            }
        }

        case contributionActions.ActionTypes.SET_STEP_COMPLETED: {
            const stepCompleted = state.stepCompleted || [];
            if (action.isCompleted) {
                if (stepCompleted.indexOf(action.stepId) === -1) {
                    stepCompleted.push(action.stepId);
                }
            } else {
                stepCompleted.splice(stepCompleted.indexOf(action.stepId), 1);
            }

            return {
                ...state,
                stepCompleted: stepCompleted
            }
        }

        case contributionActions.ActionTypes.RESET_SELECTED_ID: {
            return {
                ...state,
                selectedId: null,
                contributionBuilderData: null
            }
        }

        default: {
            return state;
        }
    }
}