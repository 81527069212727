import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { Action } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';

import { storyActions, contributionActions } from '../actions';
import { StoryHttpService } from '@modules/story/services';
import { ContributionOutputApiModel } from '@modules/story/models/api-models/output';

@Injectable()
export class ContributionEffect {

  
  createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<contributionActions.Create>(contributionActions.ActionTypes.CREATE),
    switchMap((action: contributionActions.Create) => {
      return this.storyHttpService.putContribution(action.storyId, action.payload).pipe(
        map((data: ContributionOutputApiModel) => {
          return new contributionActions.CreateSuccess(data);
        }),
        catchError((error: any) => {
          return of(new contributionActions.CreateFailure(error));
        })
      );
    })
  ));

  
  loadEditEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<contributionActions.LoadEdit>(contributionActions.ActionTypes.LOAD_EDIT),
    switchMap((action: contributionActions.LoadEdit) => {
      return from(this.storyHttpService.getContributionDetail(action.id)).pipe(
        map((response) => {
          return new contributionActions.LoadEditSuccess(response)
        }),
        catchError((error: any) => {
          return of(new contributionActions.LoadEditFailure(error));
        })
      )
    })
  ));

  
  updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<contributionActions.Update>(contributionActions.ActionTypes.UPDATE),
    switchMap((action: contributionActions.Update) => {
      return this.storyHttpService.putContribution(action.storyId, action.payload).pipe(
        map((data: ContributionOutputApiModel) => {
          return new contributionActions.UpdateSuccess(data);
        }),
        catchError((error: any) => {
          return of(new contributionActions.UpdateFailure(error));
        })
      );
    })
  ));

  
  deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<contributionActions.Delete>(contributionActions.ActionTypes.DELETE),
    switchMap((action: contributionActions.Delete) => {
      return this.storyHttpService.deleteContribution(action.contributionId).pipe(
        map(() => {
          return new contributionActions.DeleteSuccess();
        }),
        catchError((error: any) => {
          return of(new contributionActions.DeleteFailure());
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private storyHttpService: StoryHttpService) { }
}