<div class="m-story-tags__inner detail_page_tags_public" [hidden]="statusDetail.isLoading">
  <div class="l-story-tags" *ngIf="storyDetail?.stepsData">
    <div class="l-story-tags__item" *ngFor="let stepData of getStepsData()" [ngStyle]="{'color': stepData.color}">
      <div class="l-story-tags__header">
        <div class="l-story-tags__icon">
          <div class="icon">
            <svg role="img">
              <use attr.xlink:href="{{getStepIcon(stepData.title)}}"></use>
            </svg>
          </div>
        </div>
        <div class="l-story-tags__title">{{stepData.title}}</div>
      </div>
      <div class="l-story-tags__content">
        <div class="l-story-tags__lists">
          <div class="story-tag-item" *ngFor="let item of stepData.data">
            <a class="story-tag-single" (click)="likeTag(item,$event)" >{{item.label}}  <span class="gs-badge liketag"
              [id]="'countBadge'+ item.idOrSlug" [style.background]="stepData.color">{{item?.count || 0}}</span></a>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<skeleton-pb-step-bar [hidden]="statusDetail.isLoaded"></skeleton-pb-step-bar>
