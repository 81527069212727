export interface CampaignOutputApiModel {
    id: number;
    title: string;
    slug: string;
    author: string;
    story_count: number;
    time_created: Date;
    public: boolean;
    active: boolean;
    story_prompt: string;
    creator: string;
    show_story_prompt?: any;
    end_date?: any;
    order_campaign?: number;
    interview_style?:number
}

export class CampaignOutputApiModel {
    constructor(data?: Partial<CampaignOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}


