import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetReportLeadsInputApiModel } from '../models/api-models/input';

@Injectable()
export class EngagementReportHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/report/engagement/`);
    }

    public exportCSV(companyId: number) {
        return this.download(`/${companyId}/report/engagement/csv/`)
    }

}
