import { CampaignOutputApiModel } from '../models/api-models/output';

export interface CampaignEntity {
    id: number;
    title: string;
    slug: string;
    author: string;
    storyCount: number;
    createdAt: Date;
    public: boolean;
    active: boolean;
    storyPrompt: string;
    creator: string;
    show_story_prompt?:any;
    end_date?:any;
    order_campaign?: number
    interview_style?:boolean
}

export class CampaignEntity {
    constructor(data?: CampaignOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: CampaignOutputApiModel) {
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.author = data.author;
        this.storyCount = data.story_count;
        this.createdAt = data.time_created;
        this.public = data.public;
        this.active = data.active;
        this.storyPrompt = data.story_prompt;
        this.creator = data.creator;
        this.show_story_prompt = data.show_story_prompt;
        this.end_date = data.end_date;
        this.order_campaign = data.order_campaign;
        this.interview_style = data.interview_style == 1 ? true : false;
    }

}
