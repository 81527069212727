<perfect-scrollbar #scroll [config]="config" [class]="css"
                  (psScrollY)="psScrollY($event)"
                  (psScrollX)="psScrollX($event)"
                  (psScrollUp)="psScrollUp($event)"
                  (psScrollDown)="psScrollDown($event)"
                  (psScrollLeft)="psScrollLeft($event)"
                  (psScrollRight)="psScrollRight($event)"
                  (psYReachEnd)="psYReachEnd($event)"
                  (psYReachStart)="psYReachStart($event)"
                  (psXReachEnd)="psXReachEnd($event)"
                  (psXReachStart)="psXReachStart($event)">
  <ng-content></ng-content>
</perfect-scrollbar>


