export interface TagEntity {
    id: number,
    name: string,
    description: string
}

export class TagEntity {
    constructor(init?: Partial<TagEntity>) {
        Object.assign(this, init);
    }
}