import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD_PERMISSION = '[User Permission] Load Permission',
    LOAD_PERMISSION_SUCCESS = '[User Permission] Load Permission Success',
    LOAD_PERMISSION_FAILURE = '[User Permission] Load Permission Failure',
};
export class LoadPermissions implements Action {
    readonly type = ActionTypes.LOAD_PERMISSION
}

export class LoadPermissionsSuccess implements Action {
    readonly type = ActionTypes.LOAD_PERMISSION_SUCCESS;

    constructor(public response: any) { }
}

export class LoadPermissionsFailure implements Action {
    readonly type = ActionTypes.LOAD_PERMISSION_FAILURE ;

    constructor(public error: any) { }
}

export type Actions = LoadPermissions | LoadPermissionsFailure | LoadPermissionsSuccess;