import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetStoriesInputApiModel, UpdateMySettingStoryInputApiModel } from '../models/api-models/input';
import { StoryOutputApiModel } from '../models/api-models/output';

@Injectable()
export class UserHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/users');
    }

    public getStories(queryData: GetStoriesInputApiModel) {      
        return this.get(`/${queryData.userId}/stories/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<StoryOutputApiModel>) => {
                return response;
            })
        );
    }

    public getStoriesForMySettingSetting(queryData: GetStoriesInputApiModel) {
        return this.get(`/settings/stories/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<StoryOutputApiModel>) => {
                return response;
            })
        );
    }

    

    public deleteStories(storyIds: number[]) {
        return this.delete(`/settings/stories/?ids=${storyIds.join(',')}`).pipe(map((response: any) => response));
    }

    
    public updateMySettingStory(storyId: number, payload: UpdateMySettingStoryInputApiModel) {
        return this.patch(`/settings/stories/${storyId}/`, payload).pipe(map((response: Partial<UpdateMySettingStoryInputApiModel>) => {
            return response;
        }));
    }
}
