import { NgModule } from '@angular/core';

import { CognitoAuthService } from './cognito-auth.service';
import { OAuthDialogService } from './core';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [CognitoAuthService, OAuthDialogService],
})
export class CognitoModule { }