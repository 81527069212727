import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'co-pu-informations-skeleton',
  templateUrl: './informations-skeleton.component.html',
  styleUrls: ['./informations-skeleton.component.scss']
})
export class InformationsSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
