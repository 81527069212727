import { createSelector, MemoizedSelector } from '@ngrx/store';

import { UserEmailState, UserFeatureState } from '../states';
import { selectUserFeatureState } from './user-feature.selectors';
import { LoadStatusModel } from '@modules/shared/models';
import { UserEmailEntity } from '@modules/user/store/entities';
import { UserEmailViewModel } from '@modules/user/store/models';
import { userEmailAdapter } from '@modules/user/store/adapters/user-email.adapter';

export const selectUserEmailsState: MemoizedSelector<object, UserEmailState> =
  createSelector(selectUserFeatureState, (state: UserFeatureState) => state.userEmail);

const {
  selectIds: selectIds,
  selectEntities: selectMediaEntities,
  selectAll: selectAll,
  selectTotal: selectTotal
} = userEmailAdapter.getSelectors(selectUserEmailsState);

// -------------[Mapping Functions]-------------
const getAllListEmails = (entities: UserEmailEntity[]): UserEmailViewModel[] => {
  return entities.map(entity => new UserEmailViewModel(entity));
};

const getLoadStatus = (state: UserEmailState): LoadStatusModel => {
  return {
    isLoading: state.isLoading,
    isLoaded: state.isLoaded
  };
};


// -------------[Public Selectors]-------------
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> =
  createSelector(selectUserEmailsState, getLoadStatus);
export const selectListEmail: MemoizedSelector<object, UserEmailViewModel[]> =
  createSelector(selectAll, getAllListEmails);
