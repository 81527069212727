import { Action } from '@ngrx/store';

export enum ActionTypes {
    LOAD = '[Company Admin Auth Page Setting] Load',
    LOAD_SUCCESS = '[Company Admin Auth Page Setting] Load Success',
    LOAD_FAIL = '[Company Admin Auth Page Setting] Load Fail',

    UPDATE = '[Company Admin Auth Page Setting] update',
    UPDATE_SUCCESS = '[Company Admin Auth Page Setting] update Success',
    UPDATE_FAIL = '[Company Admin Auth Page Setting] update Fail',
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: any) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: any) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public payload: any) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    Update |
    UpdateSuccess |
    UpdateFailure 
    ;
