<div class="modal-header">
    <h5 class="modal-title">Unique link</h5>
    <button type="button" (click)="close()" data-dismiss="modal" aria-label="Close" class="close">
      <span aria-hidden="true">
        <svg role="img">
          <use xlink:href="#icon-close"></use>
        </svg>
      </span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="rssForm">
    <div class="form-label-group mb-0 form-group-copy">
      <gs-input-clipboard label="Link" name="Link" placeholder="Link" formControlName="link"
        [formControl]="rssForm.controls.link"></gs-input-clipboard>
    </div>
  </div>
