import { ProfilePublicState } from '../states';
import { profilePublicActions } from '../actions';
import { ProfilePublicEntity } from '../entities';
import { profilePublicAdapter, initialState } from '../adapters/profile-public.adapter';


export function profilePublicReducer(state = initialState, action: profilePublicActions.Actions): ProfilePublicState {
    switch (action.type) {

        case profilePublicActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case profilePublicActions.ActionTypes.LOAD_SUCCESS: {
            const result: any = [action.response];
            const entities = result.map(item => new ProfilePublicEntity(item));
            const newSate = profilePublicAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true
            };
        }

        case profilePublicActions.ActionTypes.LOAD_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        default: {
            return state;
        }
    }
}