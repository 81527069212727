import { Component, Input, OnChanges, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'gs-avatar',
  templateUrl: './gs-avatar.component.html',
  styleUrls: ['./gs-avatar.component.scss']
})
export class GsAvatarComponent implements OnChanges {
  @Input() src: string;
  @Input() classCss: string;
  @Input() isBackground: boolean;
  // Private variables
  private errorImage: string = 'assets/front-end-minify/img/avatar-default.png';
  // Public variables
  public isLoading: boolean = false;
  public imgSrc: string;

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnChanges() {
    this.isLoading = true;

    if (this.src) {
      const imageElement = this.document.createElement('img');
      imageElement.src = this.src;
      imageElement.onload = this.onLoaded.bind(this);
      imageElement.onerror = this.onError.bind(this);
    } else {
      this.imgSrc = this.errorImage;
    }
  }

  // Private functions

  // Public functions
  public onLoaded(e) {
    this.isLoading = false;
    this.imgSrc = this.src;
  }

  public onError(e) {
    this.isLoading = false;
    this.imgSrc = this.errorImage;
  }
}
