import { Component, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {  Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentModalBase } from '@framework/base/component';
import { ToastService } from '@framework/services';
import { UserProfileViewModel } from '@modules/user/store/models';
import { CoreState } from '@app/store/core/states';
import {
  storyActions
} from '@modules/story/store/actions'

@Component({
  selector: 'app-seo-settings-modal',
  templateUrl: './seo-settings-modal.component.html',
  styleUrls: ['./seo-settings-modal.component.scss']
})
export class SeoSettingsModalComponent extends ComponentModalBase<any> {

  // Private variables
  private LoadSeoSuccessSubscription: Subscription;
  private LoadSeoFailureSubscription: Subscription;
  private createSeoSuccessSubscription: Subscription;

  public currentUserProfile: UserProfileViewModel;
  public seoForm: UntypedFormGroup;
  public showAltImage: boolean = false;
  public mediaPayload:any[] = [];
  public isSaving: boolean = false;
  public imageUrl: any;
  private selectCampaignDetailSubscription: Subscription;
  isPublishedCampaign: boolean;

  constructor(
    private store$: Store<CoreState>,
    private formBuilder: UntypedFormBuilder,
    private actions$: Actions,
    private toastService: ToastService) {
    super();

    this.initForm();
    // this.selectCampaignDetail$ = this.store$.select(campaignSelectors.selectCampaign);
    // this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    if (this.initialState) {
      if(this.initialState.media && this.initialState.media.length)
      {
        this.showAltImage = true;
        this.seoForm.controls.altImage.setValidators(Validators.required);

        this.mediaPayload  = [];
        for(let i=0 ; i< this.initialState.media.length ; i++){
         var payload:MeediaPayload = new MeediaPayload();
           if(this.initialState.media[i].type != "video/mp4"){
             let index = i+1
             payload.id = this.initialState.media[i].id
             payload.url = this.initialState.media[i].fullsize
             payload.name = 'Image'+''+index
             this.mediaPayload.push(payload)
           }
           else{
            let index = i+1
             payload.id = this.initialState.media[i].id
             payload.url = this.getUrl(this.initialState.media[i].source);
             payload.name = 'Video'+''+index;
             this.mediaPayload.push(payload)
           }

           
        } 

      }
      else
      {
        this.showAltImage = false;
        this.seoForm.controls.altImage.setValidators(null)
      }
     this.seoForm.patchValue({
      id: this.initialState.id,
      title: this.initialState.title,
      headerImage: this.initialState.media ? this.getHeaderImage(this.initialState.media) : 'https://super-static-assets.s3.amazonaws.com/981a8884-db68-43a0-a5ea-14221b7600f2/images/8755f10a-636e-49c1-8e4f-f814fe3e4451.png',
      description: this.getHtmlContent(this.initialState.contentRaw).substr(0,150),
      altImage: this.initialState.title
    });

    }
  }
  private getHtmlContent(content): string
  {
    var createElem = document.createElement('div');
    createElem.innerHTML=content;
    return createElem.innerText
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.store$.dispatch(new storyActions.LoadSeo(this.initialState.id));

    this.LoadSeoSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.LoadSeoSuccess>(storyActions.ActionTypes.LOAD_SEO_SUCCESS)
    ).subscribe((actions) => {
      if(actions.data)
      {
        this.seoForm.patchValue({
          title: actions.data.title ? actions.data.title : this.initialState.title,
          description: actions.data.description ? actions.data.description.substr(0,150) : this.getHtmlContent(this.initialState.contentRaw).substr(0,150),
          altImage: actions.data.altImage ? actions.data.altImage : this.initialState.title,
        });
        if(this.mediaPayload && this.mediaPayload.length){
          this.imageUrl = actions.data.image_url ?  actions.data.image_url : this.mediaPayload[0].url;
          this.seoForm.controls.image_url.setValue(this.imageUrl)
        }


        if(this.mediaPayload && this.mediaPayload.length && this.mediaPayload.find((item)=> item.url == actions.data.image_url)){
          let data  = this.mediaPayload.find((item)=> item.url == actions.data.image_url)
          this.seoForm.controls.imageUrl.setValue(data.id)
        }
        else{
          if(this.mediaPayload.length){
            this.seoForm.controls.imageUrl.setValue(this.mediaPayload[0].id)
            this.seoForm.controls.image_url.setValue(this.mediaPayload[0].url)
          }

        }
      }
      else
      {
        this.seoForm.patchValue({
          id: this.initialState.id,
          title: this.initialState.title,
          headerImage: this.initialState.media ? this.getHeaderImage(this.initialState.media) : 'https://super-static-assets.s3.amazonaws.com/981a8884-db68-43a0-a5ea-14221b7600f2/images/8755f10a-636e-49c1-8e4f-f814fe3e4451.png',
          description: this.getHtmlContent(this.initialState.contentRaw).substr(0,150),
          altImage: actions.data.altImage ? actions.data.altImage : this.initialState.title,
        });
        if(this.mediaPayload && this.mediaPayload.length){
          this.imageUrl = this.mediaPayload[0].data.image_url
          this.seoForm.controls.image_url.setValue(this.mediaPayload[0].data.image_url)
          this.seoForm.controls.imageUrl.setValue(this.mediaPayload[0].id)
        }

 
      }

    })

    this.createSeoSuccessSubscription = this.actions$.pipe(
      ofType<storyActions
      .CreateSeoSuccess>(storyActions.ActionTypes.CREATE_SEO_SUCCESS)
    ).subscribe((actions) => {
      this.toastService.success("Changes Saved Successfully");
      this.close();
    })
  }

  private unsubscribe() {
    this.LoadSeoSuccessSubscription.unsubscribe();
    this.createSeoSuccessSubscription.unsubscribe();
  }



  private initForm() {
    this.seoForm = this.formBuilder.group({
      title: ['', [Validators.required,Validators.maxLength(60)]],
      description: ['',Validators.required],
      altImage:[''],
      headerImage: [''],
      imageUrl:[],
      image_url:[]
    });
  }
  public close() {
    this.onClose.emit(false);
  }
  saveData() {
   if(this.seoForm.invalid)
   {
     return;
   }
   else
   {
     this.store$.dispatch(new storyActions.CreateSeo(this.seoForm.value,this.initialState.id))
   }
  }

  getHeaderImage(data) {

    if (data.type == 'video/mp4') {

      return this.getUrl(data.source)
    }
    else {
     return this.initialState.media.preview
    }

  }

  changeData(data){
    if(typeof data === 'string' ){
      if(data != '-1'){
        this.imageUrl  = this.mediaPayload.find(item => item.id == +data).url
        this.seoForm.controls.image_url.setValue(this.imageUrl)

      }
    }
  }
  getUrl(token){
    if(token){
       return "https://hippolms-storage.s3-accelerate.amazonaws.com/wiz/videos/thumbnails/v2/"+token+"_play.jpg"
    }
  }
}

export interface MeediaPayload {
  id:any,
  url:any
  name:any
}

export class MeediaPayload {
constructor() {
}
}
