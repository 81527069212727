export interface UserAttributeSettingOutputApiModel {
    id: number;
    name: string;
    values: UserAttributeValueSettingOutputApiModel[];
}

export class UserAttributeValueSettingOutputApiModel {
    id: number;
    value: string;
}

export class UserAttributeSettingOutputApiModel {
    constructor(data?: Partial<UserAttributeSettingOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}