import { Action } from '@ngrx/store';

export enum ActionTypes {
  APP_INITIALIZED = "[App] App Initialized"
}

export class AppInitialized implements Action {
  readonly type: string = ActionTypes.APP_INITIALIZED;
}

export type Actions = AppInitialized;