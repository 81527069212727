import { Component, SimpleChanges, Inject, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { ComponentBase } from '@framework/base/component/component.base';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { CompanyViewModel } from '@modules/company/models/store-view-models';
import { companySelectors } from '@modules/company/store/selectors';

@Component({
  selector: 'co-pu-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent extends ComponentBase {

  @Input() btnColor:any = '';
  @Input() textColor:any = '';
  // Private variables
  private selectCurrentCompany$: Observable<CompanyViewModel>;

  private selectCurrentCompanySubscription: Subscription;

  // Public variables
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  public insideIframe: boolean;

  constructor(private store$: Store<CoreState>) {
    super();

    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
    if (window.location !== window.parent.location) {
      this.insideIframe = true;
      document.getElementsByClassName("site-container")[0]['style'].padding = 0;
    } else {
      this.insideIframe = false;
    }
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private subscribe() {
    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.setDefaultValues();
      }
    });
  }

  private unsubscribe() {
    this.selectCurrentCompanySubscription.unsubscribe();
  }

  private setDefaultValues() {
    this.currentCompany.logo = this.currentCompany.logo ? this.currentCompany.logo : 'assets/front-end-minify/img/company-logo.svg';
  }

  // Public functions
  public getButtons() {
    if (this.currentCompany.buttons) {
      const x = this.currentCompany.buttons.filter(f => f.isSelected);
      return x;
    }
    return null;
  }

}
