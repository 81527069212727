import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { StepOutputApiModel, TagOutputApiModel, StoryOutputApiModel, StorySettingOutputApiModel, StoryFacetedSearchOutputApiModel } from '@modules/story/models/api-models/output';
import { CtaTemplateOutputApiModel } from '@app/store/http/models/output';
import { GetStoriesInputApiModel, SearchStoryInputApiModel } from '../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { UpdateStepTagSettingInputApiModel } from '@modules/company-admin/store/models/api-models/input';

@Injectable()
export class CompanyHttpService extends BaseHttpService {

    constructor(protected http: HttpClient) {
        super(http, '/companies');
    }

    public getContributionStep(companyId: number) {
        return this.get('/' + companyId + '/setting/story-steps/contributions/').pipe(map((response: StepOutputApiModel[]) => {
            return response;
        }));
    }

    public getSteps(companyId: number, isInbound: boolean = false, tagSelected: string, activeOnly: boolean = false) {
        let apiUrl = '/' + companyId + '/setting/story-steps/';
        if (isInbound) {
            apiUrl = '/' + companyId + '/setting/story-steps/inbound/';
        }
        if (tagSelected) {
            apiUrl += `?selected=${tagSelected}`;
        }
        if (activeOnly) {
            apiUrl += !tagSelected ? '?' : '&';
            apiUrl += `ao=true`;
        }
        return this.get(apiUrl).pipe(
            map((response: Array<StepOutputApiModel>) => {
                return response.map(r => Object.assign({}, r, {
                    tags: r.tags && r.tags.map(tag => new TagOutputApiModel(tag))
                }));
            }));
    }

    public getCtaTemplates(companyId: number, isInbound: boolean) {
        let url = '/' + companyId + '/setting/cta-templates/';
        if (isInbound === true) {
            url = '/' + companyId + '/setting/cta-templates/inbound/';
        }
        return this.get(url).pipe(
            map((response: Array<CtaTemplateOutputApiModel>) => {
                return response;
            }));
    }

    public changeUserTitle(companyId,uid,payload){
        return this.patch(`/${companyId}/setting/users/${uid}/title/`,payload).pipe(
            map((response: any) => {
                return response;
            }));
    }

    public getTestimoniaSetting(companyId: number) {
        return this.get(`/${companyId}/setting/testimonial/`).pipe(
            map((response: StorySettingOutputApiModel) => {
                return response;
            }));
    }

    public getStories(queryData: SearchStoryInputApiModel) {
        return this.get(`/${queryData.companyId}/stories/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<StoryOutputApiModel, StoryFacetedSearchOutputApiModel>) => {
                return response;
            })
        );
    }

    public getFrequentTags(queryData: SearchStoryInputApiModel) {
        return this.get(`/${queryData.companyId}/stories/frequent_tags/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<StoryOutputApiModel, StoryFacetedSearchOutputApiModel>) => {
                return response;
            })
        );
    }

    public getCompanySetting(slug: string) {
        return this.get(`/${slug}/setting/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public updateStepTagSetting(companyId: number, id: number, isInbound: boolean, isStep: boolean, payload: Partial<UpdateStepTagSettingInputApiModel>) {
        const apiUrl = `/${companyId}/setting/${isStep ? 'testimonial-story-steps' : 'testimonial-story-tags'}/${id}/${isInbound ? '?inbound=true' : ''}`;
        return this.patch(apiUrl, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public createStepTagSetting(companyId: number, stepId:number, isInbound: boolean, isStep: boolean, payload: Partial<UpdateStepTagSettingInputApiModel>) {
        const apiUrl = `/${companyId}/setting/${isStep ? 'testimonial-story-steps' : 'testimonial-story-tags'}/${stepId}/add/${isInbound ? '?inbound=true' : ''}`;
        return this.post(apiUrl, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteStepTagSetting(companyId: number, id: number, isInbound: boolean, isStep: boolean) {
        const apiUrl = `/${companyId}/setting/${isStep ? 'testimonial-story-steps' : 'testimonial-story-tags'}/${id}/${isInbound ? '?inbound=true' : ''}`;
        return this.delete(apiUrl).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getQuestionsList(companyId){
        return this.get(`/${companyId}/setting/story-question/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public addQuestionsList(companyId,data){
        return this.post(`/${companyId}/setting/story-question/`,data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public editQuestionsList(companyId,questionId,payload){
        return this.patch(`/${companyId}/setting/story-question/${questionId}/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public deleteQuestionsList(companyId,questionId){
        return this.delete(`/${companyId}/setting/story-question/${questionId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public getNotes(companyId,storyId){
        return this.get(`/${companyId}/setting/story-notes/?id_story=${storyId}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public addNoteList(companyId,data){
        return this.post(`/${companyId}/setting/story-notes/`,data).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public editNotesList(companyId,noteId,payload){
        return this.patch(`/${companyId}/setting/story-notes/${noteId}/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
    public deleteNoteList(companyId,noteId){
        return this.delete(`/${companyId}/setting/story-notes/${noteId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public getReview(companyId,storyId,queryParams){
        return this.get(`/${companyId}/${storyId}/review_sites/?${queryParams}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
