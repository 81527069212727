import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';

import { userProfileActions } from '../actions';
import { UserHttpService } from '../../services';

@Injectable()
export class UserProfileEffects {
    
    loadCurrentProfileEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userProfileActions.LoadCurrentProfile>(userProfileActions.ActionTypes.LOAD_CURRENT_PROFILE),
        switchMap((action: userProfileActions.LoadCurrentProfile) => {
            return this.userHttpService.getCurrentUserProfile().pipe(
                map((respone) => {
                    return new userProfileActions.LoadCurrentProfileSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new userProfileActions.LoadCurrentProfileFailure(error));
                }));
        })
    ));

    
    loadPublicProfileEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userProfileActions.LoadPublicProfile>(userProfileActions.ActionTypes.LOAD_PUBLIC_PROFILE),
        switchMap((action: userProfileActions.LoadPublicProfile) => {
            return this.userHttpService.getPublicUserProfile(action.userIdSlug).pipe(
                map((respone) => {
                    return new userProfileActions.LoadPublicProfileSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new userProfileActions.LoadPublicProfileFailure(error));
                }));
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userProfileActions.Update>(userProfileActions.ActionTypes.UPDATE),
        switchMap((action: userProfileActions.Update) => {
            return this.userHttpService.update(action.payload).pipe(
                map(() => {
                    return new userProfileActions.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new userProfileActions.UpdateFailure(error));
                }))
        })
    ));

    
    changeSlugEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userProfileActions.ChangeSlug>(userProfileActions.ActionTypes.CHANGE_SLUG),
        switchMap((action: userProfileActions.ChangeSlug) => {
            return this.userHttpService.changeSlug(action.payload).pipe(
                map(() => {
                    return new userProfileActions.ChangeSlugSuccess();
                }),
                catchError((error: any) => {
                    return of(new userProfileActions.ChangeSlugFailure(error));
                }))
        })
    ));

    constructor(
        private actions$: Actions,
        private userHttpService: UserHttpService
    ) { }
}