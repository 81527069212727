<div class="modal-header">
	<h2 class="heading__style"><span>SEO Settings for </span><span
			class="cursor-pointer text-gray-500 overflow-ellipsis overflow-hidden whitespace-nowrap"><span>/{{initialState.slug}}</span></span>
	</h2>
	<button type="button" (click)="close()" class="close">
		<span aria-hidden="true">
			<svg role="img">
				<use xlink:href="#icon-close"></use>
			</svg></span>
	</button>
</div>
<div class="modal-body" [formGroup]="seoForm">
	<div class="row">
		<div class="col-6">
			<h6>SEO Title and Description</h6>
			<p class="text-gray-500 pt-2 text-sm">Max 60 characters for title and 150 for description.</p>
			<gs-input-material maxlength="60" label="Meta title tag" name="SiteTitle" placeholder="Site Title" formControlName="title"
				[formControl]="seoForm.controls.title" type="text">
			</gs-input-material>
			<!--<p class="text-gray-500 pt-2 text-sm">Summarize the story, add keywords, author, brand name.</p>-->
			<textarea name="description" maxlength="150" formControlName="description" variant="outline"
				placeholder="Meta description" rows="4" class="description bg-white dark:bg-gray-600 dark:text-white placeholder-gray-500 resize-none undefined w-full text-sm  rounded-lg outline-none  border border-gray-100 dark:border-gray-650 focus:border-yellow transition-colors
		"></textarea>
			<div style="float: right;"><small>({{(seoForm.value.description ? seoForm.value.description.length : 0 )+'/150'}})</small></div>
			<ng-container *ngIf="showAltImage">
				<h6 class="m-top" >Alt Image Text Tag</h6>
				<!--<p class="text-gray-500 pt-2 text-sm">Used for providing alternate text for an image if the image cannot be
					displayed</p>-->
				<gs-input-material label="Alt Image Tag" name="Alt Image Title" placeholder="Alt Image Title"
					formControlName="altImage" [formControl]="seoForm.controls.altImage" type="text">
				</gs-input-material>
			</ng-container>

			<ng-container *ngIf="showAltImage">
				<h6 class="m-top" >Alt Image Text Tag</h6>
				<!--<p class="text-gray-500 pt-2 text-sm">Used for providing alternate text for an image if the image cannot be
					displayed</p>-->
				<gs-input-material label="Alt Image Tag" name="Alt Image Title" placeholder="Alt Image Title"
					formControlName="altImage" [formControl]="seoForm.controls.altImage" type="text">
				</gs-input-material>
			</ng-container>

			<ng-container *ngIf="showAltImage">
				<h6 class="m-top" >Alt Image Text Tag</h6>
				<!--<p class="text-gray-500 pt-2 text-sm">Used for providing alternate text for an image if the image cannot be
					displayed</p>-->
				<gs-select-material name="Images && Video" label="Images && Video" formControlName="imageUrl"
				[formControl]="seoForm.controls.imageUrl" (change)="changeData($event)" [data]="mediaPayload"
				valueField="id" displayField="name" type="text">
				</gs-select-material>
			</ng-container>

		</div>
		<div class="col-6">
			<div class="w-full h-full pb-3.5">
				<div class="w-full h-full border border-gray-100 dark:border-gray-650 rounded-lg">
					<div class="bg-gray-200 w-full h-44 rounded-t-lg"><img [src]="imageUrl"
							class="w-full h-full rounded-t-lg object-cover object-center"></div>
					<div class="px-3 py-4 titleStyle">
						<p class="sitetitletext">
							{{seoForm.value.title}} </p>
						<p class="text-sm text-gray-500  overflow-hidden  overflow-ellipsis">
							{{seoForm.value.description}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- <div class="row">
	  <div class="col-12">
		<hr>
		<h6>Header Buttons</h6>
		<ng-container formArrayName="buttons" *ngFor="let item of buttonsFormArray.controls; index as i">
		  <ng-container [formGroupName]="i">
			<div class="row px-2">
			  <div class="px-2 col-lg-1 mt-3">
				<div class="custom-control custom-checkbox mb-2">
				  <label class="mb-0">
					<input formControlName="isSelected" type="checkbox" class="custom-control-input"><span
					  class="custom-control-label">{{i + 1}}</span>
				  </label>
				</div>
			  </div>
			  <div class="px-2 col-lg-3">
				<gs-input-material [customId]="i" label="Label" name="Label" placeholder="Label" formControlName="label"
				  [formControl]="item.controls.label" type="text">
				</gs-input-material>
			  </div>
			  <div class="px-2 col-lg-4">
				<gs-input-material [customId]="i" label="URL" name="URL" placeholder="URL" formControlName="url"
				  [formControl]="item.controls.url" type="text">
				</gs-input-material>
			  </div>
			  <div class="px-2 col-lg-2">
				<gs-input-color-picker [customId]="i" label="Foreground" name="Foreground" placeholder="Foreground"
				  formControlName="foreground" [formControl]="item.controls.foreground" type="text">
				</gs-input-color-picker>
			  </div>
			  <div class="px-2 col-lg-2">
				<gs-input-color-picker [customId]="i" label="Background" name="Background" placeholder="Background"
				  formControlName="background" [formControl]="item.controls.background" type="text">
				</gs-input-color-picker>
			  </div>
			</div>
		  </ng-container>
		</ng-container>
	  </div>
	</div> -->
</div>
<div class="modal-footer align-left">
	<div>
		<button (click)="close()" type="button" data-dismiss="modal"
			class="btn btn-outline btn-gray-light min-width">Cancel</button>
		<button [disabled]="seoForm.invalid" (click)="saveData()" type="button" class="btn btn-pink min-width">
			Save
		</button>
	</div>
</div>