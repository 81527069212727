import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, FormControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ComponentModalBase } from '@framework/base/component';
import { Observable, Subscription } from "rxjs";
import { userProfileSelectors } from "@modules/user/store/selectors";
import { Store } from "@ngrx/store";
import { Actions, ofType } from '@ngrx/effects';
import { CoreState } from "@app/store/core/states";
import { UserProfileViewModel } from "@modules/user/store/models";
import { take } from 'rxjs/operators';
import { emailCustomizeSettingSelectors } from '@modules/company-admin/store/selectors';
import {  EmailCustomizeSettingViewModel } from '@modules/company-admin/store/models/store-view-models';
import { emailCustomizeSettingAction } from '@modules/company-admin/store/actions';
import { userActions} from '@modules/company-admin/store/actions';
import { ToastrService } from 'ngx-toastr';
import { UpdateEmailCustomizeSettingInputApiModel } from '@modules/company-admin/store/models/api-models/input';
@Component({
  selector: 'app-invitepeoplemodal',
  templateUrl: './invitepeoplemodal.component.html',
  styleUrls: ['./invitepeoplemodal.component.scss']
})
export class InvitepeoplemodalComponent extends ComponentModalBase<any> {


    // Private variables
    private selectCurrentUserProfile$: Observable<UserProfileViewModel>;

    private selectCurrentUserProfileSubscription: Subscription;
    private selectEmailCustomizeListSubscription: Subscription;
    private createUserSuccessSubscription: Subscription;
    private createUserFailureSubscription: Subscription;
    public saveEmailSuccessSubscription: Subscription;
  
    // Public varibales
    public currentUserProfile: UserProfileViewModel;
    public addMemberForm: UntypedFormGroup;
    public invitationEmail: EmailCustomizeSettingViewModel;
    public shareUrl:any;
    public emailCustomizeList: EmailCustomizeSettingViewModel[];
    public showEditor: boolean = false;
    private selectEmailCustomizeList$: Observable<EmailCustomizeSettingViewModel[]>;
  constructor(private formBuilder: UntypedFormBuilder,  private store$: Store<CoreState>,private actions$: Actions, private toastService: ToastrService) { 

    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.addMemberForm = this.formBuilder.group({
      link:[{value: '',disabled: true}],
      send_invite: [1],
      subject: ['Invitation to join Goodseeker'],
      body: [`<p>Welcome to GoodSeeker.</p><p>GoodSeeker is an application that we use to capture, share and promote examples of good things that make our organization special. Using it is voluntary. We added you to the GoodSeeker directory so that others can easily add you to stories. In order for you to access it, you need to claim your own account. Click below to sign up.</p><p><a href='https://my.goodseeker.com/signup'>https://my.goodseeker.com/user/signup</a></p>`],
      members: this.formBuilder.array([this.createAddPlayerItemForm()])
    });
   
  }

  protected onInit(): void {
    this.shareUrl = this.initialState.url
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.pipe(
      take(1)
    ).subscribe(userProfile => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.store$.dispatch(new emailCustomizeSettingAction.Load(this.currentUserProfile.company.id));
        this.shareUrl += '?sb=' + this.currentUserProfile.id;
      }
    });
    this.selectEmailCustomizeListSubscription = this.actions$.pipe(
      ofType<emailCustomizeSettingAction.LoadSuccess>(emailCustomizeSettingAction.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result: any) => {
      if (result && result.response && result.response.length) {
        this.emailCustomizeList = [...result.response];
        this.invitationEmail = this.emailCustomizeList.find(email => email.title === 'TO A USER - when admin invites them to create account');
        // this.addMemberForm.controls.body.setValue(this.invitationEmail.body)
        if(this.invitationEmail && this.invitationEmail.subject){
          this.addMemberForm.controls.subject.setValue(this.invitationEmail.subject);
          this.addMemberForm.controls.body.setValue(this.invitationEmail.body)
        }
  
      }
    });
    this.saveEmailSuccessSubscription = this.actions$.pipe(
      ofType<emailCustomizeSettingAction.UpdateSuccess>(emailCustomizeSettingAction.ActionTypes.UPDATE_SUCCESS)
    ).subscribe((action: any) => {

      const mappeddata = this.addMemberForm.value.members.map((item)=>{
        return {
          firstname : item.fullname,
          email: item.email,
          send_invite: this.addMemberForm.value.send_invite,
          subject: this.addMemberForm.value.subject,
          body:this.addMemberForm.value.body.replace(/<p><br><\/p>/gi,''),
          guest:this.initialState.inbound
        }
      })
      this.store$.dispatch(new userActions.Create(this.currentUserProfile.company.id, mappeddata));
    });
    this.addMemberForm.controls.link.setValue(this.shareUrl)
  
    this.createUserSuccessSubscription = this.actions$.pipe(
      ofType<userActions.CreateSuccess>(userActions.ActionTypes.CREATE_SUCCESS)
    ).subscribe((action: any) => {
      this.toastService.success('Invitation successfully');
      this.close(true);
    });

    this.createUserFailureSubscription = this.actions$.pipe(
      ofType<userActions.CreateFail>(userActions.ActionTypes.CREATE_FAIL)
    ).subscribe((action: any) => {
      if(action && action.error && action.error?.error && action.error?.error?.result &&  action.error?.error?.result?.errors.length && action.error?.error?.result?.errors[0]?.message){
        this.toastService.error(action.error?.error?.result?.errors[0]?.message);
      }
    });

  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    setTimeout(()=>{
      this.showEditor = true;
    },2000)
    
  }
  protected onAfterViewChecked(): void {

  }

  private createAddPlayerItemForm() {
    return this.formBuilder.group({
      fullname: [''],
      email: ['', [Validators.required, Validators.email]]
    });
  }



  public addPlayerFormItem() {
    this.playersFormArray.push(this.createAddPlayerItemForm());
  }

  get playersFormArray() {
    return this.addMemberForm.get('members') as UntypedFormArray;
  }



  public removeAddPlayerFormItem($event, index) {
    $event.stopPropagation();
    this.playersFormArray.removeAt(index);
  }
  close(data){
    this.onClose.emit(data);
    this.addMemberForm.reset();
  }

  invitePerson(){
    const request: UpdateEmailCustomizeSettingInputApiModel = {
      subject: this.addMemberForm.value.subject,
      body:this.addMemberForm.value.body.replace(/<p><br><\/p>/gi,'')
    }

    // this.store$.dispatch(new userActions.Create(this.currentUserProfile.company.id, mappeddata));
    this.store$.dispatch(new emailCustomizeSettingAction.Update(this.currentUserProfile.company.id, this.invitationEmail.id,request));
  }

}

