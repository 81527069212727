// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as baseEnvironment from './base';

export const environment = {
    production: true,
    https: true,
    baseUrl: 'https://staging.goodseeker.com',
    customUrl: 'staging.goodseeker.com',
    endpointUrl: '/api/v3',
    froalaKey: '2J1B10dE5A5A5C3E3D3cWHNGGDTCWHId1Eb1Oc1Yh1b2Ld1POkE3D3F3B8A4A4B4G3B2G2==',
    apiDomain: 'https://staging.goodseeker.com',
    isProduction: false,
    integrations: {
        slackClientId: '13736529990.164605695457'
    },
    recaptcha: {
        site_key: "6LegldsaAAAAAPmS4zWey__zWnsnwvtoZ9SUcJOo"
    },
    addPipe: {
        accountHash: "2ec51ceb366a28899ff9384240de0f39",
        eid: "fZoIN6"
    },
    firebase: {
        apiKey: "AIzaSyAb7reTllSqjxfC0yMfAShtHTjqTtPbrpc",
        authDomain: "goodseeker-78c9c.firebaseapp.com",
        projectId: "goodseeker-78c9c",
        storageBucket: "goodseeker-78c9c.appspot.com",
        messagingSenderId: "1059298581571",
        appId: "1:1059298581571:web:b87e526193629ce0c688f6",
        measurementId: "G-BVBKS31QGG",
        // vapidKey:'BJa9tqlMCRB4VcPCvd6J-RKXitTqot2bHqcQv5QVrOHUbWw80lZ4GUNlKyEvcYOBGGP0aexhWr_SqPdGbqr0v7k'
        },
    aws: {
        accessId: 'AKIAIJJZJHU3PKGKJ2PA',
        s3: {
            bucketName: 'elasticbeanstalk-us-east-1-534096778644',
            path: 'goodseeker',
            region: 'us-east-1',
            uploadEnpoint: 'https://goodseeker-staging-uploads.s3-accelerate.amazonaws.com',
            processedEnpoint: 'https://goodseeker-staging-processed.s3-accelerate.amazonaws.com'
        },
        cognito: {
            userPoolId: 'us-east-1_KvzECsMBm',
            clientId: '3en26894pcgsnats5srgraen6i'
        },
        oAuth: {
            domain: 'goodseeker.auth.us-east-1.amazoncognito.com',
            redirectUrl: 'https://staging.goodseeker.com/authorize'
        }
    },
    cloudinary: {
        cdnVideoRootUrl: 'https://media.goodseeker.com/video',
        cdnImageRootUrl: 'https://media.goodseeker.com/image',
        convertToNewCdnUrl: baseEnvironment.convertToNewCdnUrl,
        s3RootUrl: 'https://elasticbeanstalk-us-east-1-534096778644.s3.amazonaws.com',
        keyPrefix: 's3'
    },
    analytics: {
        segment: {
            writeKey: '80C9bR3FBo07oQtWgmZRvSJjMV9DFHft'
        },
        userEngage: {
            apiKey: 'YfIMIS',
            js: 'https://goodseeker.user.com/widget.js'
        },
        gist: {
            writeKey: 'n37attwi'
        }
    },
    iframeLaunchUrl: 'https://calendly.com/meetgoodseeker/setup-session',
    phpPrefixHost: '',
    elasticsearch: {
        host: 'https://vpc-goodseeker-staging-rsr56uom3yat3iztlfay2bq4ci.us-east-1.es.amazonaws.com'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
