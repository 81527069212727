import * as addUserActions from './add-user.actions';
import * as campaignActions from './campaign.actions';
import * as landingPageActions from './landing-page.actions';
import * as profilePublicActions from './profile-public.actions';
import * as reportLeadsActions from './report-leads.actions';
import * as storyActions from './story.actions';
import * as saction from './story.actions'
import * as testimonialActions from './testimonial.actions';
import * as userActions from './user.actions';
import * as profileInternalActions from './profile-internal.actions';
import * as testimonialSettingActions from './testimonial-setting.actions';
import * as advocacySettingAction from './advocacy.actions';
import * as socialReviewSettingAction from './social-review.actions';
import * as emailsSettingAction from './emails-setting.actions';
import * as userAttributeSettingAction from './user-setting.action';
import * as emailCustomizeSettingAction from './email-customize-setting.actions';
import * as testimonialAudienceSettingActions from './testimonial-audience-setting.actions';
import * as nudgesSettingActions from './nudges-setting.actions';
import * as integrationSettingActions from './integration.actions';
import * as imageGalleryActions from './image-gallery-setting.actions';
import * as authPageSettingActions from './auth-page-setting.actions';
import * as JobLinkacAction from './job-link.action'
import * as StoryReportsAction from './story-report.actions'
import * as ScriptActions from './script.actions'
import * as PolicyActons from './policy.action'
import * as ApprovalFlowActions  from './approval-flow.actions'
export {
    PolicyActons,
    ApprovalFlowActions,
    addUserActions,
    campaignActions,
    landingPageActions,
    profilePublicActions,
    reportLeadsActions,
    storyActions,
    ScriptActions,
    
    saction,
    testimonialActions,
    userActions,
    profileInternalActions,
    testimonialSettingActions,
    advocacySettingAction,
    socialReviewSettingAction,
    emailsSettingAction,
    userAttributeSettingAction,
    emailCustomizeSettingAction,
    testimonialAudienceSettingActions,
    nudgesSettingActions,
    integrationSettingActions,
    imageGalleryActions,
    authPageSettingActions,
    JobLinkacAction,
    StoryReportsAction
};
