import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { CreateMediaInputApiModel, LoadMediaInputApiModel } from './models/input';
import { ResponseApiModel, ResponsePaginationApiModel } from '@framework/models/api-models';
import { LoadMediaOutputApiModel } from './models/output';

@Injectable()
export class MediaHttpService extends BaseHttpService {

    private endPoints = {
        companies: 'companies',
    };

    constructor(protected http: HttpClient) {
        super(http, '/');
    }

    public load(payload: LoadMediaInputApiModel, companyId: number) {
        let apiUrl: string;
        if (payload.searchFrom && payload.searchText) {
            apiUrl = `app/media/?page=${payload.pagination.page}&per_page=${payload.pagination.limit}&query=${payload.searchText}&type=pixabay`;
            return this.get(apiUrl).pipe(
                map((response: any) => {
                    let mappedResponse = {
                        count: response.total,
                        next: null,
                        prev: null,
                        result: response.hits.map(data => {
                            return {
                                id: data.id,
                                fullsize: data.largeImageURL,
                                preview: data.largeImageURL,
                                type: 'image/jpg',
                                save_in: 2,
                                from: 'pixabay'
                            }
                        })
                    }
                    return mappedResponse;
                })
            );
        } else {
            apiUrl = `${this.endPoints.companies}/${payload.companyId}/setting/media/?${payload.query}`;
            if (payload.isFilterByGlobal) {
                apiUrl = `${this.endPoints.companies}/${companyId}/media/?${payload.query}`;
            }
            return this.get(apiUrl).pipe(
                map((response: ResponsePaginationApiModel<LoadMediaOutputApiModel>) => {
                    return response;
                })
            );
        }
    }

    public create(conpanyId: number, payload: CreateMediaInputApiModel) {
        return this.post(`${this.endPoints.companies}/${conpanyId}/setting/media/`, payload).pipe(
            map((respone: any) => {
                return new LoadMediaOutputApiModel(respone);
            })
        );
    }
}