import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { SendInviteInputApiModel } from '../models/api-models/input';

@Injectable()
export class CampaignHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies/campaigns');
    }

    public sendInvite(campaignId: number, payload: SendInviteInputApiModel) {
        return this.post(`/${campaignId}/invitations/`, payload).pipe(
            map((response) => {
                return response;
            })
        );
    }
}   
