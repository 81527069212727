import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { UserProfileEntity } from '../entities';
import { UserProfileState } from '../states';

export const userProfileAdapter: EntityAdapter<UserProfileEntity> = createEntityAdapter<UserProfileEntity>();

export const initialState: UserProfileState = userProfileAdapter.getInitialState({
    currentUserProfileId: null,
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    isLoadingPublic: false,
    isLoadedPublic: false,
    isUpdating: false
});