import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewEncapsulation,
  OnDestroy
} from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

@Component({
  selector: "ss-code-viewer",
  templateUrl: "./ss-code-viewer.component.html",
  styleUrls: ["./ss-code-viewer.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class SsCodeViewerComponent implements OnInit, OnDestroy {

  // Private
  private _codeBSub$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private _subscription: Subscription;
  // code
  @Input("code")
  set code(val: string) {
    this._codeBSub$.next(val);
  }


  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._subscription = this._codeBSub$.subscribe(val => {
        this._elementRef.nativeElement.innerHTML =
          '<div class="code-wrapper p-3"><pre><code>' +
          val +
          "</code></pre></div>";
      });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
