import { Component, OnInit } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { ComponentModalBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { storyActions } from '@modules/company-admin/store/actions';


@Component({
  selector: 'app-delete-spotlight-container',
  templateUrl: './delete-spotlight-container.component.html',
  styleUrls: ['./delete-spotlight-container.component.css']
})
export class DeleteSpotlightContainerComponent extends ComponentModalBase<any> {

  // Public variables

 public delteSpotlightSubscription: Subscription;
  constructor(private store$: Store<CoreState>, private actions$: Actions) {
    super();
    
  }
  protected onInit(): void {
    this.subscribe()
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  public subscribe(){
    this.delteSpotlightSubscription = this.actions$.pipe(
      ofType<storyActions.DeleteSpotlightSuccess>(storyActions.ActionTypes.DELETE_SPOTLIGHT_SUCCESS)
    ).subscribe(() => {
      this.onClose.emit(false);
    });

  }
  public deleteSpotlight(){
      this.store$.dispatch(new storyActions.DeleteSpotlight(this.initialState.companyid,[this.initialState.spotlightid]));
  }

  Cancel(){
    this.onClose.emit(false)
  }
}
