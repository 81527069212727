export interface UserEmailOutputApiModel {
  id: number;
  email: string;
  status: string;
}

export class UserEmailOutputApiModel {
  constructor(init?: Partial<UserEmailOutputApiModel>) {
    Object.assign(this, init);
  }
}
