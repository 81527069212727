import { WorkAttributeOutputApiModel } from "@modules/company/models/api-models/output";

export interface WorkAttributeValueStoreModel {
    id: number;
    value: string;
}

export interface WorkAttributeStoreModel {
    id: number;
    name: string;
    values: WorkAttributeValueStoreModel[]
}

export class WorkAttributeStoreModel {
    constructor(data?: WorkAttributeOutputApiModel) {
        if (data)
            this.convertFromApi(data);
    }

    private convertFromApi(data: WorkAttributeOutputApiModel) {
        this.id = data.id;
        this.name = data.name;
        this.values = data.values;
    }
}