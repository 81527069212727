import { ThankYouPageSettingOutputApiModel } from "@modules/story/models/api-models/output";

export interface ThankYouPageSettingProfileModel {
    displayName: string;
    title: string;
    companyName: string;
}

export interface ButtonModel {
    text: string;
    link: string;
    color: string;
}

export interface ThankYouPageSettingButtonModel {
    color: string;
    url: string;
    text: string;
    background: string;
    isActive: boolean;
}

export interface ThankYouPageSettingModel {
    image: string;
    profile: ThankYouPageSettingProfileModel;
    title: string;
    content: string;
    buttons: ThankYouPageSettingButtonModel[];
}

export class ThankYouPageSettingModel {
    constructor(data?: ThankYouPageSettingOutputApiModel) {
        if (data)
            this.convertFromApi(data);
    }

    private convertFromApi(data: ThankYouPageSettingOutputApiModel) {
        this.image = data.image;

        if (data.profile) {
            this.profile = {
                title: data.profile.title,
                companyName: data.profile.company_name,
                displayName: data.profile.display_name
            }
        }

        this.title = data.title;
        this.content = data.content;

        if (data.buttons) {
            this.buttons = data.buttons;
        }
    }
}