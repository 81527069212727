import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { DirectoryEntity } from '../entities';
import { DirectoryState } from '../states';

export const directoryAdapter: EntityAdapter<DirectoryEntity> = createEntityAdapter<DirectoryEntity>();

export const initialState: DirectoryState = directoryAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  isFailure: false,
  total: 0,
  prevUrl: null,
  nextUrl: null,
  companyDirectoryIds: [],
  followerDirectoryIds: [],
  followingDirectoryIds: [],
  isCompanyLoaded: false,
  isFollowerLoaded: false,
  isFollowingLoaded: false,
  companyDirectoryTotal: 0,
  followerDirectoryTotal: 0,
  followingDirectoryTotal: 0,
  isCompanyForceLoad: false,
  isFollowerForceLoad: false,
  isFollowingForceLoad: false
});
