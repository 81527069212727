export interface LoadMediaOutputApiModel {
    id: number,
    fullsize: string,
    preview: string,
    type: string,
    save_in: number
    from?: string
    source?: any;
    asset_id?:any;
    url?:any;
    aspect_ratio?:any
    thumbnail_url?:any
}

export class LoadMediaOutputApiModel {
    constructor(init?: Partial<LoadMediaOutputApiModel>) {
        Object.assign(this, init);
    }
}