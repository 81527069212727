import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { companyAdminReducers } from './reducers';
import {
    LandingPageHttpService,
    CampaignHttpService,
    UserHttpService,
    StoryHttpService,
    TestimonialHttpService,
    ReportLeadsHttpService,
    AddUserHttpService,
    ProfilePublicHttpService,
    ProfileInternalHttpService,
    TestimonialSettingHttpService,
    AdvocacySettingHttpService,
    SocialReviewSettingHttpService,
    EmailsSettingHttpService,
    UserSettingHttpService,
    EmailCustomizeSettingHttpService,
    TestimonialAudienceSettingHttpService,
    NudgesSettingHttpService,
    IntegrationSettingHttpService,
    ImageGallerySettingHttpService,
    EngagementReportHttpService,
    AuthPageSettingHttpService,
    JobsHttpService,
    GetUniquelinkService,
    StoryReportHttpService,
    ScriptHttpservice,
    PolicyHttpService,
    ApprovalFlowHttpService
} from './services';
import * as effects from './effects';
import { ChangeUsernameService } from './services/change-user.http-service';
import { GetUniquelinkServiceForspotlightandCampaign } from './services/get-unique-link-spotlight-campaign.http-service';


@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('companyAdminFeature', companyAdminReducers),
        EffectsModule.forFeature([
            effects.AddUserEffects,
            effects.CampaignEffects,
            effects.LandingPageEffects,
            effects.ProfilePublicEffects,
            effects.ProfileInternalEffects,
            effects.ReportLeadsEffects,
            effects.StoryReportEffects,
            effects.StoryEffects,
            effects.TestimonialEffects,
            effects.UserEffects,
            effects.TestimonialSettingEffects,
            effects.AdvocacySettingEffects,
            effects.SocialReviewSettingEffects,
            effects.EmailsSettingEffects,
            effects.UserSettingEffects,
            effects.EmailCustomizeSettingEffects,
            effects.TestimonialAudienceSettingEffects,
            effects.NudgesSettingEffects,
            effects.IntegrationSettingEffects,
            effects.ImageGallerySettingEffects,
            effects.AuthPageSettingEffects,
            effects.ScriptEffect,
            effects.JobLinkEffect,
            effects.PolicyEffect,
            effects.ApprovalFlowEffects
        ])
    ],
    providers: [
        AddUserHttpService,
        CampaignHttpService,
        LandingPageHttpService,
        ProfilePublicHttpService,
        ReportLeadsHttpService,
        StoryReportHttpService,
        StoryHttpService,
        TestimonialHttpService,
        UserHttpService,
        ChangeUsernameService,
        ProfileInternalHttpService,
        TestimonialSettingHttpService,
        AdvocacySettingHttpService,
        SocialReviewSettingHttpService,
        EmailsSettingHttpService,
        UserSettingHttpService,
        ApprovalFlowHttpService,
        EmailCustomizeSettingHttpService,
        TestimonialAudienceSettingHttpService,
        NudgesSettingHttpService,
        IntegrationSettingHttpService,
        ImageGallerySettingHttpService,
        EngagementReportHttpService,
        AuthPageSettingHttpService,
        JobsHttpService,
        PolicyHttpService,
        ScriptHttpservice,
        GetUniquelinkService,
        GetUniquelinkServiceForspotlightandCampaign
    ]
})

export class CompanyAdminStoreModule { }
