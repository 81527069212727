import { Component, SimpleChanges, HostListener, ViewChild, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { ComponentBase } from '@framework/base/component/component.base';
import { CampaignViewModel, CompanyViewModel } from '@modules/company/models/store-view-models';
import { CoreState } from '@app/store/core/states';
import { campaignSelectors, companySelectors } from '@modules/company/store/selectors';
import { campaignActions } from '@modules/company/store/actions';
import { GetCampaignInputApiModel } from '@modules/company/models/api-models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { LoadStatusModel } from '@modules/shared/models';
import { CompanyHttpService } from '@modules/company/services';
import { ModalService } from '@framework/services';
import { ShareModalContainerComponent } from '@modules/shared/components/commons/share-modal-container';
import { storyActions } from '@modules/company-admin/store/actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'co-pb-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent extends ComponentBase {
  public showIndicator: boolean = false;
  public disabled: boolean = false;

  // Private variables
  private selectCampaign$: Observable<CampaignViewModel[]>;
  private selectCurrentCompany$: Observable<CompanyViewModel>;
  private selectCampaignLoadStatus$: Observable<LoadStatusModel>;

  private selectCurrentCompanySubscription: Subscription;
  private selectCampaignSubscription: Subscription;
  private selectCampaignLoadStatusSubscription: Subscription;
  private filterFormChangeSubscription: Subscription;
  private loadColorSuccessSubscription: Subscription;

  private queryCampaignsData: GetCampaignInputApiModel = new GetCampaignInputApiModel(new PaginationModel(10));
  public currentCompany: CompanyViewModel = new CompanyViewModel();
  // Public variables
  public campaigns: CampaignViewModel[] = [];
  public filterForm: UntypedFormGroup;
  public loadCampaignStatus: LoadStatusModel;
  submit_text_Color: any;
  requet_text_Color: any;
  submit_btn: any;
  requt_btn: any;
  toggle_color

  constructor( private modalService: ModalService, private actions$: Actions, private store$: Store<CoreState>, private formBuilder: UntypedFormBuilder, private _companyService: CompanyHttpService) {
    super();
    this.initForm();

    this.selectCurrentCompany$ = this.store$.select(companySelectors.selectSelectedCompany);
    this.selectCampaign$ = this.store$.select(campaignSelectors.selectAll);
    this.selectCampaignLoadStatus$ = this.store$.select(campaignSelectors.selectLoadStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this._companyService.campaignQueryData$.next(null);
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private initForm() {
    this.filterForm = this.formBuilder.group({
      isLoadExpired: [true]
    });
  }

  private subscribe() {
    this.selectCurrentCompanySubscription = this.selectCurrentCompany$.subscribe((currentCompany) => {
      if (currentCompany) {
        this.currentCompany = currentCompany;
        this.store$.dispatch(new storyActions.GetColor(this.currentCompany?.id));
        this.loadCampaigns();
      }
    });

    this.loadColorSuccessSubscription = this.actions$.pipe(
      ofType<storyActions.GetColorSuccess>(storyActions.ActionTypes.GET_BUTTON_COLOR_SUCCESS)
    ).subscribe((action: any) => {
      if(action && action.response && action.response && action.response.length){
        this.submit_text_Color = action.response.find(item => item.button == 10).color;
        this.submit_btn = action.response.find(item => item.button == 7).color;
        this.toggle_color = action.response.find(item => item.button == 4).color;
       }
      

    })

    this.selectCampaignLoadStatusSubscription = this.selectCampaignLoadStatus$.subscribe((loadStatus) => {
      this.loadCampaignStatus = loadStatus;
      if (loadStatus) {
        if (loadStatus.isLoaded && loadStatus.isCanLoadMore) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      }
    });

    this.selectCampaignSubscription = this.selectCampaign$.subscribe((campaigns) => {
      this.campaigns = campaigns;
      this.showIndicator = false;
    });

    this.filterFormChangeSubscription = this.filterForm.valueChanges.subscribe(() => {
      this.queryCampaignsData.pagination.page = 1;
      this.loadCampaigns();
    });
  }

  private unsubscribe() {
    this.selectCurrentCompanySubscription.unsubscribe();
    this.selectCampaignLoadStatusSubscription.unsubscribe();
    this.selectCampaignSubscription.unsubscribe();
    this.filterFormChangeSubscription.unsubscribe();
  }

  private loadCampaigns() {
    this.queryCampaignsData.pagination.sort = 'desc';
    this.queryCampaignsData.isIncludeStories = true;
    this.queryCampaignsData.public = true;
    this.buildFilter();
    this._companyService.campaignQueryData$.next(this.queryCampaignsData);
    this.store$.dispatch(new campaignActions.Load(this.currentCompany.id, this.queryCampaignsData));
  }

  private buildFilter() {
    this.queryCampaignsData.isLoadExpired = this.filterForm.controls.isLoadExpired.value;
  }
  // Public functions
  public trackByFn(campaign: CampaignViewModel) {
    return campaign.id;
  }

  public loadMoreCampaigns() {
    if (this.loadCampaignStatus.isLoaded && this.loadCampaignStatus.isCanLoadMore) {
      this.showIndicator = true;
      this.queryCampaignsData.pagination.page += 1;
      this.loadCampaigns();
    }
  }

  public share() {
    this.modalService.showModal(ShareModalContainerComponent, window.location.href).then(() => { });
  }
}
