import { Directive, ElementRef, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';

@Directive({
    selector: '[autoFocusPrevInputWhenDelete]',
})
export class AutoFocusPrevInputWhenDeleteDirective implements OnChanges {
    @Input() value: number;
    @Input() prevFocusElement: any;
    constructor(private el: ElementRef) {

    }

    public ngOnChanges(values: SimpleChanges) {

    }

    @HostListener('keyup', ['$event'])
    public keyEvent(event: KeyboardEvent) {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 8 || keyCode === 46) {
            if ((!this.value || this.value.toString().length === 0) && this.prevFocusElement) {
                this.prevFocusElement.focus();
                this.prevFocusElement.select();
            }
        }
    }
}