import { Action } from '@ngrx/store';
import { GetProfileInternalInputApiModel, UpdateProfileInternalInputApiModel } from '../models/api-models/input';
import { ProfileInternalOutputApiModel } from '../models/api-models/output';

export enum ActionTypes {
    LOAD_INTERNAL_PROFILE = '[Company Internal Profile] Load',
    LOAD_INTERNAL_PROFILE_SUCCESS = '[Company Internal Profile] Load Success',
    LOAD_INTERNAL_PROFILE_FAILURE = '[Company Internal Profile] Load Failure',

    UPDATE_PROFILE = '[Company Internal Profile] Update Profile',
    UPDATE_PROFILE_SUCCESS = '[Company Internal Profile] Update Profile Success',
    UPDATE_PROFILE_FAILURE = '[Company Internal Profile] Update Profile Failure',

    GET_DOMAIN_NAME = '[Company Internal Profile] Get Domain Name',
    GET_DOMAIN_NAME_SUCCESS = '[Company Internal Profile] Get Domain Name Success',
    GET_DOMAIN_NAME_FAILURE = '[Company Internal Profile] Get Domain Name Failure',

 
    SAVE_DATABASE = '[Company Internal Profile] Save DataBase',
    SAVE_DATABASE_SUCCESS = '[Company Internal Profile] Save DataBase Success',
    SAVE_DATABASE_FAILURE = '[Company Internal Profile] Save DataBase Failure',

     
    VERIFY_SENDGRID = '[Company Internal Profile] Verify Sendgrid',
    VERIFY_SENDGRID_SUCCESS = '[Company Internal Profile] Verify Sendgrid Success',
    VERIFY_SENDGRID_FAILURE = '[Company Internal Profile] Verify Sendgrid Failure',

    GET_DOMAIN_DETAIL = '[Company Internal Profile] Get Domain Detail',
    GET_DOMAIN_DETAIL_SUCCESS = '[Company Internal Profile] Get Domain Detail Success',
    GET_DOMAIN_DETAIL_FAILURE = '[Company Internal Profile] Get Domain Detail Failure',

}
export class LoadInternalProfile implements Action {
    readonly type = ActionTypes.LOAD_INTERNAL_PROFILE;
    payload: Partial<GetProfileInternalInputApiModel>;

    constructor(public companyId: number) {}
}

export class LoadInternalProfileSuccess implements Action {
    readonly type = ActionTypes.LOAD_INTERNAL_PROFILE_SUCCESS;

    constructor(public response: ProfileInternalOutputApiModel) { }
}

export class LoadInternalProfileFailure implements Action {
    readonly type = ActionTypes.LOAD_INTERNAL_PROFILE_FAILURE;

    constructor(public error: any) { }
}

export class UpdateProfile implements Action {
    readonly type = ActionTypes.UPDATE_PROFILE;

    constructor(public companyId: number, public payload: Partial<UpdateProfileInternalInputApiModel>) {
    }
}

export class UpdateProfileSuccess implements Action {
    readonly type = ActionTypes.UPDATE_PROFILE_SUCCESS;
}

export class UpdateProfileFailure implements Action {
    readonly type = ActionTypes.UPDATE_PROFILE_FAILURE;

    constructor(public error: any) { }
}

export class GetDomainName implements Action {
    readonly type = ActionTypes.GET_DOMAIN_NAME;

    constructor(public domainname: any) {
    }
}

export class GetDomainNameSuccess implements Action {
    readonly type = ActionTypes.GET_DOMAIN_NAME_SUCCESS;
    constructor(public response: any) { }
}

export class GetDomainNameFailure implements Action {
    readonly type = ActionTypes.GET_DOMAIN_NAME_FAILURE;

    constructor(public error: any) { }
}


export class GetDomainDetail implements Action {
    readonly type = ActionTypes.GET_DOMAIN_DETAIL;

    constructor(public sendgridid: any) {
    }
}

export class GetDomainDetailSuccess implements Action {
    readonly type = ActionTypes.GET_DOMAIN_DETAIL_SUCCESS;
    constructor(public response: any) { }
}

export class GetDomainDetailFailure implements Action {
    readonly type = ActionTypes.GET_DOMAIN_DETAIL_FAILURE;

    constructor(public error: any) { }
}
export class SaveDatabase implements Action {
    readonly type = ActionTypes.SAVE_DATABASE;

    constructor(public companyId: any,public sendGrididIfd,public payload) {
    }
}

export class SaveDatabaseSuccess implements Action {
    readonly type = ActionTypes.SAVE_DATABASE_SUCCESS;
    constructor(public response: any) { }
}

export class SaveDatabaseFailure implements Action {
    readonly type = ActionTypes.SAVE_DATABASE_FAILURE;

    constructor(public error: any) { }
}


export class VerifySendgrid implements Action {
    readonly type = ActionTypes.VERIFY_SENDGRID;

    constructor(public sendGrididIfd: any) {
    }
}

export class VerifySendgridSuccess implements Action {
    readonly type = ActionTypes.VERIFY_SENDGRID_SUCCESS;
    constructor(public response: any) { }
}

export class VerifySendgridFailure implements Action {
    readonly type = ActionTypes.VERIFY_SENDGRID_FAILURE;

    constructor(public error: any) { }
}



export type Actions = LoadInternalProfile
    | LoadInternalProfileSuccess
    | LoadInternalProfileFailure
    | UpdateProfile
    | UpdateProfileSuccess
    | UpdateProfileFailure
    | GetDomainName
    | GetDomainNameSuccess
    | GetDomainNameFailure
    | SaveDatabase
    | SaveDatabaseSuccess
    | SaveDatabaseFailure
    | VerifySendgrid
    | VerifySendgridSuccess
    | VerifySendgridFailure
    | GetDomainDetail
    | GetDomainDetailSuccess
    | GetDomainNameFailure;
