import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { TestimonialSettingHttpService } from '../services';
import { testimonialSettingActions } from '../actions';


@Injectable()
export class TestimonialSettingEffects {

    constructor(
        private actions$: Actions,
        private TestimonialSettingHttpService: TestimonialSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialSettingActions.Load>(testimonialSettingActions.ActionTypes.LOAD),
        switchMap((action: testimonialSettingActions.Load) => {
            return this.TestimonialSettingHttpService.load(action.companyId).pipe(
                map((respone) => {
                    return new testimonialSettingActions.LoadSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new testimonialSettingActions.LoadFail(error));
                }));
        })
    ));

    
    loadOptionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialSettingActions.LoadOption>(testimonialSettingActions.ActionTypes.LOAD_OPTION),
        switchMap((action: testimonialSettingActions.LoadOption) => {
            return this.TestimonialSettingHttpService.loadOption(action.companyId, action.questionId).pipe(
                map((respone) => {
                    return new testimonialSettingActions.LoadOptionSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new testimonialSettingActions.LoadOptionFail(error));
                }));
        })
    ));

    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialSettingActions.Update>(testimonialSettingActions.ActionTypes.UPDATE),
        switchMap((action: testimonialSettingActions.Update) => {
            return this.TestimonialSettingHttpService.update(action.companyId, action.payload).pipe(
                map(() => {
                    return new testimonialSettingActions.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new testimonialSettingActions.UpdateFailure(error));
                }))
        })
    ));

    
    addOptionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialSettingActions.AddOption>(testimonialSettingActions.ActionTypes.ADD_OPTION),
        switchMap((action: testimonialSettingActions.AddOption) => {
            return this.TestimonialSettingHttpService.addOption(action.companyId, action.questionId, action.payload).pipe(
                map(() => {
                    return new testimonialSettingActions.AddOptionSuccess();
                }),
                catchError((error: any) => {
                    return of(new testimonialSettingActions.AddOptionFailure(error));
                }))
        })
    ));

    
    updateOptionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialSettingActions.UpdateOption>(testimonialSettingActions.ActionTypes.UPDATE_OPTION),
        switchMap((action: testimonialSettingActions.UpdateOption) => {
            return this.TestimonialSettingHttpService.updateOption(action.companyId, action.questionId, action.optionId, action.payload).pipe(
                map(() => {
                    return new testimonialSettingActions.UpdateOptionSuccess();
                }),
                catchError((error: any) => {
                    return of(new testimonialSettingActions.UpdateOptionFailure(error));
                }))
        })
    ));

    
    deleteOptionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<testimonialSettingActions.DeleteOption>(testimonialSettingActions.ActionTypes.DELETE_OPTION),
        switchMap((action: testimonialSettingActions.DeleteOption) => {
            return this.TestimonialSettingHttpService.deleteOption(action.companyId, action.questionId, action.optionId).pipe(
                map(() => {
                    return new testimonialSettingActions.DeleteOptionSuccess();
                }),
                catchError((error: any) => {
                    return of(new testimonialSettingActions.DeleteOptionFailure(error));
                }))
        })
    ));
}