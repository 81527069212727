import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';
import { debounceTime } from 'rxjs/operators';
import { RssFeedHttpService } from '@modules/company/services/rss-feed.http-service';
import { ToastService } from '@framework/services';
import { ofType, Actions } from '@ngrx/effects';
import { storyActions } from '@modules/story/store/actions';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { AuthService } from '@framework/services';

@Component({
  selector: 'change-display-title',
  templateUrl: './change-dispaly-title.component.html',
  styleUrls: ['./change-dispaly-title.component.scss'],
})

export class ChangeUserTitleComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public copyForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public showPreview: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public activeTab: string;
  public fromTrcking: boolean = false;
  public currentUser:any= false;
  private AddtrackingsuccessSubscription: Subscription;
  private rssToken: string;
  public isShow:boolean = false;
  public disabled:boolean = false
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private actions$: Actions,
    private store$: Store<CoreState>,
    private toastService: ToastService,
    private authService: AuthService,
    private rssFeedHttpService: RssFeedHttpService
  ) {
    super();
    this.initForm();

  }

  // Life cycle hook
  protected onInit(): void {

    // this.embedForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
    //   this.showPreview = false;
    //   this.loadScript();
    // })

    this.copyForm.controls.displayTitle.setValue(this.initialState.displayTitle ? this.initialState.displayTitle : '')
    
  }
  protected onDestroy(): void {
    this.AddtrackingsuccessSubscription.unsubscribe();
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {
    // this.loadScript();
  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
   


    // this.copyForm = this.formBuilder.group({
    //   height: [900, Validators.required],
    //   width: [100, Validators.required],
    //   widthList:['px'],
    //   heightList:['px'],
    //   bodyCode: [{ value: '', disabled: true }],
    //   selectCheckbox: ['public', Validators.required],
    //   selectRadio: [false, Validators.required]
    // })

    this.copyForm = this.formBuilder.group({
      displayTitle:['',Validators.maxLength(120)]

    })

    this.AddtrackingsuccessSubscription = this.actions$.pipe(
      ofType<storyActions.changeUserTitleSuccess>(storyActions.ActionTypes.CHANGE_USER_TITLE_SUCCESS)
    ).subscribe((action: any) => {
      this.onClose.emit(true);        

    });


  }

  saveData(){
  let Payload = {
    "display_title":this.copyForm.controls.displayTitle.value
  }
  this.store$.dispatch(new storyActions.changeUserTitle(this.initialState.companyId,this.initialState.uId,Payload))
}


  close(){
    this.onClose.emit(false)
  }

}
