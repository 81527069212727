import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class SocialSharingService {
    // Private variables
    private linkedInShareLink: string = 'https://www.linkedin.com/shareArticle?mini=true&url=';
    private facebookShareLink: string = 'https://www.facebook.com/sharer/sharer.php?u=';
    private twitterShareLink: string = 'https://twitter.com/intent/tweet?text=';
    private messengerShareLink: string = 'https://www.facebook.com/login.php?api_key=&skip_api_login=1&display=page&cancel_url=&next=';
    private whatsAppShareLink: string = 'whatsapp://send?text=';
    private snapChatShareLink: string = '';
    private skypeShareLink: string = 'https://login.live.com/login.srf?wa=wsignin1.0&rpsnv=&ct=&rver=7.1.6819.0&wp=&wreply=&lc=&mkt=en-US&psi=skype&lw=1&cobrandid=&client_flight=';
    private weChatShareLink: string = '';
    private bufferShareLink: string = 'https://buffer.com/add?text=';
    private flipBoardShareLink: string = 'https://share.flipboard.com/bookmarklet/popout?ext=sharethis&title=&url=&utm_campaign=&utm_content=&utm_source=';
    private pinterestShareLink: string = 'https://www.pinterest.com/pin/create/button/?description=';
    private instagramShareLink: string = '';
    private tumblrShareLink: string = '';
    private bloggerShareLink: string = 'https://www.blogger.com/blog-this.g?n=';
    private redditShareLink: string = 'https://www.reddit.com/submit?title=&url=';
    private gmailShareLink: string = '';
    private yahooMailShareLink: string = '';

    private defaultWindowOptions = {
        width: 600,
        location: 1,
        toolbar: 0,
        directories: 0,
        status: 0,
        menubar: 0,
        scrollbars: 0,
        resizable: 0,
        copyhistory: 0
    };
    // Public variables

    constructor(@Inject(DOCUMENT) private document: Document) {

    }

    // Private functions
    private windowParams() {
        let params: any = {
            ...this.defaultWindowOptions
        };

        const root = this.document.documentElement;
        let screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
        let screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
        let outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : root.clientWidth;
        let outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : root.clientHeight - 22;
        screenX = screenX < 0 ? window.screen.width + screenX : screenX;
        params.height = Math.floor(outerHeight * 0.8);
        params.left = Math.floor(screenX + (outerWidth - params.width) / 2);
        params.top = Math.floor(screenY + (outerHeight - params.height) / 2.5);

        return params;
    }

    private serializeOptions(options) {
        let chunks = [];
        for (let prop in options) {
            if (options.hasOwnProperty(prop)) {
                chunks.push(prop + "=" + options[prop]);
            }
        }
        return chunks.join(',');
    };

    private openWindow(title: string, url: string) {
        let windowParams = this.windowParams();
        const popup = window.open(url, title, this.serializeOptions(windowParams));
    }
    // Public functions
    public toLinkedIn(shareContent: string) {
        const shareLink = `${this.linkedInShareLink}${shareContent}`;
        const title = 'Share a link on LinkedIn';
        this.openWindow(title, shareLink);
    }

    public toFacebook(shareContent: string) {
        const shareLink = `${this.facebookShareLink}${shareContent}`;
        const title = 'Share a link on Facebook';
        this.openWindow(title, shareLink);
    }

    public toTwitter(shareContent: string) {
        const shareLink = `${this.twitterShareLink}${shareContent}`;
        const title = 'Share a link on Twitter';
        this.openWindow(title, shareLink);
    }

    public toMessenger(shareContent: string) {
        const shareLink = `${this.messengerShareLink}${shareContent}`;
        const title = 'Share a link on Messenger';
        this.openWindow(title, shareLink);
    }

    public toWhatsApp(shareContent: string) {
        const shareLink = `${this.whatsAppShareLink}${shareContent}`;
        const title = 'Share a link on WhatsApp';
        this.openWindow(title, shareLink);
    }

    public toSnapChat(shareContent: string) {
        const shareLink = `${this.snapChatShareLink}${shareContent}`;
        const title = 'Share a link on SnapChat';
        this.openWindow(title, shareLink);
    }

    public toSkype(shareContent: string) {
        const shareLink = `${this.skypeShareLink}${shareContent}`;
        const title = 'Share a link on Skype';
        this.openWindow(title, shareLink);
    }

    public toWeChat(shareContent: string) {
        const shareLink = `${this.weChatShareLink}${shareContent}`;
        const title = 'Share a link on WeChat';
        this.openWindow(title, shareLink);
    }

    public toBuffer(shareContent: string) {
        const shareLink = `${this.bufferShareLink}${shareContent}`;
        const title = 'Share a link on Buffer';
        this.openWindow(title, shareLink);
    }

    public toFlipBoard(shareContent: string) {
        const shareLink = `${this.flipBoardShareLink}${shareContent}`;
        const title = 'Share a link on FlipBoard';
        this.openWindow(title, shareLink);
    }

    public toPinterest(shareContent: string) {
        const shareLink = `${this.pinterestShareLink}${shareContent}`;
        const title = 'Share a link on Pinterest';
        this.openWindow(title, shareLink);
    }

    public toInstagram(shareContent: string) {
        const shareLink = `${this.instagramShareLink}${shareContent}`;
        const title = 'Share a link on Instagram';
        this.openWindow(title, shareLink);
    }

    public toTumblr(shareContent: string) {
        const shareLink = `${this.tumblrShareLink}${shareContent}`;
        const title = 'Share a link on Tumblr';
        this.openWindow(title, shareLink);
    }

    public toBlogger(shareContent: string) {
        const shareLink = `${this.bloggerShareLink}${shareContent}`;
        const title = 'Share a link on Blogger';
        this.openWindow(title, shareLink);
    }

    public toReddit(shareContent: string) {
        const shareLink = `${this.redditShareLink}${shareContent}`;
        const title = 'Share a link on Reddit';
        this.openWindow(title, shareLink);
    }

    public toGmail(shareContent: string) {
        const shareLink = `${this.gmailShareLink}${shareContent}`;
        const title = 'Share a link on Gmail';
        this.openWindow(title, shareLink);
    }

    public toYahooMail(shareContent: string) {
        const shareLink = `${this.yahooMailShareLink}${shareContent}`;
        const title = 'Share a link on YahooMail';
        this.openWindow(title, shareLink);
    }
}