import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { storyActions } from '@modules/story/store/actions';
import { SegmentAnalyticsService } from '@framework/analytics/segment-analytics/services';
import { companyActions } from '@modules/company/store/actions';
import { UpdateCompanyInputApiModel } from '@modules/company/models/api-models/input';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { CoreState } from '@app/store/core/states';
import { Store } from '@ngrx/store';
import { UserProfileViewModel } from '@modules/user/store/models';

@Injectable()
export class CompanyPageAnalyticsEffects {
    loadStoryEffect$ = createEffect(() => this.actions$.pipe(
        ofType<storyActions.LoadByCompany>(storyActions.ActionTypes.LOAD_BY_COMPANY),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action, userProfile]) => {
            this.loadStory(action.queryData, userProfile);
        })
    ),{ dispatch: false });

    updateCompanyEffect$ = createEffect(() => this.actions$.pipe(
        ofType<companyActions.Update>(storyActions.ActionTypes.UPDATE),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action, userProfile]) => {
            this.updateCompany(action.payload, userProfile);
        })
    ),{ dispatch: false });

   createCompanyEffect$ = createEffect(() => this.actions$.pipe(
        ofType<companyActions.CreateSuccess>(companyActions.ActionTypes.CREATE_SUCCESS),
        withLatestFrom(this.store$.select(userProfileSelectors.selectCurrentProfile)),
        tap(([action,userProfile]) => {

            if (userProfile && userProfile.id && userProfile.email && userProfile.status) {

                this.segmentAnalyticsService.identify(userProfile.id.toString(), {
                    email: userProfile.email,
                    'User Status': this.getUserType(userProfile.status),
                    'Last Seen': Math.round((new Date()).getTime() / 1000),
                    'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
                    'Companyid': userProfile.company.id,
                });
            }
        
            this.segmentAnalyticsService.track('CREATED CO*', {
                payload: action.companyId.payload,
                userId: userProfile.id.toString(),
                url: window.location.href
            });
      })

   ),{ dispatch: false });

    constructor(
        private actions$: Actions,
        private store$: Store<CoreState>,
        private segmentAnalyticsService: SegmentAnalyticsService
    ) {

    }

    getUserType(type: number) {
        switch (type) {
            case 1:
                return 'Invited';
            case 2:
                return 'Named';
            case 3:
                return 'Registered';
        }
    }

    private loadStory(queryData: any, userProfile: UserProfileViewModel) {
        if (userProfile && userProfile.id && userProfile.email && userProfile.status) {

            this.segmentAnalyticsService.identify(userProfile.id.toString(), {
                email: userProfile.email,
                'User Status': this.getUserType(userProfile.status),
                'Last Seen': Math.round((new Date()).getTime() / 1000),
                'User signed up': Math.round((new Date(userProfile.signupDate +'+00:00')).getTime() / 1000),
                'Companyid': userProfile.company.id,
            });
        }
        this.segmentAnalyticsService.track('Load Company Page', {
            payload: queryData,
            userId: userProfile.id.toString(),
            url: window.location.href
        });
    }

    private updateCompany(payload: Partial<UpdateCompanyInputApiModel>, userProfile: UserProfileViewModel) {

    }
}
