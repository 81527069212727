import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
  forwardRef,
  Renderer2,
  ElementRef,
  ViewChildren,
  QueryList,
  Input
} from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gs-verify-input',
  templateUrl: './gs-verify-input.component.html',
  styleUrls: ['./gs-verify-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsVerifyInputComponent),
      multi: true
    }
  ]
})
export class GsVerifyInputComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

  @ViewChildren('verifyCodeInput') verifyCodeInputs: QueryList<ElementRef>;
  @Input() disabled: boolean = false;

  // Private variables
  private formChangeSubscription: Subscription;
  // Public variables
  public dataForm: UntypedFormGroup;
  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  constructor(private formBuilder: UntypedFormBuilder, private renderer: Renderer2, private el: ElementRef) {
    this.initForm();
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  setDisabledState(isDisabled: boolean): void {

  }

  // Private functions
  private initForm() {
    this.dataForm = this.formBuilder.group({
      firstDigit: ['', Validators.required],
      secondDigit: ['', Validators.required],
      thirdDigit: ['', Validators.required],
      fourthDigit: ['', Validators.required],
      fivethDigit: ['', Validators.required],
      sixthDigit: ['', Validators.required]
    });
  }

  private subscribe() {
    this.formChangeSubscription = this.dataForm.valueChanges.subscribe(values => {
      this.value = this.getValue();
      this.triggerChange(this.getValue());
    });
  }

  private unsubscribe() {
    this.formChangeSubscription.unsubscribe();
  }

  private setValue(val: string) {
    if (!val) {
      return;
    }

    const valChars = val.trim().split('');
    const verifyFormControls = {
      0: 'firstDigit',
      1: 'secondDigit',
      2: 'thirdDigit',
      3: 'fourthDigit',
      4: 'fivethDigit',
      5: 'sixthDigit'
    };

    valChars.forEach((value, index) => {
      this.dataForm.controls[verifyFormControls[index]] &&
        this.dataForm.controls[verifyFormControls[index]].setValue(value, { emitEvent: false });
    });
  }

  private getValue(): string {
    if (!this.dataForm.valid) {
      return '';
    }
    return `${this.dataForm.controls.firstDigit.value}${this.dataForm.controls.secondDigit.value}${this.dataForm.controls.thirdDigit.value}${this.dataForm.controls.fourthDigit.value}${this.dataForm.controls.fivethDigit.value}${this.dataForm.controls.sixthDigit.value}`;
  }

  private triggerChange($event) {
    this.fnOnChange($event);
    let event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }
  // Public functions
  public set value(val: string) {
    if (val !== undefined) {
      this.setValue(val);
    }
  }

  public get value() {
    return this.getValue();
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onPaste(e) {
    e.preventDefault();

    const pastedText = e.clipboardData.getData('text');
    if (!pastedText || isNaN(pastedText)) {
      return;
    }
    this.setValue(pastedText);
    this.value = this.getValue();
    this.triggerChange(this.getValue());

    const verifyCodeInputToFocus = this.verifyCodeInputs.filter((element, index) => index === pastedText.length)[0] || this.verifyCodeInputs.last;
    if (verifyCodeInputToFocus) {
      verifyCodeInputToFocus.nativeElement.focus();
    }
  }
}
