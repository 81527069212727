<div class="table-responsive" *ngIf="options">
  <table class="table table-hover">
    <thead>
      <tr>
        <th scope="col" *ngIf="!options.hideFilter">
          <div class="custom-control custom-checkbox">
            <label class="mb-0">
              <input [(ngModel)]="isSelectAll" (ngModelChange)="selectAllChange()" type="checkbox"
                class="custom-control-input"><span class="custom-control-label"></span>
            </label>
          </div>
        </th>
        <th scope="col" *ngFor="let column of options.columns; trackBy: columnTrackByFunc"
          [className]="column.classHeader" [width]="column.width">{{column.label}} <span style="cursor: pointer;" *ngIf="column.isSortable" (click)="sorting(column)"> &nbsp;<i class="fa fa-sort" aria-hidden="true"></i>
          </span></th>
        <th scope="col" *ngIf="options.actions && options.actions.length > 0"></th>
      </tr>
    </thead>
    <tbody *ngIf="!isDragandDrop">
      <ng-container *ngIf="options.data && options.data.length">
        <tr *ngFor="let item of options.data; trackBy: dataTrackByFunc.bind(this)">
          <th scope="row" *ngIf="!options.hideFilter">
            <div class="custom-control custom-checkbox">
              <label class="mb-0">
                <input [(ngModel)]="item.checked" (ngModelChange)="selectChange()" type="checkbox"
                  class="custom-control-input"><span class="custom-control-label"></span>
              </label>
            </div>
          </th>
          <td *ngFor="let column of options.columns; trackBy: columnTrackByFunc"  [className]="column.classBody">
              <app-grid-view-filter *ngIf="column.type =='select' && item.userRole" [showSearchbar]= "showSearchbar" [options]="getRolesGridValue(rolesDataList,item)" (changeRoles)="filter($event,item)"></app-grid-view-filter>
            <app-grid-view-dynamic-field *ngIf="column.type != 'link' && column.type != 'select' && column.type != 'sharelink'"  [type]="column.type" [(ngModel)]="item[column.name]" (ngModelChange)="fieldChange(item, column.name)"></app-grid-view-dynamic-field>
            <app-grid-view-link-field *ngIf="column.type == 'link'" [(ngModel)]="item[column.name]" [href]="assembleLink(item, column.linkData)"></app-grid-view-link-field>
            <app-grid-view-link-field *ngIf="column.type == 'sharelink'" [(ngModel)]="item[column.name]" [sharelink]="true" [href]="getUrl(item, column.linkData)"></app-grid-view-link-field>
            <app-grid-view-link-field *ngIf="column.type == 'spotlightlink'" [(ngModel)]="item[column.name]" [spotlightlink]="true" [href]="getSpotlightUrl(item, column.linkData)"></app-grid-view-link-field>
            <app-grid-view-link-field *ngIf="column.type == 'draftlink'" [(ngModel)]="item[column.name]" [draftLink]="true" [href]="getDraftlink(item, column.linkData)"></app-grid-view-link-field>
  
          </td>
  
          <td class="text-right" *ngIf="options.actions && options.actions.length > 0">
            <div class="dropdown">
              <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                class="btn btn-trans text-gray">
                <svg role="img">
                  <use xlink:href="#icon-more"></use>
                </svg>
              </button>
              <div class="dropdown-menu dropdown-menu-right" [ngClass]="{'menuheight': options && options.data && options.data.length < 7}">
                <ng-container *ngFor="let action of options.actions">
                  <ng-container *ngIf="checkConditionsforlinks(item, action)">
                    <a *ngIf="checkConditionsToHideAction(item, action)" href="javascript:void(0)" class="dropdown-item"
                    (click)="actionOnClick(item, action)">{{action.label}}</a>
                  </ng-container>
  
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="options.data && options.data.length == 0">
        <tr *ngIf="options.data.length === 0">
          <td class="text-center" [attr.colspan]="options.columns.length + 2">
            <span class="my-3">No results found.</span>
          </td>
        </tr>
      </ng-container>

    </tbody>

    <tbody *ngIf="isDragandDrop" cdkDropList (cdkDropListDropped)="onDrop($event)" class="show-cursor">
      <ng-container *ngIf="options.data && options.data.length">
        <tr *ngFor="let item of options.data; trackBy: dataTrackByFunc.bind(this)" cdkDrag >
          <th scope="row" *ngIf="!options.hideFilter">
            <div class="custom-control custom-checkbox">
              <label class="mb-0">
                <input [(ngModel)]="item.checked" (ngModelChange)="selectChange()" type="checkbox"
                  class="custom-control-input"><span class="custom-control-label"></span>
              </label>
            </div>
          </th>
          <td *ngFor="let column of options.columns; trackBy: columnTrackByFunc"  [className]="column.classBody">
              <app-grid-view-filter *ngIf="column.type =='select' && item.userRole" [showSearchbar]= "showSearchbar" [options]="getRolesGridValue(rolesDataList,item)" (changeRoles)="filter($event,item)"></app-grid-view-filter>
            <app-grid-view-dynamic-field *ngIf="column.type != 'link' && column.type != 'select' && column.type != 'sharelink'" [type]="column.type" [(ngModel)]="item[column.name]" (ngModelChange)="fieldChange(item, column.name)"></app-grid-view-dynamic-field>
            <app-grid-view-link-field *ngIf="column.type == 'link'" [(ngModel)]="item[column.name]" [href]="assembleLink(item, column.linkData)"></app-grid-view-link-field>
            <app-grid-view-link-field *ngIf="column.type == 'sharelink'" [(ngModel)]="item[column.name]" [sharelink]="true" [href]="getUrl(item, column.linkData)"></app-grid-view-link-field>
            <app-grid-view-link-field *ngIf="column.type == 'spotlightlink'" [(ngModel)]="item[column.name]" [spotlightlink]="true" [href]="getSpotlightUrl(item, column.linkData)"></app-grid-view-link-field>
            <app-grid-view-link-field *ngIf="column.type == 'draftlink'" [(ngModel)]="item[column.name]" [draftLink]="true" [href]="getDraftlink(item, column.linkData)"></app-grid-view-link-field>
  
          </td>
  
          <td class="text-right" *ngIf="options.actions && options.actions.length > 0">
            <div class="dropdown">
              <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                class="btn btn-trans text-gray">
                <svg role="img">
                  <use xlink:href="#icon-more"></use>
                </svg>
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <ng-container *ngFor="let action of options.actions">
                  <a *ngIf="checkConditionsToHideAction(item, action)" href="javascript:void(0)" class="dropdown-item"
                    (click)="actionOnClick(item, action)">{{action.label}}</a>
                </ng-container>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="options.data && options.data.length == 0">
        <tr *ngIf="options.data.length === 0">
          <td class="text-center" [attr.colspan]="options.columns.length + 2">
            <span class="my-3">No results found.</span>
          </td>
        </tr>
      </ng-container>

    </tbody>



  </table>
</div>
<div class="form-inline" *ngIf="options.totalCount > 0">
  <div class="form-group">
    <select [(ngModel)]="pagination.pageSize" (change)="paginationChange()" class="custom-select mr-2">
      <option *ngFor="let option of pageSizeOptions" [selected]="option.value === pagination.pageSize"
        [value]="option.value">
        {{option.label}}</option>
    </select>
  </div>
  <div class="spacer"></div>
  <div class="form-group">
    <nav aria-label="...">
      <ul class="gs-pagination">
        <li class="gs-page-item" [ngClass]="{'is-disabled': !canPrev}">
          <button class="btn btn-trans" (click)="prevPage()">
            <svg role="img" class="icon16">
              <use xlink:href="#icon-arrow-l"></use>
            </svg>
          </button>
        </li>
        <li class="gs-page-item">
          <input [(ngModel)]="pagination.pageIndex" (change)="paginationChange()" numbersOnly type="text"
            class="form-control">
        </li>
        <li class="gs-page-item"><span class="text-gray">of {{totalPage}}</span></li>
        <li class="gs-page-item" [ngClass]="{'is-disabled': !canNext}">
          <button class="btn btn-trans" (click)="nextPage()">
            <svg role="img" class="icon16">
              <use xlink:href="#icon-arrow-r"></use>
            </svg>
          </button>
        </li>
      </ul>
    </nav>
  </div>
</div>