import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { coreReducer, metaReducers } from './reducers';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterUrlSerializer } from './states';
import { FrameworkModule } from '@framework/framework.module';

@NgModule({
  imports: [
    CommonModule,
    FrameworkModule,    
    StoreModule.forRoot(
        coreReducer,
        {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot()
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: RouterUrlSerializer }
  ],
  declarations: []
})

export class CoreStoreModule {}
