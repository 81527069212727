import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { AdvocacySettingHttpService } from '../services';
import { advocacySettingAction } from '../actions';


@Injectable()
export class AdvocacySettingEffects {

    constructor(
        private actions$: Actions,
        private advocacySettingHttpService: AdvocacySettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<advocacySettingAction.Load>(advocacySettingAction.ActionTypes.LOAD),
        switchMap((action: advocacySettingAction.Load) => {
            return this.advocacySettingHttpService.load(action.companyId).pipe(
                map((respone) => {
                    return new advocacySettingAction.LoadSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new advocacySettingAction.LoadFail(error));
                }));
        })
    ));


    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<advocacySettingAction.Update>(advocacySettingAction.ActionTypes.UPDATE),
        switchMap((action: advocacySettingAction.Update) => {
            return this.advocacySettingHttpService.update(action.companyId, action.advocacyId, action.payload).pipe(
                map(() => {
                    return new advocacySettingAction.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new advocacySettingAction.UpdateFailure(error));
                }))
        })
    ));

    
    addEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<advocacySettingAction.Add>(advocacySettingAction.ActionTypes.ADD),
        switchMap((action: advocacySettingAction.Add) => {
            return this.advocacySettingHttpService.add(action.companyId, action.payload).pipe(
                map(() => {
                    return new advocacySettingAction.AddSuccess();
                }),
                catchError((error: any) => {
                    return of(new advocacySettingAction.AddFailure(error));
                }))
        })
    ));

    
    deleteEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<advocacySettingAction.Delete>(advocacySettingAction.ActionTypes.DELETE),
        switchMap((action: advocacySettingAction.Delete) => {
            return this.advocacySettingHttpService.deleteAdvocacy(action.companyId, action.advocacyId).pipe(
                map(() => {
                    return new advocacySettingAction.DeleteSuccess();
                }),
                catchError((error: any) => {
                    return of(new advocacySettingAction.DeleteFailure(error));
                }))
        })
    ));
}