import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions,createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EmailCustomizeSettingHttpService } from '../services';
import { emailCustomizeSettingAction } from '../actions';


@Injectable()
export class EmailCustomizeSettingEffects {

    constructor(
        private actions$: Actions,
        private emailCustomizeSettingHttpService: EmailCustomizeSettingHttpService
    ) { }

    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<emailCustomizeSettingAction.Load>(emailCustomizeSettingAction.ActionTypes.LOAD),
        switchMap((action: emailCustomizeSettingAction.Load) => {
            return this.emailCustomizeSettingHttpService.load(action.companyId).pipe(
                map((respone) => {
                    return new emailCustomizeSettingAction.LoadSuccess(respone);
                }),
                catchError((error: any) => {
                    return of(new emailCustomizeSettingAction.LoadFail(error));
                }));
        })
    ));


    
    updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<emailCustomizeSettingAction.Update>(emailCustomizeSettingAction.ActionTypes.UPDATE),
        switchMap((action: emailCustomizeSettingAction.Update) => {
            return this.emailCustomizeSettingHttpService.update(action.companyId, action.customizeId, action.payload).pipe(
                map(() => {
                    return new emailCustomizeSettingAction.UpdateSuccess();
                }),
                catchError((error: any) => {
                    return of(new emailCustomizeSettingAction.UpdateFailure(error));
                }))
        })
    ));
}