import { Component, SimpleChanges, ElementRef, Input, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { ComponentBase } from '@framework/base/component/component.base';
import { isPlatformBrowser } from '@angular/common';
import * as masonry from 'masonry-layout';
import * as imagesLoaded from 'imagesloaded';

import { MasonryOptions } from './models';

@Component({
  selector: '[gs-masonry], gs-masonry',
  templateUrl: './gs-masonry.component.html',
  styleUrls: ['./gs-masonry.component.scss'],
})
export class GsMasonryComponent extends ComponentBase {
  // Inputs
  @Input() options: MasonryOptions;
  @Input() useImagesLoaded: boolean = false;
  @Input() totalItem: number;

  // Outputs
  @Output() layoutComplete: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() removeComplete: EventEmitter<any[]> = new EventEmitter<any[]>();

  // Private variables
  private defaultOptions: MasonryOptions = {
    originTop: true,
    percentPosition: false,
    horizontalOrder: true,
    containerStyle: {
      position: 'relative',
      overflow: 'hidden'
    }
  };
  private itemTmps: any[] = [];
  // Public variables
  public mnsr: any;

  constructor(private element: ElementRef, @Inject(PLATFORM_ID) private platformId: any) {
    super();
  }

  // Life cycle hook 
  protected onInit(): void {
    this.initMasonry();
  }
  protected onDestroy(): void {
    this.destroyMasonry();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private initMasonry() {
    // Create masonry options object
    this.options = Object.assign(this.defaultOptions, this.options || {});

    // Set default itemSelector
    if (!this.options.itemSelector) {
      this.options.itemSelector = '[gsMasonryItem], gsMasonryItem';
    }

    if (isPlatformBrowser(this.platformId)) {
      // Initialize Masonry
      this.mnsr = new masonry(this.element.nativeElement, this.options);
      // Bind to events
      this.mnsr.on('layoutComplete', (items: any) => {
        this.layoutComplete.emit(items);
      });
      this.mnsr.on('removeComplete', (items: any) => {
        this.removeComplete.emit(items);
      });
    }
  }

  private destroyMasonry() {
    if (this.mnsr) {
      this.mnsr.destroy();
    }
  }

  private imageOrVideoLoaded(element: HTMLElement, callbackFunc: any) {
    const videoControl = element.getElementsByTagName('video')[0];
    if (videoControl) {
      return videoControl.onloadedmetadata = () => {
        callbackFunc();
      };
    }
    imagesLoaded(element, callbackFunc.bind(this));
  }
  // Public functions
  public layout() {
    setTimeout(() => {
      this.mnsr.layout();
    });
  }

  public reloadItems() {
    setTimeout(() => {
      this.mnsr.reloadItems();
    });
  }

  public add(element: HTMLElement) {
    let isFirstItem = false;

    // Check if first item
    if (this.mnsr.items.length === 0) {
      isFirstItem = true;
    }

    if (this.useImagesLoaded) {
      this.imageOrVideoLoaded(element, (instance: any) => {
        this.itemTmps.push(element);
        if (this.totalItem && this.itemTmps.length >= this.totalItem) {
          this.reloadLayout();
        }
      });
    }

    // Tell Masonry that a child element has been added
    this.mnsr.appended(element);

    // layout if first item
    if (isFirstItem) {
      this.layout();
    }
  }

  public remove(element: HTMLElement) {
    // Tell Masonry that a child element has been removed
    this.mnsr.remove(element);

    // Layout items
    this.layout();
  }

  public reloadLayout() {
    this.layout();
  }
}
