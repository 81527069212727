<div class="comment-chatbox">
  <div class="comment-chatbox__text">
    <textarea #textArea [placeholder]="placeholder" [(ngModel)]="value" (blur)="onBlur($event)"
      (keydown)="onKeydown($event)" (keyup)="onKeyup($event)" class="input" (click)="onClick($event)" [rows]="rows"
      (focus)="onFocus($event)" (ngModelChange)="onChange($event)" required>
    </textarea>
  </div>
  <div class="comment-chatbox__icon" *ngIf="false">
    <button type="button" #emojiShowButton class="btn btn-trans" (click)="toggleEmoji()">
      <i class="far fa-laugh" [hidden]="isShowEmoji"></i>
      <i class="fas fa-times" [hidden]="!isShowEmoji"></i>
    </button>
  </div>
</div>

<div #emojiPopup class="gs-emoji gsFadeInDown" [ngClass]="{'is-active': isShowEmoji}" *ngIf="false">
  <emoji-mart (emojiClick)="addEmoji($event)" [sheetSize]="20" [emojiSize]="22" [autoFocus]="true"
              [showPreview]="false" [emojiTooltip]="false" [exclude]="['recent']" [set]="'twitter'">
  </emoji-mart>
</div>