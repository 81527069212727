import { NgModule, PLATFORM_ID, ModuleWithProviders } from '@angular/core';

import { SegmentConfig } from './models';
import { WindowService, SegmentAnalyticsService } from './services';
import { getWindow } from './factories';
import { SEGMENT_CONFIG } from './inject-tokens';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [
        {
            provide: WindowService,
            useFactory: getWindow,
            deps: [PLATFORM_ID]
        }
    ]
})
export class SegmentAnalyticsModule {
    /**
         * Segment Module Initialisation
         *
         * @param config Segment Configuration
         * @returns Segment Module
         */
    public static forRoot(config?: SegmentConfig): ModuleWithProviders<SegmentAnalyticsModule> {
        return {
            ngModule: SegmentAnalyticsModule,
            providers: [
                {
                    provide: SEGMENT_CONFIG,
                    useValue: config
                },
                SegmentAnalyticsService,
            ],
        };
    }
}