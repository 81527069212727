export interface NotificationViewModel {
    id: number,
    activity: string,
    link: string,
    photo: string,
    read_date: Date,
    sender: string,
    sender_profile: string,
    resource_type: string,
    story_id: any;
    interview_style?:boolean;
    company_slug?:string;
    requested: boolean;
}

export class NotificationViewModel {
    constructor(init?: Partial<NotificationViewModel>) {
        Object.assign(this, init);
    }
}