import { LandingPageState } from '../states';
import { landingPageActions } from '../actions';
import { LandingPageEntity } from '../entities';
import { landingPageAdapter, initialState } from '../adapters/landing-page.adapter';


export function landingPageReducer(state = initialState, action: landingPageActions.Actions): LandingPageState {
    switch (action.type) {

        case landingPageActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case landingPageActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new LandingPageEntity(item));
            const newSate = landingPageAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: action.response.count
            };
        }

        case landingPageActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        default: {
            return state;
        }
    }
}