import { Injectable, Inject } from '@angular/core';

import { CognitoAuthService } from '@framework/cloud-authentication/cognito';

@Injectable()
export class AuthService {
    private gsTokenKey: string = 'GS_TOKEN';
    private rawTokenKey: string = 'RAW_TOKEN';

    public cognito: CognitoAuthService;

    constructor(cognitoAuthService: CognitoAuthService) {
        this.cognito = cognitoAuthService;
    }

    public setGsToken(base64Token: string, rawToken: string) {
        sessionStorage.setItem(this.gsTokenKey, base64Token);
        sessionStorage.setItem(this.rawTokenKey, rawToken);
    }

    public getGsToken() {
        const base64Token = sessionStorage.getItem(this.gsTokenKey);
        const rawToken = sessionStorage.getItem(this.rawTokenKey);
        const currentPath = window.location && window.location.pathname;
        const isValidToken = currentPath && currentPath.indexOf(rawToken) > -1;
        return isValidToken ? base64Token : '';
    }

    public isAuthenticated(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const gsToken = this.getGsToken();
            const currentUser = this.cognito.currentUser();
            if (currentUser) {
                this.cognito.userSession(currentUser).then((session) => {
                    if (session.isValid()) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, (error) => {
                    resolve(false);
                });
            } else if (gsToken) {
                resolve(true);
            }
        });
    }

    public isHasToken(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const gsToken = this.getGsToken();
            if (gsToken) {
                resolve(true);
            } else {
                const currentUser = this.cognito.currentUser();
                this.cognito.userSession(currentUser).then((session) => {
                    if (session.isValid()) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, (error) => {
                    resolve(false);
                });
            }
        });
    }
}