import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { EmailsSettingOutputApiModel } from '../models/api-models/output';
import { UpdateEmailSettingInputApiModel } from '../models/api-models/input';

@Injectable()
export class EmailsSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/emails/`).pipe(
            map((response: EmailsSettingOutputApiModel) => {
                return response;
            })
        )
    }

    public update(companyId: number, payload: Partial<UpdateEmailSettingInputApiModel>) {
        return this.patch(`/${companyId}/setting/emails/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
