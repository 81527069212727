import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { GetAddUserInputApiModel } from '../models/api-models/input';

@Injectable()
export class AddUserHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public add(companyId: number, queryData: GetAddUserInputApiModel) {
      const queryObj = {
        firstname: queryData.firstName,
        lastname: queryData.lastName,
        email: queryData.email,
        send_invite: queryData.send_invite,
        subject: queryData.subject,
        body: queryData.body
      }
      if (!queryData.send_invite) {
        delete queryObj.subject;
        delete queryObj.body;
      }
        // return this.post(`/${companyId}/setting/user/?${queryData.query}`, {}).pipe(
        return this.post(`/${companyId}/setting/user/`, queryObj).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
