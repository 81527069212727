import { userReducer } from './user.reducer';
import { userProfileReducer } from './user-profile.reducer';
import { userSettingReducer } from './user-setting.reducer';
import { directoryReducer } from './directory.reducer';
import { userEmailReducer } from './user-email.reducer';
import { userPermissionReducer } from './user-permission.reducer';

export const userReducers = {
    user: userReducer,
    userProfile: userProfileReducer,
    userSetting: userSettingReducer,
    directory: directoryReducer,
    userEmail: userEmailReducer,
    userPermission:userPermissionReducer
};
