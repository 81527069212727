import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ComponentBase } from '@framework/base/component/component.base';
import { NotificationViewModel } from '@modules/shared/store/models';
import { LoadNotificationInputApiModel } from '@app/store/http/models/input';
import { PaginationModel } from '@framework/models/pagination-models';
import { ScrollbarComponent } from '@framework/components';
import { CoreState } from '@app/store/core/states';
import { notificationSelector } from '@modules/shared/store/selectors';
import { notificationActions } from '@modules/shared/store/actions';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { Actions, ofType } from "@ngrx/effects";

@Component({
  selector: 'site-notification',
  templateUrl: './site-notification.component.html',
  styleUrls: ['./site-notification.component.scss']
})
export class SiteNotificationComponent extends ComponentBase {

  // Private variables
  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectNotification$: Observable<NotificationViewModel[]>;
  private selectNotificationCount$: Observable<number>;
  private selectNotificationCountUnread$: Observable<number>;
  private selectNotificationLoading$: Observable<boolean>;
  private selectNotificationCleared$: Observable<boolean>;
  private selectNotificationClearing$: Observable<boolean>;

  private selectCurrentUserProfileSubscription: Subscription;
  private selectNotificationSubscription: Subscription;
  private selectNotificationCountSubscription: Subscription;
  private selectNotificationCountUnreadSubscription: Subscription;
  private selectNotificationLoadingSubscription: Subscription;
  private selectNotificationClearedSubscription: Subscription;
  private selectNotificationClearingSubscription: Subscription;
  private selectNotificationClearingSuccessSubscription: Subscription;

  private loadNotificationInputData: LoadNotificationInputApiModel = new LoadNotificationInputApiModel(new PaginationModel(10));

  // Public variables
  public currentUserProfile: UserProfileViewModel;
  public notifications: NotificationViewModel[] = [];
  public loadMore: boolean = true;
  public viewmodel = {
    userId: 0,
    count: 0,
    countUnread: 0,
    totalPage: 0,
    scrollbarVisible: false,
    loading: false,
    cleared: false,
    clearing: false,
  };

  @ViewChild('scrollbar')
  scrollbar: ScrollbarComponent;

  constructor(
    private store$: Store<CoreState>, private actions$: Actions
  ) {
    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
    this.selectNotification$ = this.store$.select(notificationSelector.selectNotifications);
    this.selectNotificationCount$ = this.store$.select(notificationSelector.selectCount);
    this.selectNotificationCountUnread$ = this.store$.select(notificationSelector.selectCountUnread);
    this.selectNotificationLoading$ = this.store$.select(notificationSelector.selectLoading);
    this.selectNotificationCleared$ = this.store$.select(notificationSelector.selectCleared);
    this.selectNotificationClearing$ = this.store$.select(notificationSelector.selectClearing);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.currentUserProfile = userProfile;
        this.viewmodel.userId = userProfile.id;
        this.loadNotificationInputData.userId = this.currentUserProfile.id
        this.loadNotification();
      }
    });

    this.selectNotificationSubscription = this.selectNotification$.subscribe((notifications) => {
      if (notifications) {
        this.notifications = notifications;
      }
    });

    this.selectNotificationCountSubscription = this.selectNotificationCount$.subscribe((count) => {
      if (count) {
        this.viewmodel.totalPage = Math.ceil(count / this.loadNotificationInputData.pagination.limit);
      }
      this.viewmodel.count = count;
    });

    this.selectNotificationCountUnreadSubscription = this.selectNotificationCountUnread$.subscribe((countUnread) => {
      this.viewmodel.countUnread = countUnread;
    });

    this.selectNotificationLoadingSubscription = this.selectNotificationLoading$.subscribe((loading) => {
      this.viewmodel.loading = loading;
    });

    this.selectNotificationClearingSubscription = this.selectNotificationClearing$.subscribe((clearing) => {
      this.viewmodel.clearing = clearing;
    });

    this.selectNotificationClearedSubscription = this.selectNotificationCleared$.subscribe((cleared) => {
      this.viewmodel.cleared = cleared;
    });

    this.selectNotificationClearingSuccessSubscription = this.actions$.pipe(
      ofType<notificationActions.ClearSuccess>(notificationActions.ActionTypes.CLEAR_SUCCESS)
    ).subscribe(res => {
      this.viewmodel.countUnread = 0;
    });

  }

  private unsubscribe() {
    this.selectCurrentUserProfileSubscription.unsubscribe();
    this.selectNotificationSubscription.unsubscribe();
    this.selectNotificationCountSubscription.unsubscribe();
    this.selectNotificationLoadingSubscription.unsubscribe();
    this.selectNotificationClearingSubscription.unsubscribe();
    this.selectNotificationClearedSubscription.unsubscribe();
    this.selectNotificationCountUnreadSubscription.unsubscribe();
    this.selectNotificationClearingSuccessSubscription.unsubscribe();
  }

  private loadNotification() {
    this.store$.dispatch(new notificationActions.Load(this.loadNotificationInputData));
  }

  // Public functions
  public clearAll() {
    if (this.viewmodel.userId) {
      this.store$.dispatch(new notificationActions.Clear(this.viewmodel.userId));
    }
  }

  public makeAsRead(notification: NotificationViewModel) {
    // Redirect directly to link
    // window.location.href = notification.link;
    if(notification.read_date == null)
    this.store$.dispatch(new notificationActions.MakeAsRead(notification.id));
  }

  public onPositionChanged(e) {
    this.viewmodel.scrollbarVisible = true;
  }

  public scrollReachEnd(e) {
    if (this.loadMore && this.viewmodel.scrollbarVisible) {
      if (this.loadNotificationInputData.pagination.page < this.viewmodel.totalPage) {
        this.loadNotificationInputData.pagination.page += 1;
        this.loadNotificationInputData.selectedValues = [];
        this.loadNotification();
      }
    }
  }
}
