import { initialState, notificationAdapter } from '../adapters';
import { NotificationState } from '../states';
import { notificationActions } from '../actions';
import { NotificationEntity } from '../entities';

export function notificationReducer(state = initialState, action: notificationActions.Actions): NotificationState {
    switch (action.type) {

        case notificationActions.ActionTypes.LOAD: {
            return {
                ...state,
                loaded: false,
                loading: true,
                query: action.payload
            };
        }

        case notificationActions.ActionTypes.LOAD_SUCCESS: {
            const total = action.response.count;
            const totalUnread = action.response.count_unread;
            const entities = action.response.result ? action.response.result.map(item => new NotificationEntity(item)) : [];
            return {
                ...notificationAdapter.addMany(entities, state),
                loaded: true,
                loading: false,
                count: total,
                countUnread: totalUnread,
            };
        }

        case notificationActions.ActionTypes.LOAD_FAILURE: {
            return {
                ...state,
                loaded: false,
                loading: false
            };
        }

        case notificationActions.ActionTypes.CLEAR: {
            return {
                ...state,
                cleared: false,
                clearing: true
            };
        }

        case notificationActions.ActionTypes.CLEAR_SUCCESS: {
            return {
                ...notificationAdapter.removeAll(state),
                cleared: true,
                clearing: false,
                loaded: false,
                count: 0
            };
        }

        case notificationActions.ActionTypes.CLEAR_FAILURE: {
            return {
                ...state,
                cleared: false,
                clearing: false
            };
        }

        case notificationActions.ActionTypes.MAKE_AS_READ: {
            return {
                ...state,
            };
        }

        case notificationActions.ActionTypes.MAKE_AS_READ_SUCCESS: {
            const entity = state.entities[action.id];
            entity.readDate = new Date();
            const newState = Object.assign(notificationAdapter.upsertOne(entity, state), {
                countUnread: state.countUnread - 1,
                selectedId: action.id
            });
            return {
                ...newState
            };
        }

        case notificationActions.ActionTypes.MAKE_AS_READ_FAILURE: {
            return {
                ...state,
            };
        }

        default: {
            return state;
        }
    }
}
