export interface RouterParamsViewModel {
  storyId: number;
  stepId: number;
  isPreviewMode: boolean;
  campaign_id_slug: number | string;
}

export class RouterParamsViewModel {
  constructor() {
    this.parseValues();
  }

  private parseValues() {
    this.storyId = this.storyId ? this.storyId : null;
    this.stepId = this.stepId ? this.stepId : null;
    this.isPreviewMode = this.isPreviewMode ? this.isPreviewMode : null;
    this.campaign_id_slug = this.campaign_id_slug ? this.campaign_id_slug : null;
  }
}
