import { Component, OnInit } from '@angular/core';
import { ComponentModalBase } from '@framework/base';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-rss-feed-link-modal',
  templateUrl: './rss-feed-link-modal.component.html',
  styleUrls: ['./rss-feed-link-modal.component.css']
})
export class RssFeedLinkModalComponent extends ComponentModalBase<any> {
  public rssForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    super();
    this.initForm();
  }

  protected onInit(): void {
    if (this.initialState) {
      this.rssForm.controls.link.setValue(this.initialState);
    }
  }

  protected onDestroy(): void {
  }

  protected onChanges(): void {
  }

  protected onDoCheck(): void {
  }

  protected onAfterContentInit(): void {
  }

  protected onAfterContentChecked(): void {
  }

  protected onAfterViewInit(): void {
  }

  protected onAfterViewChecked(): void {
  }

  private initForm() {
    this.rssForm = this.formBuilder.group({
      link: ['']
    });
  }

  close() {
    this.onClose.emit();
  }
}
