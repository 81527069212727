import { Component, OnInit } from '@angular/core';
import { withLatestFrom } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { ComponentModalBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { storyActions } from '@modules/company-admin/store/actions';


@Component({
  selector: 'app-delete-campaign-container',
  templateUrl: './delete-campaign-container.component.html',
  styleUrls: ['./delete-campaign-container.component.css']
})
export class DeleteCampaignContainerComponent extends ComponentModalBase<any> {

  // Public variables

 public delteStoryandCampaignsSubscription: Subscription;
  constructor(private store$: Store<CoreState>, private actions$: Actions) {
    super();
    this.delteStoryandCampaignsSubscription = this.actions$.pipe(
      ofType<storyActions.DeleteCmapignandStorySuccess>(storyActions.ActionTypes.DELETE_CAMPAIGN_STORY_SUCCESS)
    ).subscribe(() => {
      this.onClose.emit(false);
    });
  }
  protected onInit(): void {
    this.subscribe()
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  public subscribe(){

  }
  public deletecampaignandStory(){
    if(this.initialState.fromcampaign){
      this.store$.dispatch(new storyActions.DeleteCmapignandStory(this.initialState.companyid,[this.initialState.campaignid],true));
    }
    else{
      this.deletedraftcampaign();
    }
   
  }

  public deletecampaign(){
    if(this.initialState.fromcampaign){
      this.store$.dispatch(new storyActions.DeleteCmapignandStory(this.initialState.companyid,[this.initialState.campaignid],false));
    }
    else{
      this.onClose.emit(false);
    }
    
  }

  public deletedraftcampaign(){
    this.store$.dispatch(new storyActions.DeleteCmapignandStory(this.initialState.companyid,[this.initialState.campaignid],false));
  }
}
