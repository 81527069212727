import { Directive, EventEmitter, Output, ElementRef, OnDestroy } from '@angular/core';

@Directive({
    selector: '[appMotationObserver]',
})
export class MotationObserverDirective implements OnDestroy {
    public observer: MutationObserver;

    @Output() innerHtmlRendered = new EventEmitter();

    constructor(private el: ElementRef) {
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation, index) => {
                if (mutation.type === 'childList') {
                    this.innerHtmlRendered.emit();
                }
            });
        });

        this.observer.observe(this.el.nativeElement,
            { attributes: true, childList: true, characterData: true }
        );
    }

    ngOnDestroy(): void {
        if (this.observer)
            this.observer.disconnect();
    }

}