import { Component, OnInit, ViewChild } from '@angular/core';
import { ComponentModalBase } from '@framework/base';
import { storyActions, testimonialActions } from '@modules/company-admin/store/actions';
import { Actions, ofType } from '@ngrx/effects';
import { Observable, Subscription } from 'rxjs';
import { withLatestFrom, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CoreState } from '@app/store/core/states';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ScrollbarComponent } from '@framework/components';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { storyStepActions } from '@modules/story/store/actions';
@Component({
  selector: 'app-notesmodal',
  templateUrl: './notesModal.component.html',
  styleUrls: ['./notesModal.component.css']
})
export class NotesmodalComponent extends ComponentModalBase<any> {


  private selectCurrentUserProfile$: Observable<UserProfileViewModel>;
  private selectCurrentUserProfileSubscription: Subscription;
  private updateFailureSubscription: Subscription;
  private updateSuccessSubscription: Subscription;
  private CreateNotesSUbscription: Subscription;
  private companyId: number;
  private loadQuestionsSubscription: Subscription;
  private deleteSuccessSubscription: Subscription;
  public form: UntypedFormGroup
  public allnotes=[];
  public noteId:number = 0;
  public isUpdate:boolean = false;
  public index:number =0;
  public isupdating: boolean =false
  
  

  
  @ViewChild('scrollbar')
  scrollbar: ScrollbarComponent;
  constructor(  private store$: Store<CoreState>, private formBuilder: UntypedFormBuilder,
    private actions$: Actions,) {
    super();
    this.selectCurrentUserProfile$ = this.store$.select(userProfileSelectors.selectCurrentProfile);
  this.initForm();
  }

  protected onInit(): void { 


    this.subscribe()
  }

  private initForm() {
    this.form = this.formBuilder.group({
      notes: ['',Validators.required]
    });

  }


  protected onDestroy(): void { 
  this.selectCurrentUserProfileSubscription.unsubscribe();
  this.updateFailureSubscription.unsubscribe();
  this.updateSuccessSubscription.unsubscribe();
  this.CreateNotesSUbscription.unsubscribe();
  this.loadQuestionsSubscription.unsubscribe();
  this.deleteSuccessSubscription.unsubscribe();
  }

  protected onChanges(): void { }

  protected onDoCheck(): void { }

  protected onAfterContentInit(): void { }

  protected onAfterContentChecked(): void { }

  protected onAfterViewInit(): void { }

  protected onAfterViewChecked(): void { }

  public subscribe(){

    this.selectCurrentUserProfileSubscription = this.selectCurrentUserProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.companyId = userProfile.company.id;
        this.store$.dispatch(new storyStepActions.LoadNote(this.companyId,this.initialState.id))
      }
    });



    this.updateFailureSubscription = this.actions$.pipe(
      ofType<storyStepActions.UpdatNoteFailure>(storyStepActions.ActionTypes.UPDATE_NOTE_FAIL)
    ).subscribe(() => {
      this.store$.dispatch(new storyStepActions.LoadNote(this.companyId,this.initialState.id));
      this.isupdating = false
    });

    this.updateSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.UpdatNoteSuccess>(storyStepActions.ActionTypes.UPDATE_NOTE_SUCCESS)
    ).subscribe(() => {
      this.store$.dispatch(new storyStepActions.LoadNote(this.companyId,this.initialState.id));
      this.isupdating = false
    });

    this.CreateNotesSUbscription = this.actions$.pipe(
      ofType<storyStepActions.CreateNoteSuccess>(storyStepActions.ActionTypes.CREATE_NOTE_SUCCESS)
    ).subscribe(() => {
      this.store$.dispatch(new storyStepActions.LoadNote(this.companyId,this.initialState.id));
      this.isupdating = false
    });

    this.loadQuestionsSubscription = this.actions$.pipe(
    ofType<storyStepActions.LoadNoteSuccess>(storyStepActions.ActionTypes.LOAD_NOTE_SUCCESS)).
      subscribe((notes: any) => {
        this.isupdating = false
       if( notes && notes.data && notes.data.results && notes.data.results.length){
      
        this.allnotes = notes.data.results
       }
       else{
        this.allnotes = [];
       }
    })

    this.deleteSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.DeletNoteSuccess>(storyStepActions.ActionTypes.DELETE_NOTE_SUCCESS)
    ).subscribe(() => {
      this.store$.dispatch(new storyStepActions.LoadNote(this.companyId,this.initialState.id));

    });
  }


  public noemailtoall() {
  
  }
  public save() {
    this.isupdating = true;
    if(!this.isUpdate){
      let push = {
        notes: this.form.controls.notes.value
      }
      let obj = {note_text:this.form.controls.notes.value,id_story: this.initialState.id}
      this.store$.dispatch(new storyStepActions.CreateNote(this.companyId,obj))
      this.form.reset();
  
      // this.allnotes.push(push)
    }
    else{
      this.isUpdate = false;
      this.allnotes[this.index].notes = this.form.controls.notes.value
      let obj = {note_text:this.form.controls.notes.value}
      this.store$.dispatch(new storyStepActions.UpdatNote(this.companyId,this.noteId,obj))
      this.form.reset();
    }

  }

  updateStory(i,item){
    this.isUpdate = true;
    this.index = i;
    this.noteId = item.id
    this.form.controls.notes.setValue(this.allnotes[this.index].note_text)
 
  }
  updateStoryFetch(){
     
  }


  getText(item): string{
     if(item){
      if(item.created_by && item.updated_by){
        return 'CreatedBy:'+' '+item.created_by?.display_name+' '+'& UpdatedBy:'+' '+item.updated_by?.display_name;
      }
      else if(item.created_by && !item.updated_by){
        return 'CreatedBy:'+' '+item.created_by?.display_name;
      }
      else {
        return null
       }
     }
  }

  deleteStory(i,item){
    this.isupdating = true;
    this.store$.dispatch(new storyStepActions.DeletNote(this.companyId,item.id));
    this.form.reset();
  }
 

  close(){
    this.onClose.emit(false);
  }
}
