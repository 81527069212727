<div class="modal-header">
  <h5 class="modal-title">{{initialState.title}}</h5>
  <button type="button" (click)="cancel()" class="close"><span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span></button>
</div>
<div class="modal-body" [innerHtml]="initialState.message"></div>
<div class="modal-footer">
  <button type="button" (click)="cancel()"
    class="btn btn-outline btn-gray-light min-width">{{initialState.cancelButtonText}}</button>
  <button [style.background]="primary_color ? primary_color : ''" type="button" (click)="confirm()" class="btn btn-pink min-width">{{initialState.yesButtonText}}</button>
</div>