<div class="story-item mouse_pointer" (mouseover)="changeHoverColor()"
(mouseout)="resetHoverColor()" [style.backgroundColor]="dynamicHoverColor"  [ngClass]="{'inbound': story.type === 'inbound', 'story': story.type != 'inbound'}">
  <div class="story-item__wrap">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="story-item__header" [style.background]="GuestLables ? GuestLables : ''" *ngIf="story.type === 'inbound' && GuestLables && story?.authorInboundType">
          <div class="blurb">From {{story.authorInboundType}}</div>
        </div>
        <div class="story-item__header" [style.background]="userLables ? userLables : ''" *ngIf="story.type != 'inbound' && userLables && story?.authorInboundType">
          <div class="blurb">From {{story.authorInboundType}}</div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div data-btn [routerLink]="href" [queryParams]="queryParamsDeatil()" *ngIf="story.media && story.media.length && story.media[0].type.indexOf('video') === -1"
          class="story-item__thumb">
          <img [src]="story.media[0].fullsize" />
        </div>
        <div data-btn [routerLink]="href" [queryParams]="queryParamsDeatil()" *ngIf="story.media && story.media.length && story.media[0].type.indexOf('video') > -1"
          class="story-item__video">
          <!-- <video-control autoPlay="true" [src]="story.media.fullsize" start="0" end="5"></video-control> -->
          <img [src]="getUrl(story.media[0])" />
        </div>
      </div>
      <div class="col-lg-9 col-md-8 align-self-end">
        <div class="story-item__conts">
          <h2 class="story-item__title" data-btn [routerLink]="href" [queryParams]="queryParamsDeatil()">{{story.title}}</h2>
          <div class="story-item__info">
            <div class="story-item__user px-0 col-12">
              <gs-avatar [src]="story.author.avatar" isBackground="true" classCss="avatar avatar--xs">
              </gs-avatar>
              <div class="name text-nowrap">{{story.author.displayName}}</div>
            </div>
          </div>
          <div class="story-item__tags  d-lg-block" *ngIf="story.highlightTags">
            <a *ngFor="let tag of story.highlightTags" href="javascript:void(0)" class="tag"
              [style.color]="tag.color">#{{tag.text}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="story-item__detail" (mouseover)="changeHoverColor()"
  (mouseout)="resetHoverColor()" [style.backgroundColor]="dynamicHoverColor" >
    <a [routerLink]="href" [queryParams]="queryParamsDeatil()">
      <div class="story-item__detail-wrap">
        <div class="story-item__detail-desc" *ngIf="story.media && story.media.length">
          <p [innerText]="story.content | stripHtml"></p>
        </div>
        <div class="story-item__detail-player" *ngIf="story.contributors">
          <div class="player-item" *ngFor="let player of story.contributors">
            <div class="player-item__avatar">
              <gs-avatar [src]="player.avatar" [title]="player.displayName" isBackground="true"
                classCss="avatar avatar--md">
              </gs-avatar>
            </div>
          </div>
        </div>
        <div class="story-item__detail-more">
          <a class="btn btn-white btn-md btn-outline">Read More</a>
        </div>
      </div>
    </a>
  </div>
</div>