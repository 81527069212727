import { EmailCustomizeSettingOutputApiModel } from "../models/api-models/output";

export interface EmailCustomizeSettingEntity {
    id: number;
    title: string;
    subject: string;
    body: string;
    isSelected: boolean;
    email_user_type: string;
}

export class EmailCustomizeSettingEntity {
    constructor(data?: EmailCustomizeSettingOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: EmailCustomizeSettingOutputApiModel) {
        this.id = data.id;
        this.title = data.title;
        this.subject = data.subject;
        this.body = data.body;
        this.isSelected = data.is_active;
        this.email_user_type = data.email_user_type
    }

}
