import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { AddUserEntity } from '../entities';
import { AddUserState } from '../states';

export const addUserAdapter: EntityAdapter<AddUserEntity> = createEntityAdapter<AddUserEntity>();

export const initialState: AddUserState = addUserAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});