import { initialState, storyCtaTemplateAdapter } from '../adapters/story-cta-template.adapter';
import { StoryCtaTemplateState } from '../states';
import { Actions, StoryCtaTemplateActions } from '../actions/story-cta-template.action';
import { StoryCtaTemplateEntity } from '@modules/shared/entities';

export function storyCtaTemplateReducer(state = initialState, action: Actions): StoryCtaTemplateState {
  switch (action.type) {

    case StoryCtaTemplateActions.LOAD: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case StoryCtaTemplateActions.LOAD_SUCCESS: {
      const entities = action.data.map(item => new StoryCtaTemplateEntity(item));
      return {
          ...storyCtaTemplateAdapter.addMany(entities, state),
        loaded: true,
        loading: false
      };
    }

    case StoryCtaTemplateActions.LOAD_FAILURE: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    case StoryCtaTemplateActions.RESET_STATE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}
