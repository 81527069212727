export class StoryStepEntity {
    public id: number;
    public sort: number;
    public title: string;
    public text: string;
    public type: string;
    public color: string;
    public icon: string;
    public tags: Array<any>;
    
    constructor(init?: Partial<StoryStepEntity>) {
        Object.assign(this, init);
    }
}