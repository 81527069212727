import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, Output, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from "@app/env/environment.ci";

import * as Quill from 'quill';

let Font = Quill.import('formats/font');
Font.whitelist = ['arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'];
Quill.register(Font, true);

@Component({
  selector: 'gs-area-wysiwyg',
  templateUrl: './gs-area-wysiwyg.component.html',
  styleUrls: ['./gs-area-wysiwyg.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GsAreaWysiwygComponent),
      multi: true
    }
  ]
})
export class GsAreaWysiwygComponent implements ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChild('textArea')
  textArea: ElementRef;
  // Inputs
  @Input() label: string;
  @Input() labelSmall: string;
  @Input() isEditable: boolean = false
  @Input() name: string;
  @Input() placeholder: string;
  @Input() formControl: UntypedFormControl;
  @Input() cssClass: string;
  @Input() hideErrorMessage: boolean = false;
  @Input() rows: number;

  // Outputs
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();

  // Private variables
  private inputValue: string;

  // Public variables
  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  quillModule = {
    toolbar: [
      ['bold',  'italic',  'underline',  'color', { 'align': [] },'blockquote', 'link', 'image', 'video', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }, { 'font': ['arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'] }, { 'header': [1, 2, 3, false] }, 'clean']
      //['blockquote', 'link'],
      //[{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //[{ 'indent': '-1' }, { 'indent': '+1' }],
      //[{ 'align': [] }],
      //[{ 'size': ['small', false, 'large'] }],
      //[{ 'header': [1, 2, 3, false] }],
      //['image'],
      //[{ 'font': ['arial', 'Roboto', 'cursive', 'fantasy', 'monospace', 'sans-serif', 'serif', 'times-new-roman'] }],
      //['clean']
    ]
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {

  }

  ngOnChanges() {

  }

  private triggerChange($event) {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  // Public methods

  public set value(val: string) {
    if (val !== undefined) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public writePlaceholder(val: string): void {
    this.placeholder = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange() {
    this.triggerChange(this.inputValue);
  }

}
