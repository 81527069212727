export interface TagOutputApiModel {
    id: number,
    name: string,
    description: string
}

export class TagOutputApiModel {
    constructor(init?: Partial<TagOutputApiModel>) {
        Object.assign(this, init);
    }
}