<div class="site-container">
    <!-- START HEADER-->
    <app-main-header></app-main-header>

    <div class="col-lg-8 offset-lg-2 mt-4">

        <h4> Approval Table</h4>

<table>
    <thead>
        <tr>
            <th>ID</th>
            <th>Story Title</th>
            <th>Status</th>
            <th>Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of items">
            <td>{{ item.id }}</td>
            <td [routerLink]="[ '/company/this-is-a-title-for-guest-story/23/approval' ]" class="text-blue-light">{{ item.name }}</td>
            <td [ngClass]="{'approved': item.status === 'Approved', 'rejected': item.status === 'Rejected'}">{{ item.status }}</td>
            <td>
                <span class="action-icon approved" (click)="updateStatus(item, 'Approved')"><i class="fa-duotone fa-solid fa-circle-check"  style="color: #58ca72;"></i></span>
                <span class="action-icon rejected" (click)="updateStatus(item, 'Rejected')"><i class="fa-solid fa-circle-xmark" style="color: #c3094c;"></i></span>
            </td>
        </tr>
    </tbody>
</table>


        
    </div>

</div>











