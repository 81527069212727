import { Action } from '@ngrx/store';

import { StoryOutputApiModel } from '../models/api-models/output';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetStoryInputApiModel } from '../models/api-models/input';


export enum ActionTypes {
    LOAD = '[Company Admin Story] Load',
    LOAD_SUCCESS = '[Company Admin Story] Load Success',
    LOAD_FAIL = '[Company Admin Story] Load Fail',

    LOAD_INTERNAL_REQUESTED = '[Company Admin Story] Load Internal Requested',
    LOAD_INTERNAL_REQUESTED_SUCCESS = '[Company Admin Story] Load Internal Requested Success',
    LOAD_INTERNAL_REQUESTED_FAIL = '[Company Admin Story] Load Internal Requested Fail',

    LOAD_GUEST_REQUESTED = '[Company Admin Story] Load Guest Requested',
    LOAD_GUEST_REQUESTED_SUCCESS = '[Company Admin Story] Load Guest Requested Success',
    LOAD_GUEST_REQUESTED_FAIL = '[Company Admin Story] Load Guest Requested Fail',

    MAKE_ACTIVE = '[Company Admin Story] Make Active',
    MAKE_ACTIVE_SUCCESS = '[Company Admin Story] Make Active Success',
    MAKE_ACTIVE_FAIL = '[Company Admin Story] Make Active Fail',

    MAKE_INACTIVE = '[Company Admin Story] Make Inactive',
    MAKE_INACTIVE_SUCCESS = '[Company Admin Story] Make Inactive Success',
    MAKE_INACTIVE_FAIL = '[Company Admin Story] Make Inactive Fail',

    TURN_PUBLIC_ON = '[Company Admin Story] Make Public',
    TURN_PUBLIC_ON_SUCCESS = '[Company Admin Story] Make Public Success',
    TURN_PUBLIC_ON_FAIL = '[Company Admin Story] Make Public Fail',

    TURN_PUBLIC_OFF = '[Company Admin Story] Make Internal',
    TURN_PUBLIC_OFF_SUCCESS = '[Company Admin Story] Make Internal Success',
    TURN_PUBLIC_OFF_FAIL = '[Company Admin Story] Make Internal Fail',

    TURN_INTERNAL_ON = '[Company Admin Story] Make Visible',
    TURN_INTERNAL_ON_SUCCESS = '[Company Admin Story] Make Visible Success',
    TURN_INTERNAL_ON_FAIL = '[Company Admin Story] Make Visible Fail',

    TURN_INTERNAL_OFF = '[Company Admin Story] Make Invisible',
    TURN_INTERNAL_OFF_SUCCESS = '[Company Admin Story] Make Invisible Success',
    TURN_INTERNAL_OFF_FAIL = '[Company Admin Story] Make Invisible Fail',

    EDIT = '[Company Admin Story] Edit',
    EDIT_SUCCESS = '[Company Admin Story] Edit Success',
    EDIT_FAIL = '[Company Admin Story] Edit Fail',

    LINK_TO_CAMPAIGN = '[Company Admin Story] Link to Campaign',
    LINK_TO_CAMPAIGN_SUCCESS = '[Company Admin Story] Link to Campaign Success',
    LINK_TO_CAMPAIGN_FAIL = '[Company Admin Story] Link to Campaign Fail',

    SEND_THANK_YOU_EMAIL = '[Send Thank you Email] Send Thank you Email',
    SEND_THANK_YOU_EMAIL_SUCCESS = '[Send Thank you Email] Send Thank you Email Success',
    SEND_THANK_YOU_EMAIL_FAIL = '[Send Thank you Email] Send Thank you Email Fail',


    UPDATE_REMINDER_MAIL = '[Company Admin email customize setting] Update Reminder',
    UPDATE_REMINDER_MAIL_SUCCESS = '[Company Admin email customize setting] Update Reminder Success',
    UPDATE_REMINDER_MAIL_FAIL = '[Company Admin email customize setting] Update Reminder Fail',

    ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN = '[Company Admin Story] Add Unique Link For Spotlight and  Campaign',
    ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN_SUCCESS = '[Company Admin Story] Add Unique Link Success',
    ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN_FAIL = '[Company Admin Story] Add Unique Link Fail',

    TURN_APPROVAL_OFF = '[Company Admin Story] Approval',
    TURN_APPROVAL_OFF_SUCCESS = '[Company Admin Story] Approval Success',
    TURN_APPROVAL_OFF_FAIL = '[Company Admin Story] Approval Fail',


    REJECT = '[Company Admin Story] Make Reject',
    REJECT_SUCCESS = '[Company Admin Story] Reject Success',
    REJECT_FAIL = '[Company Admin Story] Reject Fail',

    REJECT_SPOTLIGHT_ONLY = '[Company Admin Story] Make Reject Spotlight Only',
    REJECT_SPOTLIGHT_ONLY_SUCCESS = '[Company Admin Story] Make Reject Spotlight Only Success',
    REJECT_SPOTLIGHT_ONLY_FAIL = '[Company Admin Story] Make Reject Spotlight Only Fail',

    APPROVE_SPOTLIGHT_ONLY = '[Company Admin Story] Approve Spotlight Only',
    APPROVE_SPOTLIGHT_ONLY_SUCCESS = '[Company Admin Story] Approve Spotlight Only Success',
    APPROVE_SPOTLIGHT_ONLY_FAIL = '[Company Admin Story] Approve Spotlight Only Fail',


    DELETE_CAMPAIGN_STORY = "[Story] Delete campaign and story",
    DELETE_CAMPAIGN_STORY_SUCCESS = "[Story] Delete campaign and story success",
    DELETE_CAMPAIGN_STORY_FAILURE = "[Story] Delete campaign and story fail",
  
    DELETE_SPOTLIGHT = "[Story] Delete spotlight",
    DELETE_SPOTLIGHT_SUCCESS = "[Story] Delete spotlight success",
    DELETE_SPOTLIGHT_FAILURE = "[Story] Delete spotlight fail" ,

    ADD_UNIQUE_LINK = '[Company Admin Story] Add Unique Link',
    ADD_UNIQUE_LINK_SUCCESS = '[Company Admin Story] Add Unique Link Success',
    ADD_UNIQUE_LINK_FAIL = '[Company Admin Story] Add Unique Link Fail',

    ADD_UNIQUE_VIDEO_LINK = '[Company Admin Story] Add Unique Video Link',
    ADD_UNIQUE_VIDEO_LINK_SUCCESS = '[Company Admin Story] Add Unique Video Link Success',
    ADD_UNIQUE_VIDEO_LINK_FAIL = '[Company Admin Story] Add Unique Video Link Fail',


    UPDATE_COLUMN_ORDER = '[Company Admin Order Campaign] update column',
    UPDATE_COLUMN_ORDER_SUCCESS = '[Company Admin Order Campaign] update column Success',
    UPDATE_COLUMN_ORDER_FAIL = '[Company Admin Order Campaign] update column Fail',


    UPDATE_BUTTON_COLOR = '[Company Admin Button Color] update button and text color',
    UPDATE_BUTTON_COLOR_SUCCESS = '[Company Admin Button Color] update button and text color Success',
    UPDATE_BUTTON_COLOR_FAIL = '[Company Admin Button Color] update button and text color Fail',


    GET_BUTTON_COLOR = '[Company Admin Button Color] Get button and text color',
    GET_BUTTON_COLOR_SUCCESS = '[Company Admin Button Color] Get button and text color Success',
    GET_BUTTON_COLOR_FAIL = '[Company Admin Button Color] Get button and text color Fail',

    GET_MASTER_ACCESS = '[Company Video Master Access] Get Master Access',
    GET_MASTER_ACCESS_SUCCESS = '[Company Video Master Access] Get Master Access Success',
    GET_MASTER_ACCESS_FAIL = '[Company Video Master Access] Get Master Access Fail',

    EXPORT_STORIES = '[Stories] Export Stories',
    EXPORT_STORIES_SUCCESS = '[Stories] Export Stories Success',
    EXPORT_STORIES_FAILURE = '[Stories] Export Stories Failure',

    EXPORT_INTERNAL_STORIES = '[Company Admin Story] Export Internal Stories',
    EXPORT_INTERNAL_STORIES_SUCCESS = '[Company Admin Story] Export Internal Stories Success',
    EXPORT_INTERNAL_STORIES_FAILURE = '[Company Admin Story] Export Internal Stories Failure',

    EXPORT_GUEST_STORIES = '[Company Admin Story] Export Guest Stories',
    EXPORT_GUEST_STORIES_SUCCESS = '[Company Admin Story] Export Guest Stories Success',
    EXPORT_GUEST_STORIES_FAILURE = '[Company Admin Story] Export Guest Stories Failure'
}



export class UpdateColumn implements Action {
    readonly type = ActionTypes.UPDATE_COLUMN_ORDER;

    constructor(public companyId: number, public updateList) { }
}

export class UpdateColumnSuccess implements Action {
    readonly type = ActionTypes.UPDATE_COLUMN_ORDER_SUCCESS;
}

export class UpdateColumnFailure implements Action {
    readonly type = ActionTypes.UPDATE_COLUMN_ORDER_FAIL;

    constructor(public error: any) { }
}

export class GetMaster implements Action {
    readonly type = ActionTypes.GET_MASTER_ACCESS;

    constructor(public companyId: number, public payload) { }
}

export class GetMasterSuccess implements Action {
    readonly type = ActionTypes.GET_MASTER_ACCESS_SUCCESS;
}

export class GetMasterFailure implements Action {
    readonly type = ActionTypes.GET_MASTER_ACCESS_FAIL;

    constructor(public error: any) { }
}

export class UpdateColor implements Action {
    readonly type = ActionTypes.UPDATE_BUTTON_COLOR;

    constructor(public companyId: number, public id, public payload) { }
}

export class UpdateColorSuccess implements Action {
    readonly type = ActionTypes.UPDATE_BUTTON_COLOR_SUCCESS;
}

export class UpdateColorFailure implements Action {
    readonly type = ActionTypes.UPDATE_BUTTON_COLOR_FAIL;

    constructor(public error: any) { }
}

export class GetColor implements Action {
    readonly type = ActionTypes.GET_BUTTON_COLOR;

    constructor(public companyId: number | string) { }
}

export class GetColorSuccess implements Action {
    readonly type = ActionTypes.GET_BUTTON_COLOR_SUCCESS;

    constructor(public response: any) { }
}

export class GetColorFailure implements Action {
    readonly type = ActionTypes.GET_BUTTON_COLOR_FAIL;

    constructor(public error: any) { }
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number, public queryData: GetStoryInputApiModel) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: ResponsePaginationApiModel<StoryOutputApiModel>) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class LoadInternalRequested implements Action {
    readonly type = ActionTypes.LOAD_INTERNAL_REQUESTED;

    constructor(public companyId: number, public queryData: GetStoryInputApiModel) { }
}

export class LoadInternalRequestedSuccess implements Action {
    readonly type = ActionTypes.LOAD_INTERNAL_REQUESTED_SUCCESS;

    constructor(public response: any) { }
}

export class LoadInternalRequestedFail implements Action {
    readonly type = ActionTypes.LOAD_INTERNAL_REQUESTED_FAIL;

    constructor(public error: any) { }
}

export class LoadGuestRequested implements Action {
    readonly type = ActionTypes.LOAD_GUEST_REQUESTED;

    constructor(public companyId: number, public queryData: GetStoryInputApiModel) { }
}

export class LoadGuestRequestedSuccess implements Action {
    readonly type = ActionTypes.LOAD_GUEST_REQUESTED_SUCCESS;

    constructor(public response: any) { }
}

export class LoadGuestRequestedFail implements Action {
    readonly type = ActionTypes.LOAD_GUEST_REQUESTED_FAIL;

    constructor(public error: any) { }
}

export class AddUniqueLink implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_LINK;

    constructor(public payload, public fromvideo) { }
}

export class AddUniqueLinkSuccess implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_LINK_SUCCESS;

    constructor(public response: any) { }
}

export class AddUniqueLinkFail implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_LINK_FAIL;

    constructor(public error: any) { }
}


export class AddUniqueVideoLink implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_VIDEO_LINK;

    constructor(public payload, public fromvideo) { }
}

export class AddUniqueLinkVideoSuccess implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_VIDEO_LINK_SUCCESS;

    constructor(public response: any) { }
}

export class AddUniqueLinkVideoFail implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_VIDEO_LINK_FAIL

    constructor(public error: any) { }
}

export class AddUniqueLinkSpotlightandCampaign implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN;

    constructor(public payload, public fromSpptlight) { }
}

export class AddUniqueLinkSpotlightandCampaignSuccess implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN_SUCCESS;

    constructor(public response: any) { }
}

export class AddUniqueLinkSpotlightandCampaignFail implements Action {
    readonly type = ActionTypes.ADD_UNIQUE_LINK_SPOTLIGHT_CAMPAIGN_FAIL;

    constructor(public error: any) { }
}

export class MakeActive implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class MakeActiveSuccess implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE_SUCCESS;
}

export class MakeActiveFail implements Action {
    readonly type = ActionTypes.MAKE_ACTIVE_FAIL;

    constructor(public error: any) { }
}

export class MakeInactive implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE;

    constructor(public companyId: number, public adminId: number, public ids: number[], public draft?:boolean) { }
}


export class MakeInactiveSuccess implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE_SUCCESS;
}

export class MakeInactiveFail implements Action {
    readonly type = ActionTypes.MAKE_INACTIVE_FAIL;

    constructor(public error: any) { }
}

export class TurnPublicOn implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON;

    constructor(public companyId: number, public adminId: number, public ids: number[], public emailOption: any) { }
}

export class TurnPublicOnSuccess implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON_SUCCESS;
}

export class TurnPublicOnFail implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_ON_FAIL;

    constructor(public error: any) { }
}

export class TurnPublicOff implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class TurnPublicOffSuccess implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF_SUCCESS;
}

export class TurnPublicOffFail implements Action {
    readonly type = ActionTypes.TURN_PUBLIC_OFF_FAIL;

    constructor(public error: any) { }
}

export class TurnInternalOn implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_ON;

    constructor(public companyId: number, public ids: number[]) { }
}

export class TurnInternalOnSuccess implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_ON_SUCCESS;
}

export class TurnInternalOnFail implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_ON_FAIL;

    constructor(public error: any) { }
}

export class TurnInternalOff implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF;

    constructor(public companyId: number, public ids: number[]) { }
}

export class TurnInternalOffSuccess implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF_SUCCESS;
}

export class TurnInternalOffFail implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF_FAIL;

    constructor(public error: any) { }
}

export class Edit implements Action {
    readonly type = ActionTypes.EDIT;

    constructor(public companyId: number, public ids: number[]) { }
}

export class EditSuccess implements Action {
    readonly type = ActionTypes.EDIT_SUCCESS;
}

export class EditFail implements Action {
    readonly type = ActionTypes.EDIT_FAIL;

    constructor(public error: any) { }
}

export class LinkToCampaign implements Action {
    readonly type = ActionTypes.LINK_TO_CAMPAIGN;

    constructor(public companyId: number, public campaignId: number, public id: number) { }
}

export class LinkToCampaignSuccess implements Action {
    readonly type = ActionTypes.LINK_TO_CAMPAIGN_SUCCESS;
}

export class LinkToCampaignFail implements Action {
    readonly type = ActionTypes.LINK_TO_CAMPAIGN_FAIL;

    constructor(public error: any) { }
}

export class SendThankYouEmail implements Action {
    readonly type = ActionTypes.SEND_THANK_YOU_EMAIL;

    constructor(public storyId: any, public emailTemplate: any) { }
}

export class SendThankYouEmailSuccess implements Action {
    readonly type = ActionTypes.SEND_THANK_YOU_EMAIL_SUCCESS;
}

export class SendThankYouEmailFail implements Action {
    readonly type = ActionTypes.SEND_THANK_YOU_EMAIL_FAIL;

    constructor(public error: any) { }
}

export class UpdateReminder implements Action {
    readonly type = ActionTypes.UPDATE_REMINDER_MAIL;

    constructor(public storyId: any, public payload: any) { }
}

export class UpdateReminderSuccess implements Action {
    readonly type = ActionTypes.UPDATE_REMINDER_MAIL_SUCCESS;
}

export class UpdateReminderFail implements Action {
    readonly type = ActionTypes.UPDATE_REMINDER_MAIL_FAIL;

    constructor(public error: any) { }
}

export class Approval implements Action {
    readonly type = ActionTypes.TURN_APPROVAL_OFF;

    constructor(public companyId: number, public adminId: number, public ids: number[],public sendEmail: boolean,public sendTo: number,public payload: any) { }
}

export class ApprovalSuccess implements Action {
    readonly type = ActionTypes.TURN_APPROVAL_OFF_SUCCESS;
}

export class ApprovalFail implements Action {
    readonly type = ActionTypes.TURN_INTERNAL_OFF_FAIL;

    constructor(public error: any) { }
}

export class Reject implements Action {
    readonly type = ActionTypes.REJECT;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class RejectSuccess implements Action {
    readonly type = ActionTypes.REJECT_SUCCESS;
}

export class RejectFail implements Action {
    readonly type = ActionTypes.REJECT_FAIL;

    constructor(public error: any) { }
}

export class RejectSpotlightOnly implements Action {
    readonly type = ActionTypes.REJECT_SPOTLIGHT_ONLY;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class RejectSpotlightOnlySuccess implements Action {
    readonly type = ActionTypes.REJECT_SPOTLIGHT_ONLY_SUCCESS;
}

export class RejectSpotlightOnlyFail implements Action {
    readonly type = ActionTypes.REJECT_SPOTLIGHT_ONLY_FAIL;

    constructor(public error: any) { }
}

export class ApproveSpotlightOnly implements Action {
    readonly type = ActionTypes.APPROVE_SPOTLIGHT_ONLY;

    constructor(public companyId: number, public adminId: number, public ids: number[]) { }
}

export class ApproveSpotlightOnlySuccess implements Action {
    readonly type = ActionTypes.APPROVE_SPOTLIGHT_ONLY_SUCCESS;
}

export class ApproveSpotlightOnlyFail implements Action {
    readonly type = ActionTypes.APPROVE_SPOTLIGHT_ONLY_FAIL;

    constructor(public error: any) { }
}

export class DeleteCmapignandStory implements Action {
    readonly type = ActionTypes.DELETE_CAMPAIGN_STORY;
  
    constructor(public comanyid: any,public campignid: any[] , public deleteStory:boolean) { }
  }
  
  export class DeleteCmapignandStorySuccess implements Action {
    readonly type = ActionTypes.DELETE_CAMPAIGN_STORY_SUCCESS;
  }
  
  export class DeleteCmapignandStoryFailure implements Action {
    readonly type = ActionTypes.DELETE_CAMPAIGN_STORY_FAILURE;
    constructor(public error: any) { }
  }
  
  export class DeleteSpotlight implements Action {
    readonly type = ActionTypes.DELETE_SPOTLIGHT;
  
    constructor(public comanyid: any,public spotlightId: any[]) { }
  }
  
  export class DeleteSpotlightSuccess implements Action {
    readonly type = ActionTypes.DELETE_SPOTLIGHT_SUCCESS;
  }
  
  export class DeleteSpotlightFailure implements Action {
    readonly type = ActionTypes.DELETE_SPOTLIGHT_FAILURE;
    constructor(public error: any) { }
  }

  export class ExportStories implements Action {
    readonly type = ActionTypes.EXPORT_STORIES;
  
    constructor(public companyId: number, public queryData: GetStoryInputApiModel) {}
  }
  
  export class ExportStoriesSuccess implements Action {
    readonly type = ActionTypes.EXPORT_STORIES_SUCCESS;
  
    constructor() {}
  }
  
  export class ExportStoriesFailure implements Action {
    readonly type = ActionTypes.EXPORT_STORIES_FAILURE;
  
    constructor(public error: any) {}
  }

  export class ExportInternalStories implements Action {
    readonly type = ActionTypes.EXPORT_INTERNAL_STORIES;
  
    constructor(public companyId: number, public queryData: GetStoryInputApiModel) {}
  }
  
  export class ExportInternalStoriesSuccess implements Action {
    readonly type = ActionTypes.EXPORT_INTERNAL_STORIES_SUCCESS;
  
    constructor() {}
  }
  
  export class ExportInternalStoriesFailure implements Action {
    readonly type = ActionTypes.EXPORT_INTERNAL_STORIES_FAILURE;
  
    constructor(public error: any) {}
  }

  export class ExportGuestStories implements Action {
    readonly type = ActionTypes.EXPORT_GUEST_STORIES;
  
    constructor(public companyId: number, public queryData: GetStoryInputApiModel) {}
  }
  
  export class ExportGuestStoriesSuccess implements Action {
    readonly type = ActionTypes.EXPORT_GUEST_STORIES_SUCCESS;
  
    constructor() {}
  }
  
  export class ExportGuestStoriesFailure implements Action {
    readonly type = ActionTypes.EXPORT_GUEST_STORIES_FAILURE;
  
    constructor(public error: any) {}
  }
  



export type Actions =
    Load | LoadSuccess | LoadFail |
    MakeActive | MakeActiveSuccess | MakeActiveFail |
    MakeInactive | MakeInactiveSuccess | MakeInactiveFail |
    TurnPublicOn | TurnPublicOnSuccess | TurnPublicOnFail |
    TurnPublicOff | TurnPublicOffSuccess | TurnPublicOffFail |
    TurnInternalOn | TurnInternalOnSuccess | TurnInternalOnFail |
    TurnInternalOff | TurnInternalOffSuccess | TurnInternalOffFail |
    Edit | EditSuccess | EditFail |
    LinkToCampaign | LinkToCampaignSuccess | LinkToCampaignFail | SendThankYouEmail | SendThankYouEmailSuccess | SendThankYouEmailFail
    | AddUniqueLink | AddUniqueLinkSuccess | AddUniqueLinkFail
    | Approval | ApprovalSuccess | ApprovalFail
    | Reject | RejectSuccess | RejectFail
    | DeleteCmapignandStory | DeleteCmapignandStorySuccess | DeleteCmapignandStoryFailure
    | DeleteSpotlight | DeleteSpotlightSuccess | DeleteSpotlightFailure
    | AddUniqueLinkSpotlightandCampaign | AddUniqueLinkSpotlightandCampaignSuccess | AddUniqueLinkSpotlightandCampaignFail
    | ApproveSpotlightOnly | ApproveSpotlightOnlySuccess | ApproveSpotlightOnlyFail
    | RejectSpotlightOnly | RejectSpotlightOnlySuccess | RejectSpotlightOnlyFail
    | UpdateColumn | UpdateColumnSuccess | UpdateColumnFailure
    | AddUniqueVideoLink | AddUniqueLinkVideoSuccess | AddUniqueLinkVideoFail;
