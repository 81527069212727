import { Component, SimpleChanges, ChangeDetectorRef, ViewChild, ElementRef, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { PaginationModel } from '@framework/models/pagination-models';
import { CoreState } from '@app/store/core/states';
import { UtilityHelper } from '@framework/utilities';
import { Actions, ofType } from '@ngrx/effects';
import { ComponentModalBase } from '@framework/base';
import { ModalService, ToastService } from '@framework/services';
import { Router } from '@angular/router';
import { PolicyActons } from '@modules/company-admin/store/actions';

declare var $:any
@Component({
  selector: 'app-policy-create',
  templateUrl: './policy-create.component.html',
  styleUrls: ['./policy-create.component.scss'],

})
export class PolicyCreateComponent extends ComponentModalBase<any> {
  public policyForm: UntypedFormGroup;
  public ctaTemplates: Array<any>;
  public mentionRequired: boolean = false;
  public showPopup: boolean = false;
  public cta:any;
  public jobs:any[] =[];

  public selctedJobs: any[] = [];
  public showPromoteJobs:boolean = false;
  public showAuthorInformation:boolean = false;
  public templateActivedId:any;
  public primaryColor:any;
  public showJobslist: any[] = []
  public loadColorSuccessSubscription: Subscription;
  private loadSpotlightJlinkobsSubscription: Subscription;
  public jobIds:any;
  private  loadJobsSubscription: Subscription;
  private loadJlinkobsSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store$: Store<CoreState>,
    private detector: ChangeDetectorRef,
    private actions$: Actions,
    private utility: UtilityHelper,
    private modalService: ModalService,
    private router: Router,

    private actions: Actions,
    private toastService: ToastService,
  ) {
    super();


    this.initForm();
  }

  private initForm() {
    this.policyForm = this.formBuilder.group({
      policy_name:[],
      policy_slug:['yourpolicy',Validators.pattern('^[^/]*$')],
      link: [{ value: '', disabled: true }],
      policyTemplateHtml:[],
      isCampaign:[false],
      isRegister:[false],
      isDefault:[false],
      
    })
  }



  protected onInit(): void {
    this.policyForm.controls.link.setValue(window.location.origin+'/'+this.policyForm.controls.policy_slug.value)
      this.policyForm.controls.policy_slug.valueChanges.subscribe((res)=>{
        
        this.policyForm.controls.link.setValue(window.location.origin+'/'+this.policyForm.controls.policy_slug.value)

      })
  }
  protected onDestroy(): void {
    this.unSubscribe();
  }

  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {
    // if(typeof (<any>window).addthis !== 'undefined') { (<any>window).addthis.layers.refresh(); }

  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
    // $(document).on('click','#cancel-request-btn',function(event){
    //   close()
    // })

  }

  protected onAfterViewChecked(): void {

  }

  private subscribe() {
  }


   
  private unSubscribe(){
    this.loadJlinkobsSubscription.unsubscribe();
    this.loadJobsSubscription.unsubscribe();
    this.loadSpotlightJlinkobsSubscription.unsubscribe();
  }


  save(){
 if(this.initialState.type == 'CREATE'){
 this.store$.dispatch(new PolicyActons.AddPolicy(this.initialState.companyId, this.policyForm.value))
 }
 else{
  let obj = this.policyForm.value;
  obj.id = this.initialState.item.id
  this.store$.dispatch(new PolicyActons.UpdatePolicy(this.initialState.companyId, this.policyForm.value))
 }
  }

  checkPolicySlug(){
    this.store$.dispatch(new PolicyActons.checkPolicySlug(this.initialState.companyId, this.policyForm.controls.policy_slug))
  }
  cancel(){
    this.onClose.emit(false)
  }
}
