import { testimonialSettingActions } from "../actions";
import { TestimonialSettingState } from "../states";
import { TestimonialSettingEntity } from "../entities";
import { initialState } from "../adapters/testimonial-setting.adapter";

export function testimonialSettingReducer(state = initialState, action: testimonialSettingActions.Actions): TestimonialSettingState {
    switch (action.type) {

        case testimonialSettingActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case testimonialSettingActions.ActionTypes.LOAD_SUCCESS: {

            const result: TestimonialSettingEntity = new TestimonialSettingEntity(action.response);
            return {
                ...state,
                thankyouPageData: result.settingData ? result.settingData.thankyouPage : null,
                questionsPageData: result.settingData ? [...result.settingData.questions] : null,
                isLoading: false,
                isLoaded: true
            };
        }

        case testimonialSettingActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}