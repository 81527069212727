import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { sharedReducers } from './reducers';
import * as effects from './effects';
import { LikeHttpService, FollowHttpService, AppSettingHttpService, CommentHttpService } from '../services';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('sharedFeature', sharedReducers),
        EffectsModule.forFeature([
            effects.NotificationEffect,
            effects.AppSettingEffects,
            effects.LikeEffects,
            effects.FollowEffects,
            effects.CommentEffects
        ])
    ],
    providers: [
        LikeHttpService,
        FollowHttpService,
        AppSettingHttpService,
        CommentHttpService
    ]
})
export class SharedStoreModule { }
