import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, switchMap, filter, map, take } from 'rxjs/operators';

import { CoreState } from '@app/store/core/states';
import { UtilityHelper } from '@framework/utilities';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { userProfileActions } from '@modules/user/store/actions';

@Injectable()
export class CompanyAdminGuard  {
    constructor(private store$: Store<CoreState>, private utilities: UtilityHelper, private router: Router) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.getUserProfile().toPromise().then((userProfile) => {
                if (userProfile.isCompanyAdmin) {
                    resolve(true);
                } else {
                    this.router.navigate(['/company']);
                    resolve(false);
                }
            }, error => {
                resolve(false);
                window.location.href = '/user/login';
            });
        });
    }

    private getUserProfile(): Observable<UserProfileViewModel> {
        let uProfile: UserProfileViewModel;
        return this.store$
            .select(userProfileSelectors.selectCurrentProfile)
            .pipe(
                tap((userProfile: UserProfileViewModel) => {
                    if (!userProfile) {
                        return this.store$.dispatch(new userProfileActions.LoadCurrentProfile());
                    }
                }),
                switchMap((userProfile) => {
                    uProfile = userProfile;
                    return this.store$.select(userProfileSelectors.selectIsLoaded)
                }),
                filter((isLoaded) => isLoaded === true),
                map((userProfileLoadStatus) => uProfile),
                take(1)
            );
    }
}