import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/env/environment';


export abstract class BaseHttpService {

  private endpointUrl: string = environment.endpointUrl;
  private serviceUrl: string;

  constructor(protected http: HttpClient, private segmentName: string, private eUrl?: string) {
    this.endpointUrl = eUrl === undefined ? environment.endpointUrl : eUrl;
    this.serviceUrl = `${this.endpointUrl}${this.segmentName}`;


  }

  public ping() {
    this.http.get('ping').subscribe(data => console.log(data), err => console.log(err));
  }

  createAuthorizationHeader(headers: Headers) {
    headers.append('accept', 'application/csv'); 
  }

  protected get<T>(url,options?: any) {
    return this.http.get<T>(this.serviceUrl + url);
  }

  public getCampaign<T>(url) {

    return this.http.get<T>(this.serviceUrl + url ,{
      headers: { 'Cache-Control': 'no-cache, no-store', 'Pragma': 'no-cache' },
      params: { timestamp: Date.now().toString() }
    });
  }
 

  protected getFull(url) {
    return this.http.get(this.serviceUrl + url, { observe: 'response' });
  }

  protected post(url, data, options?: any) {
    return this.http.post(this.serviceUrl + url, data, options);
  }

  protected put(url, data) {
    return this.http.put(this.serviceUrl + url, data);
  }

  protected patch(url, data) {
    return this.http.patch(this.serviceUrl + url, data);
  }

  protected head(url) {
    return this.http.head(this.serviceUrl + url);
  }

  protected delete(url) {
    return this.http.delete(this.serviceUrl + url);
  }

  protected download(url) {
   let  requestHeaders = new HttpHeaders().append('Accept', 'application/csv, text/plain, */*')

    return this.http.get(this.serviceUrl + url, { responseType: 'blob',headers:requestHeaders});
  }

  protected sendThankyou(url, data) {
    return this.http.post(this.endpointUrl + '/stories/' + url, data);
  }
}


