import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-integration-blank',
  templateUrl: './integration-blank.component.html',
  styleUrls: ['./integration-blank.component.css']
})
export class IntegrationBlankComponent implements OnDestroy {
  private activeRouteSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    this.activeRouteSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params.code) {
        localStorage.setItem('integrationCode', params.code);
      }
    });
  }

  ngOnDestroy() {
    this.activeRouteSubscription.unsubscribe();
  }

}
