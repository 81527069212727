<div class="gs-modal">
    <div class="gs-modal__header">
        <div class="gs-modal__title">
            <h5>Embed</h5>
        </div>
    </div>
    <div class="gs-modal__content">
        <div class="input-group input-group-copy" [ngClass]="{'is-vaild': isCopied}">
            <input #embedInput type="text" [value]="embedCode" class="form-control">
            <div class="input-group-append" (click)="copy()">
                <span class="input-group-text"><span [hidden]="isCopied">Copy to Clipboard</span><i [hidden]="!isCopied"
                        class="fas fa-check"></i></span>
            </div>
        </div>
    </div>
</div>