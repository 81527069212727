import { Component, SimpleChanges, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { ComponentBase } from '@framework/base/component/component.base';
import { CoreState } from '@app/store/core/states';
import { ImageCloudinaryPipe } from '@modules/shared/pipes';
import { PaginationModel } from '@framework/models/pagination-models/pagination-model';
import { LoadStatusModel } from '@modules/shared/models';
import { StoryViewModel } from '@modules/story/models/view-models';
import { GetStoriesInputApiModel } from '@modules/story/models/api-models/input';
import { storySelector } from '@modules/story/store/selectors';
import { storyActions, storyStepActions } from '@modules/story/store/actions';
import { GsMasonryComponent } from '@modules/shared/components/layout/gs-masonry';
import { UserProfileViewModel, CompanyViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-pb-profile-stories',
  templateUrl: './profile-stories.component.html',
  styleUrls: ['./profile-stories.component.scss']
})
export class ProfileStoriesComponent extends ComponentBase {

  @HostListener('window:scroll', [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.loadMoreStories();
    }
  }

  @ViewChild(GsMasonryComponent)
  gsMasonryComponent: GsMasonryComponent;

  // Private variables
  private selectSelectedProfile$: Observable<UserProfileViewModel>;
  private selectStories$: Observable<StoryViewModel[]>;
  private selectLoadStoryStatus$: Observable<LoadStatusModel>;

  private selectSelectedProfileSubscription: Subscription;
  private selectStoriesSubscription: Subscription;
  private selectLoadStoryStatusSubscription: Subscription;

  private queryStoriesData: GetStoriesInputApiModel = new GetStoriesInputApiModel(new PaginationModel(9));
  private countVideoLoaded: number = 0;

  // Public variables
  public company: CompanyViewModel;
  public publicLoggedUser: UserProfileViewModel;
  public publicProfile: UserProfileViewModel;

  public stories: StoryViewModel[] = [];
  public authoredStories: StoryViewModel[] = [];
  public namedStories: StoryViewModel[] = [];
  public draftStories: StoryViewModel[] = [];

  public isViewList: boolean = false;
  public updateMasonryLayout: boolean = false;
  public loadStoryStatus: LoadStatusModel = {
    isLoading: false,
    isLoaded: false,
    isCanLoadMore: true,
    rootUrl: '',
  };
  public isLoadingMore: boolean = false;
  public isFirstLoading: boolean = true;
  public filter = {
    active: 'all',
    fields: [
      {
        name: 'All',
        key: 'all',
      },
      {
        name: 'Authored',
        key: 'author',
      },
      {
        name: 'Named',
        key: 'involved',
      },
    ],
    stories: [],
  };
  private loadStepSuccessSubscription: Subscription;
  public stepsData: any;

  constructor(private actions$: Actions, private store$: Store<CoreState>, public imageCloudinaryPipe: ImageCloudinaryPipe) {
    super();
    this.initFilter();
    this.selectSelectedProfile$ = this.store$.select(userProfileSelectors.selectSelectedProfile);
    this.selectStories$ = this.store$.select(storySelector.selectAll);
    this.selectLoadStoryStatus$ = this.store$.select(storySelector.selectLoadStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions
  private initFilter() {
    this.filter.active = 'all';
    this.queryStoriesData.filter_search = this.filter.active;
  }

  private subscribe() {
    this.selectSelectedProfileSubscription = this.selectSelectedProfile$.subscribe((userProfile) => {
      if (userProfile) {
        this.company = userProfile.company;
        this.store$.dispatch(new storyStepActions.Load(this.company.id));
        this.publicProfile = userProfile;
        this.loadStories();
      }
    });

    this.loadStepSuccessSubscription = this.actions$.pipe(
      ofType<storyStepActions.LoadSuccess>(storyStepActions.ActionTypes.LOAD_SUCCESS)
    ).subscribe((result) => {
      if (result.data) {
        this.stepsData = result.data;
      }
    });

    this.selectStoriesSubscription = this.selectStories$.subscribe((stories) => {
      this.stories = stories || [];
      this.isLoadingMore = false;
      setTimeout(() => {
        this.reloadMansonry();
      }, 1000);
    });

    this.selectLoadStoryStatusSubscription = this.selectLoadStoryStatus$.subscribe((loadStoryStatus) => {
      this.loadStoryStatus = loadStoryStatus;
      if (this.isFirstLoading && loadStoryStatus.isLoaded) {
        this.isFirstLoading = false;
      }
    });
  }

  private unsubscribe() {
    this.selectSelectedProfileSubscription.unsubscribe();
    this.selectStoriesSubscription.unsubscribe();
    this.selectLoadStoryStatusSubscription.unsubscribe();
    this.loadStepSuccessSubscription.unsubscribe();
  }

  private loadStories() {
    this.queryStoriesData.userId = Number(this.publicProfile.id);
    this.queryStoriesData.pagination.sort = 'desc';
    this.queryStoriesData.public = true;
    this.store$.dispatch(new storyActions.LoadByUser(this.queryStoriesData));
  }

  private loadMoreStories() {
    if (!this.loadStoryStatus.isCanLoadMore || this.isLoadingMore) {
      return;
    }
    this.isLoadingMore = true;
    this.queryStoriesData.pagination.page += 1;
    this.loadStories();
  }

  private reloadMansonry() {
    if (this.gsMasonryComponent) { this.gsMasonryComponent.reloadLayout(); }
  }

  private setFilterData() {
    if (this.filter.active === 'all') {
      this.queryStoriesData.filter_search = 'all';
    } else if (this.filter.active === 'author') {
      this.queryStoriesData.filter_search = 'author';
    } else if (this.filter.active === 'involved') {
      this.queryStoriesData.filter_search = 'involved';
    }

    this.queryStoriesData.pagination.page = 1;
  }
  // Public functions
  public trackByFunc(story: StoryViewModel) {
    return story.id;
  }

  public changeViewTab(filter: string) {
    if (filter === this.filter.active) return;

    this.filter.active = filter;
    this.setFilterData();
    this.loadStories();
  }

  getHref(story){
    if(story.companyIdSlug == null){

      return '/p/' + story.author.slug + '/' + story.slug 
    }
    else{
      if(story.public){
        return '/c/' + story.companyIdSlug + '/' + story.slug
      }
      else{
        return '/company/' + story.slug
      }
    }
    
  }
}
