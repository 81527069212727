<div class="b-timeline">
  <div class="container">
    <div class="row align-items-center text-center text-lg-left">
      <div class="gs-btn-group-container col-12 text-center text-lg-right">
        <div class="d-none d-lg-block">
          <div class="btn-group gs-btn-group-c">
            <button type="button" class="btn btn-sq btn-white p-0">
              <gs-skeleton-loader class="skeleton-button-circel" width="135" height="40">
              </gs-skeleton-loader>
            </button>
            <button type="button" class="btn btn-sq btn-white p-0">
              <gs-skeleton-loader class="skeleton-button-circel" width="135" height="40">
              </gs-skeleton-loader>
            </button>
            <button type="button" class="btn btn-sq btn-white p-0">
              <gs-skeleton-loader class="skeleton-button-circel" width="135" height="40">
              </gs-skeleton-loader>
            </button>
            <button type="button" class="btn btn-sq btn-white p-0">
              <gs-skeleton-loader class="skeleton-button-circel" width="135" height="40">
              </gs-skeleton-loader>
            </button>
          </div>
        </div>
        <div class="d-lg-none mt-2">
          <button class="btn btn-white btn-sm">
            <gs-skeleton-loader class="skeleton-button-circel" width="185" height="36">
            </gs-skeleton-loader>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>