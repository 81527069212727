import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { SearchCompanyOutputApiModel, InfoCompanyOutputApiModel, CampaignOutputApiModel, WorkAttributeOutputApiModel, LandingPageOutputApiModel } from '../models/api-models/output';
import { CreateCompanyInputApiModel, CreateCampaignInputApiModel, GetCampaignInputApiModel, UpdateCampaignInputApiModel, UpdateCompanyInputApiModel, GetLandingPageInputApiModel, UpdateLandingPageInputApiModel, CreateLandingPageInputApiModel } from '../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CompanyHttpService extends BaseHttpService {
    
    public campaignQueryData$: BehaviorSubject<any> = new BehaviorSubject(null);
    public featuredQueryData$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    
    public search(domain: string) {
        return this.get(`/search/?domain=${domain}`).pipe(
            map((response: SearchCompanyOutputApiModel) => {
                return new SearchCompanyOutputApiModel(response);
            })
        );
    }

    public searchByName(name: string) {
        return this.get(`/search-by-name/?name=${name}`).pipe(
            map((response: SearchCompanyOutputApiModel) => {
                return new SearchCompanyOutputApiModel(response);
            })
        );
    }

    public addMember(companyId: number, userId: number) {
        return this.post(`/${companyId}/members/${userId}/`, {}).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public create(payload: CreateCompanyInputApiModel) {
        return this.post(`/`, payload).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public loadInfo(companyId: number) {
        return this.get(`/${companyId}/`).pipe(
            map((response) => {
                return new InfoCompanyOutputApiModel(response);
            })
        )
    }

    public loadPublicInfo(companyIdOrSlug: number | string) {
        return this.get(`/${companyIdOrSlug}/public/`).pipe(
            map((response) => {
                return new InfoCompanyOutputApiModel(response);
            })
        )
    }

    public loadShortInfo(companyIdOrSlug: number | string) {
        return this.get(`/${companyIdOrSlug}/public/?testimonial=1`).pipe(
            map((response) => {
                return new InfoCompanyOutputApiModel(response);
            })
        )
    }

    public getcompanyEmbed(isCompany,Id: number | string) {
        console.log("errOuter>>")
        if(isCompany){
            console.log("err>>")
            return this.get(`/css/embeddings/?company_id=${Id}&timestamp: ${Date.now().toString()}`).pipe(
                map((response) => {
                    console.log("errInside>>")
                    return response;
                })
            )
        }
        else{
            console.log("err>>")
            return this.get(`/css/embeddings/?campaign_id=${Id}&timestamp: ${Date.now().toString()}`).pipe(
                map((response) => {
                    console.log("errInside>>")
                    return response;
                })
            )
        }

    }

    public postPatchcompanyEmbed(payload:any,type:any) {
        if(type == 'POST'){
            return this.post(`/css/embeddings/`,payload).pipe(
                map((response) => {
                    return response;
                })
            )
        }
        else{
            return this.patch(`/css/embeddings/`,payload).pipe(
                map((response) => {
                    return response;
                })
            )
        }

    }

    public update(companyId: number, payload: Partial<UpdateCompanyInputApiModel>) {
        return this.patch(`/${companyId}/`, payload).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public updateStoryCheckOut(companyId: number, payload: any) {
        return this.patch(`/${companyId}/guest-checkout/`, payload).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public getStoryCheckOut(companyId: number) {
        return this.get(`/${companyId}/guest-checkout/`).pipe(
            map((response) => {
                return response;
            })
        );
    }

    public getWorkAttribute(companyIdSlug: number | string) {
        return this.get('/' + companyIdSlug + '/setting/attributes/').pipe(
            map((response: WorkAttributeOutputApiModel[]) => {
                return response;
            }));
    }



    public loadLandingPage(companyId: number, queryData: GetLandingPageInputApiModel) {
        return this.get(`/${companyId}/landing_pages/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<LandingPageOutputApiModel>) => {
                return response;
            })
        );
    }

    public loadDetailLandingPage(companyId: number | string, idOrSlug: number | string) {
        return this.get(`/${companyId}/landing_pages/${idOrSlug}/`).pipe(
            map((response: LandingPageOutputApiModel) => {
                return response;
            })
        );
    }


    submitCta(CompanyId: any,spotlightId: number, payload: any) {
        return this.post(`/${CompanyId}/landing_pages/${spotlightId}/landing-page-cta-interactions/`, payload);
    }

    public updateLandingPage(companyId: number, id: number, payload: UpdateLandingPageInputApiModel) {
        return this.patch(`/${companyId}/landing_pages/${id}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public createLandingPage(companyId: number, payload: CreateLandingPageInputApiModel) {
        return this.post(`/${companyId}/landing_pages/`, payload).pipe(
            map((response: any) => {
                return new LandingPageOutputApiModel(response);
            })
        );
    }

    public loadCampaign(companyId: number, queryData: GetCampaignInputApiModel) {
        return this.get(`/${companyId}/campaigns/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<CampaignOutputApiModel>) => {
                return response;
            })
        );
    }
    public updateColum(companyId: number, queryData: any) {
        return this.patch(`/${companyId}/campaigns/order/campaignquestions/`,queryData).pipe(
            map((response:any) => {
                return response;
            })
        );
    }


    public loadDetailCampaign(companyId: number | string, idOrSlug: number | string) {
        return this.getCampaign(`/${companyId}/campaigns/${idOrSlug}/`).pipe(
            map((response: CampaignOutputApiModel) => {
                return response;
            })
        );
    }


    public loadCompanyDetailCampaign(companyId: number | string, idOrSlug: number | string) {
        return this.get(`/${companyId}/campaigns/${idOrSlug}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }



    public updateCampaign(companyId: number, id: number, payload: UpdateCampaignInputApiModel) {
        return this.patch(`/${companyId}/campaigns/${id}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public createCampaign(companyId: number, payload: CreateCampaignInputApiModel) {
        return this.post(`/${companyId}/campaigns/`, payload).pipe(
            map((response: any) => {
                return new CampaignOutputApiModel(response);
            })
        );
    }

    // public changeSlug(payload: Partial<any>) {
    //   return this.put(`/${payload.companyId}/setting/slug/`, {slug: payload.slug}).pipe(
    //     map((response: any) => {
    //       return response;
    //     })
    //   );
    // }
}
