<div class="m-story-tags__inner" [hidden]="statusDetail.isLoading">
  <div class="l-story-tags" *ngIf="storyDetail?.stepsData">
    <div class="l-story-tags__item" *ngFor="let stepData of stepsData" [ngStyle]="{'color': stepData.color}">
      <div class="l-story-tags__header">
        <div class="l-story-tags__icon">
          <div class="icon">
            <!-- <svg role="img">
              <use attr.xlink:href="{{getStepIcon(stepData.title)}}"></use>
            </svg> -->
          </div>
        </div>
        <div class="l-story-tags__title">{{stepData.title}}</div>
      </div>
      <div class="l-story-tags__content">
        <div class="l-story-tags__lists">
          <div class="story-tag-item" *ngFor="let item of stepData.data">
            <a class="story-tag-single" *ngIf="stepData.title == 'Contributors' || stepData.title == 'Author'">@{{item.label}}</a>
            <a class="story-tag-single" *ngIf="stepData.title != 'Contributors' && stepData.title != 'Author'">#{{item.label}}</a>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<skeleton-pb-step-bar [hidden]="statusDetail.isLoaded"></skeleton-pb-step-bar>
