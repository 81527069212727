export class GetCompaniesOutputApiModel {
  public id: number;
  public name: string;
  public sitename: string;
  public slogan: string;
  public website: string;
  public is_active: boolean;
  public is_public: boolean;
  public time_created: any;
  public created_by: number;
  
  constructor(init?: Partial<GetCompaniesOutputApiModel>){
    Object.assign(this, init);
    this.init();
  }

  private init() {
    this.setCreatedAt();
  }

  private setCreatedAt() {
    if (this.time_created) {
      this.time_created = new Date(this.time_created);
    }
  }  
}