import { Component, SimpleChanges, Input, forwardRef, ElementRef, Renderer2 } from '@angular/core';

import { ComponentBase } from '@framework/base/component/component.base';
import { Constants } from '../../../constants';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-grid-view-link-field',
  templateUrl: './grid-view-link-field.component.html',
  styleUrls: ['./grid-view-link-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GridViewLinkFieldComponent),
      multi: true
    }
  ]
})
export class GridViewLinkFieldComponent extends ComponentBase {
  @Input() type: string;
  @Input() href: string;
  @Input() disabled: boolean = false;
  @Input() sharelink: boolean = false;
  @Input() spotlightlink: boolean = false
  @Input() draftLink: boolean = false;
  // Private variablesapp-grid-view-dynamic-field
  private inputValue: string;

  // Public variables
  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };

  constructor(private el: ElementRef, private renderer: Renderer2,private router: Router) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  protected ionViewDidLoad(): void {

  }
  protected ionViewWillEnter(): void {

  }
  protected ionViewDidEnter(): void {

  }
  protected ionViewWillLeave(): void {

  }
  protected ionViewDidLeave(): void {

  }
  protected ionViewWillUnload(): void {

  }

  // Private functions
  private subscribe() {

  }

  private unsubscribe() {

  }

  private triggerChange($event) {
    this.fnOnChange($event);
    const event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }
  // Public functions
  public get isText(): boolean {
    return this.type === Constants.columnType.text;
  }

  public get isCheckBox(): boolean {
    return this.type === Constants.columnType.checkbox;
  }

  public get isDate(): boolean {
    return this.type === Constants.columnType.date;
  }

  public get isStatus(): boolean {
    return this.type === Constants.columnType.status;
  }

  public get isLink(): boolean {
    return this.type === Constants.columnType.link;
  }

  public get isSpotlightLink(): boolean {
    return this.type === Constants.columnType.spotlightlink;
  }

  public get isDraftlink(): boolean {
    return this.type === Constants.columnType.draftlink;
  }

  public set value(val: string) {
    if (val !== undefined) {
      this.inputValue = val;
    }
  }

  public get value() {
    return this.inputValue;
  }

  routernavigate(data){
    this.router.navigate([data])
  }

  public writeValue(val: string): void {
    this.value = val;
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange() {
    this.triggerChange(this.inputValue);
  }
}
