import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { NudgesSettingOutputApiModel } from '../models/api-models/output';
import { NudgesSettingInputApiModel } from '../models/api-models/input';
import { NumberSymbol } from '@angular/common';

@Injectable()
export class NudgesSettingHttpService extends BaseHttpService {

    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number) {
        return this.get(`/${companyId}/setting/nudges/`).pipe(
            map((response: ResponsePaginationApiModel<NudgesSettingOutputApiModel>) => {
                return response;
            })
        )
    }

    public loadNudgeSettings(companyId: number) {
        return this.get(`/${companyId}/setting/nudges-setting/`).pipe(
            map((response: any) => {
                return response;
            })
        )
    }

    public updateNudgeSettings(companyId: number, payload) {
        return this.patch(`/${companyId}/setting/nudges-setting/`,payload).pipe(
            map((response: any) => {
                return response;
            })
        )
    }
    public update(companyId: number, audienceId: number, payload: Partial<NudgesSettingInputApiModel>) {
        return this.patch(`/${companyId}/setting/nudges/${audienceId}/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public add(companyId: number, payload: Partial<NudgesSettingInputApiModel>) {
        return this.post(`/${companyId}/setting/nudges/`, payload).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public deleteAdvocacy(companyId: number, audienceId: number) {
        return this.delete(`/${companyId}/setting/nudges/${audienceId}/`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public UpdateColumnOrdr(companyId,updatedList){
        return this.patch(`/${companyId}/setting/ordernudges/`,updatedList).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
