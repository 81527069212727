<app-toolbar [hidden]="statusDetail.isLoading || !storyDetail?.public">

  <app-toolbar>
    <div [formGroup]="shareForm">
      <!-- <div class="addthis_inline_share_toolbox_eclv"></div> -->
      <app-toolbar-item>
        <button (click)="share()" data-toggle="tooltip" data-placement="left" title="Share" class="btn text-gray text-hover-mint">
          <i class="fa-regular fa-share"></i>
          <!--<svg role="img">
            <use xlink:href="#icon-share"></use>
          </svg>-->
        </button>
      </app-toolbar-item>
      <app-toolbar-item *ngIf="currentUserProfile?.isCompanyAdmin && isLoggedInuser" >
        <button  (click)="approvePublic()"
        data-toggle="tooltip" data-placement="left" title="Send Email" class="btn text-gray text-hover-orange" style="font-size: 18px;">
        <i class="fa-regular fa-envelope"></i>
      </button>
      </app-toolbar-item>
    </div>
  </app-toolbar>


</app-toolbar>
