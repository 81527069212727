import { Action } from '@ngrx/store';

export enum ActionTypes {


    LOAD_EMBED = '[Company Embed] Load Embed',
    LOAD_EMBED_SUCCESS = '[Company Embed] Load Embed Success',
    LOAD_EMBED_FAILURE = '[Company Embed] Load Embed Failure',

    LOAD_EMBED_FEED = '[Company Embed] Load Embed Feed',
    LOAD_EMBED_FEED_SUCCESS = '[Company Embed] Load Embed Feed Success',
    LOAD_EMBED_FEED_FAILURE = '[Company Embed] Load Embed Feed Failure',

   CREATE_UPDATE_EMBED = '[Company Embed] Create or Update Embed',
   CREATE_UPDATE_EMBED_SUCCESS = '[Company Embed] Create or Update Embed Success',
   CREATE_UPDATE_EMBED_FAILURE = '[Company Embed] Create or Update Embed Failure',

   CREATE_UPDATE_EMBED_FEED =  '[Company Embed] Create or Update Embed Feed',
   CREATE_UPDATE_EMBED_FEED_SUCCESS =  '[Company Embed] Create or Update Embed Feed Success',
   CREATE_UPDATE_EMBED_FEED_FAILURE =  '[Company Embed] Create or Update Embed Feed Failure'
}

export class LoadEmbed implements Action {
    readonly type = ActionTypes.LOAD_EMBED;

    constructor(public isCompany:boolean,public Id:any) { }
}

export class LoadEmbedSuccess implements Action {
    readonly type = ActionTypes.LOAD_EMBED_SUCCESS;

    constructor(public response: any) { }
}

export class LoadEmbedFailure implements Action {
    readonly type = ActionTypes.LOAD_EMBED_FAILURE;

    constructor(public error: any) { }
}


export class LoadEmbedFeed implements Action {
    readonly type = ActionTypes.LOAD_EMBED_FEED;

    constructor(public typeOfPost:any,public Id:any) { }
}

export class LoadEmbedFeedSuccess implements Action {
    readonly type = ActionTypes.LOAD_EMBED_FEED_SUCCESS;

    constructor(public response: any) { }
}

export class LoadEmbedFeedFailure implements Action {
    readonly type = ActionTypes.LOAD_EMBED_FEED_FAILURE;

    constructor(public error: any) { }
}


export class PostPatchEmbed implements Action {
    readonly type = ActionTypes.CREATE_UPDATE_EMBED;

    constructor(public payload:any, public typeofPost:any) { }
}

export class PostPatchEmbedSuccess implements Action {
    readonly type = ActionTypes.CREATE_UPDATE_EMBED_SUCCESS;
}

export class PostPatchEmbedFailure implements Action {
    readonly type = ActionTypes.CREATE_UPDATE_EMBED_FAILURE;
}

export class PostPatchEmbedFeed implements Action {
    readonly type = ActionTypes.CREATE_UPDATE_EMBED_FEED;

    constructor(public payload:any, public typeofPost:any) { }
}

export class PostPatchEmbedFeedSuccess implements Action {
    readonly type = ActionTypes.CREATE_UPDATE_EMBED_FEED_SUCCESS;
}

export class PostPatchEmbedFeedFailure implements Action {
    readonly type = ActionTypes.CREATE_UPDATE_EMBED_FEED_FAILURE;
}

export type Actions =
      LoadEmbed | LoadEmbedSuccess | LoadEmbedFailure
    | PostPatchEmbed | PostPatchEmbedSuccess | PostPatchEmbedFailure | PostPatchEmbedFeed | PostPatchEmbedFeedSuccess | PostPatchEmbedFeedFailure;
