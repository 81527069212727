import { Component, SimpleChanges } from '@angular/core';
import { ComponentBase } from '@framework/base';

@Component({
  selector: 'gs-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent extends ComponentBase {
  // Private variables

  // Public variables
  public template: string = `
    <div class="loading">
      <div class="lds-ellipsis-mint">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
    </div>
`;

  constructor() {
    super();
  }

  // Life cycle hook
  public focus(): void {

  }
  protected onInit(): void {

  }
  protected onDestroy(): void {

  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  // Private functions

  // Public functions

}
