import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { CognitoModule } from '@framework/cloud-authentication/cognito';
import { JwtOptionsFactory } from './services';
import { AuthService } from '@framework/services';
import { ClientHttpInterceptor } from './interceptors';

@NgModule({
    declarations: [],
    imports: [
        CognitoModule,
        HttpClientModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: JwtOptionsFactory,
                deps: [AuthService]
            }
        }),
    ],
    exports: [HttpClientModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: ClientHttpInterceptor, multi: true }],
})
export class JwtWrapperModule {

}