import { Component, Input, forwardRef, Output, EventEmitter, ElementRef, Renderer2, HostListener, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

import { ComponentBase } from '@framework/base/component';

@Component({
  selector: 'app-text-area-emoji',
  templateUrl: './text-area-emoji.component.html',
  styleUrls: ['./text-area-emoji.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaEmojiComponent),
      multi: true
    }
  ]
})
export class TextAreaEmojiComponent extends ComponentBase implements ControlValueAccessor {
  // Inputs
  @Input() placeholder: string;
  @Input() debounceTime: number = 0;
  @Input() rows: number = 2;
  @Input() classCss: string = '';
  // Outputs
  @Output() blur: EventEmitter<string> = new EventEmitter<string>();
  @Output() focus: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('emojiPopup')
  public emojiPopup: ElementRef;
  @ViewChild('emojiShowButton')
  public emojiShowButton: ElementRef;
  @ViewChild('textArea')
  public textArea: ElementRef;
  @HostListener('document:click', ['$event'])
  public documentClick(event) {
    if (this.emojiShowButton && this.emojiShowButton.nativeElement.contains(event.target) || this.emojiPopup && this.emojiPopup.nativeElement && this.emojiPopup.nativeElement.contains(event.target)) {
      // Inside
    } else {
      // Outside
      this.isShowEmoji = false;
    }
  }

  // Private variables
  private textAreaValue: string;
  private currentStartCaretPosition: number = 0;
  private currentEndCaretPosition: number = 0;
  // Public variables
  public fnOnChange: any = () => { };
  public fnOnTouch: any = () => { };
  public isShowEmoji: boolean = false;
  public isActiveAnimation: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private detector: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document) {
    super();
  }

  // Life cycle hook
  protected onInit(): void {

  }
  protected onDestroy(): void {

  }
  protected onChanges(changes: any): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private debounce(fn, args, delay) {
    let timer = null;

    return () => {
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(args);
      }, delay);
    }
  }

  private triggerChange($event) {
    this.fnOnChange($event);
    let event = new CustomEvent('change', { bubbles: true });
    this.el.nativeElement.dispatchEvent(event);
  }

  private getCurrentCaretPosition(e) {
    if (e.target.selectionStart || e.target.selectionStart == '0') {
      this.currentStartCaretPosition = e.target.selectionStart;
    }
    if (e.target.selectionEnd || e.target.selectionEnd == '0') {
      this.currentEndCaretPosition = e.target.selectionEnd;
    }
  }

  private selectRange(selectionStart: number, selectionEnd?: number) {
    selectionEnd = selectionEnd || selectionStart;
    (window as any).textArea = this.textArea.nativeElement;
    if (this.textArea.nativeElement.setSelectionRange) {
      this.textArea.nativeElement.focus();
      this.textArea.nativeElement.setSelectionRange(selectionStart, selectionEnd);
    }
    else if (this.textArea.nativeElement.createTextRange) {
      var range = this.textArea.nativeElement.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }
  // Public functions
  public set value(val: string) {
    if (val !== undefined) {
      this.textAreaValue = val;
    }
  }

  public get value() {
    return this.textAreaValue;
  }

  public writeValue(val: string): void {
    this.value = val;
    this.autosize();
  }

  public registerOnChange(fn: any): void {
    this.fnOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.fnOnTouch = fn;
  }

  public onChange($event) {
    if (this.debounceTime > 0) {
      this.debounce(this.triggerChange, this.textAreaValue, this.debounceTime);
    } else {
      this.triggerChange(this.textAreaValue);
    }
  }

  public doFocus() {
    this.textArea.nativeElement.focus();
  }

  public onBlur($event) {
    this.blur.emit(this.textAreaValue);
  }

  public toggleEmoji() {
    this.selectRange(this.currentStartCaretPosition, this.currentEndCaretPosition);
    this.isShowEmoji = !this.isShowEmoji;
    this.detector.detectChanges();
    return this.isShowEmoji;
  }

  public onKeydown($event) {
    this.autosize();
  }

  public onKeyup(e) {
    this.getCurrentCaretPosition(e);
  }

  public autosize() {
    setTimeout(() => {
      if (this.textArea.nativeElement.scrollHeight > 0) {
        this.textArea.nativeElement.style.cssText = 'height:auto; padding:0';
        this.textArea.nativeElement.style.cssText = 'height:' + this.textArea.nativeElement.scrollHeight + 'px';
      }
    }, 0);
  }

  public addEmoji(event) {
    this.value = this.value || '';
    const value = this.value.substring(0, this.currentStartCaretPosition) + event.emoji.native + this.value.substring(this.currentEndCaretPosition);
    this.writeValue(value);
    this.onChange(event);

    this.currentStartCaretPosition += event.emoji.native.length;
    this.currentEndCaretPosition = this.currentStartCaretPosition;
    setTimeout(() => {
      this.selectRange(this.currentStartCaretPosition, this.currentEndCaretPosition);
    }, 100);
  }

  public onFocus(e) {
    this.focus.emit(e);
  }

  public onClick(e) {
    this.getCurrentCaretPosition(e);
  }
}
