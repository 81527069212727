import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { TestimonialSettingEntity, ThankyouPageEntity } from '../entities';
import { TestimonialSettingState } from '../states';


export const testimonialSettingAdapter: EntityAdapter<TestimonialSettingEntity> = createEntityAdapter<TestimonialSettingEntity>();
export const initialState: TestimonialSettingState = testimonialSettingAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    thankyouPageData: null,
    questionsPageData: null
});