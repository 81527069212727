import { MemoizedSelector, createSelector } from "@ngrx/store";

import { AppSettingState, SharedFeatureState } from '../states';
import { selectSharedFeature } from "./shared.selector";
import { AppSettingViewModel } from "@modules/shared/models/view-models";
import { LoadStatusModel } from "@modules/shared/models";

export const selectSharedState: MemoizedSelector<object, AppSettingState> = createSelector(selectSharedFeature, (state: SharedFeatureState) => state.appSetting);


// -------------[Mapping Functions]------------- 
const getAppSetting = (state: AppSettingState): AppSettingViewModel => {
    const appSetting: AppSettingViewModel = {
        sso: state.sso,
        notAllowedDomains: state.notAllowedDomains
    };
    return appSetting;
};

const getLoadStatus = (state: AppSettingState): LoadStatusModel => {
    const loadStatus: LoadStatusModel = {
       isLoading: state.isLoading,
       isLoaded: state.isLoaded
    };
    return loadStatus;
};

// -------------[Public Selectors]-------------
export const selectAppSetting: MemoizedSelector<object, AppSettingViewModel> = createSelector(selectSharedState, getAppSetting);
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> = createSelector(selectSharedState, getLoadStatus);

