<div class="m-story-contribution">
  <div class="m-story-contribution__conts">
    <div class="story-contribution">
      <div class="story-contribution__head">
        <div class="story-contribution__avatar">
          <div class="name text-nowrap">
            <gs-skeleton-loader appearance="circle" width="34" height="34" ></gs-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="story-contribution__body">
        <gs-skeleton-loader></gs-skeleton-loader>
        <gs-skeleton-loader></gs-skeleton-loader>
        <gs-skeleton-loader></gs-skeleton-loader>
      </div>
    </div>
  </div>
</div>
