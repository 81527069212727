
// Document https://github.com/collab-project/videojs-record

import { Component, Input, ViewChild, ViewEncapsulation, IterableDiffers, IterableDiffer, SimpleChanges } from '@angular/core';

import { OwlCarouselOptions } from './models';
import { ComponentBase } from '@framework/base/component';
import { OwlChildComponent } from './owl-child.component';

@Component({
    selector: 'app-owl-carousel',
    templateUrl: './owl-carousel.component.html',
    styleUrls: ['./owl-carousel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OwlCarouselComponent extends ComponentBase {
    @ViewChild('owl') $owlChild: OwlChildComponent;

    @Input() options: Partial<OwlCarouselOptions>;
    @Input() carouselClasses: any = '';
    @Input() set items(coll: any[]) {
        this._items = coll;
        if (coll && !this.differ) {
            this.differ = this.differs.find(coll).create(null);
        }
    }
    // Private variables
    private _items: any[];
    private differ: IterableDiffer<any>;

    // Public variables


    constructor(private differs: IterableDiffers) {
        super();
    }

    // Life cycle hook
    protected onInit(): void {

    }
    protected onDestroy(): void {

    }
    protected onChanges(changes: SimpleChanges): void {

    }
    protected onDoCheck(): void {
        if (this.differ) {
            const changes = this.differ.diff(this._items);
            if (changes) {
                let changed = false;
                let changedFn = () => {
                    changed = true;
                };
                changes.forEachAddedItem(changedFn);
                changes.forEachMovedItem(changedFn);
                changes.forEachRemovedItem(changedFn);
                if (changed) {
                    this.reInit();
                }
            }
        }
    }
    protected onAfterContentInit(): void {

    }
    protected onAfterContentChecked(): void {

    }
    protected onAfterViewInit(): void {

    }
    protected onAfterViewChecked(): void {

    }
    // Private functions

    // Public methods
    public reInit() {
        this.$owlChild.destroyOwl();
        if (this.$owlChild.$owl) {
            let itemLength = this._items && this._items.length;
            if (itemLength && itemLength <= this.$owlChild.currentSlideIndex) {
                this.$owlChild.currentSlideIndex = itemLength;
            }
        }
        this.$owlChild.initOwl();
    }

    public refresh() {
        this.trigger('refresh.owl.carousel');
    }

    public next(options?: any[]) {
        this.trigger('next.owl.carousel', options);
    }

    public previous(options?: any[]) {
        this.trigger('prev.owl.carousel', options);
    }

    public to(options?: any[]) {
        this.trigger('to.owl.carousel', options);
    }

    public trigger(action: string, options?: any[]) {
        this.$owlChild.trigger(action, options);
    }
}
