<app-toolbar>
  <div [formGroup]="shareForm">
    <div class="addthis_inline_share_toolbox_eclv"></div>
    <app-toolbar-item>
      <button (click)="share()" data-toggle="tooltip" data-placement="left" title="Share" class="btn text-gray text-hover-mint">
        <svg role="img">
          <use xlink:href="#icon-share"></use>
        </svg>
      </button>
    </app-toolbar-item>
  </div>
</app-toolbar>
