<div class="modal-header pb-0">
  <h4 class="modal-title">Invite People to Share</h4>
  <button type="button" (click)="close()" class="close">
    <span aria-hidden="true">
      <svg role="img">
        <use xlink:href="#icon-close"></use>
      </svg></span>
  </button>
</div>

<div class="modal-body" [formGroup]="copyForm">

  <div class="option-wrapper mb-4 pt-1">
    <p>Create a personalized page you can share that makes it easy for people to promote this story on their social networks and leave reviews.</p>
     <ng-container>
      <div class="row mb-3">
        <div class="col-4 mt-1">
         <span>This page is for:</span>
      </div>
        <div class="col-4">
            <div class="custom-control custom-checkbox" [ngClass]="{'isClickable': isShow }">
                <input id="includeUsers" type="checkbox" formControlName="includeUsers"
                    class="custom-control-input">
                <label for="includeUsers" class="custom-control-label">Users</label>
            </div>
        </div>
        <div class="col-4">
            <div class="custom-control custom-checkbox" [ngClass]="{'isClickable': isShow }">
                <input id="includeGuest" type="checkbox" formControlName="includeGuest"
                    class="custom-control-input">
                <label for="includeGuest" class="custom-control-label">Guests</label>
            </div>
        </div>
    </div>
        <div class="row">
          <div class="col-12">
            <h6>Add a message or suggest a social caption (Optional):</h6>
            <!-- <textarea class="input__style" formControlName="message" name="" id="" cols="30" rows="4"></textarea> -->
            <gs-area-material
            label=""
            name="Message" placeholder="What happened? Why does it matter?" rows="2"
            cssClass="spacing-lg" formControlName="message" [formControl]="copyForm.controls.message">
        </gs-area-material>
          </div>

         </div>
         <div class="row genarate_btn_continer" *ngIf="!isShow">
          <button class="btn genarate_btn btn-pink min-width"  [disabled]="disabled || (!copyForm.value.includeGuest && !copyForm.value.includeUsers )"  [style.background-color]="!disabled || (copyForm.value.includeGuest && copyForm.value.includeUsers ) ? primaryColor : ''" (click)="genrarateLink()">Get Advocacy Link</button>
         </div>
         <div class="row genarate_btn_continer" *ngIf="isShow">
          <button class="btn min-width" type="submit"  (click)="CopyLink()">Copy 
            &nbsp; <span><i class="fas fa-link" style="color: #1579f6"></i></span>
          </button>
         </div>
         <!-- <div class="row" *ngIf="isShow">
          <h6>Internal </h6>
             <div class="col-12 mt-3"> 
                 <gs-input-clipboard name="Link" formControlName="ilink" [disabled]="true" [formControl]="copyForm.controls.ilink">
                 </gs-input-clipboard></div>
                 <h6>External </h6>
                 <div class="col-12 mt-3"> 
                  <gs-input-clipboard name="Link" formControlName="elink" [disabled]="true" [formControl]="copyForm.controls.elink">
                  </gs-input-clipboard></div>
                  <h6>Internal &&  External</h6>
                  <div class="col-12 mt-3"> 
                    <gs-input-clipboard name="Link" formControlName="ielink" [disabled]="true" [formControl]="copyForm.controls.ielink">
                    </gs-input-clipboard></div>
         </div> -->

     </ng-container>
  </div>
</div>

<!-- <iframe src="http://localhost:4200/?&showHeader=true&showtagheader=true" height="900px" width="100px" frameborder="0"></iframe> -->