import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { JwtWrapperModule } from '@framework/jwt-wrapper';
import { SegmentAnalyticsModule } from '@framework/analytics/segment-analytics';
import { environment } from '@app/env/environment';
import { FrameworkModule } from '@framework/framework.module';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@modules/shared';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { MetaStoryDetailComponent } from './seo/meta-story-detail/meta-story-detail.component';
import { CompanyStoreModule } from '@modules/company/store';


import firebase from "firebase/app";
import { CopyVideoComponent } from './seo/copy-video-url/copy-video-url.component';
import { UniqueStoryDetailComponent } from './seo/unique-people-detail-page/unique-people-detail-page.component';
import { EffectsModule } from '@ngrx/effects';
import { EmbedVideoComponent } from './seo/embed-video-url /embed-video-url.component';
import { DonateButtonScriptComponent } from './seo/donate-button-script/donate-button-script.component';
import { OpenPolicyPage } from '@modules/company-admin/pages/open-policy-page/open-policy.page';
import { OpenConsentPage } from '@modules/company-admin/pages/open-consent-page/open-consent.page';
import { ApprovalPage } from '@modules/company-admin/pages/approval page/approval-page';

let prodAnalytics = [
  SegmentAnalyticsModule.forRoot({
    apiKey: environment.analytics.segment.writeKey,
    debug: !environment.production,
    loadOnInitialization: true
  }),
];

const firebaseConfig = {
  apiKey: "AIzaSyAb7reTllSqjxfC0yMfAShtHTjqTtPbrpc",
  authDomain: "goodseeker-78c9c.firebaseapp.com",
  projectId: "goodseeker-78c9c",
  storageBucket: "goodseeker-78c9c.appspot.com",
  messagingSenderId: "1059298581571",
  appId: "1:1059298581571:web:b87e526193629ce0c688f6",
  measurementId: "G-BVBKS31QGG"
};

// if production clear dev imports and set to prod mode
if (process.env.NODE_ENV === 'development' || environment.isProduction === false) {
  prodAnalytics = [];
}

@NgModule({
  declarations: [
    AppComponent,
    MetaStoryDetailComponent,
    CopyVideoComponent,
    EmbedVideoComponent,
    DonateButtonScriptComponent,
    UniqueStoryDetailComponent,
    OpenPolicyPage,
    OpenConsentPage,
    ApprovalPage
  ],
  imports: [
    RecaptchaV3Module,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    JwtWrapperModule,

    FrameworkModule,
    AppRoutingModule,
    CommonModule,
    TransferHttpCacheModule,
    HttpClientModule,
    SharedModule,
    CompanyStoreModule,

    ...prodAnalytics,

    EffectsModule.forRoot([])
  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.site_key }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    FrameworkModule,
    SharedModule
  ]
})
export class AppModule { }
