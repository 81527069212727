import { CampaignOutputApiModel } from "../../models/api-models/output";
import { StoryEntity } from "@modules/story/store/entities";
import { CampaignStoryAuthorViewModel } from "@modules/company/models/store-view-models";

export interface CampaignButtonEntity {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface CampaignStoryAuthorEntity {
    authorId: number;
    avatar: string;
    displayName: string;
    title: string;
    relationship: string;
    companyName: string;
    slug: string;
    companySlug: string;
    companyWebsite: string;
}

export interface CampaignStoryEntity {
    id: number;
    title: string;
    slug: string;
    likesCount: number;
    commentsCount: number;
    author: CampaignStoryAuthorEntity;
}

export interface CampaignEntity {
    id: number;
    title: string;
    queryObjects: any;
    buttons: CampaignButtonEntity[];
    slug: string;
    headerImage: string;
    avatarImage: string;
    person: string;
    description: string;
    start_date: Date;
    end_date: Date;
    companySlug: string;
    isInboundStoriesEnabled: boolean;
    isPublic: boolean;
    stories: CampaignStoryEntity[];
    storyPrompt:any[];
    is_draft: boolean;
    creator: string;
    show_story_prompt?: any;
    show_submit_story_btn?: any;
    frequent_tags: any;
    enable_overlay?: boolean
    order_campaign?: number;
    interview_style?:boolean
    story_count?:number;
    request_story_label?:any;
    submit_story_label?:any;
}

export class CampaignEntity {
    constructor(data?: CampaignOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: CampaignOutputApiModel) {
        this.is_draft = data.is_draft == 1 ? true : false;
        this.creator = data.creator;
        this.id = data.id;
        this.title = data.title;
        this.frequent_tags = data.frequent_tags
        this.queryObjects = data.query_objects;
        this.storyPrompt = data.story_prompt;
        this.submit_story_label = data?.submit_story_label;
        this.request_story_label = data?.request_story_label;
        this.enable_overlay = data.enable_overlay
        this.order_campaign = data.order_campaign
        this.show_story_prompt = data.show_story_prompt == 1 ? true :false;
        this.show_submit_story_btn = data.show_submit_story_btn == 1 ? true : false;
        this.interview_style = data?.interview_style && data.interview_style == 1 ? true : false
        if (data.buttons && data.buttons.map) {
            this.buttons = data.buttons.map((button) => {
                return {
                    label: button.label,
                    url: button.url,
                    foreground: button.foreground,
                    background: button.background,
                    isSelected: button.is_selected
                };
            });
        }
        this.slug = data.slug;
        this.headerImage = data.header_image;
        this.story_count = data.story_count
        this.avatarImage = data.avatar_image;
        this.person = data.person;
        this.description = data.description;
        this.start_date = data.start_date;
        this.end_date = data.end_date;
        this.companySlug = data.company_slug;
        this.isInboundStoriesEnabled = data.allow_inbound_stories == 1 ? true : false;
        this.isPublic = data.is_public;
        if (data.stories && data.stories.length > 0) {
            this.stories = data.stories.map((story) => new StoryEntity(story));
        }
    }
}
