import { MemoizedSelector, createSelector } from "@ngrx/store";

import { StoryFeatureState, StorySettingState } from "../states";
import { selectStoryFeatureState } from "./story-feature.selector";
import { QuestionaireViewModel, ThankYouPageSettingViewModel } from "@modules/story/models/store-view-models";

export const selectStorySettingState: MemoizedSelector<object, StorySettingState> = createSelector(selectStoryFeatureState, (state: StoryFeatureState) => state.storySetting);

const getQuestionnaire = (state: StorySettingState): QuestionaireViewModel[] => state.questionnaireSettings;
const getThankYouPageSetting = (state: StorySettingState): ThankYouPageSettingViewModel => state.thankYouPageSettings;
const getStoryPublicEnabled = (state: StorySettingState): boolean => state.storyPublicEnabled;

// -------------[Public Selectors]-------------
export const selectTestimonialSetting: MemoizedSelector<object, QuestionaireViewModel[]> = createSelector(selectStorySettingState, getQuestionnaire);
export const selectThankYouPageSetting: MemoizedSelector<object, ThankYouPageSettingViewModel> = createSelector(selectStorySettingState, getThankYouPageSetting);
export const selectStoryPublicEnabled: MemoizedSelector<object, boolean> = createSelector(selectStorySettingState, getStoryPublicEnabled);
