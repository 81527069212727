import { MemoizedSelector, createSelector } from "@ngrx/store";
import * as _ from 'lodash';

import { CommentState, SharedFeatureState } from "../states";
import { selectSharedFeature } from "./shared.selector";
import { commentAdapter } from "../adapters/comment.adapter";
import { LoadStatusModel, CommentViewModel } from "@modules/shared/models";
import { CommentEntity } from "../entities";


export const selectCommentState: MemoizedSelector<object, CommentState> = createSelector(selectSharedFeature, (state: SharedFeatureState) => state.comment);

const {
    selectIds: selectIds,
    selectEntities: selectEntities,
    selectAll: selectAllItems,
    selectTotal: selectTotal
} = commentAdapter.getSelectors(selectCommentState);


// -------------[Mapping Functions]-------------
const getLoadStatus = (state: CommentState): LoadStatusModel => {
    return {
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
        isCanLoadMore: state.nextUrl ? state.nextUrl.length > 0 : null
    };
};

const mapAllToViewModel = (entities: CommentEntity[]): CommentViewModel[] => {
    let data = entities.map(entity => new CommentViewModel(entity));
    return _.orderBy(data, ['createdAt'], ['desc']);
};

// -------------[Public Selectors]-------------
export const selectLoadStatus: MemoizedSelector<object, LoadStatusModel> = createSelector(selectCommentState, getLoadStatus);
export const selectAll: MemoizedSelector<object, CommentViewModel[]> = createSelector(selectAllItems, mapAllToViewModel);

