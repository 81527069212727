import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import { userSettingActions } from '../actions';
import { UserHttpService } from '../../services';
import { UserNotificationsOutputApiModel } from '@modules/user/models/output';
import { UserNotificationsEntity } from '@modules/user/store/entities';

@Injectable()
export class UserSettingEffects {
  
  loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userSettingActions.Load>(userSettingActions.ActionTypes.LOAD),
    switchMap((action: userSettingActions.Load) => {
      return this.userHttpService.getUserSetting().pipe(
        map((respone) => {
          return new userSettingActions.LoadSuccess(respone);
        }),
        catchError((error: any) => {
          return of(new userSettingActions.LoadFailure(error));
        }));
    })
  ));

  
  updateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userSettingActions.Update>(userSettingActions.ActionTypes.UPDATE),
    switchMap((action: userSettingActions.Update) => {
      return this.userHttpService.updateUserSetting(action.payload).pipe(
        map((respone) => {
          return new userSettingActions.UpdateSuccess();
        }),
        catchError((error: any) => {
          return of(new userSettingActions.UpdateFailure(error));
        }));
    })
  ));

  
  loadNotificationsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userSettingActions.LoadNotifications>(userSettingActions.ActionTypes.LOAD_NOTIFICATIONS),
    switchMap((action: userSettingActions.LoadNotifications) => {
      return this.userHttpService.getNotifications().pipe(
        map((response: any) => {
          return new userSettingActions.LoadNotificationsSuccess(response)
        }),
        catchError((error: any) => {
          return of(new userSettingActions.LoadNotificationsFailure(error));
        }));
    })
  ));

  
  updateNotificationsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<userSettingActions.UpdateNotifications>(userSettingActions.ActionTypes.UPDATE_NOTIFICATIONS),
    mergeMap((action: userSettingActions.UpdateNotifications) => {
      return this.userHttpService.updateNotifications(action.data).pipe(
        map((response: any) => {
          return new userSettingActions.UpdateNotificationsSuccess(response);
        }),
        catchError((error: any) => {
          return of(new userSettingActions.UpdateNotificationsFailure(error));
        }));
    })
  ));

  constructor(
    private actions$: Actions,
    private userHttpService: UserHttpService
  ) {
  }
}
