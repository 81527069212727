import { AppSettingState, initialState } from '../states';
import { appSettingActions } from '../actions';


export function appSettingReducer(state = initialState, action: appSettingActions.Actions): AppSettingState {
    switch (action.type) {

        case appSettingActions.ActionTypes.LOAD_APP_SETTING: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        }

        case appSettingActions.ActionTypes.LOAD_APP_SETTING_SUCCESS: {
            return {
                ...state,
                sso: action.response.sso_domains,
                notAllowedDomains: action.response.social_domains,
                isLoading: false,
                isLoaded: true
            }
        }

        case appSettingActions.ActionTypes.LOAD_APP_SETTING_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true
            }
        }

        default: {
            return state;
        }
    }
}