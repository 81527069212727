export interface CommentAuthorOutputApiModel {
    commenter_id: number,
    avatar: string,
    display_name: string,
    slug: string
}

export interface CommentOutputApiModel {
    content: string
    id: number
    like_id: number
    likes_count: number,
    time_created: Date,
    commenter: CommentAuthorOutputApiModel
}

export class CommentOutputApiModel {
    constructor(data?: Partial<CommentOutputApiModel>) {
        if (data)
            Object.assign(this, data);
    }
}