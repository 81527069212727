import { Component, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { ComponentBase } from '@framework/base/component';
import { CoreState } from '@app/store/core/states';
import { storySelector } from '@modules/story/store/selectors';
import { StoryViewModel, StepDataItemViewModel } from '@modules/story/models/view-models';
import { Constants } from '@modules/shared/constants';
import { UtilityHelper } from '@framework/utilities';
import { StoryLoadStatusViewModel } from "@modules/story/models/store-view-models";
import { storyActions } from '@modules/story/store/actions';

@Component({
  selector: 'app-pb-advocacy-steps-bar',
  templateUrl: './steps-bar-advocacy.component.html',
  styleUrls: ['./steps-bar-advocacy.component.css']
})
export class StepsBarAdvocacyComponent extends ComponentBase {
  // Private variables
  private selectStoryDetail$: Observable<StoryViewModel>;
  private selectStatusDetail$: Observable<StoryLoadStatusViewModel>;

  private selectStoryDetailSubscription: Subscription;
  private selectStatusDetailSubscription: Subscription;
  public stepsData:any;
  // Public variables
  public storyDetail: StoryViewModel;
  public statusDetail: StoryLoadStatusViewModel;

  constructor(private store$: Store<CoreState>, private utility: UtilityHelper) {
    super();

    this.selectStoryDetail$ = this.store$.select(storySelector.selectCurrentStory);
    this.selectStatusDetail$ = this.store$.select(storySelector.selectLoadStatus);
  }

  // Life cycle hook
  protected onInit(): void {
    this.subscribe();
  }
  protected onDestroy(): void {
    this.unsubscribe();
  }
  protected onChanges(changes: SimpleChanges): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }
  // Private functions
  private subscribe() {
    this.selectStoryDetailSubscription = this.selectStoryDetail$.subscribe((storyDetail) => {
      this.storyDetail = storyDetail;
      // this.storyDetail.stepsData = Object.assign(this.storyDetail.stepsData['2'],authorPayload)

      if(this.storyDetail && this.storyDetail?.stepsData && Object.keys(this.storyDetail.stepsData).includes('17')){
        delete this.storyDetail.stepsData[17]
      }
      this.getStepsData();
      this.mapCount()
    });

    this.selectStatusDetailSubscription = this.selectStatusDetail$.subscribe((statusDetail) => {
      this.statusDetail = statusDetail;
    });
  }

  private unsubscribe() {
    this.selectStoryDetailSubscription.unsubscribe();
    this.selectStatusDetailSubscription.unsubscribe();
  }
  // Public functions
  public getStepIcon(name: string) {
    return Constants.stepIcon.getStepIcon(name.split(' ').join(''));
  }
  
  public likeTag(item, e) {
    item.count = (item.count ? item.count : 0) + 1;
    this.pop(e, item.idOrSlug);
    this.store$.dispatch(new storyActions.LikeStoryTag({ storyId: this.storyDetail.id, tagId: item.idOrSlug }));
  }

  public mapCount() {
    if(this.storyDetail?.stepsData)
    {
      const keys = this.getKeys(this.storyDetail.stepsData);
      keys.forEach(key => {
        if (this.storyDetail.stepsData[key].data && this.storyDetail.stepsData[key].data.length)
          this.storyDetail.stepsData[key].data.forEach(step => {
            step.count = this.getCount(step);
          })
      })
    }

  }

  public getKeys(arr): Array<string> {
    if (!arr) return [];
    return Object.keys(arr);
  }

  public getCount(item) {
    let count = 0;
    this.storyDetail.tagsCountData.forEach(tag => {
      if (tag.tagId == item.idOrSlug) {
        count = tag.count;
      }
    })
    return count;
  }
  public getStepsData() {
    var insertPayloadObj,stepsData,authorPayload;
    var data: StepDataItemViewModel[] = [];
    this.utility.Lodash.forEach(this.storyDetail && this.storyDetail.stepsData || [], (value, key) => {
      data.push(value);
    });
      
    if(this.storyDetail.contributions && this.storyDetail.contributions.length){
      stepsData = this.storyDetail.contributions.map(people => {
        return {
          label: people.displayName
        }
      })

      insertPayloadObj = {
        title:"Contributors",
        data:stepsData
      }
      // data.unshift(insertPayloadObj)
      data.unshift(insertPayloadObj)
    }
    authorPayload = {
      title:"Author",
      data:[{'label':this.storyDetail?.author?.displayName}]
    }  
    data.unshift(authorPayload)


    this.stepsData =  data;
  }

  public getCssClass(stepData: StepDataItemViewModel) {
    return `l-story-tags__item l-story-tags__item--${stepData.title.toLowerCase()}`;
  }



  public pop(e, id) {
    var element = document.getElementById('countBadge' + id);
    element.classList.add("animate-like");
    setTimeout(() => {
      var element = document.getElementById('countBadge' + id);
      element.classList.remove("animate-like");
    }, 500);
    let amount = 30;
    switch (e.target.dataset.type) {
      case 'shadow':
      case 'line':
        amount = 60;
        break;
    }
    // Quick check if user clicked the button using a keyboard
    if (e.clientX === 0 && e.clientY === 0) {
      const bbox = e.target.getBoundingClientRect();
      const x = bbox.left + bbox.width / 2;
      const y = bbox.top + bbox.height / 2;
      for (let i = 0; i < 30; i++) {
        // We call the function createParticle 30 times
        // We pass the coordinates of the button for x & y values
        this.createParticle(x, y, e.target.dataset.type);
      }
    } else {
      for (let i = 0; i < amount; i++) {
        this.createParticle(e.clientX, e.clientY + window.scrollY, e.target.dataset.type);
      }
    }
  }
  createParticle(x, y, type) {
    const particle = document.createElement('particle');
    document.body.appendChild(particle);
    let width: any = Math.floor(Math.random() * 30 + 8);
    let height: any = width;
    let destinationX = (Math.random() - 0.5) * 300;
    let destinationY = (Math.random() - 0.5) * 300;
    let rotation = Math.random() * 520;
    let delay = Math.random() * 100;

    var color = `hsl(${Math.random() * 90 + 90}, 70%, 50%)`;
    particle.style.boxShadow = `0 0 ${Math.floor(Math.random() * 10 + 10)}px ${color}`;
    particle.style.background = color;
    particle.style.borderRadius = '50%';
    width = height = Math.random() * 5 + 4;

    particle.style.width = `${width}px`;
    particle.style.height = `${height}px`;
    const animateArr: any = [
      {
        transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
        opacity: 1
      },
      {
        transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
        opacity: 0
      }
    ]
    const animateObj: any = {
      duration: Math.random() * 1000 + 2000,
      easing: 'cubic-bezier(0, .9, .57, 1)',
      delay: delay
    }

    const animation = particle.animate(animateArr, animateObj);
    animation.onfinish = this.removeParticle;
  }
  removeParticle(e) {
    e.srcElement.effect.target.remove();
  }
}
