import { PaginationModel } from "@framework/models/pagination-models";
import { SearchQueryBuilder } from "@framework/models/commons";
export interface GetStoriesInputApiModel {
    companyId: number;
    userId: number;
    public: boolean;
    archived: number;
    isMyStoriesOnly: boolean;
    campaignId: number;
    draft: boolean;
    filter: string;
    id_campaign: string;
    tags?: any[];
    campaigns: any[];
    authors?: any[];
    peoples?: any[];
    keywords?: string[];
    pagination: PaginationModel;
    includeStory: boolean;
    includeTestimonial: boolean;
    internaltags?:any,
    filter_search?: string;
}

export class GetStoriesInputApiModel {
    constructor(public pagination: PaginationModel) {
        this.pagination = new PaginationModel(12);
        this.includeStory = true;
        this.includeTestimonial = true;
    }

    private buildQuery(isIncludePagiantion: boolean) {
        let queryBuilder = new SearchQueryBuilder();
        

        // Put queries

        // if (this.public) {
        //     query += 'public=1&';
        // }

        // if (this.isMyStoriesOnly) {
        //     query += `user_id=${this.userId}&`;
        // }

        // if (this.campaignId > 0) {
        //     query += `campaign_id=${this.campaignId}&`;
        // }

        // if (this.draft) {
        //     query += `draft=1&`;
        // }

        // if (this.filter) {
        //     query += `filter=${this.filter}&`;
        // }


        if (this.keywords && this.keywords.length > 0) {
            queryBuilder.andQuery(this.keywords);
        }

        if (this.tags && this.tags.length > 0) {
            queryBuilder.andFilter('tags', this.tags.join(','));
        }

        if (this.campaigns && this.campaigns.length > 0) {
            queryBuilder.andFilter('campaigns', this.campaigns.join(','));
        }

        if (this.authors && this.authors.length > 0) {
            queryBuilder.andFilter('authors', this.authors.join(','));
        }

        if (this.peoples && this.peoples.length > 0) {
            queryBuilder.andFilter('players', this.peoples.join(','));
        }

        if (this.isMyStoriesOnly) {
            queryBuilder.andUser(this.userId.toString());
        }

        if (!this.includeStory) {
            queryBuilder.andParam('includeStories', 'no');
        }

        if (this.public) {
            queryBuilder.andParam('public', '1');
        }

        if (!this.includeTestimonial) {
            queryBuilder.andParam('includeTestimonials', 'no');
        }

        if(this.filter){
            queryBuilder.andParam('filter', this.filter); 
        }

        if(this.filter_search){
            queryBuilder.andParam('filter_search', this.filter_search); 
        }

        if(this.draft){
            queryBuilder.andParam('draft', '1'); 
        }


        if (isIncludePagiantion) {

            if (this.pagination.sort) {
                queryBuilder.andParam('sort',this.pagination.sort);
            }

            queryBuilder.setPage(this.pagination.page);
            queryBuilder.setLimit(this.pagination.limit);
        }
      

        return queryBuilder.build();
    }


    public get query() {
        return this.buildQuery(true);
    }

    public get queryWithoutPaination() {
        return this.buildQuery(false);
    }

    public andFilter(field: string, value: string) {
        if (this.filter) {
            this.filter = `${this.filter}|${field}:${value}`;
        } else {
            this.filter = `${field}:${value}`;
        }

        return this;
    }

    public init(queryParams: any) {
        this.keywords = [queryParams.keyword];
        this.userId = queryParams.uid;
        this.filter = queryParams.filter
        if (this.userId) {
            this.isMyStoriesOnly = true;
        }

        this.includeStory = queryParams.includeStories == 'no' ? false : true;
        this.includeTestimonial = queryParams.includeTestimonials == 'no' ? false : true;

        this.pagination = new PaginationModel(12);

        if (queryParams.filter) {
            const filterItems = queryParams.filter
            filterItems.forEach((fItem: string) => {
                if (fItem.indexOf('tags:') > -1) {
                    this.tags = fItem.split(':')[1].split(',');
                }

            });
        }
    }
}
