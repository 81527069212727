
export interface UserPermissionState {
    loading: boolean,
    loaded: boolean,
    response: any;
}


export const permissionInitialState: UserPermissionState = {
    loading: false,
    loaded: false,
    response: ''
}