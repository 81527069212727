import { LikeOutputApiModel } from '../../models/api-models/output';

export interface LikeEntity {
    name: string,
    resource_type: string,
    resource_id: number,
    payload: string
}

export class LikeEntity {
    constructor(data?: LikeOutputApiModel) {
        if (data)
            this.convertFromApi(data);
    }

    private convertFromApi(data: LikeOutputApiModel) {
       
    }
}
