import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { LikeInputApiModel } from '../models/api-models/input';
import { LikeOutputApiModel } from '../models/api-models/output';

@Injectable()
export class LikeHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/likes');
    }

    public like(payload: LikeInputApiModel) {
        return this.post('/', payload).pipe(map((response: any) => {
            return new LikeOutputApiModel(response);
        }));
    }

    public unlike(id: number, resource: string) {
        return this.delete(`/${id}/?resource=${resource}`).pipe(map((response: any) => {
            return response;
        }));
    }

}
