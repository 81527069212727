import { ProfilePublicOutputApiModel } from '../models/api-models/output';

export interface CompanyButtonEntity {
    label: string;
    url: string;
    foreground: string;
    background: string;
    isSelected: boolean;
}

export interface AuthorEntity {
    authorId: number;
    displayName: string;
    title: string;
    avatar: string;
}

export interface ProfilePublicEntity {
    companyId: number;
    name: string;
    email: string;
    website: string;
    avatar: string;
    buttons: CompanyButtonEntity[];
    author: AuthorEntity;
    slogan: string;
    slug: string;
    frequent_tags?:any;
    internal_stories_approval: any;
}

export class ProfilePublicEntity {
    constructor(data?: ProfilePublicOutputApiModel) {
        this.convertFromApi(data);
    }

    private convertFromApi(data: ProfilePublicOutputApiModel) {
        this.companyId = data.companyId;
        this.name = data.name;
        this.email = data.email;
        this.website = data.website;
        if (data.buttons) {
            this.buttons = data.buttons.map((button) => {
                return {
                    label: button.label,
                    url: button.url,
                    foreground: button.foreground,
                    background: button.background,
                    isSelected: button.isSelected
                };
            });
        }
        if(data.author){
            this.author = {
                authorId: data.author.authorId,
                avatar: data.author.avatar,
                title: data.author.title,
                displayName: data.author.displayName
            };
        }
        this.frequent_tags = data.frequent_tags
        this.slogan = data.slogan;
        this.slug = data.slug;
        this.internal_stories_approval = data.internal_stories_approval
    }
}
