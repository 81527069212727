import { Action } from '@ngrx/store';

import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { LoadDirectoryInputApiModel } from '@modules/user/models/input';
import { DirectoryOutputApiModel } from '@modules/user/models/output';
import { FollowOutputApiModel } from '@modules/shared/models/api-models/output';

export enum ActionTypes {
  LOAD_COMPANY = '[Directory] Load Company',
  LOAD_COMPANY_SUCCESS = '[Directory] Load Company Success',
  LOAD_COMPANY_FAILURE = '[Directory] Load Company Failure',

  LOAD_FOLLOWER = '[Directory] Load Follower',
  LOAD_FOLLOWER_SUCCESS = '[Directory] Load Follower Success',
  LOAD_FOLLOWER_FAILURE = '[Directory] Load Follower Failure',

  LOAD_FOLLOWING = '[Directory] Load Following',
  LOAD_FOLLOWING_SUCCESS = '[Directory] Load Following Success',
  LOAD_FOLLOWING_FAILURE = '[Directory] Load Following Failure',

  FOLLOW_SUCCESS = '[Directory] Follow Success',
  UNFOLLOW_SUCCESS = '[Directory] Unfollow Success',

  SET_COMPANY_FORCE_LOAD_STATUS = '[Directory] Set Company Force Load Status',
  SET_FOLLOWER_FORCE_LOAD_STATUS = '[Directory] Set Follower Force Load Status',
  SET_FOLLOWING_FORCE_LOAD_STATUS = '[Directory] Set Following Force Load Status'
}

export class LoadCompany implements Action {
  readonly type = ActionTypes.LOAD_COMPANY;

  constructor(public queryData: LoadDirectoryInputApiModel) { }
}

export class LoadCompanySuccess implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_SUCCESS;

  constructor(public response: ResponsePaginationApiModel<DirectoryOutputApiModel>) { }
}

export class LoadCompanyFailure implements Action {
  readonly type = ActionTypes.LOAD_COMPANY_FAILURE;

  constructor(public error: any) { }
}

export class LoadFollower implements Action {
  readonly type = ActionTypes.LOAD_FOLLOWER;

  constructor(public queryData: LoadDirectoryInputApiModel) { }
}

export class LoadFollowerSuccess implements Action {
  readonly type = ActionTypes.LOAD_FOLLOWER_SUCCESS;

  constructor(public response: ResponsePaginationApiModel<DirectoryOutputApiModel>) { }
}

export class LoadFollowerFailure implements Action {
  readonly type = ActionTypes.LOAD_FOLLOWER_FAILURE;

  constructor(public error: any) { }
}

export class LoadFollowing implements Action {
  readonly type = ActionTypes.LOAD_FOLLOWING;

  constructor(public queryData: LoadDirectoryInputApiModel) { }
}

export class LoadFollowingSuccess implements Action {
  readonly type = ActionTypes.LOAD_FOLLOWING_SUCCESS;

  constructor(public response: ResponsePaginationApiModel<DirectoryOutputApiModel>) { }
}

export class LoadFollowingFailure implements Action {
  readonly type = ActionTypes.LOAD_FOLLOWING_FAILURE;

  constructor(public error: any) { }
}

export class FollowSuccess implements Action {
  readonly type = ActionTypes.FOLLOW_SUCCESS;

  constructor(public response: FollowOutputApiModel) { }
}

export class UnfollowSuccess implements Action {
  readonly type = ActionTypes.UNFOLLOW_SUCCESS;

  constructor(public response: FollowOutputApiModel) { }
}

export class SetCompanyForceLoadStatus implements Action {
  readonly type = ActionTypes.SET_COMPANY_FORCE_LOAD_STATUS;

  constructor(public isForceLoad: boolean) { }
}

export class SetFollowerForceLoadStatus implements Action {
  readonly type = ActionTypes.SET_FOLLOWER_FORCE_LOAD_STATUS;

  constructor(public isForceLoad: boolean) { }
}

export class SetFollowingForceLoadStatus implements Action {
  readonly type = ActionTypes.SET_FOLLOWING_FORCE_LOAD_STATUS;

  constructor(public isForceLoad: boolean) { }
}

export type Actions =
  LoadCompany | LoadCompanySuccess | LoadCompanyFailure |
  LoadFollower | LoadFollowerSuccess | LoadFollowerFailure |
  LoadFollowing | LoadFollowingSuccess | LoadFollowingFailure | 
  FollowSuccess | UnfollowSuccess |
  SetCompanyForceLoadStatus | SetFollowerForceLoadStatus | SetFollowingForceLoadStatus;
