import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '@framework/services';

@Injectable()
export class AuthorizedGuard  {
    constructor(private authService: AuthService, private router: Router) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise((resolve, reject) => {
            this.authService.isHasToken().then((isHasToken: boolean) => {
                if (isHasToken) {
                    resolve(true);
                } else {
                    resolve(false);
                    this.router.navigate(['/user/login'], { queryParams: { link: encodeURI(btoa(state.url)) } });
                }
            });
        });
    }
}
