import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import { userActions } from '../actions';
import { UserHttpService } from '../../services';
import { ResponsePaginationApiModel } from '@framework/models/api-models';
import { LoadUserOutputApiModel, ValidateUserOutApiModel } from '@modules/user/models/output';

@Injectable()
export class UserEffects {
    
    loadEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.Load>(userActions.ActionTypes.LOAD),
        mergeMap((action: userActions.Load) => {
            return this.userHttpService.load(action.payload).pipe(
                map((response: ResponsePaginationApiModel<LoadUserOutputApiModel>) => {
                    return new userActions.LoadSuccess(response, action.isKeepCurrentState);
                }),
                catchError((error: any) => {
                    return of(new userActions.LoadFailure(error));
                }))
        })
    ));

    
    loadAuthorsEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.LoadAuthor>(userActions.ActionTypes.LOAD_AUTHORS),
        switchMap((action: userActions.LoadAuthor) => {
            return this.userHttpService.loadAuthors(action.companyId).pipe(
                map((response: any) => {
                    return new userActions.LoadAuthorSuccess(response);
                }),
                catchError((error: any) => {
                    return of(new userActions.LoadAuthorFailure(error));
                }))
        })
    ));

    
    loadContributorEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.LoadContributor>(userActions.ActionTypes.LOAD_CONTRIBUTOR),
        switchMap((action: userActions.LoadContributor) => {
            return this.userHttpService.loadContributor(action.storyId, action.payload).pipe(
                map((response: ResponsePaginationApiModel<LoadUserOutputApiModel>) => {
                    return new userActions.LoadSuccess(response, action.isKeepCurrentState);
                }),
                catchError((error: any) => {
                    return of(new userActions.LoadFailure(error));
                }))
        })
    ));

    
    createEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.Create>(userActions.ActionTypes.CREATE),
        switchMap((action: userActions.Create) => {
            return this.userHttpService.create(action.storyId, action.payload).pipe(
                map((data: LoadUserOutputApiModel) => {
                    return new userActions.CreateSuccess(data);
                }),
                catchError((error: any) => {
                    return of(new userActions.CreateFailure(error));
                }))
        })
    ));

    
    validateEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.ValidateUser>(userActions.ActionTypes.VALIDATE_USER),
        switchMap((action: userActions.ValidateUser) => {
            return this.userHttpService.validateUser(action.emails).pipe(
                map((data: ValidateUserOutApiModel[]) => {
                    return new userActions.ValidateUserSuccess(data);
                }),
                catchError((error: any) => {
                    return of(new userActions.ValidateUserFailure(error));
                }))
        })
    ));

    constructor(
        private actions$: Actions,
        private userHttpService: UserHttpService
    ) { }
}