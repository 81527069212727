export interface FollowOutputApiModel {
    id: number;
    resource_type: string;
    resource_id: number;
}

export class FollowOutputApiModel {
    constructor(data?: Partial<FollowOutputApiModel>) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
