import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { LandingPageEntity } from '../entities';
import { LandingPageState } from '../states';

export const landingPageAdapter: EntityAdapter<LandingPageEntity> = createEntityAdapter<LandingPageEntity>();

export const initialState: LandingPageState = landingPageAdapter.getInitialState({
    selectedId: null,
    isLoading: false,
    isLoaded: false,
    prevUrl: '',
    nextUrl: '',
    totalCount: 0
});