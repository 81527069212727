import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { SearchCompanyOutputApiModel, InfoCompanyOutputApiModel, CampaignOutputApiModel, WorkAttributeOutputApiModel, LandingPageOutputApiModel } from '../models/api-models/output';
import { CreateCompanyInputApiModel, CreateCampaignInputApiModel, GetCampaignInputApiModel, UpdateCampaignInputApiModel, UpdateCompanyInputApiModel, GetLandingPageInputApiModel, UpdateLandingPageInputApiModel, CreateLandingPageInputApiModel } from '../models/api-models/input';
import { ResponsePaginationApiModel } from '@framework/models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LandingOrderHttpService extends BaseHttpService {
    

    constructor(httpClient: HttpClient) {
        super(httpClient, '/landing_pages');
    }

  public OrderStories(id,payload){
       return this.post(`/${id}/order-stories/`, payload).pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  public GetOrderStories(id){
    return this.get(`/${id}/order-stories/`).pipe(
     map((response: any) => {
       return response;
     })
   );
}

    // public changeSlug(payload: Partial<any>) {
    //   return this.put(`/${payload.companyId}/setting/slug/`, {slug: payload.slug}).pipe(
    //     map((response: any) => {
    //       return response;
    //     })
    //   );
    // }
}
