import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { LoadAppSettingOutputApiModel } from '../models/api-models/output';

@Injectable()
export class AppSettingHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/app');
    }

    public getSSOSetting() {
        return this.get('/settings/').pipe(
            map((response: LoadAppSettingOutputApiModel) => {
                return response;
            })
        );
    }

}   
