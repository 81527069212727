export interface CreateMediaInputApiModel {
    tags: string,
    type: string,
    path: string,
    origname: string,
    source: string,
    filename: string,
    size: number,
    height: number,
    width: number,
    story_id: number
    video_token?: any;
    asset_id?: any;
    url?:any;
    aspect_ratio?:any
}

export class CreateMediaInputApiModel {
    constructor() {
        this.filename = '';
        this.height = 0;
        this.width = 0;
        this.tags = '';
    }
}