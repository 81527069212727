import { CampaignState } from '../states';
import { campaignActions } from '../actions';
import { CampaignEntity } from '../entities';
import { campaignAdapter, initialState } from '../adapters/campaign.adapter';


export function campaignReducer(state = initialState, action: campaignActions.Actions): CampaignState {
    switch (action.type) {

        case campaignActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            }
        }

        case campaignActions.ActionTypes.LOAD_SUCCESS: {
            const entities = action.response.result.map(item => new CampaignEntity(item));
            const newSate = campaignAdapter.setAll(entities, state);
            return {
                ...newSate,
                isLoading: false,
                isLoaded: true,
                nextUrl: action.response.next,
                prevUrl: action.response.prev,
                totalCount: action.response.count
            };
        }

        case campaignActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            }
        }

        default: {
            return state;
        }
    }
}