import { EventEmitter } from '@angular/core';

import { ComponentBase } from './component.base';

export abstract class ComponentModalBase<T> extends ComponentBase {
  
  public onClose: EventEmitter<any> = new EventEmitter<any>(); 
  public initialState: T;
  
  constructor(){
    super();
  }
}