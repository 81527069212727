import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, filter, take, catchError, switchMap, map } from 'rxjs/operators';

import { CoreState } from '@app/store/core/states';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { userProfileActions } from '@modules/user/store/actions';

@Injectable()
export class AdminAccessGuard  {
    constructor(private router: Router, private store$: Store<CoreState>) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.getUserProfile().pipe(
            switchMap((userProfile: UserProfileViewModel) => {
                if (userProfile.isCompanyAdmin) {
                    return of(true);
                } else {
                    window.location.href = '/company';
                    return of(false);
                }
            }),
            catchError((error) => {
                this.router.navigate(['user/login']);
                return of(false);
            })
        );
    }

    private getUserProfile(): Observable<UserProfileViewModel> {
        let uProfile: UserProfileViewModel;
        return this.store$
            .select(userProfileSelectors.selectCurrentProfile)
            .pipe(
                tap((userProfile: UserProfileViewModel) => {
                    if (!userProfile || !userProfile.id) {
                        return this.store$.dispatch(new userProfileActions.LoadCurrentProfile());
                    }
                }),
                switchMap((userProfile) => {
                    uProfile = userProfile;
                    return this.store$.select(userProfileSelectors.selectIsLoaded);
                }),
                filter((isLoaded) => isLoaded === true),
                map((userProfileLoadStatus) => uProfile),
                take(1)
            );
    }
}
