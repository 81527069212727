import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { BaseHttpService } from '@framework/services';
import { ResponsePaginationApiModel } from '@framework/models';
import { GetCampaignInputApiModel } from '../models/api-models/input';
import { CampaignOutputApiModel } from '../models/api-models/output';

@Injectable()
export class CampaignHttpService extends BaseHttpService {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/companies');
    }

    public load(companyId: number, queryData: GetCampaignInputApiModel) {
        return this.get(`/${companyId}/setting/campaigns/?${queryData.query}`).pipe(
            map((response: ResponsePaginationApiModel<CampaignOutputApiModel>) => {
                return response;
            })
        );
    }

    public makeActive(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/campaigns/activate/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makeInactive(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/campaigns/activate/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makePublic(companyId: number, ids: number[]) {
        return this.post(`/${companyId}/setting/campaigns/state/?ids=${ids.join(',')}`, {}).pipe(
            map((response: any) => {
                return response;
            })
        );
    }

    public makePrivate(companyId: number, ids: number[]) {
        return this.delete(`/${companyId}/setting/campaigns/state/?ids=${ids.join(',')}`).pipe(
            map((response: any) => {
                return response;
            })
        );
    }
}
