import { authPageSettingActions } from "../actions";
import { AuthPageSettingState } from "../states";
import { initialState } from "../adapters/auth-page-setting.adapter";

export function AuthPageSettingReducer(state = initialState, action: authPageSettingActions.Actions): AuthPageSettingState {
    switch (action.type) {

        case authPageSettingActions.ActionTypes.LOAD: {
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };
        }

        case authPageSettingActions.ActionTypes.LOAD_SUCCESS: {
            const result = action.response.config;
            return {
                ...state,
                data: result,
                isLoading: false,
                isLoaded: true
            };
        }

        case authPageSettingActions.ActionTypes.LOAD_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false
            };
        }

        default: {
            return state;
        }
    }
}