import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { tap, filter, take, catchError, switchMap, map, withLatestFrom } from 'rxjs/operators';

import { CoreState } from '@app/store/core/states';
import { appSettingSelector } from '@modules/shared/store/selectors';
import { AppSettingViewModel } from '@modules/shared/models';
import { UserProfileViewModel } from '@modules/user/store/models';
import { userProfileSelectors } from '@modules/user/store/selectors';
import { userProfileActions } from '@modules/user/store/actions';

@Injectable()
export class CheckUserHadCompanyGuard  {
    constructor(private router: Router, private store$: Store<CoreState>) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.getUserProfile().pipe(
            switchMap(([userProfile, appSetting]) => {
                userProfile = <UserProfileViewModel>userProfile;
                appSetting = <AppSettingViewModel>appSetting;
                const hasCompany = userProfile.company && userProfile.company.id > 0 && userProfile.isApprovedOutsider;
                if (hasCompany) {
                    return of(true);
                } else if (userProfile.company && userProfile.company.id > 0 && !userProfile.isApprovedOutsider) {
                    this.router.navigate([`company/approval`]);
                    return of(false);
                } else {
                    const companyDomain = userProfile.email.split('@').pop();
                    appSetting.notAllowedDomains = [...appSetting.notAllowedDomains, 'aol.com', 'hotmail.com', 'gmail.com', 'yahoo.com', 'outlook.com', 'mail.com', 'yandex.com', 'hushmail.com', 'zohomail.com', 'yopmail.com']
                    if (appSetting && appSetting.notAllowedDomains && appSetting.notAllowedDomains.findIndex(f => f.toLocaleLowerCase() === companyDomain.toLocaleLowerCase()) > -1) {
                        // Company domain in list not allowed domains
                        this.router.navigate([`company/search`]);
                    } else {
                        this.router.navigate([`company/suggested/${companyDomain}`]);
                    }

                    return of(false);
                }
            }),
            catchError((error) => {
                this.router.navigate(['user/login']);
                return of(false);
            })
        );
    }

    private getUserProfile(): Observable<(UserProfileViewModel | AppSettingViewModel)[]> {
        let uProfile: UserProfileViewModel;
        let aSetting: AppSettingViewModel;
        return this.store$
            .select(userProfileSelectors.selectCurrentProfile)
            .pipe(
                tap((userProfile: UserProfileViewModel) => {
                    if (!userProfile || !userProfile.id) {
                        return this.store$.dispatch(new userProfileActions.LoadCurrentProfile());
                    }
                }),
                withLatestFrom(
                    this.store$.select(appSettingSelector.selectAppSetting)
                ),
                switchMap(([userProfile, appSetting]) => {
                    uProfile = userProfile;
                    aSetting = appSetting;
                    return this.store$.select(userProfileSelectors.selectIsLoaded)
                }),
                filter((isLoaded) => isLoaded === true),
                map((userProfileLoadStatus) => [uProfile, aSetting]),
                take(1)
            );
    }
}