import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { ComponentModalBase } from '@framework/base/component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'co-tm-embed-modal-container',
  templateUrl: './tm-embed-modal-container.component.html',
  styleUrls: ['./tm-embed-modal-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TmEmbedModalContainerComponent extends ComponentModalBase<any> {

  public embedForm: UntypedFormGroup;
  public IsScriptCreated: boolean = false;
  public baseUrl: string;
  public queryData: any;
  public border_radius:any = '5px';
  public text_align:any ='center';
  public background_color:any = '#373e85';
  public color:any = '#fff';
  public fontSize:any = '15px'
  public text_decoration:any = 'none';
  public width:any = '140px';
  public height:any = '45px';
  private formChangeSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) { 
    super();

    if (!_.isEmpty(this.initialState)) {
      this.baseUrl = this.initialState;
    } else {
      this.baseUrl = window.location.origin;
    }
    this.initForm();
  }

  // Life cycle hook
  protected onInit(): void { 
    this.initialState.sendData.btnclass   = this.initialState?.sendData?.btnclass.replace(/[^\w\s]/g, '');
    this.initialState.sendData.paraentDiv = this.initialState?.sendData?.paraentDiv.replace(/[^\w\s]/g, '');
    if (this.initialState && this.initialState.sendData) {

      this.queryData = this.initialState.sendData;
    }
    setTimeout(()=>{
      const element = document.getElementById('embed_btn');
      element.classList.add(this.initialState?.sendData?.btnclass);
    },1000)

   
      if(this.initialState?.sendData?.paraentDiv && this.initialState?.sendData?.btnclass){
   this.embedForm.controls.styleCode.setValue(`
   #${this.initialState?.sendData?.paraentDiv} { 
    text-align:${this.text_align}
   }
   .${this.initialState?.sendData?.btnclass} {
    padding:0.8rem 0.5rem;
    line-height: 1.5rem;
    height:${this.height};
    width:${this.width};
    border-radius:${this.border_radius};
    color:${this.color};
    background-color:${this.background_color};
    font-size:${this.fontSize};
    border:none;
   }
   
`)
this.showStaticToButton(this.embedForm.controls.styleCode.value);
      }


    this.formChangeSubscription = this.embedForm.valueChanges.subscribe((res)=>{
     console.log(res);
     this.height = res.height ? res.height+res.heightList : '45px';
     this.width = res.width ? res.width+res.widthList : '140px';
     this.text_align = res.text_align ? res.text_align : 'center'; ;
     this.border_radius = res.border_radius ? res.border_radius+res.bordervalue : '5px';
     this.fontSize = res.fontsize ? res.fontsize+res.fontmeasurelist : '15px'
     var data = `
     #${this.initialState?.sendData?.paraentDiv} { 
      text-align:${this.text_align}
     }
     .${this.initialState?.sendData?.btnclass} {
      padding:0.8rem 0.5rem;
      line-height: 1.5rem;
      height:${this.height};
      width:${this.width};
      border-radius:${this.border_radius};
      color:${this.color};
      background-color:${this.background_color};
      font-size:${this.fontSize};
      border:none;
     }
     
  `
  this.showStaticToButton(data);
     if(res.customStyleCode){
      this.showStylesToButton(res.customStyleCode);
     }
    })
  }
  protected onDestroy(): void {
  }
  protected onChanges(): void {

  }
  protected onDoCheck(): void {

  }
  protected onAfterContentInit(): void {

  }
  protected onAfterContentChecked(): void {

  }
  protected onAfterViewInit(): void {

  }
  protected onAfterViewChecked(): void {

  }

  private initForm() {
    this.embedForm = this.formBuilder.group({
      backgroundColor: ['#373e85'],
      textColor: ['#fff'],
      buttonText: ["Submit a Story"],
      selectRadio: ['_self', Validators.required],
      headerCode: '',
      bodyCode: '',
      height: [45],
      width: [140],
      widthList:['px'],
      heightList:['px'],
      bordervalue:['px'],
      text_align:['center'],
      border_radius:[5],
      fontmeasurelist:['px'],
      fontsize:['1'],
      styleCode:[],
      customStyleCode:[]

    });
  }

  public close() {
    this.onClose.emit();
  }

  public getScript() {
    if(!this.queryData) {
      console.error('Invalid querydata');
    }
    let testimonilaURL = this.baseUrl + '/assets/front-end-minify/js/testimonial.js';
    // let formData = this.embedForm.getRawValue();
    this.embedForm.controls.styleCode.setValue(`
    #${this.initialState?.sendData?.paraentDiv} { 
     text-align:${this.text_align};
     display:block;
    }
    .${this.initialState?.sendData?.btnclass} {
     padding:0.8rem 0.5rem;
     line-height: 1.5rem;
     height:${this.height};
     width:${this.width};
     border-radius:${this.border_radius};
     color:${this.color};
     background-color:${this.background_color};
     font-size:${this.fontSize};
     text-decoration: none;
     border:none;
    }
 `)
 var cssString = JSON.stringify(this.embedForm.controls.styleCode.value + this.embedForm.controls.customStyleCode.value);
 var  encryptedCss = btoa(cssString)
    var config = {
      backgroundColor: this.background_color,
      textColor: this.color,
      buttonText: this.embedForm.controls.buttonText.value.replace(/\s/g, '&nbsp;'),
      whereToOpen: this.embedForm.controls.selectRadio.value,
      apiURL: this.baseUrl + '/',
      companySlug: this.queryData.companySlug,
      paraentDiv:this.initialState?.sendData?.paraentDiv,
      btnClass:this.initialState?.sendData?.btnclass,
      encryptedCss:encryptedCss

    };




    const highlightedText = JSON.stringify(config, undefined, 2).replace(/\s/g, '');
    this.embedForm.controls.headerCode.setValue('<script type="text/javascript" src="https://code.jquery.com/jquery-3.4.1.slim.min.js"></script><script type="text/javascript" src="' + this.baseUrl + '/assets/front-end-minify/js/vendor.min.js"></script><script type="text/javascript" src="' + testimonilaURL + '"></script>' + '<script type="text/javascript">' + 'window.goodSeekers.init(' + highlightedText + ')</script>');
    this.embedForm.controls.bodyCode.setValue(`<div id=${this.initialState?.sendData?.paraentDiv}></div>`);
    this.IsScriptCreated = true;
  }

  onBackgroundColorChange(data){
    this.background_color = data;
    this.embedForm.controls.backgroundColor.setValue(this.background_color)
  }
  onTextColorChange(data){
    this.color = data;
    this.embedForm.controls.textColor.setValue(this.color)
  }
  showStylesToButton(data){
    const oldstyleElement = document.getElementById('dynamicStyles');
    if (oldstyleElement) {
      oldstyleElement.parentNode.removeChild(oldstyleElement);
    }
    const styleElement = document.createElement('style');
          styleElement.id = 'dynamicStyles';

            if (styleElement) {
                styleElement.textContent += data;
                document.head.appendChild(styleElement);
            }
  }

  showStaticToButton(data){
    const oldstyleElement = document.getElementById('staticStyles');
    if (oldstyleElement) {
      oldstyleElement.parentNode.removeChild(oldstyleElement);
    }
    const styleElement = document.createElement('style');
          styleElement.id = 'staticStyles';

            if (styleElement) {
                styleElement.textContent += data;
                document.head.appendChild(styleElement);
            }

  }
}



