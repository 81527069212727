import { Action } from '@ngrx/store';
import { TestimonialSettingOutputApiModel, QuestionOptionOutputApiModel } from '../models/api-models/output';
import { UpdateTestimonialSettingInputApiModel, UpdateQuestionOptionInputApiModel } from '../models/api-models/input';

export enum ActionTypes {
    LOAD = '[Company Admin Testimonial Setting] Load',
    LOAD_SUCCESS = '[Company Admin Testimonial Setting] Load Success',
    LOAD_FAIL = '[Company Admin Testimonial Setting] Load Fail',

    LOAD_OPTION = '[Company Admin Testimonial Setting] Load Option',
    LOAD_OPTION_SUCCESS = '[Company Admin Testimonial Setting] Load Option Success',
    LOAD_OPTION_FAIL = '[Company Admin Testimonial Setting] Load Option Fail',

    ADD_OPTION = '[Company Admin Testimonial Setting] add option',
    ADD_OPTION_SUCCESS = '[Company Admin Testimonial Setting] add option Success',
    ADD_OPTION_FAIL = '[Company Admin Testimonial Setting] add option Fail',

    UPDATE = '[Company Admin Testimonial Setting] update',
    UPDATE_SUCCESS = '[Company Admin Testimonial Setting] update Success',
    UPDATE_FAIL = '[Company Admin Testimonial Setting] update Fail',

    DELETE_OPTION = '[Company Admin Testimonial Setting] delete option',
    DELETE_OPTION_SUCCESS = '[Company Admin Testimonial Setting] delete option Success',
    DELETE_OPTION_FAIL = '[Company Admin Testimonial Setting] delete option Fail',

    UPDATE_OPTION = '[Company Admin Testimonial Setting] update option',
    UPDATE_OPTION_SUCCESS = '[Company Admin Testimonial Setting] update option Success',
    UPDATE_OPTION_FAIL = '[Company Admin Testimonial Setting] update option Fail'
}

export class Load implements Action {
    readonly type = ActionTypes.LOAD;

    constructor(public companyId: number) { }
}

export class LoadSuccess implements Action {
    readonly type = ActionTypes.LOAD_SUCCESS;

    constructor(public response: TestimonialSettingOutputApiModel) { }
}

export class LoadFail implements Action {
    readonly type = ActionTypes.LOAD_FAIL;

    constructor(public error: any) { }
}

export class LoadOption implements Action {
    readonly type = ActionTypes.LOAD_OPTION;

    constructor(public companyId: number, public questionId: number) { }
}

export class LoadOptionSuccess implements Action {
    readonly type = ActionTypes.LOAD_OPTION_SUCCESS;

    constructor(public response: QuestionOptionOutputApiModel[]) { }
}

export class LoadOptionFail implements Action {
    readonly type = ActionTypes.LOAD_OPTION_FAIL;

    constructor(public error: any) { }
}

export class Update implements Action {
    readonly type = ActionTypes.UPDATE;

    constructor(public companyId: number, public payload: Partial<UpdateTestimonialSettingInputApiModel>) { }
}

export class UpdateSuccess implements Action {
    readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class UpdateFailure implements Action {
    readonly type = ActionTypes.UPDATE_FAIL;

    constructor(public error: any) { }
}

export class UpdateOption implements Action {
    readonly type = ActionTypes.UPDATE_OPTION;

    constructor(public companyId: number, public questionId: number, public optionId: number, public payload: Partial<UpdateQuestionOptionInputApiModel>) { }
}

export class UpdateOptionSuccess implements Action {
    readonly type = ActionTypes.UPDATE_OPTION_SUCCESS;
}

export class UpdateOptionFailure implements Action {
    readonly type = ActionTypes.UPDATE_OPTION_FAIL;

    constructor(public error: any) { }
}

export class DeleteOption implements Action {
    readonly type = ActionTypes.DELETE_OPTION;

    constructor(public companyId: number, public questionId: number, public optionId: number) { }
}

export class DeleteOptionSuccess implements Action {
    readonly type = ActionTypes.DELETE_OPTION_SUCCESS;
}

export class DeleteOptionFailure implements Action {
    readonly type = ActionTypes.DELETE_OPTION_FAIL;

    constructor(public error: any) { }
}

export class AddOption implements Action {
    readonly type = ActionTypes.ADD_OPTION;

    constructor(public companyId: number, public questionId: number, public payload: Partial<UpdateQuestionOptionInputApiModel>) { }
}

export class AddOptionSuccess implements Action {
    readonly type = ActionTypes.ADD_OPTION_SUCCESS;
}

export class AddOptionFailure implements Action {
    readonly type = ActionTypes.ADD_OPTION_FAIL;

    constructor(public error: any) { }
}

export type Actions =
    Load |
    LoadSuccess |
    LoadFail |
    LoadOption |
    LoadOptionSuccess |
    LoadOptionFail |
    Update |
    UpdateSuccess |
    UpdateFailure |
    UpdateOption |
    UpdateOptionSuccess |
    UpdateOptionFailure |
    DeleteOption |
    DeleteOptionSuccess |
    DeleteOptionFailure |
    AddOption |
    AddOptionSuccess |
    AddOptionFailure
    ;
