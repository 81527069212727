<app-toolbar>

  <ng-container *ngIf="isCanShare">
    <div [formGroup]="shareForm">
      <div class="addthis_inline_share_toolbox_eclv"></div>
      <app-toolbar-item>
        <button data-toggle="tooltip" (click)="share()" data-placement="left" title="Share your Profile" class="btn text-gray text-hover-mint">
          <svg role="img">
            <use xlink:href="#icon-share"></use>
          </svg>
        </button>
      </app-toolbar-item>
    </div>
  </ng-container>

</app-toolbar>
