import { CommentEntity } from "@modules/shared/store/entities";

export interface CommentAuthorViewModel {
    authorId: number,
    avatar: string,
    displayName: string,
    slug: string
}

export interface CommentViewModel {
    content: string,
    id: number,
    likeId: number,
    likesCount: number,
    createdAt: Date,
    author: CommentAuthorViewModel
}

export class CommentViewModel {
    constructor(data?: CommentEntity) {
        if (data)
            this.convertFromStore(data);
    }

    private convertFromStore(data: CommentEntity) {
        Object.assign(this, data);
    }

    public get isLiked() {
        return this.likeId > 0;
    }
}